import React, { useEffect, useState } from "react";
import DialogTitleHeader from "../../common/DialogTitleHeader";
import ComposeMessageStep from "./ComposeMessageStep";
import ScheduleDeliveryStep from "./ScheduleDeliveryStep";
import SelectAudienceStep from "./SelectAudienceStep";
import AlertDialog from "../AlertDialog";
import { getAgentsListFunc, getAllClientsUnpaginatedFunc } from "../../actions/Agency";
import { useDispatch, useSelector } from "react-redux";
import { getCaseByAgentFunc } from "../../actions/Cases";

const steps = ["Compose Message", "Select Audience", "Schedule Delivery Time"];

const PushNotificationForm = ({ handleClose }) => {
  const [activeStep, setActiveStep] = useState(0);
  // const [goBack, setGoBack] = useState(false);
  // const [selectedClientId, setSelectedClientId] = useState({});
  const [skipped, setSkipped] = useState(new Set());
  // const [addNewCaseData, setAddNewCaseData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});

  const [formValues, setFormValues] = useState({
    messageTitle: "",
    messageDescription: "",
  });
  const [clientMembersArr, setClientMembersArr] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [filteredClients, setFilteredClients] = useState(
    formValues.clientMembersArr
  );
  const [statusFilter, setStatusFilter] = useState("all");
  const [caseAssignedFilter, setCaseAssignedFilter] = useState("all");
  const [agentOptions, setAgentOptions] = useState([]);
  const [selectedAgent, setSelectedAgent] = useState("all");
  const [filter_clients, setFilter_clients] = useState([]);
  const [clientsLoading, setClientsLoading] = useState(false);

  const dispatch = useDispatch();

  const getAllClients = useSelector((state) => state.getAllClientsUnpaginated);
  const { loading, error, clients } = getAllClients;

  const getCaseByAgent = useSelector((state) => state.getCaseByAgent);
  const {
    loading: loadingCaseAgent,
    error: errorCaseAgent,
    caseInfoByAgent,
  } = getCaseByAgent;

  const getAgentsList = useSelector((state) => state.getAgentsList);
  const {
    loading: loadingAgents,
    error: errorAgents,
    allAgents,
  } = getAgentsList;

  // console.log("ALL AGENTS", allAgents);
  // console.log("CASE INFO AGENT", caseInfoByAgent);

  useEffect(() => {
    dispatch(getAllClientsUnpaginatedFunc());
    dispatch(getAgentsListFunc());
  }, []);

  useEffect(() => {
    if (allAgents?.agents_list) {
      setAgentOptions(allAgents?.agents_list);
    }
  }, [allAgents]);

  useEffect(() => {
    if (clients?.clients_list) {
      setClientOptions(clients?.clients_list);
      setClientMembersArr(clients?.clients_list);
    }
  }, [clients]);

  useEffect(() => {
    if (selectedAgent && selectedAgent?.id) {
      dispatch(getCaseByAgentFunc(selectedAgent?.id));
    }
  }, [selectedAgent]);

  useEffect(() => {
    if (caseInfoByAgent?.cases) {
      const uniqueClients = {};
      const unique_clients = caseInfoByAgent?.cases?.reduce(
        (acc, each_case) => {
          if (!uniqueClients[each_case.client.id]) {
            uniqueClients[each_case.client.id] = true;
            acc.push(each_case.client);
          }
          return acc;
        },
        []
      );

      setFilter_clients(unique_clients);
    }
  }, [caseInfoByAgent]);

  function clients_from_case_status() {
    if (statusFilter !== "all") {
      let local_filtered_clients = clientOptions.filter((client) =>
        client.cases.some((caseitem) => caseitem.status === statusFilter)
      );

      if (caseAssignedFilter !== "all") {
        local_filtered_clients = local_filtered_clients.filter((client) =>
          filter_clients.some((fc) => fc.id === client.id)
        );
      }

      // const statusClients = clientOptions
      //   .filter((client) =>
      //     client.cases.some((caseitem) => caseitem.status === statusFilter)
      //   )
      //   .filter((client) => filter_clients.some((fc) => fc.id === client.id));
      setClientMembersArr(local_filtered_clients);
    } else {
      let local_filtered_clients = clientOptions;

      if (caseAssignedFilter !== "all") {
        local_filtered_clients = local_filtered_clients.filter((client) =>
          filter_clients.some((fc) => fc.id === client.id)
        );
      }
      setClientMembersArr(local_filtered_clients);
    }
  }

  function clients_from_case_assigned() {
    if (caseAssignedFilter !== "all" && filter_clients) {
      let local_filtered_clients = clientOptions.filter((client) =>
        filter_clients.some((fc) => fc.id === client.id)
      );

      if (statusFilter !== "all") {
        local_filtered_clients = local_filtered_clients.filter((client) =>
          client.cases.some((caseitem) => caseitem.status === statusFilter)
        );
      }

      setClientMembersArr(local_filtered_clients);
    } else {
      setClientsLoading(true);
      let local_filtered_clients = clientOptions;
      if (statusFilter !== "all") {
        local_filtered_clients = local_filtered_clients.filter((client) =>
          client.cases.some((caseitem) => caseitem.status === statusFilter)
        );
      }

      setClientMembersArr(local_filtered_clients);
    }
  }

  // Apply filter when selectedStatus changes
  useEffect(() => {
    // filterClients(statusFilter, selectedAgent);
    clients_from_case_status();
    // clients_from_case_assigned();
  }, [statusFilter]);

  useEffect(() => {
    // filterClients(statusFilter, selectedAgent);
    // clients_from_case_status();
    clients_from_case_assigned();
  }, [selectedAgent, filter_clients]);

  // STEPPER FUNCTIONS
  const isStepSkipped = (step) => {
    return skipped.has(step);
  };

  const handleNext = () => {
    let newSkipped = skipped;

    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped(newSkipped);
  };

  const goPreviousStepFormHandler = async () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <ComposeMessageStep
            activeStep={activeStep}
            steps={steps}
            handleNext={handleNext}
            formValues={formValues}
            setFormValues={setFormValues}
          />
        );

      case 1:
        return (
          <SelectAudienceStep
            activeStep={activeStep}
            steps={steps}
            formValues={formValues}
            clientOptions={clientOptions}
            setFormValues={setFormValues}
            handleNext={handleNext}
            filteredClients={filteredClients}
            statusFilter={statusFilter}
            setStatusFilter={setStatusFilter}
            selectedAgent={selectedAgent}
            setSelectedAgent={setSelectedAgent}
            caseAssignedFilter={caseAssignedFilter}
            setCaseAssignedFilter={setCaseAssignedFilter}
            clientMembersArr={clientMembersArr}
            setClientMembersArr={setClientMembersArr}
            clients_from_case_status={clients_from_case_status}
            clients_from_case_assigned={clients_from_case_assigned}
            handleBack={goPreviousStepFormHandler}
            agentOptions={agentOptions}
            clientsLoading={loadingCaseAgent}
          />
        );

      case 2:
        return (
          <ScheduleDeliveryStep
            activeStep={activeStep}
            steps={steps}
            formValues={formValues}
            closeStepperForm={handleClose}
            handleBack={goPreviousStepFormHandler}
            clientMembersArr={clientMembersArr}
          />
        );

      default:
        return "Unknown step";
    }
  }

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  const closeFormHandler = () => {
    handleClose();
  };

  const closeFormDialogHandler = () => {
    // handleClose();
    setOpenModal(true);
    setModalContentData({
      title: "Are you sure?",
      subtitle: "All the progress you made will be lost.",
    });
  };

  return (
    <>
      {openModal && (
        <AlertDialog
          openModal={openModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          formSubmitFunction={closeFormHandler}
        />
      )}

      {/* DIALOG TITLE HEADER */}
      <DialogTitleHeader
        headerTitle="New Push Message"
        activeStep={activeStep}
        steps={steps}
        isStepSkipped={isStepSkipped}
        closeFormDialogHandler={closeFormDialogHandler}
      />

      {activeStep === steps.length ? (
        <React.Fragment></React.Fragment>
      ) : (
        getStepContent(activeStep)
      )}
    </>
  );
};

export default PushNotificationForm;
