const Style = () => ({
  style__main: {
    "& .MuiInputBase-root": {
      background: "#fff",
      borderRadius: "4px",

      "& input": {
        fontSize: "16px",
        lineHeight: "19px",
        fontWeight: "400",
        color: "#000",
        // fontSize: "22px",
        // textAlign: "center",
      },
    },
  },
  custom__height: {
    "& .MuiInputBase-root": {
      height: "80px",
    },
  },
  table__input: {
    "& .MuiInputBase-root": {
      background: "#F4F7FB",
      borderRadius: "5px",
      height: "32px",
    },
  },
  multiline__input: {
    "& .MuiInputBase-root": {
      borderRadius: "4px",
      height: "max-content",
      fontSize: "16px",
      lineHeight: "19px",
      fontWeight: "400",
      // color: "var(--color-disabled-hot)",
    },
  },
});
export default Style;
