import { Box, Typography } from "@mui/material";
import React from "react";
import Illustration from "../../assets/images/Illustration.png";
import HeaderText from "../../common/HeaderText";

const ComingSoonPage = () => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      flexDirection="column"
      gap="32px"
      height="90vh"
    >
      <img src={Illustration} alt="coming soon" />
      <Box>
        {/* <HeaderText fontSize="44px" textContent="Coming Soon!" variant="h1" /> */}
        <Typography
          variant="body1"
          className="d-flex"
          alignItems="center"
          justifyContent="center"
          fontSize={24}
          color="#566875"
        >
          This page is under development
        </Typography>
      </Box>
    </Box>
  );
};

export default ComingSoonPage;
