import { Stack } from "@mui/material";
import React from "react";
import HeaderText from "../../common/HeaderText";
import { extractDate, extractMonth } from "../../utils/helpers";

const PainLogsDate = ({ date }) => {
  return (
    <Stack>
      <HeaderText
        textContent={extractDate(date)}
        variant="h4"
        color="#000"
        fontSize="24px"
      />
      <HeaderText
        textContent={extractMonth(date).toUpperCase()}
        variant="body1"
        color="secondary"
        fontSize="12px"
        fontWeight="400"
      />
    </Stack>
  );
};

export default PainLogsDate;
