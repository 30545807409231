// export const CREATE_AGENCY_REQUEST = "CREATE_AGENCY_REQUEST";
// export const CREATE_AGENCY_SUCCESS = "CREATE_AGENCY_SUCCESS";
// export const CREATE_AGENCY_FAIL = "CREATE_AGENCY_FAIL";

export const CREATE_WORKSPACE_REQUEST = "CREATE_WORKSPACE_REQUEST";
export const CREATE_WORKSPACE_SUCCESS = "CREATE_WORKSPACE_SUCCESS";
export const CREATE_WORKSPACE_FAIL = "CREATE_WORKSPACE_FAIL";

export const GET_ALL_CLIENTS_REQUEST = "GET_ALL_CLIENTS_REQUEST";
export const GET_ALL_CLIENTS_SUCCESS = "GET_ALL_CLIENTS_SUCCESS";
export const GET_ALL_CLIENTS_FAIL = "GET_ALL_CLIENTS_FAIL";
export const RESET_ALL_CLIENTS = "RESET_ALL_CLIENTS";

export const GET_ALL_CLIENTS_UNPAGINATED_REQUEST =
  "GET_ALL_CLIENTS_UNPAGINATED_REQUEST";
export const GET_ALL_CLIENTS_UNPAGINATED_SUCCESS =
  "GET_ALL_CLIENTS_UNPAGINATED_SUCCESS";
export const GET_ALL_CLIENTS_UNPAGINATED_FAIL =
  "GET_ALL_CLIENTS_UNPAGINATED_FAIL";

export const GET_AGENCY_REQUEST = "GET_AGENCY_REQUEST";
export const GET_AGENCY_SUCCESS = "GET_AGENCY_SUCCESS";
export const GET_AGENCY_FAIL = "GET_AGENCY_FAIL";

export const UPDATE_AGENCY_REQUEST = "UPDATE_AGENCY_REQUEST";
export const UPDATE_AGENCY_SUCCESS = "UPDATE_AGENCY_SUCCESS";
export const UPDATE_AGENCY_FAIL = "UPDATE_AGENCY_FAIL";
