import React from "react";
import { Box, Skeleton, Stack } from "@mui/material";

const BrandingSkeleton = () => {
  return (
    <Stack gap="24px">
      <Box display="flex" gap="8px">
        <Skeleton variant="rounded" width={210} height={26} />
        <Skeleton variant="rounded" width={210} height={26} />
      </Box>
      <Box display="flex" gap="24px" height="242px">
        <Skeleton variant="rounded" width={210} height="100%" />
        <Stack gap="24px" width="75%">
          <Skeleton variant="rounded" width="100%" height="100%" />
          <Skeleton variant="rounded" width="100%" height="100%" />
          <Skeleton variant="rounded" width="100%" height="100%" />
        </Stack>
      </Box>
      <Stack gap="24px">
        <Skeleton variant="rounded" width={151} height={26} />
        <Skeleton variant="rounded" width={416} height={26} />
        <Skeleton variant="rounded" width={416} height={26} />
      </Stack>

      <Skeleton
        sx={{ marginLeft: "auto" }}
        variant="rounded"
        width={133}
        height={45}
      />
    </Stack>
  );
};

export default BrandingSkeleton;
