import React from "react";

const AddCircleIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="31"
      height="30"
      viewBox="0 0 31 30"
      fill="none"
    >
      <path
        d="M15.5 27.5C22.375 27.5 28 21.875 28 15C28 8.125 22.375 2.5 15.5 2.5C8.625 2.5 3 8.125 3 15C3 21.875 8.625 27.5 15.5 27.5Z"
        stroke="#0C80F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.5 15H20.5"
        stroke="#0C80F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5 20V10"
        stroke="#0C80F3"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AddCircleIcon;
