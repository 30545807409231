import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const CaseChartSkeleton = () => {
  return (
    <Stack gap="1.5rem">
      <Skeleton variant="rounded" height="17.75rem" />
      <Box display="flex" gap="1rem">
        <Skeleton variant="rounded" width="2rem" height="2rem" />
        <Stack gap="1rem" width="100%">
          <Skeleton variant="rounded" height="4rem" />
          <Skeleton variant="rounded" height="4rem" />
        </Stack>
      </Box>
    </Stack>
  );
};

export default CaseChartSkeleton;
