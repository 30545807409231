import {
  ALERT_NOTIFICATIONS_FAIL,
  ALERT_NOTIFICATIONS_REQUEST,
  ALERT_NOTIFICATIONS_SUCCESS,
  ALL_NOTIFICATIONS_FAIL,
  ALL_NOTIFICATIONS_REQUEST,
  ALL_NOTIFICATIONS_SUCCESS,
  CHAT_NOTIFICATIONS_FAIL,
  CHAT_NOTIFICATIONS_REQUEST,
  CHAT_NOTIFICATIONS_SUCCESS,
  DISABLE_APP_ACCESS_FAIL,
  DISABLE_APP_ACCESS_REQUEST,
  DISABLE_APP_ACCESS_SUCCESS,
  ENABLE_APP_ACCESS_FAIL,
  ENABLE_APP_ACCESS_REQUEST,
  ENABLE_APP_ACCESS_SUCCESS,
  GET_BODY_PARTS_FAIL,
  GET_BODY_PARTS_REQUEST,
  GET_BODY_PARTS_SUCCESS,
  GET_CASE_SUMMARY_LINK_FAIL,
  GET_CASE_SUMMARY_LINK_REQUEST,
  GET_CASE_SUMMARY_LINK_SUCCESS,
  GET_EXPENSE_DETAIL_FAIL,
  GET_EXPENSE_DETAIL_REQUEST,
  GET_EXPENSE_DETAIL_SUCCESS,
  MARK_NOTIFICATIONS_FAIL,
  MARK_NOTIFICATIONS_REQUEST,
  MARK_NOTIFICATIONS_SUCCESS,
  RESET_ALERT_NOTIFICATIONS,
  RESET_ALL_NOTIFICATIONS,
  RESET_APP_ACCESS_STATE,
  RESET_BODY_PARTS,
  RESET_CASE_SUMMARY_LINK,
  RESET_CHAT_NOTIFICATIONS,
  RESET_GET_EXPENSE_DETAIL,
  RESET_MARK_NOTIFICATIONS,
  RESET_SEND_PAIN_LOG_REMINDER,
  RESET_USER_ACTIVITY_NOTIFICATIONS,
  RESET_USER_FEED_NOTIFICATIONS,
  SEND_PAIN_LOG_REMINDER_FAIL,
  SEND_PAIN_LOG_REMINDER_REQUEST,
  SEND_PAIN_LOG_REMINDER_SUCCESS,
  USER_ACTIVITY_NOTIFICATIONS_FAIL,
  USER_ACTIVITY_NOTIFICATIONS_REQUEST,
  USER_ACTIVITY_NOTIFICATIONS_SUCCESS,
  USER_FEED_NOTIFICATIONS_FAIL,
  USER_FEED_NOTIFICATIONS_REQUEST,
  USER_FEED_NOTIFICATIONS_SUCCESS,
} from "../../assets/constants/appConstants";

// DISABLE APP ACCESS REDUCER
export const disableAppAccessReducer = (state = {}, action) => {
  switch (action.type) {
    case DISABLE_APP_ACCESS_REQUEST:
      return { loading: true };
    case DISABLE_APP_ACCESS_SUCCESS:
      return { loading: false, disabledApp: action.payload };
    case DISABLE_APP_ACCESS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_APP_ACCESS_STATE:
      return {};
    default:
      return state;
  }
};

// ENABLE APP ACCESS REDUCER
export const enableAppAccessReducer = (state = {}, action) => {
  switch (action.type) {
    case ENABLE_APP_ACCESS_REQUEST:
      return { loading: true };
    case ENABLE_APP_ACCESS_SUCCESS:
      return { loading: false, enabledApp: action.payload };
    case ENABLE_APP_ACCESS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_APP_ACCESS_STATE:
      return {};
    default:
      return state;
  }
};

// DELETE DOCUMENT REDUCER
const initDeleteDocumentState = {
  loading: false,
  deletedDocument: null,
  error: null,
};

// GET USER FEED NOTIFICATIONS REDUCER
export const getUserFeedNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_FEED_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case USER_FEED_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userFeeds: action.payload,
      };
    case USER_FEED_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_USER_FEED_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET ALL NOTIFICATIONS REDUCER
export const getAllNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALL_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case ALL_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        allNotifications: action.payload,
      };
    case ALL_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_ALL_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET CHAT NOTIFICATIONS REDUCER
export const getChatNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case CHAT_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case CHAT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        chatNotifications: action.payload,
      };
    case CHAT_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_CHAT_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET ALERT NOTIFICATIONS REDUCER
export const getAlertNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case ALERT_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case ALERT_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        alertNotifications: action.payload,
      };
    case ALERT_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_ALERT_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET USER ACTIVITY NOTIFICATIONS REDUCER
export const getUserActivityNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case USER_ACTIVITY_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case USER_ACTIVITY_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        userActivityNotifications: action.payload,
      };
    case USER_ACTIVITY_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_USER_ACTIVITY_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// MARK NOTIFICATIONS REDUCER
export const markAsReadNotificationsReducer = (state = {}, action) => {
  switch (action.type) {
    case MARK_NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case MARK_NOTIFICATIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        markedNotifications: action.payload,
      };
    case MARK_NOTIFICATIONS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_MARK_NOTIFICATIONS:
      return {};
    default:
      return state;
  }
};

// GET CASE SUMMARY LINK REDUCER
const initCaseSummaryState = {
  loading: false,
  error: null,
  caseSummaryPdfLink: {},
};
export const getCaseSummaryLinkReducer = (
  state = initCaseSummaryState,
  action
) => {
  switch (action.type) {
    case GET_CASE_SUMMARY_LINK_REQUEST:
      return { ...state, loading: true };
    case GET_CASE_SUMMARY_LINK_SUCCESS:
      return {
        ...state,
        loading: false,
        caseSummaryPdfLink: action.payload,
      };
    case GET_CASE_SUMMARY_LINK_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_CASE_SUMMARY_LINK:
      return { ...state, caseSummaryPdfLink: {} };
    default:
      return state;
  }
};

// GET BODY PARTS REDUCER
const initState = {
  loading: false,
  error: null,
  allBodyParts: null,
};
export const getBodyPartsReducer = (state = initState, action) => {
  switch (action.type) {
    case GET_BODY_PARTS_REQUEST:
      return { ...state, loading: true };
    case GET_BODY_PARTS_SUCCESS:
      return {
        ...state,
        loading: false,
        allBodyParts: action.payload,
      };
    case GET_BODY_PARTS_FAIL:
      return { ...state, loading: false, error: action.payload };
    case RESET_BODY_PARTS:
      return {};
    default:
      return state;
  }
};

// SEND MISSED PAIN LOG REMINDERS
export const sendPainLogReminderReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_PAIN_LOG_REMINDER_REQUEST:
      return { loading: true };
    case SEND_PAIN_LOG_REMINDER_SUCCESS:
      return {
        loading: false,
        sentReminder: action.payload,
      };
    case SEND_PAIN_LOG_REMINDER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_SEND_PAIN_LOG_REMINDER:
      return {};
    default:
      return state;
  }
};

// GET EXPENSES REDUCER
export const getExpensesDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_EXPENSE_DETAIL_REQUEST:
      return { loading: true };
    case GET_EXPENSE_DETAIL_SUCCESS:
      return {
        loading: false,
        expenses: action.payload,
      };
    case GET_EXPENSE_DETAIL_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_EXPENSE_DETAIL:
      return {};
    default:
      return state;
  }
};
