import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { dateTimeFormat, getNotificationText } from "../../utils/helpers";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => {
  return {
    all_container: {
      display: "flex",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "12px",
      borderRadius: "8px",
      padding: "16px",
    },
    lightText: {
      color: "rgba(0, 0, 0, 0.38)",
    },
    blueTextLine: {
      color: "#0C80F3",
      textDecoration: "underline",
      cursor: "pointer",
    },
    description: {
      color: "#566875",
      fontSize: "14px",
    },
  };
});

const ChatUserCard = ({ data, setOpenNotificationDrawer }) => {
  const classes = useStyles();

  const navigate = useNavigate();

  const displayText = getNotificationText(data, classes);

  return (
    <Box className={classes.all_container} bgcolor="#F4F6F8">
      <Avatar src={data?.client?.profile_picture_url} alt="name" />
      <Stack gap="8px">
        <Stack gap="4px">
          <Typography variant="body2" fontSize="16px">
            {data?.activity_performed_by}{" "}
            {data?.log_type === "chat_activity" ? (
              <>
                <span className={classes.lightText}>replied in </span>{" "}
                <span
                  className={classes.blueTextLine}
                  onClick={() => {
                    navigate(`/case/${data?.case_id}?tab=chat`);
                    setOpenNotificationDrawer((prevState) => !prevState);
                  }}
                >
                  chat
                </span>
              </>
            ) : (
              displayText
            )}
          </Typography>

          <Typography
            variant="caption"
            fontSize="12px"
            className={classes.lightText}
          >
            {dateTimeFormat(data.created_at)}
          </Typography>
        </Stack>
        {data?.log_type === "chat_activity" && (
          <Typography className={classes.description}>
            {data?.message_text}
          </Typography>
        )}

        {data?.log_type === "document_uploaded" && (
          <Typography
            className={classes.blueTextLine}
            onClick={() => {
              if (data?.case_id) {
                navigate(`/case/${data?.case_id}?tab=documents`);
                setOpenNotificationDrawer((prevState) => !prevState);
              }
            }}
          >
            {data?.document_title}
          </Typography>
        )}

        {data?.log_type === "appointment_update" &&
          data?.appointment_reason && (
            <Typography className={classes.description}>
              Reason: {data?.appointment_reason}
            </Typography>
          )}
      </Stack>
    </Box>
  );
};

export default ChatUserCard;
