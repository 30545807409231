import { makeStyles } from "@mui/styles";
import clsx from "clsx";
import Style from "./style.js";
import { Box } from "@mui/material";

const Styles = makeStyles((theme) => Style(theme));

const BgBox = ({ children }) => {
  const classes = Styles();
  return <Box className={clsx(classes.bgImage)}>{children}</Box>;
};

export default BgBox;
