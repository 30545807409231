import { Box, Divider, Stack, Typography, useMediaQuery } from "@mui/material";
import React from "react";
import HeaderText from "../../common/HeaderText";
import {
  dateTimeFormat,
  painDurationValues,
  painLevelBackgroundColor,
} from "../../utils/helpers";
import { makeStyles } from "@mui/styles";
import Ellipse from "../../icons/Ellipse";

const useStyles = makeStyles((theme) => {
  return {
    pain_level: {
      width: "16px",
      height: "16px",
      color: "#fff",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
    },
  };
});

const PainLogDetail = ({ index, bodyPart, data, filtered }) => {
  const classes = useStyles();
  const isTablet = useMediaQuery("(max-width:1325px)");

  const lastIndex = data?.body_parts_data?.length - 1;

  return (
    <Stack gap="4px" width="100%">
      {isTablet ? (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="8px"
        >
          <Box display="flex" alignItems="flex-start" gap="8px">
            <Stack gap="2px">
              <Typography
                variant="h6"
                fontSize="16px"
                color="#000"
                sx={{ textTransform: "capitalize" }}
              >
                {bodyPart?.side} {bodyPart?.name}
              </Typography>
              <Typography variant="body1" fontSize="14px" color="secondary">
                Pain Intensity :{" "}
                <Box
                  component="span"
                  className={classes.pain_level}
                  sx={{
                    background: painLevelBackgroundColor(bodyPart?.pain_scale),
                    padding: "10px",
                  }}
                >
                  {bodyPart?.pain_scale}
                </Box>
              </Typography>
            </Stack>
          </Box>
          <Stack gap="4px" alignItems="flex-end">
            {bodyPart?.loss_of_sleep && (
              <Box bgcolor="#FE7878" borderRadius="4px" padding="0 4px">
                <Typography variant="body2" color="#fff">
                  Caused Sleep loss
                </Typography>
              </Box>
            )}
            <HeaderText
              textContent={dateTimeFormat(data?.created_at)}
              color="secondary"
              variant="body1"
              fontSize="14px"
              fontWeight="400"
            />
          </Stack>
        </Box>
      ) : (
        <Box
          display="flex"
          alignItems="flex-start"
          justifyContent="space-between"
          gap="8px"
        >
          <Box display="flex" alignItems="flex-start" gap="8px">
            <Stack gap="2px">
              <Typography
                variant="h6"
                fontSize="16px"
                color="#000"
                sx={{ textTransform: "capitalize" }}
              >
                {bodyPart?.side} {bodyPart?.name}
              </Typography>
              <Typography variant="body1" fontSize="14px" color="secondary">
                Pain Intensity :{" "}
                <Box
                  component="span"
                  className={classes.pain_level}
                  sx={{
                    background: painLevelBackgroundColor(bodyPart?.pain_scale),
                    padding: "10px",
                  }}
                >
                  {bodyPart?.pain_scale}
                </Box>
              </Typography>
            </Stack>

            {bodyPart?.loss_of_sleep && (
              <Box display="flex" alignItems="center" gap="8px">
                <Ellipse />

                <Box bgcolor="#FE7878" borderRadius="4px" padding="0 4px">
                  <Typography variant="body2" color="#fff">
                    Caused Sleep loss
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box>
            <HeaderText
              textContent={dateTimeFormat(data?.created_at)}
              color="secondary"
              variant="body1"
              fontSize="14px"
              fontWeight="400"
            />
          </Box>
        </Box>
      )}

      {data?.duration && (
        <Typography variant="body2" color="secondary">
          Duration: {painDurationValues[data?.duration]}
        </Typography>
      )}

      <Box>
        <Typography fontSize="16px" variant="body1">
          {bodyPart?.description}
        </Typography>
      </Box>
      {index !== lastIndex && <Divider sx={{ margin: "12px 0" }} />}
    </Stack>
  );
};

export default PainLogDetail;
