import { Box, Grid, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import EditIcon from "../../icons/EditIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    case_onboard_card: {
      borderRadius: "4px",
      backgroundColor: "rgba(33, 150, 243, 0.15)",
      padding: "24px",
      height: "180px",
      cursor: "pointer",
      "&:hover": {
        border: "1px solid #0C80F3",
      },
    },
  };
});

const ClientIntakeCard = ({
  defaultCard,
  questionTitle,
  numOfQuestions,
  numOfDocuments,
  deletePresetModalHandler,
  editPresetHandler,
  preset_id,
}) => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={4}>
      <Box className={classes.case_onboard_card}>
        <Stack gap="16px">
          <Typography variant="body1" fontWeight="500">
            {questionTitle}
          </Typography>
          <Stack>
            <Typography variant="body2">
              {numOfQuestions === 1
                ? `${numOfQuestions} Question`
                : `${numOfQuestions} Questions`}{" "}
            </Typography>
            <Typography variant="caption" color="#566875">
              {numOfDocuments === 1
                ? `${numOfDocuments} Document`
                : `${numOfDocuments} Documents`}{" "}
            </Typography>
          </Stack>
          <Box display="flex" alignItems="center" gap="16px">
            <IconButton onClick={() => editPresetHandler(preset_id)}>
              <EditIcon />
            </IconButton>
            {!defaultCard && (
              <IconButton onClick={() => deletePresetModalHandler(preset_id)}>
                <DeleteIcon />
              </IconButton>
            )}
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
};

export default ClientIntakeCard;
