import React, { useState, useEffect, useRef, createRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Typography,
  Grid,
  Box,
  IconButton,
  Divider,
  TextField,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  Autocomplete,
  Icon,
  Button,
  Skeleton,
} from "@mui/material";

import Style from "./style";
// import Button from "../../../../common/Button";
import Textfield from "../../../../common/Textfield";
import CardUI from "../../../../common/Card";
import CircularProgressLoading from "../../../../common/CircularProgress";
import AlertMessage from "../../../../common/AlertMessage";
import ProfilePicture from "../../../../common/ProfilePicture";
import { AddLogoIcon, DeleteIcon } from "../../../../assets/constants";
import {
  getAgentProfile,
  resetUpdateAgentProfile,
  updateAgentProfile,
} from "../../../../actions/Agency";
import Cookies from "js-cookie";
import {
  clearContact,
  formatPhoneNumber,
  validateEmail,
} from "../../../../utils/helpers";
import { statesWithCities } from "../../../../utils/helpers/states_with_cities";
import DialogOverlay from "../../../../components/DialogOverlay";
import ProfileModal from "../../../../components/ProfileModal";
import companyLogo from "../../../../assets/images/company-logo.jpg";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";

import CancelOutlined from "@mui/icons-material/CancelOutlined";
import ZoominIcon from "../../../../icons/ZoominIcon";
import ZoomoutIcon from "../../../../icons/ZoomoutIcon";
import SkeletonGrid from "../../../../components/SkeletonGrid";
import { LoadingButton } from "@mui/lab";
import GeneralSkeleton from "../../../../components/GeneralSkeleton";

const states = Object.keys(statesWithCities);

const Styles = makeStyles((theme) => Style(theme));

export default function PersonalDetails() {
  const classes = Styles();

  // var userInfo = localStorage.getItem("userInfo");

  const dispatch = useDispatch();
  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  // const userLogin = useSelector((state) => state.userLogin);
  // const { userInfo } = userLogin;

  // updateAgentProfile
  const agentUpdateProfile = useSelector((state) => state.agentUpdateProfile);
  const {
    loading: loadingUpdateAgent,
    error: errorUpdateAgent,
    agentInfo,
  } = agentUpdateProfile;

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  const agentToken = Cookies.get("token");
  const cookieAgentId = Cookies.get("agentId");
  const cookieAgencyId = Cookies.get("agencyId");

  const [uploading, setUploading] = useState(false);
  useEffect(() => {
    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState(null); // state/region
  const [country, setCountry] = useState("United States of America");
  const [zipCode, setZipCode] = useState("");
  const [profilePictureFilename, setProfilePictureFilename] = useState("");
  const [profileSizeError, setProfileSizeError] = useState(null);
  const [profilePictureURL, setProfilePictureURL] = useState(
    "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
  );
  const [emailError, setEmailError] = useState(false);
  const [openImageModal, setOpenImageModal] = useState(false);
  const [profileImage, setProfileImage] = useState(
    "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
  );

  const [alertOpen, setAlertOpen] = useState(false);

  const hiddenFileInputRef = useRef();
  const cropperRef = useRef(null);
  // const [cropper, setCropper] = useState();
  // const [croppedImage, setCroppedImage] = useState(null);
  // const handleAlertClick = () => {
  //   setAlertOpen(true);
  // };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  useEffect(() => {
    dispatch(getAgentProfile());
  }, [agentInfo]);

  useEffect(() => {
    if (agentID) {
      setFirstName(
        agentUser?.agent?.first_name ? agentUser?.agent?.first_name : ""
      );
      setLastName(
        agentUser?.agent?.last_name ? agentUser?.agent?.last_name : ""
      );
      setEmail(agentUser?.agent?.email ? agentUser?.agent?.email : "");
      setContactNumber(
        agentUser?.agent?.contact_number ? agentUser?.agent?.contact_number : ""
      );
      setAddress(agentUser?.agent?.address ? agentUser?.agent?.address : "");
      setCity(agentUser?.agent?.city ? agentUser?.agent?.city : "");
      setRegion(agentUser?.agent?.region ? agentUser?.agent?.region : null);
      setCountry("United States of America");
      setZipCode(agentUser?.agent?.zipcode ? agentUser?.agent?.zipcode : "");
      setProfilePictureFilename(
        agentUser?.agent?.profile_picture_filename
          ? agentUser?.agent?.profile_picture_filename
          : ""
      );

      if (agentUser?.agent?.profile_picture_filename) {
        setProfilePictureURL(agentUser?.agent?.profile_picture_url);
      } else {
        setProfilePictureURL(
          "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
        );
      }
    }
  }, [agentUser]);

  const handleSubmit = () => {
    // e.preventDefault();

    const agentData = {
      agent_id: agentID,
      agent_profile: {
        first_name: firstName,
        last_name: lastName,
        email,
        contact_number: contactNumber,
        address,
        city,
        country,
        region,
        zipcode: zipCode,
      },
    };

    dispatch(updateAgentProfile(agentData));
  };

  useEffect(() => {
    if (agentInfo?.ok) {
      setAlertOpen(true);

      dispatch(resetUpdateAgentProfile());
    }
  }, [agentInfo]);

  // useEffect(() => {
  //   handleSubmit();
  // }, [profilePictureURL]);

  const handleFileClick = () => {
    hiddenFileInputRef.current.click();
  };

  const closeImageModal = () => {
    setOpenImageModal(false);
  };

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    const fileType = file?.type;

    if (file && file?.size > 5 * 1024 * 1024) {
      setProfileSizeError("File size cannot exceed more than 5MB.");
      setAlertOpen(true);
      setProfilePictureURL(
        "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
      );
      // setSelectedFileError("File size cannot exceed more than 2MB");
      return;
    }

    if (
      fileType !== "image/jpeg" &&
      fileType !== "image/png" &&
      fileType !== "image/jpg"
    ) {
      setProfileSizeError("Only png and jpg images are allowed!");
      setAlertOpen(true);
      return;
    }

    //
    setOpenImageModal(true);
    setProfileImage(URL.createObjectURL(file));

    // const fileData = new FormData();
    // fileData.append("file", file);
    // fileData.append("file_type", fileType);
    // fileData.append("agency_id", agencyID);

    // try {
    //   setUploading(true);
    //   const config = {
    //     headers: {
    //       "Content-type": "multipart/form-data",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   };

    //   const res = await axios.post(
    //     `${process.env.REACT_APP_BASE_URL}/agent/upload-file`,
    //     fileData,
    //     config
    //   );

    //   // if (imageUrl && !res?.data?.ok) {
    //   //   console.log("HERE");
    //   //   setImageName(imageName);
    //   //   setImageUrl(imageUrl);
    //   // }

    //   // console.log("RESSSS", res);

    //   if (!res?.data?.ok) {
    //     setProfilePictureFilename("");
    //     setProfilePictureURL(
    //       "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
    //     );
    //     setUploading(false);
    //     return;
    //   }

    //   setProfilePictureFilename(res?.data?.filename);
    //   setProfilePictureURL(res?.data?.file_url);
    //   setUploading(false);
    //   setAlertOpen(true);

    //   const agentData = {
    //     agent_id: agentID,
    //     agent_profile: {
    //       profile_picture_filename: res?.data?.filename,
    //     },
    //   };

    //   dispatch(updateAgentProfile(agentData));
    // } catch (error) {
    //   console.log(error);
    //   // setSelectedFileError(
    //   //   error.response && error.response.data.message
    //   //     ? error.response.data.message
    //   //     : error.message
    //   // );
    //   setUploading(false);
    // } finally {
    //   setProfileSizeError(null);
    // }
  };

  const onCrop = () => {
    const cropper = cropperRef?.current?.cropper;
  };

  const saveImageHandler = async () => {
    if (
      typeof cropperRef?.current?.cropper?.getCroppedCanvas() === "undefined"
    ) {
      return;
    }
    cropperRef?.current?.cropper
      ?.getCroppedCanvas({ fillColor: "#fff" })
      ?.toBlob(async (blob) => {
        const croppedImageFile = new File([blob], "croppedImage.jpg", {
          type: "image/jpeg",
        });
        // setCroppedImage(croppedImageFile);

        const fileData = new FormData();
        fileData.append("file", croppedImageFile);
        fileData.append("file_type", "image/jpeg");
        fileData.append("agency_id", agencyID);

        try {
          setUploading(true);
          const config = {
            headers: {
              "Content-type": "multipart/form-data",
              Authorization: `Bearer ${token}`,
            },
          };

          const res = await axios.post(
            `${process.env.REACT_APP_BASE_URL}/agent/upload-file`,
            fileData,
            config
          );

          // if (imageUrl && !res?.data?.ok) {
          //   console.log("HERE");
          //   setImageName(imageName);
          //   setImageUrl(imageUrl);
          // }

          // console.log("RESSSS", res);

          if (!res?.data?.ok) {
            setProfilePictureFilename("");
            setProfilePictureURL(
              "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
            );
            setUploading(false);
            return;
          }

          setProfilePictureFilename(res?.data?.filename);
          setProfilePictureURL(res?.data?.file_url);
          setUploading(false);

          const agentData = {
            agent_id: agentID,
            agent_profile: {
              profile_picture_filename: res?.data?.filename,
            },
          };

          dispatch(updateAgentProfile(agentData));
          setOpenImageModal(false);
        } catch (error) {
          console.log(error);
          // setSelectedFileError(
          //   error.response && error.response.data.message
          //     ? error.response.data.message
          //     : error.message
          // );
          setUploading(false);
        } finally {
          setProfileSizeError(null);
        }
      }, "image/png");
  };

  const deleteFileHandler = () => {
    setProfilePictureFilename("");
    setProfilePictureURL(
      "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
    );

    const agentData = {
      agent_id: agentID,
      agent_profile: {
        profile_picture_filename: "",
      },
    };

    dispatch(updateAgentProfile(agentData));
  };

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;

    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);

    if (!emailIsValid) {
      setEmailError(true);
      return;
    }

    setEmailError(false);
  };

  const handleZipCodeChange = (e) => {
    // Only update if the input is a number or empty (to allow deletion)
    if (/^\d*$/g.test(e.target.value)) {
      setZipCode(e.target.value);
    }
  };

  const contactNumberHandler = (e) => {
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setContactNumber(formattedNumber);
  };

  // RESET CHANGES HANDLER
  const resetChangesHandler = (e) => {
    e.preventDefault();

    setFirstName(
      agentUser?.agent?.first_name ? agentUser?.agent?.first_name : ""
    );
    setLastName(agentUser?.agent?.last_name ? agentUser?.agent?.last_name : "");
    setEmail(agentUser?.agent?.email ? agentUser?.agent?.email : "");
    setContactNumber(
      agentUser?.agent?.contact_number ? agentUser?.agent?.contact_number : ""
    );
    setAddress(agentUser?.agent?.address ? agentUser?.agent?.address : "");
    setCity(agentUser?.agent?.city ? agentUser?.agent?.city : "");
    setRegion(agentUser?.agent?.region ? agentUser?.agent?.region : null);
    setZipCode(agentUser?.agent?.zipcode ? agentUser?.agent?.zipcode : "");
  };

  const handleZoomIn = () => {
    const cropper = cropperRef.current.cropper;
    cropper.zoom(0.1); // You can adjust the zoom factor as needed
  };

  const handleZoomOut = () => {
    const cropper = cropperRef.current.cropper;
    cropper.zoom(-0.1); // Negative value zooms out, adjust the value as needed
  };

  return (
    <>
      {alertOpen && (
        <AlertMessage
          message="Your changes have been saved successfully!"
          severity="success"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingUpdateAgent && profileSizeError && (
        <AlertMessage
          message={profileSizeError}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {openImageModal && (
        <DialogOverlay open={openImageModal} height="587px">
          <ProfileModal
            headerTitle="Crop Profile Picture"
            closeFormDialogHandler={closeImageModal}
            saveImageHandler={saveImageHandler}
            uploading={uploading}
          >
            <Box
              position="relative"
              height="100%"
              sx={{
                overflow: "hidden",
                ".cropper-crop-box, .cropper-view-box": {
                  borderRadius: "50%",
                  ".cropper-modal": {
                    backgroundColor: "#000 !important",
                  },
                  // ".cropper-view-box": {
                  //   outline: "none !important",
                  // },
                  ".cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line":
                    {
                      display: "none !important",
                    },
                  ".cropper-face": {
                    backgroundColor: "inherit !important",
                  },
                },
              }}
            >
              {profileImage && (
                <Cropper
                  ref={cropperRef}
                  style={{
                    height: "100% !important",
                    width: "100%",
                  }}
                  zoomTo={0.5}
                  initialAspectRatio={1}
                  aspectRatio={1}
                  preview=".img-preview"
                  src={profileImage}
                  viewMode={1}
                  minCropBoxHeight={10}
                  minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  autoCropArea={1}
                  checkOrientation={false}
                  // onInitialized={(instance) => {
                  //   setCropper(instance);
                  // }}
                  guides={true}
                  crop={onCrop}
                />
              )}
              <Box
                display="flex"
                position="absolute"
                bottom="18.5px"
                left="23px"
                gap="4px"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  size="small"
                  disableRipple
                  onClick={handleZoomIn}
                >
                  <Icon sx={{ width: "100%", height: "100%" }}>
                    <ZoominIcon />
                  </Icon>
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  disableRipple
                  onClick={handleZoomOut}
                >
                  <Icon sx={{ width: "100%", height: "100%" }}>
                    <ZoomoutIcon />
                  </Icon>
                </Button>
              </Box>
            </Box>
          </ProfileModal>
        </DialogOverlay>
      )}

      {/* {loadingAgentProfile ? (
        <SkeletonGrid itemCount={2} spacing={2} secondRowHeight={[600, 250]} />
      ) : ( */}
      <Grid
        container
        spacing={1}
        className={classes.general__settings__main}
        width="100%"
      >
        <Grid
          item
          // columnSpacing={3}
          gap={3}
          xs={12}
          md={8}
          className={classes.grid_column}
        >
          <CardUI responsive="true" dashboard="true" maxWidth="100%" padding="32px">
            <Stack
              // onSubmit={handleSubmit}
              direction="column"
              gap="24px"
            >
              <Typography
                variant="boxHeading"
                textTransform="uppercase"
                color="secondary"
              >
                Personal Information
              </Typography>

              {loadingAgentProfile ? (
                <GeneralSkeleton />
              ) : (
                <>
                  {" "}
                  <Box display="flex" gap="24px">
                    <Textfield
                      placeholder="Henry"
                      label="First Name"
                      variant="outlined"
                      name="firstName"
                      width="100%"
                      required
                      // error={isTouched && !!error}
                      // helperText={error?.message}
                      // margin="dense"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                    />
                    <Textfield
                      placeholder="Arthur"
                      label="Last Name"
                      variant="outlined"
                      name="lastName"
                      width="100%"
                      // margin="dense"
                      // error={isTouched && !!error}
                      // helperText={error?.message}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      required
                    />
                  </Box>
                  <Box display="flex" gap="24px">
                    <Textfield
                      placeholder="Henryarthur@abclawfirm.com"
                      label="Email Address"
                      variant="outlined"
                      name="email"
                      width="100%"
                      // margin="dense"
                      // error={isTouched && !!error}
                      helperText={emailError && "Please enter valid email"}
                      errorContent={emailError && "Please enter valid email"}
                      value={email}
                      onChange={emailChangeHandler}
                      readOnly
                    />
                    <Textfield
                      placeholder="+1 7776665555"
                      label="Contact Number"
                      variant="outlined"
                      name="contactNumber"
                      width="100%"
                      // error={isTouched && !!error}
                      // helperText={error?.message}
                      // margin="dense"
                      value={contactNumber}
                      onChange={contactNumberHandler}
                    />
                  </Box>
                  <Box display="flex" gap="24px">
                    <Textfield
                      placeholder="90210 Broadway Blvd"
                      label="Address"
                      variant="outlined"
                      name="address"
                      fullWidth
                      width="100%"
                      // margin="dense"
                      // error={isTouched && !!error}
                      // helperText={error?.message}
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                    />
                  </Box>
                  <Box display="flex" gap="24px">
                    {/* <TextField
                    placeholder="United States of America"
                    margin="dense"
                    label="Country"
                    variant="outlined"
                    name="country"
                    // error={isTouched && !!error}
                    // helperText={error?.message}
                    value="United States of America"
                    fullWidth
                    InputProps={{
                      readOnly: true,
                      style: {
                        backgroundColor: "transparent",
                        border: "none",
                        color: "black",
                        fontSize: "1em",
                      },
                    }}
                    // onChange={(e) => setCountry(e.target.value)}
                  /> */}

                    <Textfield
                      placeholder="San Mateo"
                      label="City"
                      variant="outlined"
                      name="city"
                      fullWidth
                      width="100%"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      // margin="dense"
                      // error={isTouched && !!error}
                      // helperText={error?.message}
                    />
                    <Autocomplete
                      options={states}
                      fullWidth
                      value={region}
                      onChange={(event, newValue) => setRegion(newValue)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="State/Region"
                          margin="dense"
                          variant="outlined"
                          fullWidth
                        />
                      )}
                      sx={{ marginTop: -1 }}
                    />
                  </Box>
                  <Box display="flex" gap="24px" width="48%">
                    <Textfield
                      placeholder="91000"
                      label="Zip Code"
                      variant="outlined"
                      name="zipcode"
                      width="100%"
                      value={zipCode}
                      onChange={handleZipCodeChange}
                      fullWidth
                      // margin="dense"
                      // error={isTouched && !!error}
                      // helperText={error?.message}
                    />
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-end"
                    gap="16px"
                  >
                    <Button
                      color="primary"
                      onClick={resetChangesHandler}
                      name="reset-changes"
                      fontSize="14px"
                    >
                      Reset Changes
                    </Button>
                    <LoadingButton
                      disabled={!firstName || !email || emailError}
                      color="primary"
                      variant="contained"
                      loading={loadingUpdateAgent}
                      // type="submit"
                      onClick={handleSubmit}
                    >
                      Save Changes
                    </LoadingButton>
                  </Box>
                </>
              )}
            </Stack>
          </CardUI>
        </Grid>

        <Grid item xs={12} md={4} className={classes.grid_column}>
          <CardUI maxWidth="347px" responsive="true" dashboard="true" padding="32px">
            <Box display="flex" flexDirection="column" gap="8px">
              <Typography
                variant="boxHeading"
                color="secondary"
                textTransform="uppercase"
              >
                Profile picture
              </Typography>
              {loadingAgentProfile ? (
                <Box display="flex" alignItems="center" justifyContent="center">
                  <Skeleton variant="circular" width={200} height={200} />
                </Box>
              ) : (
                <>
                  {" "}
                  <Stack className={classes.banner__withName}>
                    <Stack alignItems="center">
                      {uploading && (
                        <SkeletonGrid itemCount={1} height={180} md={12} />
                      )}

                      {!uploading && (
                        <Box position="relative">
                          <ProfilePicture
                            src={profilePictureURL}
                            alt="user profile"
                            width="200px"
                            height="200px"
                          />

                          <Stack
                            className="overlay"
                            direction="row"
                            alignItems="center"
                            justifyContent="center"
                            spacing={1}
                          >
                            <Stack
                              alignItems="center"
                              width="50%"
                              sx={{ cursor: "pointer" }}
                              onClick={handleFileClick}
                            >
                              <IconButton
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                              >
                                <AddLogoIcon />
                              </IconButton>
                              <Typography
                                variant="h6"
                                color="primary.light"
                                sx={{ fontWeight: 400, fontSize: "12px" }}
                              >
                                Upload New
                              </Typography>
                              <input
                                accept="image/png, image/jpg, image/jpeg"
                                type="file"
                                ref={hiddenFileInputRef}
                                style={{ display: "none" }}
                                onChange={handleFileChange}
                                id="document-input"
                              />
                            </Stack>
                            <Divider
                              orientation="vertical"
                              variant="middle"
                              flexItem
                              sx={{
                                backgroundColor: "primary.light",
                                height: "50%",
                                margin: "auto 0 !important",
                              }}
                            />
                            <Stack
                              alignItems="center"
                              width="50%"
                              sx={{ cursor: "pointer", margin: "0 !important" }}
                              onClick={deleteFileHandler}
                            >
                              <IconButton
                                disableRipple
                                disableFocusRipple
                                disableTouchRipple
                              >
                                <DeleteIcon />
                              </IconButton>
                              <Typography
                                variant="h6"
                                color="primary.light"
                                sx={{ fontWeight: 400, fontSize: "12px" }}
                              >
                                Delete
                              </Typography>
                            </Stack>
                          </Stack>
                        </Box>
                      )}
                    </Stack>
                  </Stack>
                  <Typography
                    variant="body1"
                    color="secondary"
                    textAlign="center"
                  >
                    jpeg, png max 5MB
                  </Typography>
                </>
              )}
            </Box>
          </CardUI>
        </Grid>
      </Grid>
      {/* )} */}
    </>
  );
}
