import React from "react";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { LoadingButton } from "@mui/lab";

const ProfileModal = ({
  headerTitle,
  closeFormDialogHandler,
  uploading,
  children,
  saveImageHandler,
}) => {
  return (
    <>
      <DialogTitle
        sx={{
          m: 0,
          p: "24px 32px",
          background: "#fff",
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="24px" variant="h2" fontWeight="400">
            {headerTitle}
          </Typography>
          <IconButton aria-label="close" onClick={closeFormDialogHandler}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ padding: "0 !important" }}>{children}</DialogContent>

      <DialogActions
        sx={{
          padding: "16px 32px",
          boxShadow: "0px -2px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Button variant="text" onClick={closeFormDialogHandler}>
          Cancel
        </Button>
        <LoadingButton
          onClick={saveImageHandler}
          loading={uploading}
          variant="contained"
        >
          Save
        </LoadingButton>
      </DialogActions>
    </>
  );
};

export default ProfileModal;
