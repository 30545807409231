import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const renderSkeletonItems = () => {
  return Array(3)
    .fill()
    .map((_, index) => (
      <Box display="flex" gap="1rem" key={index}>
        <Skeleton variant="rounded" width="2rem" height="2rem" />
        <Stack gap="1rem" width="100%">
          <Skeleton variant="rounded" height="4.75rem" />
        </Stack>
      </Box>
    ));
};

const CaseLogsSkeleton = () => {
  return <Stack gap="1rem">{renderSkeletonItems()}</Stack>;
};

export default CaseLogsSkeleton;
