import React from "react";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  InputLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CircularProgressLoading from "../../common/CircularProgress";
import Style from "./style";
import { AddLogoIcon, DeleteIcon } from "../../assets/constants";
import { makeStyles, styled } from "@mui/styles";
import Textfield from "../../common/Textfield";

const Styles = makeStyles((theme) => Style(theme));

const CustomTextField = styled("div")({
  width: 181,
  height: 242,
});

const CustomImage = styled("img")({
  height: "100%",
  width: "auto",
  maxWidth: "100%",
});

const maxCharTextField1 = 33;
const maxCharTextField2 = 60;
const maxCharTextField3 = 44;

const CreateBannerTemplate = ({
  uploading,
  imageUrl,
  hiddenFileInputRef,
  handleFileChange,
  handleFileClick,
  deleteFileHandler,
  name,
  agencyName,
  setAgencyName,
  tagline,
  setAgencyTagline,
  agencyTagline,
  website,
  agencyWebsite,
  setAgencyWebsite,
  setChangesMade,
  isTouched,
  setIsTouched,
}) => {
  const classes = Styles();

  return (
    <Box
      display="flex"
      // alignItems="center"
      gap="24px"
      width="100%"
      className={classes.fields__space}
    >
      <Stack gap="2px">
        <Stack className={classes.banner_container} justifyContent="flex-end">
          <Stack
            className="overlay"
            direction="row"
            alignItems="center"
            justifyContent="center"
            spacing={1}
            // zIndex="99"
          >
            <Stack
              alignItems="center"
              width="50%"
              sx={{ cursor: "pointer" }}
              onClick={handleFileClick}
            >
              <IconButton>
                <AddLogoIcon />
              </IconButton>
              <Typography
                variant="h6"
                fontSize="12px"
                color="primary.light"
                sx={{ fontWeight: 400 }}
              >
                Upload New
              </Typography>
            </Stack>
            <Divider
              className={classes.divider}
              orientation="vertical"
              sx={{
                backgroundColor: "primary.light",
                height: "74px",
              }}
            />

            <Stack
              onClick={deleteFileHandler}
              alignItems="center"
              width="50%"
              sx={{ cursor: "pointer" }}
            >
              <IconButton>
                <DeleteIcon />
              </IconButton>
              <Typography
                variant="h6"
                fontSize="12px"
                color="primary.light"
                sx={{ fontWeight: 400 }}
              >
                Delete
              </Typography>
            </Stack>
          </Stack>
          <Box
            height="200px"
            overflow="hidden"
            sx={{
              borderBottomRightRadius: "4px",
              borderBottomLeftRadius: "4px",
            }}
          >
            <img
              src={imageUrl}
              alt="headshot image"
              width="100%"
              height="100%"
            />
          </Box>
          <Box
            position="absolute"
            top="0"
            left="16px"
            padding="0 8px"
            backgroundColor="#fff"
            sx={{ transform: "translate(0, -50%)" }}
          >
            <Typography variant="body2" fontSize="12px">
              Upload your headshot
            </Typography>
          </Box>
          <input
            accept="image/png, image/jpg, image/jpeg"
            type="file"
            ref={hiddenFileInputRef}
            style={{ display: "none" }}
            onChange={handleFileChange}
            id="document-input"
          />
        </Stack>
        <Typography variant="body2">jpg, png only</Typography>
        {/* <TextField
          className={classes.img__upload__textfield}
          label="Upload your Headshot"
          helperText="jpg, png max 5MB"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <input
                  accept="image/png, image/jpg, image/jpeg"
                  type="file"
                  ref={hiddenFileInputRef}
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                  id="document-input"
                />
                {uploading && (
                  <CircularProgressLoading size="24px" color="primary" />
                )}
                {!uploading && imageUrl && (
                  <label htmlFor="document-input">
                    <img
                      src={imageUrl}
                      alt="Headshot"
                      className={classes.headshot_img}
                    />
                  </label>
                )}
              </InputAdornment>
            ),
          }}
        /> */}
      </Stack>
      {/* <CustomTextField>
        <TextField
          fullWidth
          variant="outlined"
          label="Your Label"
          InputLabelProps={{ shrink: true }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CustomImage src={imageUrl} alt="Image" />
              </InputAdornment>
            ),
          }}
        />
      </CustomTextField> */}

      {/* BRANDING FORM */}
      <Stack width="100%" gap="24px" flex="1">
        <TextField
          placeholder={name}
          label={name}
          variant="outlined"
          name="agencyName"
          value={agencyName}
          error={!agencyName && isTouched}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= maxCharTextField1) {
              setAgencyName(value);
              setChangesMade(true);
              setIsTouched(true);
            }
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="end"
                sx={{ fontSize: "12px !important" }}
              >
                {agencyName?.length}/33
              </InputAdornment>
            ),
          }}
        />
        <Textfield
          placeholder={tagline}
          // error={isTouched && !!error}
          // helperText={error?.message}

          label={tagline}
          variant="outlined"
          name="agencyTagline"
          value={agencyTagline}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= maxCharTextField2) {
              setAgencyTagline(value);
              setChangesMade(true);
            }
          }}
          endAdornment={
            <InputAdornment position="end" sx={{ fontSize: "12px !important" }}>
              {agencyTagline?.length}/60
            </InputAdornment>
          }
        />
        <Textfield
          placeholder={website}
          // error={isTouched && !!error}
          // helperText={error?.message}
          label={website}
          variant="outlined"
          name="agencyWebsite"
          value={agencyWebsite}
          onChange={(e) => {
            const value = e.target.value;
            if (value.length <= maxCharTextField3) {
              setAgencyWebsite(value);
              setChangesMade(true);
            }
          }}
          endAdornment={
            <InputAdornment
              // style={{ fontSize: "12px !important" }}
              position="end"
              sx={{ fontSize: "12px !important" }}
            >
              {agencyWebsite?.length}/44
            </InputAdornment>
          }
        />
      </Stack>
    </Box>
  );
};

export default CreateBannerTemplate;
