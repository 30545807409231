import React, { useEffect, useState } from "react";

import clsx from "clsx";
import { makeStyles } from "@mui/styles";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  Autocomplete,
  Box,
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  Stack,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import Style from "./style";
import Textfield from "../../../../common/Textfield";
import Button from "../../../../common/Button";
import Dropdown from "../../../../common/Dropdown";
import CheckBoxrow from "../../../../common/checkBoxRow";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../../common/AlertMessage";
import Cookies from "js-cookie";
import {
  getAgentProfile,
  getAgencyDetailFunc,
} from "../../../../actions/Agency";
import { validateEmail } from "../../../../utils/helpers";
import TeamInfoIcon from "../../../../components/TeamInfoIcon";
import { segmentTrack } from "../../../../utils/helpers/tracking_utils";

const Styles = makeStyles((theme) => Style(theme));

const userTypes = ["Admin", "Lawyer", "Paralegal"];

const keyLabelConfig = {
  access_client_details: "Client",
  access_case_details: "Case",
  access_agent_details: "Team Member",
  access_push_notifications: "Push Notififications",
  update_branding: "Update Mobile App Branding",
  update_lawfirm_settings: "Update Lawfirm Settings",
  update_case_settings: "Update Case Settings",
};

export default function InviteDrawer({
  anchor,
  open,
  onClose,
  getAgentsList,
  update,
  setUpdate,
  agent,
  status,
  setStatus,
}) {
  const classes = Styles();

  // var userInfo = localStorage.getItem("userInfo");

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentProfile,
    error: errorAgentProfile,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [userType, setUserType] = useState(userTypes[0]);
  const [chatWithClients, setChatWithClients] = useState(false);
  const [administration, setAdministration] = useState(false);
  const [requestDocumentClient, setRequestDocumentClient] = useState(true);
  const [permissions, setPermissions] = useState({
    access_case_details: {
      create: true,
      read: true,
      edit: true,
      delete: true,
    },
    access_client_details: {
      create: true,
      read: true,
      edit: true,
      delete: true,
    },
    access_agent_details: {
      create: true,
      read: true,
      edit: true,
      delete: true,
    },
    access_push_notifications: {
      create: true,
      read: true,
      edit: true,
      delete: true,
    },
    update_lawfirm_settings: true,
    update_case_settings: true,
    update_branding: true,
  });

  // console.log("Permissions", permissions);
  // console.log("AGENT PERMISSSIONS", JSON.parse(agent?.permissions));
  // console.log("AGENT", agent);

  useEffect(() => {
    if (update) {
      setFirstName(agent?.first_name);
      setLastName(agent?.last_name);
      setFullName(agent?.name);
      setEmail(agent?.email);
      setUserType(agent?.role);
      setPermissions(agent?.permissions);
      // setChatWithClients(agent);
    }
  }, [open]);

  const agentToken = Cookies.get("token");
  const cookieAgentId = Cookies.get("agentId");
  const cookieAgencyId = Cookies.get("agencyId");

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    //   setAgencyID(userInfo.agency_id);
    //   setAgentID(userInfo.agent_id);
    // }

    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;

    setEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);

    if (!emailIsValid) {
      setEmailError(true);
      return;
    }

    setEmailError(false);
  };

  const inviteAgentHandler = async (e) => {
    e.preventDefault();

    setStatus({
      type: "",
      message: "",
    });

    setLoading(true);

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      if (update) {
        // UPDATING EXISTING AGENT
        const agentData = {
          agent_id: agent?.id,
          // send_credentials: sendCredentials,
          agent_profile: {
            first_name: firstName,
            last_name: lastName,
            name: firstName + " " + lastName,
            email,
            role: userType,
            permissions: permissions,
            admin: administration,
            chat_with_clients: chatWithClients,
            request_documents: requestDocumentClient,
          },
        };

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agent/update`,
          agentData,
          config
        );

        if (!res?.data?.ok) {
          throw Error(res?.data?.message);
        }

        setStatus({
          type: "success",
          message: "Agent details updated!",
        });

        getAgentsList();
        dispatch(getAgentProfile());

        // console.log(agentData);
        // setUpdate(false);

        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "member_updated",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      } else {
        // ADD NEW AGENT
        const agentData = {
          agency_id: agencyID,
          // send_credentials: sendCredentials,
          agent_obj: {
            first_name: firstName,
            last_name: lastName,
            name: firstName + " " + lastName,
            email,
            role: userType,
            permissions: permissions,
            admin: administration,
            chat_with_clients: chatWithClients,
            request_documents: requestDocumentClient,
          },
        };

        // console.log(agentData);

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agent/invite-agent`,
          agentData,
          config
        );

        if (!res?.data?.ok) {
          throw Error(res?.data?.message);
        }

        getAgentsList();
        setStatus({
          type: "success",
          message: "Agent invite sent!",
        });

        setFirstName("");
        setLastName("");
        setEmail("");
        setPermissions({
          access_case_details: {
            create: true,
            read: true,
            edit: true,
            delete: true,
          },
          access_client_details: {
            create: true,
            read: true,
            edit: true,
            delete: true,
          },
          access_agent_details: {
            create: true,
            read: true,
            edit: true,
            delete: true,
          },
          access_push_notifications: {
            create: true,
            read: true,
            edit: true,
            delete: true,
          },
          update_lawfirm_settings: true,
          update_case_settings: true,
          update_branding: true,
        });
        // setChatWithClients(false);
        // setRequestDocumentClient(true);

        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "new_member_created",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      }

      setLoading(false);
      onClose(false);
      setUpdate(false);
      setAlertOpen(true);
    } catch (error) {
      setStatus({
        type: "error",
        message:
          error.data && error.data.message ? error.data.message : error.message,
      });
      setLoading(false);
      // onClose(false);
      setAlertOpen(true);
      // console.log(error);
    }
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  return (
    <>
      {!loading && (status?.type === "success" || status?.type === "error") && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      <Drawer
        anchor={anchor}
        open={open}
        onClose={() => {
          onClose();
          setUpdate(false);
        }}
      >
        <Stack
          p={4}
          className={classes.drawer__width}
          gap={3}
          component="form"
          onSubmit={inviteAgentHandler}
          height="100%"
        >
          <Stack gap={3} flex="1" sx={{ overflowY: "auto" }}>
            <Stack
              justifyContent="space-between"
              direction="row"
              alignItems="center"
            >
              <Typography variant="h5">
                {update ? "Edit Team Member" : "Invite Team Members"}
              </Typography>
              <IconButton size="small" onClick={onClose}>
                <CancelOutlinedIcon />
              </IconButton>
            </Stack>
            <Typography
              variant="boxHeading"
              color="disabled.boxHeading"
              sx={{ textTransform: "uppercase" }}
            >
              new team member’s information
            </Typography>
            <Stack
              direction="row"
              width="100%"
              justifyContent="space-between"
              className={clsx(classes.fields__space)}
              spacing={2}
            >
              <Textfield
                placeholder="Enter First Name"
                // error={isTouched && !!error}
                // helperText={error?.message}
                width="100%"
                label="First Name"
                variant="outlined"
                name="firstName"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                // value={value || ''}
                // onChange={onChange}
              />

              <Textfield
                placeholder="Enter Last Name"
                label="Last Name"
                variant="outlined"
                width="100%"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                // error={isTouched && !!error}
                // helperText={error?.message}
                // value={value || ''}
                // onChange={onChange}
              />
            </Stack>
            <Stack>
              <Textfield
                placeholder="Enter Email"
                margin="dense"
                label="Email Address"
                variant="outlined"
                name="email"
                value={email}
                onChange={emailChangeHandler}
                errorContent={emailError && "Enter valid email"}
                helperText={emailError && "Enter valid email"}
                readOnly={update && true}
                // onChange={onChange}
              />
            </Stack>
            <Typography
              variant="h4"
              color="disabled.customs"
              sx={{ fontWeight: 400 }}
            >
              Auto-generated password shared on invite, changeable after first
              login.
            </Typography>

            <Autocomplete
              id="userType"
              options={userTypes}
              value={userType}
              // freeSolo
              onChange={(e, value) => {
                setUserType(value);
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" label="User Type" />
              )}
            />

            {/* <Dropdown label="City" /> */}
            <Divider />

            <Typography
              variant="boxHeading"
              color="disabled.boxHeading"
              sx={{ textTransform: "uppercase" }}
            >
              Permissions Manager
            </Typography>

            <Stack>
              <Stack>
                <Stack justifyContent="space-between" direction="row">
                  <Typography
                    variant="h4"
                    color="disabled.customs"
                    sx={{ fontWeight: 600 }}
                    pb={3}
                  >
                    Permission name
                  </Typography>
                  <Stack direction="row" gap="24px">
                    <Box className={classes.pos_relative}>
                      <Typography
                        variant="h4"
                        color="disabled.customs"
                        sx={{ fontWeight: 600 }}
                        className={classes.checkbox__radio}
                      >
                        Create
                      </Typography>
                      <TeamInfoIcon
                        title={`Users with the "Create" permission can add new data to the system.`}
                        maxWidth="212px"
                      />
                    </Box>

                    <Box className={classes.pos_relative}>
                      <Typography
                        variant="h4"
                        color="disabled.customs"
                        sx={{ fontWeight: 600 }}
                        className={classes.checkbox__radio}
                      >
                        View
                      </Typography>

                      <TeamInfoIcon
                        title={`Users with the "View" permission can access and see 
data that already exists in the system.`}
                        maxWidth="262px"
                      />
                    </Box>

                    <Box className={classes.pos_relative}>
                      <Typography
                        variant="h4"
                        color="disabled.customs"
                        sx={{ fontWeight: 600 }}
                        className={classes.checkbox__radio}
                      >
                        Edit
                      </Typography>
                      <TeamInfoIcon
                        title={`Users with the "Edit" permission can 
                        modify and update existing data.`}
                        maxWidth="212px"
                      />
                    </Box>

                    <Box className={classes.pos_relative}>
                      <Typography
                        variant="h4"
                        color="disabled.customs"
                        sx={{ fontWeight: 600 }}
                        className={classes.checkbox__radio}
                      >
                        Delete
                      </Typography>

                      <TeamInfoIcon
                        title={`Users with the "Delete" permission have the authority 
                        to remove data from the system. This includes 
                        permanently erasing records.`}
                        maxWidth="261px"
                      />
                    </Box>
                  </Stack>
                </Stack>
                {Object.keys(permissions)
                  .filter(
                    (permission_key) =>
                      typeof permissions[permission_key] === "object"
                  )
                  .map((key) => (
                    <CheckBoxrow
                      key={key}
                      label={keyLabelConfig[key]}
                      permissions={permissions[key]}
                      setPermissions={(newPermission) => {
                        setPermissions({
                          ...permissions,
                          [key]: { ...newPermission },
                        });
                      }}
                    />
                  ))}
                <FormControl component="fieldset">
                  <FormGroup
                    aria-label="position"
                    row
                    className={classes.form__radio__group}
                  >
                    {Object.keys(permissions)
                      .filter(
                        (permission_key) =>
                          typeof permissions[permission_key] === "boolean"
                      )
                      .map((key) => (
                        <FormControlLabel
                          key={key}
                          name={key}
                          value={permissions[key]}
                          control={
                            <Switch
                              color="primary"
                              value={permissions[key]}
                              checked={permissions[key]}
                              onChange={(e) =>
                                setPermissions({
                                  ...permissions,
                                  [key]: e.target.checked,
                                })
                              }
                            />
                          }
                          label={keyLabelConfig[key]}
                          labelPlacement="start"
                        />
                      ))}
                  </FormGroup>
                </FormControl>
              </Stack>
              {/* <FormControl component="fieldset">
              <FormGroup
                aria-label="position"
                row
                className={classes.form__radio__group}
              >
                <FormControlLabel
                  value={chatWithClients}
                  name="administration"
                  control={
                    <Switch
                      color="primary"
                      value={administration}
                      checked={administration}
                      onChange={(e) => setAdministration(e.target.checked)}
                    />
                  }
                  label="Administration"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={chatWithClients}
                  name="chatWithClients"
                  control={
                    <Switch
                      color="primary"
                      value={chatWithClients}
                      checked={chatWithClients}
                      onChange={(e) => setChatWithClients(e.target.checked)}
                    />
                  }
                  label="Can Chat with Clients"
                  labelPlacement="start"
                />
                <FormControlLabel
                  value={requestDocumentClient}
                  name="requestDocumentClient"
                  control={
                    <Switch
                      color="primary"
                      value={permissions.request_documents}
                      checked={permissions.request_documents}
                      onChange={(e) =>
                        setPermissions((prevState) => ({
                          ...prevState,
                          request_documents: e.target.checked,
                        }))
                      }
                    />
                  }
                  label="Can Request Documents from Clients"
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl> */}
            </Stack>
          </Stack>
          <Stack alignItems="flex-end" pt={1.4}>
            <Button
              title={update ? "Save Changes" : "Send Invite"}
              color="primary"
              variant="contained"
              disabled={!firstName || !email || emailError}
              // height="40px"
              // width="140px"
            />
          </Stack>
        </Stack>
      </Drawer>
    </>
  );
}
