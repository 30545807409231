import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const CaseMoreInfoSkeleton = ({ leftCard = false }) => {
  return (
    <Stack gap="2rem" height={leftCard && "55vh"}>
      <Stack gap="1rem">
        <Skeleton variant="rounded" width="8.9375rem" height="1.75rem" />
        <Stack gap="0.25rem">
          <Skeleton variant="rounded" width="6.875rem" height="1.375rem" />
          <Skeleton variant="rounded" width="100%" height="4.5rem" />
        </Stack>
      </Stack>
      {leftCard && (
        <Stack gap="1rem">
          <Stack gap="0.25rem">
            <Skeleton variant="rounded" width="6.875rem" height="1.375rem" />
            <Skeleton variant="rounded" width="100%" height="2.5rem" />
          </Stack>
          <Box display="flex" gap="1rem" alignItems="center">
            <Stack gap="0.25rem" width="50%">
              <Skeleton variant="rounded" width="6.875rem" height="1.375rem" />
              <Skeleton variant="rounded" width="100%" height="2.5rem" />
            </Stack>
            <Stack gap="0.25rem" width="50%">
              <Skeleton variant="rounded" width="6.875rem" height="1.375rem" />
              <Skeleton variant="rounded" width="100%" height="2.5rem" />
            </Stack>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default CaseMoreInfoSkeleton;
