import { Menu, MenuItem } from "@mui/material";
import React from "react";
import { checkFileExtension } from "../../utils/helpers";

const DocumentMenuItems = ({
  anchorDocumentEl,
  openDocumentMenu,
  handleDocumentClose,
  addMoreDocumentsHandler,
  selectedDocument,
  moveFileDialogHandler,
  moveToNewFolderFunction,
  renameDocumentHandler,
  handleDownloadClick,
  documentDeleteHandler,
  downloadImgAsPdfHandler,
  fromInnerFolderView = false,
  innerSelectedDocument,
}) => {
  const isImage = fromInnerFolderView
    ? checkFileExtension(innerSelectedDocument?.file_name)
    : checkFileExtension(selectedDocument?.file_name);

  return (
    <Menu
      id="document-menu"
      anchorEl={anchorDocumentEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "center",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={openDocumentMenu}
      onClose={handleDocumentClose}
      MenuListProps={{
        "aria-labelledby": "document-button",
      }}
    >
      <MenuItem onClick={() => renameDocumentHandler("fromInnerFolderView")}>
        Rename
      </MenuItem>

      {selectedDocument?.type === "folder" ? (
        [
          <MenuItem key="add-more-documents" onClick={addMoreDocumentsHandler}>
            Add more documents
          </MenuItem>,
          <MenuItem
            key="download-pdf"
            onClick={() => downloadImgAsPdfHandler(selectedDocument)}
          >
            Download as PDF
          </MenuItem>,
        ]
      ) : fromInnerFolderView ? (
        <MenuItem key="move" onClick={moveFileDialogHandler}>
          Move
        </MenuItem>
      ) : (
        [
          <MenuItem key="move" onClick={moveFileDialogHandler}>
            Move
          </MenuItem>,
          <MenuItem
            key="moveToNew"
            onClick={() => moveToNewFolderFunction(selectedDocument)}
          >
            Move to new folder
          </MenuItem>,
        ]
      )}

      {/* {selectedDocument?.type === "file" && ( */}
      {isImage
        ? [
            <MenuItem key="download-image" onClick={handleDownloadClick}>
              Download as Image
            </MenuItem>,
            <MenuItem
              key="download-pdf"
              onClick={() => downloadImgAsPdfHandler(selectedDocument)}
            >
              Download as PDF
            </MenuItem>,
          ]
        : [
            <MenuItem key="download" onClick={handleDownloadClick}>
              Download
            </MenuItem>,
          ]}
      {/* )} */}
      <MenuItem onClick={documentDeleteHandler} sx={{ color: "#FF2D2D" }}>
        Delete
      </MenuItem>
    </Menu>
  );
};

export default DocumentMenuItems;
