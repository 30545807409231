import React from "react";
import { Stack, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from "../../icons/CalendarIcon";

const CustomPainDateRange = ({
  fromCustomDate,
  toCustomDate,
  setFromCustomDate,
  setToCustomDate,
  isTouchedCustomDate,
}) => {
  return (
    <Stack gap="24px">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="From"
          value={fromCustomDate}
          onChange={(newValue) => setFromCustomDate(newValue)}
          slotProps={{
            textField: {
              fullWidth: true,
              error: isTouchedCustomDate && !fromCustomDate,
            },
          }}
          sx={{ marginTop: 1 }}
          disableFuture
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          textField={(params) => <TextField {...params} readOnly />}
        />
      </LocalizationProvider>

      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="To"
          value={toCustomDate}
          onChange={(newValue) => setToCustomDate(newValue)}
          slotProps={{
            textField: {
              fullWidth: true,
              error: isTouchedCustomDate && !toCustomDate,
            },
          }}
          disabled={fromCustomDate ? false : true}
          minDate={fromCustomDate}
          disableFuture
          textField={(params) => <TextField {...params} readOnly />}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
          sx={{ marginTop: 1 }}
        />
      </LocalizationProvider>
    </Stack>
  );
};

export default CustomPainDateRange;
