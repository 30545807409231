import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Chip,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import CardUI from "../../common/Card";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import AvatarWithName from "../../components/AvatarWithName";
import CompanyLogo from "../../assets/images/company-logo.jpg";
import {
  caseStatusType,
  formatPhoneNumber,
  timeDateFormat,
} from "../../utils/helpers";
import ReferralEmpty from "../../assets/images/referral-empty.png";
import { useDispatch, useSelector } from "react-redux";
import { getReferralsListFunc } from "../../actions/Referrals";
import dayjs from "dayjs";
import AlertMessage from "../../common/AlertMessage";
import CircularProgressLoading from "../../common/CircularProgress";
import { getAgencyDetailFunc } from "../../actions/Agency";
import { InfoCircle } from "../../assets/constants";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import SkeletonGrid from "../../components/SkeletonGrid";
import CaseTableSkeleton from "../../components/CaseTableSkeleton";

// const referralsArray = [
//   {
//     id: 1,
//     referralId: "11",
//     referee: {
//       name: "Sandal Kumar",
//       email: "sandlakumar@gmail.com",
//       contact: "12312341234",
//     },
//     referrer: {
//       name: "Arlene mcoy",
//       profile_picture_url: CompanyLogo,
//     },
//     status: "Sent",
//     sentDate: "29 jul, 12:00pm",
//   },
//   {
//     id: 2,
//     referralId: "12",
//     referee: {
//       name: "Sandal Kumar",
//       email: "sandlakumar@gmail.com",
//       contact: "12312341234",
//     },
//     referrer: {
//       name: "Arlene mcoy",
//       profile_picture_url: CompanyLogo,
//     },
//     status: "Completed",
//     sentDate: "29 jul, 12:00pm",
//   },
// ];

const Referrals = () => {
  const [referralStatus, setReferralStatus] = useState("all");
  const [referralsData, setReferralsData] = useState([]);

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getReferralsList = useSelector((state) => state.getReferralsList);
  const { loading, error, referralsList } = getReferralsList;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "referrals",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  const showReferees = agencyDetail?.agency?.show_referees;

  const [openAlert, setOpenAlert] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  useEffect(() => {
    dispatch(getReferralsListFunc());
    dispatch(getAgencyDetailFunc());
    // setReferralsData(referralsArray);
  }, []);

  // useEffect(() => {
  //   if (referralsList?.referrals) {
  //     // setReferralsData(referralsList?.referrals);
  //   }
  // }, []);

  // console.log("LIST", referralsList?.referrals);

  const rows = useMemo(() => {
    if (!referralsList?.referrals?.length) return [];

    //   .filter(
    //     ({ name, email }) =>
    //       name.search(regex) !== -1 || email.search(regex) !== -1
    //   )

    // {
    //   id: 1,
    //   referralId: "11",
    //   referee: {
    //     name: "Sandal Kumar",
    //     email: "sandlakumar@gmail.com",
    //     contact: "12312341234",
    //   },
    //   referrer: {
    //     name: "Arlene mcoy",
    //     profile_picture_url: CompanyLogo,
    //   },
    //   status: "Sent",
    //   sentDate: "29 jul, 12:00pm",
    // },

    return referralsList?.referrals?.map((referral, index) => ({
      id: referral?.id,
      referralId: index + 1,
      referee: {
        name: referral?.name ? referral?.name : "",
        email: referral?.email ? referral?.email : "",
        contact: referral?.contact_number ? referral?.contact_number : "",
      },
      referrer: {
        name: referral?.client?.name ? referral?.client?.name : "",
        profile_picture_url: referral?.client?.profile_picture_url
          ? referral?.client?.profile_picture_url
          : "",
      },
      // status: referral?.status ? referral?.status : "",
      status: referral?.status ? referral?.status : "",
      sentDate: referral?.created_at
        ? timeDateFormat(referral?.created_at)
        : "",
    }));

    // return referralsData.map((client) => ({
    //   id: client.id,
    //   name_with_picture: {
    //     name: client.name,
    //     profile_picture_url: client.profile_picture_url,
    //   },
    //   active_case: client.cases?.length ? client.cases[0].title : "Not found",
    //   email_address: client.email,
    //   assigned_to: client.assigned_to ? JSON.parse(client.assigned_to) : [],
    //   assigned_to_details: client.assigned_to_details,
    //   created_at: dayjs(client.created_at).format("DD MMM, hh:mm a"),
    //   client: client,
    // }));
  }, [referralsList]); // clientsList, query

  const columns = useMemo(
    () => [
      {
        field: "referralId",
        headerName: "Referral ID",
        width: 140,
        flex: 1,

        // renderCell: ({ value }) => (
        //   <AvatarWithName
        //     name={value.name}
        //     imageURL={value.profile_picture_url}
        //   />
        // ),
        sortable: false,
      },
      {
        field: "referee",
        headerName: "Referee",
        renderCell: ({ value }) => (
          <Stack>
            <Typography variant="subtitle1" fontSize="16px" fontWeight="400">
              {value.name}
            </Typography>
            <Typography
              variant="subtitle1"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0, 0, 0, 0.38)"
              sx={{
                // whiteSpace: "normal",
                // wordWrap: "break-word",
                textOverflow: "ellipsis !important",
                whiteSpace: "nowrap !important",
                width: "200px !important",
                overflow: "hidden !important",
              }}
            >
              {value.email}
            </Typography>
            <Typography
              variant="subtitle1"
              fontSize="16px"
              fontWeight="400"
              color="rgba(0, 0, 0, 0.38)"
            >
              {formatPhoneNumber(value.contact)}
            </Typography>
          </Stack>
        ),
        width: 304,
        flex: 1,
      },
      {
        field: "referrer",
        headerName: "Referrer",
        width: 195,
        renderCell: ({ value }) => (
          <AvatarWithName
            name={value.name}
            imageURL={value.profile_picture_url}
          />
        ),
        flex: 1,
      },
      {
        field: "status",
        renderHeader: () => (
          <Box display="flex" gap="4px" alignItems="flex-start">
            <Typography
              variant="h2"
              fontSize="14px"
              fontWeight="500"
              lineHeight="28px"
            >
              Status
            </Typography>
            <Tooltip
              title="Sent: The client recommended the law firm to a friend. Completed: The referred friend became a client."
              placement="top"
              arrow
              PopperProps={{
                sx: {
                  marginBottom: "-7px !important",
                },
              }}
            >
              <InfoCircle />
            </Tooltip>
          </Box>
        ),
        flex: 1,
        renderCell: ({ value }) => (
          <Chip
            size="small"
            label={value}
            sx={{ textTransform: "capitalize" }}
            color={caseStatusType(value)}
            variant="outlined"
          />
        ),
        width: 125,
        sortable: false,
      },
      {
        field: "sentDate",
        headerName: "Sent Date",
        sortable: false,
        // renderCell: (params) => (
        //   <Box
        //     display="flex"
        //     alignItems="center"
        //     justifyContent="space-between"
        //     gap="16px"
        //     width="100%"
        //   >
        //     <Box component="span">{params.value.text}</Box>
        //     <Button variant="contained" size="small">
        //       NUDGE
        //     </Button>
        //   </Box>
        // ),
        flex: 1,
      },
    ],
    []
  );

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}
      <Stack>
        <Typography variant="h5" fontSize="30px" pb={3}>
          Referrals
        </Typography>

        <CardUI
          maxWidth="100%"
          dashboard="true"
          responsive="true"
          height="792px"
          padding="32px"
        >
          {referralsList?.referrals?.length > 0 ? (
            <Stack
              // justifyContent="space-between"
              gap="24px"
              height="100%"
            >
              {/* <TextField
                  sx={{
                    maxWidth: "271px",
                    width: "100%",
                  }}
                  variant="outlined"
                  value={referralStatus}
                  onChange={(e) => setReferralStatus(e.target.value)}
                  select
                  label="Referral Status"
                >
                  <MenuItem value="all">All</MenuItem>
                </TextField> */}
              {loading ? (
                <CaseTableSkeleton
                  headers={["Referee", "Referrer", " Status", "Sent Date"]}
                  numRows={4}
                />
              ) : (
                <div
                  style={{
                    height: "100%",
                    width: "100%",
                    lineHeight: "normal !important",
                    // maxWidth: "1200px",
                    // overflowX: "scroll",
                  }}
                >
                  <DataGrid
                    rows={rows}
                    columns={columns.filter((column) => {
                      if (column.field === "referee") {
                        return showReferees;
                      }
                      return true;
                    })}
                    getRowHeight={() => (showReferees ? "auto" : 58)}
                    // onRowClick={overviewDetailHandler}
                    initialState={{
                      pagination: {
                        paginationModel: { page: 0, pageSize: 5 },
                      },
                    }}
                    // components={{
                    //   Cell: ({ value }) => (
                    //     <div style={{ display: "flex", alignItems: "start" }}>
                    //       {value}
                    //     </div>
                    //   ),
                    // }}
                    sx={{
                      cursor: "pointer !important",
                      "&": { border: "none" },
                      [`& .${gridClasses.cell}`]: {
                        py: "16px",
                        display: "flex",
                        // alignItems: "flex-start",
                        alignItems: showReferees ? "flex-start" : "center",
                      },
                    }}
                    pageSizeOptions={[5, 10]}
                    disableRowSelectionOnClick
                    // getRowId={(row) => row.id}
                    // className={classes.dataGrid}
                  />
                </div>
              )}
            </Stack>
          ) : (
            <Stack
              height="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
              gap={1}
            >
              <img src={ReferralEmpty} alt="referrals" />
              <Typography
                variant="body2"
                textAlign="center"
                color="rgba(0, 0, 0, 0.38)"
              >
                When your clients start referring you to their friends it will
                start showing up here.{" "}
              </Typography>
            </Stack>
          )}
        </CardUI>
      </Stack>
    </>
  );
};

export default Referrals;
