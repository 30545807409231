import React from "react";

const ReminderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M1.33203 14.667H14.6654"
        stroke="#FF852D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 4C4.68667 4 2 6.68667 2 10V14.6667H14V10C14 6.68667 11.3133 4 8 4Z"
        stroke="#FF852D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 1.33301V1.99967"
        stroke="#FF852D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.66797 2.66699L3.33464 3.33366"
        stroke="#FF852D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3346 2.66699L12.668 3.33366"
        stroke="#FF852D"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ReminderIcon;
