export const page_size = 10;

export const DISABLE_APP_ACCESS_REQUEST = "DISABLE_APP_ACCESS_REQUEST";
export const DISABLE_APP_ACCESS_SUCCESS = "DISABLE_APP_ACCESS_SUCCESS";
export const DISABLE_APP_ACCESS_FAIL = "DISABLE_APP_ACCESS_FAIL";
export const RESET_APP_ACCESS_STATE = "RESET_APP_ACCESS_STATE";

export const ENABLE_APP_ACCESS_REQUEST = "ENABLE_APP_ACCESS_REQUEST";
export const ENABLE_APP_ACCESS_SUCCESS = "ENABLE_APP_ACCESS_SUCCESS";
export const ENABLE_APP_ACCESS_FAIL = "ENABLE_APP_ACCESS_FAIL";

export const USER_FEED_NOTIFICATIONS_REQUEST =
  "USER_FEED_NOTIFICATIONS_REQUEST";
export const USER_FEED_NOTIFICATIONS_SUCCESS =
  "USER_FEED_NOTIFICATIONS_SUCCESS";
export const USER_FEED_NOTIFICATIONS_FAIL = "USER_FEED_NOTIFICATIONS_FAIL";
export const RESET_USER_FEED_NOTIFICATIONS = "RESET_USER_FEED_NOTIFICATIONS";

export const ALL_NOTIFICATIONS_REQUEST = "ALL_NOTIFICATIONS_REQUEST";
export const ALL_NOTIFICATIONS_SUCCESS = "ALL_NOTIFICATIONS_SUCCESS";
export const ALL_NOTIFICATIONS_FAIL = "ALL_NOTIFICATIONS_FAIL";
export const RESET_ALL_NOTIFICATIONS = "RESET_ALL_NOTIFICATIONS";

export const CHAT_NOTIFICATIONS_REQUEST = "CHAT_NOTIFICATIONS_REQUEST";
export const CHAT_NOTIFICATIONS_SUCCESS = "CHAT_NOTIFICATIONS_SUCCESS";
export const CHAT_NOTIFICATIONS_FAIL = "CHAT_NOTIFICATIONS_FAIL";
export const RESET_CHAT_NOTIFICATIONS = "RESET_CHAT_NOTIFICATIONS";

export const ALERT_NOTIFICATIONS_REQUEST = "ALERT_NOTIFICATIONS_REQUEST";
export const ALERT_NOTIFICATIONS_SUCCESS = "ALERT_NOTIFICATIONS_SUCCESS";
export const ALERT_NOTIFICATIONS_FAIL = "ALERT_NOTIFICATIONS_FAIL";
export const RESET_ALERT_NOTIFICATIONS = "RESET_ALERT_NOTIFICATIONS";

export const MARK_NOTIFICATIONS_REQUEST = "MARK_NOTIFICATIONS_REQUEST";
export const MARK_NOTIFICATIONS_SUCCESS = "MARK_NOTIFICATIONS_SUCCESS";
export const MARK_NOTIFICATIONS_FAIL = "MARK_NOTIFICATIONS_FAIL";
export const RESET_MARK_NOTIFICATIONS = "RESET_MARK_NOTIFICATIONS";

export const USER_ACTIVITY_NOTIFICATIONS_REQUEST =
  "USER_ACTIVITY_NOTIFICATIONS_REQUEST";
export const USER_ACTIVITY_NOTIFICATIONS_SUCCESS =
  "USER_ACTIVITY_NOTIFICATIONS_SUCCESS";
export const USER_ACTIVITY_NOTIFICATIONS_FAIL =
  "USER_ACTIVITY_NOTIFICATIONS_FAIL";
export const RESET_USER_ACTIVITY_NOTIFICATIONS =
  "RESET_USER_ACTIVITY_NOTIFICATIONS";

export const GET_BODY_PARTS_REQUEST = "GET_BODY_PARTS_REQUEST";
export const GET_BODY_PARTS_SUCCESS = "GET_BODY_PARTS_SUCCESS";
export const GET_BODY_PARTS_FAIL = "GET_BODY_PARTS_FAIL";
export const RESET_BODY_PARTS = "RESET_BODY_PARTS";

export const GET_CASE_SUMMARY_LINK_REQUEST = "GET_CASE_SUMMARY_LINK_REQUEST";
export const GET_CASE_SUMMARY_LINK_SUCCESS = "GET_CASE_SUMMARY_LINK_SUCCESS";
export const GET_CASE_SUMMARY_LINK_FAIL = "GET_CASE_SUMMARY_LINK_FAIL";
export const RESET_CASE_SUMMARY_LINK = "RESET_CASE_SUMMARY_LINK";

export const SEND_PAIN_LOG_REMINDER_REQUEST = "SEND_PAIN_LOG_REMINDER_REQUEST";
export const SEND_PAIN_LOG_REMINDER_SUCCESS = "SEND_PAIN_LOG_REMINDER_SUCCESS";
export const SEND_PAIN_LOG_REMINDER_FAIL = "GET_SEND_PAIN_LOG_REMINDER_FAIL";
export const RESET_SEND_PAIN_LOG_REMINDER = "RESET_SEND_PAIN_LOG_REMINDER";

export const GET_EXPENSE_DETAIL_REQUEST = "GET_EXPENSE_DETAIL_REQUEST";
export const GET_EXPENSE_DETAIL_SUCCESS = "GET_EXPENSE_DETAIL_SUCCESS";
export const GET_EXPENSE_DETAIL_FAIL = "GET_GET_EXPENSE_DETAIL_FAIL";
export const RESET_GET_EXPENSE_DETAIL = "RESET_GET_EXPENSE_DETAIL";
