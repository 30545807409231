import { SEGMENT_KEY } from "../../actions"

const getProps = ({ agent, agency }) => ({
    agent: agent?.id,
    name: agent?.name,
    agency: agency?.id,
    agencyName: agency?.name,
    email: agent?.email
})

const getErrorProps = ({ agent, agency, error }) => ({
    agent: agent?.id,
    name: agent?.name,
    agency: agency?.id,
    agencyName: agency?.name,
    email: agent?.email,
    error: error,
})

export const segmentIdentify = ({ agent, agency }) => {
    try {
        fetch('https://api.segment.io/v1/identify', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: SEGMENT_KEY,
        },
        body: JSON.stringify({
            userId: `${agent?.id}-${agency?.id}`,
            traits: getProps({ agent, agency }),
        }),
    })
    } catch (error) {
        console.log(error);
    }
}

export const segmentTrack = ({ event, agent, agency }) => {
    try {
        fetch('https://api.segment.io/v1/track', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: SEGMENT_KEY,
        },
        body: JSON.stringify({
            userId: `${agent?.id}-${agency?.id}`,
            event: event,
            properties: getProps({ agent, agency }),
        }),
    })
    } catch (error) {
        console.log(error);
    }
}

export const segmentError = ({ event, agent, agency, error }) => {
    try {
        fetch('https://api.segment.io/v1/track', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            Authorization: SEGMENT_KEY,
        },
        body: JSON.stringify({
            userId: `${agent?.id}-${agency?.id}`,
            event: event,
            properties: getErrorProps({ agent, agency, error }),
        }),
    })
    } catch (error) {
        console.log(error);
    }
}
