const Style = () => ({
  phone__input: {
    "& .react-tel-input": {
      width: "100%",
      marginTop: "8px",
      "& input": {
        width: "100%",
        height: "100%",
        fontSize: "13.5px",
        lineHeight: "19px",
        fontWeight: "600",
        letterSpacing: "inherit",
        // color: 'var(--color-disabled-hot)',
        background: "#fff",
        borderRadius: "10px",
        border: "1px solid rgba(145, 158, 171, 0.2) !important",
      },
      "& .flag-dropdown": {
        background: "#fff",
        borderRadius: "36px 0 0 36px",
        border: "0",
        "& .selected-flag": {
          background: "#fff",
          borderRadius: "36px 0 0 36px",
        },
      },
    },
  },
  error__boundry: {
    "& .react-tel-input": {
      "& input": {
        border: "1px solid #ff3636",
      },
      "& .flag-dropdown": {
        border: "1px solid #ff3636",
        borderRight: "0",
      },
    },
  },
});
export default Style;
