import {
  Alert,
  Avatar,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Select,
  Snackbar,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TextField,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import CardUI from "../../../common/Card";
import ExpandMoreOutlinedIcon from "@mui/icons-material/ExpandMoreOutlined";
import AddIcon from "@mui/icons-material/Add";

import {
  caseLogsCategory,
  caseLogsOptions,
  dateTimeFormat,
  displayExpenseAmounts,
  expenseTypeValues,
  exportCsvData,
  onlyDateFormat,
  painLevelBackgroundColor,
  parseAndMergeBodyParts,
  renderStepContent,
  showExpenseTypeName,
  stepperIcon,
} from "../../../utils/helpers";
import HeaderText from "../../../common/HeaderText";

import CurveChart from "../../../components/CurveChart";
import PainLogsDate from "../../../components/PainLogsDate";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  addNoteToCaseFunc,
  getCaseDetailByCaseId,
  getClientPainlogsFunc,
  resetAddNoteToCase,
} from "../../../actions/Cases";
import EmptyBox from "../../../assets/images/empty-box.png";
import PainLogDetail from "../../../components/PainLogDetail";
import dayjs from "dayjs";
import BodyPartsFilter from "../../../components/BodyPartsFilter";
import AlertDialog from "../../../components/AlertDialog";
import CustomPainDateRange from "../../../components/CustomPainDateRange";
import ExportIcon from "../../../icons/ExportIcon";
import CaseChartSkeleton from "../../../components/CaseChartSkeleton";
import CaseLogsSkeleton from "../../../components/CaseLogsSkeleton";
import jsPDF from "jspdf";
import { CSVLink } from "react-csv";
import DialogOverlay from "../../../components/DialogOverlay";
import restrictedImg from "../../../assets/images/restricted-img.png";
import { LoadingButton } from "@mui/lab";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  addCaseToBillingSubscriptionFunc,
  resetAddCaseToSubscription,
} from "../../../actions/BillingSubscription";
import chartEmptyBox from "../../../assets/images/chart-empty-box.png";

import DoughnutChartUI from "../../../components/DoughnutChartUI";
import CollapsableUIBox from "../../../components/CollapsableUIBox";
import FilterDaysDropDown from "../../../components/FilterDaysDropDown";
import {
  getExpensesDetailFunc,
  resetSendPainLogReminder,
  sendPainLogReminderFunc,
} from "../../../actions/AppConfig";
import ExpenseTypeFilter from "../../../components/ExpenseTypeFilter";
import SortIcon from "../../../icons/SortIcon";
import Ellipse from "../../../icons/Ellipse";
import CaseLogEllipseIcon from "../../../icons/CaseLogEllipseIcon";
import Timeline, { timelineItemClasses } from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import RectangleSkeletons from "../../../components/RectangleSkeletons";

const log_type_title_config = {
  pain_logged: "Pain Logged",
  case_created: "Case Created",
  appointment_update: "",
  case_status_update: "Case Status changed to ",
  document_uploaded: "Document Uploaded",
  chat_activity: "Client replied in chat",
};

const useStyles = makeStyles((theme) => {
  return {
    pain_level: {
      width: "16px",
      height: "16px",
      color: "#fff",
      display: "inline-flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: "4px",
    },
  };
});

const rows = [
  {
    date: "12:00am, 27 July",
    title: "Appointment Expenses",
    type: "Transport",
    amount: "$ 13.00",
  },
  {
    date: "12:00am, 27 July",
    title: "Appointment Expenses",
    type: "Transport",
    amount: "$ 13.00",
  },
  {
    date: "12:00am, 27 July",
    title: "Appointment Expenses",
    type: "Transport",
    amount: "$ 13.00",
  },
  {
    date: "12:00am, 27 July",
    title: "Appointment Expenses",
    type: "Transport",
    amount: "$ 13.00",
  },
  {
    date: "12:00am, 27 July",
    title: "Appointment Expenses",
    type: "Transport",
    amount: "$ 13.00",
  },
  {
    date: "12:00am, 27 July",
    title: "Appointment Expenses",
    type: "Transport",
    amount: "$ 13.00",
  },
  {
    date: "12:00am, 27 July",
    title: "Appointment Expenses",
    type: "Transport",
    amount: "$ 13.00",
  },
];

const OverviewTab = ({
  allBodyPartsData,
  billingActive,
  setShowBillingPlan,
  showBillingPlan,
  setBillingActive,
  caseAddedInPlan,
  setCaseAddedInPlan,
  billingSubscription,
  loadingBilling,
}) => {
  const caseDetailByCaseId = useSelector((state) => state.caseDetailByCaseId);
  const {
    loading: loadingCaseDetail,
    error: errorcaseDetail,
    caseDetail,
  } = caseDetailByCaseId;
  // const [painlogsFilter, setPainlogsFilter] = useState("all");
  const [painLogDays, setPainLogDays] = useState(14);
  const [expenseDays, setExpenseDays] = useState(14);
  const [openCustomPainLogDays, setOpenCustomPainLogDays] = useState(false);
  const [openCustomExpenseTypeDays, setOpenCustomExpenseTypeDays] =
    useState(false);
  const [bodyPartsAnchorEl, setBodyPartsAnchorEl] = useState(null);
  const [expenseTypeAnchorEl, setExpenseTypeAnchorEl] = useState(null);
  const [exportChartEl, setExportChartEl] = useState(null);
  const [activity, setActivity] = useState("all");
  const [filteredData, setFilteredData] = useState([]);
  const [allBodyParts, setAllBodyParts] = useState([]);
  const [loadingPlan, setLoadingPlan] = useState(false);
  const [checkedItems, setCheckedItems] = useState({
    transportExpenses: true,
    medicalExpenses: true,
    salaryLoss: true,
    others: true,
  });
  const [expenseDetails, setExpenseDetails] = useState([]);

  // const [selectedBodyPart, setSelectedBodyPart] = useState([]);
  const [selectedBodyParts, setSelectedBodyParts] = useState([]);
  const [fromCustomDate, setFromCustomDate] = useState(null);
  const [toCustomDate, setToCustomDate] = useState(null);
  const [expenseTypeFromDate, setExpenseTypeFromDate] = useState(null);
  const [expenseTypeToDate, setExpenseTypeToDate] = useState(null);
  const [startDate, setStartDate] = useState(null);
  const [exportedCsvData, setExportedCsvData] = useState([]);
  // const [customDateError, setCustomDateError] = useState(null);
  const [isTouchedCustomDate, setIsTouchedCustomDate] = useState(false);
  const [isTouchedExpenseTypeDate, setIsTouchedExpenseTypeDate] =
    useState(false);
  const [endDate, setEndDate] = useState(null);
  const [selectedPain, setSelectedPain] = useState("average");
  const [painOptions] = useState([
    { value: "maximum", label: "Maximum" },
    { value: "latest", label: "Latest" },
    { value: "average", label: "Average" },
    // Add more options as needed
  ]);
  const [confirmPlan, setConfirmPlan] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openSnackbarReminder, setOpenSnackbarReminder] = useState(false);

  const [showPainLogsListing, setShowPainLogsListing] = useState(false);
  const [showExpenseListings, setShowExpenseListings] = useState(false);
  const [reverseArray, setReverseArray] = useState(false);

  const [selectedLogsFilters, setSelectedLogsFilters] = useState(["all"]);
  const [openAddNoteModal, setOpenAddNoteModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [noteValue, setNoteValue] = useState("");
  const [fieldIsTouched, setFieldIsTouched] = useState(false);

  const classes = useStyles();
  const filterButtonRef = useRef();
  const chartRef = useRef();

  const caseTitle = caseDetail?.case?.title;

  const dispatch = useDispatch();
  const getClientPainlogs = useSelector((state) => state.getClientPainlogs);
  const { loading, error, painlogs } = getClientPainlogs;

  const getExpensesDetail = useSelector((state) => state.getExpensesDetail);
  const {
    loading: loadingExpense,
    error: errorExpense,
    expenses,
  } = getExpensesDetail;

  const sendPainLogReminder = useSelector((state) => state.sendPainLogReminder);
  const {
    loading: loadingSendReminder,
    errorSendReminder,
    sentReminder,
  } = sendPainLogReminder;

  const addNoteToCase = useSelector((state) => state.addNoteToCase);
  const { loading: loadingNote, error: errorNote, addedNote } = addNoteToCase;

  const addCaseToSubscription = useSelector(
    (state) => state.addCaseToSubscription
  );
  const {
    loading: loadingAddCaseToSubsc,
    error: errorAddCaseToSubsc,
    caseAddedToSubscription,
  } = addCaseToSubscription;

  // const [accordionData, setAccordionData] = useState(accordionDataArr);
  // const [filteredPainLogData, setFilteredPainLogData] = useState([]);

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  const handleClose = () => {
    setBodyPartsAnchorEl(null);
  };
  const handleCloseExpenseType = () => {
    setExpenseTypeAnchorEl(null);
  };

  // EXPORT CHART AS PNG OR PDF HANDLER
  const openExportChart = Boolean(exportChartEl);
  const openExportListHandler = (event) => {
    setExportChartEl(event.currentTarget);
  };

  const exportChartCloseHandler = () => {
    setExportChartEl(null);
  };

  // SELECT BODY PARTS HANDLER
  const selectBodyPartsHandler = (event) => {
    setBodyPartsAnchorEl(event.currentTarget);
  };

  // EXPENSES HANDLER -
  const selectExpenseTypeHandler = (e) => {
    setExpenseTypeAnchorEl(e.currentTarget);
  };

  const isInsidePopover = (event) => {
    return bodyPartsAnchorEl && bodyPartsAnchorEl.contains(event.target);
  };

  const isInsidePopoverExpenseType = (e) => {
    return expenseTypeAnchorEl && expenseTypeAnchorEl.contains(e.target);
  };

  const handleOutsideClick = (event) => {
    if (!isInsidePopover(event)) {
      handleClose();
    }
  };
  const handleOutsideClickExpenseType = (event) => {
    if (!isInsidePopoverExpenseType(event)) {
      handleCloseExpenseType();
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [bodyPartsAnchorEl]);

  useEffect(() => {
    document.addEventListener("click", handleOutsideClickExpenseType);
    return () => {
      document.removeEventListener("click", handleOutsideClickExpenseType);
    };
  }, [expenseTypeAnchorEl]);

  // DISPATCH PAIN LOGS FUNCTION
  useEffect(() => {
    if (
      painLogDays === "custom" &&
      caseDetail?.case?.id &&
      caseDetail?.case?.client?.id
    ) {
      dispatch(
        getClientPainlogsFunc(
          caseDetail?.case?.id,
          caseDetail?.case?.client?.id,
          startDate.format("YYYY-MM-DD"),
          endDate.format("YYYY-MM-DD"),
          "all"
        )
      );
    } else if (caseDetail?.case?.id && caseDetail?.case?.client?.id) {
      const endingDate = dayjs();
      setEndDate(endingDate);
      const startingDate = endingDate?.subtract(painLogDays, "day");
      setStartDate(startingDate);

      dispatch(
        getClientPainlogsFunc(
          caseDetail?.case?.id,
          caseDetail?.case?.client?.id,
          startingDate.format("YYYY-MM-DD"),
          endingDate.format("YYYY-MM-DD"),
          "all"
        )
      );
    }
  }, [caseDetail?.case?.id, painLogDays, selectedPain]);

  useEffect(() => {
    if (caseDetail?.case?.activity_logs) {
      let filtered_data;

      if (selectedLogsFilters.includes("all")) {
        // If 'All' filter is selected, display all logs
        filtered_data = caseDetail?.case?.activity_logs;
      } else {
        // Filter logs based on selected filters
        filtered_data = caseDetail?.case?.activity_logs?.filter((item) => {
          // Filter based on log_type
          if (
            selectedLogsFilters.includes("appointments") &&
            selectedLogsFilters.includes("notes") &&
            selectedLogsFilters.includes("expenses") &&
            selectedLogsFilters.includes("other")
          ) {
            // If all filters are selected, show all logs
            return true;
          } else {
            // Check individual filters
            let match = false;
            if (selectedLogsFilters.includes("appointments")) {
              match = match || item.log_type === "appointment_update";
            }
            if (selectedLogsFilters.includes("notes")) {
              match = match || item.log_type === "custom_note";
            }
            if (selectedLogsFilters.includes("expenses")) {
              match = match || item.log_type === "expense_added";
            }
            if (selectedLogsFilters.includes("other")) {
              match =
                match ||
                ![
                  "appointment_update",
                  "custom_note",
                  "expense_added",
                ].includes(item.log_type);
            }
            return match;
          }
        });
      }

      //  filtered_data = selectedLogsFilters?.includes("all")
      //   ? caseDetail?.case?.activity_logs
      //   : caseDetail?.case?.activity_logs?.filter(
      //       (item) => item.log_type === "appointment_update"
      //     );
      setFilteredData(filtered_data);
    }
  }, [caseDetail?.case?.activity_logs, selectedLogsFilters]);

  // EXPENSE FILTERS FUNCTION =========================

  const isAllChecked =
    checkedItems.transportExpenses &&
    checkedItems.medicalExpenses &&
    checkedItems.salaryLoss &&
    checkedItems.others;

  const isAllUnchecked =
    !checkedItems.transportExpenses &&
    !checkedItems.medicalExpenses &&
    !checkedItems.salaryLoss &&
    !checkedItems.others;

  const filterExpenseDetailsByDays = (
    filteredExpenseDetails,
    expenseDaysValue,
    checkedExpenseTypeItems
  ) => {
    let startExpenseDate;
    let endExpenseDate;

    if (expenseDaysValue === "custom") {
      startExpenseDate = dayjs(expenseTypeFromDate);
      endExpenseDate = dayjs(expenseTypeToDate);
    } else {
      startExpenseDate = dayjs().subtract(expenseDaysValue, "day");
      endExpenseDate = dayjs();
    }

    const filteredExpenses = filteredExpenseDetails?.filter((expense) => {
      const expenseDate = dayjs(expense?.date);
      return expenseDate?.isBetween(
        startExpenseDate,
        endExpenseDate,
        null,
        "[]"
      );
    });

    if (isAllChecked) {
      return filteredExpenses;
    }

    // Filter by expense type
    const filteredExpensesType = filteredExpenses?.filter((expense) => {
      const isSelectedExpenseType =
        checkedItems[expenseTypeValues[expense.expense_type.toLowerCase()]];
      return isSelectedExpenseType;
    });

    return filteredExpensesType;
  };

  // CUSTOM EXPENSE DATE HANDLER
  const customExpenseTypeDateHandler = () => {
    setIsTouchedExpenseTypeDate(true);

    if (!expenseTypeFromDate || !expenseTypeToDate) {
      return;
    }

    const customExpenseDetails = filterExpenseDetailsByDays(
      expenses?.expenses,
      "custom",
      checkedItems
    );

    setExpenseDetails(customExpenseDetails);
    closeCustomPainLogDaysHandler();
    setExpenseTypeFromDate(null);
    setExpenseTypeToDate(null);
    setIsTouchedExpenseTypeDate(false);
  };

  // DISPATCH EXPENSE DETAIL FUNCTION
  useEffect(() => {
    dispatch(getExpensesDetailFunc(caseDetail?.case?.id));
  }, [caseDetail?.case?.id]);

  useEffect(() => {
    if (expenses?.ok) {
      const filteredExpenseDetails = filterExpenseDetailsByDays(
        expenses?.expenses,
        expenseDays,
        checkedItems
      );

      setExpenseDetails(filteredExpenseDetails);
    }
  }, [expenses, expenseDays, checkedItems]);

  const customPainDateHandler = () => {
    setIsTouchedCustomDate(true);

    if (!fromCustomDate || !toCustomDate) {
      // setCustomDateError(true);
      return;
    }

    // setIsTouchedCustomDate(false);

    if (fromCustomDate && toCustomDate) {
      setStartDate(fromCustomDate);
      setEndDate(toCustomDate);

      dispatch(
        getClientPainlogsFunc(
          caseDetail?.case?.id,
          caseDetail?.case?.client?.id,
          fromCustomDate.format("YYYY-MM-DD"),
          toCustomDate.format("YYYY-MM-DD"),
          "all"
        )
      );

      closeCustomPainLogDaysHandler();
      setFromCustomDate(null);
      setToCustomDate(null);
      setIsTouchedCustomDate(false);
    }
  };

  // useEffect(() => {
  //   if (painlogs?.ok) {
  //     dispatch(resetClientPainlogs());
  //   }
  // }, [painlogs]);

  // Parse data without triggering a re-render
  const parsedDataArray = React.useMemo(() => {
    return painlogs?.pain_entries?.map((item) => {
      const bodyPartsData = JSON.parse(item?.body_parts_data);

      return {
        ...item,
        body_parts_data: bodyPartsData,
      };
    });
  }, [painlogs?.pain_entries]);

  const changeBodyPartsHandler = (e, bodyPart) => {
    e.stopPropagation();

    setSelectedBodyParts((prevSelected) => {
      if (prevSelected?.includes(bodyPart)) {
        return prevSelected.filter((part) => part !== bodyPart);
      } else {
        return [...prevSelected, bodyPart];
      }
    });

    // if (
    //   selectedBodyParts?.length < 4 ||
    //   selectedBodyParts?.includes(bodyPart)
    // ) {
    //   setSelectedBodyParts((prevSelected) => {
    //     if (prevSelected?.includes(bodyPart)) {
    //       if (prevSelected.length > 1) {
    //         return prevSelected.filter((part) => part !== bodyPart);
    //       } else {
    //         return prevSelected;
    //       }
    //     } else {
    //       return [...prevSelected, bodyPart];
    //     }
    //   });
    // }
  };

  // PREVIOUS FILTER -> ALL & BODY PARTS
  // const painlogsFilterSelectHandler = (e) => {
  //   const selectedValue = e.target.value;
  //   setSelectedBodyPart(selectedValue);

  //   // Filter the data based on the selected body part
  //   let filtered = parsedDataArray?.filter((item) => {
  //     return item?.body_parts_data?.some(
  //       (bodyPart) => `${bodyPart.side} ${bodyPart.name}` === selectedValue
  //     );
  //   });

  //   for (const pain_log of filtered) {
  //     var modified_body_parts_data = [];
  //     for (const body_part of pain_log.body_parts_data) {
  //       if (`${body_part.side} ${body_part.name}` === selectedValue) {
  //         modified_body_parts_data.push(body_part);
  //       }
  //     }
  //     pain_log.body_parts_data = modified_body_parts_data;
  //   }

  //   setFilteredPainLogData(filtered);
  // };

  const changePainDaysHandler = (e) => {
    if (e.target.value === "custom") {
      setOpenCustomPainLogDays(true);
      setPainLogDays(e.target.value);
    } else {
      setPainLogDays(e.target.value);
    }
  };

  const changeExpenseDaysHandler = (e) => {
    if (e.target.value === "custom") {
      setOpenCustomExpenseTypeDays(true);
      setExpenseDays(e.target.value);
    } else {
      setExpenseDays(e.target.value);
    }
  };

  const closeCustomPainLogDaysHandler = () => {
    setOpenCustomPainLogDays(false);
    setOpenCustomExpenseTypeDays(false);
  };

  const changePainHandler = (e) => {
    const selectedPainValue = e.target.value;
    setSelectedPain(selectedPainValue);
  };

  // GROUPED PAINLOGS BY DATE
  const groupedPainLogs = {};
  painlogs?.pain_entries?.forEach((painlog) => {
    const date = painlog.date;
    if (!groupedPainLogs[date]) {
      groupedPainLogs[date] = [];
    }
    groupedPainLogs[date].push(painlog);
  });

  // Get all dates between the first and last entry

  // const endDate = dayjs();
  // const startDate = endDate.subtract(painLogDays - 1, "day");

  let targetDate;
  if (caseDetail?.case?.incident_date) {
    targetDate = dayjs(caseDetail?.case?.incident_date);
  } else {
    targetDate = endDate?.subtract(painLogDays, "day");
  }

  const datesInRange = [];
  let currentDate = endDate;
  while (
    currentDate?.isAfter(startDate, "day") ||
    currentDate?.isSame(startDate, "day")
  ) {
    datesInRange?.push(currentDate?.format("YYYY-MM-DD"));
    if (currentDate?.isSame(targetDate, "day")) {
      break;
    }
    currentDate = currentDate?.subtract(1, "day");
  }

  const filteredPains = datesInRange
    ?.map((date) => {
      const existingEntry = painlogs?.pain_entries?.find(
        (item) => item.date === date
      );
      if (existingEntry) {
        const bodyPartsData = JSON.parse(existingEntry.body_parts_data);
        return {
          ...existingEntry,
          missed: false,
          body_parts_data: bodyPartsData,
        };
      } else {
        return {
          date,
          missed: true,
          agency_id: null,
          global_pain_level: null,
          body_parts_data: [],
        };
      }
    })
    .sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);

      return dateA.getTime() - dateB.getTime();
    });

  const filteredPainsWithPainLevel = filteredPains.filter(
    (item) => item.global_pain_level !== null
  );

  const missedPainLogsEntries = filteredPains?.filter(
    (item) => item?.missed === false
  );

  const sortCaseLogsHandler = () => {
    setReverseArray((prev) => !prev);
  };

  useEffect(() => {
    const updatedAllBodyParts = Array.from(
      new Set(
        parsedDataArray
          ?.filter((data) => data?.global_pain_level !== 0)
          ?.reduce((accumulator, item) => {
            if (Array.isArray(item?.body_parts_data)) {
              accumulator.push(
                ...item.body_parts_data.map(
                  (bodyPart) => `${bodyPart?.side} ${bodyPart?.name}`
                )
              );
            }
            return accumulator;
          }, [])
        // ?.flatMap((item) =>
        //   item?.body_parts_data?.map(
        //     (bodyPart) => `${bodyPart?.side} ${bodyPart?.name}`
        //   )
        // )
      )
    );

    if (updatedAllBodyParts) {
      setAllBodyParts(updatedAllBodyParts);
    }
  }, [painlogs, parsedDataArray]);

  // ================ EXPORTING FUNCTIONS =================

  const exportAsPngHandler = () => {
    if (!chartRef.current) return;

    const dataUrl = chartRef.current.toBase64Image("image/png", 1);

    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = `${caseTitle}_chart.png`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setExportChartEl(null);
  };

  const exportAsPdfHandler = async () => {
    const chartElement = chartRef.current;

    if (!chartElement) {
      return;
    }

    const beginDate = onlyDateFormat(filteredPains[0]?.date);
    const endingDate = onlyDateFormat(filteredPains[-1]?.date);

    try {
      const caseTitle = caseDetail?.case?.title;

      const caseNumber = `${
        caseDetail?.case?.case_type && caseDetail?.case?.case_type
      } #${caseDetail?.case?.case_number}`;

      const painLogs = "Pain Logs";
      const dateRange = `${beginDate} - ${endingDate}`;
      const sourceText = "Source: MyCaseCare";

      const dataUrl = chartElement.toBase64Image("image/png", 1);

      const pdf = new jsPDF({
        orientation: "landscape",
        unit: "mm",
        format: "a4",
      });

      pdf.setFontSize(20);
      // pdf.setTextColor(0, 0, 0);
      // pdf.setTextAlpha(0.87);
      pdf.text(caseTitle, 15, 10);

      pdf.setFontSize(16);
      pdf.text(caseNumber, 15, 18);
      pdf.text(painLogs, 15, 25);
      pdf.setTextColor("#566875");
      pdf.text(dateRange, 50, 25);

      pdf.setFontSize(10);
      pdf.addImage(
        dataUrl,
        "JPEG",
        15,
        30,
        pdf.internal.pageSize.width - 20,
        pdf.internal.pageSize.height - 40
      );

      pdf.setFontSize(12);
      pdf.text(
        sourceText,
        pdf.internal.pageSize.width - 50,
        pdf.internal.pageSize.height - 5
      );
      pdf.save(`${caseTitle}_chart.pdf`);
    } catch (error) {
      console.error("Error exporting to PDF:", error);
    }
    setExportChartEl(null);
  };

  const exportCsvHandler = () => {
    dispatch(
      getClientPainlogsFunc(
        caseDetail?.case?.id,
        caseDetail?.case?.client?.id,
        startDate.format("YYYY-MM-DD"),
        endDate.format("YYYY-MM-DD"),
        "all"
      )
    );
    setExportChartEl(null);
  };

  useEffect(() => {
    if (painlogs?.ok) {
      const csvData = exportCsvData(filteredPains);
      setExportedCsvData(csvData);
    }
  }, [painlogs]);

  const addCaseInBillingHandler = () => {
    const caseIdObj = {
      case_ID: caseDetail?.case?.id,
    };

    dispatch(addCaseToBillingSubscriptionFunc(caseIdObj));
  };

  useEffect(() => {
    if (caseAddedToSubscription?.ok) {
      setCaseAddedInPlan(true);
      setShowBillingPlan(false);
      setOpenSnackbar(true);
      setLoadingPlan(false);
      setConfirmPlan(false);
      dispatch(resetAddCaseToSubscription());
    }
  }, [caseAddedToSubscription]);

  const sendPainReminderHandler = (date) => {
    const painObj = {
      case_id: caseDetail?.case?.id,
      client_id: caseDetail?.case?.client?.id,
      date: dayjs(date).format("YYYY-MM-DD"),
    };

    dispatch(sendPainLogReminderFunc(painObj));
  };

  useEffect(() => {
    if (sentReminder?.ok) {
      dispatch(resetSendPainLogReminder());
      setOpenSnackbarReminder(true);
    }
  }, [sentReminder]);

  // CHANGE EXPENSES TYPE HANDLER
  const changeExpensesHandler = (itemName) => (event) => {
    setCheckedItems({
      ...checkedItems,
      [itemName]: event.target.checked,
    });
  };

  // PARENT CHECKBOX ALL LIST ITEM CHECKBOX HANDLER
  const changeAllExpensesHandler = (event) => {
    const { checked } = event.target;

    setCheckedItems({
      transportExpenses: checked,
      medicalExpenses: checked,
      salaryLoss: checked,
      others: checked,
    });
  };

  const openNoteHandler = () => {
    setModalContentData({ title: "Add Note" });
    setOpenAddNoteModal(true);
  };

  const closeNoteModalHandler = () => {
    setOpenAddNoteModal(false);
    setFieldIsTouched(false);
    setNoteValue("");
  };

  const clickFilterHandler = (value) => {
    let newSelectedFilters;

    if (value === "all") {
      newSelectedFilters = ["all"];
    } else if (selectedLogsFilters?.includes("all")) {
      newSelectedFilters = [value];
    } else {
      if (selectedLogsFilters.includes(value)) {
        newSelectedFilters = selectedLogsFilters.filter(
          (filter) => filter !== value
        );
      } else {
        newSelectedFilters = [...selectedLogsFilters, value];
      }
    }

    if (newSelectedFilters.length === 0) {
      newSelectedFilters = ["all"];
    }

    setSelectedLogsFilters(newSelectedFilters);
  };

  const showError = fieldIsTouched && noteValue?.trim() === "";

  const addingNoteHandler = () => {
    setFieldIsTouched(true);

    if (!noteValue?.length) {
      return;
    }

    const noteData = {
      case_id: caseDetail?.case?.id,
      client_id: caseDetail?.case?.client?.id,
      note: noteValue,
    };

    dispatch(addNoteToCaseFunc(noteData));
  };

  useEffect(() => {
    if (addedNote?.ok) {
      closeNoteModalHandler();
      dispatch(resetAddNoteToCase());
      dispatch(getCaseDetailByCaseId(caseDetail?.case?.id));
    }
  }, [addedNote]);

  const expenseAmounts = displayExpenseAmounts(expenseDetails);
  const totalExpenseAmount =
    expenseAmounts?.transportExpenseAmount +
    expenseAmounts?.medicalExpenseAmount +
    expenseAmounts?.salaryLossAmount +
    expenseAmounts?.otherAmount;

  const sortedCaseLogsData = reverseArray
    ? filteredData?.slice()?.reverse()
    : filteredData?.slice();

  return (
    <>
      {openSnackbarReminder && (
        <Snackbar
          open={openSnackbarReminder}
          autoHideDuration={2000}
          onClose={() => setOpenSnackbarReminder(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={() => setOpenSnackbarReminder(false)}
            severity="success"
            color="success"
            sx={{ width: "100%" }}
          >
            Reminder sent
          </Alert>
        </Snackbar>
      )}

      {/* OPEN CUSTOM PAIN DAYS ALERT MODAL */}
      {openCustomPainLogDays && (
        <AlertDialog
          openModal={openCustomPainLogDays}
          closeModal={closeCustomPainLogDaysHandler}
          data={{ title: "Date Range" }}
          submitText="Ok"
          formSubmitFunction={customPainDateHandler}
        >
          <CustomPainDateRange
            fromCustomDate={fromCustomDate}
            isTouchedCustomDate={isTouchedCustomDate}
            // customDateError={customDateError}
            toCustomDate={toCustomDate}
            setFromCustomDate={setFromCustomDate}
            setToCustomDate={setToCustomDate}
          />
        </AlertDialog>
      )}

      {/* OPEN ADD NOTE MODAL */}
      {openAddNoteModal && (
        <AlertDialog
          openModal={openAddNoteModal}
          closeModal={closeNoteModalHandler}
          data={modalContentData}
          submitText="Done"
          contentPadding="24px 32px"
          titlePadding="24px 32px"
          contentPaddingTop="24px !important"
          titlePaddingTop="24px"
          padding="16px 32px"
          actionsPaddingTop="0"
          maxWidth="556px"
          isLoading={loadingNote}
          formSubmitFunction={addingNoteHandler}
        >
          <Stack gap="16px">
            <TextField
              size="medium"
              multiline
              rows={6}
              value={noteValue}
              onChange={(e) => setNoteValue(e.target.value)}
              label="Start Typing Here"
              error={showError}
            />
          </Stack>
        </AlertDialog>
      )}

      {/* OPEN EXPENSE TYPE DAYS ALERT */}
      {openCustomExpenseTypeDays && (
        <AlertDialog
          openModal={openCustomExpenseTypeDays}
          closeModal={closeCustomPainLogDaysHandler}
          data={{ title: "Date Range" }}
          submitText="Ok"
          formSubmitFunction={customExpenseTypeDateHandler}
        >
          <CustomPainDateRange
            fromCustomDate={expenseTypeFromDate}
            toCustomDate={expenseTypeToDate}
            isTouchedCustomDate={isTouchedExpenseTypeDate}
            setFromCustomDate={setExpenseTypeFromDate}
            setToCustomDate={setExpenseTypeToDate}
          />
        </AlertDialog>
      )}

      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="flex-start"
        width="100%"
        gap="16px"
        position="relative"
      >
        {showBillingPlan && (
          <DialogOverlay open={showBillingPlan} width="441px">
            <Stack gap="24px" padding="24px">
              <Typography variant="body1" fontWeight={500} fontSize="20px">
                Add Case to Billing Plan{" "}
              </Typography>
              <Typography variant="body2">
                By adding this case to your billing plan,{" "}
                <Box component="span" fontWeight={600}>
                  {" "}
                  a monthly charge of $30 will apply.
                </Box>{" "}
              </Typography>
              <Stack
                gap="8px"
                borderRadius="8px"
                bgcolor="#F4F6F8"
                padding="8px"
                height="222px"
              >
                <Typography variant="body1" fontWeight={500}>
                  Case Details
                </Typography>
                <Stack gap="16px">
                  <Box display="flex">
                    <Stack gap="4px" width="50%">
                      <Typography variant="body2" color="secondary">
                        Case Title
                      </Typography>
                      {caseTitle ? (
                        <Typography variant="body1">{caseTitle}</Typography>
                      ) : (
                        <Typography variant="body1">-</Typography>
                      )}
                    </Stack>
                    <Stack gap="4px" width="50%">
                      <Typography variant="body2" color="secondary">
                        Case Number
                      </Typography>
                      {caseDetail?.case?.case_number ? (
                        <Typography variant="body1">
                          #{caseDetail?.case?.case_number}
                        </Typography>
                      ) : (
                        <Typography variant="body1">-</Typography>
                      )}
                    </Stack>
                  </Box>
                  <Stack gap="4px" width="50%">
                    <Typography variant="body2" color="secondary">
                      Associated Client{" "}
                    </Typography>
                    <Box display="flex" gap="4px" alignItems="center">
                      <Avatar
                        src={caseDetail?.case?.client?.profile_picture_url}
                        alt={caseDetail?.case?.client?.name}
                      />
                      <Stack>
                        {caseDetail?.case?.client?.name ? (
                          <Typography variant="body1">
                            {caseDetail?.case?.client?.name}
                          </Typography>
                        ) : (
                          <Typography variant="body1">-</Typography>
                        )}
                        {caseDetail?.case?.client?.email ? (
                          <Typography
                            variant="body1"
                            color="rgba(0, 0, 0, 0.38)"
                          >
                            {caseDetail?.case?.client?.email}
                          </Typography>
                        ) : (
                          <Typography
                            variant="body1"
                            color="rgba(0, 0, 0, 0.38)"
                          >
                            -{" "}
                          </Typography>
                        )}
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={confirmPlan}
                    onChange={(e) => setConfirmPlan(e.target.checked)}
                  />
                }
                label={
                  <span
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    I confirm that I want to add this case to my plan and accept
                    the additional monthly charge.{" "}
                  </span>
                }
              />
              <Box display="flex" alignSelf="flex-end" gap="16px">
                <Button
                  variant="contained"
                  sx={{
                    background: "#E0E0E0",
                    color: "rgba(0, 0, 0, 0.87)",
                    "&:hover": { background: "#fff" },
                  }}
                  onClick={() => {
                    setBillingActive(false);
                    setShowBillingPlan(false);
                    setConfirmPlan(false);
                  }}
                >
                  Cancel
                </Button>
                <LoadingButton
                  disabled={!confirmPlan}
                  variant="contained"
                  loading={loadingAddCaseToSubsc}
                  onClick={addCaseInBillingHandler}
                >
                  Add Case to plan
                </LoadingButton>
              </Box>
            </Stack>
          </DialogOverlay>
        )}

        {openSnackbar && (
          <Snackbar
            open={openSnackbar}
            autoHideDuration={3000}
            onClose={closeSnackbarHandler}
            anchorOrigin={{
              vertical: "top",
              horizontal: "center",
            }}
          >
            <Alert
              iconMapping={{
                success: <CheckCircleOutlineIcon fontSize="inherit" />,
              }}
              sx={{ fontSize: "16px", fontWeight: 500 }}
            >
              Case added to your current billing Plan{" "}
            </Alert>
          </Snackbar>
        )}
        <Stack gap={2} width="100%">
          {/* LINE CHART CARD ROW */}
          <CardUI
            maxWidth="100%"
            dashboard="true"
            padding="24px"
            height="100%"
            overflow={
              billingSubscription?.subscription &&
              !billingSubscription?.subscription?.custom_subscription &&
              !caseAddedInPlan &&
              "hidden"
            }
          >
            <Stack
              gap="16px"
              position="relative"
              height={painlogs?.pain_entries?.length <= 0 ? "100%" : "auto"}
            >
              {billingSubscription?.subscription &&
                !billingSubscription?.subscription?.custom_subscription &&
                !caseAddedInPlan &&
                !loadingCaseDetail &&
                !loadingBilling &&
                !loading && (
                  <Stack
                    gap="10px"
                    padding="24px 16px"
                    alignItems="center"
                    position="absolute"
                    top="0"
                    left="50%"
                    sx={{ transform: "translate(-50%, 50%)" }}
                    maxWidth="441px"
                    width="100%"
                    bgcolor="#fff"
                    borderRadius="8px"
                    boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
                    zIndex="1"
                  >
                    <Box width="67px">
                      <img src={restrictedImg} alt="restricted image" />
                    </Box>
                    <Typography
                      variant="body1"
                      fontWeight={500}
                      fontSize="18px"
                    >
                      Limited Access
                    </Typography>
                    <Typography variant="body2">
                      This case is not part of your current plan, resulting in
                      limited access. To add it to your plan and gain full
                      access, click below.{" "}
                    </Typography>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setShowBillingPlan(true);
                        setBillingActive(true);
                      }}
                    >
                      Add Case to plan
                    </Button>
                  </Stack>
                )}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  textTransform="uppercase"
                  fontSize="1rem"
                >
                  Client's Pain Logs
                </Typography>
                <Stack gap="8px" direction="row">
                  {/* FILTER DAYS  */}
                  <FormControl
                    disabled={
                      billingSubscription?.subscription &&
                      !billingSubscription?.subscription?.custom_subscription &&
                      !caseAddedInPlan
                    }
                  >
                    <FilterDaysDropDown
                      labelId="painlogDays"
                      id="painlogDays"
                      value={painLogDays}
                      iconComponent={ExpandMoreOutlinedIcon}
                      caseAddedInPlan={caseAddedInPlan}
                      changeFilterHandler={changePainDaysHandler}
                    />
                  </FormControl>

                  <BodyPartsFilter
                    billingActive={
                      !(
                        billingSubscription?.subscription &&
                        !billingSubscription?.subscription
                          ?.custom_subscription &&
                        !caseAddedInPlan
                      )
                    }
                    filterButtonRef={filterButtonRef}
                    selectBodyPartsHandler={selectBodyPartsHandler}
                    bodyPartsAnchorEl={bodyPartsAnchorEl}
                    selectedBodyParts={selectedBodyParts}
                    changeBodyPartsHandler={changeBodyPartsHandler}
                    allBodyParts={allBodyParts}
                    allBodyPartsData={allBodyPartsData}
                    setSelectedBodyParts={setSelectedBodyParts}
                    loading={loading}
                    loadingCaseDetail={loadingCaseDetail}
                  />
                </Stack>
              </Box>
              {loading || loadingCaseDetail ? (
                <CaseChartSkeleton />
              ) : (
                <>
                  {painlogs?.pain_entries?.length > 0 ? (
                    <Stack
                      position="relative"
                      gap="24px"
                      sx={{
                        "& canvas": {
                          width: "100% !important",
                        },

                        filter:
                          billingSubscription?.subscription &&
                          !billingSubscription?.subscription
                            ?.custom_subscription &&
                          !caseAddedInPlan &&
                          "blur(8.5px)",
                        background:
                          billingSubscription?.subscription &&
                          !billingSubscription?.subscription
                            ?.custom_subscription &&
                          !caseAddedInPlan &&
                          "rgba(225, 225, 225, 0.20)",
                      }}
                    >
                      {/* LINE CHART */}
                      <Stack bgcolor="#FCFCFC" borderRadius="8px">
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="12px"
                          padding="8px 26px"
                        >
                          <Box display="flex" alignItems="center">
                            <Typography
                              variant="body1"
                              color="secondary"
                              fontSize="0.875rem"
                            >
                              Showing
                            </Typography>{" "}
                            <FormControl sx={{ marginLeft: "0.5rem" }}>
                              <Select
                                labelId="pains"
                                id="pains"
                                value={selectedPain}
                                IconComponent={ExpandMoreOutlinedIcon}
                                displayEmpty
                                size="small"
                                sx={{
                                  fontSize: "0.75rem",
                                  textTransform: "capitalize !important",
                                  marginRight: "10px !important",
                                  color: "#000 !important",
                                  borderRadius: "4px !important",
                                  background: "#F4F6F8",
                                  "& .MuiSelect-select": {
                                    padding: "0.2rem 0.25rem",
                                  },
                                  "&:before": {
                                    borderColor: "white !important",
                                  },
                                  "&:after": {
                                    borderColor: "white !important",
                                  },
                                  boxShadow: "none !important",
                                  ".MuiOutlinedInput-notchedOutline": {
                                    border: "0  !important",
                                  },

                                  ".MuiSvgIcon-root ": {
                                    fill: "#000 !important",
                                  },
                                }}
                                onChange={changePainHandler}
                              >
                                {painOptions.map((option) => (
                                  <MenuItem
                                    key={option.value}
                                    value={option.value}
                                  >
                                    {option.label}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>{" "}
                            <Typography
                              variant="body1"
                              color="secondary"
                              fontSize="0.875rem"
                            >
                              pain for days with multiple entries.
                            </Typography>
                          </Box>

                          {/* EXPORT ICON PNG, PDF */}
                          <IconButton
                            sx={{
                              backgroundColor: "#F4F6F8",
                              borderRadius: "4px",
                              height: "28px",
                              padding: "3px 8px",
                            }}
                            id="export-chart-btn"
                            aria-controls={
                              openExportChart ? "export-chart-menu" : undefined
                            }
                            aria-haspopup="true"
                            aria-expanded={openExportChart ? "true" : undefined}
                            onClick={openExportListHandler}
                          >
                            <ExportIcon />
                          </IconButton>
                          <Menu
                            sx={{
                              "& .MuiPaper-root": {
                                minWidth: 142,
                              },
                            }}
                            id="export-chart-menu"
                            anchorEl={exportChartEl}
                            open={openExportChart}
                            onClose={exportChartCloseHandler}
                            MenuListProps={{
                              "aria-labelledby": "export-chart-btn",
                            }}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "right",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "right",
                            }}
                          >
                            <MenuItem onClick={exportAsPngHandler}>
                              PNG
                            </MenuItem>
                            <MenuItem onClick={exportAsPdfHandler}>
                              PDF
                            </MenuItem>
                            <MenuItem>
                              <CSVLink
                                asyncOnClick={true}
                                onClick={exportCsvHandler}
                                style={{
                                  textDecoration: "none",
                                  color: "inherit",
                                  "&:hover": {
                                    color: "inherit",
                                    textDecoration: "none",
                                  },
                                }}
                                data={exportedCsvData}
                                filename={`${caseTitle}.csv`}
                              >
                                CSV
                              </CSVLink>
                            </MenuItem>
                          </Menu>
                        </Box>

                        {missedPainLogsEntries?.length === 0 ? (
                          <Stack
                            alignItems="center"
                            justifyContent="center"
                            height="335px"
                          >
                            <Avatar
                              src={EmptyBox}
                              alt="coming soon"
                              sx={{ width: "128px", height: "128px" }}
                              gap="8px"
                            />
                            <Typography
                              variant="body1"
                              color="secondary"
                              textAlign="center"
                            >
                              No data available for this date range, <br /> try
                              picking different date range
                            </Typography>
                          </Stack>
                        ) : (
                          <Box>
                            <CurveChart
                              chartRef={chartRef}
                              allDates={filteredPains}
                              painEntries={filteredPainsWithPainLevel}
                              selectedBodyParts={selectedBodyParts}
                            />
                          </Box>
                        )}
                      </Stack>

                      {showPainLogsListing && (
                        <Stack gap="8px" height="400px" overflow="auto">
                          {filteredPains?.map((data, i) =>
                            // selectedBodyPart === "all" ? (
                            data?.missed ? (
                              <Box
                                key={i}
                                display="flex"
                                gap="24px"
                                bgcolor="#FCFCFC"
                                padding="8px 16px"
                                borderRadius="8px"
                                width="100%"
                              >
                                <PainLogsDate date={data?.date} />
                                <Stack
                                  gap="2px"
                                  flex="1"
                                  bgcolor="#D32F2F26"
                                  borderRadius="8px"
                                  padding="12px 10px"
                                  textAlign="center"
                                  color="#E76072"
                                >
                                  <Typography
                                    variant="body1"
                                    fontSize="12px"
                                    fontWeight={600}
                                  >
                                    {" "}
                                    Missed
                                  </Typography>
                                  <Typography
                                    variant="body2"
                                    color="rgba(0, 0, 0, 0.38)"
                                    sx={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                    }}
                                    onClick={() =>
                                      sendPainReminderHandler(data?.date)
                                    }
                                  >
                                    Send reminder to log pain
                                  </Typography>
                                </Stack>
                              </Box>
                            ) : (
                              <React.Fragment key={i}>
                                {data?.body_parts_data?.length ? (
                                  <Box
                                    key={i}
                                    display="flex"
                                    gap="24px"
                                    bgcolor="#FCFCFC"
                                    padding="16px"
                                    borderRadius="8px"
                                    width="100%"
                                  >
                                    <PainLogsDate date={data?.date} />
                                    <Box
                                      sx={{
                                        width: "100%",
                                        padding: "0 !important",
                                        // paddingLeft: "84px !important",
                                        paddingBottom: "12px !important",
                                        paddingRight: "16px !important",
                                      }}
                                    >
                                      {data?.body_parts_data?.map(
                                        (bodyPart, i) => (
                                          <PainLogDetail
                                            key={i}
                                            index={i}
                                            bodyPart={bodyPart}
                                            data={data}
                                          />
                                        )
                                      )}
                                    </Box>
                                  </Box>
                                ) : (
                                  <></>
                                )}
                              </React.Fragment>
                            )
                          )}
                        </Stack>
                      )}

                      <CollapsableUIBox
                        showListings={showPainLogsListing}
                        toggleCollapsableBoxHandler={() =>
                          setShowPainLogsListing((prev) => !prev)
                        }
                      />
                    </Stack>
                  ) : (
                    <Stack
                      alignItems="center"
                      justifyContent="center"
                      height="335px"
                    >
                      <Avatar
                        src={EmptyBox}
                        alt="coming soon"
                        sx={{ width: "128px", height: "128px" }}
                        gap="8px"
                      />
                      <Typography
                        variant="body1"
                        color="secondary"
                        textAlign="center"
                      >
                        No pain logs available.
                        <br />
                        Once user begin logging pain, it will start showing
                        here.
                      </Typography>
                    </Stack>
                  )}
                </>
              )}
            </Stack>
          </CardUI>

          {/* EXPENSE CARD ROW */}

          <CardUI maxWidth="100%" dashboard="true" padding="24px" height="100%">
            <Stack gap="24px">
              {/* EXPENSE HEADING ROW */}
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  textTransform="uppercase"
                  fontSize="1rem"
                >
                  Expenses
                </Typography>
                <Box gap="8px" direction="row">
                  {/* EXPENSE FILTER DAYS */}
                  <FormControl
                    disabled={
                      billingSubscription?.subscription &&
                      !billingSubscription?.subscription?.custom_subscription &&
                      !caseAddedInPlan
                    }
                  >
                    <FilterDaysDropDown
                      labelId="expenseDays"
                      id="expenseDays"
                      value={expenseDays}
                      iconComponent={ExpandMoreOutlinedIcon}
                      caseAddedInPlan={caseAddedInPlan}
                      changeFilterHandler={changeExpenseDaysHandler}
                    />
                  </FormControl>
                  <ExpenseTypeFilter
                    selectExpenseTypeHandler={selectExpenseTypeHandler}
                    expenseTypeAnchorEl={expenseTypeAnchorEl}
                    checkedItems={checkedItems}
                    changeAllExpensesHandler={changeAllExpensesHandler}
                    changeExpensesHandler={changeExpensesHandler}
                    isAllChecked={isAllChecked}
                    isAllUnchecked={isAllUnchecked}
                  />
                </Box>
              </Box>

              {loadingExpense || loadingCaseDetail ? (
                <RectangleSkeletons height={300} totalRows={1} />
              ) : expenses?.expenses?.length === 0 ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="287px"
                  gap="8px"
                >
                  <Avatar
                    src={EmptyBox}
                    alt="coming soon"
                    sx={{ width: "128px", height: "128px" }}
                  />

                  <Typography
                    variant="body2"
                    color="secondary"
                    textAlign="center"
                  >
                    No expense data available <br /> Once user begin adding
                    expense, it will start showing here.
                  </Typography>
                </Stack>
              ) : expenseDetails?.length === 0 ? (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  height="287px"
                  gap="8px"
                >
                  <Avatar
                    src={EmptyBox}
                    alt="coming soon"
                    sx={{ width: "128px", height: "128px" }}
                  />

                  <Typography
                    variant="body2"
                    color="secondary"
                    textAlign="center"
                  >
                    No data available for this date range, <br /> try picking
                    different date range
                  </Typography>
                </Stack>
              ) : (
                <>
                  <Box
                    borderRadius="8px"
                    bgcolor="#FCFCFC"
                    display="flex"
                    alignItems="flex-start"
                    gap="41px"
                    padding="16px"
                  >
                    <Box width="199.463px" height="199.463px">
                      <DoughnutChartUI
                        transportAmount={expenseAmounts.transportExpenseAmount}
                        medicalAmount={expenseAmounts.medicalExpenseAmount}
                        salaryLossAmount={expenseAmounts.salaryLossAmount}
                        otherAmount={expenseAmounts.otherAmount}
                      />
                    </Box>
                    <Stack width="100%" gap={2} alignItems="flex-start">
                      <Stack gap={1} width="100%">
                        <Stack>
                          <Typography
                            variant="body2"
                            color="#13132B"
                            fontWeight={500}
                          >
                            Total Expenses
                          </Typography>
                          <Typography
                            variant="h3"
                            color="#13132B"
                            fontWeight={400}
                            fontSize="34px"
                          >
                            ${totalExpenseAmount.toFixed(2)}
                          </Typography>
                        </Stack>

                        <Grid
                          container
                          rowSpacing={2}
                          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
                          color="#13132B"
                        >
                          {expenseAmounts?.transportExpenseAmount > 0 ? (
                            <Grid item xs={6}>
                              <Stack
                                borderLeft="4px solid #3F3CD8"
                                pl={1}
                                height="100%"
                              >
                                <Typography variant="body2" fontWeight={600}>
                                  Transport Expenses
                                </Typography>
                                <Typography variant="body2">
                                  ${" "}
                                  {expenseAmounts.transportExpenseAmount.toFixed(
                                    2
                                  )}
                                </Typography>
                              </Stack>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {expenseAmounts?.medicalExpenseAmount ? (
                            <Grid item xs={6}>
                              <Stack
                                borderLeft="4px solid #4EB9ED"
                                pl={1}
                                height="100%"
                              >
                                <Typography variant="body2" fontWeight={600}>
                                  Medical Expenses
                                </Typography>
                                <Typography variant="body2">
                                  ${" "}
                                  {expenseAmounts.medicalExpenseAmount.toFixed(
                                    2
                                  )}
                                </Typography>
                              </Stack>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {expenseAmounts.salaryLossAmount ? (
                            <Grid item xs={6}>
                              <Stack
                                borderLeft="4px solid #FAD44D"
                                pl={1}
                                height="100%"
                              >
                                <Typography variant="body2" fontWeight={600}>
                                  Salary Loss
                                </Typography>
                                <Typography variant="body2">
                                  $ {expenseAmounts.salaryLossAmount.toFixed(2)}
                                </Typography>
                              </Stack>
                            </Grid>
                          ) : (
                            <></>
                          )}
                          {expenseAmounts.otherAmount ? (
                            <Grid item xs={6}>
                              <Stack
                                borderLeft="4px solid #EA981C"
                                pl={1}
                                height="100%"
                              >
                                <Typography variant="body2" fontWeight={600}>
                                  Other Expenses
                                </Typography>
                                <Typography variant="body2">
                                  $ {expenseAmounts.otherAmount.toFixed(2)}
                                </Typography>
                              </Stack>
                            </Grid>
                          ) : (
                            <></>
                          )}
                        </Grid>
                      </Stack>

                      {!showExpenseListings && (
                        <CollapsableUIBox
                          showListings={showExpenseListings}
                          toggleCollapsableBoxHandler={() =>
                            setShowExpenseListings((prev) => !prev)
                          }
                        />
                      )}
                    </Stack>
                  </Box>
                  {/* EXPENSE LISTINGS */}
                  {showExpenseListings && (
                    <Stack sx={{ width: "100%", overflow: "hidden" }}>
                      <TableContainer sx={{ maxHeight: 375 }}>
                        <Table stickyHeader aria-label="expense table">
                          <TableHead>
                            <TableRow>
                              <TableCell>Date</TableCell>
                              <TableCell align="left">Title</TableCell>
                              <TableCell align="left">Type</TableCell>
                              <TableCell align="left">Amount</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {expenseDetails?.map((row, index) => (
                              <TableRow
                                key={index}
                                sx={{
                                  "&:last-child td, &:last-child th": {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  align="left"
                                  sx={{ color: "rgba(0, 0, 0, 0.38)" }}
                                >
                                  {dateTimeFormat(row.date)}
                                </TableCell>
                                <TableCell align="left">{row.title}</TableCell>
                                <TableCell
                                  align="left"
                                  sx={{ color: "#212121", fontWeight: 500 }}
                                >
                                  {showExpenseTypeName(row.expense_type)}
                                </TableCell>
                                <TableCell align="left">
                                  $ {row.total_amount}
                                </TableCell>
                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  )}
                  {showExpenseListings && (
                    <CollapsableUIBox
                      showListings={showExpenseListings}
                      toggleCollapsableBoxHandler={() =>
                        setShowExpenseListings((prev) => !prev)
                      }
                    />
                  )}
                </>
              )}
              {/* EXPENSE DOUGHNUT CHART */}
            </Stack>
          </CardUI>
        </Stack>

        {/* LATEST ACTIVITY */}
        <CardUI
          maxWidth="368px"
          dashboard="true"
          height="746px"
          padding="24px"
          overflow={
            billingSubscription?.subscription &&
            !billingSubscription?.subscription?.custom_subscription &&
            !caseAddedInPlan &&
            "hidden"
          }
        >
          <Stack gap="12px" height="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                gap="12px"
              >
                <Typography
                  variant="subtitle1"
                  fontSize="16px"
                  color="secondary"
                  sx={{ textTransform: "uppercase" }}
                >
                  Case Logs
                </Typography>
                <IconButton
                  onClick={sortCaseLogsHandler}
                  sx={{
                    transform: reverseArray ? "rotate(180deg)" : "rotate(0deg)",
                  }}
                >
                  <SortIcon />
                </IconButton>
              </Box>
              <Button
                startIcon={<AddIcon />}
                onClick={openNoteHandler}
                color="primary"
                variant="text"
                fontSize="13px"
              >
                Add
              </Button>
            </Box>

            <Box
              display="flex"
              alignItems="center"
              gap="8px"
              flexWrap="wrap"
              pb="12px"
            >
              {caseLogsOptions?.map((item, index) => (
                <Box
                  key={index}
                  padding="3px 10px"
                  borderRadius="4px"
                  bgcolor={
                    selectedLogsFilters?.includes(item?.value)
                      ? `${item?.bgColor}`
                      : "#F4F6F8"
                  }
                  color="#0D0D0D"
                  sx={{
                    boxShadow: selectedLogsFilters?.includes(item?.value)
                      ? `inset 0 0 0 1.5px ${item.borderColor}`
                      : ``,
                    "&:hover": {
                      background: `${item?.hoverBgColor}`,
                      color: "#fff !important",
                      transition: "background 300ms ease-out",
                    },
                    cursor: "pointer",
                  }}
                  onClick={() => clickFilterHandler(item.value)}
                >
                  <Typography variant="body2" fontSize="12px">
                    {" "}
                    {item.title}
                  </Typography>
                </Box>
              ))}
            </Box>

            {/*  CASE LOGS LISTINGS  */}
            {loadingCaseDetail ? (
              <CaseLogsSkeleton />
            ) : filteredData?.length === 0 ? (
              <Stack alignItems="center" justifyContent="center" height="100%">
                <Avatar
                  src={EmptyBox}
                  alt="coming soon"
                  sx={{ width: "128px", height: "128px" }}
                />
                <Typography variant="body1" color="secondary">
                  No Activity Yet
                </Typography>
              </Stack>
            ) : (
              <Box
                sx={{
                  filter:
                    billingSubscription?.subscription &&
                    !billingSubscription?.subscription?.custom_subscription &&
                    !caseAddedInPlan &&
                    "blur(8.5px)",
                  background:
                    billingSubscription?.subscription &&
                    !billingSubscription?.subscription?.custom_subscription &&
                    !caseAddedInPlan &&
                    "rgba(225, 225, 225, 0.20)",
                }}
              >
                <Timeline>
                  {sortedCaseLogsData?.map((step, index) => (
                    <TimelineItem
                      key={index}
                      sx={{ paddingLeft: 0, "::before": { content: "none" } }}
                    >
                      <TimelineSeparator>
                        <TimelineDot
                          sx={{ backgroundColor: stepperIcon(step.log_type) }}
                        />
                        {index !== filteredData?.length - 1 && (
                          <TimelineConnector
                            sx={{ backgroundColor: "#E9ECEE" }}
                          />
                        )}
                      </TimelineSeparator>
                      <TimelineContent pb="16px !important">
                        <Typography
                          variant="body2"
                          pb="2px"
                          sx={{ textTransform: "capitalize" }}
                        >
                          {" "}
                          {renderStepContent(step)}
                        </Typography>
                        {step.appointment_reason && (
                          <Typography color="#212121" variant="body2">
                            Reason: {step.appointment_reason}
                          </Typography>
                        )}
                        <Box display="flex" alignItems="center" gap="4px">
                          <Typography
                            color="rgba(0, 0, 0, 0.38)"
                            fontWeight={500}
                            variant="body2"
                          >
                            {caseLogsCategory[step.log_type]}
                          </Typography>
                          <Ellipse />
                          <Typography
                            fontSize="12px"
                            color="secondary"
                            variant="body1"
                          >
                            {dayjs(step.timestamp).format("hh:mma, DD MMMM")}
                          </Typography>
                        </Box>
                      </TimelineContent>
                    </TimelineItem>
                  ))}
                </Timeline>
              </Box>
            )}
          </Stack>
        </CardUI>
      </Box>
    </>
  );
};

export default OverviewTab;
