import { Box, Typography } from "@mui/material";
import React from "react";
import CollapseUpIcon from "../../icons/CollapseUpIcon";
import CollapseDownIcon from "../../icons/CollapseDownIcon";

const CollapsableUIBox = ({ showListings, toggleCollapsableBoxHandler }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      gap="6px"
      justifyContent="center"
      onClick={toggleCollapsableBoxHandler}
      sx={{ cursor: "pointer" }}
    >
      <Typography
        variant="body2"
        color="primary"
        fontSize="12px"
        sx={{ textDecoration: "underline" }}
      >
        {showListings ? "Show Less" : "Show Detailed Logs"}
      </Typography>
      {showListings ? <CollapseUpIcon /> : <CollapseDownIcon />}
    </Box>
  );
};

export default CollapsableUIBox;
