export const GET_CLIENT_INTAKE_LISTS_REQUEST =
  "GET_CLIENT_INTAKE_LISTS_REQUEST";
export const GET_CLIENT_INTAKE_LISTS_SUCCESS =
  "GET_CLIENT_INTAKE_LISTS_SUCCESS";
export const GET_CLIENT_INTAKE_LISTS_FAIL = "GET_CLIENT_INTAKE_LISTS_FAIL";

export const GET_PRESET_REQUEST = "GET_PRESET_REQUEST";
export const GET_PRESET_SUCCESS = "GET_PRESET_SUCCESS";
export const GET_PRESET_FAIL = "GET_PRESET_FAIL";
export const RESET_PRESET_DETAIL = "RESET_PRESET_DETAIL";

export const CREATE_PRESET_REQUEST = "CREATE_PRESET_REQUEST";
export const CREATE_PRESET_SUCCESS = "CREATE_PRESET_SUCCESS";
export const CREATE_PRESET_FAIL = "CREATE_PRESET_FAIL";
export const RESET_CREATE_PRESET = "RESET_CREATE_PRESET";

export const UPDATE_PRESET_REQUEST = "UPDATE_PRESET_REQUEST";
export const UPDATE_PRESET_SUCCESS = "UPDATE_PRESET_SUCCESS";
export const UPDATE_PRESET_FAIL = "UPDATE_PRESET_FAIL";
export const RESET_UPDATE_PRESET = "RESET_UPDATE_PRESET";

export const DELETE_PRESET_REQUEST = "DELETE_PRESET_REQUEST";
export const DELETE_PRESET_SUCCESS = "DELETE_PRESET_SUCCESS";
export const DELETE_PRESET_FAIL = "DELETE_PRESET_FAIL";
export const RESET_DELETE_PRESET = "RESET_DELETE_PRESET";

export const INTAKE_QUESTIONS_ANSWERS_REQUEST =
  "INTAKE_QUESTIONS_ANSWERS_REQUEST";
export const INTAKE_QUESTIONS_ANSWERS_SUCCESS =
  "INTAKE_QUESTIONS_ANSWERS_SUCCESS";
export const INTAKE_QUESTIONS_ANSWERS_FAIL = "INTAKE_QUESTIONS_ANSWERS_FAIL";
export const RESET_INTAKE_QUESTIONS_ANSWERS = "RESET_INTAKE_QUESTIONS_ANSWERS";

export const INTAKE_REMINDER_REQUEST = "INTAKE_REMINDER_REQUEST";
export const INTAKE_REMINDER_SUCCESS = "INTAKE_REMINDER_SUCCESS";
export const INTAKE_REMINDER_FAIL = "INTAKE_REMINDER_FAIL";
export const RESET_INTAKE_REMINDER = "RESET_INTAKE_REMINDER";

export const GET_QUESTIONS_BY_CTYPE_REQUEST = "GET_QUESTIONS_BY_CTYPE_REQUEST";
export const GET_QUESTIONS_BY_CTYPE_SUCCESS = "GET_QUESTIONS_BY_CTYPE_SUCCESS";
export const GET_QUESTIONS_BY_CTYPE_FAIL = "GET_QUESTIONS_BY_CTYPE_FAIL";
export const RESET_GET_QUESTIONS_BY_CTYPE = "RESET_GET_QUESTIONS_BY_CTYPE";

export const ADD_INTAKE_ANSWER_REQUEST = "ADD_INTAKE_ANSWER_REQUEST";
export const ADD_INTAKE_ANSWER_SUCCESS = "ADD_INTAKE_ANSWER_SUCCESS";
export const ADD_INTAKE_ANSWER_FAIL = "ADD_INTAKE_ANSWER_FAIL";
export const RESET_ADD_INTAKE_ANSWER = "RESET_ADD_INTAKE_ANSWER";
