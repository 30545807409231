import React, { useEffect, useState } from "react";
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Avatar,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  Divider,
  FormControlLabel,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Textfield from "../../../common/Textfield";
import CompanyLogo from "../../../assets/images/company-logo.jpg";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
  getNotificationsListFunc,
  postNotificationFunc,
} from "../../../actions/Referrals";
import { getAgencyDetailFunc } from "../../../actions/Agency";
import CircularProgressLoading from "../../../common/CircularProgress";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";

const ScheduleDeliveryStep = ({
  activeStep,
  steps,
  handleBack,
  formValues,
  closeStepperForm,
  clientMembersArr,
}) => {
  const dispatch = useDispatch();
  const postNotification = useSelector((state) => state.postNotification);
  const { loading, error, newNotification } = postNotification;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  // console.log("FILTER", filteredClients);

  const clientIds = clientMembersArr.map((client) => client.id);

  // SUBMIT  FORM
  const submitNotificationMessageHandler = async (e) => {
    e.preventDefault();

    const notificationMessageData = {
      client_ids: clientIds,
      title: formValues.messageTitle,
      body: formValues.messageDescription,
      notification_time: dayjs(new Date()).format(),
    };

    dispatch(postNotificationFunc(notificationMessageData));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({ event: "new_pn_created", agent: agentUser?.agent, agency: agencyDetail?.agency })
    }
  };

  useEffect(() => {
    if (!loading && newNotification?.ok) {
      dispatch(getNotificationsListFunc());
      closeStepperForm();
    }
  }, [newNotification]);

  return (
    <>
      {loading && <CircularProgressLoading />}
      <DialogContent sx={{ padding: "32px !important", height: "462px" }}>
        <Stack gap="16px">
          <Alert severity="info">
            <AlertTitle>Schedule Feature is Coming Soon</AlertTitle>
            You will be able to select a date and time to deliver your push
            notification.
          </Alert>
          <FormControlLabel
            htmlFor="send-message"
            disabled
            control={<Checkbox id="send-message" defaultChecked />}
            label="Send the message now"
          />
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          padding: "16px 32px",
          boxShadow: "0px -2px 16px 0px rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button
          color="primary"
          onClick={handleBack}
          sx={{ textTransform: "uppercase", fontSize: "15px" }}
        >
          Go back
        </Button>
        <Button variant="contained" onClick={submitNotificationMessageHandler}>
          {activeStep === steps.length - 1 ? "Finish" : "Continue"}
        </Button>
      </DialogActions>
    </>
  );
};

export default ScheduleDeliveryStep;
