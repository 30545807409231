import React, { useEffect, useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Drawer,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DragIndicator as DragIndicatorIcon } from "@mui/icons-material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import CheckIcon from "@mui/icons-material/Check";
import SendIcon from "../../../../icons/SendIcon";
import { makeStyles } from "@mui/styles";
import Style from "./style";
import DeleteIcon from "../../../../icons/DeleteIcon";
import { LoadingButton } from "@mui/lab";
import CircularProgressLoading from "../../../../common/CircularProgress";
import AlertDialog from "../../../../components/AlertDialog";
import { EditIcon } from "../../../../assets/constants";
import DragIcon from "../../../../assets/images/drag-icon.png";
import IntakeQuestionField from "../../../../components/IntakeQuestionField";
import {
  caseTypeName,
  caseTypeOptions,
  caseTypeValues,
} from "../../../../utils/helpers";

// const caseTypesOption = [
//   "Personal Injury",
//   "Auto Accident",
//   "Slip & Fall",
//   "Premises Liability",
//   "Dog Bite",
//   "Pedestrian Accident",
//   "Other",
// ];

const Styles = makeStyles((theme) => Style(theme));

const IntakeFormDrawer = ({
  anchor,
  open,
  onClose,
  // questionsArr,
  // setQuestionsArr,
  // documents,
  // setDocuments,
  onSubmitPresetForm,
  loadingCreatedPreset,
  updatingPreset,
  singlePreset,
  loadingPresetDetail,
  loadingUpdatePreset,
  defaultQuestions,
  defaultDocuments,
}) => {
  const classes = Styles();

  const updatedDocuments =
    singlePreset && JSON.parse(singlePreset?.required_documents);

  const [caseType, setCaseType] = useState("");
  const [isRequired, setIsRequired] = useState(false);
  const [showHelperText, setShowHelperText] = useState(false);
  const [helperTextValues, setHelperTextValues] = useState("");
  const [questionsArr, setQuestionsArr] = useState([]);
  const [questionText, setQuestionText] = useState("");
  const [documents, setDocuments] = useState([]);
  const [isTouched, setIsTouched] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [changesMade, setChangesMade] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [showIntakeQuestionField, setShowIntakeQuestionField] = useState(true);

  const caseTypeHandler = (e) => {
    setChangesMade(true);
    setCaseType(e.target.value);
  };

  useEffect(() => {
    if (updatingPreset) {
      setQuestionsArr(singlePreset?.intake_questions);
      setDocuments(updatedDocuments);
      // setCaseType(singlePreset?.name || "");
    } else {
      setCaseType("");
      setQuestionsArr((prevState) => prevState.concat(defaultQuestions));
      setDocuments((prevState) => prevState.concat(defaultDocuments));
    }
  }, [singlePreset]);

  const saveQuestionHandler = () => {
    setIsTouched(true);

    if (!questionText) return;
    setChangesMade(true);
    const questionObj = {
      question_text: questionText,
      question_type: "text",
      is_required: isRequired,
      helper_text: helperTextValues,
    };

    if (editIndex !== null) {
      // Update existing question
      setQuestionsArr((prev) =>
        prev.map((item, index) =>
          index === editIndex ? { ...item, ...questionObj } : item
        )
      );
      setEditIndex(null);
      setShowIntakeQuestionField(true);
    } else {
      // Add new question
      setQuestionsArr((prev) => [questionObj, ...prev]);
    }

    setQuestionText("");
    setIsRequired(false);
    setIsTouched(false);
    setHelperTextValues("");
    setShowHelperText(false);
  };

  const showError = isTouched && !questionText;
  //

  const deleteQuestionHandler = (i) => {
    setChangesMade(true);
    setEditIndex(null);
    setQuestionText("");
    setHelperTextValues("");
    setIsRequired(false);
    setShowHelperText(false);
    setQuestionsArr((prevState) => prevState.filter((_, index) => index !== i));
  };

  const editQuestionHandler = (index) => {
    setShowIntakeQuestionField(false);
    setChangesMade(true);
    setEditIndex(index);
    const editedQuestion = questionsArr[index];
    setQuestionText(editedQuestion?.question_text);
    setHelperTextValues(editedQuestion?.helper_text || "");
    setShowHelperText(editedQuestion?.helper_text ? true : false);
    setIsRequired(editedQuestion?.is_required || false);
  };

  const submitFormHandler = () => {
    let presetObj;

    if (updatingPreset) {
      presetObj = {
        intake_preset_id: singlePreset?.id,
        questions: questionsArr,
        required_documents: JSON.stringify(documents),
      };
    } else {
      presetObj = {
        name: caseTypeName[caseType],
        questions: questionsArr,
        required_documents: JSON.stringify(documents),
      };
    }

    onSubmitPresetForm(presetObj);
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.index !== destination.index) {
      const newQuestions = Array.from(questionsArr);
      const [removed] = newQuestions.splice(source.index, 1);
      newQuestions.splice(destination.index, 0, removed);

      // Update the order in your state
      const reorderedQuestions = newQuestions?.map((q, index) => ({
        ...q,
        question_order: index + 1, // Assuming your order starts at 1
      }));

      // Set this new array to your state
      setQuestionsArr(reorderedQuestions);
    }
  };

  const changeHelperTextHandler = (e) => {
    if (!e.target.checked) {
      setHelperTextValues("");
      setShowHelperText(e.target.checked);
    } else {
      setShowHelperText(e.target.checked);
    }
  };

  const closeFormDialogHandler = () => {
    if (changesMade) {
      setOpenModal(true);
      setModalContentData({
        title: "Are you sure?",
        subtitle: "All the progress you made will be lost.",
      });
    } else {
      onClose();
    }
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  const changeTextHandler = (e) => {
    setChangesMade(true);
    setQuestionText(e.target.value);
  };

  return (
    <>
      {openModal && (
        <AlertDialog
          openModal={openModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          formSubmitFunction={onClose}
          color="error"
        />
      )}

      <Drawer
        anchor={anchor}
        open={open}
        // onClose={() => {
        //   onClose();
        // }}
      >
        <Stack
          p={4}
          className={classes.drawer_width}
          gap={3}
          component="form"
          // onSubmit={inviteAgentHandler}
          height="100%"
        >
          {loadingPresetDetail ? (
            <CircularProgressLoading height="100vh" />
          ) : (
            <>
              <Stack gap={3} flex="1" sx={{ overflowY: "auto" }}>
                <Stack
                  justifyContent="space-between"
                  direction="row"
                  alignItems="center"
                >
                  <Typography variant="h5">
                    {updatingPreset
                      ? singlePreset?.is_default
                        ? "Default Intake Form"
                        : `${singlePreset?.name} Intake Form`
                      : "New Intake Form"}
                  </Typography>
                  <IconButton size="small" onClick={closeFormDialogHandler}>
                    <CancelOutlinedIcon />
                  </IconButton>
                </Stack>
                <Typography
                  variant="boxHeading"
                  color="disabled.boxHeading"
                  sx={{ textTransform: "uppercase" }}
                >
                  case details
                </Typography>

                {/* CASE TYPE */}
                <FormControl
                  fullWidth
                  disabled={updatingPreset}
                  // required
                  // error={isTouched && !formValues.caseType}
                >
                  <InputLabel id="case_type">Type of case</InputLabel>
                  <Select
                    labelId="case_type"
                    id="case_type"
                    label="Type of case"
                    defaultValue="All"
                    value={
                      updatingPreset
                        ? singlePreset?.is_default
                          ? "All"
                          : caseTypeValues[singlePreset?.name] || ""
                        : caseType
                    }
                    onChange={caseTypeHandler}
                  >
                    {updatingPreset && singlePreset?.is_default ? (
                      <MenuItem value="All">All</MenuItem>
                    ) : (
                      caseTypeOptions?.map((option, index) => (
                        <MenuItem key={option?.title} value={option?.value}>
                          {option?.title}
                        </MenuItem>
                      ))
                    )}
                  </Select>
                </FormControl>

                <Autocomplete
                  multiple
                  id="tags"
                  options={[]}
                  freeSolo
                  // defaultValue={documents}
                  value={documents || []}
                  onChange={(e, value) => {
                    if (value) {
                      setChangesMade(true);
                      setDocuments(value);
                    }
                  }}
                  getOptionLabel={(option) => option}
                  renderTags={(value, props) =>
                    value?.map((option, index) => (
                      <Chip label={option} {...props({ index })} />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Default Required Documents"
                    />
                  )}
                />

                <Typography
                  variant="boxHeading"
                  color="disabled.boxHeading"
                  sx={{ textTransform: "uppercase" }}
                >
                  Questions
                </Typography>

                <Stack gap="12px">
                  {showIntakeQuestionField && (
                    <IntakeQuestionField
                      questionText={questionText}
                      changeTextHandler={changeTextHandler}
                      showError={showError}
                      saveQuestionHandler={saveQuestionHandler}
                      isRequired={isRequired}
                      setIsRequired={setIsRequired}
                      showHelperText={showHelperText}
                      changeHelperTextHandler={changeHelperTextHandler}
                      helperTextValues={helperTextValues}
                      setHelperTextValues={setHelperTextValues}
                      label="Question"
                    />
                  )}

                  <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="droppable-questions">
                      {(provided) => (
                        <Stack
                          gap="8px"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {questionsArr?.map((question, index) => (
                            <React.Fragment key={index}>
                              {editIndex === index ? (
                                <IntakeQuestionField
                                  questionText={questionText}
                                  changeTextHandler={changeTextHandler}
                                  showError={showError}
                                  saveQuestionHandler={saveQuestionHandler}
                                  isRequired={isRequired}
                                  setIsRequired={setIsRequired}
                                  showHelperText={showHelperText}
                                  changeHelperTextHandler={
                                    changeHelperTextHandler
                                  }
                                  helperTextValues={helperTextValues}
                                  setHelperTextValues={setHelperTextValues}
                                  label="Question"
                                />
                              ) : (
                                <Draggable
                                  key={index}
                                  draggableId={index?.toString()}
                                  index={index}
                                >
                                  {(provided) => (
                                    <Box
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      display="flex"
                                      alignItems="center"
                                      borderRadius="4px"
                                      border="1px solid #EBEBEB"
                                      padding="16px"
                                      gap="16px"
                                      style={{
                                        ...provided.draggableProps.style,
                                      }}
                                    >
                                      <Box {...provided.dragHandleProps}>
                                        <img src={DragIcon} alt="drag icon" />
                                      </Box>{" "}
                                      <Stack flex="1">
                                        <Typography
                                          variant="body1"
                                          fontWeight="500"
                                          sx={{
                                            textOverflow: "ellipsis",
                                            whiteSpace: "nowrap",
                                            width: "440px",
                                            overflow: "hidden",
                                          }}
                                        >
                                          {question?.question_text}
                                        </Typography>
                                        {question?.helper_text && (
                                          <Typography
                                            variant="body2"
                                            fontSize="12px"
                                            sx={{
                                              textOverflow: "ellipsis",
                                              whiteSpace: "nowrap",
                                              width: "440px",
                                              overflow: "hidden",
                                            }}
                                          >
                                            {question?.helper_text}
                                          </Typography>
                                        )}
                                      </Stack>
                                      {question?.is_required && (
                                        <Typography
                                          width="134px"
                                          variant="caption"
                                        >
                                          Required
                                        </Typography>
                                      )}
                                      <Box
                                        display="flex"
                                        alignItems="center"
                                        gap="16px"
                                      >
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            editQuestionHandler(index);
                                          }}
                                        >
                                          <EditIcon />
                                        </IconButton>
                                        <IconButton
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            deleteQuestionHandler(index);
                                          }}
                                        >
                                          <DeleteIcon />
                                        </IconButton>
                                      </Box>
                                    </Box>
                                  )}
                                </Draggable>
                              )}
                            </React.Fragment>
                          ))}
                          {provided.placeholder}
                        </Stack>
                      )}
                    </Droppable>
                  </DragDropContext>
                </Stack>
              </Stack>
              <Stack alignItems="flex-end" pt={1.4}>
                <LoadingButton
                  variant="contained"
                  onClick={submitFormHandler}
                  disabled={!updatingPreset && !caseType}
                  loading={
                    updatingPreset ? loadingUpdatePreset : loadingCreatedPreset
                  }
                >
                  Save
                </LoadingButton>
              </Stack>
            </>
          )}
        </Stack>
      </Drawer>
    </>
  );
};

export default IntakeFormDrawer;
