import React from "react";

const CollapseUpIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
    >
      <path
        d="M1.86988 6.89746L4.85822 3.90913C5.21113 3.55621 5.78863 3.55621 6.14155 3.90913L9.12988 6.89746"
        stroke="#0C80F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CollapseUpIcon;
