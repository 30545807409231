import React from "react";

const CreateBannerIcon = ({ color = "#566875" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.49967 8.33333C8.42015 8.33333 9.16634 7.58714 9.16634 6.66667C9.16634 5.74619 8.42015 5 7.49967 5C6.5792 5 5.83301 5.74619 5.83301 6.66667C5.83301 7.58714 6.5792 8.33333 7.49967 8.33333Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.8337 1.66699H7.50033C3.33366 1.66699 1.66699 3.33366 1.66699 7.50033V12.5003C1.66699 16.667 3.33366 18.3337 7.50033 18.3337H12.5003C16.667 18.3337 18.3337 16.667 18.3337 12.5003V8.33366"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.9501 2.15854L12.9251 5.18354C12.8084 5.30021 12.6917 5.52521 12.6751 5.69187L12.5084 6.85021C12.4501 7.26687 12.7417 7.55854 13.1584 7.50021L14.3167 7.33354C14.4751 7.30854 14.7084 7.20021 14.8251 7.08354L17.8501 4.05854C18.3751 3.53354 18.6167 2.93354 17.8501 2.16687C17.0751 1.38354 16.4751 1.63354 15.9501 2.15854Z"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.5166 2.5918C15.7749 3.50846 16.4916 4.22513 17.4083 4.48346"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.22461 15.7918L6.33294 13.0335C6.99128 12.5918 7.94128 12.6418 8.53294 13.1501L8.80794 13.3918C9.45794 13.9501 10.5079 13.9501 11.1579 13.3918L14.6246 10.4168C15.2746 9.85846 16.3246 9.85846 16.9746 10.4168L18.3329 11.5835"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CreateBannerIcon;
