import React from "react";
import { Avatar, Stack, Typography } from "@mui/material";
import EmptyBox from "../../assets/images/empty-box.png";
import RectangleSkeletons from "../RectangleSkeletons";
import BillingCaseList from "../BillingCaseList";

const AllCasesTab = ({ loadingCase, selectedRows, setSelectedRows, cases }) => {
  const changeCheckboxHandler = (event, caseItem) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      setSelectedRows((prevState) => [...prevState, caseItem]);
    } else {
      setSelectedRows((prevState) =>
        prevState.filter((item) => item.id !== caseItem.id)
      );
    }
  };

  const isSelected = (caseItem) => {
    return selectedRows?.some((item) => item.id === caseItem.id);
  };

  return (
    <>
      {loadingCase && cases?.length === 0 ? (
        <Stack pt="8px">
          <RectangleSkeletons height={60} totalRows={5} gap="16px" />
        </Stack>
      ) : cases?.length === 0 ? (
        <Stack alignItems="center" justifyContent="center" height="375px">
          <Avatar
            src={EmptyBox}
            alt="coming soon"
            sx={{
              width: "128px",
              height: "128px",
              paddingBottom: "24px",
            }}
          />
          <Typography variant="body1" color="secondary" pb={2}>
            All your Cases will show up here
          </Typography>
        </Stack>
      ) : (
        <Stack sx={{ height: "402px", overflow: "auto", padding: "0 8px" }}>
          {cases?.map((caseItem) => (
            <React.Fragment key={caseItem?.id}>
              <BillingCaseList
                caseItem={caseItem}
                changeCheckboxHandler={changeCheckboxHandler}
                isSelected={isSelected}
              />
            </React.Fragment>
          ))}
        </Stack>
      )}
    </>
  );
};

export default AllCasesTab;
