import React from "react";

const BodyPartFiltericon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
    >
      <path
        d="M4.27474 2.16211H14.7247C15.5956 2.16211 16.3081 2.87461 16.3081 3.74544V5.48711C16.3081 6.12044 15.9122 6.91211 15.5164 7.30794L12.1122 10.3163C11.6372 10.7121 11.3206 11.5038 11.3206 12.1371V15.5413C11.3206 16.0163 11.0039 16.6496 10.6081 16.8871L9.49974 17.5996C8.47057 18.2329 7.04557 17.5204 7.04557 16.2538V12.0579C7.04557 11.5038 6.72891 10.7913 6.41224 10.3954L3.40391 7.22878C3.00807 6.83294 2.69141 6.12044 2.69141 5.64544V3.82461C2.69141 2.87461 3.40391 2.16211 4.27474 2.16211Z"
        stroke="#696969"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.65292 2.16211L4.75 8.41628"
        stroke="#696969"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BodyPartFiltericon;
