const Style = (theme) => ({
  // setting__main: {
  //   height: "100%",
  // },
  // content__height: {
  //   height: "calc(100vh)",
  //   padding: "0",
  //   backgroundColor: "#F4F8FF",
  // },
  side__bar: {
    padding: "1rem !important",
    borderRight: "1px solid #E9F1FD !important",
  },
  listText: {
    color: "#566875 !important",
  },
  settingBottom: {
    position: "absolute !important",
    bottom: "0 !important",
  },

  // activeTab: {
  //   backgroundColor: "#0C80F3 !important",
  //   borderRadius: "8px !important",
  // },

  activeTab: {
    "&:hover": {
      "& svg path": {
        stroke: "#fff !important",
      },
    },
    "&& .Mui-selected, && .Mui-selected:hover": {
      backgroundColor: "#0C80F3 !important",
      borderRadius: "8px !important",
      "&, & .MuiListItemIcon-root": {
        color: "#fff !important",
      },
    },
  },
  icon: {
    minWidth: "35px !important",
  },
  activeColor: {
    color: "#fff !important",
  },
  // tab__details: {
  //   width: "calc(100% - 250px)",
  //   backgroundColor: "#FCFCFC",
  //   padding: "24px 0 16px 40px",
  //   [theme.breakpoints.down("md")]: {
  //     width: "100%",
  //     padding: "1.5rem 0 1.5rem 1.5rem",
  //     "& .xs__p__0": {
  //       padding: 0,
  //     },
  //   },
  // },
  // tab__panel: {
  //   height: "100%",
  //   overflowY: "auto",
  // },
  // logo: {
  //   height: "40px",
  //   width: "130px",
  //   marginBottom: "1.5rem",
  // },
  // indicator: {
  //   right: "0px",
  //   backgroundColor: theme.palette.primary.main,
  //   height: "41.83px !important",
  //   width: "100% !important",
  //   borderRadius: "8px",
  // },
  // tabs__bar: {
  //   backgroundColor: "transparent",
  //   // boxShadow: 'none',
  //   padding: "0",
  //   zIndex: "1",
  //   position: "relative",
  //   borderRadius: "0",
  //   height: "calc(100% - 60px)",
  //   "& .MuiTabScrollButton-root": {
  //     borderBottom: "3px solid #CCD2E3",
  //     opacity: "1 !important",
  //   },
  //   "& .MuiTabScrollButton-root.Mui-disabled": {
  //     color: "rgba(109, 125, 139, .3)",
  //   },
  //   "& .MuiTabs-scroller": {
  //     "& .MuiTabs-flexContainer": {
  //       justifyContent: "flex-start",
  //       height: "100%",
  //       "& .MuiTab-root": {
  //         margin: "0",
  //         opacity: "1",
  //         width: "100%",
  //         minWidth: "100%",
  //         maxWidth: "100%",
  //         fontSize: "13px",
  //         minHeight: "30px",
  //         borderBottom: "0",
  //         borderRadius: "8px",
  //         alignItems: "center",
  //         padding: ".8rem 1.4rem",
  //         justifyContent: "flex-start",
  //         textTransform: "capitalize",
  //         color: "rgba(109, 125, 139, 1)",
  //         "&:last-child": {
  //           position: "absolute",
  //           bottom: "0",
  //           [theme.breakpoints.down("md")]: {
  //             position: "unset",
  //             bottom: "unset",
  //           },
  //         },
  //         "&:nth-child(7)": {
  //           display: "none",
  //         },
  //       },
  //     },
  //     "& .Mui-selected": {
  //       zIndex: 1,
  //       color: `${theme.palette.primary.light} !important`,
  //       "& svg": {
  //         "& path": {
  //           stroke: theme.palette.primary.light,
  //         },
  //       },
  //     },
  //   },
  // },
});
export default Style;
