import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const GeneralSkeleton = () => {
  return (
    <Stack gap="24px">
      <Box display="flex" width="100%" gap="24px">
        <Skeleton variant="rounded" width="50%" height={56} />
        <Skeleton variant="rounded" width="50%" height={56} />
      </Box>
      <Box display="flex" width="100%" gap="24px">
        <Skeleton variant="rounded" width="50%" height={56} />
        <Skeleton variant="rounded" width="50%" height={56} />
      </Box>
      <Skeleton variant="rounded" width="100%" height={56} />
      <Box display="flex" width="100%" gap="24px">
        <Skeleton variant="rounded" width="50%" height={56} />
        <Skeleton variant="rounded" width="50%" height={56} />
      </Box>
      <Box display="flex" width="100%" gap="24px">
        <Skeleton variant="rounded" width="50%" height={56} />
        <Skeleton variant="rounded" width="50%" height={56} />
      </Box>
      <Skeleton
        sx={{ marginLeft: "auto" }}
        variant="rounded"
        width={139}
        height={45}
      />
    </Stack>
  );
};

export default GeneralSkeleton;
