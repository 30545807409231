import { DataGrid } from "@mui/x-data-grid";
import React, { useMemo } from "react";
import CaseTableSkeleton from "../CaseTableSkeleton";
import { Avatar, Box, Chip, Stack, Typography } from "@mui/material";
import EmptyBox from "../../assets/images/empty-box.png";
import AvatarWithName from "../AvatarWithName";
import dayjs from "dayjs";
import { caseStatusType } from "../../utils/helpers";

const LastMonthCasesTab = ({
  setPageSize,
  setPage,
  pageSize,
  page,
  totalCases,
  loadingCase,
  selectedRows,
  setSelectedRows,
  cases,
  query,
}) => {
  const handleSelectionModelChange = (newSelection) => {
    const selectedRowsData = newSelection.map((rowId) =>
      rows?.find((row) => row.id === rowId)
    );
    setSelectedRows(selectedRowsData);
  };

  const rows = useMemo(() => {
    if (!cases?.length) return [];
    return cases?.map((caseItem) => ({
      id: caseItem.id,
      case_title: caseItem,
      associated_client: {
        name: caseItem?.client?.name,
        profile_picture_url: caseItem?.client?.profile_picture_url,
      },
      case_status: caseItem?.status ? caseItem?.status : "open",
      open_date: caseItem?.case_opening_date
        ? dayjs(caseItem?.case_opening_date).format("DD MMM YYYY")
        : "-",
    }));
  }, [cases, query, pageSize, page]);

  const columns = useMemo(
    () => [
      {
        field: "case_title",
        headerName: "Case",
        width: 213,
        flex: 1,
        renderCell: ({ value }) => (
          <Box display="flex" alignItems="center" gap="0.5rem">
            {" "}
            {/* This Box will align items in a row with a small gap */}
            <Typography
              id={`case-title-${value?.title}`}
              sx={{ fontSize: "0.875rem" }}
            >
              {value?.title}
            </Typography>
            {value?.case_origin === "clio" && (
              <Chip
                label="Clio"
                size="small"
                sx={{
                  fontSize: "0.75rem",
                  height: "auto",
                  padding: "2px 8px", // Adjust padding to match your design
                  backgroundColor: "#f5f5f5", // This is a guess based on your image
                  color: "#000000",
                  borderRadius: "10px", // Adjust border-radius to match your design
                  // More styling as needed
                }}
              />
            )}
          </Box>
        ),
      },
      {
        field: "associated_client",
        headerName: "Associated Client",
        renderCell: ({ value }) => (
          <AvatarWithName
            name={value.name}
            imageURL={value.profile_picture_url}
          />
        ),
        width: 195,
        flex: 1,
      },
      {
        field: "case_status",
        headerName: "Case Status",
        flex: 1,
        width: 112,
        renderCell: ({ value }) => (
          <Chip
            size="small"
            label={value}
            color={caseStatusType(value)}
            variant="outlined"
          />
        ),
        sortable: false,
      },
      {
        field: "open_date",
        headerName: "Open Date",
        flex: 1,
        width: 119,
        // renderCell: ({ value }) => (
        //   <AssignedTeamMembers assigned_to_details={value} />
        // ),
        sortable: false,
      },
    ],
    []
  );

  return (
    <>
      {loadingCase ? (
        <CaseTableSkeleton
          headers={[
            "",
            "Case",
            "Associated Client",
            "Case Status",
            "Open Date",
          ]}
          numRows={5}
          cellWidths={[65, 190, 163, 112, 119]}
          marginTop="0px"
          height={400}
        />
      ) : cases?.length === 0 ? (
        <Stack alignItems="center" justifyContent="center" height="440px">
          <Avatar
            src={EmptyBox}
            alt="coming soon"
            sx={{
              width: "128px",
              height: "128px",
              paddingBottom: "24px",
            }}
          />
          <Typography variant="body1" color="secondary" pb={2}>
            All your Cases will show up here
          </Typography>
          {/* <Button
        id="new-case-button"
        startIcon={<AddIcon />}
        title="New Case"
        color="primary"
        fontSize="15px"
        variant="contained"
        onClick={closeDialogHandler}
      /> */}
        </Stack>
      ) : (
        <div style={{ height: 400, width: "100%" }}>
          <DataGrid
            loading={loadingCase}
            // onRowClick={caseDetailHandler}
            rowsPerPageOptions={[5, 10]}
            getRowId={(row) => row.id}
            rows={rows}
            columns={columns}
            pagination
            page={page - 1}
            pageSize={pageSize}
            rowCount={totalCases}
            paginationMode="server"
            onPageChange={(newPage) => setPage(newPage + 1)}
            onPageSizeChange={(newPageSize) => {
              setPageSize(newPageSize);
              setPage(1);
            }}
            sx={{
              cursor: " pointer !important",
              "&": { border: "none" },
            }}
            checkboxSelection
            onSelectionModelChange={handleSelectionModelChange}
            selectionModel={selectedRows?.map((row) => row.id)}
          />
        </div>
      )}
    </>
  );
};

export default LastMonthCasesTab;
