// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media print {
  @page {
    size: A4;
  }
  body {
    print-color-adjust: exact; /* Standard property */
    -webkit-print-color-adjust: exact;
  }
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE;IACE,QAAQ;EACV;EACA;IACE,yBAAyB,EAAE,sBAAsB;IACjD,iCAAiC;EACnC;AACF","sourcesContent":["@media print {\n  @page {\n    size: A4;\n  }\n  body {\n    print-color-adjust: exact; /* Standard property */\n    -webkit-print-color-adjust: exact;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
