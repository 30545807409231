import bgImage from "../../assets/images/bgImage.png";

const Style = () => ({
  bgImage: {
    backgroundImage: `url(${bgImage}) `,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
    backgroundAttachment: "fixed",
    height: "max-content",
    minHeight: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    alignItems: "center",
    justifyContent: "center",
    padding: "16px",
  },
});
export default Style;
