import { Box } from "@mui/material";
import React from "react";
import { pdfjs } from "react-pdf";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Viewer } from "@react-pdf-viewer/core";

const PdfUi = ({ pdfUrl }) => {
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  return <Viewer fileUrl={pdfUrl} />;
};

export default PdfUi;
