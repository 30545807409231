import React from "react";

const GridViewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="14"
      viewBox="0 0 18 14"
      fill="none"
    >
      <path
        d="M11.67 0V6.5H6.33V0H11.67ZM12.67 6.5H18V0H12.67V6.5ZM11.67 14V7.5H6.33V14H11.67ZM12.67 7.5V14H18V7.5H12.67ZM5.33 7.5H0V14H5.33V7.5ZM5.33 6.5V0H0V6.5H5.33Z"
        fill="black"
        fillOpacity="0.87"
      />
    </svg>
  );
};

export default GridViewIcon;
