import { Avatar, Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

const useStyles = makeStyles((theme) => {
  return {
    profileBox: {
      "& img": {
        width: "100%",
        height: "100%",
        borderRadius: "50%",
      },
    },
  };
});

const ProfilePicture = ({ src, alt, width, height }) => {
  const classes = useStyles();

  return (
    <Box borderRadius="50%" className={classes.profileBox} border>
      <Avatar src={src} alt={alt} sx={{ width: width, height: height }} />
    </Box>
  );
};

export default ProfilePicture;
