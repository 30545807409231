import React, { useEffect, useState } from "react";
import CommonDrawer from "../../common/CommonDrawer";
import {
  Box,
  Divider,
  IconButton,
  Stack,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import AllNotifications from "./AllNotifications";
import ChatNotifications from "./ChatNotifications";
import AlertNotifications from "./AlertNotifications";
import UserActivityNotifications from "./UserActivityNotifications";
import { notificationsData } from "../../utils/fakeData";
import Style from "./style";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import {
  getWebNotificationsFunc,
  resetAlertNotifications,
  resetAllNotifications,
  resetChatNotifications,
  resetUserActivityNotifications,
} from "../../actions/AppConfig";

const tabsData = [
  {
    label: "All",
    value: "all",
  },
  {
    label: "Chat",
    value: "chat",
  },
  {
    label: "Alerts",
    value: "alerts",
  },
  {
    label: "User Activity",
    value: "user-activity",
  },
];

const Styles = makeStyles((theme) => Style(theme));

const NotificationDrawer = ({
  anchor,
  openNotificationDrawer,
  setOpenNotificationDrawer,
  onClose,
  setTotalNotifications,
  totalNotifications,
}) => {
  const [tabValue, setTabValue] = useState("all");

  // const [notificationData, setNotificationData] = useState([]);

  const dispatch = useDispatch();

  const tabChangeHandler = (event, newValue) => {
    dispatch(resetAllNotifications());
    dispatch(resetChatNotifications());
    dispatch(resetAlertNotifications());
    dispatch(resetUserActivityNotifications());
    setTabValue(newValue);
  };

 

  return (
    <CommonDrawer
      anchor={anchor}
      open={openNotificationDrawer}
      setOpen={setOpenNotificationDrawer}
      onClose={onClose}
      padding="0"
      width="522px"
    >
      <Stack>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          p="24px"
          position="sticky"
          bgcolor="#fff"
          zIndex="99"
          top="0"
          height="64px"
        >
          <Typography variant="h5">Notifications</Typography>

          <IconButton
            size="small"
            onClick={() => {
              setOpenNotificationDrawer(false);
              // setTotalNotifications(0);
            }}
          >
            <CancelOutlinedIcon />
          </IconButton>
        </Box>

        {/* TABS VALUES */}
        <Box>
          <Tabs
            sx={{
              padding: "0 24px",
              // color: "#fff",
              // // "& button:active": { background: "orange" },
              position: "sticky",
              bgcolor: "#fff",
              zIndex: "99",
              top: "64px",
            }}
            value={tabValue}
            onChange={tabChangeHandler}
            // textColor="secondary"
            // aria-label="secondary tabs example"
          >
            {tabsData.map((tabData, i) => (
              <Tab
                disableRipple
                key={i}
                value={tabData.value}
                label={tabData.label}
                sx={{ padding: "9px 16px" }}
              />
            ))}
          </Tabs>
          <Divider />
          {/*  */}

          {/* TAB 1 ALL */}
          {tabValue === "all" && (
            <AllNotifications
              tabValue={tabValue}
              setOpenNotificationDrawer={setOpenNotificationDrawer}
              setTotalNotifications={setTotalNotifications}
              totalNotifications={totalNotifications}
              // notificationData={notificationData}
              // setNotificationData={setNotificationData}
            />
          )}

          {/* TAB 2 CHAT */}
          {tabValue === "chat" && (
            <ChatNotifications
              tabValue={tabValue}
              setOpenNotificationDrawer={setOpenNotificationDrawer}
            />
          )}

          {/* TAB 3 ALERTS */}
          {tabValue === "alerts" && (
            <AlertNotifications tabValue={tabValue} backgroundColor="#F4F6F8" />
          )}

          {/* TAB 4 USER ACTIVITY */}
          {tabValue === "user-activity" && (
            <UserActivityNotifications
              tabValue={tabValue}
              setOpenNotificationDrawer={setOpenNotificationDrawer}
            />
          )}
        </Box>
      </Stack>
    </CommonDrawer>
  );
};

export default NotificationDrawer;
