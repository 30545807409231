import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const ChatProfileSkeleton = () => {
  return (
    <Stack gap="1.5rem" alignItems="center" justifyContent="center">
      <Skeleton variant="circular" width="134px" height="134px" />
      <Stack gap="0.56rem" alignItems="center">
        <Skeleton variant="rounded" width="10rem" height="2rem" />
        <Skeleton variant="rounded" width="6.25rem" height="1.25rem" />
        <Skeleton variant="rounded" width="6.25rem" height="1.25rem" />
      </Stack>
    </Stack>
  );
};

export default ChatProfileSkeleton;
