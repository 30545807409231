import React from "react";

import { Box } from "@mui/material";

export default function BoxMain({ children }) {
  return (
    <Box
      sx={{
        // boxShadow: '0px 2px 16px 0px rgba(0, 0, 0, 0.08)',
        background: "#fff",
        padding: "2rem",
        borderRadius: "8px",
      }}
    >
      {children}
    </Box>
  );
}
