import React from "react";

const UploadDocIcon = ({ color = "#566875" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M7.50065 14.1665V9.1665L5.83398 10.8332" fill="white" />
      <path
        d="M7.50065 14.1665V9.1665L5.83398 10.8332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.1665L9.16667 10.8332"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3327 8.33317V12.4998C18.3327 16.6665 16.666 18.3332 12.4993 18.3332H7.49935C3.33268 18.3332 1.66602 16.6665 1.66602 12.4998V7.49984C1.66602 3.33317 3.33268 1.6665 7.49935 1.6665H11.666"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3327 8.33317H14.9993C12.4993 8.33317 11.666 7.49984 11.666 4.99984V1.6665L18.3327 8.33317Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadDocIcon;
