import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Typography,
  Grid,
  FormControlLabel,
  FormGroup,
  Switch,
  FormControl,
  Box,
  Radio,
  RadioGroup,
  Checkbox,
  TextField,
} from "@mui/material";
import Style from "./style";
import CardUI from "../../../../common/Card";
import CircularProgressLoading from "../../../../common/CircularProgress";
import AlertMessage from "../../../../common/AlertMessage";
import Cookies from "js-cookie";
import {
  updateAgencyFunc,
  getAgencyDetailFunc,
} from "../../../../actions/Agency";
import { segmentTrack } from "../../../../utils/helpers/tracking_utils";
import ClientIntakeForm from "../../../../components/ClientIntakeForm";
import IntakeFormDrawer from "./IntakeFormDrawer";
import AlertDialog from "../../../../components/AlertDialog";
import {
  createPresetFunc,
  deletePresetFunc,
  getClientIntakeListsFunc,
  getPresetDetailFunc,
  resetCreatePreset,
  resetDeletePreset,
  resetUpdatePreset,
  updatePresetFunc,
} from "../../../../actions/ClientIntakeActions";
import SkeletonGrid from "../../../../components/SkeletonGrid";
import RectangleSkeletons from "../../../../components/RectangleSkeletons";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";

import {
  DesktopTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";

// const questionsArray = [
//   { title: "Tell us what happened", required: true },
//   {
//     title: "Briefly outline the nature and extent of your injuries",
//     required: true,
//   },
//   { title: "Was the police involved?", required: false },
//   {
//     title: "Have you incurred any financial losses or damages?",
//     required: false,
//   },
// ];

// const defaultDocumentsArr = [
//   "Insurance Documents",
//   "Photo or Video Evidence",
//   "Driving License",
// ];

const Styles = makeStyles((theme) => Style(theme));

export default function Billing() {
  const classes = Styles();

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  const [openDrawer, setOpenDrawer] = useState(false);
  const [singlePreset, setSinglePreset] = useState(null);

  const agentToken = Cookies.get("token");
  const cookieAgentId = Cookies.get("agentId");
  const cookieAgencyId = Cookies.get("agencyId");

  useEffect(() => {
    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const [loading, setLoading] = useState(false);
  const [agencyDetailData, setAgencyDetailData] = useState({});
  const [modalContentData, setModalContentData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [updatingPreset, setUpdatingPreset] = useState(false);
  const [deletePresetId, setDeletePresetId] = useState(null);
  const [defaultQuestions, setDefaultQuestions] = useState([]);
  const [defaultDocuments, setDefaultDocuments] = useState([]);
  const [morningTime, setMorningTime] = useState(null);
  const [eveningTime, setEveningTime] = useState(null);
  const [morningReminder, setMorningReminder] = useState(true);
  const [eveningReminder, setEveningReminder] = useState(true);
  const [allPresets, setAllPresets] = useState([]);
  const [allowPainLogUpdates, setAllowPainLogUpdates] = useState(false);
  const [dailyPainLogNotifications, setDailyPainLogNotifications] =
    useState(false);
  const [allowPainLogUpdatesUpto, setAllowPainLogUpdatesUpto] = useState(24);
  const [missedPainLogAlerts, setMissedPainLogAlerts] = useState(false);
  const [numberOfMissedPainLogsForAlerts, setNumberOfMissedPainLogsForAlerts] =
    useState(7);
  const [inactivityAlerts, setInactivityAlerts] = useState(false);
  const [numberOfInactiveDaysForAlerts, setNumberOfInactiveDaysForAlerts] =
    useState(7);
  const [missedAppointmentAlerts, setMissedAppointmentAlerts] = useState(false);
  const [
    numberOfMissedAppointmentsForAlerts,
    setNumberOfMissedAppointmentsForAlerts,
  ] = useState(5);

  const [status, setStatus] = useState({ type: "", message: "" });
  const [permissionMessage, setPermissionMessage] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const dispatch = useDispatch();
  const updateAgency = useSelector((state) => state.updateAgency);
  const {
    loading: loadingAgency,
    error: errorAgency,
    updatedAgency,
  } = updateAgency;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getClientIntakeLists = useSelector(
    (state) => state.getClientIntakeLists
  );
  const {
    loading: loadingClientIntake,
    error: errorClientIntake,
    clientIntakeLists,
  } = getClientIntakeLists;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  const getPresetDetail = useSelector((state) => state.getPresetDetail);
  const {
    loading: loadingPresetDetail,
    error: errorPresetDetail,
    presetDetail,
  } = getPresetDetail;

  const createPreset = useSelector((state) => state.createPreset);
  const {
    loading: loadingCreatedPreset,
    error: errorCreatedPreset,
    newPreset,
  } = createPreset;

  const updatePreset = useSelector((state) => state.updatePreset);
  const {
    loading: loadingUpdatePreset,
    error: errorUpdatePreset,
    updatedPreset,
  } = updatePreset;

  const deletePreset = useSelector((state) => state.deletePreset);
  const {
    loading: loadingDeletedPreset,
    error: errorDeletedPreset,
    deletedPreset,
  } = deletePreset;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
    dispatch(getClientIntakeListsFunc());
  }, []);

  useEffect(() => {
    if (clientIntakeLists?.ok) {
      setAllPresets(clientIntakeLists?.presets);
      const alldefaultQuestions =
        clientIntakeLists?.presets[0]?.intake_questions?.map((question) => ({
          question_text: question.question_text,
          question_type: question.question_type,
          is_required: question.is_required,
          helper_text: question.helper_text,
        }));

      const allDefaultDocuments = JSON.parse(
        clientIntakeLists?.presets[0]?.required_documents
      );

      setDefaultDocuments(allDefaultDocuments);
      setDefaultQuestions(alldefaultQuestions);
    }
  }, [clientIntakeLists]);

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "settings_case_tab",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  const agentUserRef = useRef();

  useEffect(() => {
    agentUserRef.current = agentUser;
  }, [agentUser]);

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const getAgencyData = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agent/get-agency?agency_id=${agencyID}`,
        config
      );

      setAgencyDetailData(data?.agency);

      const {
        daily_pain_log_notifications,
        allow_pain_log_updates,
        allow_pain_log_updates_upto,
        missed_pain_log_alerts,
        number_of_missed_pain_logs_for_alerts,
        inactivity_alerts,
        number_of_inactive_days_for_alerts,
        missed_appointment_alerts,
        number_of_missed_appointments_for_alerts,
        morning_pain_log_notification_time,
        evening_pain_log_notification_time,
        enable_morning_pain_reminders,
        enable_evening_pain_reminders,
      } = data?.agency;

      if (morning_pain_log_notification_time) {
        const parsedMorningTime = dayjs(morning_pain_log_notification_time);
        setMorningTime(parsedMorningTime);
      } else {
        const defaultMorningTime = dayjs().set("hour", 9).set("minute", 0);
        setMorningTime(defaultMorningTime);
      }

      if (evening_pain_log_notification_time) {
        const parsedEveningTime = dayjs(evening_pain_log_notification_time);
        setEveningTime(parsedEveningTime);
      } else {
        const defaultEveningTime = dayjs().set("hour", 18).set("minute", 0);
        setEveningTime(defaultEveningTime);
      }

      if (enable_morning_pain_reminders) {
        setMorningReminder(true);
      } else {
        setMorningReminder(false);
      }

      if (enable_evening_pain_reminders) {
        setEveningReminder(true);
      } else {
        setEveningReminder(false);
      }

      setDailyPainLogNotifications(
        daily_pain_log_notifications ? daily_pain_log_notifications : false
      );
      setAllowPainLogUpdates(
        allow_pain_log_updates ? allow_pain_log_updates : false
      );
      setAllowPainLogUpdatesUpto(
        allow_pain_log_updates_upto ? allow_pain_log_updates_upto : 24
      );
      setMissedPainLogAlerts(
        missed_pain_log_alerts ? missed_pain_log_alerts : false
      );
      setNumberOfMissedPainLogsForAlerts(
        number_of_missed_pain_logs_for_alerts
          ? number_of_missed_pain_logs_for_alerts
          : 7
      );
      setInactivityAlerts(inactivity_alerts ? inactivity_alerts : false);
      setNumberOfInactiveDaysForAlerts(
        number_of_inactive_days_for_alerts
          ? number_of_inactive_days_for_alerts
          : 7
      );
      setMissedAppointmentAlerts(
        missed_appointment_alerts ? missed_appointment_alerts : false
      );
      setNumberOfMissedAppointmentsForAlerts(
        number_of_missed_appointments_for_alerts
          ? number_of_missed_appointments_for_alerts
          : 5
      );

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (updatedAgency?.ok) {
      setAlertOpen(true);
    }
  }, [updatedAgency]);

  useEffect(() => {
    if (token) {
      try {
        getAgencyData();
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  }, [token]);

  const dailyPainLogNotificationsHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const dailyPainLogNotificationsValue = e.target.checked;
      setDailyPainLogNotifications(dailyPainLogNotificationsValue);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          daily_pain_log_notifications: dailyPainLogNotificationsValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const allowPainLogUpdatesHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const allowPainLogUpdatesValue = e.target.checked;
      setAllowPainLogUpdates(allowPainLogUpdatesValue);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          allow_pain_log_updates: allowPainLogUpdatesValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const allowPainLogUpdatesUptoHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const allowPainLogUpdatesUptoValue = e.target.value;
      setAllowPainLogUpdatesUpto(allowPainLogUpdatesUptoValue);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          allow_pain_log_updates_upto: allowPainLogUpdatesUptoValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const missedPainLogAlertsHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const missedPainLogAlertsValue = e.target.checked;
      setMissedPainLogAlerts(missedPainLogAlertsValue);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          missed_pain_log_alerts: missedPainLogAlertsValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const numberOfMissedPainLogsForAlertsHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const numberOfMissedPainLogsForAlertsValue = e.target.value;
      setNumberOfMissedPainLogsForAlerts(numberOfMissedPainLogsForAlertsValue);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          number_of_missed_pain_logs_for_alerts:
            numberOfMissedPainLogsForAlertsValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const inactivityAlertsHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const inactivityAlertsValue = e.target.checked;
      setInactivityAlerts(inactivityAlertsValue);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          inactivity_alerts: inactivityAlertsValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const numberOfInactiveDaysForAlertsHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const numberOfInactiveDaysForAlertsValue = e.target.value;
      setNumberOfInactiveDaysForAlerts(numberOfInactiveDaysForAlertsValue);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          number_of_inactive_days_for_alerts:
            numberOfInactiveDaysForAlertsValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const missedAppointmentAlertsHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const missedAppointmentAlertsValue = e.target.checked;
      setMissedAppointmentAlerts(missedAppointmentAlertsValue);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          missed_appointment_alerts: missedAppointmentAlertsValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const numberOfMissedAppointmentsForAlertsHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_case_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update case settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const numberOfMissedAppointmentsForAlertsValue = e.target.value;
      setNumberOfMissedAppointmentsForAlerts(
        numberOfMissedAppointmentsForAlertsValue
      );

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          number_of_missed_appointments_for_alerts:
            numberOfMissedAppointmentsForAlertsValue,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const modalOpenHandler = () => {
    setOpenModal(true);
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  // EDIT PRESET
  const editPresetHandler = (preset_id) => {
    setOpenDrawer(true);
    setUpdatingPreset(true);
    dispatch(getPresetDetailFunc(preset_id));
  };

  useEffect(() => {
    if (presetDetail?.ok) {
      setSinglePreset(presetDetail?.preset);
    }
  }, [presetDetail]);

  // CREATING NEW PRESET
  const submitPresetFormHandler = (presetObj) => {
    if (updatingPreset) {
      // UPDATING PRESET
      dispatch(updatePresetFunc(presetObj));
    } else {
      // CREATING NEW PRESET
      dispatch(createPresetFunc(presetObj));
    }
  };

  useEffect(() => {
    if (newPreset?.ok) {
      dispatch(getClientIntakeListsFunc());
      setOpenDrawer(false);

      resetCreatePreset();
    }
  }, [newPreset]);
  useEffect(() => {
    if (updatedPreset?.ok) {
      dispatch(getClientIntakeListsFunc());
      setOpenDrawer(false);
      // setQuestionsArr([]);
      // setDocuments([]);

      resetUpdatePreset();
    }
  }, [updatedPreset]);

  // OPEN DELETE MODAL
  const deletePresetModalHandler = (presetId) => {
    setModalContentData({
      title: "Are you sure?",
      subtitle: "This will permanently delete this preset.",
    });
    modalOpenHandler();
    setDeletePresetId(presetId);
  };

  // DELETING PRESET
  const deletingPresetHandler = () => {
    const deleteObj = {
      intake_preset_id: deletePresetId,
    };

    dispatch(deletePresetFunc(deleteObj));
  };

  useEffect(() => {
    if (deletedPreset?.ok) {
      dispatch(getClientIntakeListsFunc());
      modalCloseHandler();

      resetDeletePreset();
    }
  }, [deletedPreset]);

  const changeMorningTimeHandler = (newTime) => {
    const agencyData = {
      agency_id: agencyID,
      agency_settings: {
        morning_pain_log_notification_time: newTime?.toISOString(),
      },
    };
    setMorningTime(newTime);

    dispatch(updateAgencyFunc(agencyData));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "lawfirm_details_updated",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const changeEveningTimeHandler = (newTime) => {
    const agencyData = {
      agency_id: agencyID,
      agency_settings: {
        evening_pain_log_notification_time: newTime?.toISOString(),
      },
    };

    setEveningTime(newTime);
    dispatch(updateAgencyFunc(agencyData));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "lawfirm_details_updated",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const changeMorningReminderHandler = (e) => {
    // const defaultMorningTime = dayjs().set("hour", 9).set("minute", 0);
    let agencyData;
    if (e.target.checked) {
      agencyData = {
        agency_id: agencyID,
        agency_settings: {
          enable_morning_pain_reminders: true,
        },
      };
      // setMorningTime(defaultMorningTime);
    } else {
      agencyData = {
        agency_id: agencyID,
        agency_settings: {
          enable_morning_pain_reminders: false,
        },
      };
    }
    dispatch(updateAgencyFunc(agencyData));
    setMorningReminder(e.target.checked);
  };

  const changeEveningReminderHandler = (e) => {
    let agencyData;
    if (e.target.checked) {
      agencyData = {
        agency_id: agencyID,
        agency_settings: {
          enable_evening_pain_reminders: true,
        },
      };
    } else {
      agencyData = {
        agency_id: agencyID,
        agency_settings: {
          enable_evening_pain_reminders: false,
        },
      };
    }
    dispatch(updateAgencyFunc(agencyData));
    setEveningReminder(e.target.checked);
  };

  return (
    <>
      {/* {loading && (
        <SkeletonGrid itemCount={4} height={250} md={8} spacing={2} />
      )} */}

      {/* DELETE ALERT MODAL */}
      {openModal && (
        <AlertDialog
          openModal={openModal}
          closeModal={modalCloseHandler}
          isLoading={loadingDeletedPreset}
          data={modalContentData}
          formSubmitFunction={deletingPresetHandler}
          color="error"
        />
      )}

      {openDrawer && (
        <IntakeFormDrawer
          anchor={"right"}
          // questionsArr={questionsArr}
          // setQuestionsArr={setQuestionsArr}
          // documents={documents}
          // setDocuments={setDocuments}
          defaultQuestions={defaultQuestions}
          defaultDocuments={defaultDocuments}
          updatingPreset={updatingPreset}
          singlePreset={singlePreset}
          // setUpdate={setUpdate}
          open={openDrawer}
          onClose={() => setOpenDrawer(false)}
          onSubmitPresetForm={submitPresetFormHandler}
          loadingPresetDetail={loadingPresetDetail}
          loadingCreatedPreset={loadingCreatedPreset}
          loadingUpdatePreset={loadingUpdatePreset}
          // status={status}
          // setStatus={setStatus}
        />
      )}

      {!loading && status?.type === "success" && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingAgency && permissionMessage && (
        <AlertMessage
          message={permissionMessage}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingAgency && errorAgency && (
        <AlertMessage
          message={errorAgency}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingAgency && updatedAgency?.ok && (
        <AlertMessage
          message="Your changes have been saved successfully"
          severity="success"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      <Grid display="flex" flexDirection="column" gap="32px">
        <CardUI dashboard="true" maxWidth="741px" padding="32px">
          <Grid item xs={12} md={8}>
            <Stack component="form" direction="column" gap="24px">
              <Typography
                variant="boxHeading"
                color="secondary"
                sx={{ textTransform: "uppercase" }}
              >
                Customize Pain Log Settings
              </Typography>

              <Box display="flex" flexDirection="column">
                {loading ? (
                  <RectangleSkeletons totalRows={2} height={30} gap="24px" />
                ) : (
                  <Stack gap="24px">
                    <FormControl component="fieldset">
                      {/* <FormGroup
                      aria-label="position"
                      row
                      className={classes.form__radio__group}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={dailyPainLogNotifications}
                            name="referees"
                            onChange={dailyPainLogNotificationsHandler}
                          />
                        }
                        label="Enable Daily Pain Log Reminders for Clients"
                        labelPlacement="start"
                      />{" "}
                    </FormGroup> */}
                      <FormGroup
                        aria-label="position"
                        row
                        className={classes.form__radio__group}
                      >
                        <FormControlLabel
                          control={
                            <Switch
                              color="primary"
                              checked={allowPainLogUpdates}
                              name="referees"
                              onChange={allowPainLogUpdatesHandler}
                            />
                          }
                          label="Client can update a pain log entry"
                          labelPlacement="start"
                          className={classes.form__radio__group}
                        />
                      </FormGroup>
                      {allowPainLogUpdates && (
                        <Grid
                          sx={{
                            paddingLeft: 3,
                          }}
                        >
                          <Typography
                            component="label"
                            color="secondary"
                            id="demo-row-radio-buttons-group-label"
                          >
                            Select time limit
                          </Typography>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name="row-radio-buttons-group"
                            value={allowPainLogUpdatesUpto}
                            onChange={allowPainLogUpdatesUptoHandler}
                          >
                            <FormControlLabel
                              value="12"
                              control={<Radio />}
                              label="12 hours"
                              disabled={!allowPainLogUpdates}
                            />
                            <FormControlLabel
                              value="24"
                              control={<Radio />}
                              label="24 hours"
                              disabled={!allowPainLogUpdates}
                            />
                            <FormControlLabel
                              value="48"
                              control={<Radio />}
                              label="48 hours"
                              disabled={!allowPainLogUpdates}
                            />
                          </RadioGroup>
                        </Grid>
                      )}
                    </FormControl>
                    {/* <Stack gap="16px">
                      <Typography variant="h6" fontSize="1rem" fontWeight="400">
                        Pain log reminders
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="16px"
                      >
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              checked={morningReminder}
                              value={morningReminder}
                              onChange={changeMorningReminderHandler}
                            />
                          }
                          label="Morning Reminder"
                          sx={{ color: "#566875" }}
                          labelPlacement="end"
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Choose time"
                            value={morningTime}
                            onChange={changeMorningTimeHandler}
                            disabled={!morningReminder}
                          />
                        </LocalizationProvider>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="16px"
                      >
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              checked={eveningReminder}
                              value={eveningReminder}
                              onChange={changeEveningReminderHandler}
                            />
                          }
                          label="Evening Reminder"
                          sx={{ color: "#566875" }}
                          labelPlacement="end"
                        />
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <TimePicker
                            label="Choose time"
                            value={eveningTime}
                            onChange={changeEveningTimeHandler}
                            disabled={!eveningReminder}
                          />
                        </LocalizationProvider>
                      </Box>
                    </Stack> */}
                  </Stack>
                )}
              </Box>
            </Stack>
          </Grid>
        </CardUI>
        <CardUI dashboard="true" maxWidth="741px" padding="32px">
          <Grid item xs={12} md={8}>
            <Stack component="form" direction="column" gap="24px">
              <Typography
                variant="boxHeading"
                color="secondary"
                sx={{ textTransform: "uppercase" }}
              >
                Customize Attorney Alerts
              </Typography>

              <Box display="flex" flexDirection="column">
                {loading ? (
                  <RectangleSkeletons totalRows={4} height={30} gap="24px" />
                ) : (
                  <FormControl component="fieldset">
                    <FormGroup
                      aria-label="position"
                      row
                      className={classes.form__radio__group}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={missedPainLogAlerts}
                            name="referees"
                            onChange={missedPainLogAlertsHandler}
                          />
                        }
                        label="Alert me when a client has missed consecutive pain logs"
                        labelPlacement="start"
                        className={classes.form__radio__group}
                      />
                    </FormGroup>
                    {missedPainLogAlerts && (
                      <Grid
                        sx={{
                          paddingLeft: 3,
                        }}
                      >
                        <Typography
                          component="label"
                          color="secondary"
                          id="demo-row-radio-buttons-group-label"
                        >
                          Select number of consecutive missed pain logs
                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={numberOfMissedPainLogsForAlerts}
                          onChange={numberOfMissedPainLogsForAlertsHandler}
                        >
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="3 days"
                            disabled={!missedPainLogAlerts}
                          />
                          <FormControlLabel
                            value="7"
                            control={<Radio />}
                            label="7 days"
                            disabled={!missedPainLogAlerts}
                          />
                          <FormControlLabel
                            value="14"
                            control={<Radio />}
                            label="14 days"
                            disabled={!missedPainLogAlerts}
                          />
                        </RadioGroup>
                      </Grid>
                    )}
                    <FormGroup
                      aria-label="position"
                      row
                      className={classes.form__radio__group}
                      sx={{
                        marginTop: 3,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={inactivityAlerts}
                            name="referees"
                            onChange={inactivityAlertsHandler}
                          />
                        }
                        label="Alert me when a client has been inactive"
                        labelPlacement="start"
                        className={classes.form__radio__group}
                      />
                    </FormGroup>
                    {inactivityAlerts && (
                      <Grid
                        sx={{
                          paddingLeft: 3,
                        }}
                      >
                        <Typography
                          component="label"
                          color="secondary"
                          id="demo-row-radio-buttons-group-label"
                        >
                          Select number of consecutive inactive days
                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={numberOfInactiveDaysForAlerts}
                          onChange={numberOfInactiveDaysForAlertsHandler}
                        >
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="3 days"
                            disabled={!inactivityAlerts}
                          />
                          <FormControlLabel
                            value="7"
                            control={<Radio />}
                            label="7 days"
                            disabled={!inactivityAlerts}
                          />
                          <FormControlLabel
                            value="14"
                            control={<Radio />}
                            label="14 days"
                            disabled={!inactivityAlerts}
                          />
                        </RadioGroup>
                      </Grid>
                    )}
                    <FormGroup
                      aria-label="position"
                      row
                      className={classes.form__radio__group}
                      sx={{
                        marginTop: 3,
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Switch
                            color="primary"
                            checked={missedAppointmentAlerts}
                            name="referees"
                            onChange={missedAppointmentAlertsHandler}
                          />
                        }
                        label="Alert me when a client has not updated status of consecutive appointments"
                        labelPlacement="start"
                        className={classes.form__radio__group}
                      />
                    </FormGroup>
                    {missedAppointmentAlerts && (
                      <Grid
                        sx={{
                          paddingLeft: 3,
                        }}
                      >
                        <Typography
                          component="label"
                          color="secondary"
                          id="demo-row-radio-buttons-group-label"
                        >
                          Select number of consecutive appointments
                        </Typography>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={numberOfMissedAppointmentsForAlerts}
                          onChange={numberOfMissedAppointmentsForAlertsHandler}
                        >
                          <FormControlLabel
                            value="3"
                            control={<Radio />}
                            label="3"
                            disabled={!missedAppointmentAlerts}
                          />
                          <FormControlLabel
                            value="5"
                            control={<Radio />}
                            label="5"
                            disabled={!missedAppointmentAlerts}
                          />
                          <FormControlLabel
                            value="7"
                            control={<Radio />}
                            label="7"
                            disabled={!missedAppointmentAlerts}
                          />
                        </RadioGroup>
                      </Grid>
                    )}
                  </FormControl>
                )}
              </Box>
            </Stack>
          </Grid>
        </CardUI>

        <ClientIntakeForm
          setOpenDrawer={setOpenDrawer}
          setUpdatingPreset={setUpdatingPreset}
          deletePresetModalHandler={deletePresetModalHandler}
          allPresets={allPresets}
          editPresetHandler={editPresetHandler}
          loadingClientIntake={loadingClientIntake}
        />
      </Grid>
    </>
  );
}
