import React, { useEffect, useState } from "react";
import ChatUserCard from "../../ChatUserCard";
import { Box, Stack, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { chatNotificationsFunc } from "../../../actions/AppConfig";
import CircularProgressLoading from "../../../common/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyBox from "../../../assets/images/empty-box.png";
import SkeletonGrid from "../../SkeletonGrid";

const page_size = 10;

const ChatNotifications = ({ tabValue, setOpenNotificationDrawer }) => {
  // const chatData = notificationData?.filter(
  //   (data) => data?.log_category === "chat_activity"
  // );

  const [chatNotificationData, setChatNotificationData] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const getChatNotifications = useSelector(
    (state) => state.getChatNotifications
  );
  const { loading, error, chatNotifications } = getChatNotifications;

  useEffect(() => {
    dispatch(chatNotificationsFunc(page, page_size));
  }, [tabValue]);

  const fetchMoreData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (!loading && chatNotifications?.ok) {
      setChatNotificationData((prevState) => [
        ...prevState,
        ...chatNotifications?.chat_activity,
      ]);

      setHasNext(chatNotifications?.has_next);
    }
  }, [chatNotifications]);

  useEffect(() => {
    if (hasNext) {
      dispatch(chatNotificationsFunc(page, page_size));
    }
  }, [page]);

  return (
    <>
      {loading && chatNotificationData?.length === 0 && (
        <SkeletonGrid itemCount={10} height={100} spacing={2} pt={2} pl={2} />
      )}

      {!loading && chatNotificationData?.length === 0 ? (
        <Stack gap="16px" alignItems="center" justifyContent="center">
          <Box width="128px" height="128px">
            <img src={EmptyBox} alt="empty box" width="100%" />
          </Box>
          <Typography variant="body1">No chat notifications yet</Typography>
        </Stack>
      ) : (
        <div style={{ padding: "16px" }}>
          <InfiniteScroll
            dataLength={chatNotificationData.length || []}
            next={fetchMoreData}
            hasMore={hasNext}
            loader={<CircularProgressLoading size={20} />}
            // scrollableTarget="scrollableDiv"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
            height={885}
          >
            {chatNotificationData?.map((data, index) => (
              <React.Fragment key={index}>
                <ChatUserCard
                  data={data}
                  setOpenNotificationDrawer={setOpenNotificationDrawer}
                />{" "}
              </React.Fragment>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};

export default ChatNotifications;
