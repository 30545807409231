import React, { useEffect, useRef, useState } from "react";
import BgBox from "../../../common/BgBox";
import HeaderText from "../../../common/HeaderText";
import CardUI from "../../../common/Card";
import { Box, Grid, TextField, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../../common/AlertMessage";
import { otpVerifyFunc, resetCreatePassword } from "../../../actions/Auth";
import MccLogo from "../../../components/MccLogo";
import { LoadingButton } from "@mui/lab";

const useStyles = makeStyles((theme) => {
  return {
    otp_box: {
      "& input": {
        fontSize: "22px !important",
        padding: "12px",
        textAlign: "center",
        WebkitAppereance: "none",
      },
      "& input::-webkit-outer-spin-button, input::-webkit-inner-spin-button": {
        display: "none",
        margin: 0,
      },
    },
  };
});

let currentOtpIndex = 0;
const numbers = /^[0-9\b]+$/;

const OtpVerification = () => {
  const classes = useStyles();

  const [otp, setOtp] = useState(new Array(6).fill(""));
  // const [finalOtp, setFinalOtp] = useState("");
  const [activeOtpIndex, setActiveOtpIndex] = useState(0);

  const inputRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const otpVerify = useSelector((state) => state.otpVerify);
  const { loading, error, res } = otpVerify;

  const [open, setOpen] = useState(false);
  const [moveToNext, setMoveToNext] = useState(false);

  const location = useLocation();

  // console.log("RES IN OTP VERIFICATION", res);

  // const state = location.state && location.state

  useEffect(() => {
    if (!error && res?.ok && location?.state?.createAgency && moveToNext) {
      navigate("/create-password", { state: { agent_data: res } });
      return;
    }
  }, [res]);

  useEffect(() => {
    if (!error && res?.ok && location?.state?.forgotPassword && moveToNext) {
      navigate("/reset-password", {
        state: { finalEmail: location?.state.email },
      });
      return;
    }
  }, [res, location]);

  useEffect(() => {
    if (location?.state === null) {
      navigate(-1);
    }
  }, [res, location, navigate]);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const otpVerificationHandler = async (e) => {
    e.preventDefault();

    const otpNum = otp.join("");

    if (!otpNum.length) {
      return;
    }

    dispatch(resetCreatePassword());
    dispatch(otpVerifyFunc(location?.state?.email, otpNum));
    setOpen(true);
    setMoveToNext(true);
  };

  const otpChangeHandler = (e) => {
    const { value } = e.target;

    const newOtp = [...otp];

    if (value === "" || numbers.test(value)) {
      newOtp[currentOtpIndex] = value.substring(value.length - 1);
      if (!value) {
        setActiveOtpIndex(currentOtpIndex - 1);
      } else {
        // pasteOtpHandler();
        setActiveOtpIndex(currentOtpIndex + 1);
      }
    }
    setOtp(newOtp);
  };

  const keyDownHandler = (e, index) => {
    currentOtpIndex = index;
    if (e.key === "Backspace") {
      setActiveOtpIndex(currentOtpIndex - 1);
    }
  };

  const pasteOtpHandler = (e) => {
    e.preventDefault();
    const pasteText = (e.clipboardData || window.clipboardData)
      .getData("text/plain")
      .trim()
      .slice(0)
      .split("");

    if (pasteText.length === otp.length) {
      setOtp(pasteText);
    }

    // const sanitizedText = pasteText.replace(/[^0-9]/g, "");
  };

  // const updateHandler = (event, index) => {
  //   setOtp([
  //     ...otp.slice(0, index),
  //     event.target.value,
  //     ...otp.slice(index + 1),
  //   ]);
  // };

  useEffect(() => {
    inputRef.current?.focus();
  }, [activeOtpIndex]);

  if (location.state === null) {
    return;
  }

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      <BgBox>
        <CardUI paddingTop="86px">
          {/* MCC LOGO */}
          <MccLogo />

          <Grid container component="main">
            <Box
              component="form"
              onSubmit={otpVerificationHandler}
              xs={12}
              display="flex"
              gap="52px"
              flexDirection="column"
              width="100%"
            >
              <Box>
                <HeaderText
                  variant="h3"
                  fontSize={34}
                  textContent="Enter the 6 digit code"
                  lineHeight="24px"
                  textAlign="center"
                />
                <Typography
                  variant="body1"
                  className="d-flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={16}
                  mt="16px"
                  color="#566875"
                >
                  We've sent you a six digit code on your email
                </Typography>
              </Box>

              <Box width="100%" display="flex" alignItems="center" gap="16px">
                {otp.map((s, index) => (
                  <TextField
                    key={index}
                    inputRef={index === activeOtpIndex ? inputRef : null}
                    variant="outlined"
                    className={classes.otp_box}
                    type="number"
                    value={s}
                    inputMode="numeric"
                    autoComplete="one-time-code"
                    onChange={otpChangeHandler}
                    onKeyDown={(e) => keyDownHandler(e, index)}
                    onPaste={pasteOtpHandler}

                    // onInput={(e) => updateHandler(e, index)}
                  />
                ))}
              </Box>

              <LoadingButton
                fullWidth
                loading={loading}
                type="submit"
                variant="contained"
                sx={{ padding: "8px 22px", fontSize: 15 }}
              >
                Continue
              </LoadingButton>

              {/* <Button
        className="w-100"
        fontSize="15px"
        color="primary"
        
        title="Sign In"
        variant="contained"
        width="100%"
        height="48px"
        // disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
        // onClick={() => onSignIn()}
        // loading={signInLoading}
        loadingColor="primary"
      /> */}
            </Box>
          </Grid>
        </CardUI>
      </BgBox>
    </>
  );
};

export default OtpVerification;
