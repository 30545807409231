import React, { useEffect } from "react";
import BgBox from "../../common/BgBox";
import CardUI from "../../common/Card";
import { Box, Grid, Link, Typography } from "@mui/material";
import HeaderText from "../../common/HeaderText";
import ChckedImg from "../../assets/images/checked-image.png";
import { Link as LinkTo, useNavigate } from "react-router-dom";

const EmailSent = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate("/otp-verification");
    }, 1000);
  }, []);

  return (
    <BgBox>
      <CardUI paddingTop="86px">
        <Grid container component="main">
          <Box
            xs={12}
            display="flex"
            gap="40px"
            flexDirection="column"
            width="100%"
          >
            <Box
              textAlign="center"
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap="16px"
            >
              <img src={ChckedImg} alt="checked-icon" />
              <HeaderText
                variant="h3"
                fontSize={34}
                textContent="Check your mail"
                lineHeight="24px"
                textAlign="center"
              />
              <Typography
                variant="body1"
                className="d-flex"
                alignItems="center"
                justifyContent="center"
                fontSize={16}
                width="75%"
                margin="0 auto"
                color="#566875"
              >
                We've sent a password link to your mail.
              </Typography>
            </Box>

            <Box textAlign="center">
              <Typography
                variant="body1"
                fontSize={16}
                color="#566875"
                className="d-flex"
                alignItems="center"
                justifyContent="center"
              >
                Did not receive the email? Check yours spam filter, or
              </Typography>
              <Link
                component={LinkTo}
                to="/forgot-password"
                textAlign="center"
                underline="none"
              >
                try another email address
              </Link>
            </Box>

            {/* <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ padding: "8px 22px", fontSize: 15 }}
            >
              Reset Password
            </Button> */}

            {/* <Button
    className="w-100"
    fontSize="15px"
    color="primary"
    
    title="Sign In"
    variant="contained"
    width="100%"
    height="48px"
    // disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
    // onClick={() => onSignIn()}
    // loading={signInLoading}
    loadingColor="primary"
  /> */}
          </Box>
        </Grid>
      </CardUI>
    </BgBox>
  );
};

export default EmailSent;
