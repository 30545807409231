const Style = (theme) => ({
  fields__space: {
    // height: "242px",
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column !important",
      "& .img__sec, .fields__sec": {
        // position: "relative",
        width: "100% !important",
        margin: "0",
        height: "auto",
      },
    },
    "& .control-width": {
      width: "100%",
      "& .react-tel-input": {
        // marginTop: ' !important',
        marginBottom: "3px !important",
      },
    },
  },
  banner_container: {
    position: "relative",
    border: "1px solid rgba(0, 0, 0, 0.23)",
    borderRadius: "4px",
    // maxWidth: "181px",
    // width: "100%",
    width: "181px",
    height: "218px",
    // paddingTop: "12px",
    [theme.breakpoints.down(" lg")]: {
      width: "100%",
    },
    "& .overlay": {
      display: "none",
      position: "absolute",
      bottom: 0,
      right: 0,
      height: "80%",
      width: "181px",
      height: "205px",
      margin: "0 auto",
      backgroundColor: "rgba(0, 0, 0, 0.60)",
      borderRadius: "4px",
      [theme.breakpoints.down("lg")]: {
        width: "100%",
        maxWidth: "100%",
      },
    },
    "&:hover": {
      "& .overlay": {
        display: "flex",
      },
    },
    "& svg": {
      "& path": {
        stroke: "#fff",
      },
    },
  },
  divider: {
    margin: theme.spacing(0, 2), // Adjust the margin as needed
  },

  img__upload__textfield: {
    "& .MuiInputAdornment-root": {
      width: "100%",
      "& label": {
        width: "100%",
        height: "225px",
      },
    },

    "& > .MuiInputBase-root": {
      height: "235px",
      "& > input": {
        width: "0.001px",
        opacity: "0",
      },
    },
  },

  headshot_img: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
  },
  counterText: {
    fontSize: "12px !important",
  },

  colorOuterBox: {
    padding: "2px",
    borderRadius: "4px",
    cursor: "pointer",
  },

  image_container: {
    width: "50%",
    height: "100%",
  },

  passportImage: {
    width: "100%", // Adjust the size as needed
    height: "100%",
    borderRadius: "8px",
    objectFit: "cover",
  },
  branding__main: {
    [theme.breakpoints.down("md")]: {
      "& .first__grid": {
        paddingRight: "0",
      },
      "& .second__grid": {
        paddingTop: "2rem",
      },
    },
  },
  // banner_preview_container: {
  //   [theme.breakpoints.down("1070")]: {
  //     maxWidth: "100% !important",
  //     width: "100% !important",
  //   },
  // },
});
export default Style;
