export const GET_REFERRALS_BYAGENCY_REQUEST = "GET_REFERRALS_BYAGENCY_REQUEST";
export const GET_REFERRALS_BYAGENCY_SUCCESS = "GET_REFERRALS_BYAGENCY_SUCCESS";
export const GET_REFERRALS_BYAGENCY_FAIL = "GET_REFERRALS_BYAGENCY_FAIL";

export const GET_NOTIFICATIONS_LIST_REQUEST = "GET_NOTIFICATIONS_LIST_REQUEST";
export const GET_NOTIFICATIONS_LIST_SUCCESS = "GET_NOTIFICATIONS_LIST_SUCCESS";
export const GET_NOTIFICATIONS_LIST_FAIL = "GET_NOTIFICATIONS_LIST_FAIL";

export const POST_NOTIFICATION_REQUEST = "POST_NOTIFICATION_REQUEST";
export const POST_NOTIFICATION_SUCCESS = "POST_NOTIFICATION_SUCCESS";
export const POST_NOTIFICATION_FAIL = "POST_NOTIFICATIONS_LIST_FAIL";
