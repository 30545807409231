import { Box, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ChatUserCard from "../../ChatUserCard";
import { useDispatch, useSelector } from "react-redux";
import { userActivityNotificationsFunc } from "../../../actions/AppConfig";
import { page_size } from "../../../assets/constants/appConstants";
import CircularProgressLoading from "../../../common/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import EmptyBox from "../../../assets/images/empty-box.png";
import SkeletonGrid from "../../SkeletonGrid";

const UserActivityNotifications = ({ tabValue, setOpenNotificationDrawer }) => {
  const [userActivityNotificationData, setUserActivityNotificationData] =
    useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const getUserActivityNotifications = useSelector(
    (state) => state.getUserActivityNotifications
  );
  const { loading, error, userActivityNotifications } =
    getUserActivityNotifications;

  useEffect(() => {
    dispatch(userActivityNotificationsFunc(page, page_size));
  }, [tabValue]);

  const fetchMoreData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    if (!loading && userActivityNotifications?.ok) {
      setUserActivityNotificationData(
        (prevState) => [
          ...prevState,
          ...userActivityNotifications?.user_activity,
        ]
        // Array.from(
        //   new Map(
        //     [...prevState, ...chatNotifications?.chat_activity].map((obj) => [
        //       obj.id,
        //       obj,
        //     ])
        //   ).values()
        // )
      );

      setHasNext(userActivityNotifications?.has_next);
    }
  }, [userActivityNotifications]);

  useEffect(() => {
    if (hasNext) {
      dispatch(userActivityNotificationsFunc(page, page_size));
    }
  }, [page]);

  return (
    <>
      {loading && userActivityNotificationData?.length === 0 && (
        <SkeletonGrid itemCount={10} height={100} spacing={2} pt={2} pl={2} />
      )}

      {!loading && userActivityNotificationData?.length === 0 ? (
        <Stack gap="16px" alignItems="center" justifyContent="center">
          <Box width="128px" height="128px">
            <img src={EmptyBox} alt="empty box" width="100%" />
          </Box>
          <Typography variant="body1">No chat notifications yet</Typography>
        </Stack>
      ) : (
        <div style={{ padding: "16px" }}>
          <InfiniteScroll
            dataLength={userActivityNotificationData?.length || []}
            next={fetchMoreData}
            hasMore={hasNext}
            loader={<CircularProgressLoading size={20} />}
            // scrollableTarget="scrollableDiv"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
            height={885}
          >
            {userActivityNotificationData?.map((data, index) => (
              <React.Fragment key={index}>
                <ChatUserCard
                  data={data}
                  setOpenNotificationDrawer={setOpenNotificationDrawer}
                />{" "}
              </React.Fragment>
            ))}
          </InfiniteScroll>
        </div>
      )}

      {/* <Stack p="24px" gap="16px">
        {userActivityData?.map((data, index) => (
          <React.Fragment key={index}>
            <ChatUserCard data={data} />{" "}
          </React.Fragment>
        ))}
      </Stack> */}
    </>
  );
};

export default UserActivityNotifications;
