import { Grid, Skeleton } from "@mui/material";
import React from "react";

const SkeletonGrid = ({
  itemCount,
  height,
  md,
  sm = 12,
  spacing,
  secondRowHeight,
  pt,
  pl
}) => {
  const renderSkeletonItems = () => {
    return Array.from({ length: itemCount }, (_, index) => (
      <Grid
        item
        sm={sm}
        md={secondRowHeight ? (index === 0 ? 8 : 4) : md}
        key={index}
      >
        <Skeleton
          variant="rounded"
          height={
            secondRowHeight
              ? index === 0
                ? secondRowHeight[0]
                : secondRowHeight[1]
              : height
          }
          animation="wave"
        />
      </Grid>
    ));
  };

  return (
    <Grid container spacing={spacing} width="100%" pt={pt} pl={pl}>
      {renderSkeletonItems()}
    </Grid>
  );
};

export default SkeletonGrid;
