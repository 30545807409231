import { Box, IconButton, Stack, Typography } from "@mui/material";
import React from "react";
import { UploadIcon } from "../../assets/constants";
import pngImg from "../../assets/images/image-icon.png";
import DeleteIcon from "../../icons/DeleteIcon";

const UploadBannerTemplate = ({
  handleFileClick,
  hiddenFileInputRef,
  handleFileChange,
  bannerSize,
  bannerName,
  deleteUploadedBanner,
}) => {
  return (
    <Stack>
      <Stack
        sx={{ cursor: "pointer" }}
        border="1px dashed #566875"
        p="10px"
        height="242px"
        borderRadius="8px"
      >
        {bannerName ? (
          <Box
            width="100%"
            display="flex"
            gap="8px"
            alignItems="center"
            padding="12px"
            borderRadius="8px"
            border="1px solid #EBEBEB"
          >
            <Box width="42px" height="42px">
              <img src={pngImg} alt="banner image" width="100%" height="100%" />
            </Box>
            <Stack flex="1">
              <Typography variant="subtitle1" fontSize="16px" fontWeight="400">
                {bannerName}
              </Typography>
              <Typography variant="caption" color="secondary">
                {bannerSize}
              </Typography>
            </Stack>
            <IconButton onClick={deleteUploadedBanner}>
              <DeleteIcon width={24} height={24} />
            </IconButton>
          </Box>
        ) : (
          <Stack
            height="100%"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            onClick={handleFileClick}
          >
            <input
              accept="image/png, image/jpg, image/jpeg"
              hidden
              type="file"
              ref={hiddenFileInputRef}
              onChange={handleFileChange}
              id="document-input"
            />
            <UploadIcon />
            <Stack gap="4px" alignItems="center">
              <Typography
                variant="h6"
                fontSize="14px"
                fontWeight="400"
                color="secondary"
              >
                Click{" "}
                <span style={{ color: "#0C80F3", textDecoration: "underline" }}>
                  browse
                </span>{" "}
                through your computer
              </Typography>
              <Typography
                variant="body2"
                fontSize="12px"
                color="secondary"
                textAlign="center"
              >
                For optimal display, please upload a banner with a 2:1 aspect
                ratio
              </Typography>
            </Stack>
          </Stack>
        )}
      </Stack>

      {/* <Stack gap="8px" pb="50px">
        {files?.map((file, i) => (
          <Box
            padding="12px"
            display="flex"
            alignItems="flex-start"
            bgcolor="#FCFCFC"
            gap="8px"
            key={i}
          >
            <Box
              width="48px"
              height="48px"
              bgcolor="#fff"
              borderRadius="8px"
              padding="2px"
            >
              <Avatar
                src={fileTypes(file?.type)}
                alt="client"
                className={classes.icon}
                variant="rounded"
              />
            </Box>
            <Stack width="100%" gap="8px">
              <Stack>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <HeaderText
                    textContent={file?.name}
                    fontSize="16px"
                    fontWeight="400"
                    color="#000"
                  />
                  <IconButton
                    size="small"
                    onClick={() => deleteFileHandler(i, file)}
                  >
                    <CancelOutlinedIcon />
                  </IconButton>
                </Box>
              </Stack>
              <HeaderText
                textContent={formatSizeUnits(file.size)}
                fontSize="12px"
                fontWeight="400"
                variant="body1"
                textAlign="-webkit-auto"
              />
            </Stack>
          </Box>
        ))}
      </Stack> */}
    </Stack>
  );
};

export default UploadBannerTemplate;
