import { Box, Button, Stack, Typography } from "@mui/material";
import React from "react";
import CardUI from "../../common/Card";

const CustomBillingScreen = () => {
  const purchased = false;

  return (
    <>
      {purchased ? (
        <Box display="flex" gap="16px" alignItems="flex-start">
          <CardUI maxWidth="100%" dashboard={true} padding="32px">
            <Stack gap="24px">
              <Typography
                variant="subtitle1"
                fontSize="16px"
                textTransform="uppercase"
                color="secondary"
              >
                Current Plan{" "}
              </Typography>
              <Stack
                bgcolor="rgba(12, 128, 243, 0.12)"
                border="1px solid #0C80F3"
                padding="24px 16px"
                borderRadius="8px"
                gap="13px"
              >
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Typography color="#073666" variant="h3" fontSize="28px">
                    Custom Plan
                  </Typography>
                  <Typography variant="h6" fontSize="23px">
                    $300
                    <Box component="span" fontSize="15px">
                      /Month
                    </Box>
                  </Typography>
                </Box>
                <Stack padding="8px 0">
                  <Typography color="#014361" variant="body2">
                    {" "}
                    <Box component="span" fontWeight={600}>
                      Fixed Rate Charging:{" "}
                    </Box>
                    Users pay a consistent rate, regardless of case usage.
                  </Typography>
                  <Typography color="#014361" variant="body2">
                    {" "}
                    <Box component="span" fontWeight={600}>
                      Unlimited Usage:{" "}
                    </Box>
                    Enjoy unrestricted access without per-case charges.{" "}
                  </Typography>
                </Stack>
              </Stack>
              <Stack gap="12px" height="300px">
                <Box display="flex" alignItems="center">
                  <Typography
                    width="166px"
                    variant="body2"
                    color="secondary"
                    fontWeight={500}
                  >
                    Billing Name
                  </Typography>
                  <Typography variant="body2" color="#0D0D0D">
                    Javiyon Simon{" "}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    width="166px"
                    variant="body2"
                    color="secondary"
                    fontWeight={500}
                  >
                    Billing Address{" "}
                  </Typography>
                  <Typography variant="body2" color="#0D0D0D">
                    19034 Verna Unions Apt. 164 - Honolulu, RI / 87535{" "}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    width="166px"
                    variant="body2"
                    color="secondary"
                    fontWeight={500}
                  >
                    Billing Phone Number{" "}
                  </Typography>
                  <Typography variant="body2" color="#0D0D0D">
                    365-374-4961{" "}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    width="166px"
                    variant="body2"
                    color="secondary"
                    fontWeight={500}
                  >
                    Payment Method{" "}
                  </Typography>
                  <Typography variant="body2" color="#0D0D0D">
                    Credit Card{" "}
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <Typography
                    width="166px"
                    variant="body2"
                    color="secondary"
                    fontWeight={500}
                  >
                    Last Payment{" "}
                  </Typography>
                  <Typography variant="body2" color="#0D0D0D">
                    1 Feb 2024{" "}
                  </Typography>
                </Box>
              </Stack>
              <Box alignSelf="flex-end">
                <Button variant="contained">Edit Payment details</Button>
              </Box>
            </Stack>
          </CardUI>
          <CardUI maxWidth={347} dashboard={true} padding="32px">
            <Stack gap="24px">
              <Typography
                variant="subtitle1"
                fontSize="16px"
                textTransform="uppercase"
                color="secondary"
              >
                Invoice history{" "}
              </Typography>
              <Stack gap="16px">
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Stack gap="8px">
                    <Typography variant="body2" fontWeight={500}>
                      INV-2000{" "}
                    </Typography>
                    <Typography variant="body2" color="#566875" fontSize="12px">
                      1 Feb 2024{" "}
                    </Typography>
                  </Stack>
                  <Typography variant="body1" fontWeight={500}>
                    $300{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#566875"
                    fontSize="12px"
                    sx={{ textDecoration: "underline" }}
                  >
                    PDF
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Stack gap="8px">
                    <Typography variant="body2" fontWeight={500}>
                      INV-2000{" "}
                    </Typography>
                    <Typography variant="body2" color="#566875" fontSize="12px">
                      1 Feb 2024{" "}
                    </Typography>
                  </Stack>
                  <Typography variant="body1" fontWeight={500}>
                    $300{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#566875"
                    fontSize="12px"
                    sx={{ textDecoration: "underline" }}
                  >
                    PDF
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Stack gap="8px">
                    <Typography variant="body2" fontWeight={500}>
                      INV-2000{" "}
                    </Typography>
                    <Typography variant="body2" color="#566875" fontSize="12px">
                      1 Feb 2024{" "}
                    </Typography>
                  </Stack>
                  <Typography variant="body1" fontWeight={500}>
                    $300{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#566875"
                    fontSize="12px"
                    sx={{ textDecoration: "underline" }}
                  >
                    PDF
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Stack gap="8px">
                    <Typography variant="body2" fontWeight={500}>
                      INV-2000{" "}
                    </Typography>
                    <Typography variant="body2" color="#566875" fontSize="12px">
                      1 Feb 2024{" "}
                    </Typography>
                  </Stack>
                  <Typography variant="body1" fontWeight={500}>
                    $300{" "}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="#566875"
                    fontSize="12px"
                    sx={{ textDecoration: "underline" }}
                  >
                    PDF
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </CardUI>
        </Box>
      ) : (
        <CardUI maxWidth="726px" dashboard={true} padding="32px">
          <Stack gap="24px" height={661}>
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              gap="8px"
            >
              <Typography
                variant="subtitle1"
                fontSize="16px"
                textTransform="uppercase"
                color="secondary"
              >
                Plan Details{" "}
              </Typography>
              <Button variant="contained">Purchase Plan</Button>
            </Box>

            <Stack
              bgcolor="rgba(12, 128, 243, 0.12)"
              border="1px solid #0C80F3"
              padding="24px 16px"
              borderRadius="8px"
              gap="13px"
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                gap="8px"
              >
                <Typography color="#073666" variant="h3" fontSize="28px">
                  Custom Plan
                </Typography>
                <Typography variant="h6" fontSize="23px">
                  $300
                  <Box component="span" fontSize="15px">
                    /Month
                  </Box>
                </Typography>
              </Box>
              <Stack padding="8px 0">
                <Typography color="#014361" variant="body2">
                  {" "}
                  <Box component="span" fontWeight={600}>
                    Fixed Rate Charging:{" "}
                  </Box>
                  Users pay a consistent rate, regardless of case usage.
                </Typography>
                <Typography color="#014361" variant="body2">
                  {" "}
                  <Box component="span" fontWeight={600}>
                    Unlimited Usage:{" "}
                  </Box>
                  Enjoy unrestricted access without per-case charges.{" "}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </CardUI>
      )}
    </>
  );
};

export default CustomBillingScreen;
