import React from "react";

const Ellipse = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="4"
      height="4"
      viewBox="0 0 4 4"
      fill="none"
    >
      <circle cx="2" cy="2" r="2" fill="black" fillOpacity="0.38" />
    </svg>
  );
};

export default Ellipse;
