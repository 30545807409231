import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Textfield from "../../../common/Textfield";
import CompanyLogo from "../../../assets/images/company-logo.jpg";
import EmptyBox from "../../../assets/images/empty-box.png";
import { getAllClientsFunc, getAgencyDetailFunc } from "../../../actions/Agency";
import { useDispatch, useSelector } from "react-redux";
import { resetPostNotification } from "../../../actions/Referrals";
import { getCaseByAgentFunc } from "../../../actions/Cases";
import CircularProgressLoading from "../../../common/CircularProgress";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";

// const clientOptions = [
//   { id: 1, name: "Arlenme Mccoy", profile_picture_url: CompanyLogo },
//   { id: 2, name: "John Smith", profile_picture_url: CompanyLogo },
//   { id: 3, name: "John Smith", profile_picture_url: CompanyLogo },
//   {
//     id: 4,
//     name: "Rachel Puran warden",
//     profile_picture_url: CompanyLogo,
//   },
// ];

const SelectAudienceStep = ({
  activeStep,
  steps,
  handleNext,
  handleBack,
  clientOptions,
  clientMembersArr,
  setClientMembersArr,
  statusFilter,
  setStatusFilter,
  setSelectedAgent,
  caseAssignedFilter,
  setCaseAssignedFilter,
  agentOptions,
  clientsLoading,
}) => {
  // const [clientMembersArr, setClientMembersArr] = useState(
  //   formValues.clientMembersArr
  // );
  // const [clientOptions, setClientOptions] = useState([]);

  const dispatch = useDispatch();

  const [errorMsg, setErrorMsg] = useState("");

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  // SUBMIT  FORM
  const submitClientsMemberHandler = async (e) => {
    e.preventDefault();

    dispatch(resetPostNotification());

    if (!clientMembersArr.length) {
      setErrorMsg("Please add clients to send the message");
      return;
    }

    setErrorMsg("");
    handleNext();

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({ event: "pn_audience_selected", agent: agentUser?.agent, agency: agencyDetail?.agency })
    }
  };

  const removeClientHandler = (id) => {
    const removeClient = clientMembersArr.filter((member) => member.id !== id);
    setClientMembersArr(removeClient);
  };

  const changeStatusFilterHandler = (e) => {
    setStatusFilter(e.target.value);
  };

  const changeCaseAssignedHandler = (e) => {
    setCaseAssignedFilter(e.target.value);

    const agentSelected = agentOptions.find(
      (agent) => agent.id === e.target.value
    );

    setSelectedAgent(agentSelected);
  };

  return (
    <>
      <DialogContent sx={{ padding: "32px !important", height: "462px" }}>
        <Stack gap="24px" height="100%">
          <Typography variant="subtitle1" fontSize="16px" fontWeight="400">
            You can use below filters to only delivers you push notifications
            whom you want to.
          </Typography>
          <Autocomplete
            //   {...defaultProps}
            multiple
            placeholder="Search Clients to add manually"
            // filterSelectedOptions
            id="client-members"
            renderTags={() => null}
            fullWidth
            value={clientMembersArr}
            onChange={(e, newValue) => {
              setClientMembersArr(newValue);
            }}
            // options={clientOptions}
            options={clientOptions?.map((option) => ({
              ...option,
              // key: `${option.id}-${option.name}`, // Combine ID and name to create a unique key
            }))}
            clearOnBlur={true}
            blurOnSelect={true}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            autoHighlight
            filterOptions={(options, { inputValue }) => {
              const inputValueLowerCase = inputValue.toLowerCase();
              return options.filter((option) =>
                option.name.toLowerCase().includes(inputValueLowerCase)
              );
            }}
            getOptionLabel={(option) => String(option.id)}
            renderOption={(props, option, state) => (
              <Box
                component="li"
                key={option.id}
                display="flex"
                sx={{ backgroundColor: "#fff !important" }}
                alignItems="center"
                justifyContent="space-between"
                width="100%"
                {...props}
              >
                <Box
                  display="flex"
                  alignItems="center"
                  gap="8px"
                  maxWidth="217px"
                  width="100%"
                >
                  <Avatar
                    src={
                      option.profile_picture_url && option.profile_picture_url
                    }
                  />
                  <Typography>{option.name}</Typography>
                </Box>
                <Box width="100%" textAlign="end">
                  <Button variant="text" color="primary">
                    {state.selected ? "REMOVE" : "ADD"}
                  </Button>
                </Box>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="search"
                label="Manual Search"
                error={!clientMembersArr.length && errorMsg ? true : false}
                helperText={!clientMembersArr.length && errorMsg}
                inputProps={{
                  ...params.inputProps,
                  autoComplete: "search", // disable autocomplete and autofill
                }}
              />
            )}
          />
          <Box display="flex" gap="24px" height="100%">
            <Stack
              maxWidth="193px"
              width="100%"
              gap="24px"
              paddingBottom="32px"
            >
              {/* CLIENT SELECT FILTER */}
              {/* <TextField
                variant="outlined"
                value={"all"}
                // onChange={(e) => setSelectedCase(e.target.value)}
                select
                label="Client Assigned to"
                size="small"
              >
                <MenuItem value="all">All</MenuItem>
              </TextField> */}

              {/* CASE SELECT FILTER */}
              <TextField
                variant="outlined"
                value={caseAssignedFilter}
                onChange={changeCaseAssignedHandler}
                select
                label="Case Assigned to"
                size="small"
              >
                <MenuItem value="all">All</MenuItem>
                {agentOptions.map((agent) => (
                  <MenuItem key={agent.id} value={agent.id}>
                    {agent.name}
                  </MenuItem>
                ))}
              </TextField>

              {/* CASE STATUS FILTER */}
              <TextField
                variant="outlined"
                value={statusFilter}
                onChange={changeStatusFilterHandler}
                select
                label="Case Status"
                size="small"
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="open">Open</MenuItem>
                <MenuItem value="closed">Closed</MenuItem>
                <MenuItem value="settled">Settled</MenuItem>
                <MenuItem value="on hold">On Hold</MenuItem>
              </TextField>

              {/* CASE HEALTH FILTER */}
              {/* <TextField
                variant="outlined"
                value={"good"}
                // onChange={(e) => setSelectedCase(e.target.value)}
                select
                label="Case Health"
                size="small"
              >
                <MenuItem value="good">Good</MenuItem>
              </TextField> */}

              {/* CASE VALUE FILTER */}
              {/* <TextField
                variant="outlined"
                value={"high"}
                // onChange={(e) => setSelectedCase(e.target.value)}
                select
                label="Case Value"
                size="small"
              >
                <MenuItem value="high">High</MenuItem>
              </TextField> */}
            </Stack>
            <Divider orientation="vertical" sx={{ height: "auto" }} />
            <Stack width="100%">
              <Typography
                variant="subtitle1"
                fontSize="16px"
                fontWeight="500"
                color="secondary"
                textTransform="uppercase"
              >
                {`Client Selected ${clientMembersArr?.length}/${clientOptions?.length}`}
              </Typography>

              {clientsLoading ? (
                <CircularProgressLoading />
              ) : clientMembersArr.length <= 0 ? (
                <Stack
                  gap={1}
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box width="75px" height="75px">
                    <img
                      src={EmptyBox}
                      alt="no client"
                      width="100%"
                      height="100%"
                    />
                  </Box>

                  <Typography variant="body2">No Client Selected</Typography>
                </Stack>
              ) : (
                <Stack>
                  {clientMembersArr?.map((member) => (
                    <React.Fragment key={member.id}>
                      {" "}
                      <Box
                        component="li"
                        display="flex"
                        alignItems="center"
                        justifyContent="space-between"
                        gap="16px"
                        py="16px"
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="8px"
                          maxWidth="217px"
                          width="100%"
                        >
                          <Avatar
                            src={
                              member.profile_picture_url &&
                              member.profile_picture_url
                            }
                            alt={member.name}
                          />
                          <Typography>{member.name}</Typography>
                        </Box>

                        <Button
                          variant="text"
                          color="primary"
                          disableRipple
                          onClick={() => removeClientHandler(member.id)}
                        >
                          REMOVE
                        </Button>
                      </Box>
                      <Divider />
                    </React.Fragment>
                  ))}
                </Stack>
              )}

              {/* {clientMembersArr.length <= 0 ? (
                <Stack
                  gap={1}
                  height="100%"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box width="75px" height="75px">
                    <img
                      src={EmptyBox}
                      alt="no client"
                      width="100%"
                      height="100%"
                    />
                  </Box>

                  <Typography variant="body2">No Client Selected</Typography>
                </Stack>
              ) : (
                <Stack>
                  {clientsLoading ? (
                    <CircularProgressLoading />
                  ) : (
                    clientMembersArr?.map((member) => (
                      <React.Fragment key={member.id}>
                        {" "}
                        <Box
                          component="li"
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          gap="16px"
                          py="16px"
                        >
                          <Box
                            display="flex"
                            alignItems="center"
                            gap="8px"
                            maxWidth="217px"
                            width="100%"
                          >
                            <Avatar
                              src={
                                member.profile_picture_url &&
                                member.profile_picture_url
                              }
                              alt={member.name}
                            />
                            <Typography>{member.name}</Typography>
                          </Box>

                          <Button
                            variant="text"
                            color="primary"
                            disableRipple
                            onClick={() => removeClientHandler(member.id)}
                          >
                            REMOVE
                          </Button>
                        </Box>
                        <Divider />
                      </React.Fragment>
                    ))
                  )}
                </Stack>
              )} */}
            </Stack>
          </Box>
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          padding: "16px 32px",
          boxShadow: "0px -2px 16px 0px rgba(0, 0, 0, 0.08)",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          zIndex: 99,
          background: "#fff",
        }}
      >
        <Button
          color="primary"
          onClick={handleBack}
          sx={{ textTransform: "uppercase", fontSize: "15px" }}
        >
          Go back
        </Button>
        <Button variant="contained" onClick={submitClientsMemberHandler}>
          {activeStep === steps.length - 1 ? "Finish" : "Continue"}
        </Button>
      </DialogActions>
    </>
  );
};

export default SelectAudienceStep;
