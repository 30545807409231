import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditIcon from "../../icons/EditIcon";
import AvatarGroups from "../AvatarGroups";

const EditCaseField = ({
  id,
  label,
  value,
  closeEditModal,
  openEditFieldModalHandler,
  modalStates,
  children,
  saveFieldHandler,
  loadingUpdatedCase,
  padding = "6px",
  displayValue,
  assignedTo = false,
}) => {
  const openEditModal = modalStates[id] || false;

  return (
    <Stack
      gap="4px"
      position="relative"
      alignItems="flex-start"
      width="100%"
      bgcolor={openEditModal && "#F4F6F8"}
      padding={padding}
      sx={{
        cursor: "pointer",
        "&:hover": {
          background: "#F4F6F8",
          borderRadius: "4px",
          "& .editIcon": {
            visibility: "visible",
          },
        },
      }}
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="4px"
        width="100%"
      >
        <Typography fontSize="14px" variant="body1" color="secondary">
          {label}
        </Typography>

        <IconButton
          className="editIcon"
          sx={{ visibility: openEditModal ? "visible" : "hidden" }}
          aria-label="edit"
          onClick={() => openEditFieldModalHandler(id)}
        >
          <EditIcon width={16} height={16} color="#566875" opacity={1} />
        </IconButton>
      </Box>

      {assignedTo ? (
        <AvatarGroups assigned_to_details={displayValue} />
      ) : (
        <Typography
          fontSize="16px"
          variant="body1"
          color="#000"
          fontWeight="400"
        >
          {displayValue ? displayValue : "-"}
        </Typography>
      )}

      {openEditModal && (
        <Stack
          position="absolute"
          width="100%"
          top="34px"
          left="0"
          bgcolor="#fff"
          zIndex="9"
          borderRadius="4px"
          boxShadow="0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 11px 15px -7px rgba(0, 0, 0, 0.20)"
        >
          <Box padding="24px 16px 8px">{children}</Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            gap="8px"
            padding="8px"
          >
            <Button
              variant="text"
              sx={{ color: "rgba(0, 0, 0, 0.87)" }}
              onClick={() => closeEditModal(id)}
            >
              Cancel
            </Button>
            <LoadingButton
              variant="contained"
              size="small"
              onClick={() => saveFieldHandler(value, id)}
              loading={loadingUpdatedCase}
            >
              Save
            </LoadingButton>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};

export default EditCaseField;
