export const INNER_FOLDER_VIEW_REQUEST = "INNER_FOLDER_VIEW_REQUEST";
export const INNER_FOLDER_VIEW_SUCCESS = "INNER_FOLDER_VIEW_SUCCESS";
export const INNER_FOLDER_VIEW_FAIL = "INNER_FOLDER_VIEW_FAIL";
export const RESET_INNER_FOLDER_VIEW = "RESET_INNER_FOLDER_VIEW";

export const CREATE_EMPTY_FOLDER_REQUEST = "CREATE_EMPTY_FOLDER_REQUEST";
export const CREATE_EMPTY_FOLDER_SUCCESS = "CREATE_EMPTY_FOLDER_SUCCESS";
export const CREATE_EMPTY_FOLDER_FAIL = "CREATE_EMPTY_FOLDER_FAIL";
export const RESET_CREATE_EMPTY_FOLDER = "RESET_CREATE_EMPTY_FOLDER";

export const RENAME_FOLDER_REQUEST = "RENAME_FOLDER_REQUEST";
export const RENAME_FOLDER_SUCCESS = "RENAME_FOLDER_SUCCESS";
export const RENAME_FOLDER_FAIL = "RENAME_FOLDER_FAIL";
export const RESET_RENAME_FOLDER = "RESET_RENAME_FOLDER";

export const RENAME_FILE_REQUEST = "RENAME_FILE_REQUEST";
export const RENAME_FILE_SUCCESS = "RENAME_FILE_SUCCESS";
export const RENAME_FILE_FAIL = "RENAME_FILE_FAIL";
export const RESET_RENAME_FILE = "RESET_RENAME_FILE";

export const DELETE_FILE_INFOLDER_REQUEST = "DELETE_FILE_INFOLDER_REQUEST";
export const DELETE_FILE_INFOLDER_SUCCESS = "DELETE_FILE_INFOLDER_SUCCESS";
export const DELETE_FILE_INFOLDER_FAIL = "DELETE_FILE_INFOLDER_FAIL";
export const RESET_DELETE_FILE_INFOLDER = "RESET_DELETE_FILE_INFOLDER";

export const DELETE_FILE_REQUEST = "DELETE_FILE_REQUEST";
export const DELETE_FILE_SUCCESS = "DELETE_FILE_SUCCESS";
export const DELETE_FILE_FAIL = "DELETE_FILE_FAIL";
export const RESET_DELETE_FILE = "RESET_DELETE_FILE";

export const DELETE_FOLDER_REQUEST = "DELETE_FOLDER_REQUEST";
export const DELETE_FOLDER_SUCCESS = "DELETE_FOLDER_SUCCESS";
export const DELETE_FOLDER_FAIL = "DELETE_FOLDER_FAIL";
export const RESET_DELETE_FOLDER = "RESET_DELETE_FOLDER";

export const MOVE_FILE_TO_FOLDER_REQUEST = "MOVE_FILE_TO_FOLDER_REQUEST";
export const MOVE_FILE_TO_FOLDER_SUCCESS = "MOVE_FILE_TO_FOLDER_SUCCESS";
export const MOVE_FILE_TO_FOLDER_FAIL = "MOVE_FILE_TO_FOLDER_FAIL";
export const RESET_MOVE_FILE_TO_FOLDER = "RESET_MOVE_FILE_TO_FOLDER";

export const GET_CLIENT_CASES_DOCS_REQUEST = "GET_CLIENT_CASES_DOCS_REQUEST";
export const GET_CLIENT_CASES_DOCS_SUCCESS = "GET_CLIENT_CASES_DOCS_SUCCESS";
export const GET_CLIENT_CASES_DOCS_FAIL = "GET_CLIENT_CASES_DOCS_FAIL";
export const RESET_GET_CLIENT_CASES_DOCS = "RESET_GET_CLIENT_CASES_DOCS";

export const SEND_DOC_REMINDER_REQUEST = "SEND_DOC_REMINDER_REQUEST";
export const SEND_DOC_REMINDER_SUCCESS = "SEND_DOC_REMINDER_SUCCESS";
export const SEND_DOC_REMINDER_FAIL = "SEND_DOC_REMINDER_FAIL";
export const RESET_SEND_DOC_REMINDER = "RESET_SEND_DOC_REMINDER";

export const CANCEL_DOCUMENT_REQUEST = "CANCEL_DOCUMENT_REQUEST";
export const CANCEL_DOCUMENT_SUCCESS = "CANCEL_DOCUMENT_SUCCESS";
export const CANCEL_DOCUMENT_FAIL = "CANCEL_DOCUMENT_FAIL";
export const RESET_CANCEL_DOCUMENT = "RESET_CANCEL_DOCUMENT";
