import React, { useEffect, useState } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import companyLogo from "../../../assets/images/company-logo.jpg";
import SmsIcon from "../../../icons/SmsIcon";
import CallIcon from "../../../icons/CallIcon";
import Style from "../style";
import UserFeedCard from "../../UserFeedCard";
import AlertNotificationCard from "../../AlertNotificationCard";
import AlertNotifications from "../AlertNotifications";
import ChatUserCard from "../../ChatUserCard";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import CircularProgressLoading from "../../../common/CircularProgress";
import {
  allNotificationsFunc,
  markNotificationsFunc,
} from "../../../actions/AppConfig";
import EmptyBox from "../../../assets/images/empty-box.png";
import SkeletonGrid from "../../SkeletonGrid";

const page_size = 10;

const Styles = makeStyles((theme) => Style(theme));

const AllNotifications = ({
  tabValue,
  setOpenNotificationDrawer,
  setTotalNotifications,
  totalNotifications,
  // notificationData,
  // setNotificationData,
}) => {
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(1);
  const [notificationData, setNotificationData] = useState([]);

  const dispatch = useDispatch();
  const getAllNotifications = useSelector((state) => state.getAllNotifications);
  const {
    loading: loadingAllNotifications,
    error: errorAllNotifications,
    allNotifications,
  } = getAllNotifications;

  const markedAsReadNotifications = useSelector(
    (state) => state.markedAsReadNotifications
  );
  const { loading: loadingMarkNotifications, markedNotifications } =
    markedAsReadNotifications;

  const fetchMoreData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    dispatch(markNotificationsFunc());
  }, []);

  useEffect(() => {
    if (!loadingAllNotifications && allNotifications?.ok) {
      setNotificationData((prevState) =>
        Array.from(
          new Map(
            [...prevState, ...allNotifications?.notifications].map((obj) => [
              obj.id,
              obj,
            ])
          ).values()
        )
      );
      setHasNext(allNotifications?.has_next);
    }
  }, [allNotifications]);

  useEffect(() => {
    if (!loadingMarkNotifications && markedNotifications?.ok) {
      setTotalNotifications(allNotifications?.number_of_unread_notifications);
    }
  }, [markedNotifications]);

  useEffect(() => {
    if (tabValue) {
      dispatch(allNotificationsFunc(page, page_size));
    }
  }, [tabValue, markedNotifications]);

  useEffect(() => {
    if (hasNext) {
      dispatch(allNotificationsFunc(page, page_size));
    }
  }, [page]);

  const classes = Styles();

  return (
    <>
      {loadingAllNotifications && notificationData?.length === 0 && (
        <SkeletonGrid itemCount={10} height={100} spacing={2} pt={2} pl={2} />
      )}

      {!loadingAllNotifications && notificationData?.length === 0 ? (
        <Stack gap="16px" alignItems="center" justifyContent="center">
          <Box width="128px" height="128px">
            <img src={EmptyBox} alt="empty box" width="100%" />
          </Box>
          <Typography variant="body1">No notifications yet</Typography>
        </Stack>
      ) : (
        <div style={{ padding: "16px" }}>
          <InfiniteScroll
            dataLength={notificationData.length || []}
            next={fetchMoreData}
            hasMore={hasNext}
            loader={<CircularProgressLoading size={20} />}
            // scrollableTarget="scrollableDiv"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
            height={885}
          >
            {notificationData?.map((data, index) => (
              <React.Fragment key={index}>
                {data?.log_category === "user_activity" ||
                data?.log_category === "chat_activity" ? (
                  <ChatUserCard
                    data={data}
                    setOpenNotificationDrawer={setOpenNotificationDrawer}
                  />
                ) : (
                  <AlertNotificationCard
                    notificationData={data}
                    backgroundColor="rgba(211, 47, 47, 0.15)"
                  />
                )}
              </React.Fragment>
            ))}
          </InfiniteScroll>
        </div>
      )}
    </>
  );
};

export default AllNotifications;
