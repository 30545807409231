import axios from "axios";
import {
  CANCEL_DOCUMENT_FAIL,
  CANCEL_DOCUMENT_REQUEST,
  CANCEL_DOCUMENT_SUCCESS,
  CREATE_EMPTY_FOLDER_FAIL,
  CREATE_EMPTY_FOLDER_REQUEST,
  CREATE_EMPTY_FOLDER_SUCCESS,
  DELETE_FILE_FAIL,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FOLDER_FAIL,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_SUCCESS,
  GET_CLIENT_CASES_DOCS_FAIL,
  GET_CLIENT_CASES_DOCS_REQUEST,
  GET_CLIENT_CASES_DOCS_SUCCESS,
  INNER_FOLDER_VIEW_FAIL,
  INNER_FOLDER_VIEW_REQUEST,
  INNER_FOLDER_VIEW_SUCCESS,
  MOVE_FILE_TO_FOLDER_FAIL,
  MOVE_FILE_TO_FOLDER_REQUEST,
  MOVE_FILE_TO_FOLDER_SUCCESS,
  RENAME_FILE_FAIL,
  RENAME_FILE_REQUEST,
  RENAME_FILE_SUCCESS,
  RENAME_FOLDER_FAIL,
  RENAME_FOLDER_REQUEST,
  RENAME_FOLDER_SUCCESS,
  RESET_CANCEL_DOCUMENT,
  RESET_CREATE_EMPTY_FOLDER,
  RESET_DELETE_FILE,
  RESET_DELETE_FOLDER,
  RESET_GET_CLIENT_CASES_DOCS,
  RESET_INNER_FOLDER_VIEW,
  RESET_MOVE_FILE_TO_FOLDER,
  RESET_RENAME_FILE,
  RESET_RENAME_FOLDER,
  RESET_SEND_DOC_REMINDER,
  SEND_DOC_REMINDER_FAIL,
  SEND_DOC_REMINDER_REQUEST,
  SEND_DOC_REMINDER_SUCCESS,
} from "../../assets/constants/documents";
import { BASE_URL } from "..";
import Cookies from "js-cookie";
import { GET_A_CASE_BY_CID_SUCCESS } from "../../assets/constants/caseConstants";
import { getCaseDetailByCaseId } from "../Cases";

// ============= CREATE EMPTY FOLDER ACTIONS  ========================
export const createEmptyFolderFunc = (documentObj) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_EMPTY_FOLDER_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agencyId");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/storage/create-folder`,
        documentObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: CREATE_EMPTY_FOLDER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CREATE_EMPTY_FOLDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetCreateEmptyFolder = () => ({
  type: RESET_CREATE_EMPTY_FOLDER,
});

// ============= INNER FOLDER VIEW ACTIONS  ========================
export const innerFolderViewFunc = (clientId, folderId) => async (dispatch) => {
  try {
    dispatch({ type: INNER_FOLDER_VIEW_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agencyId");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/storage/get-folder-contents?client_id=${clientId}&folder_id=${folderId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: INNER_FOLDER_VIEW_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: INNER_FOLDER_VIEW_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetInnerFolderView = () => (dispatch) =>
  dispatch({
    type: RESET_INNER_FOLDER_VIEW,
  });

// ============= RENAME FOLDER ACTIONS  ========================
export const renameFolderFunc = (renameFolderObj) => async (dispatch) => {
  try {
    dispatch({ type: RENAME_FOLDER_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/storage/rename-folder`,
        renameFolderObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: RENAME_FOLDER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: RENAME_FOLDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetRenameFolder = () => ({
  type: RESET_RENAME_FOLDER,
});

// ============= RENAME FILE ACTIONS  ========================
export const renameFileFunc = (fileObj) => async (dispatch) => {
  try {
    dispatch({ type: RENAME_FILE_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/storage/rename-file`,
        fileObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.msg);
      }

      dispatch({
        type: RENAME_FILE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: RENAME_FILE_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetRenameFile = () => ({
  type: RESET_RENAME_FILE,
});

// ============= MOVE FILE TO FOLDER ACTIONS  ========================
export const moveFileToFolderFunc = (fileObj) => async (dispatch) => {
  try {
    dispatch({ type: MOVE_FILE_TO_FOLDER_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/storage/move-files`,
        fileObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.msg);
      }

      dispatch({
        type: MOVE_FILE_TO_FOLDER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: MOVE_FILE_TO_FOLDER_FAIL,
      payload: error ? error?.message : "Something went wrong!",
    });
  }
};

export const resetMoveToFolder = () => ({
  type: RESET_MOVE_FILE_TO_FOLDER,
});

// ============= DELETE FILE ACTIONS  ========================
export const deleteFileFunc = (fileObj) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_FILE_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/storage/delete-files`,
        fileObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.msg);
      }

      dispatch({
        type: DELETE_FILE_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_FILE_FAIL,
      payload: error ? error?.message : "Something went wrong!",
    });
  }
};

export const resetDeleteFile = () => ({
  type: RESET_DELETE_FILE,
});

// ============= DELETE FOLDER ACTIONS  ========================
export const deleteFolderFunc = (fileObj) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_FOLDER_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/storage/delete-folders`,
        fileObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.msg);
      }

      dispatch({
        type: DELETE_FOLDER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DELETE_FOLDER_FAIL,
      payload: error ? error?.message : "Something went wrong!",
    });
  }
};

export const resetDeleteFolder = () => ({
  type: RESET_DELETE_FOLDER,
});

// ============= GET CLIENT AND CASES DOCUMENTS ACTIONS  ========================
export const getClientCasesDocsFunc = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: GET_CLIENT_CASES_DOCS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/storage/get-client-and-cases-documents?client_id=${clientId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_CLIENT_CASES_DOCS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_CLIENT_CASES_DOCS_FAIL,
      payload: error ? error?.message : "Something went wrong!",
    });
  }
};

export const resetClientCasesDocs = () => ({
  type: RESET_GET_CLIENT_CASES_DOCS,
});

// ============= SEND DOCUMENT REMINDER ACTIONS  ========================
export const sendDocReminderFunc = (reminderObj) => async (dispatch) => {
  try {
    dispatch({ type: SEND_DOC_REMINDER_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/storage/send-request-reminder`,
        reminderObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: SEND_DOC_REMINDER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: SEND_DOC_REMINDER_FAIL,
      payload: error ? error?.message : "Something went wrong!",
    });
  }
};

export const resetSendDocReminder = () => ({
  type: RESET_SEND_DOC_REMINDER,
});

// ============= CANCEL  DOCUMENT REQUEST ACTIONS  ========================
export const cancelDocRequestFunc =
  (cancelDocObj, caseId) => async (dispatch) => {
    try {
      dispatch({ type: CANCEL_DOCUMENT_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.post(
          `${BASE_URL}/storage/cancel-document-request`,
          cancelDocObj,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: CANCEL_DOCUMENT_SUCCESS,
          payload: response?.data,
        });

        if (caseId) {
          dispatch(getCaseDetailByCaseId(caseId));
        }
      }
    } catch (error) {
      dispatch({
        type: CANCEL_DOCUMENT_FAIL,
        payload: error ? error?.message : "Something went wrong!",
      });
    }
  };

export const resetCancelDocRequest = () => ({
  type: RESET_CANCEL_DOCUMENT,
});
