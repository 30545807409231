import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { checkFileExtension, timeDateFormat } from "../../utils/helpers";
import { makeStyles } from "@mui/styles";
import { FolderIcon } from "../../assets/constants";
import pdfImg from "../../assets/images/pdfImg.png";
import pngImg from "../../assets/images/image-icon.png";
import docImg from "../../assets/images/docImg.png";
import RequestDocIcon from "../../icons/RequestDocIcon";

const useStyles = makeStyles(() => {
  return {
    doc_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "12px",
      background: "#fff",
      borderRadius: "8px",
      border: "1px solid #EBEBEB",
      padding: "12px",
      cursor: "pointer",
      "&:hover": {
        background: "#EBEBEB",
      },
    },
    folder_icon: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    doc_title: {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      width: "67%",
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      // marginRight: 8,
    },
  };
});

const DocumentListViewCard = ({
  doc,
  fromClientPage,
  singleInnerFolderFile,
  handleTitleClick,
  handleBoxClick,
  changeCheckBoxHandler,
  selectedCheckedDoc,
  openDocumentMenu,
  handleDocumentClick,
  setMenuFileIndex,
  setMenuFileDownloadURLs,
  setSelectedDocument,
  index,
  isFolder,
  blob_name,
  fileTitle,
  numOfFiles,
  fileSize,
  documentType,
  fsLightBoxViewHandler,
  fromInnerFolderView,
  setInnerSelectedFile,
  selectedSingleDoc,
  innerSelectedCheckedDoc,
  innerModifiedCheckFile,
  changeInnerCheckBoxHandler,
}) => {
  const classes = useStyles();

  return (
    <Box
      component="a"
      p={1.5}
      onClick={() => {
        if (isFolder) {
          handleTitleClick(doc);
          setSelectedDocument(doc);
        } else {
          fsLightBoxViewHandler(doc);
        }
      }}
      alignItems="center"
      justifyContent="space-between"
      gap="8px"
      className={classes.doc_container}
      bgcolor={
        selectedCheckedDoc?.some((item) => item?.id === doc?.id)
          ? "#D9ECFF !important"
          : ""
      }
    >
      <Box onClick={handleBoxClick}>
        <Checkbox
          onChange={() => {
            if (fromInnerFolderView) {
              changeInnerCheckBoxHandler({
                ...singleInnerFolderFile,
                folder_id: selectedSingleDoc?.id,
                index_id: `${selectedSingleDoc?.id}-${index}`,
              });
            } else {
              changeCheckBoxHandler({
                ...doc,
                unique_id: `${doc?.type}-${doc?.id}`,
              });
            }
          }}
          checked={
            fromInnerFolderView
              ? innerSelectedCheckedDoc.some(
                  (selectedItem) =>
                    selectedItem?.index_id === innerModifiedCheckFile?.index_id
                )
              : selectedCheckedDoc?.some(
                  (selectedItem) => selectedItem?.id === doc?.id
                )
          }
          // inputProps={{ "aria-label": "controlled" }}
        />
      </Box>

      <Box display="flex" alignItems="center" flex="1" gap="8px">
        {isFolder ? (
          <Box
            width="48px"
            height="48px"
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <FolderIcon />
          </Box>
        ) : checkFileExtension(blob_name) ? (
          <Box bgcolor="#FCFCFC" width="48px" height="48px" padding="6px">
            <img src={pngImg} alt={doc?.title} width="100%" height="100%" />
          </Box>
        ) : (
          <Box bgcolor="#FCFCFC" width="48px" height="48px" padding="6px">
            <img src={pdfImg} alt={doc?.title} width="100%" height="100%" />
          </Box>
        )}

        <Typography
          className={classes.doc_title}
          variant="subtitle1"
          fontSize="16px"
          fontWeight="400"
        >
          {fileTitle}
        </Typography>
      </Box>

      {!fromClientPage && (
        <Stack flex="1">
          <Typography
            variant="body2"
            // fontSize="12px"
            fontWeight="400"
          >
            {fileSize}
          </Typography>
          <Typography
            variant="caption"
            // fontSize="12px"
            fontWeight="400"
          >
            {isFolder
              ? numOfFiles === 1
                ? `${numOfFiles} file`
                : `${numOfFiles} files`
              : `1 file`}
          </Typography>
        </Stack>
      )}

      <Box display="flex" alignItems="center" flex="1">
        <Chip
          label={documentType}
          size="small"
          sx={{
            marginLeft: 0,
            padding: "4px",
            color: `${documentType === "Personal" ? "#2196F3" : "#3DC583"}`,
            background: `${
              documentType === "Personal"
                ? "rgba(33, 150, 243, 0.15)"
                : "rgba(61, 197, 131, 0.15)"
            }`,
            // "& .MuiChip-deleteIcon": {
            //   color: `${
            //     doc?.document_type === "client_level"
            //       ? "#2196F3"
            //       : "#3DC583"
            //   }`,
            // },
          }}
        />
      </Box>

      <Stack flex="1">
        <Typography variant="body2">Last Modified </Typography>
        <Typography variant="caption" color="secondary">
          {doc?.updated_at
            ? timeDateFormat(doc?.updated_at)
            : timeDateFormat(doc?.created_at)}
        </Typography>
      </Stack>

      {selectedCheckedDoc?.length === 0 && (
        <IconButton
          id="document-btn"
          aria-controls={openDocumentMenu ? "document-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openDocumentMenu ? "true" : undefined}
          onClick={(event) => {
            handleDocumentClick(event);
            setMenuFileIndex(index);
            setMenuFileDownloadURLs([doc]);
            fromInnerFolderView
              ? innerSelectedCheckedDoc?.length
                ? setInnerSelectedFile(null)
                : setInnerSelectedFile({
                    ...singleInnerFolderFile,
                    folder_id: selectedSingleDoc?.id,
                  })
              : selectedCheckedDoc?.length
              ? setSelectedDocument(null)
              : setSelectedDocument(doc);
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
    </Box>
  );
};

export default DocumentListViewCard;
