import { Box } from "@mui/material";
import React from "react";
import { Outlet } from "react-router-dom";
// import Appbar from "../Dashboard/MainView/Appbar";
import NavbarUI from "../NavbarUI";
import Sidedrawer from "../SideDrawer";
import { makeStyles } from "@mui/styles";

const drawerWidth = "255px";
const useStyles = makeStyles((theme) => {
  return {
    root: {
      display: "flex",
      overflow: "auto",
      height: "100%",
      // background: "green",
    },
    page: {
      width: "100%",
      padding: "20px 28px",
      paddingBottom: "40px",
    },
    toolbar: theme.mixins.toolbar,
  };
});

const AppLayout = () => {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {/* APP BAR */}

      <NavbarUI drawerWidth={drawerWidth} />

      {/* SIDE DRAWER */}

      <Sidedrawer drawerWidth={drawerWidth} />

      <Box className={classes.page}>
        <Box className={classes.toolbar}></Box>
        <Box pb={2}>
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
};

export default AppLayout;
