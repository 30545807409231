import React from "react";

const DocumentFolderIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="106"
      height="97"
      viewBox="0 0 106 97"
      fill="none"
    >
      <path
        d="M88.334 21.9998H48.584L39.7507 13.1665H17.6673C12.809 13.1665 8.83398 17.1415 8.83398 21.9998V39.6665H97.1673V30.8332C97.1673 25.9748 93.1923 21.9998 88.334 21.9998Z"
        fill="#FFA000"
      />
      <path
        d="M88.334 22H17.6673C12.809 22 8.83398 25.975 8.83398 30.8333V75C8.83398 79.8583 12.809 83.8333 17.6673 83.8333H88.334C93.1923 83.8333 97.1673 79.8583 97.1673 75V30.8333C97.1673 25.975 93.1923 22 88.334 22Z"
        fill="#FFDF80"
      />
    </svg>
  );
};

export default DocumentFolderIcon;
