import React from "react";

const PdfBackArrow = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
    >
      <path
        d="M18.7498 24.8996L10.5998 16.7496C9.6373 15.7871 9.6373 14.2121 10.5998 13.2496L18.7498 5.09961"
        stroke="#566875"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PdfBackArrow;
