import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  DialogActions,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import Textfield from "../../../common/Textfield";
import { getAgencyDetailFunc } from "../../../actions/Agency";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";

const ComposeMessageStep = ({
  activeStep,
  steps,
  handleNext,
  formValues,
  setFormValues,
}) => {
  const [errorMsg, setErrorMsg] = useState("");

  const dispatch = useDispatch();
  
  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  // SUBMIT  FORM
  const submitMessageHandler = async (e) => {
    e.preventDefault();
   

    if (!formValues.messageTitle) {
      setErrorMsg("Please enter message title");
      return;
    }

    if (!formValues.messageDescription) {
      setErrorMsg("Please enter message description");
      return;
    }

    setErrorMsg("");

    // if (!formValues.messageDescription) {
    //   setErrorMsg("Please enter message description");
    //   return;
    // }

    handleNext();

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({ event: "pn_message_added", agent: agentUser?.agent, agency: agencyDetail?.agency })
    }
  };

  return (
    <>
      <DialogContent sx={{ padding: "32px !important", height: "462px" }}>
        <Stack gap="32px">
          <Typography variant="subtitle1" fontSize="16px" fontWeight="400">
            Keeping your push message short will help you to engage more
            clients.
          </Typography>
          <Textfield
            label="Push Message Title"
            variant="outlined"
            value={formValues.messageTitle}
            onChange={(e) =>
              setFormValues({ ...formValues, messageTitle: e.target.value })
            }
            errorContent={!formValues.messageTitle && errorMsg}
            helperText={!formValues.messageTitle && errorMsg}
          />
          <Textfield
            label="Push Message"
            name="messageDescription"
            multiline
            maxRows={4}
            variant="outlined"
            value={formValues.messageDescription}
            errorContent={!formValues.messageDescription && errorMsg}
            helperText={!formValues.messageDescription && errorMsg}
            onChange={(e) =>
              setFormValues({
                ...formValues,
                messageDescription: e.target.value,
              })
            }
          />
        </Stack>
      </DialogContent>

      <DialogActions
        sx={{
          padding: "16px 32px",
          boxShadow: "0px -2px 16px 0px rgba(0, 0, 0, 0.08)",
        }}
      >
        <Button variant="contained" onClick={submitMessageHandler}>
          {activeStep === steps.length - 1 ? "Finish" : "Continue"}
        </Button>
      </DialogActions>
    </>
  );
};

export default ComposeMessageStep;
