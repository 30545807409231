import React from "react";

const SortIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M2.25 5.25H15.75"
        stroke="#566875"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M4.5 9H13.5"
        stroke="#566875"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M7.5 12.75H10.5"
        stroke="#566875"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SortIcon;
