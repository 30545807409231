import axios from "axios";
import {
  ALERT_NOTIFICATIONS_FAIL,
  ALERT_NOTIFICATIONS_REQUEST,
  ALERT_NOTIFICATIONS_SUCCESS,
  ALL_NOTIFICATIONS_FAIL,
  ALL_NOTIFICATIONS_REQUEST,
  ALL_NOTIFICATIONS_SUCCESS,
  CHAT_NOTIFICATIONS_FAIL,
  CHAT_NOTIFICATIONS_REQUEST,
  CHAT_NOTIFICATIONS_SUCCESS,
  DISABLE_APP_ACCESS_FAIL,
  DISABLE_APP_ACCESS_REQUEST,
  DISABLE_APP_ACCESS_SUCCESS,
  ENABLE_APP_ACCESS_FAIL,
  ENABLE_APP_ACCESS_REQUEST,
  ENABLE_APP_ACCESS_SUCCESS,
  GET_BODY_PARTS_FAIL,
  GET_BODY_PARTS_REQUEST,
  GET_BODY_PARTS_SUCCESS,
  GET_CASE_SUMMARY_LINK_FAIL,
  GET_CASE_SUMMARY_LINK_REQUEST,
  GET_CASE_SUMMARY_LINK_SUCCESS,
  GET_EXPENSE_DETAIL_FAIL,
  GET_EXPENSE_DETAIL_REQUEST,
  GET_EXPENSE_DETAIL_SUCCESS,
  MARK_NOTIFICATIONS_FAIL,
  MARK_NOTIFICATIONS_REQUEST,
  MARK_NOTIFICATIONS_SUCCESS,
  RESET_ALERT_NOTIFICATIONS,
  RESET_ALL_NOTIFICATIONS,
  RESET_APP_ACCESS_STATE,
  RESET_BODY_PARTS,
  RESET_CASE_SUMMARY_LINK,
  RESET_CHAT_NOTIFICATIONS,
  RESET_GET_EXPENSE_DETAIL,
  RESET_SEND_PAIN_LOG_REMINDER,
  RESET_USER_ACTIVITY_NOTIFICATIONS,
  RESET_USER_FEED_NOTIFICATIONS,
  SEND_PAIN_LOG_REMINDER_FAIL,
  SEND_PAIN_LOG_REMINDER_REQUEST,
  SEND_PAIN_LOG_REMINDER_SUCCESS,
  USER_ACTIVITY_NOTIFICATIONS_FAIL,
  USER_ACTIVITY_NOTIFICATIONS_REQUEST,
  USER_ACTIVITY_NOTIFICATIONS_SUCCESS,
  USER_FEED_NOTIFICATIONS_FAIL,
  USER_FEED_NOTIFICATIONS_REQUEST,
  USER_FEED_NOTIFICATIONS_SUCCESS,
  WEB_NOTIFICATIONS_FAIL,
  WEB_NOTIFICATIONS_REQUEST,
  WEB_NOTIFICATIONS_SUCCESS,
} from "../../assets/constants/appConstants";
import { BASE_URL } from "..";
import Cookies from "js-cookie";

// ================== DISABLE ACCESS APP ACTIONS  =========================
export const disableAppAccessFunc = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: DISABLE_APP_ACCESS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/revoke-client-access`,
        { client_id: clientId },
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: DISABLE_APP_ACCESS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: DISABLE_APP_ACCESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ================== ENABLE ACCESS APP ACTIONS  =========================
export const enableAppAccessFunc = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: ENABLE_APP_ACCESS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/enable-client-access`,
        { client_id: clientId },
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ENABLE_APP_ACCESS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ENABLE_APP_ACCESS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET USER FEEDS NOTIFICATIONS ACTIONS  ========================
export const userFeedNotificationsFunc =
  (page, pageSize) => async (dispatch) => {
    try {
      dispatch({ type: USER_FEED_NOTIFICATIONS_REQUEST });

      const token = Cookies.get("token");
      const agencyId = Cookies.get("agencyId");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/log/get-user-feed-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: USER_FEED_NOTIFICATIONS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_FEED_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ============= GET ALL NOTIFICATIONS ACTIONS  ========================
export const allNotificationsFunc = (page, pageSize) => async (dispatch) => {
  try {
    dispatch({ type: ALL_NOTIFICATIONS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agencyId");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/log/get-notifications-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ALL_NOTIFICATIONS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ALL_NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET CHAT NOTIFICATIONS ACTIONS  ========================
export const chatNotificationsFunc = (page, pageSize) => async (dispatch) => {
  try {
    dispatch({ type: CHAT_NOTIFICATIONS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agencyId");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/log/get-chat-activity-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: CHAT_NOTIFICATIONS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: CHAT_NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET ALERT NOTIFICATIONS ACTIONS  ========================
export const alertNotificationsFunc = (page, pageSize) => async (dispatch) => {
  try {
    dispatch({ type: ALERT_NOTIFICATIONS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agencyId");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/log/get-alerts-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: ALERT_NOTIFICATIONS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: ALERT_NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET USER ACTIVITY NOTIFICATIONS ACTIONS  ========================
export const userActivityNotificationsFunc =
  (page, pageSize) => async (dispatch) => {
    try {
      dispatch({ type: USER_ACTIVITY_NOTIFICATIONS_REQUEST });

      const token = Cookies.get("token");
      const agencyId = Cookies.get("agencyId");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/log/get-user-activity-by-agency?agency_id=${agencyId}&page=${page}&pageSize=${pageSize}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: USER_ACTIVITY_NOTIFICATIONS_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: USER_ACTIVITY_NOTIFICATIONS_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

// ============= MARKED AS READ NOTIFICATIONS ACTIONS  ========================
export const markNotificationsFunc = () => async (dispatch) => {
  try {
    dispatch({ type: MARK_NOTIFICATIONS_REQUEST });

    const token = Cookies.get("token");
    const agencyId = Cookies.get("agencyId");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/mark-notifications-as-read`,
        {},
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: MARK_NOTIFICATIONS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: MARK_NOTIFICATIONS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET ALL BODY PARTS ACTIONS  ========================
export const getAllBodyPartsFunc = (clientId, caseId) => async (dispatch) => {
  try {
    dispatch({ type: GET_BODY_PARTS_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/pain-entry/get-body-parts?client_id=${clientId}&case_id=${caseId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_BODY_PARTS_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_BODY_PARTS_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

// ============= GET CASE SUMMARY PDF LINK  ACTIONS  ========================
export const getCaseSummaryLinkFunc =
  (
    clientId,
    caseId,
    startDate,
    endDate,
    bodyParts,
    checkedPainLogs,
    checkedEventLogs,
    documents
  ) =>
  async (dispatch) => {
    try {
      dispatch({ type: GET_CASE_SUMMARY_LINK_REQUEST });

      const token = Cookies.get("token");

      if (token) {
        const config = {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const response = await axios.get(
          `${BASE_URL}/case/generate-report?client_id=${clientId}
          &case_id=${caseId}&start_date=${startDate}&end_date=${endDate}&body_parts=${bodyParts}
          &include_pain_logs=${checkedPainLogs}&include_events=${checkedEventLogs}&documents=${documents}`,
          config
        );

        if (!response?.data?.ok) {
          throw new Error(response?.data?.message);
        }

        dispatch({
          type: GET_CASE_SUMMARY_LINK_SUCCESS,
          payload: response?.data,
        });
      }
    } catch (error) {
      dispatch({
        type: GET_CASE_SUMMARY_LINK_FAIL,
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
    }
  };

export const resetAllBodyParts = () => (dispatch) =>
  dispatch({
    type: RESET_BODY_PARTS,
  });

export const resetCaseSummaryLink = () => async (dispatch) => {
  dispatch({ type: RESET_CASE_SUMMARY_LINK });
};

export const resetUserFeedNotifications = () => ({
  type: RESET_USER_FEED_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetAllNotifications = () => ({
  type: RESET_ALL_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetChatNotifications = () => ({
  type: RESET_CHAT_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetAlertNotifications = () => ({
  type: RESET_ALERT_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetUserActivityNotifications = () => ({
  type: RESET_USER_ACTIVITY_NOTIFICATIONS,
  payload: { ok: false },
});

export const resetAppAccessState = () => (dispatch) =>
  dispatch({
    type: RESET_APP_ACCESS_STATE,
  });

// ================== SEND MISSED  PAIN LOG REMINDER ACTIONS  =========================
export const sendPainLogReminderFunc = (painObj) => async (dispatch) => {
  try {
    dispatch({ type: SEND_PAIN_LOG_REMINDER_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/pain-entry/send-missed-log-reminder`,
        painObj,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: SEND_PAIN_LOG_REMINDER_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: SEND_PAIN_LOG_REMINDER_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetSendPainLogReminder = () => (dispatch) =>
  dispatch({
    type: RESET_SEND_PAIN_LOG_REMINDER,
  });

// ============= GET EXPENSES DETAIL ACTIONS  ========================
export const getExpensesDetailFunc = (caseId) => async (dispatch) => {
  try {
    dispatch({ type: GET_EXPENSE_DETAIL_REQUEST });

    const token = Cookies.get("token");

    if (token) {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.get(
        `${BASE_URL}/expense/get-by-case?case_id=${caseId}`,
        config
      );

      if (!response?.data?.ok) {
        throw new Error(response?.data?.message);
      }

      dispatch({
        type: GET_EXPENSE_DETAIL_SUCCESS,
        payload: response?.data,
      });
    }
  } catch (error) {
    dispatch({
      type: GET_EXPENSE_DETAIL_FAIL,
      payload:
        error.response && error.response.data.message
          ? error.response.data.message
          : error.message,
    });
  }
};

export const resetGetExpenseDetail = () => (dispatch) =>
  dispatch({
    type: RESET_GET_EXPENSE_DETAIL,
  });
