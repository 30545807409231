import {
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  Typography,
} from "@mui/material";

import ProfilePicture from "../../common/ProfilePicture";
import CompanyLogo from "../../assets/images/userProfile.png";
import { useNavigate } from "react-router-dom";

const RiskCasesCard = ({
  caseTitle,
  caseStatus,
  caseUpdate,
  profilePhoto,
  clientName,
  caseId,
}) => {
  const navigate = useNavigate();

  return (
    <Stack
      padding="16px"
      borderRadius="8px"
      bgcolor="rgba(211, 47, 47, 0.10)"
      display="flex"
      width="100%"
      gap="8px"
      sx={{ cursor: "pointer" }}
      onClick={() => navigate(`/case/${caseId}?tab=overview`)}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap="8px"
      >
        <Typography variant="body1">{caseTitle}</Typography>

        <Chip
          label={caseStatus}
          size="small"
          sx={{
            background: "rgba(33, 150, 243, 0.15)",
            color: "#2196F3",
            textTransform: "capitalize",
          }}
        />
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        gap="8px"
      >
        <Typography variant="body1" color="secondary" fontSize="14px">
          Last Updated on - {caseUpdate}
        </Typography>
        <Box display="flex" gap="8px" alignItems="center">
          <ProfilePicture
            width="20px"
            height="20px"
            src={profilePhoto}
            alt={clientName}
          />
          <Typography variant="body1">{clientName}</Typography>
        </Box>
      </Box>
    </Stack>
  );
};

export default RiskCasesCard;
