import React from "react";
import {
  Box,
  DialogTitle,
  IconButton,
  Stack,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

// headerTitle - New Case

const DialogTitleHeader = ({
  headerTitle,
  activeStep,
  steps,
  isStepSkipped,
  closeFormDialogHandler,
}) => {
  return (
    <DialogTitle
      sx={{
        m: 0,
        p: 4,
        background:
          "radial-gradient(85.47% 105.71% at 15.61% 87.28%, rgba(128, 12, 243, 0.09) 0%, rgba(12, 128, 243, 0.09) 100%)",
      }}
    >
      <Stack gap="24px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography fontSize="24px" variant="h2" fontWeight="400">
            {headerTitle}
          </Typography>
          <IconButton aria-label="close" onClick={closeFormDialogHandler}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>

        {/* STEPPER HEADER */}
        <Box
          width="100%"
          bgcolor="rgba(244, 246, 248, 0.50)"
          p="16px"
          borderRadius="4px"
        >
          <Stepper activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const labelProps = {};

              if (isStepSkipped(index)) {
                stepProps.completed = false;
              }
              return (
                <Step key={label} {...stepProps}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
      </Stack>
    </DialogTitle>
  );
};

export default DialogTitleHeader;
