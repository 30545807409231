import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const AlertDialog = ({
  submitID,
  data,
  openModal,
  closeModal,
  formSubmitFunction,
  submitText = "Yes",
  maxWidth = "xs",
  children,
  padding = "8px",
  isLoading = false,
  moveFileDialog = false,
  caseSummary = false,
  color = "primary",
  cancelColor = "#000",
  disabled = false,
  contentPadding,
  titlePadding,
  titlePaddingTop = "16px",
  contentPaddingTop = "16px !important",
  actionsPaddingTop
}) => {
  //   const handleClickOpen = () => {
  //     setOpen(true);
  //   };

  //   const handleClose = () => {
  //     setOpen(false);
  //   };

  return (
    <Dialog
      open={openModal}
      maxWidth={maxWidth}
      fullWidth={true}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: `${maxWidth} !important`,
        },
      }}
    >
      <DialogTitle
        sx={{
          padding: titlePadding && titlePadding,
          paddingBottom: moveFileDialog ? "32px" : "0 !important",
          paddingTop: titlePaddingTop,
        }}
        id="alert-dialog-title"
        fontSize="20px"
      >
        {caseSummary ? (
          <Stack gap="4px">
            <Typography variant="h6" fontSize="20px">
              {data?.title}
            </Typography>
            <Typography
              variant="body2"
              fontSize="1rem"
              color="rgba(0, 0, 0, 0.38)"
            >
              {data?.lightText}
            </Typography>
          </Stack>
        ) : (
          <>
            {data?.title}{" "}
            {data?.lightText && (
              <span style={{ fontWeight: "400" }}>"{data?.lightText}"</span>
            )}
          </>
        )}
      </DialogTitle>
      <DialogContent
        sx={{
          padding: contentPadding && contentPadding,
          paddingTop: contentPaddingTop,
        }}
      >
        {data?.subtitle ? (
          <DialogContentText id="alert-dialog-description">
            {data?.subtitle}
          </DialogContentText>
        ) : (
          children
        )}
      </DialogContent>
      <DialogActions sx={{ padding, paddingTop:actionsPaddingTop }}>
        <Button sx={{ color: cancelColor }} onClick={closeModal}>
          Cancel
        </Button>
        <LoadingButton
          id={submitID}
          disabled={disabled}
          loading={isLoading}
          variant="contained"
          onClick={formSubmitFunction}
          color={color}
        >
          {submitText}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
