import {
  CANCEL_DOCUMENT_FAIL,
  CANCEL_DOCUMENT_REQUEST,
  CANCEL_DOCUMENT_SUCCESS,
  CREATE_EMPTY_FOLDER_FAIL,
  CREATE_EMPTY_FOLDER_REQUEST,
  CREATE_EMPTY_FOLDER_SUCCESS,
  DELETE_FILE_FAIL,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  DELETE_FOLDER_FAIL,
  DELETE_FOLDER_REQUEST,
  DELETE_FOLDER_SUCCESS,
  GET_CLIENT_CASES_DOCS_FAIL,
  GET_CLIENT_CASES_DOCS_REQUEST,
  GET_CLIENT_CASES_DOCS_SUCCESS,
  INNER_FOLDER_VIEW_FAIL,
  INNER_FOLDER_VIEW_REQUEST,
  INNER_FOLDER_VIEW_SUCCESS,
  MOVE_FILE_TO_FOLDER_FAIL,
  MOVE_FILE_TO_FOLDER_REQUEST,
  MOVE_FILE_TO_FOLDER_SUCCESS,
  RENAME_FILE_FAIL,
  RENAME_FILE_REQUEST,
  RENAME_FILE_SUCCESS,
  RENAME_FOLDER_FAIL,
  RENAME_FOLDER_REQUEST,
  RENAME_FOLDER_SUCCESS,
  RESET_CANCEL_DOCUMENT,
  RESET_CREATE_EMPTY_FOLDER,
  RESET_DELETE_FILE,
  RESET_DELETE_FOLDER,
  RESET_GET_CLIENT_CASES_DOCS,
  RESET_INNER_FOLDER_VIEW,
  RESET_MOVE_FILE_TO_FOLDER,
  RESET_RENAME_FILE,
  RESET_RENAME_FOLDER,
  RESET_SEND_DOC_REMINDER,
  SEND_DOC_REMINDER_FAIL,
  SEND_DOC_REMINDER_REQUEST,
  SEND_DOC_REMINDER_SUCCESS,
} from "../../assets/constants/documents";

//================   CREATE EMPTY FOLDER REDUCER ================
export const createEmptyFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_EMPTY_FOLDER_REQUEST:
      return { loading: true };
    case CREATE_EMPTY_FOLDER_SUCCESS:
      return { loading: false, emptyFolder: action.payload };
    case CREATE_EMPTY_FOLDER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CREATE_EMPTY_FOLDER:
      return {};
    default:
      return state;
  }
};

//================   INNER FOLDER VIEW REDUCER ================
export const innerFolderViewReducer = (state = {}, action) => {
  switch (action.type) {
    case INNER_FOLDER_VIEW_REQUEST:
      return { loading: true };
    case INNER_FOLDER_VIEW_SUCCESS:
      return { loading: false, folderContents: action.payload };
    case INNER_FOLDER_VIEW_FAIL:
      return { loading: false, error: action.payload };
    case RESET_INNER_FOLDER_VIEW:
      return {};
    default:
      return state;
  }
};

//================   RENAME FOLDER REDUCER ================
export const renameFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case RENAME_FOLDER_REQUEST:
      return { loading: true };
    case RENAME_FOLDER_SUCCESS:
      return { loading: false, renameFolderName: action.payload };
    case RENAME_FOLDER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_RENAME_FOLDER:
      return {};
    default:
      return state;
  }
};

//================   RENAME FILE REDUCER ================
export const renameFileReducer = (state = {}, action) => {
  switch (action.type) {
    case RENAME_FILE_REQUEST:
      return { loading: true };
    case RENAME_FILE_SUCCESS:
      return { loading: false, renameFileName: action.payload };
    case RENAME_FILE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_RENAME_FILE:
      return {};
    default:
      return state;
  }
};

//================   MOVE FILE TO FOLDER REDUCER ================
export const moveFileToFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case MOVE_FILE_TO_FOLDER_REQUEST:
      return { loading: true };
    case MOVE_FILE_TO_FOLDER_SUCCESS:
      return { loading: false, moveFileToFolders: action.payload };
    case MOVE_FILE_TO_FOLDER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_MOVE_FILE_TO_FOLDER:
      return {};
    default:
      return state;
  }
};

//================   DELETE FILE REDUCER ================
export const deleteFileReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FILE_REQUEST:
      return { loading: true };
    case DELETE_FILE_SUCCESS:
      return { loading: false, deletedFile: action.payload };
    case DELETE_FILE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_FILE:
      return {};
    default:
      return state;
  }
};

//================   DELETE FOLDER REDUCER ================
export const deleteFolderReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_FOLDER_REQUEST:
      return { loading: true };
    case DELETE_FOLDER_SUCCESS:
      return { loading: false, deletedFolder: action.payload };
    case DELETE_FOLDER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_FOLDER:
      return {};
    default:
      return state;
  }
};

//================   GET CLIENT AND CASES DOCUMENTS REDUCER ================
export const getClientCasesDocsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT_CASES_DOCS_REQUEST:
      return { loading: true };
    case GET_CLIENT_CASES_DOCS_SUCCESS:
      return { loading: false, clientCasesDocuments: action.payload };
    case GET_CLIENT_CASES_DOCS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_CLIENT_CASES_DOCS:
      return {};
    default:
      return state;
  }
};

//================  SEND DOC REMINDER REDUCER =action===============
export const sendDocReminderReducer = (state = {}, action) => {
  switch (action.type) {
    case SEND_DOC_REMINDER_REQUEST:
      return { loading: true };
    case SEND_DOC_REMINDER_SUCCESS:
      return { loading: false, sentDocReminder: action.payload };
    case SEND_DOC_REMINDER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_SEND_DOC_REMINDER:
      return {};
    default:
      return state;
  }
};

//================  CANCEL DOCUMENT REDUCER ================
export const cancelDocumentReducer = (state = {}, action) => {
  switch (action.type) {
    case CANCEL_DOCUMENT_REQUEST:
      return { loading: true };
    case CANCEL_DOCUMENT_SUCCESS:
      return { loading: false, cancelledDocument: action.payload };
    case CANCEL_DOCUMENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CANCEL_DOCUMENT:
      return {};
    default:
      return state;
  }
};
