import React from "react";

import clsx from "clsx";
import { makeStyles, styled } from "@mui/styles";
import { TextField, Typography } from "@mui/material";

import Style from "./style";

const Styles = makeStyles((theme) => Style(theme));
// const CssTextField = styled(TextField)({
//   "& label.Mui-focused": {
//     color: "#0000008F",
//   },
//   "& .MuiInput-underline:after": {
//     borderBottomColor: "#0000008F",
//   },
//   "& .MuiOutlinedInput-root": {
//     "& fieldset": {
//       borderColor: "#0000008F",
//     },
//     "&:hover fieldset": {
//       borderColor: "#0000008F",
//     },
//     "&.Mui-focused fieldset": {
//       borderColor: "#0000008F",
//     },
//   },
// });

export default function index({
  id,
  name,
  type,
  label,
  value,
  disabled,
  margin,
  height,
  variant = "outlined",
  maxRows,
  onChange,
  multiline,
  tableInput,
  helperText,
  placeholder,
  endAdornment,
  errorContent,
  inputRef,
  endProps,
  startProps,
  startAdornment,
  autoComplete = "true",
  onEnter,
  size = "normal",
  helperTextContent,
  fullWidth,
  width = "auto",
  required,
  readOnly,
  onBlur,
}) {
  const classes = Styles();
  return (
    // <div className="d-flex flex-column control-width"></div>
    <TextField
      id={id}
      autoComplete={`${autoComplete}`}
      name={name}
      fullWidth={fullWidth}
      type={type}
      onBlur={onBlur}
      {...startProps}
      {...endProps}
      label={label}
      value={value}
      required={required ? true : false}
      disabled={disabled}
      onKeyDown={onEnter}
      margin={margin}
      variant={variant}
      rows={maxRows}
      multiline={multiline}
      size={size}
      placeholder={placeholder}
      error={errorContent?.length > 0}
      helperText={
        helperText ? helperText : helperTextContent ? helperTextContent : null
      }
      InputProps={{
        // disableUnderline: true,
        endAdornment: endAdornment,
        startAdornment: startAdornment,
        readOnly: readOnly && true,
      }}
      inputRef={inputRef}
      sx={{
        "& fieldset": {
          border: helperText && "1px solid #ff3636 !important",
        },
        width: `${width}`,
      }}
      className={clsx(
        classes.style__main,
        height && classes.custom__height,
        tableInput && classes.table__input,
        multiline && classes.multiline__input
      )}
      onChange={onChange}
    />
  );
}
