import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Typography,
  Grid,
  Box,
  TextField,
  FormGroup,
  FormControlLabel,
  Switch,
  FormControl,
  InputLabel,
  Select,
  OutlinedInput,
  MenuItem,
  InputAdornment,
  Autocomplete,
} from "@mui/material";

import Style from "./style";
import Button from "../../../../common/Button";
import Textfield from "../../../../common/Textfield";
import CardUI from "../../../../common/Card";
import AlertMessage from "../../../../common/AlertMessage";
import Cookies from "js-cookie";
import {
  clearContact,
  formatPhoneNumber,
  validateEmail,
} from "../../../../utils/helpers";
import {
  updateAgencyFunc,
  getAgencyDetailFunc,
} from "../../../../actions/Agency";
import { statesWithCities } from "../../../../utils/helpers/states_with_cities";
import { segmentTrack } from "../../../../utils/helpers/tracking_utils";
import SkeletonGrid from "../../../../components/SkeletonGrid";
import GeneralSkeleton from "../../../../components/GeneralSkeleton";
import RectangleSkeletons from "../../../../components/RectangleSkeletons";

const states = Object.keys(statesWithCities);

const Styles = makeStyles((theme) => Style(theme));

export default function General() {
  const classes = Styles();

  // var userInfo = localStorage.getItem("userInfo");

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  const agentToken = Cookies.get("token");
  const cookieAgentId = Cookies.get("agentId");
  const cookieAgencyId = Cookies.get("agencyId");

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    //   setAgencyID(userInfo.agency_id);
    //   setAgentID(userInfo.agent_id);
    // }

    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const [loading, setLoading] = useState(false);
  const [agencyDetailData, setAgencyDetailData] = useState({});
  const [name, setName] = useState("");
  const [primaryEmail, setPrimaryEmail] = useState("");
  const [primaryContactNumber, setContactNumber] = useState("");
  const [website, setWebsite] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [region, setRegion] = useState(null); // state/region
  const [country, setCountry] = useState("United States of America");
  const [zipCode, setZipCode] = useState("");
  const [logoFilename, setLogoFilename] = useState("");
  const [logoURL, setLogoURL] = useState("");
  const [emailError, setEmailError] = useState(false);
  const [showReferees, setShowReferees] = useState(false);
  const [isTouched, setIsTouched] = useState(false);

  const [status, setStatus] = useState({ type: "", message: "" });
  const [permissionMessage, setPermissionMessage] = useState(null);
  const [alertOpen, setAlertOpen] = useState(false);

  const dispatch = useDispatch();
  const updateAgency = useSelector((state) => state.updateAgency);
  const {
    loading: loadingAgency,
    error: errorAgency,
    updatedAgency,
  } = updateAgency;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  // useEffect(() => {
  //   dispatch(getAgencyDetailFunc());
  // }, []);

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "settings_general_tab",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  const agentUserRef = useRef();

  useEffect(() => {
    agentUserRef.current = agentUser;
  }, [agentUser]);

  // console.log("LOADING AGENCY", loadingAgency);
  // console.log("UPDATE AGENCY", updatedAgency);
  // console.log("ERRORRRRRR", errorAgency);

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  // const formatContactNumber = (input) => {
  //   // Remove non-numeric characters from input
  //   const cleanedInput = input.replace(/\D/g, "");

  //   // Apply regex to format the contact number
  //   const formattedInput = cleanedInput.replace(
  //     /^(\d{1})(\d{3})(\d{3})(\d{4})$/,
  //     "+$1 ($2) $3-$4"
  //   );

  //   return formattedInput;
  // };

  const contactNumberHandler = (e) => {
    const input = e.target.value;
    const num = clearContact(input);
    const formattedNumber = formatPhoneNumber(num);
    setContactNumber(formattedNumber);
  };

  const websiteHandler = (e) => {
    setWebsite(e.target.value);
  };

  const getAgencyData = async () => {
    try {
      setLoading(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agent/get-agency?agency_id=${agencyID}`,
        config
      );

      setAgencyDetailData(data?.agency);

      const {
        name,
        primary_email,
        primary_contact_number,
        address,
        website,
        city,
        country,
        region,
        zipcode,
        logo_filename,
        logo_url,
        show_referees,
      } = data?.agency;

      setName(name ? name : "");
      setPrimaryEmail(primary_email ? primary_email : "");
      setContactNumber(primary_contact_number ? primary_contact_number : "");
      setAddress(address ? address : "");
      setCity(city ? city : "");
      setRegion(region ? region : null);
      setWebsite(website ? website : "");
      // setCountry(country ? country : "");
      setCountry("United States of America");
      setZipCode(zipcode ? zipcode : "");
      setLogoFilename(logo_filename ? logo_filename : "");

      if (logoFilename) {
        setLogoURL(logo_url);
      }

      setShowReferees(show_referees ? show_referees : false);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const changeRefereeHandler = (e) => {
    const access =
      agentUserRef.current?.agent?.permissions?.update_lawfirm_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update law firm settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      const referreStatus = e.target.checked;
      setShowReferees(referreStatus);

      const agencyData = {
        agency_id: agencyID,
        agency_settings: {
          show_referees: referreStatus,
        },
      };

      dispatch(updateAgencyFunc(agencyData));

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "lawfirm_details_updated",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  useEffect(() => {
    if (!loadingAgency && updatedAgency?.ok) {
      setAlertOpen(true);
    }
  }, [updatedAgency]);

  const emailChangeHandler = (e) => {
    const emailInputValue = e.target.value;
    setPrimaryEmail(emailInputValue);

    const emailIsValid = validateEmail(emailInputValue);

    setEmailError(!emailIsValid);
  };

  const handleZipCodeChange = (e) => {
    // Only update if the input is a number or empty (to allow deletion)
    if (/^\d*$/g.test(e.target.value)) {
      setZipCode(e.target.value);
    }
  };

  const emailBlurHandler = () => {
    if (!primaryEmail) {
      setEmailError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const access =
      agentUserRef.current?.agent?.permissions?.update_lawfirm_settings;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update law firm settings!"
      );
      setAlertOpen(true);
      return;
    } else {
      if (primaryEmail && !validateEmail(primaryEmail)) {
        return;
      }

      setStatus({
        type: "",
        message: "",
      });

      setLoading(true);

      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        const agencyData = {
          agency_id: agencyID,
          agency_settings: {
            name,
            primary_email: primaryEmail,
            primary_contact_number: primaryContactNumber,
            website,
            address,
            city,
            country,
            region: region,
            zipcode: zipCode,
            logo_filename: logoFilename,
          },
        };

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agent/update-agency`,
          agencyData,
          config
        );

        setStatus({
          type: "success",
          message: "Your changes have been saved successfully",
        });
        setLoading(false);
        handleAlertClick();

        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "lawfirm_details_updated",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      } catch (error) {
        setStatus({
          type: "error",
          message:
            error.data && error.data.message
              ? error.data.message
              : error.message,
        });
        setLoading(false);
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (token) {
      try {
        getAgencyData();
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  }, [token]);

  // RESET CHANGES HANDLER
  const resetChangesHandler = (e) => {
    e.preventDefault();

    // if (agencyDetailData?.primary_email) {
    //   const emailIsValid = validateEmail(agencyDetailData?.primary_email);

    //   if (!emailIsValid) {
    //     setEmailError(true);
    //     return;
    //   }

    //   setEmailError(false);
    // }

    setName(agencyDetailData?.name ? agencyDetailData?.name : "");
    setPrimaryEmail(
      agencyDetailData?.primary_email ? agencyDetailData?.primary_email : ""
    );
    setContactNumber(
      agencyDetailData?.primary_contact_number
        ? agencyDetailData?.primary_contact_number
        : ""
    );
    setAddress(agencyDetailData?.address ? agencyDetailData?.address : "");
    setCity(agencyDetailData?.city ? agencyDetailData?.city : "");
    setRegion(agencyDetailData?.region ? agencyDetailData?.region : null);
    // setCountry(country ? country : "");
    setCountry("United States of America");
    setZipCode(agencyDetailData?.zipcode ? agencyDetailData?.zipcode : "");
  };

  // console.log("REFEREESS", showReferees);

  return (
    <>
      {!loading && status?.type === "success" && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loading && permissionMessage && (
        <AlertMessage
          message={permissionMessage}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingAgency && errorAgency && (
        <AlertMessage
          message={errorAgency}
          severity="error"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      {!loadingAgency && updatedAgency?.ok && (
        <AlertMessage
          message="Your changes have been saved successfully"
          severity="success"
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      <Grid
        container
        spacing={1}
        className={classes.general__settings__main}
        width="100%"
      >
        <Grid
          item
          columnSpacing={1}
          xs={12}
          // md={12}
          className={classes.grid_column}
        >
          <Box display="flex" flexDirection="column" gap="32px">
            {/* AGENCY DETAILS */}
            <CardUI maxWidth="741px" dashboard="true" padding="32px">
              <Grid item>
                <Stack component="form" direction="column" gap="24px">
                  <Typography
                    variant="boxHeading"
                    textTransform="uppercase"
                    color="secondary"
                  >
                    Law Firm Details
                  </Typography>

                  {loading ? (
                    <GeneralSkeleton />
                  ) : (
                    <>
                      <Box display="flex" gap="24px">
                        <Textfield
                          placeholder="Enter Name"
                          label="Law Firm Name"
                          variant="outlined"
                          name="firmName"
                          width="100%"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          // error={isTouched && !!error}
                          // helperText={error?.message}
                          // margin="dense"
                        />
                        <Textfield
                          placeholder="contact@example.com"
                          label="Email Address"
                          variant="outlined"
                          name="email"
                          width="100%"
                          value={primaryEmail}
                          onChange={emailChangeHandler}
                          onBlur={emailBlurHandler}
                          // margin="dense"
                          // error={isTouched && !!error}
                          errorContent={emailError && "Enter valid email"}
                          helperText={emailError && "Enter valid email"}
                        />
                      </Box>
                      <Box display="flex" gap="24px">
                        <Textfield
                          placeholder="+1 7776665555"
                          label="Contact Number"
                          variant="outlined"
                          name="contactNumber"
                          width="100%"
                          value={primaryContactNumber}
                          onChange={contactNumberHandler}
                          // error={isTouched && !!error}
                          // helperText={error?.message}
                          // margin="dense"
                        />
                        <Textfield
                          placeholder="www.acme.com"
                          label="Website"
                          variant="outlined"
                          name="contactNumber"
                          width="100%"
                          value={website}
                          onChange={websiteHandler}
                          // error={isTouched && !!error}
                          // helperText={error?.message}
                          // margin="dense"
                        />
                      </Box>
                      <Box>
                        <Textfield
                          placeholder="90210 Broadway Blvd"
                          label="Address"
                          variant="outlined"
                          name="address"
                          fullWidth
                          width="100%"
                          value={address}
                          onChange={(e) => setAddress(e.target.value)}
                          // margin="dense"
                          // error={isTouched && !!error}
                          // helperText={error?.message}
                        />
                      </Box>
                      <Box display="flex" gap="24px">
                        {/* <TextField
                        placeholder="United States of America"
                        margin="dense"
                        label="Country"
                        variant="outlined"
                        name="country"
                        // error={isTouched && !!error}
                        // helperText={error?.message}
                        value="United States of America"
                        fullWidth
                        InputProps={{
                          readOnly: true,
                          style: {
                            backgroundColor: "transparent",
                            border: "none",
                            color: "black",
                            fontSize: "1em",
                          },
                        }}
                        // onChange={(e) => setCountry(e.target.value)}
                      /> */}

                        <Textfield
                          placeholder="San Mateo"
                          label="City"
                          variant="outlined"
                          name="city"
                          fullWidth
                          width="100%"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                          // margin="dense"
                          // error={isTouched && !!error}
                          // helperText={error?.message}
                        />

                        <Autocomplete
                          options={states}
                          fullWidth
                          value={region}
                          onChange={(event, newValue) => setRegion(newValue)}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="State/Region"
                              margin="dense"
                              variant="outlined"
                              fullWidth
                            />
                          )}
                          sx={{ marginTop: -1 }}
                        />
                      </Box>
                      <Box
                        display="flex"
                        gap="24px"
                        width="48%"
                        alignItems="center"
                      >
                        <Textfield
                          placeholder="91000"
                          label="Zip Code"
                          variant="outlined"
                          name="zipcode"
                          fullWidth
                          width="100%"
                          value={zipCode}
                          onChange={handleZipCodeChange}
                          // margin="dense"
                          // error={isTouched && !!error}
                          // helperText={error?.message}
                        />
                      </Box>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        gap="16px"
                      >
                        <Button
                          title="Reset Changes"
                          color="primary"
                          onClick={resetChangesHandler}
                          name="reset-changes"
                          fontSize="14px"
                        />

                        <Button
                          title="Save Changes"
                          disabled={!name}
                          color="primary"
                          variant="contained"
                          onClick={handleSubmit}
                          // height="40px"
                          // width="140px"
                        />
                      </Box>
                    </>
                  )}
                </Stack>
              </Grid>
            </CardUI>

            {/*  CUSTOMIZED NOTIFICATIONS */}
            {/* <CardUI maxWidth="741px" dashboard="true">
                <Grid item>
                  <Stack component="form" direction="column" gap="24px">
                    <Typography
                      variant="boxHeading"
                      textTransform="uppercase"
                      color="secondary"
                    >
                      Customize Notifications
                    </Typography>

                    <Stack>
                      <FormControl component="fieldset">
                        <FormGroup
                          aria-label="position"
                          row
                          className={classes.form__radio__group}
                        >
                          <FormControlLabel
                            value="start"
                            control={<Switch color="primary" defaultChecked />}
                            label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
                            labelPlacement="start"
                          />
                          <FormControlLabel
                            value="start"
                            control={<Switch color="primary" />}
                            label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
                            labelPlacement="start"
                          />{" "}
                          <FormControlLabel
                            value="start"
                            control={<Switch color="primary" defaultChecked />}
                            label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
                            labelPlacement="start"
                          />{" "}
                          <FormControlLabel
                            value="start"
                            control={<Switch color="primary" />}
                            label="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod"
                            labelPlacement="start"
                          />
                        </FormGroup>
                      </FormControl>
                    </Stack>
                  </Stack>
                </Grid>
              </CardUI> */}

            <CardUI maxWidth="741px" dashboard="true" padding="32px">
              <Typography
                variant="subtitle1"
                color="secondary"
                fontSize="16px"
                fontWeight="500"
                textTransform="uppercase"
                pb="24px"
              >
                Referrals
              </Typography>
              {loading ? (
                <RectangleSkeletons totalRows={1} height={36} />
              ) : (
                <FormGroup
                  aria-label="position"
                  row
                  className={classes.form__radio__group}
                >
                  <FormControlLabel
                    control={
                      <Switch
                        color="primary"
                        checked={showReferees}
                        name="referees"
                        onChange={changeRefereeHandler}
                      />
                    }
                    label="Show referee's contact details in referrals list "
                    labelPlacement="start"
                  />
                </FormGroup>
              )}
            </CardUI>
          </Box>
        </Grid>

        {/* <Grid item xs={12} md={4} className={classes.grid_column}>
            <CardUI maxWidth="355px" dashboard="true">
              <Stack>
                <Typography py={1.5} variant="boxHeading" color="secondary">
                  Logo
                </Typography>
                <Stack p={1} className={classes.banner__withName}>
                  <Stack
                    position="relative"
                    alignItems="center"
                    className="main__container"
                    p={2.5}
                  >
                    <Logo />
                    <Stack
                      className="overlay"
                      direction="row"
                      alignItems="center"
                      justifyContent="center"
                      spacing={1}
                    >
                      <Stack
                        alignItems="center"
                        px={3}
                        width="50%"
                        sx={{ cursor: "pointer" }}
                      >
                        <IconButton
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                        >
                          <AddLogoIcon />
                        </IconButton>
                        <Typography
                          variant="h6"
                          color="primary.light"
                          sx={{ fontWeight: 400 }}
                        >
                          Upload New
                        </Typography>
                      </Stack>
                      <Divider
                        orientation="vertical"
                        flexItem
                        sx={{ backgroundColor: "primary.light" }}
                      />
                      <Stack
                        alignItems="center"
                        px={3}
                        width="50%"
                        sx={{ cursor: "pointer" }}
                      >
                        <IconButton
                          disableRipple
                          disableFocusRipple
                          disableTouchRipple
                        >
                          <DeleteIcon />
                        </IconButton>
                        <Typography
                          variant="h7"
                          color="primary.light"
                          sx={{ fontWeight: 400 }}
                        >
                          Delete
                        </Typography>
                      </Stack>
                    </Stack>
                  </Stack>
                </Stack>
                <Typography
                  py={0.5}
                  variant="h7"
                  sx={{ fontWeight: 400 }}
                  color="secondary.main"
                >
                  Ipsum
                </Typography>
              </Stack>
            </CardUI>
          </Grid> */}
      </Grid>
    </>
  );
}
