import React from "react";
import CardUI from "../../common/Card";
import { Box, Grid, Skeleton, Stack, Typography } from "@mui/material";
import AddCircle from "../../icons/AddCircleIcon";
import { makeStyles } from "@mui/styles";
import AddCircleIcon from "../../icons/AddCircleIcon";
import EditIcon from "../../icons/EditIcon";
import DeleteIcon from "../../icons/DeleteIcon";
import ClientIntakeCard from "../ClientIntakeCard";

const ClientIntakeForm = ({
  setOpenDrawer,
  deletePresetModalHandler,
  allPresets,
  editPresetHandler,
  setUpdatingPreset,
  loadingClientIntake,
}) => {
  // const pp = JSON.parse(allPresets[0]?.required_documents)?.length;
  // console.log("PPPP", pp);

  return (
    <CardUI dashboard="true" maxWidth="741px" padding="32px">
      <Grid item xs={12} md={8}>
        <Stack component="form" direction="column" gap="24px">
          <Stack gap="8px">
            <Typography
              fontSize="16px"
              variant="subtitle1"
              color="secondary"
              sx={{ textTransform: "uppercase" }}
            >
              Customize Client intake Form
            </Typography>
            <Typography
              variant="subtitle1"
              fontSize="16px"
              color="rgba(0, 0, 0, 0.38)"
            >
              Create different intake forms for clients having different case
              types.
            </Typography>
          </Stack>

          {loadingClientIntake ? (
            <Box display="flex" alignItems="center" gap="8px">
              <Skeleton variant="rectangular" width={210} height={180} />
              <Skeleton variant="rectangular" width={210} height={180} />
            </Box>
          ) : (
            <Grid container spacing="12px">
              <Grid item xs={12} md={4}>
                <Box
                  bgcolor="#FCFCFC"
                  borderRadius="4px"
                  border="1px solid #EBEBEB"
                  height="180px"
                  padding="24px"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    cursor: "pointer",
                    "&:hover": { border: "1px solid #0C80F3" },
                  }}
                  onClick={() => {
                    setOpenDrawer(true);
                    setUpdatingPreset(false);
                  }}
                >
                  <AddCircleIcon />
                </Box>
              </Grid>
              {allPresets?.map((preset, index) => (
                <React.Fragment key={index}>
                  <ClientIntakeCard
                    defaultCard={preset?.is_default}
                    questionTitle={
                      preset?.is_default ? "Default" : preset?.name
                    }
                    numOfQuestions={preset?.intake_questions?.length}
                    numOfDocuments={
                      JSON.parse(preset?.required_documents)?.length
                    }
                    preset_id={preset?.id}
                    deletePresetModalHandler={deletePresetModalHandler}
                    editPresetHandler={editPresetHandler}
                  />
                </React.Fragment>
              ))}
            </Grid>
          )}
        </Stack>
      </Grid>
    </CardUI>
  );
};

export default ClientIntakeForm;
