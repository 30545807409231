import React from "react";

const CloseIconBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M15.8307 5.34199L14.6557 4.16699L9.9974 8.82533L5.33906 4.16699L4.16406 5.34199L8.8224 10.0003L4.16406 14.6587L5.33906 15.8337L9.9974 11.1753L14.6557 15.8337L15.8307 14.6587L11.1724 10.0003L15.8307 5.34199Z"
        fill="#014361"
      />
    </svg>
  );
};

export default CloseIconBlue;
