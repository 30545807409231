import {
  //   CREATE_AGENCY_FAIL,
  //   CREATE_AGENCY_REQUEST,
  //   CREATE_AGENCY_SUCCESS,
  CREATE_WORKSPACE_FAIL,
  CREATE_WORKSPACE_REQUEST,
  CREATE_WORKSPACE_SUCCESS,
  GET_AGENCY_FAIL,
  GET_AGENCY_REQUEST,
  GET_AGENCY_SUCCESS,
  GET_ALL_CLIENTS_FAIL,
  GET_ALL_CLIENTS_REQUEST,
  GET_ALL_CLIENTS_SUCCESS,
  GET_ALL_CLIENTS_UNPAGINATED_FAIL,
  GET_ALL_CLIENTS_UNPAGINATED_REQUEST,
  GET_ALL_CLIENTS_UNPAGINATED_SUCCESS,
  RESET_ALL_CLIENTS,
  UPDATE_AGENCY_FAIL,
  UPDATE_AGENCY_REQUEST,
  UPDATE_AGENCY_SUCCESS,
} from "../../assets/constants/agencyConstants";
import {
  ADD_NEW_CLIENT_FAIL,
  ADD_NEW_CLIENT_REQUEST,
  ADD_NEW_CLIENT_SUCCESS,
  DELETE_CLIENT_FAIL,
  DELETE_CLIENT_REQUEST,
  DELETE_CLIENT_SUCCESS,
  GET_AGENT_LISTS_FAIL,
  GET_AGENT_LISTS_REQUEST,
  GET_AGENT_LISTS_SUCCESS,
  GET_AGENT_PROFILE_FAIL,
  GET_AGENT_PROFILE_REQUEST,
  GET_AGENT_PROFILE_SUCCESS,
  GET_CLIENT_DATA_FAIL,
  GET_CLIENT_DATA_REQUEST,
  GET_CLIENT_DATA_SUCCESS,
  RESET_UPDATE_AGENT_TEAM_MEMBERS,
  RESET_DELETE_CLIENT,
  UPDATE_AGENT_PROFILE_FAIL,
  UPDATE_AGENT_PROFILE_REQUEST,
  UPDATE_AGENT_PROFILE_SUCCESS,
  UPDATE_AGENT_TEAM_MEMBERS_FAIL,
  UPDATE_AGENT_TEAM_MEMBERS_REQUEST,
  UPDATE_AGENT_TEAM_MEMBERS_SUCCESS,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_REQUEST,
  UPDATE_CLIENT_SUCCESS,
  RESET_AGENT_LISTS,
} from "../../assets/constants/agentConstants";

// CREATE AGENT REDUCER
export const getAgentProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENT_PROFILE_REQUEST:
      return { loading: true };
    case GET_AGENT_PROFILE_SUCCESS:
      return { loading: false, agentUser: action.payload };
    case GET_AGENT_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// ================ UPDATE AGENT REDUCER  ====================
export const agentUpdateProfileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AGENT_PROFILE_REQUEST:
      return { loading: true };
    case UPDATE_AGENT_PROFILE_SUCCESS:
      return { loading: false, success: true, agentInfo: action.payload };
    case UPDATE_AGENT_PROFILE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   CREATE WORKSPACE REDUCER ================
export const createWorkspaceReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_WORKSPACE_REQUEST:
      return { loading: true };
    case CREATE_WORKSPACE_SUCCESS:
      return { loading: false, workspace: action.payload };
    case CREATE_WORKSPACE_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   GET ALL AGENTS LISTS REDUCER ================
export const getAgentsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENT_LISTS_REQUEST:
      return { loading: true };
    case GET_AGENT_LISTS_SUCCESS:
      return { loading: false, allAgents: action.payload };
    case GET_AGENT_LISTS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_AGENT_LISTS:
      return {};
    default:
      return state;
  }
};

//================  UPDATE ASSIGNED AGENT TEAM MEMBERS REDUCER ================
export const updateAgentTeamMembersReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_AGENT_TEAM_MEMBERS_REQUEST:
      return { loading: true };
    case UPDATE_AGENT_TEAM_MEMBERS_SUCCESS:
      return { loading: false, updatedAgentTeamMembers: action.payload };
    case UPDATE_AGENT_TEAM_MEMBERS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UPDATE_AGENT_TEAM_MEMBERS:
      return {};
    default:
      return state;
  }
};

//================  GET AGENCY REDUCER ================
export const getAgencyReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_AGENCY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_AGENCY_SUCCESS:
      return { ...state, loading: false, agencyDetail: action.payload };
    case GET_AGENCY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//================  UPDATE AGENCY REDUCER ================
export const updateAgencyReducer = (
  state = { loading: false, updatedAgency: {}, error: null },
  action
) => {
  switch (action.type) {
    case UPDATE_AGENCY_REQUEST:
      return {
        ...state,
        loading: true,
        updatedAgency: {},
      };
    case UPDATE_AGENCY_SUCCESS:
      return { ...state, loading: false, updatedAgency: action.payload };
    case UPDATE_AGENCY_FAIL:
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   GET ALL CLIENTS REDUCER ================
export const getAllClientsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CLIENTS_REQUEST:
      return { loading: true };
    case GET_ALL_CLIENTS_SUCCESS:
      return { loading: false, clients: action.payload };
    case GET_ALL_CLIENTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   GET ALL CLIENTS UNPAGINATED REDUCER ================
export const getAllClientsUnpaginatedReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_ALL_CLIENTS_UNPAGINATED_REQUEST:
      return { loading: true };
    case GET_ALL_CLIENTS_UNPAGINATED_SUCCESS:
      return { loading: false, clients: action.payload };
    case GET_ALL_CLIENTS_UNPAGINATED_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ALL_CLIENTS:
      return {};
    default:
      return state;
  }
};

//================   GET CLIENT DATA REDUCER ================
export const getClientDataReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT_DATA_REQUEST:
      return { loading: true };
    case GET_CLIENT_DATA_SUCCESS:
      return { loading: false, clientInfo: action.payload };
    case GET_CLIENT_DATA_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   UPDATE CLIENT REDUCER ================
export const updateClientReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_CLIENT_REQUEST:
      return { loading: true };
    case UPDATE_CLIENT_SUCCESS:
      return { loading: false, updatedClient: action.payload };
    case UPDATE_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   ADD NEW CLIENT REDUCER ================
export const addNewClientReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_NEW_CLIENT_REQUEST:
      return { loading: true };
    case ADD_NEW_CLIENT_SUCCESS:
      return { loading: false, newClient: action.payload };
    case ADD_NEW_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

//================   DELETE CLIENT DATA REDUCER ================
export const deleteClientReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_CLIENT_REQUEST:
      return { loading: true };
    case DELETE_CLIENT_SUCCESS:
      return { loading: false, deletedClient: action.payload };
    case DELETE_CLIENT_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_CLIENT:
      return {};
    default:
      return state;
  }
};
