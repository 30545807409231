import React from "react";

const WaitingClockIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
    >
      <path
        d="M28.9173 16.0002C28.9173 23.1302 23.1307 28.9168 16.0007 28.9168C8.87065 28.9168 3.08398 23.1302 3.08398 16.0002C3.08398 8.87016 8.87065 3.0835 16.0007 3.0835C23.1307 3.0835 28.9173 8.87016 28.9173 16.0002Z"
        stroke="#566875"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.7915 20.1073L16.7873 17.7177C16.0898 17.3044 15.5215 16.3098 15.5215 15.496V10.2002"
        stroke="#566875"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default WaitingClockIcon;
