const Style = (theme) => ({
  fields__space: {
    "& .control-width": {
      width: "48.7%",
      "& .react-tel-input": {
        // marginTop: ' !important',
        marginBottom: "3px !important",
      },
    },
  },
  form__radio__group: {
    width: "100%",
    "& label": {
      paddingBottom: ".5rem",
      width: "100%",
      display: "flex",
      margin: "0",
      justifyContent: "space-between",
    },
  },
  checkbox__radio: {
    width: "75px",
    textAlign: "center",
  },
  filter__width: {
    "& .field-width": {
      width: "20rem",
    },
    "& .dropdown-width": {
      width: "10rem",
    },
  },
  drawer__width: {
    width: "748px !important",
    [theme.breakpoints.down("md")]: {
      width: "400px !important",
      padding: "1rem !important",
    },
  },
  pos_relative: {
    display: "flex",
    alignItems: "flex-start",
    position: "relative",
  },
});
export default Style;
