import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.REACT_APP_INSIGHTS_INSTRUMENTATION_KEY,
    disableFetchTracking: false,
    enableAutoRouteTracking: true, // Track page views in a SPA application
    autoTrackPageVisitTime: true,
    enableDebug: true,
    enableCorsCorrelation: true,
  }
});

appInsights.loadAppInsights();

// Setting cloud_RoleName to "frontend"
appInsights.addTelemetryInitializer((envelope) => {
  envelope.tags["ai.cloud.role"] = "frontend";
  // Additional custom telemetry initializers can be added here
});

// Enable console log tracking
appInsights.trackTrace({message: "Console log tracking enabled for frontend"});

export { appInsights };
