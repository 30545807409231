import { Box, Tooltip } from "@mui/material";
import React from "react";
import { InfoCircle } from "../../assets/constants";

const TeamInfoIcon = ({ title, maxWidth }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: "-8px",
        right: 0,
      }}
    >
      <Tooltip
        title={title}
        placement="top"
        arrow
        PopperProps={{
          sx: {
            marginBottom: "-7px !important",
            maxWidth: `${maxWidth} !important`,
          },
        }}
      >
        <InfoCircle />
      </Tooltip>
    </Box>
  );
};

export default TeamInfoIcon;
