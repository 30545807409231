import React, { useEffect } from "react";
import BgBox from "../../common/BgBox";
import CardUI from "../../common/Card";
import { Box, Button, Grid, Link, Typography } from "@mui/material";
import HeaderText from "../../common/HeaderText";
import ChckedImg from "../../assets/images/checked-image.png";
import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";

const ChangedPassword = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.state === null) {
      navigate("/signin");
    }
  }, []);

  if (location.state === null) {
    return;
  }

  return (
    <BgBox>
      <CardUI paddingTop="86px">
        <Grid container component="main">
          <Box
            xs={12}
            display="flex"
            gap="40px"
            flexDirection="column"
            width="100%"
          >
            <Box
              textAlign="center"
              display="flex"
              alignItems="center"
              flexDirection="column"
              gap="16px"
            >
              <img src={ChckedImg} alt="checked-icon" />
              <HeaderText
                variant="h3"
                fontSize={34}
                textContent="Password changed successfully"
                textAlign="center"
              />
              <Typography
                variant="body1"
                className="d-flex"
                alignItems="center"
                justifyContent="center"
                fontSize={16}
                width="75%"
                margin="0 auto"
                color="#566875"
              >
                You can login with your new password
              </Typography>
            </Box>

            <Button
              component={LinkTo}
              to="/signin"
              fullWidth
              type="submit"
              variant="contained"
              //   disabled={!email.length}
              sx={{ padding: "8px 22px", fontSize: 15 }}
            >
              Go to Home Page
            </Button>
            {/* <Button
          type="submit"
          fullWidth
          variant="contained"
          sx={{ padding: "8px 22px", fontSize: 15 }}
        >
          Reset Password
        </Button> */}

            {/* <Button
className="w-100"
fontSize="15px"
color="primary"

title="Sign In"
variant="contained"
width="100%"
height="48px"
// disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
// onClick={() => onSignIn()}
// loading={signInLoading}
loadingColor="primary"
/> */}
          </Box>
        </Grid>
      </CardUI>
    </BgBox>
  );
};

export default ChangedPassword;
