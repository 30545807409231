const Style = (theme) => ({
  banner__withName: {
    borderRadius: "15px",
    overflow: "hidden",
    height: "164px",
    width: "343px",
    "& .main__container": {
      height: "100%",
    },
  },

  // image_container: {
  //   // width: "50%",
  //   height: "100%",
  // },

  passportImage: {
    width: "100%", // Adjust the size as needed
    height: "100%",
    borderRadius: "8px",
  },

  bannerImage: {
    width: "100%", // Adjust the size as needed
    height: "100%",
    objectFit: "contain",
  },

  // banner_preview_container: {
  //   [theme.breakpoints.down("1070")]: {
  //     maxWidth: "100% !important",
  //     width: "100% !important",
  //   },
  // },
});
export default Style;
