import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Typography,
  Grid,
  FormControlLabel,
  FormGroup,
  Switch,
  FormControl,
  TextField,
  InputAdornment,
  Box,
  IconButton,
  Divider,
  Icon,
  Tabs,
  Tab,
} from "@mui/material";

import Style from "./style";
import BoxMain from "../../../common/Box";
import Button from "../../../common/Button";
import Dropdown from "../../../common/Dropdown";
import Textfield from "../../../common/Textfield";
import PhoneInput from "../../../common/PhoneInput";
import CardUI from "../../../common/Card";
import { AddLogoIcon, DeleteIcon } from "../../../assets/constants";
import { Input } from "@mui/base";
import axios from "axios";
import CircularProgressLoading from "../../../common/CircularProgress";
import AlertMessage from "../../../common/AlertMessage";
import Cookies from "js-cookie";
import { getAgencyDetailFunc } from "../../../actions/Agency";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";
import { Cropper } from "react-cropper";
import "cropperjs/dist/cropper.css";
import DialogOverlay from "../../../components/DialogOverlay";
import ProfileModal from "../../../components/ProfileModal";
import ZoominIcon from "../../../icons/ZoominIcon";
import ZoomoutIcon from "../../../icons/ZoomoutIcon";
import SkeletonGrid from "../../../components/SkeletonGrid";
import headshotImg from "../../../assets/images/headshotImg.png";
import { LoadingButton } from "@mui/lab";
import CreateBannerIcon from "../../../icons/CreateBannerIcon";
import UploadBannerIcon from "../../../icons/UploadBannerIcon";
import CreateBannerTemplate from "../../../components/CreateBannerTemplate";
import UploadBannerTemplate from "../../../components/UploadBannerTemplate";
import BannerPreviewTemplate from "../../../components/BannerPreviewTemplate";
import { formatSizeUnits } from "../../../utils/helpers";
import { SettingsSharp } from "@mui/icons-material";
import RectangleSkeletons from "../../../components/RectangleSkeletons";
import BrandingSkeleton from "../../../components/BrandingSkeleton";

const Styles = makeStyles((theme) => Style(theme));

const brandingTabs = [
  {
    icon: <CreateBannerIcon />,
    title: "Create Banner",
    value: "create_banner",
  },
  {
    icon: <UploadBannerIcon />,
    title: "Upload Banner",
    value: "upload_banner",
  },
];

const colorBoxes = [
  "#2F2F2F",
  "#795548",
  "#FF5722",
  "#FF9800",
  "#FFC107",
  "#FFEB3B",
  "#8BC34A",
  "#4CAF50",
  "#009688",
  "#03A9F4",
  "#2196F3",
  "#564FD5",
  "#9242DA",
  "#9C27B0",
  "#E91E63",
  "#F44336",
];

const name = "Heading";
const tagline = "Sub-heading 1";
const website = "Sub-heading 2";

export default function Branding({ changesMade, setChangesMade }) {
  const classes = Styles();
  const hiddenFileInputRef = useRef();
  const cropperRef = useRef(null);
  const [openImageModal, setOpenImageModal] = useState(false);

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  const [permissionMessage, setPermissionMessage] = useState(null);

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "branding",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  // useEffect(() => {
  //   dispatch(getAgencyDetailFunc());
  // }, []);

  // var userInfo = localStorage.getItem("userInfo");

  const closeImageModal = () => {
    setOpenImageModal(false);
  };

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    //   setAgencyID(userInfo.agency_id);
    //   setAgentID(userInfo.agent_id);
    // }

    const agentToken = Cookies.get("token");
    const cookieAgentId = Cookies.get("agentId");
    const cookieAgencyId = Cookies.get("agencyId");

    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const agentUserRef = useRef();

  useEffect(() => {
    agentUserRef.current = agentUser;
  }, [agentUser]);

  const [agencyData, setAgencyData] = useState({});
  const [agencyName, setAgencyName] = useState("");
  const [agencyTagline, setAgencyTagline] = useState("");
  const [agencyWebsite, setAgencyWebsite] = useState("");
  const [backgroundColor, setBackgroundColor] = useState("#3F8BE7");
  const [selectedFileError, setSelectedFileError] = useState("");
  const [uploading, setUploading] = useState(false);
  const [agencyLoading, setAgencyLoading] = useState(true);
  const [agencyUploading, setAgencyUploading] = useState(false);
  const [imageName, setImageName] = useState("");
  const [imageUrl, setImageUrl] = useState(headshotImg);
  const [bannerImageName, setBannerImageName] = useState("");
  const [bannerUrl, setBannerUrl] = useState(headshotImg);
  const [bannerSize, setBannerSize] = useState("");
  const [bannerName, setBannerName] = useState("");
  const [bannerSelection, setBannerSelection] = useState("");
  const [existingImageName, setExistingImageName] = useState("");
  const [status, setStatus] = useState({ type: "", message: "" });
  const [tabValue, setTabValue] = useState("create_banner");
  const [isTouched, setIsTouched] = useState(false);

  const [showMessage, setShowMessage] = useState(false);

  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const getAgencyData = async () => {
    try {
      setAgencyLoading(true);
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/agent/get-agency?agency_id=${agencyID}`,
        config
      );

      if (!data?.ok) {
        throw new Error("Something went wrong");
      }

      setAgencyData(data?.agency);
      setAgencyName(
        data?.agency?.banner_heading ? data?.agency?.banner_heading : ""
      );
      setAgencyWebsite(
        data?.agency?.banner_subheading2 ? data?.agency?.banner_subheading2 : ""
      );
      setAgencyTagline(
        data?.agency?.banner_subheading1 ? data?.agency?.banner_subheading1 : ""
      );
      setBackgroundColor(
        data?.agency?.brand_color ? data?.agency?.brand_color : "#3F8BE7"
      );
      setBannerSelection(
        data?.agency?.banner_selection ? data?.agency?.banner_selection : ""
      );

      if (data?.agency?.headshot_filename) {
        setImageName(data?.agency?.headshot_filename);
        setImageUrl(data?.agency?.headshot_url);
        setExistingImageName(data?.agency?.headshot_filename);
      } else {
        setExistingImageName(imageName);
        setImageUrl(headshotImg);
      }

      setBannerName(
        data?.agency?.banner_filename_raw
          ? data?.agency?.banner_filename_raw
          : ""
      );

      setBannerSize(
        data?.agency?.banner_file_size ? data?.agency?.banner_file_size : ""
      );

      if (data?.agency?.banner_filename) {
        setBannerImageName(data?.agency?.banner_filename);
        setBannerUrl(data?.agency?.banner_url);
      } else {
        setBannerImageName("");
        setBannerUrl(bannerUrl);
      }

      setAgencyLoading(false);
    } catch (error) {
      setAgencyLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (token) {
      try {
        getAgencyData();
      } catch (error) {
        setUploading(false);
        console.log(error);
      }
    }
  }, [token]);

  const changeBackgroundColorHandler = (color) => {
    setBackgroundColor(color);
    setChangesMade(true);
  };

  useEffect(() => {
    if (bannerSelection === "uploaded") {
      setTabValue("upload_banner");
    } else {
      setTabValue("create_banner");
    }
  }, [bannerSelection]);

  const uploadApi = async (fileData) => {
    try {
      setUploading(true);
      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/agent/upload-file`,
        fileData,
        config
      );

      // if (imageUrl && !res?.data?.ok) {
      //   console.log("HERE");
      //   setImageName(imageName);
      //   setImageUrl(imageUrl);
      // }

      if (!res?.data?.ok) {
        setExistingImageName("");
        setImageName("");
        setBannerImageName("");
        setImageUrl(headshotImg);
        setBannerUrl(headshotImg);
        setUploading(false);
        return;
      }

      if (tabValue === "create_banner") {
        setExistingImageName(res?.data?.filename);
        setImageName(res?.data?.filename);
        setImageUrl(res?.data?.file_url);
      } else {
        setBannerName(res?.data?.raw_filename);
        setBannerSize(res?.data?.file_size);
        setBannerImageName(res?.data?.filename);
        setBannerUrl(res?.data?.file_url);
      }

      setUploading(false);
      setChangesMade(true);
      // const agentData = {
      //   agent_id: agentID,
      //   agent_profile: {
      //     profile_picture_filename: res?.data?.filename,
      //   },
      // };

      // dispatch(updateAgentProfile(agentData));
      setOpenImageModal(false);
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "headshot_uploaded",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } catch (error) {
      console.log(error);
      // setSelectedFileError(
      //   error.response && error.response.data.message
      //     ? error.response.data.message
      //     : error.message
      // );
      setUploading(false);
    } finally {
      setSelectedFileError(null);
    }
  };

  const saveImageHandler = async () => {
    if (
      typeof cropperRef?.current?.cropper?.getCroppedCanvas() === "undefined"
    ) {
      return;
    }
    cropperRef?.current?.cropper?.getCroppedCanvas()?.toBlob(async (blob) => {
      const croppedImageFile = new File([blob], "croppedImage.jpg", {
        type: "image/png",
      });
      // setCroppedImage(croppedImageFile);

      const fileData = new FormData();
      fileData.append("file", croppedImageFile);
      fileData.append("file_type", "image/png");
      fileData.append("agency_id", agencyID);

      uploadApi(fileData);
    }, "image/png");
  };

  const onCrop = () => {
    const cropper = cropperRef?.current?.cropper;
  };

  const handleFileChange = async (event) => {
    const file = event?.target?.files[0];
    const fileType = file?.type;
    const bannerSize = formatSizeUnits(file?.size);
    const uploadedFileName = file?.name;

    if (file && file.size > 5 * 1024 * 1024) {
      setImageUrl(headshotImg);
      setBannerUrl(headshotImg);
      setSelectedFileError("File size cannot exceed more than 5MB");
      setOpen(true);
      setBannerSize("");
      return;
    }

    if (
      fileType !== "image/jpeg" &&
      fileType !== "image/png" &&
      fileType !== "image/jpg"
    ) {
      setSelectedFileError("Only png and jpg images are allowed!");
      setOpen(true);
      return;
    }

    if (tabValue === "create_banner") {
      setOpenImageModal(true);
      setImageUrl(URL.createObjectURL(file));
      // setImageUrl(URL.createObjectURL(file));
    } else {
      const fileData = new FormData();
      fileData.append("file", file);
      fileData.append("file_type", fileType);
      fileData.append("agency_id", agencyID);
      fileData.append("banner_filename_raw", uploadedFileName);
      fileData.append("banner_file_size", bannerSize);

      uploadApi(fileData);
    }

    // const fileData = new FormData();
    // fileData.append("file", file);
    // fileData.append("file_type", fileType);
    // fileData.append("agency_id", agencyID);

    // setUploading(true);
    // try {
    //   const config = {
    //     headers: {
    //       "Content-type": "multipart/form-data",
    //       Authorization: `Bearer ${token}`,
    //     },
    //   };

    //   const res = await axios.post(
    //     `${process.env.REACT_APP_BASE_URL}/agent/upload-file`,
    //     fileData,
    //     config
    //   );

    //   // if (imageUrl && !res?.data?.ok) {
    //   //   console.log("HERE");
    //   //   setImageName(imageName);
    //   //   setImageUrl(imageUrl);
    //   // }

    //   // console.log("RESSSS", res);

    //   if (!res?.data?.ok) {
    //     setExistingImageName("");
    //     setImageUrl(
    //       "https://icon-library.com/images/anonymous-avatar-icon/anonymous-avatar-icon-25.jpg"
    //     );
    //     setUploading(false);
    //     return;
    //   }

    //   setExistingImageName(res?.data?.filename);
    //   setImageName(res?.data?.filename);
    //   setImageUrl(res?.data?.file_url);
    //   setUploading(false);

    //   if (agentUser?.agent && agencyDetail?.agency) {
    //     segmentTrack({
    //       event: "headshot_uploaded",
    //       agent: agentUser?.agent,
    //       agency: agencyDetail?.agency,
    //     });
    //   }
    // } catch (error) {
    //   console.log(error);
    //   setSelectedFileError(
    //     error.response && error.response.data.message
    //       ? error.response.data.message
    //       : error.message
    //   );
    //   setUploading(false);
    // }
  };

  const deleteUploadedBannerHandler = () => {
    setBannerName("");
    setBannerImageName("");
    setBannerUrl(headshotImg);
    setChangesMade(true);
  };

  const handleFileClick = () => {
    hiddenFileInputRef.current.click();
  };

  const handleZoomIn = () => {
    const cropper = cropperRef.current.cropper;
    cropper.zoom(0.1);
  };

  const handleZoomOut = () => {
    const cropper = cropperRef.current.cropper;
    cropper.zoom(-0.1);
  };

  const deleteFileHandler = () => {
    setImageName("");
    setImageUrl(headshotImg);
    setExistingImageName("");
    setChangesMade(true);
    // setBannerUrl(headshotImg);
    // setBannerImageName("");
  };

  const tabChangeHandler = (event, newValue) => {
    setTabValue(newValue);
  };

  const resetChangesHandler = (e) => {
    e.preventDefault();

    setAgencyName(agencyData?.banner_heading ? agencyData?.banner_heading : "");
    setAgencyWebsite(
      agencyData?.banner_subheading2 ? agencyData?.banner_subheading2 : ""
    );
    setAgencyTagline(
      agencyData?.banner_subheading1 ? agencyData?.banner_subheading1 : ""
    );
    setBackgroundColor(
      agencyData?.brand_color ? agencyData?.brand_color : "#3F8BE7"
    );

    if (agencyData?.headshot_filename) {
      setImageName(agencyData?.headshot_filename);
      setExistingImageName(agencyData?.headshot_filename);
      setImageUrl(agencyData?.headshot_url);
    } else {
      setExistingImageName(imageName);
      setImageUrl(headshotImg);
    }

    if (agencyData?.banner_filename_raw) {
      setBannerName(agencyData?.banner_filename_raw);
    } else {
      setBannerName("");
    }

    setBannerSize(
      agencyData?.agency?.banner_file_size
        ? agencyData?.agency?.banner_file_size
        : ""
    );

    if (agencyData?.banner_filename) {
      setBannerImageName(agencyData?.banner_filename);
      setBannerUrl(agencyData?.banner_url);
    } else {
      setBannerImageName("");
      setBannerUrl(headshotImg);
    }

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "branding_reset",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }

    setChangesMade(false);
  };

  const submitAgencyDetailsHandler = async (e) => {
    e.preventDefault();
    const access = agentUserRef.current?.agent?.permissions?.update_branding;
    if (!access) {
      setPermissionMessage(
        "You do not have permission to update app branding!"
      );
      setOpen(true);
      return;
    } else {
      setStatus({
        type: "",
        message: "",
      });

      setAgencyUploading(true);

      try {
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };

        let agencySettings;

        if (tabValue === "create_banner") {
          agencySettings = {
            banner_heading: agencyName,
            banner_subheading1: agencyTagline,
            banner_subheading2: agencyWebsite,
            brand_color: backgroundColor,
            headshot_filename: imageName,
            banner_selection: "customized",
          };
        } else {
          agencySettings = {
            banner_filename_raw: bannerName,
            banner_file_size: bannerSize,
            brand_color: backgroundColor,
            banner_filename: bannerImageName,
            banner_selection: bannerName ? "uploaded" : "customized",
          };
        }

        const agencyData = {
          agency_id: agencyID,
          agency_settings: agencySettings,
        };

        const res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/agent/update-agency`,
          agencyData,
          config
        );

        if (tabValue === "create_banner") {
          setBannerName("");
        }

        setStatus({
          type: "success",
          message: "Your changes saved successfully",
        });
        setAgencyUploading(false);
        handleClick();
        setChangesMade(false);

        if (agentUser?.agent && agencyDetail?.agency) {
          segmentTrack({
            event: "branding_updated",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
          });
        }
      } catch (error) {
        setStatus({
          type: "error",
          message:
            error.data && error.data.message
              ? error.data.message
              : error.message,
        });
        setAgencyUploading(false);
        console.log(error);
      } finally {
        setSelectedFileError("");
      }
    }
  };

  return (
    <>
      {/* {agencyLoading && (
        <SkeletonGrid itemCount={2} spacing={2} secondRowHeight={[600, 250]} />
      )} */}
      {!agencyUploading && status?.type === "success" && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={open}
          handleClose={handleClose}
        />
      )}
      {!agencyUploading && selectedFileError && (
        <AlertMessage
          message={selectedFileError}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}
      {!agencyUploading && permissionMessage && (
        <AlertMessage
          message={permissionMessage}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {openImageModal && (
        <DialogOverlay open={openImageModal} height="587px">
          <ProfileModal
            headerTitle="Crop Profile Picture"
            closeFormDialogHandler={closeImageModal}
            saveImageHandler={saveImageHandler}
            uploading={uploading}
          >
            <Box
              position="relative"
              height="100%"
              sx={{
                overflow: "hidden",
                ".cropper-crop-box, .cropper-view-box": {
                  ".cropper-modal": {
                    backgroundColor: "#000 !important",
                  },
                  // ".cropper-view-box": {
                  //   outline: "none !important",
                  // },
                  ".cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line":
                    {
                      display: "none !important",
                    },
                  ".cropper-face": {
                    backgroundColor: "inherit !important",
                  },
                },
              }}
              // sx={{
              //   overflow: "hidden",
              //   ".cropper-crop-box, .cropper-view-box, .cropper-modal": {
              //     borderRadius: "50%",
              //     // backgroundColor: "#fff !important",
              //     ".cropper-view-box": {
              //       outline: "none !important",
              //     },
              //     ".cropper-dashed, .cropper-point.point-se, .cropper-point.point-sw, .cropper-point.point-nw,   .cropper-point.point-ne, .cropper-line":
              //       {
              //         display: "none !important",
              //       },
              //     ".cropper-face": {
              //       backgroundColor: "inherit !important",
              //     },
              //   },
              // }}
            >
              {imageUrl && (
                <Cropper
                  ref={cropperRef}
                  style={{
                    height: "100% !important",
                    width: "100%",
                    // background: "transparent !important",
                  }}
                  // zoomTo={0.5}
                  // initialAspectRatio={10}
                  aspectRatio={0.88 / 1} // Set the desired aspect ratio (width / height)
                  // cropBoxResizable={false} // Disable crop box resizing
                  viewMode={1}
                  preview=".img-preview"
                  src={imageUrl}
                  // minCropBoxHeight={10}
                  // minCropBoxWidth={10}
                  background={false}
                  responsive={true}
                  // autoCropArea={1}
                  checkOrientation={false}
                  // onInitialized={(instance) => {
                  //   setCropper(instance);
                  // }}
                  guides={true}
                  crop={onCrop}
                />
              )}
              <Box
                display="flex"
                position="absolute"
                bottom="18.5px"
                left="23px"
                gap="4px"
                alignItems="center"
              >
                <Button
                  variant="contained"
                  size="small"
                  disableRipple
                  onClick={handleZoomIn}
                >
                  <Icon sx={{ width: "100%", height: "100%" }}>
                    <ZoominIcon />
                  </Icon>
                </Button>

                <Button
                  variant="contained"
                  size="small"
                  disableRipple
                  onClick={handleZoomOut}
                >
                  <Icon sx={{ width: "100%", height: "100%" }}>
                    <ZoomoutIcon />
                  </Icon>
                </Button>
              </Box>
            </Box>
          </ProfileModal>
        </DialogOverlay>
      )}

      <Box
        display="flex"
        pr={3}
        justifyContent="space-between"
        gap="24px"
        alignItems="flex-start"
        className={classes.agency_container}
      >
        <CardUI dashboard="true" maxWidth="100%" padding="32px">
          <Box
            display="flex"
            component="form"
            flexDirection="column"
            gap="24px"
          >
            <Typography
              variant="h4"
              color="secondary"
              textTransform="uppercase"
            >
              Customize Client app Banner
            </Typography>

            {/* TABS */}
            {agencyLoading ? (
              <Stack>
                <BrandingSkeleton />
              </Stack>
            ) : (
              <>
                <Stack>
                  <Tabs
                    value={tabValue}
                    onChange={tabChangeHandler}
                    sx={{ minHeight: "0 !important" }}
                    indicatorColor="primary"
                  >
                    {brandingTabs.map((tabData, i) => (
                      <Tab
                        icon={React.cloneElement(tabData.icon, {
                          color:
                            tabValue === tabData.value ? "#0C80F3" : "#566875",
                        })}
                        iconPosition="start"
                        sx={{
                          minHeight: "0 !important",
                          "& svg": {
                            marginRight: "8px !important",
                          },
                        }}
                        // disableRipple
                        key={i}
                        value={tabData.value}
                        label={tabData.title}
                      />
                    ))}
                  </Tabs>
                  <Divider />
                </Stack>

                {tabValue === "create_banner" && (
                  <CreateBannerTemplate
                    uploading={uploading}
                    imageUrl={imageUrl}
                    hiddenFileInputRef={hiddenFileInputRef}
                    handleFileChange={handleFileChange}
                    handleFileClick={handleFileClick}
                    deleteFileHandler={deleteFileHandler}
                    name={name}
                    agencyName={agencyName}
                    setAgencyName={setAgencyName}
                    tagline={tagline}
                    setAgencyTagline={setAgencyTagline}
                    agencyTagline={agencyTagline}
                    website={website}
                    agencyWebsite={agencyWebsite}
                    setAgencyWebsite={setAgencyWebsite}
                    setChangesMade={setChangesMade}
                    isTouched={isTouched}
                    setIsTouched={setIsTouched}
                  />
                )}

                {tabValue === "upload_banner" && (
                  <UploadBannerTemplate
                    handleFileClick={handleFileClick}
                    hiddenFileInputRef={hiddenFileInputRef}
                    handleFileChange={handleFileChange}
                    bannerSize={bannerSize}
                    bannerName={bannerName}
                    deleteUploadedBanner={deleteUploadedBannerHandler}
                  />
                )}

                {/* CUSTOMIZE CLIENT APP THEME */}
                <Typography
                  variant="h4"
                  color="secondary"
                  textTransform="uppercase"
                >
                  Customize Client app Theme
                </Typography>

                <Box display="flex" flexDirection="column" gap="8px">
                  <Typography variant="h4" color="#000" fontWeight="400">
                    Choose application primary color
                  </Typography>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="16px"
                    flexWrap="wrap"
                  >
                    {colorBoxes.map((box, i) => (
                      <Box
                        key={i}
                        border={
                          backgroundColor === box ? `1px solid ${box}` : ""
                        }
                        className={classes.colorOuterBox}
                        onClick={() => changeBackgroundColorHandler(box)}
                      >
                        <Box className={classes.color_box} bgcolor={box}></Box>
                      </Box>
                    ))}
                  </Box>
                  <Typography
                    variant="tableSemiHeading"
                    color="disabled.customs"
                  >
                    Note: This color will also be the background of your Banner
                  </Typography>
                </Box>

                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-end"
                  gap="16px"
                >
                  <Button
                    title="Reset Changes"
                    color="primary"
                    onClick={resetChangesHandler}
                    name="reset-changes"
                    fontSize="14px"
                    disabled={!changesMade}
                  />

                  <LoadingButton
                    loading={agencyUploading || uploading}
                    color="primary"
                    name="publish-changes"
                    onClick={submitAgencyDetailsHandler}
                    variant="contained"
                    fontSize="14px"
                    disabled={!changesMade || !agencyName}
                  >
                    Publish Changes
                  </LoadingButton>
                </Box>
              </>
            )}
          </Box>
        </CardUI>

        {/* BANNER PREVIEW  */}
        <BannerPreviewTemplate
          existingImageName={existingImageName}
          backgroundColor={backgroundColor}
          agencyName={agencyName}
          name={name}
          agencyTagline={agencyTagline}
          tagline={tagline}
          agencyWebsite={agencyWebsite}
          website={website}
          imageUrl={imageUrl}
          bannerUrl={bannerUrl}
          tabValue={tabValue}
          bannerName={bannerName}
          loadingAgency={loadingAgency}
          changesMade={changesMade}
        />
      </Box>
    </>
  );
}
