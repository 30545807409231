import React from "react";

import { makeStyles } from "@mui/styles";
import { Drawer, Stack } from "@mui/material";

const CommonDrawer = ({
  anchor,
  open,
  onClose,
  setOpen,
  children,
  height,
  padding = "32px",
  width = "748px",
}) => {
  const useStyles = makeStyles((theme) => {
    return {
      drawer__width: {
        width: width,
        [theme.breakpoints.down("md")]: {
          width: "90vw",
        },
      },
    };
  });

  const classes = useStyles();

  return (
    <Drawer anchor={anchor} open={open} onClose={onClose}>
      <Stack
        className={classes.drawer__width}
        height={height}
        padding={padding}
      >
        {children}
      </Stack>
    </Drawer>
  );
};

export default CommonDrawer;
