import React from "react";
import { Chart } from "chart.js";
import * as Chartjs from "chart.js";
import { Doughnut } from "react-chartjs-2";

const controllers = Object.values(Chartjs).filter(
  (chart) => chart.id !== undefined
);

Chart.register(...controllers);

const DoughnutChartUI = ({
  transportAmount,
  medicalAmount,
  salaryLossAmount,
  otherAmount,
}) => {
  const data = {
    labels: [
      "Transport Expenses",
      "Medical Expenses",
      "Salary Loss",
      "Other Expenses",
    ],
    datasets: [
      {
        label: "Expenses",
        data: [transportAmount, medicalAmount, salaryLossAmount, otherAmount],
        backgroundColor: ["#3F3CD8", "#4EB9ED", "#EA981C", "#FAD44D"],
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  return (
    <Doughnut
      data={data}
      options={options}
      style={{ width: "100% !important" }}
    />
  );
};

export default DoughnutChartUI;
