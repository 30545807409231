import { MenuItem, Select } from "@mui/material";
import React from "react";

const FilterDaysDropDown = ({
  labelId,
  id,
  value,
  iconComponent,
  caseAddedInPlan,
  changeFilterHandler,
  hideCustom = false,
}) => {
  return (
    <Select
      labelId={labelId}
      id={id}
      value={value}
      IconComponent={iconComponent}
      size="small"
      sx={{
        marginRight: "10px !important",
        color: "#000 !important",
        borderRadius: "4px !important",
        background: "#F4F6F8",
        "&:before": {
          borderColor: "white !important",
        },
        "&:after": {
          borderColor: "white !important",
        },
        boxShadow: "none !important",
        ".MuiOutlinedInput-notchedOutline": {
          border: "0  !important",
        },

        ".MuiSvgIcon-root ": {
          fill: caseAddedInPlan ? "#000" : "rgba(0, 0, 0, 0.38) !important",
        },
      }}
      onChange={changeFilterHandler}
    >
      <MenuItem value={14}>Last 14 Days</MenuItem>
      <MenuItem value={31}>Last 1 Month</MenuItem>
      <MenuItem value={92}>Last 3 Months</MenuItem>
      <MenuItem value={365}>Last 12 Months</MenuItem>
      {!hideCustom && <MenuItem value="custom">Custom</MenuItem>}
    </Select>
  );
};

export default FilterDaysDropDown;
