import React, { useState } from "react";
import CardUI from "../../common/Card";
import {
  Box,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import Style from "./style";
import BannerBackView from "../BannerBackView";

const Styles = makeStyles((theme) => Style(theme));

const BannerPreviewTemplate = ({
  existingImageName,
  backgroundColor,
  agencyName,
  name,
  agencyTagline,
  tagline,
  agencyWebsite,
  website,
  imageUrl,
  bannerUrl,
  tabValue,
  bannerName,
  loadingAgency,
  changesMade,
}) => {
  const classes = Styles();

  const [bannerView, setBannerView] = useState("front");

  // BANNER FRONT BACK HANDLER
  const bannerViewHandler = (event, nextView) => {
    setBannerView(nextView);
  };

  return (
    <CardUI dashboard="true" maxWidth={408} responsive="true" padding="32px">
      <Stack gap="16px">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Stack>
            <Typography
              variant="h4"
              color="secondary"
              textTransform="uppercase"
            >
              Banner Preview
            </Typography>
            <Box display="flex" alignItems="center" gap="4px">
              <Box
                width="9px"
                height="9px"
                borderRadius="50%"
                bgcolor={changesMade ? "#F87E4A" : "#3DC583"}
              ></Box>
              <Typography
                variant="body2"
                fontWeight={600}
                color={changesMade ? "#F87E4A" : "#3DC583"}
              >
                {changesMade ? "Draft" : "Live"}
              </Typography>
            </Box>
          </Stack>

          <ToggleButtonGroup
            sx={{ height: "40px" }}
            size="small"
            exclusive
            value={bannerView}
            onChange={bannerViewHandler}
            aria-label="view"
          >
            <ToggleButton
              value="front"
              aria-label="front"
              sx={{
                textTransform: "capitalize",
                padding: "10px",
              }}
              style={{
                color: bannerView === "front" ? "#fff" : "#566875",
                border: bannerView === "front" && "1px solid #0C80F3",
                backgroundColor: bannerView === "front" ? "#0C80F3" : "inherit",
              }}
            >
              Front
            </ToggleButton>
            <ToggleButton
              value="back"
              aria-label="back"
              sx={{ textTransform: "capitalize", padding: "10px" }}
              style={{
                color: bannerView === "back" ? "#fff" : "#566875",
                border: bannerView === "back" && "1px solid #0C80F3",
                backgroundColor: bannerView === "back" ? "#0C80F3" : "inherit",
              }}
            >
              Back
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>

        {loadingAgency ? (
          <Skeleton width={342} variant="rounded" height={164} />
        ) : (
          <>
            {bannerView === "front" ? (
              <>
                {(tabValue === "create_banner" || !bannerName) && (
                  <Box
                    className={classes.banner__withName}
                    bgcolor={backgroundColor}
                    display="flex"
                    alignItems="center"
                    gap="6px"
                    // justifyContent="center"
                  >
                    {existingImageName?.length > 0 && (
                      <Box
                        className={classes.image_container}
                        width="138px"
                        height="100%"
                      >
                        <img
                          src={imageUrl}
                          alt="Headshot image"
                          className={classes.passportImage}
                        />
                      </Box>
                    )}
                    <Stack
                      className="main__container"
                      paddingRight="8px"
                      textAlign="center"
                      alignItems="center"
                      justifyContent="center"
                      width={existingImageName.length > 0 ? `182px` : `100%`}
                    >
                      <Stack gap="8px">
                        <Typography
                          variant="h5"
                          color="primary.light"
                          sx={{
                            fontSize: "20px",
                            fontWeight: 600,
                            fontFamily: "Lora",
                          }}
                        >
                          {agencyName ? agencyName : name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          fontSize="12px"
                          color="primary.light"
                          fontFamily="Roboto Condensed"
                          sx={{ opacity: 0.7 }}
                        >
                          {agencyTagline ? agencyTagline : tagline}
                        </Typography>
                      </Stack>

                      <Typography
                        py={0.5}
                        variant="h6"
                        fontSize="12px"
                        color="primary.light"
                        fontFamily="Roboto Condensed"
                      >
                        {agencyWebsite ? agencyWebsite : website}
                      </Typography>
                    </Stack>
                  </Box>
                )}

                {tabValue === "upload_banner" && bannerName && (
                  <Box
                    className={classes.banner__withName}
                    bgcolor={backgroundColor}
                    // justifyContent="center"
                  >
                    <Box className={classes.image_container} width="100%">
                      <img
                        src={bannerUrl}
                        alt="Banner image"
                        className={classes.bannerImage}
                      />
                    </Box>
                  </Box>
                )}
              </>
            ) : (
              <BannerBackView backgroundColor={backgroundColor} />
            )}

            <Typography
              p="3px 14px 0px 14px"
              variant="tableSemiHeading"
              color="disabled.light"
            >
              Note: Publish changes to see updated banner and theme color on
              mobile app.
            </Typography>
          </>
        )}
      </Stack>
    </CardUI>
  );
};

export default BannerPreviewTemplate;
