const Style = (theme) => ({
  all_container: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    gap: "12px",
    borderRadius: "8px",
    padding: "16px",
  },
  lightText: {
    color: "rgba(0, 0, 0, 0.38)",
  },
  blueTextLine: {
    color: "#0C80F3",
    textDecoration: "underline",
  },
  description: {
    color: "#566875",
    fontSize: "14px",
  },
});

export default Style;
