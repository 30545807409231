import React, { useCallback, useEffect, useMemo, useState } from "react";
import CardUI from "../../../../common/Card";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import AllCasesTab from "../../../../components/AllCasesTab";
import LastMonthCasesTab from "../../../../components/LastMonthCasesTab";
import Last6MonthCasesTab from "../../../../components/Last6MonthCasesTab";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getAllCasesFunc,
  getUnpaginatedCasesFunc,
  resetAllCases,
} from "../../../../actions/Cases";
import { debounce } from "lodash";
import AvatarWithName from "../../../../components/AvatarWithName";
import {
  caseStatusType,
  lastMonthCreatedCases,
  onlyDateFormat,
} from "../../../../utils/helpers";
import dayjs from "dayjs";
import CaseTableSkeleton from "../../../../components/CaseTableSkeleton";
import {
  getAgentsListFunc,
  getAllClientsUnpaginatedFunc,
} from "../../../../actions/Agency";
import CustomBillingScreen from "../../../../components/CustomBillingScreen";
import {
  cancelBillingSubscriptionFunc,
  createBillingSubscriptionFunc,
  getBillingSubscriptionFunc,
  resetCreateBillingSubscription,
  resetUpdateBillingSubscription,
  updateBillingSubscriptionFunc,
} from "../../../../actions/BillingSubscription";
import RectangleSkeletons from "../../../../components/RectangleSkeletons";
import { LoadingButton } from "@mui/lab";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ height: "100%" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `plan-tab-${index}`,
    "aria-controls": `plan-tabpanel-${index}`,
  };
}

const Billing = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [selectedRows, setSelectedRows] = useState([]);
  const [showEditPlanScreen, setShowEditPlanScreen] = useState(true);
  const [casesList, setCasesList] = useState([]);
  const [query, setQuery] = useState("");
  const [filteredCases, setFilteredCases] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState("all");
  const [caseStatus, setCaseStatus] = useState("all");
  const [allClients, setAllClients] = useState([]);
  const [addFutureCases, setAddFutureCases] = useState(true);
  const [editPlan, setEditPlan] = useState(false);
  const [billingSubscriptionScreen, setBillingSubscriptionScreen] =
    useState(null);

  const dispatch = useDispatch();
  const lastMonthCases = lastMonthCreatedCases(filteredCases, 1);
  const last6MonthCases = lastMonthCreatedCases(filteredCases, 6);
  const customBillingScreen = true;

  const getAllUnpaginatedCases = useSelector(
    (state) => state.getAllUnpaginatedCases
  );
  const {
    loading: loadingCase,
    error: errorCase,
    allUnpaginatedCases: cases,
  } = getAllUnpaginatedCases;

  const getBillingSubscription = useSelector(
    (state) => state.getBillingSubscription
  );
  const {
    loading: loadingBilling,
    error: errorBilling,
    billingSubscription,
  } = getBillingSubscription;

  const createBillingSubscription = useSelector(
    (state) => state.createBillingSubscription
  );
  const {
    loading: loadingCreateBillingSubsc,
    error: errorCreateBillingSubsc,
    createdBillingSubscription,
  } = createBillingSubscription;

  const updateBillingSubscription = useSelector(
    (state) => state.updateBillingSubscription
  );
  const {
    loading: loadingUpdateBillingSubsc,
    error: errorUpdateBillingSubsc,
    updatedBillingSubscription,
  } = updateBillingSubscription;

  const getAgentsList = useSelector((state) => state.getAgentsList);
  const {
    loading: loadingAgentsList,
    error: errorAgentsList,
    allAgents,
  } = getAgentsList;

  const getAllClients = useSelector((state) => state.getAllClientsUnpaginated);
  const {
    loading: loadingClients,
    error: errorClients,
    clients,
  } = getAllClients;

  const cancelBillingSubscription = useSelector(
    (state) => state.cancelBillingSubscription
  );
  const {
    loading: loadingCancelSubsc,
    error: errorCancelSubsc,
    cancelledSubscription,
  } = cancelBillingSubscription;

  const planTabs = [
    {
      title: "All",
      component: (
        <AllCasesTab
          cases={filteredCases}
          loadingCase={loadingCase}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      title: "Created last Month",
      component: (
        <AllCasesTab
          cases={lastMonthCases}
          loadingCase={loadingCase}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
    {
      title: "Created in last 6 Months",
      component: (
        <AllCasesTab
          cases={last6MonthCases}
          loadingCase={loadingCase}
          selectedRows={selectedRows}
          setSelectedRows={setSelectedRows}
        />
      ),
    },
  ];

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  // console.log("SSSS", selectedRows);
  // console.log("BBBBB", billingSubscription);

  useEffect(() => {
    dispatch(getUnpaginatedCasesFunc());
    dispatch(getBillingSubscriptionFunc());
    if (!allAgents) {
      dispatch(getAgentsListFunc());
    }
    if (!clients) {
      dispatch(getAllClientsUnpaginatedFunc());
    }
  }, []);

  useEffect(() => {
    if (billingSubscription?.ok) {
      setBillingSubscriptionScreen(billingSubscription?.subscription);
      setAddFutureCases(
        billingSubscription?.subscription?.include_future_cases || false
      );
      if (billingSubscription?.subscription?.subscription_status === "active") {
        setShowEditPlanScreen(false);
      }
    }
  }, [billingSubscription]);

  const addFutureCasesHandler = (e) => {
    setAddFutureCases(e.target.checked);
  };

  const editPlanHandler = () => {
    setEditPlan(true);
    setBillingSubscriptionScreen(null);
    setShowEditPlanScreen(true);
  };

  useEffect(() => {
    if (clients?.ok) {
      setAllClients(clients?.clients_list);
    }
  }, [clients]);

  useEffect(() => {
    if (allAgents?.ok) {
      const agentOptions =
        allAgents?.agents_list?.length > 0
          ? allAgents?.agents_list?.filter(
              (agent) => agent?.agent_type !== "mcc_support"
            )
          : [];
      setTeamMembers(agentOptions);
    }
  }, [allAgents]);

  useEffect(() => {
    if (cases?.ok) {
      setCasesList(cases?.cases);
      const initiallySelectedRows = cases?.cases?.filter(
        (caseItem) => caseItem?.added_to_subscription
      );
      setSelectedRows(initiallySelectedRows);
    }
  }, [cases]);

  // const selectedActiveCases = selectedRows?.filter(
  //   (row) => row.case_status === "open"
  // );
  // const normalCases = selectedRows?.filter((row) => row.case_status !== "open");

  const purchasePlanHandler = () => {
    const casesIds = selectedRows?.map((sc) => sc?.id);
    const clientIds = allClients?.map((c) => c?.id);
    const billingObj = {
      include_future_cases: addFutureCases,
      case_IDs: casesIds,
      client_IDs: clientIds,
    };
    if (editPlan) {
      dispatch(updateBillingSubscriptionFunc(billingObj));
    } else {
      dispatch(createBillingSubscriptionFunc(billingObj));
    }
  };

  useEffect(() => {
    if (createdBillingSubscription?.ok) {
      const linkURL = createdBillingSubscription?.payment_link;

      window.open(linkURL, "_blank");
      dispatch(resetCreateBillingSubscription());
    }
  }, [createdBillingSubscription]);

  useEffect(() => {
    if (updatedBillingSubscription?.ok) {
      dispatch(getBillingSubscriptionFunc());
      dispatch(resetUpdateBillingSubscription());
    }
  }, [updatedBillingSubscription]);

  const parentCheckboxHandler = (e) => {
    const isParentChecked = e.target.checked;
    const selectedCases = isParentChecked ? casesList : [];
    setSelectedRows(selectedCases);
  };

  const isIndeterminate = () => {
    return selectedRows?.length > 0 && selectedRows?.length < casesList?.length;
  };

  const selectTeamMemberHandler = (e) => {
    setSelectedTeamMember(e.target.value);
  };
  const changeCaseStatusHandler = (e) => {
    setCaseStatus(e.target.value);
  };

  useEffect(() => {
    const filteredCasesList = casesList?.filter(
      (caseItem) =>
        caseItem?.title?.toLowerCase()?.includes(query?.toLowerCase()) &&
        (selectedTeamMember === "all" ||
          caseItem?.agents?.some(
            (agent) => agent?.id === selectedTeamMember?.id
          )) &&
        (caseStatus === "all" || caseItem?.status?.toLowerCase() === caseStatus)
    );
    setFilteredCases(filteredCasesList);
  }, [casesList, query, selectedTeamMember, caseStatus]);

  const searchQueryHandler = (value) => {
    setQuery(value);
  };

  const cancelSubscriptionHandler = () => {
    dispatch(cancelBillingSubscriptionFunc());
  };

  useEffect(() => {
    if (cancelledSubscription?.ok) {
      setSelectedRows([]);
      setShowEditPlanScreen(true);
      dispatch(getAllClientsUnpaginatedFunc());
      dispatch(getBillingSubscriptionFunc());
    }
  }, [cancelledSubscription]);

  const activeCases = casesList?.filter(
    (caseItem) => caseItem?.added_to_subscription === true
  );

  const activeCasesBill = selectedRows?.length * 30;
  const totalClientsBill = allClients?.length * 5;
  const totalBill = activeCasesBill + totalClientsBill;

  return (
    <>
      {loadingBilling ? (
        <RectangleSkeletons />
      ) : !billingSubscriptionScreen ||
        (billingSubscriptionScreen &&
          !billingSubscriptionScreen?.custom_subscription) ? (
        <Box display="flex" gap="16px" alignItems="flex-start">
          {/* PLAN DETAILS */}
          <CardUI maxWidth="100%" dashboard={true} padding="32px">
            <Stack gap="24px">
              <Stack padding="16px" bgcolor="#E5F6FD" borderRadius="4px">
                <Typography
                  variant="subtitle1"
                  fontSize="16px"
                  textTransform="uppercase"
                  color="secondary"
                >
                  Plan Details
                </Typography>
                <Typography color="#014361" variant="body2" padding="8px 0">
                  For all active cases you will be charged{" "}
                  <Box component="span" fontWeight="600">
                    $30/case/month{" "}
                  </Box>{" "}
                  and for all client you will be charged{" "}
                  <Box component="span" fontWeight="600">
                    $5/client/month{" "}
                  </Box>{" "}
                  . Customise your plan from the list below by selecting the
                  case you want to include.
                </Typography>
              </Stack>
              {showEditPlanScreen ? (
                <>
                  {/* PLAN TABS */}
                  <Tabs
                    // className={clsx(classes.tabs__bar)}
                    value={activeTab}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    {planTabs?.map(
                      (tab, key) =>
                        tab.title && (
                          <Tab
                            iconPosition="start"
                            icon={tab.icon}
                            label={tab.title}
                            key={tab.title}
                            {...a11yProps(key++)}
                          />
                        )
                    )}
                  </Tabs>
                  <Box display="flex" alignItems="center" gap="16px">
                    <Box minWidth="256px">
                      <TextField
                        size="small"
                        value={query}
                        onChange={(e) => searchQueryHandler(e.target.value)}
                        placeholder="Name or Number"
                        label="Search"
                        fullWidth
                        // disabled={true}
                      />
                    </Box>
                    <FormControl sx={{ m: 1, width: 136 }} size="small">
                      <InputLabel id="assigned-to">Assigned to</InputLabel>
                      <Select
                        labelId="assigned-to"
                        id="assigned-to"
                        value={selectedTeamMember}
                        label="Assigned to"
                        onChange={selectTeamMemberHandler}
                      >
                        <MenuItem value="all">All</MenuItem>
                        {teamMembers?.map((agent) => (
                          <MenuItem key={agent?.id} value={agent}>
                            {agent?.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl sx={{ m: 1, width: 136 }} size="small">
                      <InputLabel id="case-status">Case Status</InputLabel>
                      <Select
                        labelId="case-status"
                        id="case-status"
                        value={caseStatus}
                        label="Case Status"
                        onChange={changeCaseStatusHandler}
                      >
                        <MenuItem value="all">All</MenuItem>
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="settled">Settled</MenuItem>
                        <MenuItem value="on hold">On Hold</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Stack>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      gap="16px"
                      p="0 8px"
                    >
                      <Box>
                        <Checkbox
                          onChange={parentCheckboxHandler}
                          indeterminate={isIndeterminate()}
                          checked={selectedRows?.length === casesList?.length}
                        />
                      </Box>
                      <Box flex="1">
                        <Typography
                          variant="body1"
                          fontSize="14px"
                          fontWeight={500}
                        >
                          Case
                        </Typography>
                      </Box>
                      <Box flex="1">
                        <Typography
                          variant="body1"
                          fontSize="14px"
                          fontWeight={500}
                        >
                          Associated Client{" "}
                        </Typography>
                      </Box>
                      <Box flex="1">
                        <Typography
                          variant="body1"
                          fontSize="14px"
                          fontWeight={500}
                        >
                          Case Status{" "}
                        </Typography>
                      </Box>
                      <Box flex="1" display="flex" justifyContent="flex-end">
                        <Typography
                          variant="body1"
                          fontSize="14px"
                          fontWeight={500}
                        >
                          Open Date{" "}
                        </Typography>
                      </Box>
                    </Box>
                    <Divider />
                    <TabPanel
                      value={activeTab}
                      index={activeTab}
                      // className={clsx(classes.tab__panel)}
                    >
                      {planTabs[activeTab].component}
                    </TabPanel>
                  </Stack>
                </>
              ) : (
                <Stack gap="24px">
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="16px"
                  >
                    <Typography
                      variant="subtitle1"
                      color="secondary"
                      fontSize="16px"
                    >
                      Active Cases
                    </Typography>
                    <Button variant="contained" onClick={editPlanHandler}>
                      Edit Plan
                    </Button>
                  </Box>

                  {activeCases?.length ? (
                    <Stack gap="8px" height="402px" overflow="auto">
                      {activeCases?.map((rowItem, index) => (
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="space-between"
                          key={index}
                          border="1px solid #EBEBEB"
                          padding="8px 24px"
                          borderRadius="4px"
                        >
                          <Stack flex="1">
                            <Typography
                              variant="subtitle1"
                              fontSize="16px"
                              fontWeight={400}
                            >
                              {rowItem?.title}
                            </Typography>
                            {rowItem?.case_number && (
                              <Typography
                                variant="body2"
                                color="rgba(0, 0, 0, 0.38)"
                              >
                                #{rowItem?.case_number}
                              </Typography>
                            )}
                          </Stack>
                          <Stack gap="4px" flex="1">
                            <Typography
                              variant="body2"
                              fontSize="12px"
                              fontWeight={500}
                            >
                              Associated Client
                            </Typography>
                            <AvatarWithName
                              name={rowItem?.client?.name}
                              imageURL={rowItem?.client?.profile_picture_url}
                            />
                          </Stack>
                          <Stack gap="4px">
                            <Typography
                              variant="body2"
                              fontSize="12px"
                              fontWeight={500}
                            >
                              Case Open Date{" "}
                            </Typography>
                            {rowItem?.case_opening_date ? (
                              <Typography
                                variant="body2"
                                color="rgba(0, 0, 0, 0.38)"
                              >
                                {onlyDateFormat(rowItem?.case_opening_date)}
                              </Typography>
                            ) : (
                              <Typography
                                variant="body2"
                                color="rgba(0, 0, 0, 0.38)"
                              >
                                -{" "}
                              </Typography>
                            )}
                          </Stack>
                        </Box>
                      ))}
                    </Stack>
                  ) : (
                    <Stack>
                      <Typography variant="h6" textAlign="center">
                        No active plans!{" "}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              )}
            </Stack>
          </CardUI>

          {/* PLAN SUMMARY */}
          <CardUI maxWidth={347} dashboard={true} padding="32px">
            <Stack gap="24px">
              <Stack gap="16px">
                <Typography
                  variant="subtitle1"
                  color="secondary"
                  fontSize="16px"
                >
                  PLAN SUMMARY
                </Typography>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Typography
                    color="rgba(0, 0, 0, 0.38)"
                    variant="body2"
                    fontWeight="500"
                  >
                    Active Cases ({selectedRows?.length} x ${30}):
                  </Typography>
                  <Typography variant="body2" color="#0D0D0D">
                    ${activeCasesBill?.toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Typography
                    color="rgba(0, 0, 0, 0.38)"
                    variant="body2"
                    fontWeight="500"
                  >
                    Total Clients ({allClients?.length} x $5):{" "}
                  </Typography>
                  <Typography variant="body2" color="#0D0D0D">
                    ${totalClientsBill?.toFixed(2)}
                  </Typography>
                </Box>
                <Divider />
                <FormControlLabel
                  value="end"
                  control={
                    <Checkbox
                      value={addFutureCases}
                      checked={addFutureCases}
                      onChange={addFutureCasesHandler}
                    />
                  }
                  label={
                    <Box component="span" fontSize="14px">
                      Add all new future cases to my plan
                    </Box>
                  }
                  labelPlacement="end"
                  sx={{ fontSize: "14px !important" }}
                />
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  gap="8px"
                >
                  <Typography
                    color="secondary"
                    variant="body1"
                    fontWeight="500"
                  >
                    Total Monthly Bill{" "}
                  </Typography>
                  <Typography variant="body1" fontWeight="500" color="#0D0D0D">
                    ${totalBill.toFixed(2)}
                  </Typography>
                </Box>
                <Divider />
              </Stack>

              {!showEditPlanScreen ? (
                <Box onClick={cancelSubscriptionHandler}>
                  <Typography
                    variant="body2"
                    color="rgba(0, 0, 0, 0.38)"
                    sx={{ textDecoration: "underline", cursor: "pointer" }}
                    textAlign="center"
                  >
                    Cancel Your Plan
                  </Typography>
                </Box>
              ) : (
                <>
                  {" "}
                  <LoadingButton
                    variant="contained"
                    onClick={purchasePlanHandler}
                    loading={
                      loadingCreateBillingSubsc || loadingUpdateBillingSubsc
                    }
                  >
                    Purchase plan
                  </LoadingButton>
                  <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
                    By updating your plan, you agree to our company Privacy
                    Policy and Condition of use
                  </Typography>
                </>
              )}
            </Stack>
          </CardUI>
        </Box>
      ) : (
        <CustomBillingScreen />
      )}
    </>
  );
};

// (
//   <Stack gap="24px">
//     <Box
//       display="flex"
//       alignItems="center"
//       justifyContent="space-between"
//       gap="16px"
//     >
//       <Typography variant="subtitle1" color="secondary" fontSize="16px">
//         Active Cases
//       </Typography>
//       <Button
//         variant="contained"
//         onClick={() => setShowActivePlans(false)}
//       >
//         Edit Plan
//       </Button>
//     </Box>
//     {activeCases?.length ? (
//       <Stack gap="8px">
//         {activeCases?.map((rowItem, index) => (
//           <Box
//             display="flex"
//             alignItems="center"
//             justifyContent="space-between"
//             key={index}
//             border="1px solid #EBEBEB"
//             padding="8px 24px"
//             borderRadius="4px"
//           >
//             <Stack flex="1">
//               <Typography
//                 variant="subtitle1"
//                 fontSize="16px"
//                 fontWeight={400}
//               >
//                 {rowItem?.title}
//               </Typography>
//               {rowItem?.case_number && (
//                 <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
//                   #{rowItem?.case_number}
//                 </Typography>
//               )}
//             </Stack>
//             <Stack gap="4px" flex="1">
//               <Typography
//                 variant="body2"
//                 fontSize="12px"
//                 fontWeight={500}
//               >
//                 Associated Client
//               </Typography>
//               <AvatarWithName
//                 name={rowItem?.client?.name}
//                 imageURL={rowItem?.client?.profile_picture_url}
//               />
//             </Stack>
//             <Stack gap="4px">
//               <Typography
//                 variant="body2"
//                 fontSize="12px"
//                 fontWeight={500}
//               >
//                 Case Open Date{" "}
//               </Typography>
//               {rowItem?.case_opening_date ? (
//                 <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
//                   {onlyDateFormat(rowItem?.case_opening_date)}
//                 </Typography>
//               ) : (
//                 <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
//                   -{" "}
//                 </Typography>
//               )}
//             </Stack>
//           </Box>
//         ))}
//       </Stack>
//     ) : (
//       <Stack>
//         <Typography variant="h6" textAlign="center">
//           No active plans!{" "}
//         </Typography>
//       </Stack>
//     )}
//   </Stack>
// )

export default Billing;
