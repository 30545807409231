import React from "react";
import { Dialog } from "@mui/material";
import zIndex from "@mui/material/styles/zIndex";

const DialogOverlay = ({
  open,
  handleClose,
  children,
  height,
  zIndex,
  hideBackdrop = false,
  width = "740px",
}) => {
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
      hideBackdrop={hideBackdrop}
      sx={{
        padding: "76px 0",
        zIndex: zIndex && zIndex,
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: width, // Set your width here
            maxHeight: "calc(100% - -110px)",
            height: height && height,
          },
        },
      }}
    >
      {children}
    </Dialog>
  );
};

export default DialogOverlay;
