import React from "react";

const IntegrationLinkIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="41"
      height="41"
      viewBox="0 0 41 41"
      fill="none"
    >
      <path
        d="M22.1004 18.7324C25.8504 22.4824 25.8504 28.5491 22.1004 32.2824C18.3504 36.0158 12.2837 36.0324 8.55039 32.2824C4.81706 28.5324 4.80039 22.4658 8.55039 18.7324"
        stroke="#0C80F3"
        strokeWidth="4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.9836 22.8508C14.0836 18.9508 14.0836 12.6175 17.9836 8.70078C21.8836 4.78412 28.2169 4.80078 32.1336 8.70078C36.0503 12.6008 36.0336 18.9341 32.1336 22.8508"
        stroke="#0C80F3"
        strokeWidth="4.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IntegrationLinkIcon;
