import { Box, CircularProgress } from "@mui/material";

const CircularProgressLoading = ({ size, color, overflow, height }) => {
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      width="100%"
      height={height && height}
      overflow={overflow && overflow}
    >
      <CircularProgress size={size} color={color} />
    </Box>
  );
};
export default CircularProgressLoading;
