import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Checkbox,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CalendarIcon from "../../icons/CalendarIcon";
import ChevronDownIcon from "../../icons/ChevronDownIcon";
import dayjs from "dayjs";

const CaseSummaryBoxContent = ({
  allFolderAndFiles,
  documentValue,
  setDocumentValue,
  fromPdfDate,
  setFromPdfDate,
  toPdfDate,
  setToPdfDate,
  dateFieldIsTouched,
  allBodyPartsData,
  loadingCaseClientDocs,
  loadingBodyParts,
  bodyPartsValue,
  setBodyPartsValue,
  checkedPainLogs,
  setCheckedPainLogs,
  checkedEventLogs,
  setCheckedEventLogs,
  caseDetail,
}) => {
  const [isTextFieldFocused, setIsTextFieldFocused] = useState(false);
  const [bodyPartField, setBodyPartField] = useState(false);

  // const lastDate =
  //   caseDetail?.case?.incident_date || caseDetail?.case?.created_at;

  // const todayDate = dayjs(new Date());

  return (
    <Stack paddingTop="12px" gap="12px">
      <Typography variant="h6" fontSize="1rem">
        Output Configurations
      </Typography>
      <Box
        variant="h6"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="12px"
      >
        <Typography variant="h6" fontWeight="400" width="130px">
          Range{" "}
        </Typography>
        <Box display="flex" alignItems="center" gap="8px">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="From"
              // defaultValue={todayDate}
              value={fromPdfDate}
              onChange={(newValue) => setFromPdfDate(newValue)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: dateFieldIsTouched && !fromPdfDate,
                },
              }}
              //   renderInput={(params) => (

              //     <TextField
              //       {...params}
              //       sx={{ width: "200px", height: "40px" }} // Adjust width and height as needed
              //     />
              //   )}
              disableFuture
              slots={{
                openPickerIcon: CalendarIcon,
              }}
              //   textField={(params) => (
              //     <TextField size="small" {...params} readOnly />
              //   )}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker
              label="To"
              value={toPdfDate}
              onChange={(newValue) => setToPdfDate(newValue)}
              // defaultValue={dayjs(lastDate)}
              slotProps={{
                textField: {
                  fullWidth: true,
                  error: dateFieldIsTouched && !toPdfDate,
                },
              }}
              //   disabled={fromCustomDate ? false : true}
              //   minDate={fromCustomDate}
              minDate={dayjs(fromPdfDate)}
              disableFuture
              //   textField={(params) => (
              //     <TextField size="small" {...params} readOnly />
              //   )}
              slots={{
                openPickerIcon: CalendarIcon,
              }}
            />
          </LocalizationProvider>
        </Box>
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="12px"
      >
        <Typography variant="h6" fontWeight="400" width="130px">
          Select Bodyparts{" "}
        </Typography>
        <Autocomplete
          multiple
          id="tags-outlined"
          options={allBodyPartsData}
          // defaultValue={allBodyPartsData}
          value={bodyPartsValue}
          onChange={(e, value) => {
            if (value) {
              setBodyPartsValue(value);
            }
          }}
          size="small"
          fullWidth
          sx={{ width: "492px" }}
          getOptionLabel={(option) => option}
          loading={loadingBodyParts}
          filterSelectedOptions
          renderInput={(params) => (
            <TextField
              error={dateFieldIsTouched && !bodyPartsValue?.length}
              size="small"
              onFocus={() => setBodyPartField(true)}
              onBlur={() => setBodyPartField(false)}
              {...params}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {bodyPartField && loadingBodyParts ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      </Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Typography
          variant="body1"
          fontWeight="400"
          component="label"
          htmlFor="pain-logs"
        >
          Include detailed daily pain logs in table form{" "}
        </Typography>
        <Checkbox
          id="pain-logs"
          checked={checkedPainLogs}
          onChange={(event) => setCheckedPainLogs(event.target.checked)}
          inputProps={{ "aria-label": "pain-logs" }}
        />
      </Box>
      {/* <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={1}
      >
        <Typography
          variant="body1"
          fontWeight="400"
          component="label"
          htmlFor="event-logs"
        >
          Include detailed Events logs in table form
        </Typography>
        <Checkbox
          id="event-logs"
          checked={checkedEventLogs}
          onChange={(event) => setCheckedEventLogs(event.target.checked)}
          inputProps={{ "aria-label": "event-logs" }}
        />
      </Box> */}
      <Divider sx={{ margin: "12px 0" }} />
      <Typography variant="body1" fontWeight="500">
        Choose Documents to include in the report
      </Typography>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={allFolderAndFiles || []}
        value={documentValue}
        getOptionLabel={(option) => option?.name}
        isOptionEqualToValue={(option, value) => option?.id === value?.id}
        onChange={(e, value) => {
          if (value) {
            setDocumentValue(value);
          }
        }}
        loading={loadingCaseClientDocs}
        autoHighlight
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            // error={dateFieldIsTouched && !documentValue?.length}
            // onFocus={() => setIsTextFieldFocused(true)}
            // onBlur={() => setIsTextFieldFocused(false)}
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {isTextFieldFocused && loadingCaseClientDocs ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
            // InputProps={{
            //   ...params.InputProps,
            //   endAdornment: (
            //     <React.Fragment>
            //       <div
            //         style={{
            //           display: "flex",
            //           alignItems: "center",
            //           justifyContent: "flex-end",
            //         }}
            //       >
            //         <ChevronDownIcon />
            //       </div>
            //     </React.Fragment>
            //   ),
            // }}
          />
        )}
      />
    </Stack>
  );
};

export default CaseSummaryBoxContent;
