import React from "react";
import BgBox from "../../common/BgBox";
import CardUI from "../../common/Card";
import { Box, Divider, Grid, Stack, Typography } from "@mui/material";
import MccLogo from "../MccLogo";
import { makeStyles } from "@mui/styles";
import MccVerticalLogo from "../../assets/images/mcc-logo-texticon.png";
import integrationImage from "../../assets/images/clio.png";
import mccLogoIntegrationImage from "../../assets/images/mcclogoIntegrationImage.png";
import IntegrationLinkGreyIcon from "../../icons/IntegrationLinkGreyIcon";
import integrationSuccessImage from "../../assets/images/integration-success-image.png";

const useStyles = makeStyles(() => {
  return {
    bgImage: {
      background:
        "linear-gradient(180deg, rgba(30, 142, 255, 0.25) 0%, rgba(250, 251, 252, 0.00) 100%)",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: "40px",
      padding: "16px",
    },
  };
});

const IntegrationSuccessPage = () => {
  const classes = useStyles();

  return (
    <Box className={classes.bgImage}>
      {/* MCC LOGO */}
      <Box width="253px">
        <img width="100%" src={MccVerticalLogo} />
      </Box>
      <Box
        bgcolor="#fff"
        borderRadius="16px"
        maxWidth="1250px"
        width="100%"
        boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
        height="611px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="8px"
        padding="2rem"
      >
        <Box maxWidth="555px">
          <img width="100%" src={integrationSuccessImage} />
        </Box>
        <Stack gap="2rem">
          <Box display="flex" alignItems="center" gap="12px">
            <Box
              width="47px"
              height="44px"
              borderRadius="4px"
              bgcolor="#F4F6F8"
              padding="4px"
            >
              <img
                width="100%"
                src={integrationImage}
                alt="integration image"
              />
            </Box>
            <IntegrationLinkGreyIcon />
            <Box
              width="42px"
              height="42px"
              borderRadius="4px"
              bgcolor="#F4F6F8"
              padding="4px"
            >
              <img width="100%" src={mccLogoIntegrationImage} alt="mcc logo" />
            </Box>
          </Box>
          <Stack gap="29px">
            <Typography variant="h1" fontSize="3.5rem" color="#124273">
              Congratulations!
            </Typography>
            <Typography
              variant="body2"
              fontSize="1.125rem"
              textTransform="capitalize"
              color="#124273"
            >
              Your Clio account has been successfully connected with MyCaseCare
            </Typography>
          </Stack>
        </Stack>
      </Box>
      {/* FOOTER */}
      <Box
        component="footer"
        display="flex"
        alignItems="center"
        justifyContent="center"
        gap="8px"
        fontSize="0.75rem"
      >
        <Typography
          variant="body2"
          color="rgba(0, 0, 0, 0.38)"
          fontSize="inherit"
        >
          ©2024 MyCaseCare
        </Typography>
        <Divider orientation="vertical" sx={{ height: "10px" }} />
        <Typography
          variant="body2"
          color="rgba(0, 0, 0, 0.38)"
          fontSize="inherit"
        >
          Contact Support
        </Typography>
        <Divider orientation="vertical" sx={{ height: "10px" }} />

        <Typography
          variant="body2"
          color="rgba(0, 0, 0, 0.38)"
          fontSize="inherit"
        >
          Privacy policy
        </Typography>
      </Box>
    </Box>
  );
};

export default IntegrationSuccessPage;
