import React from "react";

const IntakeQuestionWaitingIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M12.8346 7.00033C12.8346 10.2203 10.2213 12.8337 7.0013 12.8337C3.7813 12.8337 1.16797 10.2203 1.16797 7.00033C1.16797 3.78033 3.7813 1.16699 7.0013 1.16699C10.2213 1.16699 12.8346 3.78033 12.8346 7.00033Z"
        stroke="#ED7626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.16516 8.85503L7.35682 7.77586C7.04182 7.58919 6.78516 7.14003 6.78516 6.77253V4.38086"
        stroke="#ED7626"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IntakeQuestionWaitingIcon;
