import {
  ADD_PASSWORD_FAIL,
  ADD_PASSWORD_REQUEST,
  ADD_PASSWORD_SUCCESS,
  OTP_VERIFY_FAIL,
  OTP_VERIFY_REQUEST,
  OTP_VERIFY_SUCCESS,
  RESEND_CLIENT_CREDENTIALS_FAIL,
  RESEND_CLIENT_CREDENTIALS_REQUEST,
  RESEND_CLIENT_CREDENTIALS_SUCCESS,
  RESET_RESEND_CLIENT_CREDENTIALS,
  UPDATE_FORGOTTEN_PASSWORD_FAIL,
  UPDATE_FORGOTTEN_PASSWORD_REQUEST,
  UPDATE_FORGOTTEN_PASSWORD_SUCCESS,
  USER_FORGOT_FAIL,
  USER_FORGOT_REQUEST,
  USER_FORGOT_SUCCESS,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_LOGOUT,
  USER_REGISTER_FAIL,
  USER_REGISTER_REQUEST,
  USER_REGISTER_SUCCESS,
} from "../../assets/constants/userConstants";
import axios from "axios";
import { BASE_URL } from "..";
import Cookies from "js-cookie";
import {
  segmentIdentify,
  segmentTrack,
} from "../../utils/helpers/tracking_utils";
import { RESET_ALL_CLIENTS } from "../../assets/constants/agencyConstants";

// token, agent_id, agency_id

// ======================================== LOG IN REDUCER ========================================
export const login = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: USER_LOGIN_REQUEST,
    });

    const config = {
      headers: {
        "Content-type": "application/json",
        "Cache-Control": "no-cache",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/login`,
      formData,
      config
    );

    // if (!response?.data?.ok && response?.data?.statusCode === 401) {
    //   throw new Error("Email or password is incorrect!");
    // }

    // if (!response?.data?.ok) {
    //   console.log("RES", response);
    //   throw new Error(response?.data?.message);
    // }

    const payloadData = {
      token: response?.data?.token,
      agentId: response?.data?.agent?.id,
      agencyId: response?.data?.agent?.agency_id,
    };

    dispatch({
      type: USER_LOGIN_SUCCESS,
      payload: payloadData,
    });

    Cookies.set("token", response?.data?.token, { path: "/" });
    Cookies.set("agentId", response?.data?.agent?.id, { path: "/" });
    Cookies.set("agencyId", response?.data?.agent?.agency_id, { path: "/" });

    const agency_config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${response?.data?.token}`,
      },
    };

    const agency_response = await axios.get(
      `${BASE_URL}/agent/get-agency?agency_id=${response?.data?.agent?.agency_id}`,
      agency_config
    );

    if (agency_response?.data?.agency && response?.data?.agent) {
      segmentIdentify({
        agent: response?.data?.agent,
        agency: agency_response?.data?.agency,
      });
      segmentTrack({
        event: "logged_in",
        agent: response?.data?.agent,
        agency: agency_response?.data?.agency,
      });
    }

    // localStorage.setItem(
    //   "userInfo",
    //   JSON.stringify({
    //     token: response?.data?.token,
    //     agent_id: response?.data?.agent?.id,
    //     agency_id: response?.data?.agent?.agency_id,
    //     userEmail: response?.data?.agent?.email,
    //   })
    // );
  } catch (error) {
    dispatch({
      type: USER_LOGIN_FAIL,
      payload:
        error?.response &&
        error?.response?.status &&
        error?.response?.status === 404
          ? error?.message
          : error?.response?.status === 401
          ? "Email or password is incorrect!"
          : error?.message,
    });
  }
};

// ======================================== REGISTER ACTION ========================================
export const register = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_REGISTER_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/register-temp`,
      { agent_email: email },
      config
    );

    dispatch({ type: USER_REGISTER_SUCCESS, payload: response?.data });
  } catch (error) {
    dispatch({
      type: USER_REGISTER_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

// ================== USER LOGOUT =====================
export const logout = () => async (dispatch, getState) => {
  // localStorage.removeItem("userInfo");
  // localStorage.removeItem("agentToken");
  // localStorage.removeItem("agentId");
  const token = Cookies.get("token");
  const agency_id = Cookies.get("agencyId");

  Cookies.remove("token");
  Cookies.remove("agentId");
  Cookies.remove("agencyId");
  Cookies.remove("selectedIndex");
  dispatch({ type: USER_LOGOUT });
  dispatch({ type: RESET_ALL_CLIENTS });

  const agency_config = {
    headers: {
      "Content-Type": "application/json",
      "Cache-Control": "no-cache",
      Authorization: `Bearer ${token}`,
    },
  };

  const agency_response = await axios.get(
    `${BASE_URL}/agent/get-agency?agency_id=${agency_id}`,
    agency_config
  );

  const { agentProfile } = getState();
  const agent = agentProfile?.agentUser?.agent;

  if (agency_response?.data?.agency && agent) {
    segmentTrack({
      event: "logged_out",
      agent: agent,
      agency: agency_response?.data?.agency,
    });
  }
};

// ================= OTP VERIFY ACTION ==================
export const otpVerifyFunc = (email, otp) => async (dispatch) => {
  try {
    dispatch({ type: OTP_VERIFY_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/verify-otp`,
      { email, otp },
      config
    );

    dispatch({ type: OTP_VERIFY_SUCCESS, payload: response?.data });
    Cookies.set("token", response?.data?.token);
    Cookies.set("agentId", response?.data?.agent?.id);
    // Cookies.set("agencyId", null);
    // localStorage.setItem(
    //   "agentToken",
    //   JSON.stringify({
    //     token: response?.data?.token,
    //   })
    // );

    // localStorage.setItem(
    //   "agentId",
    //   JSON.stringify({
    //     agentId: response?.data?.agent?.id,
    //   })
    // );

    // dispatch({
    //   type: USER_LOGIN_SUCCESS,
    //   payload: response?.data,
    // });

    // localStorage.setItem(
    //   "userInfo",
    //   JSON.stringify({
    //     token: response?.data?.token,
    //     agent_id: response?.data?.agent?.id,
    //     agency_id: response?.data?.agent?.agency_id,
    //     userEmail: response?.data?.agent?.email,
    //   })
    // );
  } catch (error) {
    dispatch({
      type: OTP_VERIFY_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

// ======================================== FORGOT PASSWORD ACTION ========================================
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: USER_FORGOT_REQUEST });

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: "",
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/send-otp`,
      { email },
      config
    );

    // if (data.ok === false) {
    //   dispatch({
    //     type: USER_LOGIN_FAIL,
    //     payload: data?.message,
    //   });
    // }

    dispatch({ type: USER_FORGOT_SUCCESS, payload: response });
  } catch (error) {
    dispatch({
      type: USER_FORGOT_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

// ======================================== UPDATE FORGOT PASSWORD ACTION ========================================
export const updateForgotPasswordFunc =
  (email, password) => async (dispatch, getState) => {
    try {
      dispatch({ type: UPDATE_FORGOTTEN_PASSWORD_REQUEST });

      // const {
      //   userLogin: { userInfo },
      // } = getState();

      // const { token } = JSON.parse(localStorage.getItem("agentToken"));
      const token = Cookies.get("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/update-forgotten-password`,
        { email, password },
        config
      );

      // if (data.ok === false) {
      //   dispatch({
      //     type: USER_LOGIN_FAIL,
      //     payload: data?.message,
      //   });
      // }

      // console.log("Forgot", response);

      dispatch({ type: UPDATE_FORGOTTEN_PASSWORD_SUCCESS, payload: response });
    } catch (error) {
      dispatch({
        type: UPDATE_FORGOTTEN_PASSWORD_FAIL,
        payload:
          error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
      });
    }
  };

// ======================================== ADD PASSWORD ACTION ========================================
export const addpasswordFunc =
  (agent_id, password) => async (dispatch, getState) => {
    try {
      dispatch({ type: ADD_PASSWORD_REQUEST });

      // const {
      //   userLogin: { userInfo },
      // } = getState();

      // const agentToken = JSON.parse(localStorage.getItem("agentToken"));
      // console.log("TOKEN", agentToken);
      const token = Cookies.get("token");

      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const response = await axios.post(
        `${BASE_URL}/agent/add-password`,
        { agent_id, password },
        config
      );

      // if (data.ok === false) {
      //   dispatch({
      //     type: USER_LOGIN_FAIL,
      //     payload: data?.message,
      //   });
      // }

      dispatch({ type: ADD_PASSWORD_SUCCESS, payload: response });
    } catch (error) {
      dispatch({
        type: ADD_PASSWORD_FAIL,
        payload:
          error?.response && error?.response?.data?.message
            ? error?.response?.data?.message
            : error?.message,
      });
    }
  };

// ============= RESEND CLIENT CREDENTIALS ACTION ==========================
export const resendClientCredentialsFunc = (clientId) => async (dispatch) => {
  try {
    dispatch({ type: RESEND_CLIENT_CREDENTIALS_REQUEST });

    const token = Cookies.get("token");

    const config = {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    const response = await axios.post(
      `${BASE_URL}/agent/resend-client-credentials`,
      { client_id: clientId },
      config
    );

    if (!response?.data?.ok) {
      throw new Error(response?.data?.message);
    }

    dispatch({
      type: RESEND_CLIENT_CREDENTIALS_SUCCESS,
      payload: response?.data,
    });
  } catch (error) {
    dispatch({
      type: RESEND_CLIENT_CREDENTIALS_FAIL,
      payload:
        error?.response && error?.response?.data?.message
          ? error?.response?.data?.message
          : error?.message,
    });
  }
};

export const resetCreatePassword = () => async (dispatch) => {
  dispatch({ type: OTP_VERIFY_SUCCESS, payload: { ok: false } });
};

export const resetRegister = () => async (dispatch) => {
  dispatch({ type: USER_REGISTER_SUCCESS, payload: { ok: false } });
};

export const resetResendClientCredsState = () => ({
  type: RESET_RESEND_CLIENT_CREDENTIALS,
});

// ======================================== FORGOT EMAIL VERIFY ACTION ========================================
// export const forgotEmailVerify = (email, otp) => async (dispatch) => {
//   try {
//     dispatch({ type: FORGOT_EMAIL_VERIFY_REQUEST });

//     const config = {
//       headers: {
//         "Content-Type": "application/json",
//       },
//     };

//     const response = await axios.post(
//       `${BASE_URL}/agent/verify-otp`,
//       { email, otp },
//       config
//     );

//     dispatch({ type: FORGOT_EMAIL_VERIFY_SUCCESS, payload: response });
//   } catch (error) {
//     dispatch({
//       type: FORGOT_EMAIL_VERIFY_FAIL,
//       payload:
//         error?.response && error?.response?.data?.message
//           ? error?.response?.data?.message
//           : error?.message,
//     });
//   }
// };
