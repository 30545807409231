import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Typography,
  Grid,
  IconButton,
  InputAdornment,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

import Style from "./style";
import Button from "../../../../common/Button";
import Textfield from "../../../../common/Textfield";
import CardUI from "../../../../common/Card";
import PasswordConstraint from "../../../../common/PasswordConstraint";
import CircularProgressLoading from "../../../../common/CircularProgress";
import AlertMessage from "../../../../common/AlertMessage";
import Cookies from "js-cookie";
import { LoadingButton } from "@mui/lab";

const Styles = makeStyles((theme) => Style(theme));

const passwordRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;

export default function Security() {
  // var userInfo = localStorage.getItem("userInfo");

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);

  const agentToken = Cookies.get("token");
  const cookieAgentId = Cookies.get("agentId");
  const cookieAgencyId = Cookies.get("agencyId");

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    //   setAgencyID(userInfo.agency_id);
    //   setAgentID(userInfo.agent_id);
    // }

    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmedNewPassword, setShowConfirmedNewPassword] =
    useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmedNewPassword, setConfirmedNewPassword] = useState("");
  const [validPassword, setValidPassword] = useState(true);

  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [status, setStatus] = useState({ type: "", message: "" });
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClickShowPassword = (password_type) => {
    if (password_type == "current") {
      setShowCurrentPassword((show) => !show);
    } else if (password_type == "new") {
      setShowNewPassword((show) => !show);
    } else if (password_type == "confirmed_new") {
      setShowConfirmedNewPassword((show) => !show);
    }
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const newPasswordChangeHandler = (e) => {
    const newPswd = e.target.value;
    setNewPassword(newPswd);

    setValidPassword(passwordRegex.test(newPswd));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    setStatus({
      type: "",
      message: "",
    });

    setLoading(true);

    try {
      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };

      const payload = {
        agent_id: agentID,
        current: currentPassword,
        password: newPassword,
      };

      const { data } = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/agent/update-password`,
        payload,
        config
      );

      if (data?.ok) {
        setStatus({
          type: "success",
          message: "Your password has been updated successfully",
        });
        setCurrentPassword("");
        setNewPassword("");
        setConfirmedNewPassword("");
      } else {
        setStatus({
          type: "error",
          message: data.message,
        });
      }
      setLoading(false);
      handleAlertClick();
    } catch (error) {
      setStatus({
        type: "error",
        message:
          error.data && error.data.message ? error.data.message : error.message,
      });
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      !validPassword ||
      newPassword !== confirmedNewPassword ||
      newPassword.length < 8
    ) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [newPassword, confirmedNewPassword]);

  useEffect(() => {
    if (!token) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [token]);

  return (
    <>
      {!loading && (status?.type === "success" || status?.type === "error") && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}
      <Grid container pr={3}>
        <CardUI maxWidth="741px" dashboard="true" responsive="true" padding="32px">
          <Stack direction="column" component="form" gap="24px">
            <Typography
              variant="boxHeading"
              color="disabled.boxHeading"
              textTransform="uppercase"
            >
              Change password
            </Typography>

            <Textfield
              placeholder="Enter Password"
              margin="dense"
              label="Current Password"
              // error={isTouched && !!error}
              // helperText={error?.message}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("current")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showCurrentPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={currentPassword}
              name="password"
              onChange={(e) => setCurrentPassword(e.target.value)}
              type={showCurrentPassword ? "text" : "password"}
            />
            <Textfield
              placeholder="Enter Password"
              margin="dense"
              label="New Password"
              errorContent={!validPassword && "Enter valid password"}
              helperText={
                !validPassword &&
                "Password must be a combination of uppercase and lowercase characters, numbers and special characters(!, @, $, %, &, *, ?)"
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("new")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showNewPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              value={newPassword}
              name="newPassword"
              helperTextContent={<PasswordConstraint />}
              onChange={newPasswordChangeHandler}
              type={showNewPassword ? "text" : "password"}
            />
            <Textfield
              placeholder="Enter Password"
              margin="dense"
              label="Confirm New Password"
              errorContent={
                newPassword !== confirmedNewPassword &&
                "Password should matched"
              }
              helperText={
                newPassword !== confirmedNewPassword &&
                "Password does not matched"
              }
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => handleClickShowPassword("confirmed_new")}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                  >
                    {showConfirmedNewPassword ? (
                      <VisibilityOff />
                    ) : (
                      <Visibility />
                    )}
                  </IconButton>
                </InputAdornment>
              }
              value={confirmedNewPassword}
              name="confirmedNewPassword"
              onChange={(e) => setConfirmedNewPassword(e.target.value)}
              type={showConfirmedNewPassword ? "text" : "password"}
            />

            <Stack alignItems="flex-end">
              <LoadingButton
                color="primary"
                variant="contained"
                disabled={buttonDisabled}
                loading={loading}
                onClick={handleSubmit}
              >
                Save Changes
              </LoadingButton>
            </Stack>
          </Stack>
        </CardUI>
      </Grid>
    </>
  );
}
