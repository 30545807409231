import React, { useEffect, useRef, useState } from "react";
import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Textfield from "../../../common/Textfield";
import { UploadIcon } from "../../../assets/constants";
import HeaderText from "../../../common/HeaderText";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { makeStyles } from "@mui/styles";
import Style from "../style";
import axios from "axios";
import { fileTypes, formatSizeUnits } from "../../../utils/helpers";
import AlertMessage from "../../../common/AlertMessage";
import { LoadingButton } from "@mui/lab";
import Cookies from "js-cookie";
import {
  getAgencyDetailFunc,
  getClientDataFunc,
} from "../../../actions/Agency";
import { useDispatch, useSelector } from "react-redux";
import {
  segmentError,
  segmentTrack,
} from "../../../utils/helpers/tracking_utils";
import {
  getClientCasesDocsFunc,
  innerFolderViewFunc,
} from "../../../actions/DocumentsActions";
import { appInsights } from "../../../utils/helpers/AppInsights";

const Styles = makeStyles((theme) => Style(theme));

const UploadDocManually = ({
  agent,
  client,
  getClientsData,
  onClose,
  setOpen,
  selectedDocument,
}) => {
  const classes = Styles();
  const [documentName, setDocumentName] = useState("");
  const [files, setFiles] = useState([]);
  const [formData, setFormData] = useState(new FormData());
  // const [uploadedFiles, setUploadedFiles] = useState([]);
  const [showProgress, setShowProgress] = useState(false);
  //  const [error, setError] = useState(null);
  const [status, setStatus] = useState({ type: "", message: "" });
  const [loading, setLoading] = useState(false);
  const [documentType, setDocumentType] = useState("personal");

  const [alertOpen, setAlertOpen] = useState(false);

  const dispatch = useDispatch();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  const handleAlertClick = () => {
    setAlertOpen(true);
  };

  const handleAlertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };

  const fileInputRef = useRef(null);

  // var userInfo = localStorage.getItem("userInfo");
  const [token, setToken] = useState("");
  const agentToken = Cookies.get("token");
  // const cookieAgentId = Cookies.get("agentId");
  // const cookieAgencyId = Cookies.get("agencyId");

  useEffect(() => {
    // if (userInfo) {
    //   userInfo = JSON.parse(userInfo);
    //   setToken(userInfo.token);
    // }

    if (agentToken) {
      setToken(agentToken);
      // setAgentID(cookieAgentId);
      // setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const handleClickFile = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (e) => {
    const localFormData = new FormData();
    const localFiles = Array.from(e.target.files);
    formData.forEach((value, key) => {
      localFormData.append(key, value);
    });

    for (let i = 0; i < localFiles.length; i++) {
      localFormData.append("files", localFiles[i]);
    }

    setFiles((prevState) => [...prevState, ...localFiles]);
    setFormData(localFormData);
  };

  const submitDocumentHandler = async (e) => {
    e.preventDefault();

    if (!files.length) {
      setStatus({
        type: "error",
        message: "Please enter the document name and upload files!",
      });
      handleAlertClick();
      return;
    }

    setLoading(true);
    try {
      const config = {
        headers: {
          "Content-type": "multipart/form-data",
          Authorization: `Bearer ${token}`,
        },
      };

      const doc_type = "client_level";

      let res;

      if (selectedDocument) {
        formData.set("client_id", client?.id);
        formData.set("case_id", selectedDocument?.case_id);
        formData.set("folder_id", selectedDocument?.id);
        formData.set("file_type", selectedDocument?.folder_type);

        res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/storage/upload-files`,
          formData,
          config
        );
      } else {
        formData.set("client_id", client?.id);
        formData.set("case_id", 0);
        formData.set("file_type", doc_type);
        formData.set("folder_id", client?.root_folder_id);

        res = await axios.post(
          `${process.env.REACT_APP_BASE_URL}/storage/upload-files`,
          formData,
          config
        );
      }

      if (!res?.data?.ok) {
        appInsights.trackTrace({
          message: `Error while uploading documents in client: ${
            res?.data?.message || "Uploading documents failed in client!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_UPLOADING_DOCUMENTS_IN_CLIENT",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${
            res?.data?.message || "Error while uploading documents in client!"
          } `,
        });
        setStatus({
          type: "error",
          message: res?.data?.message,
        });
        handleAlertClick();
        setLoading(false);

        return;
      }

      setStatus({
        type: "success",
        message: `Files uploaded!`,
      });

      // dispatch(getClientDataFunc(client?.id));
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "document_uploaded",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }

      handleAlertClick();
      setLoading(false);
      setDocumentName("");
      setFiles([]);
      if (selectedDocument) {
        dispatch(innerFolderViewFunc(client?.id, selectedDocument?.id));
        dispatch(getClientCasesDocsFunc(client?.id));
      } else {
        dispatch(getClientCasesDocsFunc(client?.id));
      }
      setFormData(new FormData());

      onClose("right", false);
      setOpen(false);
    } catch (error) {
      appInsights.trackTrace({
        message: `Error while uploading documents in client: ${
          error || "Uploading documents failed in client!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_UPLOADING_DOCUMENTS_IN_CLIENT",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Error while uploading documents in client!"} `,
      });
      console.log(error);
      setStatus({
        type: "error",
        message:
          error.data && error.data.message ? error.data.message : error.message,
      });
      setLoading(false);
      onClose("right", false);
      setOpen(false);
    }
  };

  const deleteFileHandler = (idx, file) => {
    const names = formData.getAll("files");
    formData.delete("files");

    names
      .filter((name) => name.name !== file?.name)
      .forEach((name) => formData.append("files", name));

    setFiles(files.filter((_, i) => i !== idx));
  };

  return (
    <>
      {!loading && (status?.type === "success" || status?.type === "error") && (
        <AlertMessage
          message={status?.message}
          severity={status?.type}
          open={alertOpen}
          handleClose={handleAlertClose}
        />
      )}

      <Stack
        gap="24px"
        // position="relative"
        component="form"
        onSubmit={submitDocumentHandler}
        noValidate
        height="100%"
      >
        <Stack gap={3} flex="1">
          <Stack
            alignItems="center"
            justifyContent="center"
            gap="10px"
            sx={{ cursor: "pointer" }}
            border="1px dashed #566875"
            p="10px"
            height="301px"
            borderRadius="8px"
            onClick={handleClickFile}
          >
            <input
              type="file"
              style={{ display: "none" }}
              name="file"
              ref={fileInputRef}
              multiple
              onChange={handleFileChange}
              required
            />
            <UploadIcon />
            <HeaderText
              textContent="Select files"
              color="#000"
              variant="h6"
              fontSize="16px"
              fontWeight="400"
            />
            <Typography
              variant="h6"
              fontSize="14px"
              fontWeight="400"
              color="secondary"
            >
              Click{" "}
              <span style={{ color: "#0C80F3", textDecoration: "underline" }}>
                browse
              </span>{" "}
              through your computer
            </Typography>
          </Stack>

          <Stack gap="8px" pb="50px">
            {files?.map((file, i) => (
              <Box
                padding="12px"
                display="flex"
                alignItems="flex-start"
                bgcolor="#FCFCFC"
                gap="8px"
                key={i}
              >
                <Box
                  width="48px"
                  height="48px"
                  bgcolor="#fff"
                  borderRadius="8px"
                  padding="2px"
                >
                  <Avatar
                    src={fileTypes(file?.type)}
                    alt="client"
                    className={classes.icon}
                    variant="rounded"
                  />
                </Box>
                <Stack width="100%" gap="8px">
                  <Stack>
                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <HeaderText
                        textContent={file?.name}
                        fontSize="16px"
                        fontWeight="400"
                        color="#000"
                      />
                      <IconButton
                        size="small"
                        onClick={() => deleteFileHandler(i, file)}
                      >
                        <CancelOutlinedIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                  <HeaderText
                    textContent={formatSizeUnits(file.size)}
                    fontSize="12px"
                    fontWeight="400"
                    variant="body1"
                    textAlign="-webkit-auto"
                  />
                </Stack>
              </Box>
            ))}
          </Stack>
        </Stack>

        <Stack alignItems="flex-end" py={2}>
          <LoadingButton
            variant="contained"
            disabled={!files.length > 0}
            type="submit"
            loading={loading}
          >
            Upload Documents
          </LoadingButton>
        </Stack>
      </Stack>
    </>
  );
};

export default UploadDocManually;
