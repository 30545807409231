import React from "react";

const CaseLogEllipseIcon = ({ bgColor = "#0C80F3" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
    >
      <circle cx="5" cy="5" r="5" fill={bgColor} />
    </svg>
  );
};

export default CaseLogEllipseIcon;
