import { Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CallIcon from "../../icons/CallIcon";
import BannerCallicon from "../../icons/BannerCallicon";
import BannerEmailIcon from "../../icons/BannerEmailIcon";
import BannerWebsiteIcon from "../../icons/BannerWebsiteIcon";
import BannerAddressIcon from "../../icons/BannerAddressIcon";

const Styles = makeStyles((theme) => {
  return {
    backview_container: {
      borderRadius: "15px",
      overflow: "hidden",
      height: "164px",
      width: "343px",
      padding: "16px",
    },
  };
});

const BannerBackView = ({ backgroundColor }) => {
  const classes = Styles();

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgencyDetail,
    error: errorAgencyDetail,
    agencyDetail,
  } = getAgencyDetail;

  const {
    primary_contact_number,
    primary_email,
    website,
    address,
    city,
    region,
    zipcode,
  } = agencyDetail?.agency;

  return (
    <>
      <Stack
        className={classes.backview_container}
        bgcolor={backgroundColor}
        gap="10px"
        fontFamily="Montserrat"
        // justifyContent="center"
      >
        {primary_contact_number && (
          <Box display="flex" alignItems="flex-start" gap="8px">
            <BannerCallicon />
            <Typography
              color="#fff"
              variant="body2"
              fontWeight="500"
              fontFamily="inherit"
            >
              {primary_contact_number}
            </Typography>
          </Box>
        )}
        {primary_email && (
          <Box display="flex" alignItems="flex-start" gap="8px">
            <BannerEmailIcon />
            <Typography color="#fff" variant="body2" fontWeight="500">
              {primary_email}
            </Typography>
          </Box>
        )}
        {website && (
          <Box display="flex" alignItems="flex-start" gap="8px">
            <BannerWebsiteIcon />
            <Typography color="#fff" variant="body2" fontWeight="500">
              {website}
            </Typography>
          </Box>
        )}

        {(address || city || region || zipcode) && (
          <Box display="flex" alignItems="flex-start" gap="8px">
            <BannerAddressIcon />

            <Typography color="#fff" variant="body2" fontWeight="500">
              {address && address} {city && city}
              {region && ","} {region && region} {zipcode && zipcode}
            </Typography>
          </Box>
        )}
      </Stack>
      <Typography variant="body1" color="#0D0D0D" fontSize="12px">
        The details displayed in the banner above can be modified through the{" "}
        <Box
          component={Link}
          to="/settings"
          color="#0C80F3"
          sx={{ textDecoration: "underline", cursor: "pointer" }}
        >
          Law-firm settings
        </Box>{" "}
        Page.
      </Typography>
    </>
  );
};

export default BannerBackView;
