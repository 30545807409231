import React from "react";

const FolderGreyIcon = ({ color = "#566875" }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="vuesax/linear/folder-2">
        <g id="folder-2">
          <path
            id="Vector"
            d="M18.3337 9.1665V14.1665C18.3337 17.4998 17.5003 18.3332 14.167 18.3332H5.83366C2.50033 18.3332 1.66699 17.4998 1.66699 14.1665V5.83317C1.66699 2.49984 2.50033 1.6665 5.83366 1.6665H7.08366C8.33366 1.6665 8.60866 2.03317 9.08366 2.6665L10.3337 4.33317C10.6503 4.74984 10.8337 4.99984 11.667 4.99984H14.167C17.5003 4.99984 18.3337 5.83317 18.3337 9.1665Z"
            fill="white"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
          />
          <path
            id="Vector_2"
            d="M6.66699 1.6665H14.167C15.8337 1.6665 16.667 2.49984 16.667 4.1665V5.3165"
            stroke={color}
            strokeWidth="1.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default FolderGreyIcon;
