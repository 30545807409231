import React, { useEffect, useState } from "react";

import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";

import Style from "./style";
import PersonalDetails from "./Components/PersonalDetails";
import Security from "./Components/Security";
import { GeneralTabIcon, SecurityIcon } from "../../assets/constants";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyDetailFunc } from "../../actions/Agency";
import { segmentTrack } from "../../utils/helpers/tracking_utils";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, height: "100%" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Styles = makeStyles((theme) => Style(theme));

export default function Settings() {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState(0);

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "user_profile",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  const allTabs = [
    {
      icon: <GeneralTabIcon />,
      title: `Personal Details`,
      component: <PersonalDetails />,
    },
    {
      icon: <SecurityIcon />,
      title: "Security",
      component: <Security />,
    },
  ];
  const classes = Styles();
  return (
    <div>
      <Typography variant="h5">User Account</Typography>
      <Stack pt={2}>
        <Tabs
          className={clsx(classes.tabs__bar)}
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {allTabs?.map(
            (tab, key) =>
              tab.title && (
                <Tab
                  iconPosition="start"
                  icon={tab.icon}
                  label={tab.title}
                  key={tab.title}
                  {...a11yProps(key++)}
                />
              )
          )}
        </Tabs>
        <TabPanel
          value={activeTab}
          index={activeTab}
          className={clsx(classes.tab__panel)}
        >
          {allTabs[activeTab].component}
        </TabPanel>
      </Stack>
    </div>
  );
}
