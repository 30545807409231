import React from "react";

const IntegrationLinkGreyIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
    >
      <path
        d="M3.67875 14C2.79 12.9313 2.25 11.5588 2.25 10.0625C2.25 6.665 5.02875 3.875 8.4375 3.875H14.0625C17.46 3.875 20.25 6.665 20.25 10.0625C20.25 13.46 17.4712 16.25 14.0625 16.25H11.25"
        stroke="#5B6064"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M23.3213 14C24.21 15.0687 24.75 16.4412 24.75 17.9375C24.75 21.335 21.9712 24.125 18.5625 24.125H12.9375C9.54 24.125 6.75 21.335 6.75 17.9375C6.75 14.54 9.52875 11.75 12.9375 11.75H15.75"
        stroke="#5B6064"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default IntegrationLinkGreyIcon;
