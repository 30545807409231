const Style = (theme) => ({
  form__radio__group: {
    width: "100%",
    "& label": {
      marginLeft: 0,
      // marginBottom: "24px",
      width: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
  },
  drawer_width: {
    width: "748px !important",
    [theme.breakpoints.down("md")]: {
      width: "500px !important",
      padding: "1rem !important",
    },
  },
});
export default Style;
