import React, {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
} from "react";
import axios from "axios";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import {
  Stack,
  Typography,
  Box,
  Avatar,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Chip,
} from "@mui/material";
import { debounce } from "lodash";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import Style from "./style";
import InviteDrawer from "./InviteDrawer";
import Button from "../../common/Button";
import AddIcon from "@mui/icons-material/Add";
import CircularProgressLoading from "../../common/CircularProgress";
import CardUI from "../../common/Card";
import { DataGrid, GridActionsCellItem } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import AlertDialog from "../../components/AlertDialog";
import AvatarWithName from "../../components/AvatarWithName";
import HeaderText from "../../common/HeaderText";
import Cookies from "js-cookie";
import AvatarGroups from "../../components/AvatarGroups";
import EmptyBox from "../../assets/images/empty-box.png";
import { useDispatch, useSelector } from "react-redux";
import AlertMessage from "../../common/AlertMessage";
import { getAgencyDetailFunc } from "../../actions/Agency";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import SkeletonGrid from "../../components/SkeletonGrid";
import CaseTableSkeleton from "../../components/CaseTableSkeleton";

const Styles = makeStyles((theme) => Style(theme));

export default function Clients() {
  const classes = Styles();
  const dispatch = useDispatch();

  const [token, setToken] = useState("");
  const [agentID, setAgentID] = useState(0);
  const [agencyID, setAgencyID] = useState(0);
  const [error, setError] = useState(null);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalClients, setTotalClients] = useState(0);
  const [query, setQuery] = useState("");

  const [permissionMessage, setPermissionMessage] = useState(null);

  const [openAlert, setOpenAlert] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
  };

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "clients",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  useEffect(() => {
    const agentToken = Cookies.get("token");
    const cookieAgentId = Cookies.get("agentId");
    const cookieAgencyId = Cookies.get("agencyId");

    if (agentToken) {
      setToken(agentToken);
      setAgentID(cookieAgentId);
      setAgencyID(cookieAgencyId);
    }
  }, [token]);

  const agentUserRef = useRef();

  useEffect(() => {
    agentUserRef.current = agentUser;
  }, [agentUser]);

  const [open, setOpen] = useState(false);
  const [update, setUpdate] = useState(false);
  const [client, setClient] = useState({});
  const navigate = useNavigate();

  const overviewDetailHandler = (params) => {
    const access =
      agentUserRef.current?.agent?.permissions?.access_client_details?.read;
    if (!access) {
      setPermissionMessage("You do not have permission to view a client!");
      setOpenAlert(true);
      return;
    }

    setPermissionMessage(null);
    setOpenAlert(false);
    const currentRow = params?.row;
    navigate(`/client/${params?.id}`, {
      state: { rowData: currentRow },
    });
  };

  const [openModal, setOpenModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [submitDeleteID, setSubmitDeleteID] = useState(null);
  const [rowData, setRowData] = useState({});
  const [anchorRowEl, setAnchorRowEl] = useState(null);

  const modalOpenHandler = () => {
    setOpenModal(true);
  };

  const documentDeleteHandler = (item) => {
    const access =
      agentUserRef.current?.agent?.permissions?.access_client_details?.delete;
    if (!access) {
      setPermissionMessage("You do not have permission to delete a client!");
      setOpenAlert(true);
      return;
    } else {
      setOpenAlert(false);
      setPermissionMessage(null);
      setRowData(item);
      setModalContentData({
        title: "Are you sure?",
        subtitle:
          "This will delete all data and activity associated with this user.",
      });
      setSubmitDeleteID(`confirm-delete-client-${item?.client?.name}`);
      modalOpenHandler();
      setAnchorRowEl(null);

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "delete_client_clicked",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const modalCloseHandler = () => {
    setOpenModal(false);
  };

  // DELETING ROW DATA
  const clientDeleteHandler = async (clientId) => {
    try {
      setLoading(true);
      modalCloseHandler();

      const deletePayload = {
        client_id: clientId,
      };

      const config = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      };
      const res = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/agent/delete-client`,
        deletePayload,
        config
      );

      setLoading(false);

      setRowData({});
      getClientsList(query);
    } catch (error) {
      setLoading(false);
      console.log(error);
      modalCloseHandler();
      setRowData({});

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "client_deleted",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  const [loading, setLoading] = useState(true);
  const [clientsList, setClientsList] = useState([]);

  const getClientsList = useCallback(
    debounce(async (searchQuery) => {
      try {
        setLoading(true);
        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const { data } = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/agent/get-clients-list?agency_id=${agencyID}&page=${page}&pageSize=${pageSize}&search=${searchQuery}`,
          config
        );
        setClientsList(data?.clients_list);
        setTotalClients(data?.count);
        setLoading(false);
      } catch (error) {
        setError(
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message
        );
        setLoading(false);
        console.error(error);
      }
    }, 500),
    [agencyID, page, pageSize, token]
  );

  useEffect(() => {
    if (agencyID) {
      getClientsList(query);
    }
  }, [agencyID, page, pageSize, query, getClientsList]);

  const editClientHandler = (params) => {
    const access =
      agentUserRef.current?.agent?.permissions?.access_client_details?.edit;
    if (!access) {
      setPermissionMessage("You do not have permission to edit a client!");
      setOpenAlert(true);
      return;
    }

    setPermissionMessage(null);
    setClient(params.row.client);
    setUpdate(true);
    setOpen(true);
    setOpenAlert(false);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "edit_client_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const createClientHandler = () => {
    const access =
      agentUserRef.current?.agent?.permissions?.access_client_details?.create;
    if (!access) {
      setPermissionMessage("You do not have permission to create a client!");
      setOpenAlert(true);
      return;
    }

    setPermissionMessage(null);
    setOpenAlert(false);
    setUpdate(false);
    setOpen(true);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "new_client_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const rows = useMemo(() => {
    // if (!clientsList?.length) return [];
    if (clientsList?.length) {
      return (
        clientsList
          // .filter(
          //   ({ name, email }) =>
          //     name.search(regex) !== -1 || email.search(regex) !== -1
          // )
          ?.map((client) => ({
            id: client.id,
            name_with_picture: {
              name: client.name,
              profile_picture_url: client.profile_picture_url,
              origin: client.client_origin,
            },
            active_case: client.cases?.length
              ? client.cases[0].title
              : "Not found",
            email_address: client.email,
            assigned_to: client.assigned_to
              ? JSON.parse(client.assigned_to)
              : [],
            assigned_to_details: client.assigned_to_details,
            created_at: dayjs(client.created_at).format("DD MMM, hh:mm a"),
            client: client,
          }))
      );
    }
  }, [clientsList]);

  const columns = useMemo(
    () => [
      {
        field: "name_with_picture",
        headerName: "Client Name",
        width: 220,
        flex: 1,
        renderCell: ({ value }) => (
          <Box display="flex" alignItems="center" gap="0.5rem">
            {" "}
            {/* This Box will align items in a row with a small gap */}
            <AvatarWithName
              name={value.name}
              imageURL={value.profile_picture_url}
            />
            {value.origin === "clio" && (
              <Chip
                label="Clio"
                size="small"
                sx={{
                  fontSize: "0.75rem",
                  height: "auto",
                  padding: "2px 8px", // Adjust padding to match your design
                  backgroundColor: "#f5f5f5", // This is a guess based on your image
                  color: "#000000",
                  borderRadius: "10px", // Adjust border-radius to match your design
                  // More styling as needed
                }}
              />
            )}
          </Box>
        ),
        sortable: false,
      },
      {
        field: "email_address",
        headerName: "Email Address",
        width: 300,
        flex: 1,
      },
      {
        field: "active_case",
        headerName: "Active Case",
        width: 256,
        flex: 1,
      },
      {
        field: "assigned_to_details",
        headerName: "Assigned To",
        flex: 1,
        minWidth: 150,
        renderCell: ({ value }) => <AvatarGroups assigned_to_details={value} />,
        sortable: false,
      },
      {
        field: "actions",
        type: "actions",
        headerName: "Actions",
        headerAlign: "right",
        align: "right",
        width: 80,
        getActions: (params) => [
          <GridActionsCellItem
            icon={<EditOutlinedIcon />}
            label="Edit"
            onClick={() => editClientHandler(params)}
          />,
          <GridActionsCellItem
            id={`delete-client-${params.row?.client?.name}`}
            icon={<DeleteOutlinedIcon />}
            label="Delete"
            onClick={() => {
              documentDeleteHandler(params.row);
              // setRowData(params.row);

              // setTeam(params.row)
              // setOpen(true)
            }}
          />,
        ],
        sortable: false,
      },
    ],
    []
  );

  return (
    <Stack>
      {!loadingAgent && permissionMessage && (
        <AlertMessage
          message={permissionMessage}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}
      {!loadingAgent && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}
      {openModal && (
        <AlertDialog
          submitID={submitDeleteID}
          openModal={openModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          formSubmitFunction={() => clientDeleteHandler(rowData?.id)}
          color="error"
        />
      )}
      <Stack spacing={3}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <HeaderText textContent="Clients" fontSize="30px" />
          {totalClients > 0 && (
            <Button
              id="new-client-button"
              startIcon={<AddIcon />}
              title="Add New Client"
              color="primary"
              fontSize="15px"
              variant="contained"
              onClick={createClientHandler}
            />
          )}
        </Box>
        <InviteDrawer
          anchor={"right"}
          open={open}
          setOpen={setOpen}
          onClose={() => setOpen(false)}
          update={update}
          setUpdate={setUpdate}
          client={client}
          getClientsList={getClientsList}
        />

        {/* {!loading && clientsList?.length === 0 && (
          <CardUI maxWidth="100%" dashboard="true" responsive="true"></CardUI>
        )} */}

        <CardUI
          maxWidth="100%"
          dashboard="true"
          responsive="true"
          padding="32px"
          // height="648px"
        >
          <Stack
            justifyContent="space-between"
            alignItems="center"
            direction="row"
          >
            <Stack direction="row" gap="16px" alignItems="center">
              <Box display="flex" alignItems="center" gap="16px" width="100%">
                <FormControl size="small" variant="outlined">
                  <InputLabel htmlFor="search-agents">Search</InputLabel>
                  <OutlinedInput
                    id="search-agents"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    }
                    label="Search"
                    size="small"
                    sx={{ backgroundColor: "#FFF" }}
                  />
                </FormControl>
              </Box>
            </Stack>
          </Stack>

          {loading && clientsList?.length === 0 ? (
            <CaseTableSkeleton
              headers={[
                "Client Name",
                "Email Address",
                "Active Case",
                "Assigned to",
                "Actions",
              ]}
              numRows={5}
              cellWidths={[220, 300, 256, 150, 80]}
            />
          ) : clientsList?.length > 0 ? (
            <div style={{ height: 550, width: "100%", marginTop: "24px" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                onRowClick={overviewDetailHandler}
                rowCount={totalClients}
                pagination
                page={page - 1}
                pageSize={pageSize}
                paginationMode="server"
                onPageChange={(newPage) => setPage(newPage + 1)}
                onPageSizeChange={(newPageSize) => {
                  setPageSize(newPageSize);
                  setPage(1);
                }}
                sx={{
                  cursor: " pointer !important",
                  "&": { border: "none" },
                }}
                rowsPerPageOptions={[5, 10]}
                disableRowSelectionOnClick
                getRowId={(row) => row.id}
                // className={classes.dataGrid}
              />
            </div>
          ) : (
            <Stack
              alignItems="center"
              justifyContent="center"
              height="calc(100vh - 311px)"
            >
              <Avatar
                src={EmptyBox}
                alt="empty box"
                sx={{
                  width: "128px",
                  height: "128px",
                  paddingBottom: "24px",
                }}
              />
              <Typography variant="body1" color="secondary" pb={2}>
                All your Clients will show up here
              </Typography>
              <Button
                id="new-client-button"
                startIcon={<AddIcon />}
                title="New Client"
                color="primary"
                fontSize="15px"
                variant="contained"
                onClick={() => {
                  setUpdate(false);
                  setOpen(true);
                }}
              />
            </Stack>
          )}
        </CardUI>
      </Stack>
    </Stack>
  );
}
