import { Grid, Skeleton, Stack } from "@mui/material";
import React from "react";

const ClientOverviewSkeleton = () => {
  return (
    <Stack gap="32px">
      <Stack gap="16px">
        <Skeleton variant="rounded" width={143} height={28} />
        <Skeleton variant="rounded" width="100%" height={42} />
      </Stack>
      <Stack gap="16px">
        <Skeleton variant="rounded" width={143} height={28} />
        <Skeleton variant="rounded" width="100%" height={42} />
      </Stack>
      <Stack gap="16px">
        <Skeleton variant="rounded" width={143} height={28} />
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Stack gap="8px">
              <Skeleton variant="rounded" width={103} height={20} />
              <Skeleton variant="rounded" width="100%" height={20} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack gap="8px">
              <Skeleton variant="rounded" width={103} height={20} />
              <Skeleton variant="rounded" width="100%" height={20} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack gap="8px">
              <Skeleton variant="rounded" width={103} height={20} />
              <Skeleton variant="rounded" width="100%" height={20} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack gap="8px">
              <Skeleton variant="rounded" width={103} height={20} />
              <Skeleton variant="rounded" width="100%" height={20} />
            </Stack>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ClientOverviewSkeleton;
