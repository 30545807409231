import React from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Stack,
  Chip,
  Typography,
} from "@mui/material";
import IntegrationLinkIcon from "../../icons/IntegrationLinkIcon";
import CircularProgressLoading from "../../common/CircularProgress";

const IntegrationCard = ({
  title,
  integrationImage,
  integrationAuthLink,
  websiteLink,
  subheading,
  description,
  comingSoon,
  integrated,
  disconnectIntegrationHandler,
  loadingUpdateAgent,
}) => {
  const integrationHandler = () => {
    window.open(
      integrationAuthLink,
      "_blank"
    );
  };

  return (
    <Grid item xs={12} sm={6} lg={4} style={{ position: 'relative' }}>
      <Stack
        gap="1.5rem"
        bgcolor="#FFF"
        boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
        borderRadius="0.5rem"
        padding="1.5rem"
        width="auto"
        height='250px'
      >
        <Box display="flex" alignItems="flex-start" justifyContent="space-between" gap="1rem">
          <Box>
            <Box
              bgcolor="#F4F6F8"
              borderRadius="0.5rem"
              padding="0.375rem"
              width="61px"
              height="61px"
              display="flex"
              alignItems="center"
              justifyContent="center"
              maxWidth="100%"
            >
              <img
                width="44px"
                //  height="100%"
                src={integrationImage}
                alt="integration"
              />
            </Box>
            <Stack flex="1" gap="8px">
              <Typography variant="h6" fontSize="1.25rem">
                {title}
              </Typography>
              <Typography
                component="a"
                variant="h6"
                fontSize="1rem"
                href={websiteLink}
                target="_blank"
                color="rgba(0, 0, 0, 0.38)"
                sx={{ textDecoration: "underline" }}
              >
                {subheading}
              </Typography>
            </Stack>
          </Box>
          {integrated ? (
            loadingUpdateAgent ? (
              <CircularProgressLoading size={20} />
            ) : (
              <Button
                sx={{ fontWeight: 400 }}
                variant="text"
                onClick={disconnectIntegrationHandler}
              >
                Disconnect
              </Button>
            )
          ) : !comingSoon ? (
            <IconButton onClick={integrationHandler}>
              <IntegrationLinkIcon />
            </IconButton>
          ) : (<></>)}
        </Box>
        {comingSoon && (
          <Box sx={{ position: 'absolute', top: 24, right: 16 }}> 
            <Chip
              label="Coming Soon"
              variant="filled"
              sx={{
                backgroundColor: '#007FFF', 
                color: 'white',
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                borderBottomRightRadius: '7px', 
                borderBottomLeftRadius: '7px',
                fontWeight: 'bold',
                fontSize: '0.75rem',
                padding: '2px 8px', // Reduced vertical padding
                height: 'auto', // Height auto to adjust according to content
                boxShadow: 'none', // Remove the box-shadow if any
                '& .MuiChip-label': {
                  padding: '2px', // Reduced label padding to 0
                },
              }}
            />
          </Box>
        )}
        <Typography variant="body1">{description}</Typography>
      </Stack>
    </Grid>
  );
};

export default IntegrationCard;
