const Style = (theme) => ({
  fields__space: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column !important",
    },
    "& .control-width": {
      width: "48.7%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        margin: "0",
      },
      "& .react-tel-input": {
        // marginTop: ' !important',
        marginBottom: "3px !important",
      },
    },
  },
  banner__withName: {
    borderRadius: "10px",
    // border: "1px solid #242121",
    "& .main__container": {
      borderRadius: "10px",
    },
    "& .overlay": {
      display: "none",
      position: "absolute",
      top: "0",
      left: "0",
      width: "200px",
      height: "200px",
      borderRadius: "50%",
      backgroundColor: "rgba(0,0,0,.6)",
    },
    "&:hover": {
      "& .overlay": {
        display: "flex",
      },
    },
    "& svg": {
      "& path": {
        stroke: "#fff",
      },
    },
  },
  profile__main: {
    [theme.breakpoints.down("md")]: {
      "& .first__grid": {
        paddingRight: "0",
      },
      "& .second__grid": {
        paddingTop: "2rem",
      },
    },
  },
});
export default Style;
