import React from "react";
import { Pie } from "react-chartjs-2";

const CustomPieChart = ({ pieChartStatsState }) => {
  const worstPainEntries = pieChartStatsState?.worst_pain_entries || 0;
  const severePainEntries = pieChartStatsState?.severe_pain_entries || 0;
  const mildPainEntries = pieChartStatsState?.mild_pain_entries || 0;
  const noPainEntries = pieChartStatsState?.no_pain_entries || 0;

  const data = {
    datasets: [
      {
        label: "Overall Pain Statistics",
        data: [
          worstPainEntries,
          severePainEntries,
          mildPainEntries,
          noPainEntries,
        ],
        backgroundColor: ["#F45454", "#FFDD66", "#AAE77A", "#0C80F3"],
        // borderColor: [],
        // borderWidth: 1,
        // borderColor: "black",

        // tooltipLabel: (context) => {
        //   const labels = ["Worst Pain", "Severe Pain", "Mild Pain", "No Pain"];
        //   return labels[context.dataIndex];
        // },
        // hoverOffset: 10,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: true,
        position: "bottom",
      },
    },
  };

  //   const options = {
  //     responsive: true,
  //     scales: {
  //       y: {
  //         title: {
  //           display: true,
  //           text: "Pain Level",
  //         },
  //         beginAtZero: true,
  //         max: 6,
  //         ticks: {
  //           callback: (value, index, values) => {
  //             return value === 6 ? null : value;
  //           },
  //           stepSize: 1,
  //         },
  //       },
  //     },
  //     plugins: {
  //       legend: {
  //         position: "bottom",
  //         labels: {
  //           boxWidth: 15,
  //           boxHeight: 10,
  //           usePointStyle: true,
  //           pointStyle: "rectRounded",
  //           borderRadius: 11,
  //           color: "rgba(0, 0, 0, 0.38)",
  //           font: {
  //             size: 12,
  //           },
  //         },
  //         align: "end",
  //       },
  //     },
  //   };

  return (
    <Pie data={data} options={options} style={{ width: "100% !important" }} />
  );
};

export default CustomPieChart;
