export const statesWithCities = {
  "Alabama": [
    "Abbeville",
    "Adamsville",
    "Alabaster",
    "Albertville",
    "Alexander City",
    "Alexandria",
    "Aliceville",
    "Andalusia",
    "Anniston",
    "Arab",
    "Argo",
    "Ashford",
    "Ashland",
    "Ashville",
    "Athens",
    "Atmore",
    "Attalla",
    "Auburn",
    "Autauga County",
    "Baldwin County",
    "Ballplay",
    "Barbour County",
    "Bay Minette",
    "Bayou La Batre",
    "Bear Creek",
    "Berry",
    "Bessemer",
    "Bibb County",
    "Birmingham",
    "Blount County",
    "Blountsville",
    "Blue Ridge",
    "Boaz",
    "Brent",
    "Brewton",
    "Bridgeport",
    "Brighton",
    "Brook Highland",
    "Brookside",
    "Brookwood",
    "Brundidge",
    "Bullock County",
    "Butler",
    "Butler County",
    "Bynum",
    "Cahaba Heights",
    "Calera",
    "Calhoun County",
    "Camden",
    "Carbon Hill",
    "Carlisle-Rockledge",
    "Carrollton",
    "Cedar Bluff",
    "Center Point",
    "Centre",
    "Centreville",
    "Chalkville",
    "Chambers County",
    "Chatom",
    "Chelsea",
    "Cherokee",
    "Cherokee County",
    "Chickasaw",
    "Childersburg",
    "Chilton County",
    "Choccolocco",
    "Choctaw County",
    "Citronelle",
    "Clanton",
    "Clarke County",
    "Clay",
    "Clay County",
    "Clayton",
    "Cleburne County",
    "Cleveland",
    "Clio",
    "Coaling",
    "Coffee County",
    "Colbert County",
    "Collinsville",
    "Columbiana",
    "Concord",
    "Conecuh County",
    "Coosa County",
    "Coosada",
    "Cordova",
    "Cottonwood",
    "Covington County",
    "Cowarts",
    "Crenshaw County",
    "Creola",
    "Crossville",
    "Cullman",
    "Cullman County",
    "Dadeville",
    "Dale County",
    "Daleville",
    "Dallas County",
    "Danville",
    "Daphne",
    "Dauphin Island",
    "DeKalb County",
    "Deatsville",
    "Decatur",
    "Demopolis",
    "Dixiana",
    "Dora",
    "Dothan",
    "Double Springs",
    "East Brewton",
    "East Florence",
    "Eclectic",
    "Elba",
    "Elberta",
    "Elmore",
    "Elmore County",
    "Emerald Mountain",
    "Enterprise",
    "Escambia County",
    "Etowah County",
    "Eufaula",
    "Eutaw",
    "Evergreen",
    "Fairfield",
    "Fairhope",
    "Falkville",
    "Fayette",
    "Fayette County",
    "Fayetteville",
    "Flint City",
    "Flomaton",
    "Florala",
    "Florence",
    "Foley",
    "Forestdale",
    "Fort Deposit",
    "Fort Payne",
    "Fort Rucker",
    "Franklin County",
    "Frisco City",
    "Fultondale",
    "Fyffe",
    "Gadsden",
    "Gardendale",
    "Geneva",
    "Geneva County",
    "Georgiana",
    "Glencoe",
    "Good Hope",
    "Goodwater",
    "Gordo",
    "Grand Bay",
    "Grayson Valley",
    "Graysville",
    "Greene County",
    "Greensboro",
    "Greenville",
    "Grove Hill",
    "Guin",
    "Gulf Shores",
    "Guntersville",
    "Hackleburg",
    "Hale County",
    "Haleyville",
    "Hamilton",
    "Hanceville",
    "Harpersville",
    "Hartford",
    "Hartselle",
    "Harvest",
    "Hayden",
    "Hayneville",
    "Hazel Green",
    "Headland",
    "Heflin",
    "Helena",
    "Henagar",
    "Henry County",
    "Highland Lakes",
    "Hokes Bluff",
    "Holt",
    "Holtville",
    "Homewood",
    "Hoover",
    "Houston County",
    "Hueytown",
    "Huguley",
    "Huntsville",
    "Indian Springs Village",
    "Inverness",
    "Irondale",
    "Jackson",
    "Jackson County",
    "Jacksonville",
    "Jasper",
    "Jefferson County",
    "Jemison",
    "Kimberly",
    "Kinsey",
    "Ladonia",
    "Lafayette",
    "Lake Purdy",
    "Lake View",
    "Lamar County",
    "Lanett",
    "Lauderdale County",
    "Lawrence County",
    "Lee County",
    "Leeds",
    "Leesburg",
    "Level Plains",
    "Limestone County",
    "Lincoln",
    "Linden",
    "Lineville",
    "Lipscomb",
    "Livingston",
    "Locust Fork",
    "Lowndes County",
    "Loxley",
    "Luverne",
    "Macon County",
    "Madison",
    "Madison County",
    "Malvern",
    "Marbury",
    "Marengo County",
    "Margaret",
    "Marion",
    "Marion County",
    "Marshall County",
    "Meadowbrook",
    "Meridianville",
    "Midfield",
    "Midland City",
    "Mignon",
    "Millbrook",
    "Minor",
    "Mobile",
    "Mobile County",
    "Monroe County",
    "Monroeville",
    "Montevallo",
    "Montgomery",
    "Montgomery County",
    "Moody",
    "Moores Mill",
    "Morgan County",
    "Morris",
    "Moulton",
    "Moundville",
    "Mount Olive",
    "Mount Vernon",
    "Mountain Brook",
    "Munford",
    "Muscle Shoals",
    "New Brockton",
    "New Hope",
    "New Market",
    "Newton",
    "North Bibb",
    "Northport",
    "Odenville",
    "Ohatchee",
    "Oneonta",
    "Opelika",
    "Opp",
    "Orange Beach",
    "Owens Cross Roads",
    "Oxford",
    "Ozark",
    "Pelham",
    "Pell City",
    "Perry County",
    "Phenix City",
    "Phil Campbell",
    "Pickens County",
    "Piedmont",
    "Pike County",
    "Pike Road",
    "Pine Level",
    "Pinson",
    "Pleasant Grove",
    "Point Clear",
    "Prattville",
    "Priceville",
    "Prichard",
    "Ragland",
    "Rainbow City",
    "Rainsville",
    "Randolph County",
    "Red Bay",
    "Redstone Arsenal",
    "Reform",
    "Rehobeth",
    "Riverside",
    "Roanoke",
    "Robertsdale",
    "Rock Creek",
    "Rockford",
    "Rogersville",
    "Russell County",
    "Russellville",
    "Saint Clair County",
    "Saks",
    "Samson",
    "Saraland",
    "Sardis City",
    "Satsuma",
    "Scottsboro",
    "Selma",
    "Selmont-West Selmont",
    "Semmes",
    "Sheffield",
    "Shelby",
    "Shelby County",
    "Shoal Creek",
    "Slocomb",
    "Smiths Station",
    "Smoke Rise",
    "Southside",
    "Spanish Fort",
    "Springville",
    "Steele",
    "Stevenson",
    "Stewartville",
    "Sulligent",
    "Sumiton",
    "Summerdale",
    "Sumter County",
    "Sylacauga",
    "Sylvan Springs",
    "Sylvania",
    "Talladega",
    "Talladega County",
    "Tallapoosa County",
    "Tallassee",
    "Tarrant",
    "Taylor",
    "Theodore",
    "Thomasville",
    "Thorsby",
    "Tillmans Corner",
    "Town Creek",
    "Trinity",
    "Troy",
    "Trussville",
    "Tuscaloosa",
    "Tuscaloosa County",
    "Tuscumbia",
    "Tuskegee",
    "Underwood-Petersville",
    "Union Springs",
    "Uniontown",
    "Valley",
    "Valley Grande",
    "Vance",
    "Vandiver",
    "Vernon",
    "Vestavia Hills",
    "Vincent",
    "Walker County",
    "Warrior",
    "Washington County",
    "Weaver",
    "Webb",
    "Wedowee",
    "West Blocton",
    "West End-Cobb Town",
    "Westover",
    "Wetumpka",
    "Whitesboro",
    "Wilcox County",
    "Wilsonville",
    "Winfield",
    "Winston County",
    "Woodstock",
    "York"
  ],
  "Alaska": [
    "Akutan",
    "Aleutians East Borough",
    "Aleutians West Census Area",
    "Anchor Point",
    "Anchorage",
    "Anchorage Municipality",
    "Badger",
    "Barrow",
    "Bear Creek",
    "Bethel",
    "Bethel Census Area",
    "Big Lake",
    "Bristol Bay Borough",
    "Butte",
    "Chevak",
    "City and Borough of Wrangell",
    "Cohoe",
    "College",
    "Cordova",
    "Craig",
    "Deltana",
    "Denali Borough",
    "Diamond Ridge",
    "Dillingham",
    "Dillingham Census Area",
    "Dutch Harbor",
    "Eagle River",
    "Eielson Air Force Base",
    "Elmendorf Air Force Base",
    "Ester",
    "Fairbanks",
    "Fairbanks North Star Borough",
    "Farm Loop",
    "Farmers Loop",
    "Fishhook",
    "Fritz Creek",
    "Gateway",
    "Girdwood",
    "Haines",
    "Haines Borough",
    "Healy",
    "Homer",
    "Hoonah-Angoon Census Area",
    "Hooper Bay",
    "Houston",
    "Juneau",
    "Kalifornsky",
    "Kenai",
    "Kenai Peninsula Borough",
    "Ketchikan",
    "Ketchikan Gateway Borough",
    "King Cove",
    "Knik-Fairview",
    "Kodiak",
    "Kodiak Island Borough",
    "Kodiak Station",
    "Kotzebue",
    "Lake and Peninsula Borough",
    "Lakes",
    "Lazy Mountain",
    "Matanuska-Susitna Borough",
    "Meadow Lakes",
    "Metlakatla",
    "Nikiski",
    "Nome",
    "Nome Census Area",
    "North Pole",
    "North Slope Borough",
    "Northwest Arctic Borough",
    "Palmer",
    "Petersburg",
    "Petersburg Borough",
    "Prudhoe Bay",
    "Ridgeway",
    "Salcha",
    "Sand Point",
    "Seward",
    "Sitka",
    "Sitka City and Borough",
    "Skagway Municipality",
    "Soldotna",
    "Southeast Fairbanks Census Area",
    "Sterling",
    "Sutton-Alpine",
    "Tanaina",
    "Tok",
    "Unalaska",
    "Valdez",
    "Valdez-Cordova Census Area",
    "Wasilla",
    "Willow",
    "Wrangell",
    "Y",
    "Yakutat City and Borough",
    "Yukon-Koyukuk Census Area"
  ],
  "Arizona": [
    "Ahwatukee Foothills",
    "Ajo",
    "Alhambra",
    "Anthem",
    "Apache County",
    "Apache Junction",
    "Arivaca Junction",
    "Arizona City",
    "Avenue B and C",
    "Avondale",
    "Avra Valley",
    "Bagdad",
    "Beaver Dam",
    "Benson",
    "Big Park",
    "Bisbee",
    "Black Canyon City",
    "Blackwater",
    "Buckeye",
    "Bullhead City",
    "Bylas",
    "Cactus Flat",
    "Camp Verde",
    "Canyon Day",
    "Carefree",
    "Casa Blanca",
    "Casa Grande",
    "Casas Adobes",
    "Catalina",
    "Catalina Foothills",
    "Cave Creek",
    "Centennial Park",
    "Central City",
    "Central Heights-Midland City",
    "Chandler",
    "Chinle",
    "Chino Valley",
    "Cibecue",
    "Cienega Springs",
    "Citrus Park",
    "Clarkdale",
    "Claypool",
    "Clifton",
    "Cochise County",
    "Coconino County",
    "Colorado City",
    "Congress",
    "Coolidge",
    "Cordes Lakes",
    "Cornville",
    "Corona de Tucson",
    "Cottonwood",
    "Deer Valley",
    "Desert Hills",
    "Dewey-Humboldt",
    "Dilkon",
    "Dolan Springs",
    "Donovan Estates",
    "Douglas",
    "Drexel Heights",
    "Eagar",
    "East Sahuarita",
    "Ehrenberg",
    "El Mirage",
    "Eloy",
    "Encanto",
    "First Mesa",
    "Flagstaff",
    "Florence",
    "Flowing Wells",
    "Fort Defiance",
    "Fortuna Foothills",
    "Fountain Hills",
    "Fredonia",
    "Ganado",
    "Gila Bend",
    "Gila County",
    "Gilbert",
    "Glendale",
    "Globe",
    "Gold Camp",
    "Gold Canyon",
    "Golden Shores",
    "Golden Valley",
    "Goodyear",
    "Graham County",
    "Grand Canyon",
    "Grand Canyon Village",
    "Green Valley",
    "Greenlee County",
    "Guadalupe",
    "Heber-Overgaard",
    "Holbrook",
    "Houck",
    "Huachuca City",
    "Joseph City",
    "Kachina Village",
    "Kaibito",
    "Kayenta",
    "Kearny",
    "Kingman",
    "La Paz County",
    "Lake Havasu City",
    "Lake Montezuma",
    "Lake of the Woods",
    "Laveen",
    "LeChee",
    "Linden",
    "Litchfield Park",
    "Lukachukai",
    "Mammoth",
    "Many Farms",
    "Marana",
    "Maricopa",
    "Maricopa County",
    "Maryvale",
    "Mayer",
    "Meadview",
    "Mesa",
    "Mescal",
    "Miami",
    "Mohave County",
    "Mohave Valley",
    "Morenci",
    "Mountainaire",
    "Naco",
    "Navajo County",
    "New Kingman-Butler",
    "New River",
    "Nogales",
    "North Fork",
    "Oracle",
    "Oro Valley",
    "Page",
    "Paradise Valley",
    "Parker",
    "Parks",
    "Paulden",
    "Payson",
    "Peach Springs",
    "Peoria",
    "Peridot",
    "Phoenix",
    "Picture Rocks",
    "Pima",
    "Pima County",
    "Pinal County",
    "Pine",
    "Pinetop-Lakeside",
    "Pirtleville",
    "Prescott",
    "Prescott Valley",
    "Quartzsite",
    "Queen Creek",
    "Rio Rico",
    "Rio Verde",
    "Sacaton",
    "Saddle Brooke",
    "Safford",
    "Sahuarita",
    "Saint David",
    "Saint Johns",
    "Saint Michaels",
    "Salome",
    "San Carlos",
    "San Luis",
    "San Manuel",
    "San Tan Valley",
    "Santa Cruz County",
    "Scenic",
    "Scottsdale",
    "Sedona",
    "Sells",
    "Show Low",
    "Sierra Vista",
    "Sierra Vista Southeast",
    "Six Shooter Canyon",
    "Snowflake",
    "Somerton",
    "South Tucson",
    "Spring Valley",
    "Springerville",
    "Star Valley",
    "Summit",
    "Sun City",
    "Sun City West",
    "Sun Lakes",
    "Sun Valley",
    "Superior",
    "Surprise",
    "Swift Trail Junction",
    "Tanque Verde",
    "Taylor",
    "Tempe",
    "Tempe Junction",
    "Thatcher",
    "Three Points",
    "Tolleson",
    "Tombstone",
    "Tonto Basin",
    "Tortolita",
    "Tsaile",
    "Tuba City",
    "Tubac",
    "Tucson",
    "Tucson Estates",
    "Vail",
    "Valencia West",
    "Valle Vista",
    "Verde Village",
    "Village of Oak Creek (Big Park)",
    "Wellton",
    "West Sedona",
    "Whetstone",
    "White Mountain Lake",
    "Whiteriver",
    "Wickenburg",
    "Willcox",
    "Williams",
    "Williamson",
    "Willow Valley",
    "Window Rock",
    "Winslow",
    "Yavapai County",
    "Youngtown",
    "Yuma",
    "Yuma County"
  ],
  "Arkansas": [
    "Alexander",
    "Alma",
    "Arkadelphia",
    "Arkansas City",
    "Arkansas County",
    "Ash Flat",
    "Ashdown",
    "Ashley County",
    "Atkins",
    "Augusta",
    "Austin",
    "Bald Knob",
    "Barling",
    "Batesville",
    "Baxter County",
    "Bay",
    "Beebe",
    "Bella Vista",
    "Benton",
    "Benton County",
    "Bentonville",
    "Berryville",
    "Bethel Heights",
    "Blytheville",
    "Bono",
    "Boone County",
    "Booneville",
    "Bradley County",
    "Brinkley",
    "Brookland",
    "Bryant",
    "Bull Shoals",
    "Cabot",
    "Calhoun County",
    "Calico Rock",
    "Camden",
    "Caraway",
    "Carlisle",
    "Carroll County",
    "Cave City",
    "Cave Springs",
    "Cedarville",
    "Centerton",
    "Charleston",
    "Cherokee Village",
    "Chicot County",
    "Clarendon",
    "Clark County",
    "Clarksville",
    "Clay County",
    "Cleburne County",
    "Cleveland County",
    "Clinton",
    "Coal Hill",
    "Columbia County",
    "Conway",
    "Conway County",
    "Corning",
    "Craighead County",
    "Crawford County",
    "Crittenden County",
    "Cross County",
    "Crossett",
    "Dallas County",
    "Danville",
    "Dardanelle",
    "De Queen",
    "De Witt",
    "Decatur",
    "Dermott",
    "Des Arc",
    "Desha County",
    "Diaz",
    "Dierks",
    "Dover",
    "Drew County",
    "Dumas",
    "Earle",
    "East End",
    "El Dorado",
    "Elkins",
    "Elm Springs",
    "England",
    "Eudora",
    "Eureka Springs",
    "Fairfield Bay",
    "Farmington",
    "Faulkner County",
    "Fayetteville",
    "Flippin",
    "Fordyce",
    "Forrest City",
    "Fort Smith",
    "Franklin County",
    "Fulton County",
    "Garland County",
    "Gassville",
    "Gentry",
    "Gibson",
    "Glenwood",
    "Goshen",
    "Gosnell",
    "Grant County",
    "Gravel Ridge",
    "Gravette",
    "Green Forest",
    "Greenbrier",
    "Greene County",
    "Greenland",
    "Greenwood",
    "Gurdon",
    "Hamburg",
    "Hampton",
    "Harrisburg",
    "Harrison",
    "Haskell",
    "Hazen",
    "Heber Springs",
    "Helena",
    "Helena-West Helena",
    "Hempstead County",
    "Highland",
    "Holiday Island",
    "Hope",
    "Horatio",
    "Horseshoe Bend",
    "Hot Spring County",
    "Hot Springs",
    "Hot Springs National Park",
    "Hot Springs Village",
    "Howard County",
    "Hoxie",
    "Hughes",
    "Huntsville",
    "Independence County",
    "Izard County",
    "Jackson County",
    "Jacksonville",
    "Jasper",
    "Jefferson County",
    "Johnson",
    "Johnson County",
    "Jonesboro",
    "Judsonia",
    "Kensett",
    "Lafayette County",
    "Lake City",
    "Lake Hamilton",
    "Lake Village",
    "Lamar",
    "Landmark",
    "Lavaca",
    "Lawrence County",
    "Leachville",
    "Lee County",
    "Lepanto",
    "Lewisville",
    "Lincoln",
    "Lincoln County",
    "Little Flock",
    "Little River County",
    "Little Rock",
    "Little Rock Air Force Base",
    "Logan County",
    "London",
    "Lonoke",
    "Lonoke County",
    "Lowell",
    "Luxora",
    "Madison County",
    "Magnolia",
    "Malvern",
    "Manila",
    "Mansfield",
    "Marianna",
    "Marion",
    "Marion County",
    "Marked Tree",
    "Marmaduke",
    "Marshall",
    "Marvell",
    "Maumelle",
    "Mayflower",
    "McAlmont",
    "McCrory",
    "McGehee",
    "Melbourne",
    "Mena",
    "Midway",
    "Miller County",
    "Mineral Springs",
    "Mississippi County",
    "Monette",
    "Monroe County",
    "Montgomery County",
    "Monticello",
    "Morrilton",
    "Mount Ida",
    "Mountain Home",
    "Mountain View",
    "Mulberry",
    "Murfreesboro",
    "Nashville",
    "Nevada County",
    "Newark",
    "Newport",
    "Newton County",
    "North Crossett",
    "North Little Rock",
    "Ola",
    "Osceola",
    "Ouachita County",
    "Ozark",
    "Paragould",
    "Paris",
    "Parkin",
    "Pea Ridge",
    "Perry County",
    "Perryville",
    "Phillips County",
    "Piggott",
    "Pike County",
    "Pine Bluff",
    "Piney",
    "Pocahontas",
    "Poinsett County",
    "Polk County",
    "Pope County",
    "Pottsville",
    "Prairie County",
    "Prairie Creek",
    "Prairie Grove",
    "Prescott",
    "Pulaski County",
    "Randolph County",
    "Rector",
    "Redfield",
    "Rison",
    "Rockwell",
    "Rogers",
    "Russellville",
    "Saint Francis County",
    "Salem",
    "Saline County",
    "Scott County",
    "Searcy",
    "Searcy County",
    "Sebastian County",
    "Sevier County",
    "Shannon Hills",
    "Sharp County",
    "Sheridan",
    "Sherwood",
    "Siloam Springs",
    "Smackover",
    "Southside",
    "Springdale",
    "Stamps",
    "Star City",
    "Stone County",
    "Stuttgart",
    "Sulphur Springs",
    "Texarkana",
    "Tontitown",
    "Trumann",
    "Tuckerman",
    "Union County",
    "Van Buren",
    "Van Buren County",
    "Vilonia",
    "Waldo",
    "Waldron",
    "Walnut Ridge",
    "Ward",
    "Warren",
    "Washington County",
    "West Crossett",
    "West Fork",
    "West Helena",
    "West Memphis",
    "White County",
    "White Hall",
    "Woodruff County",
    "Wrightsville",
    "Wynne",
    "Yell County",
    "Yellville"
  ],
  "California": [
    "Acalanes Ridge",
    "Acton",
    "Adelanto",
    "Agoura",
    "Agoura Hills",
    "Agua Dulce",
    "Aguanga",
    "Ahwahnee",
    "Alameda",
    "Alameda County",
    "Alamo",
    "Albany",
    "Alhambra",
    "Aliso Viejo",
    "Allendale",
    "Alondra Park",
    "Alpaugh",
    "Alpine",
    "Alpine County",
    "Alta Sierra",
    "Altadena",
    "Alturas",
    "Alum Rock",
    "Amador County",
    "American Canyon",
    "Amesti",
    "Anaheim",
    "Anderson",
    "Angels Camp",
    "Angwin",
    "Antelope",
    "Antioch",
    "Anza",
    "Apple Valley",
    "Aptos",
    "Aptos Hills-Larkin Valley",
    "Arbuckle",
    "Arcadia",
    "Arcata",
    "Arden-Arcade",
    "Armona",
    "Arnold",
    "Aromas",
    "Arroyo Grande",
    "Artesia",
    "Arvin",
    "Ashland",
    "Atascadero",
    "Atherton",
    "Atwater",
    "Auberry",
    "Auburn",
    "Auburn Lake Trails",
    "August",
    "Avalon",
    "Avenal",
    "Avila Beach",
    "Avocado Heights",
    "Azusa",
    "Bakersfield",
    "Baldwin Park",
    "Banning",
    "Barstow",
    "Barstow Heights",
    "Bay Point",
    "Bayside",
    "Bayview",
    "Beale Air Force Base",
    "Bear Valley Springs",
    "Beaumont",
    "Bell",
    "Bell Gardens",
    "Bella Vista",
    "Bellflower",
    "Belmont",
    "Belvedere",
    "Ben Lomond",
    "Benicia",
    "Berkeley",
    "Bermuda Dunes",
    "Berry Creek",
    "Bertsch-Oceanview",
    "Bethel Island",
    "Beverly Hills",
    "Big Bear City",
    "Big Bear Lake",
    "Big Pine",
    "Big River",
    "Biggs",
    "Biola",
    "Bishop",
    "Black Point-Green Point",
    "Blackhawk",
    "Bloomington",
    "Blue Lake",
    "Blythe",
    "Bodega Bay",
    "Bodfish",
    "Bolinas",
    "Bonadelle Ranchos-Madera Ranchos",
    "Bonita",
    "Bonny Doon",
    "Bonsall",
    "Boonville",
    "Boron",
    "Boronda",
    "Borrego Springs",
    "Bostonia",
    "Boulder Creek",
    "Boyes Hot Springs",
    "Boyle Heights",
    "Bradbury",
    "Brawley",
    "Brea",
    "Brentwood",
    "Bret Harte",
    "Bridgeport",
    "Brisbane",
    "Broadmoor",
    "Brookdale",
    "Brooktrails",
    "Buckhorn",
    "Buellton",
    "Buena Park",
    "Buena Vista",
    "Burbank",
    "Burlingame",
    "Burney",
    "Butte County",
    "Buttonwillow",
    "Byron",
    "Bystrom",
    "Cabazon",
    "Calabasas",
    "Calaveras County",
    "Calexico",
    "California City",
    "Calimesa",
    "Calipatria",
    "Calistoga",
    "Callender",
    "Camarillo",
    "Cambria",
    "Cambrian Park",
    "Cameron Park",
    "Camino",
    "Camp Meeker",
    "Camp Pendleton North",
    "Camp Pendleton South",
    "Campbell",
    "Campo",
    "Canoga Park",
    "Canyon Lake",
    "Capitola",
    "Carlsbad",
    "Carmel Valley Village",
    "Carmel-by-the-Sea",
    "Carmichael",
    "Carpinteria",
    "Carson",
    "Caruthers",
    "Casa Conejo",
    "Casa de Oro-Mount Helix",
    "Castaic",
    "Castro Valley",
    "Castroville",
    "Cathedral City",
    "Cayucos",
    "Cedar Ridge",
    "Central Valley (historical)",
    "Century City",
    "Ceres",
    "Cerritos",
    "Challenge-Brownsville",
    "Channel Islands Beach",
    "Charter Oak",
    "Chatsworth",
    "Cherry Valley",
    "Cherryland",
    "Chester",
    "Chico",
    "China Lake Acres",
    "Chinatown",
    "Chino",
    "Chino Hills",
    "Chowchilla",
    "Chualar",
    "Chula Vista",
    "Citrus",
    "Citrus Heights",
    "City and County of San Francisco",
    "Claremont",
    "Clay",
    "Clayton",
    "Clear Lake Riviera",
    "Clearlake",
    "Clearlake Oaks",
    "Cloverdale",
    "Clovis",
    "Coachella",
    "Coalinga",
    "Coarsegold",
    "Cobb",
    "Colfax",
    "Collierville",
    "Colma",
    "Colton",
    "Columbia",
    "Colusa",
    "Colusa County",
    "Commerce",
    "Compton",
    "Concord",
    "Contra Costa Centre",
    "Contra Costa County",
    "Cool",
    "Copperopolis",
    "Corcoran",
    "Corning",
    "Corona",
    "Coronado",
    "Corralitos",
    "Corte Madera",
    "Costa Mesa",
    "Cotati",
    "Coto De Caza",
    "Cottonwood",
    "Country Club",
    "Covelo",
    "Covina",
    "Crescent City",
    "Crest",
    "Crestline",
    "Crockett",
    "Cudahy",
    "Culver City",
    "Cupertino",
    "Cutler",
    "Cutten",
    "Cypress",
    "Daly City",
    "Dana Point",
    "Danville",
    "Davis",
    "Day Valley",
    "Deer Park",
    "Del Aire",
    "Del Mar",
    "Del Monte Forest",
    "Del Norte County",
    "Del Rey",
    "Del Rey Oaks",
    "Del Rio",
    "Delano",
    "Delhi",
    "Denair",
    "Descanso",
    "Desert Edge",
    "Desert Hot Springs",
    "Desert Shores",
    "Desert View Highlands",
    "Diablo",
    "Diamond Bar",
    "Diamond Springs",
    "Dinuba",
    "Discovery Bay",
    "Dixon",
    "Dixon Lane-Meadow Creek",
    "Dogtown",
    "Dollar Point",
    "Dos Palos",
    "Downey",
    "Downieville",
    "Duarte",
    "Dublin",
    "Dunnigan",
    "Dunsmuir",
    "Durham",
    "Earlimart",
    "East Foothills",
    "East Hemet",
    "East La Mirada",
    "East Los Angeles",
    "East Oakdale",
    "East Palo Alto",
    "East Pasadena",
    "East Porterville",
    "East Quincy",
    "East Rancho Dominguez",
    "East Richmond Heights",
    "East San Gabriel",
    "East Sonora",
    "Easton",
    "Eastvale",
    "Echo Park",
    "Edwards Air Force Base",
    "El Cajon",
    "El Centro",
    "El Cerrito",
    "El Cerrito Corona",
    "El Dorado County",
    "El Dorado Hills",
    "El Granada",
    "El Monte",
    "El Rio",
    "El Segundo",
    "El Sobrante",
    "El Verano",
    "Eldridge",
    "Elk Grove",
    "Elkhorn",
    "Elverta",
    "Emerald Lake Hills",
    "Emeryville",
    "Empire",
    "Encinitas",
    "Encino",
    "Escalon",
    "Escondido",
    "Esparto",
    "Eucalyptus Hills",
    "Eureka",
    "Exeter",
    "Fair Oaks",
    "Fairbanks Ranch",
    "Fairfax",
    "Fairfield",
    "Fairmead",
    "Fairview",
    "Fallbrook",
    "Farmersville",
    "Felton",
    "Ferndale",
    "Fetters Hot Springs-Agua Caliente",
    "Fillmore",
    "Firebaugh",
    "Florence-Graham",
    "Florin",
    "Folsom",
    "Fontana",
    "Foothill Farms",
    "Foothill Ranch",
    "Ford City",
    "Forest Meadows",
    "Forest Ranch",
    "Foresthill",
    "Forestville",
    "Fort Bragg",
    "Fort Irwin",
    "Fortuna",
    "Foster City",
    "Fountain Valley",
    "Fowler",
    "Frazier Park",
    "Freedom",
    "Fremont",
    "French Camp",
    "Fresno",
    "Fresno County",
    "Fruitridge Pocket",
    "Fullerton",
    "Galt",
    "Garden Acres",
    "Garden Grove",
    "Gardena",
    "Garnet",
    "Georgetown",
    "Gerber",
    "Gilroy",
    "Glen Avon",
    "Glendale",
    "Glendora",
    "Glenn County",
    "Gold River",
    "Golden Hills",
    "Goleta",
    "Gonzales",
    "Good Hope",
    "Goshen",
    "Grand Terrace",
    "Granite Bay",
    "Granite Hills",
    "Grass Valley",
    "Graton",
    "Green Acres",
    "Green Valley",
    "Greenacres",
    "Greenfield",
    "Greenville",
    "Gridley",
    "Grover Beach",
    "Guadalupe",
    "Guerneville",
    "Gustine",
    "Hacienda Heights",
    "Half Moon Bay",
    "Hamilton City",
    "Hanford",
    "Happy Camp",
    "Harbison Canyon",
    "Hartley",
    "Hawaiian Gardens",
    "Hawthorne",
    "Hayfork",
    "Hayward",
    "Healdsburg",
    "Heber",
    "Hemet",
    "Herald",
    "Hercules",
    "Hermosa Beach",
    "Hesperia",
    "Hidden Hills",
    "Hidden Meadows",
    "Hidden Valley Lake",
    "Highgrove",
    "Highland",
    "Highlands-Baywood Park",
    "Hillsborough",
    "Hilmar-Irwin",
    "Hollister",
    "Hollywood",
    "Holtville",
    "Home Garden",
    "Home Gardens",
    "Homeland",
    "Hughson",
    "Humboldt County",
    "Humboldt Hill",
    "Huntington Beach",
    "Huntington Park",
    "Huron",
    "Hydesville",
    "Idyllwild",
    "Idyllwild-Pine Cove",
    "Imperial",
    "Imperial Beach",
    "Imperial County",
    "Indian Wells",
    "Indio",
    "Inglewood",
    "Interlaken",
    "Inverness",
    "Inyo County",
    "Inyokern",
    "Ione",
    "Irvine",
    "Irwindale",
    "Isla Vista",
    "Ivanhoe",
    "Jackson",
    "Jamestown",
    "Jamul",
    "Janesville",
    "Johnstonville",
    "Joshua Tree",
    "Julian",
    "Jurupa Valley",
    "Kelseyville",
    "Kennedy",
    "Kensington",
    "Kentfield",
    "Kenwood",
    "Kerman",
    "Kern County",
    "Kernville",
    "Kettleman City",
    "Keyes",
    "King City",
    "Kings Beach",
    "Kings County",
    "Kingsburg",
    "Knightsen",
    "Koreatown",
    "La Cañada Flintridge",
    "La Crescenta-Montrose",
    "La Habra",
    "La Habra Heights",
    "La Jolla",
    "La Mesa",
    "La Mirada",
    "La Palma",
    "La Presa",
    "La Puente",
    "La Quinta",
    "La Riviera",
    "La Selva Beach",
    "La Verne",
    "Ladera",
    "Ladera Heights",
    "Ladera Ranch",
    "Lafayette",
    "Laguna",
    "Laguna Beach",
    "Laguna Hills",
    "Laguna Niguel",
    "Laguna Woods",
    "Lagunitas-Forest Knolls",
    "Lake Arrowhead",
    "Lake County",
    "Lake Elsinore",
    "Lake Forest",
    "Lake Isabella",
    "Lake Los Angeles",
    "Lake Nacimiento",
    "Lake San Marcos",
    "Lake Wildwood",
    "Lake of the Pines",
    "Lakeland Village",
    "Lakeport",
    "Lakeside",
    "Lakeview",
    "Lakewood",
    "Lamont",
    "Lancaster",
    "Larkfield-Wikiup",
    "Larkspur",
    "Las Flores",
    "Las Lomas",
    "Lassen County",
    "Lathrop",
    "Laton",
    "Lawndale",
    "Laytonville",
    "Le Grand",
    "Lebec",
    "Lemon Grove",
    "Lemoore",
    "Lemoore Station",
    "Lennox",
    "Lenwood",
    "Leona Valley",
    "Lewiston",
    "Lexington Hills",
    "Lincoln",
    "Lincoln Village",
    "Linda",
    "Linden",
    "Lindsay",
    "Littlerock",
    "Live Oak",
    "Livermore",
    "Livingston",
    "Lockeford",
    "Lodi",
    "Loma Linda",
    "Loma Rica",
    "Lomita",
    "Lompico",
    "Lompoc",
    "London",
    "Lone Pine",
    "Long Beach",
    "Loomis",
    "Los Alamitos",
    "Los Alamos",
    "Los Altos",
    "Los Altos Hills",
    "Los Angeles",
    "Los Angeles County",
    "Los Banos",
    "Los Gatos",
    "Los Molinos",
    "Los Olivos",
    "Los Osos",
    "Los Serranos",
    "Lost Hills",
    "Lower Lake",
    "Loyola",
    "Lucas Valley-Marinwood",
    "Lucerne",
    "Lucerne Valley",
    "Lynwood",
    "Madera",
    "Madera Acres",
    "Madera County",
    "Magalia",
    "Malibu",
    "Mammoth Lakes",
    "Manhattan Beach",
    "Manteca",
    "March Air Force Base",
    "Maricopa",
    "Marin City",
    "Marin County",
    "Marina",
    "Marina del Rey",
    "Mariposa",
    "Mariposa County",
    "Martinez",
    "Marysville",
    "Matheny",
    "Maxwell",
    "Mayflower Village",
    "Maywood",
    "McCloud",
    "McFarland",
    "McKinleyville",
    "Mead Valley",
    "Meadow Vista",
    "Meadowbrook",
    "Mecca",
    "Meiners Oaks",
    "Mendocino County",
    "Mendota",
    "Menifee",
    "Menlo Park",
    "Mentone",
    "Merced",
    "Merced County",
    "Mesa Verde",
    "Middletown",
    "Midpines",
    "Midway City",
    "Mill Valley",
    "Millbrae",
    "Milpitas",
    "Minkler",
    "Mira Mesa",
    "Mira Monte",
    "Mission Canyon",
    "Mission District",
    "Mission Hills",
    "Mission Viejo",
    "Modesto",
    "Modoc County",
    "Mojave",
    "Mono County",
    "Mono Vista",
    "Monrovia",
    "Montague",
    "Montalvin",
    "Montara",
    "Montclair",
    "Monte Rio",
    "Monte Sereno",
    "Montebello",
    "Montecito",
    "Monterey",
    "Monterey County",
    "Monterey Park",
    "Monument Hills",
    "Moorpark",
    "Morada",
    "Moraga",
    "Moreno Valley",
    "Morgan Hill",
    "Morongo Valley",
    "Morro Bay",
    "Moss Beach",
    "Mount Hermon",
    "Mount Shasta",
    "Mountain House",
    "Mountain Ranch",
    "Mountain View",
    "Mountain View Acres",
    "Murphys",
    "Murrieta",
    "Murrieta Hot Springs",
    "Muscoy",
    "Myrtletown",
    "Napa",
    "Napa County",
    "National City",
    "Needles",
    "Nevada City",
    "Nevada County",
    "Newark",
    "Newcastle",
    "Newman",
    "Newport Beach",
    "Nice",
    "Niland",
    "Nipomo",
    "Noe Valley",
    "Norco",
    "North Auburn",
    "North Edwards",
    "North El Monte",
    "North Fair Oaks",
    "North Highlands",
    "North Hills",
    "North Hollywood",
    "North Lakeport",
    "North Richmond",
    "North Tustin",
    "Northridge",
    "Norwalk",
    "Novato",
    "Nuevo",
    "Oak Hills",
    "Oak Park",
    "Oak View",
    "Oakdale",
    "Oakhurst",
    "Oakland",
    "Oakley",
    "Oasis",
    "Occidental",
    "Oceano",
    "Oceanside",
    "Oildale",
    "Ojai",
    "Old Fig Garden",
    "Olivehurst",
    "Ontario",
    "Opal Cliffs",
    "Orange",
    "Orange County",
    "Orange Cove",
    "Orangevale",
    "Orcutt",
    "Orinda",
    "Orland",
    "Orosi",
    "Oroville",
    "Oroville East",
    "Oxnard",
    "Pacheco",
    "Pacific Grove",
    "Pacifica",
    "Pajaro",
    "Palermo",
    "Palm Desert",
    "Palm Springs",
    "Palmdale",
    "Palo Alto",
    "Palo Cedro",
    "Palos Verdes Estates",
    "Paradise",
    "Paramount",
    "Parksdale",
    "Parkway",
    "Parkwood",
    "Parlier",
    "Pasadena",
    "Pasatiempo",
    "Paso Robles",
    "Patterson",
    "Patterson Tract",
    "Pedley",
    "Penn Valley",
    "Penngrove",
    "Perris",
    "Petaluma",
    "Phelan",
    "Phoenix Lake",
    "Pico Rivera",
    "Piedmont",
    "Pine Grove",
    "Pine Hills",
    "Pine Mountain Club",
    "Pine Valley",
    "Pinole",
    "Pioneer",
    "Piru",
    "Pismo Beach",
    "Pittsburg",
    "Pixley",
    "Piñon Hills",
    "Placentia",
    "Placer County",
    "Placerville",
    "Planada",
    "Pleasant Hill",
    "Pleasanton",
    "Plumas County",
    "Plumas Lake",
    "Pollock Pines",
    "Pomona",
    "Poplar-Cotton Center",
    "Port Hueneme",
    "Porterville",
    "Portola",
    "Portola Hills",
    "Portola Valley",
    "Poway",
    "Prunedale",
    "Quail Valley",
    "Quartz Hill",
    "Quincy",
    "Rainbow",
    "Ramona",
    "Rancho Calaveras",
    "Rancho Cordova",
    "Rancho Cucamonga",
    "Rancho Mirage",
    "Rancho Murieta",
    "Rancho Palos Verdes",
    "Rancho Penasquitos",
    "Rancho San Diego",
    "Rancho Santa Fe",
    "Rancho Santa Margarita",
    "Rancho Tehama Reserve",
    "Red Bluff",
    "Red Corral",
    "Redding",
    "Redlands",
    "Redondo Beach",
    "Redway",
    "Redwood City",
    "Redwood Shores",
    "Redwood Valley",
    "Reedley",
    "Rialto",
    "Richgrove",
    "Richmond",
    "Ridgecrest",
    "Ridgemark",
    "Rio Del Mar",
    "Rio Dell",
    "Rio Linda",
    "Rio Vista",
    "Ripon",
    "Riverbank",
    "Riverdale",
    "Riverdale Park",
    "Riverside",
    "Riverside County",
    "Rocklin",
    "Rodeo",
    "Rohnert Park",
    "Rolling Hills",
    "Rolling Hills Estates",
    "Rollingwood",
    "Romoland",
    "Rosamond",
    "Rosedale",
    "Roseland",
    "Rosemead",
    "Rosemont",
    "Roseville",
    "Ross",
    "Rossmoor",
    "Rowland Heights",
    "Rubidoux",
    "Running Springs",
    "Sacramento",
    "Sacramento County",
    "Saint Helena",
    "Salida",
    "Salinas",
    "Salton City",
    "San Andreas",
    "San Anselmo",
    "San Antonio Heights",
    "San Benito County",
    "San Bernardino",
    "San Bernardino County",
    "San Bruno",
    "San Carlos",
    "San Clemente",
    "San Diego",
    "San Diego Country Estates",
    "San Diego County",
    "San Dimas",
    "San Fernando",
    "San Francisco",
    "San Gabriel",
    "San Jacinto",
    "San Joaquin",
    "San Joaquin County",
    "San Joaquin Hills",
    "San Jose",
    "San Juan Bautista",
    "San Juan Capistrano",
    "San Leandro",
    "San Lorenzo",
    "San Luis Obispo",
    "San Luis Obispo County",
    "San Marcos",
    "San Marino",
    "San Martin",
    "San Mateo",
    "San Mateo County",
    "San Miguel",
    "San Pablo",
    "San Pasqual",
    "San Pedro",
    "San Rafael",
    "San Ramon",
    "Sanger",
    "Santa Ana",
    "Santa Barbara",
    "Santa Barbara County",
    "Santa Clara",
    "Santa Clara County",
    "Santa Clarita",
    "Santa Cruz",
    "Santa Cruz County",
    "Santa Fe Springs",
    "Santa Margarita",
    "Santa Maria",
    "Santa Monica",
    "Santa Paula",
    "Santa Rosa",
    "Santa Susana",
    "Santa Venetia",
    "Santa Ynez",
    "Santee",
    "Saranap",
    "Saratoga",
    "Saticoy",
    "Sausalito",
    "Scotts Valley",
    "Sea Ranch",
    "Seacliff",
    "Seal Beach",
    "Searles Valley",
    "Seaside",
    "Sebastopol",
    "Sedco Hills",
    "Seeley",
    "Selma",
    "Seven Trees",
    "Shackelford",
    "Shadow Hills",
    "Shafter",
    "Shandon",
    "Shasta",
    "Shasta County",
    "Shasta Lake",
    "Sheridan",
    "Sherman Oaks",
    "Shingle Springs",
    "Shingletown",
    "Sierra County",
    "Sierra Madre",
    "Signal Hill",
    "Silver Lake",
    "Silver Lakes",
    "Simi Valley",
    "Siskiyou County",
    "Sky Valley",
    "Sleepy Hollow",
    "Soda Bay",
    "Solana Beach",
    "Solano County",
    "Soledad",
    "Solvang",
    "Sonoma",
    "Sonoma County",
    "Sonora",
    "Soquel",
    "Sorrento Valley",
    "Soulsbyville",
    "South Dos Palos",
    "South El Monte",
    "South Gate",
    "South Lake Tahoe",
    "South Oroville",
    "South Pasadena",
    "South San Francisco",
    "South San Gabriel",
    "South San Jose Hills",
    "South Taft",
    "South Whittier",
    "South Yuba City",
    "Spring Valley",
    "Spring Valley Lake",
    "Squaw Valley",
    "Stallion Springs",
    "Stanford",
    "Stanislaus County",
    "Stanton",
    "Stevenson Ranch",
    "Stockton",
    "Stratford",
    "Strathmore",
    "Strawberry",
    "Studio City",
    "Suisun",
    "Summerland",
    "Sun City",
    "Sun Village",
    "Sunland",
    "Sunnyside",
    "Sunnyside-Tahoe City",
    "Sunnyslope",
    "Sunnyvale",
    "Susanville",
    "Sutter",
    "Sutter County",
    "Sutter Creek",
    "Taft",
    "Taft Heights",
    "Taft Mosswood",
    "Tahoe Vista",
    "Tahoma",
    "Talmage",
    "Tamalpais Valley",
    "Tamalpais-Homestead Valley",
    "Tara Hills",
    "Tarpey Village",
    "Tehachapi",
    "Tehama County",
    "Temecula",
    "Temelec",
    "Temple City",
    "Templeton",
    "Terra Bella",
    "Teviston",
    "Thermal",
    "Thermalito",
    "Thornton",
    "Thousand Oaks",
    "Thousand Palms",
    "Three Rivers",
    "Tiburon",
    "Tierra Buena",
    "Tipton",
    "Topanga",
    "Toro Canyon",
    "Torrance",
    "Trabuco Canyon",
    "Tracy",
    "Trinity County",
    "Truckee",
    "Tujunga",
    "Tulare",
    "Tulare County",
    "Tuolumne City",
    "Tuolumne County",
    "Turlock",
    "Tustin",
    "Twain Harte",
    "Twentynine Palms",
    "Twin Lakes",
    "Ukiah",
    "Union City",
    "Universal City",
    "Upland",
    "Upper Lake",
    "Vacaville",
    "Val Verde",
    "Valencia",
    "Valinda",
    "Valle Vista",
    "Vallejo",
    "Valley Center",
    "Valley Glen",
    "Valley Springs",
    "Van Nuys",
    "Vandenberg Air Force Base",
    "Vandenberg Village",
    "Venice",
    "Ventura",
    "Ventura County",
    "Victorville",
    "View Park-Windsor Hills",
    "Villa Park",
    "Vincent",
    "Vine Hill",
    "Vineyard",
    "Visalia",
    "Visitacion Valley",
    "Vista",
    "Vista Santa Rosa",
    "Waldon",
    "Walnut",
    "Walnut Creek",
    "Walnut Grove",
    "Walnut Park",
    "Wasco",
    "Waterford",
    "Watsonville",
    "Weaverville",
    "Weed",
    "Weedpatch",
    "Weldon",
    "West Athens",
    "West Bishop",
    "West Carson",
    "West Covina",
    "West Hills",
    "West Hollywood",
    "West Menlo Park",
    "West Modesto",
    "West Park",
    "West Puente Valley",
    "West Rancho Dominguez",
    "West Sacramento",
    "West Whittier-Los Nietos",
    "Westhaven-Moonstone",
    "Westlake Village",
    "Westminster",
    "Westmont",
    "Westmorland",
    "Westwood",
    "Wheatland",
    "Whittier",
    "Wildomar",
    "Williams",
    "Willits",
    "Willow Creek",
    "Willowbrook",
    "Willows",
    "Wilton",
    "Winchester",
    "Windsor",
    "Winter Gardens",
    "Winters",
    "Winton",
    "Wofford Heights",
    "Woodacre",
    "Woodbridge",
    "Woodcrest",
    "Woodlake",
    "Woodland",
    "Woodland Hills",
    "Woodside",
    "Woodville",
    "Wrightwood",
    "Yolo County",
    "Yorba Linda",
    "Yosemite Lakes",
    "Yosemite Valley",
    "Yountville",
    "Yreka",
    "Yuba City",
    "Yuba County",
    "Yucaipa",
    "Yucca Valley"
  ],
  "Colorado": [
    "Acres Green",
    "Adams County",
    "Air Force Academy",
    "Akron",
    "Alamosa",
    "Alamosa County",
    "Alamosa East",
    "Applewood",
    "Arapahoe County",
    "Archuleta County",
    "Aristocrat Ranchettes",
    "Arvada",
    "Aspen",
    "Ault",
    "Aurora",
    "Avon",
    "Baca County",
    "Basalt",
    "Battlement Mesa",
    "Bayfield",
    "Bennett",
    "Bent County",
    "Berkley",
    "Berthoud",
    "Black Forest",
    "Boulder",
    "Boulder County",
    "Breckenridge",
    "Brighton",
    "Broomfield",
    "Broomfield County",
    "Brush",
    "Buena Vista",
    "Burlington",
    "Byers",
    "Campion",
    "Carbondale",
    "Carriage Club",
    "Cascade-Chipita Park",
    "Castle Pines",
    "Castle Pines North",
    "Castle Rock",
    "Castlewood",
    "Cañon City",
    "Cedaredge",
    "Centennial",
    "Center",
    "Central City",
    "Chaffee County",
    "Cherry Creek",
    "Cherry Hills Village",
    "Cheyenne County",
    "Cheyenne Wells",
    "Cimarron Hills",
    "Clear Creek County",
    "Clifton",
    "Coal Creek",
    "Colorado City",
    "Colorado Springs",
    "Columbine",
    "Columbine Valley",
    "Commerce City",
    "Conejos",
    "Conejos County",
    "Cortez",
    "Costilla County",
    "Craig",
    "Creede",
    "Crested Butte",
    "Cripple Creek",
    "Crowley County",
    "Custer County",
    "Dacono",
    "Del Norte",
    "Delta",
    "Delta County",
    "Denver",
    "Denver County",
    "Derby",
    "Dolores County",
    "Douglas County",
    "Dove Creek",
    "Dove Valley",
    "Durango",
    "Eads",
    "Eagle",
    "Eagle County",
    "Eaton",
    "Edgewater",
    "Edwards",
    "El Jebel",
    "El Paso County",
    "Elbert County",
    "Elizabeth",
    "Ellicott",
    "Englewood",
    "Erie",
    "Estes Park",
    "Evans",
    "Evergreen",
    "Fairplay",
    "Federal Heights",
    "Firestone",
    "Florence",
    "Fort Carson",
    "Fort Collins",
    "Fort Lupton",
    "Fort Morgan",
    "Fountain",
    "Fowler",
    "Fraser",
    "Frederick",
    "Fremont County",
    "Frisco",
    "Fruita",
    "Fruitvale",
    "Garfield County",
    "Genesee",
    "Georgetown",
    "Gilcrest",
    "Gilpin County",
    "Glendale",
    "Gleneagle",
    "Glenwood Springs",
    "Golden",
    "Granby",
    "Grand County",
    "Grand Junction",
    "Greeley",
    "Greenwood Village",
    "Gunbarrel",
    "Gunnison",
    "Gunnison County",
    "Gypsum",
    "Hayden",
    "Highlands Ranch",
    "Hinsdale County",
    "Holly Hills",
    "Holyoke",
    "Hot Sulphur Springs",
    "Hudson",
    "Huerfano County",
    "Hugo",
    "Idaho Springs",
    "Indian Hills",
    "Inverness",
    "Jackson County",
    "Jefferson County",
    "Johnstown",
    "Julesburg",
    "Keenesburg",
    "Ken Caryl",
    "Kersey",
    "Keystone",
    "Kiowa",
    "Kiowa County",
    "Kit Carson County",
    "Kittredge",
    "Kremmling",
    "La Junta",
    "La Plata County",
    "La Salle",
    "Lafayette",
    "Lake City",
    "Lake County",
    "Lakewood",
    "Lamar",
    "Laporte",
    "Larimer County",
    "Las Animas",
    "Las Animas County",
    "Leadville",
    "Leadville North",
    "Limon",
    "Lincoln County",
    "Lincoln Park",
    "Littleton",
    "Lochbuie",
    "Logan County",
    "Loma",
    "Lone Tree",
    "Longmont",
    "Louisville",
    "Loveland",
    "Lyons",
    "Mancos",
    "Manitou Springs",
    "Mead",
    "Meeker",
    "Meridian",
    "Mesa County",
    "Milliken",
    "Mineral County",
    "Minturn",
    "Moffat County",
    "Monte Vista",
    "Montezuma County",
    "Montrose",
    "Montrose County",
    "Monument",
    "Morgan County",
    "Mountain Village",
    "Nederland",
    "New Castle",
    "Niwot",
    "Northglenn",
    "Olathe",
    "Orchard City",
    "Orchard Mesa",
    "Ordway",
    "Otero County",
    "Ouray",
    "Ouray County",
    "Pagosa Springs",
    "Palisade",
    "Palmer Lake",
    "Paonia",
    "Parachute",
    "Park County",
    "Parker",
    "Penrose",
    "Perry Park",
    "Phillips County",
    "Pitkin County",
    "Platteville",
    "Ponderosa Park",
    "Prowers County",
    "Pueblo",
    "Pueblo County",
    "Pueblo West",
    "Rangely",
    "Redlands",
    "Rifle",
    "Rio Blanco County",
    "Rio Grande County",
    "Rocky Ford",
    "Routt County",
    "Roxborough Park",
    "Saguache",
    "Saguache County",
    "Salida",
    "San Juan County",
    "San Luis",
    "San Miguel County",
    "Security-Widefield",
    "Sedgwick County",
    "Severance",
    "Shaw Heights",
    "Sheridan",
    "Sherrelwood",
    "Silt",
    "Silverthorne",
    "Silverton",
    "Snowmass Village",
    "Southglenn",
    "Springfield",
    "Steamboat Springs",
    "Sterling",
    "Stonegate",
    "Strasburg",
    "Stratmoor",
    "Summit County",
    "Superior",
    "Teller County",
    "Telluride",
    "The Pinery",
    "Thornton",
    "Todd Creek",
    "Towaoc",
    "Trinidad",
    "Twin Lakes",
    "Upper Bear Creek",
    "Vail",
    "Walden",
    "Walsenburg",
    "Washington County",
    "Welby",
    "Weld County",
    "Wellington",
    "West Pleasant View",
    "Westcliffe",
    "Westminster",
    "Wheat Ridge",
    "Windsor",
    "Woodland Park",
    "Woodmoor",
    "Wray",
    "Yuma",
    "Yuma County"
  ],
  "Connecticut": [
    "Ansonia",
    "Baltic",
    "Bethel",
    "Bethlehem Village",
    "Blue Hills",
    "Branford",
    "Branford Center",
    "Bridgeport",
    "Bristol",
    "Byram",
    "Canaan",
    "Canton Valley",
    "Central Waterford",
    "Cheshire",
    "Cheshire Village",
    "Chester Center",
    "City of Milford (balance)",
    "Clinton",
    "Colchester",
    "Collinsville",
    "Conning Towers-Nautilus Park",
    "Cos Cob",
    "Coventry Lake",
    "Cromwell",
    "Crystal Lake",
    "Danbury",
    "Danielson",
    "Darien",
    "Deep River Center",
    "Derby",
    "Durham",
    "East Brooklyn",
    "East Haddam",
    "East Hampton",
    "East Hartford",
    "East Haven",
    "East Norwalk",
    "East Windsor",
    "Easton",
    "Ellington",
    "Enfield",
    "Essex Village",
    "Fairfield",
    "Fairfield County",
    "Farmington",
    "Gales Ferry",
    "Georgetown",
    "Glastonbury",
    "Glastonbury Center",
    "Glenville",
    "Greenwich",
    "Groton",
    "Guilford",
    "Guilford Center",
    "Hamden",
    "Hartford",
    "Hartford County",
    "Hazardville",
    "Hebron",
    "Heritage Village",
    "Higganum",
    "Jewett City",
    "Kensington",
    "Kent",
    "Killingly Center",
    "Lake Pocotopaug",
    "Ledyard",
    "Lisbon",
    "Litchfield",
    "Litchfield County",
    "Long Hill",
    "Madison",
    "Madison Center",
    "Manchester",
    "Mansfield City",
    "Meriden",
    "Middlebury",
    "Middlesex County",
    "Middletown",
    "Milford",
    "Montville Center",
    "Moodus",
    "Moosup",
    "Mystic",
    "Naugatuck",
    "New Britain",
    "New Canaan",
    "New Fairfield",
    "New Hartford Center",
    "New Haven",
    "New Haven County",
    "New London",
    "New London County",
    "New Milford",
    "New Preston",
    "Newington",
    "Newtown",
    "Niantic",
    "Noank",
    "North Branford",
    "North Granby",
    "North Grosvenor Dale",
    "North Haven",
    "North Stamford",
    "Northwest Harwinton",
    "Norwalk",
    "Norwich",
    "Oakville",
    "Old Greenwich",
    "Old Mystic",
    "Old Saybrook",
    "Old Saybrook Center",
    "Orange",
    "Oxford",
    "Oxoboxo River",
    "Pawcatuck",
    "Pemberwick",
    "Plainfield",
    "Plainfield Village",
    "Plainville",
    "Plymouth",
    "Poquonock Bridge",
    "Portland",
    "Preston City",
    "Prospect",
    "Putnam",
    "Quinebaug",
    "Ridgefield",
    "Riverside",
    "Rockville",
    "Salem",
    "Salmon Brook",
    "Saybrook Manor",
    "Seymour",
    "Shelton",
    "Sherman",
    "Sherwood Manor",
    "Simsbury Center",
    "Somers",
    "South Coventry",
    "South Windham",
    "South Windsor",
    "South Woodstock",
    "Southbury",
    "Southport",
    "Southwood Acres",
    "Stafford",
    "Stafford Springs",
    "Stamford",
    "Storrs",
    "Stratford",
    "Suffield Depot",
    "Tariffville",
    "Terramuggus",
    "Terryville",
    "Thomaston",
    "Thompson",
    "Thompsonville",
    "Tolland",
    "Tolland County",
    "Torrington",
    "Trumbull",
    "Uncasville",
    "Wallingford",
    "Wallingford Center",
    "Washington",
    "Waterbury",
    "Waterford",
    "Watertown",
    "Wauregan",
    "Weatogue",
    "West Hartford",
    "West Haven",
    "West Simsbury",
    "West Torrington",
    "Westbrook Center",
    "Westport",
    "Wethersfield",
    "Willimantic",
    "Wilton",
    "Winchester Center",
    "Windham",
    "Windham County",
    "Windsor",
    "Windsor Locks",
    "Winsted",
    "Wolcott",
    "Woodbridge",
    "Woodbury",
    "Woodbury Center",
    "Woodmont"
  ],
  "Delaware": [
    "Bear",
    "Bellefonte",
    "Bethany Beach",
    "Blades",
    "Bridgeville",
    "Brookside",
    "Camden",
    "Cheswold",
    "Claymont",
    "Clayton",
    "Delaware City",
    "Delmar",
    "Dover",
    "Dover Base Housing",
    "Edgemoor",
    "Elsmere",
    "Felton",
    "Georgetown",
    "Glasgow",
    "Greenville",
    "Greenwood",
    "Harrington",
    "Highland Acres",
    "Hockessin",
    "Kent Acres",
    "Kent County",
    "Laurel",
    "Lewes",
    "Long Neck",
    "Middletown",
    "Milford",
    "Millsboro",
    "Milton",
    "New Castle",
    "New Castle County",
    "Newark",
    "Newport",
    "North Star",
    "Ocean View",
    "Pike Creek",
    "Pike Creek Valley",
    "Rehoboth Beach",
    "Rising Sun-Lebanon",
    "Riverview",
    "Rodney Village",
    "Seaford",
    "Selbyville",
    "Smyrna",
    "Sussex County",
    "Townsend",
    "Wilmington",
    "Wilmington Manor",
    "Woodside East",
    "Wyoming"
  ],
  "District of Columbia": [
    "Adams Morgan",
    "Bloomingdale",
    "Chevy Chase",
    "Shaw",
    "Washington, D.C."
  ],
  "Florida": [
    "Aberdeen",
    "Alachua",
    "Alachua County",
    "Alafaya",
    "Allapattah",
    "Altamonte Springs",
    "Alturas",
    "Alva",
    "Andover",
    "Anna Maria",
    "Apalachicola",
    "Apollo Beach",
    "Apopka",
    "Arcadia",
    "Archer",
    "Asbury Lake",
    "Astatula",
    "Astor",
    "Atlantic Beach",
    "Atlantis",
    "Auburndale",
    "Aventura",
    "Avon Park",
    "Azalea Park",
    "Babson Park",
    "Bagdad",
    "Baker County",
    "Bal Harbour",
    "Baldwin",
    "Balm",
    "Bartow",
    "Bay County",
    "Bay Harbor Islands",
    "Bay Hill",
    "Bay Pines",
    "Bayonet Point",
    "Bayshore Gardens",
    "Beacon Square",
    "Bee Ridge",
    "Bellair-Meadowbrook Terrace",
    "Belle Glade",
    "Belle Glade Camp",
    "Belle Isle",
    "Belleair",
    "Belleair Beach",
    "Belleair Bluffs",
    "Belleview",
    "Bellview",
    "Beverly Hills",
    "Big Coppitt Key",
    "Big Pine Key",
    "Biscayne Park",
    "Bithlo",
    "Black Diamond",
    "Bloomingdale",
    "Blountstown",
    "Boca Del Mar",
    "Boca Pointe",
    "Boca Raton",
    "Bokeelia",
    "Bonifay",
    "Bonita Springs",
    "Boulevard Gardens",
    "Bowling Green",
    "Boyette",
    "Boynton Beach",
    "Bradenton",
    "Bradenton Beach",
    "Bradford County",
    "Brandon",
    "Brent",
    "Brevard County",
    "Bristol",
    "Broadview Park",
    "Bronson",
    "Brookridge",
    "Brooksville",
    "Broward County",
    "Broward Estates",
    "Brownsville",
    "Buckhead Ridge",
    "Buckingham",
    "Buenaventura Lakes",
    "Bunche Park",
    "Bunnell",
    "Burnt Store Marina",
    "Bushnell",
    "Butler Beach",
    "Calhoun County",
    "Callahan",
    "Callaway",
    "Campbell",
    "Cantonment",
    "Cape Canaveral",
    "Cape Coral",
    "Carol City",
    "Carrabelle",
    "Carrollwood",
    "Carrollwood Village",
    "Carver Ranches",
    "Casselberry",
    "Cedar Grove",
    "Celebration",
    "Center Hill",
    "Century",
    "Charlotte County",
    "Charlotte Harbor",
    "Charlotte Park",
    "Chattahoochee",
    "Cheval",
    "Chiefland",
    "Chipley",
    "Christmas",
    "Chuluota",
    "Citra",
    "Citrus County",
    "Citrus Hills",
    "Citrus Park",
    "Citrus Ridge",
    "Citrus Springs",
    "Clarcona",
    "Clay County",
    "Clearwater",
    "Clermont",
    "Cleveland",
    "Clewiston",
    "Cocoa",
    "Cocoa Beach",
    "Cocoa West",
    "Coconut Creek",
    "Coconut Grove",
    "Collier County",
    "Columbia County",
    "Combee Settlement",
    "Connerton",
    "Conway",
    "Cooper City",
    "Coral Gables",
    "Coral Springs",
    "Coral Terrace",
    "Cortez",
    "Country Club",
    "Country Walk",
    "Crawfordville",
    "Crescent City",
    "Crestview",
    "Crooked Lake Park",
    "Cross City",
    "Crystal Lake",
    "Crystal River",
    "Crystal Springs",
    "Cudjoe Key",
    "Cutler",
    "Cutler Bay",
    "Cutler Ridge",
    "Cypress Gardens",
    "Cypress Lake",
    "Cypress Quarters",
    "Dade City",
    "Dade City North",
    "Dania Beach",
    "Davenport",
    "Davie",
    "Daytona Beach",
    "Daytona Beach Shores",
    "De Land Southwest",
    "De Leon Springs",
    "DeBary",
    "DeFuniak Springs",
    "DeLand",
    "DeSoto County",
    "Deerfield Beach",
    "Delray Beach",
    "Deltona",
    "Desoto Lakes",
    "Destin",
    "Dixie County",
    "Doctor Phillips",
    "Doral",
    "Dover",
    "Dundee",
    "Dunedin",
    "Dunnellon",
    "Duval County",
    "Eagle Lake",
    "East Bronson",
    "East Lake",
    "East Lake-Orient Park",
    "East Milton",
    "East Naples",
    "East Palatka",
    "East Pensacola Heights",
    "East Perrine",
    "Eastpoint",
    "Eatonville",
    "Edgewater",
    "Edgewood",
    "Eglin Air Force Base",
    "Eglin Village",
    "Egypt Lake-Leto",
    "El Portal",
    "Elfers",
    "Ellenton",
    "Englewood",
    "Ensley",
    "Escambia County",
    "Estero",
    "Eustis",
    "Fairview Shores",
    "Feather Sound",
    "Fellsmere",
    "Fern Park",
    "Fernandina Beach",
    "Ferry Pass",
    "Fish Hawk",
    "Five Points",
    "Flagami",
    "Flagler Beach",
    "Flagler County",
    "Flagler Estates",
    "Fleming Island",
    "Floral City",
    "Florida City",
    "Florida Ridge",
    "Forest City",
    "Fort Lauderdale",
    "Fort Meade",
    "Fort Myers",
    "Fort Myers Beach",
    "Fort Myers Shores",
    "Fort Pierce",
    "Fort Pierce North",
    "Fort Pierce South",
    "Fort Walton Beach",
    "Fountainebleau",
    "Four Corners",
    "Franklin County",
    "Freeport",
    "Frostproof",
    "Fruit Cove",
    "Fruitland Park",
    "Fruitville",
    "Fuller Heights",
    "Fussels Corner",
    "Gadsden County",
    "Gainesville",
    "Gandy",
    "Gateway",
    "Geneva",
    "Gibsonia",
    "Gibsonton",
    "Gifford",
    "Gilchrist County",
    "Glades County",
    "Gladeview",
    "Glencoe",
    "Glenvar Heights",
    "Golden Gate",
    "Golden Glades",
    "Goldenrod",
    "Gonzalez",
    "Gotha",
    "Goulding",
    "Goulds",
    "Graceville",
    "Grant-Valkaria",
    "Greater Northdale",
    "Green Cove Springs",
    "Greenacres City",
    "Greenbriar",
    "Gretna",
    "Grove City",
    "Groveland",
    "Gulf Breeze",
    "Gulf County",
    "Gulf Gate Estates",
    "Gulfport",
    "Haines City",
    "Hallandale Beach",
    "Hamilton County",
    "Harbor Bluffs",
    "Harbour Heights",
    "Hardee County",
    "Harlem",
    "Harlem Heights",
    "Havana",
    "Haverhill",
    "Hawthorne",
    "Heathrow",
    "Hendry County",
    "Heritage Pines",
    "Hernando",
    "Hernando Beach",
    "Hernando County",
    "Hialeah",
    "Hialeah Gardens",
    "High Point",
    "High Springs",
    "Highland Beach",
    "Highland City",
    "Highlands County",
    "Hiland Park",
    "Hill 'n Dale",
    "Hilliard",
    "Hillsboro Beach",
    "Hillsborough County",
    "Hobe Sound",
    "Holden Heights",
    "Holiday",
    "Holley",
    "Holly Hill",
    "Hollywood",
    "Holmes Beach",
    "Holmes County",
    "Homestead",
    "Homosassa",
    "Homosassa Springs",
    "Horizon West",
    "Howey-in-the-Hills",
    "Hudson",
    "Hunters Creek",
    "Hutchinson Island South",
    "Hypoluxo",
    "Immokalee",
    "Indialantic",
    "Indian Harbour Beach",
    "Indian River County",
    "Indian River Estates",
    "Indian River Shores",
    "Indian Rocks Beach",
    "Indian Shores",
    "Indiantown",
    "Inglis",
    "Interlachen",
    "Inverness",
    "Inverness Highlands North",
    "Inverness Highlands South",
    "Inwood",
    "Iona",
    "Islamorada",
    "Island Walk",
    "Isle of Normandy",
    "Ives Estates",
    "Jackson County",
    "Jacksonville",
    "Jacksonville Beach",
    "Jan-Phyl Village",
    "Jasmine Estates",
    "Jasper",
    "Jefferson County",
    "Jensen Beach",
    "June Park",
    "Juno Beach",
    "Jupiter",
    "Kathleen",
    "Kendale Lakes",
    "Kendall",
    "Kendall Green",
    "Kendall West",
    "Kenneth City",
    "Kensington Park",
    "Key Biscayne",
    "Key Largo",
    "Key Vista",
    "Key West",
    "Keystone",
    "Keystone Heights",
    "Kings Point",
    "Kissimmee",
    "LaBelle",
    "Lacoochee",
    "Lady Lake",
    "Lafayette County",
    "Laguna Beach",
    "Lake Alfred",
    "Lake Belvedere Estates",
    "Lake Butler",
    "Lake City",
    "Lake Clarke Shores",
    "Lake County",
    "Lake Forest",
    "Lake Hamilton",
    "Lake Helen",
    "Lake Lorraine",
    "Lake Lucerne",
    "Lake Mack-Forest Hills",
    "Lake Magdalene",
    "Lake Mary",
    "Lake Panasoffkee",
    "Lake Park",
    "Lake Placid",
    "Lake Sarasota",
    "Lake Wales",
    "Lake Worth",
    "Lake Worth Corridor",
    "Lakeland",
    "Lakeland Highlands",
    "Lakes by the Bay",
    "Lakeside",
    "Lakewood Park",
    "Land O' Lakes",
    "Lantana",
    "Largo",
    "Lauderdale Lakes",
    "Lauderdale-by-the-Sea",
    "Lauderhill",
    "Laurel",
    "Lealman",
    "Lecanto",
    "Lee County",
    "Leesburg",
    "Lehigh Acres",
    "Leisure City",
    "Lely",
    "Lely Resort",
    "Leon County",
    "Levy County",
    "Liberty County",
    "Lighthouse Point",
    "Limestone Creek",
    "Live Oak",
    "Lochmoor Waterway Estates",
    "Lockhart",
    "Longboat Key",
    "Longwood",
    "Loughman",
    "Lower Grand Lagoon",
    "Loxahatchee Groves",
    "Lutz",
    "Lynn Haven",
    "Macclenny",
    "Madeira Beach",
    "Madison",
    "Madison County",
    "Maitland",
    "Malabar",
    "Malone",
    "Manasota Key",
    "Manatee County",
    "Manatee Road",
    "Mango",
    "Mangonia Park",
    "Marathon",
    "Marco",
    "Marco Island",
    "Margate",
    "Marianna",
    "Marion County",
    "Martin County",
    "Mary Esther",
    "Masaryktown",
    "Mascotte",
    "Mayo",
    "McGregor",
    "Meadow Oaks",
    "Meadow Woods",
    "Medulla",
    "Melbourne",
    "Melbourne Beach",
    "Melrose Park",
    "Memphis",
    "Merritt Island",
    "Mexico Beach",
    "Miami",
    "Miami Beach",
    "Miami Gardens",
    "Miami Lakes",
    "Miami Shores",
    "Miami Springs",
    "Miami-Dade County",
    "Micco",
    "Middleburg",
    "Midway",
    "Milton",
    "Mims",
    "Minneola",
    "Miramar",
    "Miramar Beach",
    "Molino",
    "Monroe County",
    "Monticello",
    "Montverde",
    "Moore Haven",
    "Mount Dora",
    "Mount Plymouth",
    "Mulberry",
    "Myrtle Grove",
    "Naples",
    "Naples Manor",
    "Naples Park",
    "Naranja",
    "Nassau County",
    "Nassau Village-Ratliff",
    "Navarre",
    "Neptune Beach",
    "New Port Richey",
    "New Port Richey East",
    "New Smyrna Beach",
    "Newberry",
    "Niceville",
    "Nocatee",
    "Nokomis",
    "Norland",
    "North Andrews Gardens",
    "North Bay Village",
    "North Brooksville",
    "North DeLand",
    "North Fort Myers",
    "North Key Largo",
    "North Lauderdale",
    "North Miami",
    "North Miami Beach",
    "North Palm Beach",
    "North Port",
    "North Redington Beach",
    "North River Shores",
    "North Sarasota",
    "North Weeki Wachee",
    "Northdale",
    "Oak Hill",
    "Oak Ridge",
    "Oakland",
    "Oakland Park",
    "Oakleaf Plantation",
    "Ocala",
    "Ocean City",
    "Ocean Ridge",
    "Ocoee",
    "Odessa",
    "Ojus",
    "Okaloosa County",
    "Okeechobee",
    "Okeechobee County",
    "Oldsmar",
    "Olga",
    "Olympia Heights",
    "Opa-locka",
    "Orange City",
    "Orange County",
    "Orange Park",
    "Orangetree",
    "Oriole Beach",
    "Orlando",
    "Orlovista",
    "Ormond Beach",
    "Ormond-by-the-Sea",
    "Osceola County",
    "Osprey",
    "Oviedo",
    "Pace",
    "Pahokee",
    "Palatka",
    "Palm Aire",
    "Palm Bay",
    "Palm Beach",
    "Palm Beach County",
    "Palm Beach Gardens",
    "Palm Beach Shores",
    "Palm City",
    "Palm Coast",
    "Palm Harbor",
    "Palm River-Clair Mel",
    "Palm Springs",
    "Palm Springs North",
    "Palm Valley",
    "Palmetto",
    "Palmetto Bay",
    "Palmetto Estates",
    "Palmona Park",
    "Panama City",
    "Panama City Beach",
    "Paradise Heights",
    "Parker",
    "Parkland",
    "Pasadena Hills",
    "Pasco County",
    "Pebble Creek",
    "Pelican Bay",
    "Pembroke Park",
    "Pembroke Pines",
    "Pensacola",
    "Perry",
    "Pierson",
    "Pine Castle",
    "Pine Hills",
    "Pine Island Center",
    "Pine Island Ridge",
    "Pine Manor",
    "Pine Ridge",
    "Pinecrest",
    "Pinellas County",
    "Pinellas Park",
    "Pinewood",
    "Placid Lakes",
    "Plant City",
    "Plantation",
    "Plantation Mobile Home Park",
    "Poinciana",
    "Point Baker",
    "Polk City",
    "Polk County",
    "Pompano Beach",
    "Pompano Beach Highlands",
    "Ponce Inlet",
    "Ponte Vedra Beach",
    "Port Charlotte",
    "Port LaBelle",
    "Port Orange",
    "Port Richey",
    "Port Saint Joe",
    "Port Saint John",
    "Port Saint Lucie",
    "Port Salerno",
    "Pretty Bayou",
    "Princeton",
    "Progress Village",
    "Punta Gorda",
    "Punta Gorda Isles",
    "Punta Rassa",
    "Putnam County",
    "Quail Ridge",
    "Quincy",
    "Redington Beach",
    "Redington Shores",
    "Richmond Heights",
    "Richmond West",
    "Ridge Manor",
    "Ridge Wood Heights",
    "Ridgecrest",
    "River Park",
    "Riverview",
    "Riviera Beach",
    "Rock Island",
    "Rockledge",
    "Roosevelt Gardens",
    "Roseland",
    "Rotonda West",
    "Royal Palm Beach",
    "Royal Palm Estates",
    "Ruskin",
    "Safety Harbor",
    "Saint Augustine",
    "Saint Augustine Beach",
    "Saint Augustine Shores",
    "Saint Augustine South",
    "Saint Cloud",
    "Saint George",
    "Saint James City",
    "Saint Johns County",
    "Saint Leo",
    "Saint Lucie County",
    "Saint Pete Beach",
    "Samoset",
    "Samsula-Spruce Creek",
    "San Antonio",
    "San Carlos Park",
    "Sandalfoot Cove",
    "Sanford",
    "Sanibel",
    "Santa Rosa County",
    "Sarasota",
    "Sarasota County",
    "Sarasota Springs",
    "Satellite Beach",
    "Sawgrass",
    "Schall Circle",
    "Scott Lake",
    "Seaside",
    "Sebastian",
    "Sebring",
    "Seffner",
    "Seminole",
    "Seminole County",
    "Seminole Manor",
    "Sewall's Point",
    "Shady Hills",
    "Sharpes",
    "Siesta Key",
    "Silver Lake",
    "Silver Springs",
    "Silver Springs Shores",
    "Sky Lake",
    "Sneads",
    "South Apopka",
    "South Bay",
    "South Beach",
    "South Bradenton",
    "South Brooksville",
    "South Daytona",
    "South Gate Ridge",
    "South Highpoint",
    "South Miami",
    "South Miami Heights",
    "South Palm Beach",
    "South Pasadena",
    "South Patrick Shores",
    "South Sarasota",
    "South Venice",
    "Southchase",
    "Southeast Arcadia",
    "Southgate",
    "Southwest Ranches",
    "Spring Hill",
    "Springfield",
    "St. Johns",
    "St. Petersburg",
    "Starke",
    "Steinhatchee",
    "Stock Island",
    "Stuart",
    "Sugarmill Woods",
    "Sumter County",
    "Sun City Center",
    "Suncoast Estates",
    "Sunny Isles Beach",
    "Sunrise",
    "Sunset",
    "Sunshine Ranches",
    "Surfside",
    "Suwannee County",
    "Sweetwater",
    "Taft",
    "Tallahassee",
    "Tamarac",
    "Tamiami",
    "Tampa",
    "Tangelo Park",
    "Tangerine",
    "Tarpon Springs",
    "Tavares",
    "Tavernier",
    "Taylor County",
    "Taylor Creek",
    "Tedder",
    "Temple Terrace",
    "Tequesta",
    "Terra Mar",
    "The Acreage",
    "The Crossings",
    "The Hammocks",
    "The Meadows",
    "The Villages",
    "Thonotosassa",
    "Three Lakes",
    "Three Oaks",
    "Tice",
    "Tierra Verde",
    "Tiger Point",
    "Timber Pines",
    "Titusville",
    "Town 'n' Country",
    "Treasure Island",
    "Trenton",
    "Trinity",
    "Twin Lakes",
    "Tyndall Air Force Base",
    "Umatilla",
    "Union County",
    "Union Park",
    "University",
    "University Park",
    "Upper Grand Lagoon",
    "Valparaiso",
    "Valrico",
    "Vamo",
    "Venice",
    "Venice Gardens",
    "Vero Beach",
    "Vero Beach South",
    "Verona Walk",
    "Viera East",
    "Viera West",
    "Villages of Oriole",
    "Villano Beach",
    "Villas",
    "Vineyards",
    "Virginia Gardens",
    "Volusia County",
    "Wabasso Beach",
    "Wahneta",
    "Wakulla County",
    "Waldo",
    "Wallace",
    "Walton County",
    "Warm Mineral Springs",
    "Warrington",
    "Washington County",
    "Washington Park",
    "Watertown",
    "Wauchula",
    "Wedgefield",
    "Weeki Wachee Gardens",
    "Wekiwa Springs",
    "Wellborn",
    "Wellington",
    "Wesley Chapel",
    "West Bradenton",
    "West DeLand",
    "West Gate",
    "West Hollywood",
    "West Little River",
    "West Melbourne",
    "West Miami",
    "West Palm Beach",
    "West Park",
    "West Pensacola",
    "West Perrine",
    "West Samoset",
    "West Vero Corridor",
    "West and East Lealman",
    "Westchase",
    "Westchester",
    "Weston",
    "Westview",
    "Westwood Lake",
    "Wewahitchka",
    "Whiskey Creek",
    "White City",
    "Whitfield",
    "Wildwood",
    "Williamsburg",
    "Williston",
    "Williston Highlands",
    "Willow Oak",
    "Wilton Manors",
    "Wimauma",
    "Windermere",
    "Winston",
    "Winter Beach",
    "Winter Garden",
    "Winter Haven",
    "Winter Park",
    "Winter Springs",
    "Woodlawn Beach",
    "Woodville",
    "Wright",
    "Yalaha",
    "Youngstown",
    "Yulee",
    "Zellwood",
    "Zephyrhills",
    "Zephyrhills North",
    "Zephyrhills South",
    "Zephyrhills West",
    "Zolfo Springs"
  ],
  "Georgia": [
    "Abbeville",
    "Acworth",
    "Adairsville",
    "Adel",
    "Alamo",
    "Albany",
    "Alma",
    "Alpharetta",
    "Alto",
    "Americus",
    "Appling County",
    "Aragon",
    "Arcade",
    "Arlington",
    "Ashburn",
    "Athens",
    "Atkinson County",
    "Atlanta",
    "Auburn",
    "Augusta",
    "Austell",
    "Avondale Estates",
    "Bacon County",
    "Bainbridge",
    "Baker County",
    "Baldwin",
    "Baldwin County",
    "Ball Ground",
    "Banks County",
    "Barnesville",
    "Barrow County",
    "Bartow County",
    "Baxley",
    "Belvedere Park",
    "Ben Hill County",
    "Berkeley Lake",
    "Berrien County",
    "Bibb County",
    "Blackshear",
    "Blairsville",
    "Blakely",
    "Bleckley County",
    "Bloomingdale",
    "Blue Ridge",
    "Bogart",
    "Bonanza",
    "Boston",
    "Bowdon",
    "Brantley County",
    "Braselton",
    "Bremen",
    "Brookhaven",
    "Brooklet",
    "Brooks County",
    "Broxton",
    "Brunswick",
    "Bryan County",
    "Buchanan",
    "Buena Vista",
    "Buford",
    "Bulloch County",
    "Burke County",
    "Butler",
    "Butts County",
    "Byron",
    "Cairo",
    "Calhoun",
    "Calhoun County",
    "Camden County",
    "Camilla",
    "Candler County",
    "Candler-McAfee",
    "Canton",
    "Carnesville",
    "Carroll County",
    "Carrollton",
    "Cartersville",
    "Catoosa County",
    "Cave Spring",
    "Cedartown",
    "Centerville",
    "Chamblee",
    "Charlton County",
    "Chatham County",
    "Chatsworth",
    "Chattahoochee County",
    "Chattahoochee Hills",
    "Chattanooga Valley",
    "Chattooga County",
    "Cherokee County",
    "Chester",
    "Chickamauga",
    "Clarke County",
    "Clarkesville",
    "Clarkston",
    "Claxton",
    "Clay County",
    "Clayton",
    "Clayton County",
    "Cleveland",
    "Clinch County",
    "Cobb County",
    "Cochran",
    "Coffee County",
    "College Park",
    "Colquitt",
    "Colquitt County",
    "Columbia County",
    "Columbus",
    "Comer",
    "Commerce",
    "Conley",
    "Conyers",
    "Cook County",
    "Cordele",
    "Cornelia",
    "Country Club Estates",
    "Covington",
    "Coweta County",
    "Crawford County",
    "Crawfordville",
    "Crisp County",
    "Cumming",
    "Cusseta",
    "Cuthbert",
    "Dacula",
    "Dade County",
    "Dahlonega",
    "Dallas",
    "Dalton",
    "Danielsville",
    "Darien",
    "Davisboro",
    "Dawson",
    "Dawson County",
    "Dawsonville",
    "DeKalb County",
    "Decatur",
    "Decatur County",
    "Deenwood",
    "Demorest",
    "Dock Junction",
    "Dodge County",
    "Donalsonville",
    "Dooly County",
    "Doraville",
    "Dougherty County",
    "Douglas",
    "Douglas County",
    "Douglasville",
    "Druid Hills",
    "Dublin",
    "Duluth",
    "Dunwoody",
    "Early County",
    "East Dublin",
    "East Griffin",
    "East Newnan",
    "East Point",
    "Eastman",
    "Eatonton",
    "Echols County",
    "Edison",
    "Effingham County",
    "Elbert County",
    "Elberton",
    "Ellaville",
    "Ellijay",
    "Emanuel County",
    "Emerson",
    "Enigma",
    "Euharlee",
    "Evans",
    "Evans County",
    "Experiment",
    "Fair Oaks",
    "Fairburn",
    "Fairview",
    "Fannin County",
    "Fayette County",
    "Fayetteville",
    "Firing Range",
    "Fitzgerald",
    "Flowery Branch",
    "Floyd County",
    "Folkston",
    "Forest Park",
    "Forsyth",
    "Forsyth County",
    "Fort Gaines",
    "Fort Oglethorpe",
    "Fort Stewart",
    "Fort Valley",
    "Franklin",
    "Franklin County",
    "Franklin Springs",
    "Fulton County",
    "Gainesville",
    "Garden City",
    "Georgetown",
    "Gibson",
    "Gilmer County",
    "Glascock County",
    "Glennville",
    "Glynn County",
    "Gordon",
    "Gordon County",
    "Grady County",
    "Grantville",
    "Gray",
    "Grayson",
    "Greene County",
    "Greensboro",
    "Greenville",
    "Gresham Park",
    "Griffin",
    "Grovetown",
    "Gumlog",
    "Guyton",
    "Gwinnett County",
    "Habersham County",
    "Hahira",
    "Hall County",
    "Hamilton",
    "Hampton",
    "Hancock County",
    "Hannahs Mill",
    "Hapeville",
    "Haralson County",
    "Hardwick",
    "Harlem",
    "Harris County",
    "Hart County",
    "Hartwell",
    "Hawkinsville",
    "Hazlehurst",
    "Heard County",
    "Helena",
    "Henderson",
    "Henry County",
    "Hephzibah",
    "Hiawassee",
    "Hinesville",
    "Hiram",
    "Hogansville",
    "Holly Springs",
    "Homer",
    "Homerville",
    "Hoschton",
    "Houston County",
    "Indian Springs",
    "Irondale",
    "Irwin County",
    "Irwinton",
    "Isle of Hope",
    "Jackson",
    "Jackson County",
    "Jasper",
    "Jasper County",
    "Jeff Davis County",
    "Jefferson",
    "Jefferson County",
    "Jeffersonville",
    "Jenkins County",
    "Jesup",
    "Johns Creek",
    "Johnson County",
    "Jones County",
    "Jonesboro",
    "Kennesaw",
    "Kings Bay Base",
    "Kingsland",
    "Knoxville",
    "LaFayette",
    "LaGrange",
    "Lake City",
    "Lakeland",
    "Lakeview",
    "Lakeview Estates",
    "Lamar County",
    "Lanier County",
    "Laurens County",
    "Lavonia",
    "Lawrenceville",
    "Lee County",
    "Leesburg",
    "Lexington",
    "Liberty County",
    "Lilburn",
    "Lincoln County",
    "Lincolnton",
    "Lindale",
    "Lithia Springs",
    "Lithonia",
    "Locust Grove",
    "Loganville",
    "Long County",
    "Lookout Mountain",
    "Louisville",
    "Lovejoy",
    "Lowndes County",
    "Ludowici",
    "Lula",
    "Lumber City",
    "Lumpkin",
    "Lumpkin County",
    "Lyons",
    "Mableton",
    "Macon",
    "Macon County",
    "Madison",
    "Madison County",
    "Manchester",
    "Marietta",
    "Marion County",
    "Marshallville",
    "Martinez",
    "Maysville",
    "McCaysville",
    "McDonough",
    "McDuffie County",
    "McIntosh County",
    "McRae",
    "Meigs",
    "Meriwether County",
    "Metter",
    "Midway",
    "Milledgeville",
    "Millen",
    "Miller County",
    "Milton",
    "Mitchell County",
    "Monroe",
    "Monroe County",
    "Montezuma",
    "Montgomery",
    "Montgomery County",
    "Monticello",
    "Morgan",
    "Morgan County",
    "Morrow",
    "Moultrie",
    "Mount Airy",
    "Mount Vernon",
    "Mount Zion",
    "Mountain City",
    "Mountain Park",
    "Murray County",
    "Muscogee County",
    "Nahunta",
    "Nashville",
    "Nelson",
    "Newnan",
    "Newton",
    "Newton County",
    "Nicholls",
    "Nicholson",
    "Norcross",
    "North Atlanta",
    "North Decatur",
    "North Druid Hills",
    "Oakwood",
    "Ocilla",
    "Oconee County",
    "Oglethorpe",
    "Oglethorpe County",
    "Omega",
    "Oxford",
    "Palmetto",
    "Panthersville",
    "Paulding County",
    "Peach County",
    "Peachtree City",
    "Peachtree Corners",
    "Pearson",
    "Pelham",
    "Pembroke",
    "Perry",
    "Pickens County",
    "Pierce County",
    "Pike County",
    "Pine Mountain",
    "Polk County",
    "Pooler",
    "Port Wentworth",
    "Porterdale",
    "Powder Springs",
    "Preston",
    "Pulaski County",
    "Putnam County",
    "Putney",
    "Quitman",
    "Quitman County",
    "Rabun County",
    "Randolph County",
    "Raoul",
    "Ray City",
    "Redan",
    "Reed Creek",
    "Reidsville",
    "Remerton",
    "Reynolds",
    "Richland",
    "Richmond County",
    "Richmond Hill",
    "Rincon",
    "Ringgold",
    "Riverdale",
    "Robins Air Force Base",
    "Rochelle",
    "Rockdale County",
    "Rockmart",
    "Rome",
    "Rossville",
    "Roswell",
    "Royston",
    "Russell",
    "Rydal",
    "Saint Simon Mills",
    "Saint Simons Island",
    "Sandersville",
    "Sandy Springs",
    "Sardis",
    "Savannah",
    "Schley County",
    "Scottdale",
    "Screven County",
    "Seminole County",
    "Senoia",
    "Shannon",
    "Skidaway Island",
    "Smyrna",
    "Snellville",
    "Social Circle",
    "Soperton",
    "Spalding County",
    "Sparks",
    "Sparta",
    "Springfield",
    "St. Marys",
    "Statenville",
    "Statesboro",
    "Statham",
    "Stephens County",
    "Stewart County",
    "Stockbridge",
    "Stone Mountain",
    "Stonecrest",
    "Sugar Hill",
    "Summerville",
    "Sumter County",
    "Sunnyside",
    "Suwanee",
    "Swainsboro",
    "Sylvania",
    "Sylvester",
    "Talbot County",
    "Talbotton",
    "Taliaferro County",
    "Tallapoosa",
    "Tattnall County",
    "Taylor County",
    "Telfair County",
    "Temple",
    "Tennille",
    "Terrell County",
    "Thomas County",
    "Thomaston",
    "Thomasville",
    "Thomson",
    "Thunderbolt",
    "Tift County",
    "Tifton",
    "Toccoa",
    "Toombs County",
    "Towns County",
    "Trenton",
    "Treutlen County",
    "Trion",
    "Troup County",
    "Tucker",
    "Turner County",
    "Twiggs County",
    "Twin City",
    "Tybee Island",
    "Tyrone",
    "Unadilla",
    "Union City",
    "Union County",
    "Union Point",
    "Unionville",
    "Upson County",
    "Valdosta",
    "Varnell",
    "Vidalia",
    "Vienna",
    "Villa Rica",
    "Vinings",
    "Wadley",
    "Walker County",
    "Walnut Grove",
    "Walthourville",
    "Walton County",
    "Ware County",
    "Warner Robins",
    "Warren County",
    "Warrenton",
    "Washington",
    "Washington County",
    "Watkinsville",
    "Waycross",
    "Wayne County",
    "Waynesboro",
    "Webster County",
    "West Point",
    "West Warrenton",
    "Wheeler County",
    "White County",
    "Whitemarsh Island",
    "Whitfield County",
    "Wilcox County",
    "Wilkes County",
    "Wilkinson County",
    "Willacoochee",
    "Wilmington Island",
    "Winder",
    "Winterville",
    "Woodbine",
    "Woodstock",
    "Worth County",
    "Wrens",
    "Wrightsville",
    "Young Harris",
    "Zebulon"
  ],
  "Hawaii": [
    "Ainaloa",
    "Anahola",
    "Captain Cook",
    "East Honolulu",
    "Fern Acres",
    "Haiku-Pauwela",
    "Hale‘iwa",
    "Hana",
    "Hanamā‘ulu",
    "Hanapēpē",
    "Hanapēpē Heights",
    "Hau‘ula",
    "Hawaii County",
    "Hawaiian Acres",
    "Hawaiian Beaches",
    "Hawaiian Ocean View",
    "Hawaiian Paradise Park",
    "He‘eia",
    "Hickam Field",
    "Hilo",
    "Honalo",
    "Honaunau-Napoopoo",
    "Honoka‘a",
    "Honolulu",
    "Honolulu County",
    "Hālawa",
    "Hālawa Heights",
    "Hāwī",
    "Hōlualoa",
    "Iroquois Point",
    "Kaanapali Landing",
    "Kahaluu-Keauhou",
    "Kahalu‘u",
    "Kahuku",
    "Kahului",
    "Kailua",
    "Kailua-Kona",
    "Kalaoa",
    "Kalawao County",
    "Kalāheo",
    "Kaneohe",
    "Kapaau",
    "Kapa‘a",
    "Kapolei",
    "Kauai County",
    "Kaunakakai",
    "Ka‘a‘awa",
    "Kealakekua",
    "Kea‘au",
    "Kekaha",
    "Ko Olina",
    "Koloa",
    "Kualapu‘u",
    "Kula",
    "Kurtistown",
    "Kā‘anapali",
    "Kēōkea",
    "Kīhei",
    "Kīlauea",
    "Lahaina",
    "Lanai City",
    "Lawai",
    "Leilani Estates",
    "Lihue",
    "Lā‘ie",
    "Makakilo City",
    "Makawao",
    "Marine Corps Base Hawaii - MCBH",
    "Maui County",
    "Maunawili",
    "Mililani Town",
    "Mokulēia",
    "Mountain View",
    "Mākaha",
    "Mākaha Valley",
    "Mā‘ili",
    "Nanawale Estates",
    "Napili-Honokowai",
    "Nānākuli",
    "Ocean Pointe",
    "Orchidlands Estates",
    "Paia",
    "Pearl City",
    "Pepeekeo",
    "Princeville",
    "Puhi",
    "Pukalani",
    "Punalu‘u",
    "Pupukea",
    "Pāhala",
    "Pāpa‘ikou",
    "Royal Kunia",
    "Schofield Barracks",
    "Village Park",
    "Volcano",
    "Wahiawā",
    "Waialua",
    "Waianae",
    "Waihee-Waiehu",
    "Waikapū",
    "Waikoloa",
    "Wailea",
    "Wailua",
    "Wailua Homesteads",
    "Wailuku",
    "Waimalu",
    "Waimanalo",
    "Waimea",
    "Waimānalo Beach",
    "Wainaku",
    "Waipahu",
    "Waipio",
    "Waipi‘o Acres",
    "Whitmore Village",
    "‘Aiea",
    "‘Ele‘ele",
    "‘Ewa Beach",
    "‘Ewa Gentry",
    "‘Ewa Villages",
    "‘Āhuimanu",
    "‘Ōma‘o"
  ],
  "Idaho": [
    "Aberdeen",
    "Ada County",
    "Adams County",
    "American Falls",
    "Ammon",
    "Arco",
    "Ashton",
    "Bannock County",
    "Bear Lake County",
    "Bellevue",
    "Benewah County",
    "Bingham County",
    "Blackfoot",
    "Blaine County",
    "Boise",
    "Boise County",
    "Bonner County",
    "Bonners Ferry",
    "Bonneville County",
    "Boundary County",
    "Buhl",
    "Burley",
    "Butte County",
    "Caldwell",
    "Camas County",
    "Canyon County",
    "Caribou County",
    "Cascade",
    "Cassia County",
    "Challis",
    "Chubbuck",
    "Clark County",
    "Clearwater County",
    "Coeur d'Alene",
    "Council",
    "Custer County",
    "Dalton Gardens",
    "Driggs",
    "Dubois",
    "Eagle",
    "Elmore County",
    "Emmett",
    "Fairfield",
    "Filer",
    "Fort Hall",
    "Franklin County",
    "Fremont County",
    "Fruitland",
    "Garden City",
    "Gem County",
    "Glenns Ferry",
    "Gooding",
    "Gooding County",
    "Grangeville",
    "Hailey",
    "Hansen",
    "Hayden",
    "Heyburn",
    "Hidden Spring",
    "Homedale",
    "Idaho City",
    "Idaho County",
    "Idaho Falls",
    "Iona",
    "Jefferson County",
    "Jerome",
    "Jerome County",
    "Kamiah",
    "Kellogg",
    "Ketchum",
    "Kimberly",
    "Kootenai County",
    "Kuna",
    "Lapwai",
    "Latah County",
    "Lemhi County",
    "Lewis County",
    "Lewiston",
    "Lewiston Orchards",
    "Lincoln",
    "Lincoln County",
    "Madison County",
    "Malad City",
    "Marsing",
    "McCall",
    "Meridian",
    "Middleton",
    "Minidoka County",
    "Montpelier",
    "Moreland",
    "Moscow",
    "Mountain Home",
    "Murphy",
    "Nampa",
    "New Plymouth",
    "Nez Perce County",
    "Nezperce",
    "Oneida County",
    "Orofino",
    "Osburn",
    "Owyhee County",
    "Paris",
    "Parma",
    "Paul",
    "Payette",
    "Payette County",
    "Pinehurst",
    "Plummer",
    "Pocatello",
    "Ponderay",
    "Post Falls",
    "Power County",
    "Preston",
    "Priest River",
    "Rathdrum",
    "Rexburg",
    "Rigby",
    "Rupert",
    "Saint Anthony",
    "Saint Maries",
    "Salmon",
    "Sandpoint",
    "Shelley",
    "Shoshone",
    "Shoshone County",
    "Soda Springs",
    "Spirit Lake",
    "Star",
    "Sugar City",
    "Sun Valley",
    "Teton County",
    "Twin Falls",
    "Twin Falls County",
    "Tyhee",
    "Ucon",
    "Valley County",
    "Victor",
    "Wallace",
    "Washington County",
    "Weiser",
    "Wendell",
    "Wilder"
  ],
  "Illinois": [
    "Abingdon",
    "Adams County",
    "Addison",
    "Albany Park",
    "Albers",
    "Albion",
    "Aledo",
    "Alexander County",
    "Algonquin",
    "Alorton",
    "Alsip",
    "Altamont",
    "Alton",
    "Amboy",
    "Andalusia",
    "Anna",
    "Antioch",
    "Arcola",
    "Arlington Heights",
    "Arthur",
    "Ashburn",
    "Ashland",
    "Assumption",
    "Astoria",
    "Athens",
    "Atlanta",
    "Atwood",
    "Auburn",
    "Auburn Gresham",
    "Aurora",
    "Aviston",
    "Avondale",
    "Bannockburn",
    "Barrington",
    "Barrington Hills",
    "Barry",
    "Bartlett",
    "Bartonville",
    "Batavia",
    "Beach Park",
    "Beardstown",
    "Beckemeyer",
    "Beecher",
    "Belleville",
    "Bellevue",
    "Bellwood",
    "Belmont Cragin",
    "Belvidere",
    "Bement",
    "Benld",
    "Bensenville",
    "Benton",
    "Berkeley",
    "Berwyn",
    "Bethalto",
    "Bethany",
    "Big Rock",
    "Bloomingdale",
    "Bloomington",
    "Blue Island",
    "Blue Mound",
    "Bolingbrook",
    "Bond County",
    "Boone County",
    "Boulder Hill",
    "Bourbonnais",
    "Bradley",
    "Braidwood",
    "Breese",
    "Bridgeport",
    "Bridgeview",
    "Brighton",
    "Brighton Park",
    "Broadview",
    "Brookfield",
    "Brown County",
    "Buffalo Grove",
    "Bull Valley",
    "Bunker Hill",
    "Burbank",
    "Bureau County",
    "Burnham",
    "Burr Ridge",
    "Bushnell",
    "Byron",
    "Cahokia",
    "Cairo",
    "Calhoun County",
    "Calumet City",
    "Calumet Park",
    "Cambria",
    "Cambridge",
    "Camp Point",
    "Canton",
    "Capron",
    "Carbon Cliff",
    "Carbondale",
    "Carlinville",
    "Carlyle",
    "Carmi",
    "Carol Stream",
    "Carpentersville",
    "Carrier Mills",
    "Carroll County",
    "Carrollton",
    "Carterville",
    "Carthage",
    "Cary",
    "Casey",
    "Caseyville",
    "Cass County",
    "Catlin",
    "Central City",
    "Centralia",
    "Centreville",
    "Cerro Gordo",
    "Champaign",
    "Champaign County",
    "Channahon",
    "Channel Lake",
    "Charleston",
    "Chatham",
    "Chatsworth",
    "Chebanse",
    "Chenoa",
    "Cherry Valley",
    "Chester",
    "Chicago",
    "Chicago Heights",
    "Chicago Lawn",
    "Chicago Loop",
    "Chicago Ridge",
    "Chillicothe",
    "Chrisman",
    "Christian County",
    "Christopher",
    "Cicero",
    "Clarendon Hills",
    "Clark County",
    "Clay County",
    "Clifton",
    "Clinton",
    "Clinton County",
    "Coal City",
    "Coal Valley",
    "Cobden",
    "Colchester",
    "Coles County",
    "Colfax",
    "Collinsville",
    "Colona",
    "Columbia",
    "Cook County",
    "Cortland",
    "Country Club Hills",
    "Countryside",
    "Crainville",
    "Crawford County",
    "Crest Hill",
    "Crestwood",
    "Crete",
    "Creve Coeur",
    "Crystal Lake",
    "Crystal Lawns",
    "Cuba",
    "Cumberland County",
    "Danvers",
    "Danville",
    "Darien",
    "Davis Junction",
    "De Soto",
    "De Witt County",
    "DeKalb",
    "DeKalb County",
    "Decatur",
    "Deer Park",
    "Deerfield",
    "Delavan",
    "Depue",
    "Des Plaines",
    "Diamond",
    "Divernon",
    "Dixmoor",
    "Dixon",
    "Dolton",
    "Douglas",
    "Douglas County",
    "Downers Grove",
    "Du Quoin",
    "DuPage County",
    "Dunlap",
    "Dupo",
    "Durand",
    "Dwight",
    "Earlville",
    "East Alton",
    "East Dubuque",
    "East Dundee",
    "East Garfield Park",
    "East Hazel Crest",
    "East Moline",
    "East Peoria",
    "East Saint Louis",
    "Edgar County",
    "Edgewater",
    "Edinburg",
    "Edwards County",
    "Edwardsville",
    "Effingham",
    "Effingham County",
    "El Paso",
    "Elburn",
    "Eldorado",
    "Elgin",
    "Elizabethtown",
    "Elk Grove Village",
    "Elmhurst",
    "Elmwood",
    "Elmwood Park",
    "Elwood",
    "Energy",
    "Englewood",
    "Erie",
    "Eureka",
    "Evanston",
    "Evergreen Park",
    "Fairbury",
    "Fairfield",
    "Fairmont",
    "Fairmont City",
    "Fairview Heights",
    "Farmer City",
    "Farmington",
    "Fayette County",
    "Fisher",
    "Flanagan",
    "Flora",
    "Flossmoor",
    "Ford County",
    "Ford Heights",
    "Forest Lake",
    "Forest Park",
    "Forrest",
    "Forreston",
    "Forsyth",
    "Fox Lake",
    "Fox Lake Hills",
    "Fox River Grove",
    "Frankfort",
    "Frankfort Square",
    "Franklin County",
    "Franklin Park",
    "Freeburg",
    "Freeport",
    "Fulton",
    "Fulton County",
    "Gage Park",
    "Gages Lake",
    "Galena",
    "Galesburg",
    "Gallatin County",
    "Galva",
    "Gardner",
    "Geneseo",
    "Geneva",
    "Genoa",
    "Georgetown",
    "Germantown",
    "Germantown Hills",
    "Gibson City",
    "Gifford",
    "Gilberts",
    "Gillespie",
    "Gilman",
    "Girard",
    "Glasford",
    "Glen Carbon",
    "Glen Ellyn",
    "Glencoe",
    "Glendale Heights",
    "Glenview",
    "Glenwood",
    "Godfrey",
    "Golconda",
    "Goodings Grove",
    "Goreville",
    "Grand Boulevard",
    "Grandview",
    "Grandwood Park",
    "Granite City",
    "Grant Park",
    "Granville",
    "Grayslake",
    "Grayville",
    "Greater Grand Crossing",
    "Green Oaks",
    "Green Rock",
    "Greene County",
    "Greenfield",
    "Greenup",
    "Greenville",
    "Gridley",
    "Griggsville",
    "Grundy County",
    "Gurnee",
    "Hainesville",
    "Hamilton",
    "Hamilton County",
    "Hampshire",
    "Hampton",
    "Hancock County",
    "Hanna City",
    "Hanover Park",
    "Hardin",
    "Hardin County",
    "Harrisburg",
    "Harristown",
    "Hartford",
    "Harvard",
    "Harvey",
    "Harwood Heights",
    "Havana",
    "Hawthorn Woods",
    "Hazel Crest",
    "Hebron",
    "Henderson County",
    "Hennepin",
    "Henry",
    "Henry County",
    "Heritage Lake",
    "Herrin",
    "Herscher",
    "Heyworth",
    "Hickory Hills",
    "Highland",
    "Highland Park",
    "Highwood",
    "Hillcrest",
    "Hillsboro",
    "Hillside",
    "Hinckley",
    "Hinsdale",
    "Hodgkins",
    "Hoffman Estates",
    "Holiday Shores",
    "Homer",
    "Homer Glen",
    "Hometown",
    "Homewood",
    "Hoopeston",
    "Hudson",
    "Huntley",
    "Hyde Park",
    "Ina",
    "Indian Head Park",
    "Ingalls Park",
    "Inverness",
    "Iroquois County",
    "Irving Park",
    "Island Lake",
    "Itasca",
    "Jackson County",
    "Jacksonville",
    "Jasper County",
    "Jefferson County",
    "Jerome",
    "Jersey County",
    "Jerseyville",
    "Jo Daviess County",
    "Johnsburg",
    "Johnson County",
    "Johnston City",
    "Joliet",
    "Jonesboro",
    "Justice",
    "Kane County",
    "Kankakee",
    "Kankakee County",
    "Kendall County",
    "Kenilworth",
    "Kenwood",
    "Kewanee",
    "Kildeer",
    "Kincaid",
    "Kingston",
    "Kirkland",
    "Knollwood",
    "Knox County",
    "Knoxville",
    "La Grange",
    "La Grange Park",
    "La Harpe",
    "La Salle",
    "LaSalle County",
    "Lacon",
    "Ladd",
    "Lake Barrington",
    "Lake Bluff",
    "Lake Camelot",
    "Lake Catherine",
    "Lake County",
    "Lake Forest",
    "Lake Holiday",
    "Lake Summerset",
    "Lake Villa",
    "Lake Zurich",
    "Lake in the Hills",
    "Lake of the Woods",
    "Lakemoor",
    "Lakewood",
    "Lakewood Shores",
    "Lanark",
    "Lansing",
    "Lawrence County",
    "Lawrenceville",
    "Le Roy",
    "Lebanon",
    "Lee County",
    "Leland Grove",
    "Lemont",
    "Lena",
    "Lewistown",
    "Lexington",
    "Libertyville",
    "Lily Lake",
    "Limestone",
    "Lincoln",
    "Lincoln Park",
    "Lincoln Square",
    "Lincolnshire",
    "Lincolnwood",
    "Lindenhurst",
    "Lisle",
    "Litchfield",
    "Livingston County",
    "Lockport",
    "Logan County",
    "Logan Square",
    "Lombard",
    "Long Creek",
    "Long Grove",
    "Long Lake",
    "Louisville",
    "Loves Park",
    "Lovington",
    "Lower West Side",
    "Lynwood",
    "Lyons",
    "Machesney Park",
    "Mackinaw",
    "Macomb",
    "Macon",
    "Macon County",
    "Macoupin County",
    "Madison",
    "Madison County",
    "Mahomet",
    "Malta",
    "Manhattan",
    "Manito",
    "Manteno",
    "Maple Park",
    "Marengo",
    "Marion",
    "Marion County",
    "Marissa",
    "Markham",
    "Maroa",
    "Marquette Heights",
    "Marseilles",
    "Marshall",
    "Marshall County",
    "Martinsville",
    "Maryville",
    "Mascoutah",
    "Mason City",
    "Mason County",
    "Massac County",
    "Matteson",
    "Mattoon",
    "Maywood",
    "McCullom Lake",
    "McDonough County",
    "McHenry",
    "McHenry County",
    "McKinley Park",
    "McLean County",
    "McLeansboro",
    "Melrose Park",
    "Menard County",
    "Mendota",
    "Mercer County",
    "Meredosia",
    "Merrionette Park",
    "Metamora",
    "Metropolis",
    "Midlothian",
    "Milan",
    "Milford",
    "Millstadt",
    "Minier",
    "Minonk",
    "Minooka",
    "Mitchell",
    "Mokena",
    "Moline",
    "Momence",
    "Monee",
    "Monmouth",
    "Monroe County",
    "Montgomery",
    "Montgomery County",
    "Monticello",
    "Morgan County",
    "Morgan Park",
    "Morris",
    "Morrison",
    "Morrisonville",
    "Morton",
    "Morton Grove",
    "Moultrie County",
    "Mound City",
    "Mount Carmel",
    "Mount Carroll",
    "Mount Greenwood",
    "Mount Morris",
    "Mount Olive",
    "Mount Prospect",
    "Mount Pulaski",
    "Mount Sterling",
    "Mount Vernon",
    "Mount Zion",
    "Moweaqua",
    "Mundelein",
    "Murphysboro",
    "Naperville",
    "Nashville",
    "Nauvoo",
    "Near North Side",
    "Near South Side",
    "Neoga",
    "New Athens",
    "New Baden",
    "New Berlin",
    "New City",
    "New Lenox",
    "Newark",
    "Newton",
    "Niles",
    "Nokomis",
    "Normal",
    "Norridge",
    "Norris City",
    "North Aurora",
    "North Barrington",
    "North Center",
    "North Chicago",
    "North Lawndale",
    "North Pekin",
    "North Peoria",
    "North Riverside",
    "Northbrook",
    "Northfield",
    "Northlake",
    "O'Fallon",
    "Oak Brook",
    "Oak Forest",
    "Oak Lawn",
    "Oak Park",
    "Oakbrook Terrace",
    "Oakwood",
    "Oakwood Hills",
    "Oblong",
    "Odin",
    "Ogle County",
    "Oglesby",
    "Okawville",
    "Olney",
    "Olympia Fields",
    "Onarga",
    "Oquawka",
    "Oregon",
    "Orion",
    "Orland Hills",
    "Orland Park",
    "Oswego",
    "Ottawa",
    "Palatine",
    "Palestine",
    "Palos Heights",
    "Palos Hills",
    "Palos Park",
    "Pana",
    "Paris",
    "Park City",
    "Park Forest",
    "Park Ridge",
    "Pawnee",
    "Paxton",
    "Payson",
    "Pecatonica",
    "Pekin",
    "Peoria",
    "Peoria County",
    "Peoria Heights",
    "Peotone",
    "Perry County",
    "Peru",
    "Petersburg",
    "Philo",
    "Phoenix",
    "Piatt County",
    "Pike County",
    "Pinckneyville",
    "Pingree Grove",
    "Pistakee Highlands",
    "Pittsfield",
    "Plainfield",
    "Plano",
    "Polo",
    "Pontiac",
    "Pontoon Beach",
    "Pope County",
    "Poplar Grove",
    "Port Barrington",
    "Port Byron",
    "Portage Park",
    "Posen",
    "Prairie Grove",
    "Prestbury",
    "Preston Heights",
    "Princeton",
    "Princeville",
    "Prophetstown",
    "Prospect Heights",
    "Pulaski County",
    "Putnam County",
    "Quincy",
    "Ramsey",
    "Randolph County",
    "Rantoul",
    "Red Bud",
    "Richland County",
    "Richmond",
    "Richton Park",
    "River Forest",
    "River Grove",
    "Riverdale",
    "Riverside",
    "Riverton",
    "Riverwoods",
    "Roanoke",
    "Robbins",
    "Robinson",
    "Rochelle",
    "Rochester",
    "Rock Falls",
    "Rock Island",
    "Rock Island County",
    "Rockdale",
    "Rockford",
    "Rockton",
    "Rogers Park",
    "Rolling Meadows",
    "Rome",
    "Romeoville",
    "Roodhouse",
    "Roscoe",
    "Roselle",
    "Rosemont",
    "Rosewood Heights",
    "Rosiclare",
    "Rossville",
    "Round Lake",
    "Round Lake Beach",
    "Round Lake Heights",
    "Round Lake Park",
    "Roxana",
    "Royalton",
    "Rushville",
    "Saint Anne",
    "Saint Charles",
    "Saint Clair County",
    "Saint Elmo",
    "Saint Jacob",
    "Saint Joseph",
    "Salem",
    "Saline County",
    "Sandoval",
    "Sandwich",
    "Sangamon County",
    "Sauk Village",
    "Savanna",
    "Savoy",
    "Schaumburg",
    "Schiller Park",
    "Schuyler County",
    "Scott Air Force Base",
    "Scott County",
    "Seneca",
    "Sesser",
    "Shawneetown",
    "Shelby County",
    "Shelbyville",
    "Sheldon",
    "Sheridan",
    "Sherman",
    "Shiloh",
    "Shorewood",
    "Sidney",
    "Silvis",
    "Skokie",
    "Sleepy Hollow",
    "Smithton",
    "Somonauk",
    "South Barrington",
    "South Beloit",
    "South Chicago",
    "South Chicago Heights",
    "South Elgin",
    "South Holland",
    "South Jacksonville",
    "South Lawndale",
    "South Pekin",
    "South Roxana",
    "South Shore",
    "Southern View",
    "Sparta",
    "Spring Grove",
    "Spring Valley",
    "Springfield",
    "Stark County",
    "Staunton",
    "Steeleville",
    "Steger",
    "Stephenson County",
    "Sterling",
    "Stickney",
    "Stillman Valley",
    "Stockton",
    "Stone Park",
    "Streamwood",
    "Streator",
    "Sugar Grove",
    "Sullivan",
    "Summit",
    "Sumner",
    "Swansea",
    "Sycamore",
    "Taylorville",
    "Tazewell County",
    "Teutopolis",
    "The Galena Territory",
    "Third Lake",
    "Thomasboro",
    "Thornton",
    "Tilton",
    "Tinley Park",
    "Toledo",
    "Tolono",
    "Toluca",
    "Toulon",
    "Tower Lake",
    "Tremont",
    "Trenton",
    "Troy",
    "Tuscola",
    "Twin Grove",
    "Union County",
    "University Park",
    "Upper Alton",
    "Uptown",
    "Urbana",
    "Valmeyer",
    "Vandalia",
    "Venetian Village",
    "Venice",
    "Vermilion County",
    "Vernon Hills",
    "Vienna",
    "Villa Grove",
    "Villa Park",
    "Village of Campton Hills",
    "Virden",
    "Virginia",
    "Volo",
    "Wabash County",
    "Wadsworth",
    "Walnut",
    "Wamac",
    "Warren",
    "Warren County",
    "Warrensburg",
    "Warrenville",
    "Warsaw",
    "Wasco",
    "Washburn",
    "Washington",
    "Washington County",
    "Washington Park",
    "Waterloo",
    "Waterman",
    "Watseka",
    "Wauconda",
    "Waukegan",
    "Waverly",
    "Wayne",
    "Wayne City",
    "Wayne County",
    "West Chicago",
    "West Dundee",
    "West Elsdon",
    "West Englewood",
    "West Frankfort",
    "West Garfield Park",
    "West Lawn",
    "West Peoria",
    "West Ridge",
    "West Town",
    "Westchester",
    "Western Springs",
    "Westmont",
    "Westville",
    "Wheaton",
    "Wheeling",
    "White County",
    "White Hall",
    "Whiteside County",
    "Will County",
    "Williamson County",
    "Williamsville",
    "Willow Springs",
    "Willowbrook",
    "Wilmette",
    "Wilmington",
    "Winchester",
    "Windsor",
    "Winfield",
    "Winnebago",
    "Winnebago County",
    "Winnetka",
    "Winthrop Harbor",
    "Wonder Lake",
    "Wood Dale",
    "Wood River",
    "Woodford County",
    "Woodlawn",
    "Woodridge",
    "Woodstock",
    "Worden",
    "Worth",
    "Wyoming",
    "Yorkville",
    "Zeigler",
    "Zion"
  ],
  "Indiana": [
    "Aberdeen",
    "Adams County",
    "Akron",
    "Albany",
    "Albion",
    "Alexandria",
    "Allen County",
    "Anderson",
    "Andrews",
    "Angola",
    "Arcadia",
    "Argos",
    "Attica",
    "Auburn",
    "Aurora",
    "Austin",
    "Avilla",
    "Avon",
    "Bargersville",
    "Bartholomew County",
    "Bass Lake",
    "Batesville",
    "Battle Ground",
    "Bedford",
    "Beech Grove",
    "Benton County",
    "Berne",
    "Bicknell",
    "Blackford County",
    "Bloomfield",
    "Bloomington",
    "Bluffton",
    "Boone County",
    "Boonville",
    "Bourbon",
    "Brazil",
    "Bremen",
    "Bright",
    "Bristol",
    "Broad Ripple",
    "Brooklyn",
    "Brookston",
    "Brookville",
    "Brown County",
    "Brownsburg",
    "Brownstown",
    "Burns Harbor",
    "Butler",
    "Cambridge City",
    "Cannelton",
    "Carmel",
    "Carroll County",
    "Cass County",
    "Cayuga",
    "Cedar Lake",
    "Centerville",
    "Chandler",
    "Charlestown",
    "Chesterfield",
    "Chesterton",
    "Churubusco",
    "Cicero",
    "Clark County",
    "Clarksville",
    "Clay County",
    "Clermont",
    "Clinton",
    "Clinton County",
    "Cloverdale",
    "Columbia City",
    "Columbus",
    "Connersville",
    "Converse",
    "Cordry Sweetwater Lakes",
    "Corydon",
    "Country Squire Lakes",
    "Covington",
    "Crawford County",
    "Crawfordsville",
    "Crothersville",
    "Crown Point",
    "Culver",
    "Cumberland",
    "Dale",
    "Daleville",
    "Danville",
    "Darmstadt",
    "Daviess County",
    "Dayton",
    "DeKalb County",
    "DeMotte",
    "Dearborn County",
    "Decatur",
    "Decatur County",
    "Delaware County",
    "Delphi",
    "Dillsboro",
    "Dubois County",
    "Dunkirk",
    "Dunlap",
    "Dyer",
    "East Chicago",
    "Eaton",
    "Edgewood",
    "Edinburgh",
    "Elkhart",
    "Elkhart County",
    "Ellettsville",
    "Elwood",
    "English",
    "Evansville",
    "Fairfield Heights",
    "Fairmount",
    "Fairview Park",
    "Farmersburg",
    "Farmland",
    "Fayette County",
    "Ferdinand",
    "Fish Lake",
    "Fishers",
    "Flora",
    "Floyd County",
    "Fort Branch",
    "Fort Wayne",
    "Fortville",
    "Fountain County",
    "Fowler",
    "Frankfort",
    "Franklin",
    "Franklin County",
    "Frankton",
    "Fremont",
    "French Lick",
    "Fulton County",
    "Galena",
    "Galveston",
    "Garrett",
    "Gary",
    "Gas City",
    "Geneva",
    "Georgetown",
    "Gibson County",
    "Goodland",
    "Goshen",
    "Grabill",
    "Granger",
    "Grant County",
    "Greencastle",
    "Greendale",
    "Greene County",
    "Greenfield",
    "Greensburg",
    "Greentown",
    "Greenwood",
    "Griffith",
    "Grissom Air Force Base",
    "Gulivoire Park",
    "Hagerstown",
    "Hamilton",
    "Hamilton County",
    "Hammond",
    "Hancock County",
    "Hanover",
    "Harlan",
    "Harrison County",
    "Hartford City",
    "Haubstadt",
    "Hebron",
    "Hendricks County",
    "Henry County",
    "Henryville",
    "Heritage Lake",
    "Hidden Valley",
    "Highland",
    "Hobart",
    "Hope",
    "Howard County",
    "Hudson Lake",
    "Huntertown",
    "Huntingburg",
    "Huntington",
    "Huntington County",
    "Indian Heights",
    "Indianapolis",
    "Ingalls",
    "Jackson County",
    "Jasonville",
    "Jasper",
    "Jasper County",
    "Jay County",
    "Jefferson County",
    "Jeffersonville",
    "Jennings County",
    "Johnson County",
    "Jonesboro",
    "Kendallville",
    "Kentland",
    "Kingsford Heights",
    "Knightstown",
    "Knox",
    "Knox County",
    "Kokomo",
    "Koontz Lake",
    "Kosciusko County",
    "Kouts",
    "La Porte",
    "LaGrange County",
    "LaPorte",
    "LaPorte County",
    "Lafayette",
    "Lagrange",
    "Lake County",
    "Lake Dalecarlia",
    "Lake Station",
    "Lakes of the Four Seasons",
    "Lapel",
    "Lawrence",
    "Lawrence County",
    "Lawrenceburg",
    "Lebanon",
    "Leo-Cedarville",
    "Liberty",
    "Ligonier",
    "Linton",
    "Logansport",
    "Long Beach",
    "Loogootee",
    "Lowell",
    "Lynn",
    "Madison",
    "Madison County",
    "Marion",
    "Marion County",
    "Markle",
    "Marshall County",
    "Martin County",
    "Martinsville",
    "McCordsville",
    "Melody Hill",
    "Meridian Hills",
    "Merrillville",
    "Miami County",
    "Michigan City",
    "Middlebury",
    "Middletown",
    "Milan",
    "Milford",
    "Mishawaka",
    "Mitchell",
    "Monon",
    "Monroe County",
    "Monroeville",
    "Monrovia",
    "Montgomery County",
    "Monticello",
    "Montpelier",
    "Mooresville",
    "Morgan County",
    "Morocco",
    "Morristown",
    "Mount Vernon",
    "Mulberry",
    "Muncie",
    "Munster",
    "Nappanee",
    "Nashville",
    "New Albany",
    "New Carlisle",
    "New Castle",
    "New Chicago",
    "New Haven",
    "New Palestine",
    "New Paris",
    "New Pekin",
    "New Whiteland",
    "Newburgh",
    "Newport",
    "Newton County",
    "Noble County",
    "Noblesville",
    "North Judson",
    "North Liberty",
    "North Madison",
    "North Manchester",
    "North Terre Haute",
    "North Vernon",
    "North Webster",
    "Notre Dame",
    "Oak Park",
    "Oakland City",
    "Odon",
    "Ogden Dunes",
    "Ohio County",
    "Oolitic",
    "Orange County",
    "Orleans",
    "Osceola",
    "Osgood",
    "Ossian",
    "Otterbein",
    "Owen County",
    "Owensville",
    "Oxford",
    "Paoli",
    "Parke County",
    "Parker City",
    "Pendleton",
    "Perry County",
    "Peru",
    "Petersburg",
    "Pierceton",
    "Pike County",
    "Pittsboro",
    "Plainfield",
    "Plymouth",
    "Portage",
    "Porter",
    "Porter County",
    "Portland",
    "Posey County",
    "Poseyville",
    "Princes Lakes",
    "Princeton",
    "Pulaski County",
    "Putnam County",
    "Randolph County",
    "Redkey",
    "Remington",
    "Rensselaer",
    "Richmond",
    "Ripley County",
    "Rising Sun",
    "Roanoke",
    "Rochester",
    "Rockport",
    "Rockville",
    "Rome City",
    "Roselawn",
    "Rossville",
    "Rush County",
    "Rushville",
    "Russiaville",
    "Saint John",
    "Saint Joseph County",
    "Saint Paul",
    "Salem",
    "Santa Claus",
    "Schererville",
    "Scott County",
    "Scottsburg",
    "Seelyville",
    "Sellersburg",
    "Seymour",
    "Shadeland",
    "Shelburn",
    "Shelby County",
    "Shelbyville",
    "Sheridan",
    "Shoals",
    "Shorewood Forest",
    "Simonton Lake",
    "Smithville-Sanders",
    "South Bend",
    "South Haven",
    "South Whitley",
    "Southport",
    "Speedway",
    "Spencer",
    "Spencer County",
    "Starke County",
    "Steuben County",
    "Sullivan",
    "Sullivan County",
    "Sunman",
    "Sweetser",
    "Switzerland County",
    "Syracuse",
    "Tell City",
    "Terre Haute",
    "Thorntown",
    "Tippecanoe County",
    "Tipton",
    "Tipton County",
    "Topeka",
    "Trafalgar",
    "Trail Creek",
    "Tri-Lakes",
    "Union City",
    "Union County",
    "Upland",
    "Valparaiso",
    "Vanderburgh County",
    "Veedersburg",
    "Vermillion County",
    "Versailles",
    "Vevay",
    "Vigo County",
    "Vincennes",
    "Wabash",
    "Wabash County",
    "Wakarusa",
    "Walkerton",
    "Walton",
    "Wanatah",
    "Warren",
    "Warren County",
    "Warren Park",
    "Warrick County",
    "Warsaw",
    "Washington",
    "Washington County",
    "Waterloo",
    "Wayne County",
    "Wells County",
    "West Lafayette",
    "West Terre Haute",
    "Westfield",
    "Westport",
    "Westville",
    "White County",
    "Whiteland",
    "Whitestown",
    "Whiting",
    "Whitley County",
    "Williamsport",
    "Winamac",
    "Winchester",
    "Winfield",
    "Winona Lake",
    "Wolcottville",
    "Woodburn",
    "Worthington",
    "Yorktown",
    "Zionsville"
  ],
  "Iowa": [
    "Ackley",
    "Adair County",
    "Adams County",
    "Adel",
    "Akron",
    "Albia",
    "Algona",
    "Allamakee County",
    "Allison",
    "Alta",
    "Alton",
    "Altoona",
    "Ames",
    "Anamosa",
    "Ankeny",
    "Aplington",
    "Appanoose County",
    "Arnolds Park",
    "Asbury",
    "Atkins",
    "Atlantic",
    "Audubon",
    "Audubon County",
    "Avoca",
    "Baxter",
    "Bedford",
    "Belle Plaine",
    "Bellevue",
    "Belmond",
    "Benton County",
    "Bettendorf",
    "Black Hawk County",
    "Bloomfield",
    "Blue Grass",
    "Bondurant",
    "Boone",
    "Boone County",
    "Bremer County",
    "Britt",
    "Brooklyn",
    "Buchanan County",
    "Buena Vista County",
    "Buffalo",
    "Buffalo (historical)",
    "Burlington",
    "Butler County",
    "Calhoun County",
    "Camanche",
    "Carlisle",
    "Carroll",
    "Carroll County",
    "Carter Lake",
    "Cascade",
    "Cass County",
    "Cedar County",
    "Cedar Falls",
    "Cedar Rapids",
    "Center Point",
    "Centerville",
    "Central City",
    "Cerro Gordo County",
    "Chariton",
    "Charles City",
    "Cherokee",
    "Cherokee County",
    "Chickasaw County",
    "Clarinda",
    "Clarion",
    "Clarke County",
    "Clarksville",
    "Clay County",
    "Clayton County",
    "Clear Lake",
    "Clinton",
    "Clinton County",
    "Clive",
    "Colfax",
    "Columbus Junction",
    "Conrad",
    "Coon Rapids",
    "Coralville",
    "Corning",
    "Corydon",
    "Council Bluffs",
    "Crawford County",
    "Cresco",
    "Creston",
    "Dakota City",
    "Dallas Center",
    "Dallas County",
    "Davenport",
    "Davis County",
    "De Soto",
    "De Witt",
    "Decatur County",
    "Decorah",
    "Delaware County",
    "Denison",
    "Denver",
    "Des Moines",
    "Des Moines County",
    "Dickinson County",
    "Dike",
    "Dubuque",
    "Dubuque County",
    "Durant",
    "Dyersville",
    "Dysart",
    "Eagle Grove",
    "Earlham",
    "Eddyville",
    "Eldora",
    "Eldridge",
    "Elk Run Heights",
    "Elkader",
    "Ely",
    "Emmet County",
    "Emmetsburg",
    "Epworth",
    "Estherville",
    "Evansdale",
    "Fairbank",
    "Fairfax",
    "Fairfield",
    "Farley",
    "Fayette",
    "Fayette County",
    "Floyd County",
    "Forest City",
    "Fort Dodge",
    "Fort Madison",
    "Franklin County",
    "Fremont County",
    "Garner",
    "George",
    "Gilbert",
    "Glenwood",
    "Glidden",
    "Granger",
    "Greene",
    "Greene County",
    "Greenfield",
    "Grimes",
    "Grinnell",
    "Grundy Center",
    "Grundy County",
    "Guthrie Center",
    "Guthrie County",
    "Guttenberg",
    "Hamburg",
    "Hamilton County",
    "Hampton",
    "Hancock County",
    "Hardin County",
    "Harlan",
    "Harrison County",
    "Hartley",
    "Hawarden",
    "Henry County",
    "Hiawatha",
    "Holstein",
    "Howard County",
    "Hudson",
    "Hull",
    "Humboldt",
    "Humboldt County",
    "Huxley",
    "Ida County",
    "Ida Grove",
    "Independence",
    "Indianola",
    "Iowa City",
    "Iowa County",
    "Iowa Falls",
    "Jackson County",
    "Jasper County",
    "Jefferson",
    "Jefferson County",
    "Jesup",
    "Jewell",
    "Johnson County",
    "Johnston",
    "Jones County",
    "Kalona",
    "Keokuk",
    "Keokuk County",
    "Keosauqua",
    "Kingsley",
    "Knoxville",
    "Kossuth County",
    "La Porte City",
    "Lake City",
    "Lake Mills",
    "Lake Panorama",
    "Lake Park",
    "Lake View",
    "Lamoni",
    "Laurens",
    "Le Claire",
    "Le Mars",
    "Lee County",
    "Lenox",
    "Leon",
    "Linn County",
    "Lisbon",
    "Logan",
    "Lone Tree",
    "Louisa County",
    "Lucas County",
    "Lyon County",
    "Madison County",
    "Madrid",
    "Maharishi Vedic City",
    "Mahaska County",
    "Malvern",
    "Manchester",
    "Manly",
    "Manning",
    "Manson",
    "Mapleton",
    "Maquoketa",
    "Marcus",
    "Marengo",
    "Marion",
    "Marion County",
    "Marshall County",
    "Marshalltown",
    "Mason City",
    "Mechanicsville",
    "Mediapolis",
    "Melcher-Dallas",
    "Milford",
    "Mills County",
    "Missouri Valley",
    "Mitchell County",
    "Mitchellville",
    "Monona",
    "Monona County",
    "Monroe",
    "Monroe County",
    "Montezuma",
    "Montgomery County",
    "Monticello",
    "Mount Ayr",
    "Mount Pleasant",
    "Mount Vernon",
    "Moville",
    "Muscatine",
    "Muscatine County",
    "Nashua",
    "Nevada",
    "New Hampton",
    "New London",
    "New Sharon",
    "Newton",
    "Nora Springs",
    "North English",
    "North Liberty",
    "Northwood",
    "Norwalk",
    "O'Brien County",
    "Oakland",
    "Oelwein",
    "Ogden",
    "Onawa",
    "Orange City",
    "Osage",
    "Osceola",
    "Osceola County",
    "Oskaloosa",
    "Ottumwa",
    "Page County",
    "Palo",
    "Palo Alto County",
    "Panora",
    "Park View",
    "Parkersburg",
    "Paullina",
    "Pella",
    "Peosta",
    "Perry",
    "Pleasant Hill",
    "Pleasantville",
    "Plymouth County",
    "Pocahontas",
    "Pocahontas County",
    "Polk City",
    "Polk County",
    "Postville",
    "Pottawattamie County",
    "Poweshiek County",
    "Prairie City",
    "Preston",
    "Primghar",
    "Red Oak",
    "Reinbeck",
    "Remsen",
    "Ringgold County",
    "Riverside",
    "Robins",
    "Rock Rapids",
    "Rock Valley",
    "Rockwell",
    "Rockwell City",
    "Roland",
    "Sac City",
    "Sac County",
    "Saint Ansgar",
    "Sanborn",
    "Saylorville",
    "Scott County",
    "Sergeant Bluff",
    "Sheffield",
    "Shelby County",
    "Sheldon",
    "Shell Rock",
    "Shenandoah",
    "Sibley",
    "Sidney",
    "Sigourney",
    "Sioux Center",
    "Sioux City",
    "Sioux County",
    "Slater",
    "Solon",
    "Spencer",
    "Spirit Lake",
    "Springville",
    "State Center",
    "Storm Lake",
    "Story City",
    "Story County",
    "Strawberry Point",
    "Stuart",
    "Sumner",
    "Tama",
    "Tama County",
    "Taylor County",
    "Tiffin",
    "Tipton",
    "Toledo",
    "Traer",
    "Tripoli",
    "Union County",
    "University Heights",
    "Urbana",
    "Urbandale",
    "Van Buren County",
    "Van Meter",
    "Villisca",
    "Vinton",
    "Walcott",
    "Walford",
    "Wapello",
    "Wapello County",
    "Warren County",
    "Washington",
    "Washington County",
    "Waterloo",
    "Waukee",
    "Waukon",
    "Waverly",
    "Wayne County",
    "Webster City",
    "Webster County",
    "Wellman",
    "West Branch",
    "West Burlington",
    "West Des Moines",
    "West Liberty",
    "West Union",
    "Williamsburg",
    "Wilton",
    "Windsor Heights",
    "Winfield",
    "Winnebago County",
    "Winneshiek County",
    "Winterset",
    "Woodbine",
    "Woodbury County",
    "Woodward",
    "Worth County",
    "Wright County"
  ],
  "Kansas": [
    "Abilene",
    "Allen County",
    "Alma",
    "Altamont",
    "Anderson County",
    "Andover",
    "Anthony",
    "Arkansas City",
    "Arma",
    "Ashland",
    "Atchison",
    "Atchison County",
    "Atwood",
    "Auburn",
    "Augusta",
    "Baldwin City",
    "Barber County",
    "Barton County",
    "Basehor",
    "Baxter Springs",
    "Bellaire",
    "Belle Plaine",
    "Belleville",
    "Beloit",
    "Bonner Springs",
    "Bourbon County",
    "Brown County",
    "Buhler",
    "Burlington",
    "Butler County",
    "Caldwell",
    "Caney",
    "Carbondale",
    "Chanute",
    "Chapman",
    "Chase County",
    "Chautauqua County",
    "Cheney",
    "Cherokee County",
    "Cherryvale",
    "Chetopa",
    "Cheyenne County",
    "Cimarron",
    "Clark County",
    "Clay Center",
    "Clay County",
    "Clearwater",
    "Cloud County",
    "Coffey County",
    "Coffeyville",
    "Colby",
    "Coldwater",
    "Columbus",
    "Colwich",
    "Comanche County",
    "Concordia",
    "Conway Springs",
    "Cottonwood Falls",
    "Council Grove",
    "Cowley County",
    "Crawford County",
    "De Soto",
    "Decatur County",
    "Derby",
    "Dickinson County",
    "Dighton",
    "Dodge City",
    "Doniphan County",
    "Douglas County",
    "Douglass",
    "Edgerton",
    "Edwards County",
    "Edwardsville",
    "El Dorado",
    "Elk County",
    "Elkhart",
    "Ellinwood",
    "Ellis",
    "Ellis County",
    "Ellsworth",
    "Ellsworth County",
    "Elwood",
    "Emporia",
    "Erie",
    "Eudora",
    "Eureka",
    "Fairway",
    "Finney County",
    "Ford County",
    "Fort Riley North",
    "Fort Scott",
    "Franklin County",
    "Fredonia",
    "Frontenac",
    "Galena",
    "Garden City",
    "Gardner",
    "Garnett",
    "Geary County",
    "Girard",
    "Goddard",
    "Goodland",
    "Gove",
    "Gove County",
    "Graham County",
    "Grandview Plaza",
    "Grant County",
    "Gray County",
    "Great Bend",
    "Greeley County",
    "Greensburg",
    "Greenwood County",
    "Halstead",
    "Hamilton County",
    "Harper",
    "Harper County",
    "Harvey County",
    "Haskell County",
    "Haven",
    "Hays",
    "Haysville",
    "Herington",
    "Hesston",
    "Hiawatha",
    "Highland",
    "Hill City",
    "Hillsboro",
    "Hodgeman County",
    "Hoisington",
    "Holcomb",
    "Holton",
    "Horton",
    "Howard",
    "Hoxie",
    "Hugoton",
    "Humboldt",
    "Hutchinson",
    "Independence",
    "Inman",
    "Iola",
    "Jackson County",
    "Jefferson County",
    "Jetmore",
    "Jewell County",
    "Johnson",
    "Johnson County",
    "Junction City",
    "Kansas City",
    "Kearny County",
    "Kechi",
    "Kingman",
    "Kingman County",
    "Kinsley",
    "Kiowa",
    "Kiowa County",
    "La Crosse",
    "La Cygne",
    "Labette County",
    "Lakin",
    "Lane County",
    "Lansing",
    "Larned",
    "Lawrence",
    "Leavenworth",
    "Leavenworth County",
    "Leawood",
    "Lenexa",
    "Leoti",
    "Liberal",
    "Lincoln",
    "Lincoln County",
    "Lindsborg",
    "Linn County",
    "Logan County",
    "Louisburg",
    "Lyndon",
    "Lyon County",
    "Lyons",
    "Maize",
    "Manhattan",
    "Mankato",
    "Marion",
    "Marion County",
    "Marshall County",
    "Marysville",
    "McConnell AFB",
    "McPherson",
    "McPherson County",
    "Meade",
    "Meade County",
    "Medicine Lodge",
    "Merriam",
    "Miami County",
    "Minneapolis",
    "Mission",
    "Mission Hills",
    "Mitchell County",
    "Montgomery County",
    "Morris County",
    "Morton County",
    "Mound City",
    "Moundridge",
    "Mulvane",
    "Nemaha County",
    "Neodesha",
    "Neosho County",
    "Ness City",
    "Ness County",
    "New Century, KS",
    "Newton",
    "Nickerson",
    "North Newton",
    "Norton",
    "Norton County",
    "Oakley",
    "Oberlin",
    "Ogden",
    "Olathe",
    "Osage City",
    "Osage County",
    "Osawatomie",
    "Osborne",
    "Osborne County",
    "Oskaloosa",
    "Oswego",
    "Ottawa",
    "Ottawa County",
    "Overbrook",
    "Overland Park",
    "Oxford",
    "Paola",
    "Park City",
    "Parsons",
    "Pawnee County",
    "Peabody",
    "Phillips County",
    "Phillipsburg",
    "Pittsburg",
    "Plains",
    "Plainville",
    "Pleasanton",
    "Pottawatomie County",
    "Prairie Village",
    "Pratt",
    "Pratt County",
    "Rawlins County",
    "Reno County",
    "Republic County",
    "Rice County",
    "Riley County",
    "Roeland Park",
    "Rooks County",
    "Rose Hill",
    "Rossville",
    "Rush County",
    "Russell",
    "Russell County",
    "Sabetha",
    "Saint Francis",
    "Saint John",
    "Saint Marys",
    "Salina",
    "Saline County",
    "Satanta",
    "Scott City",
    "Scott County",
    "Sedan",
    "Sedgwick",
    "Sedgwick County",
    "Seneca",
    "Seward County",
    "Sharon Springs",
    "Shawnee",
    "Shawnee County",
    "Sheridan County",
    "Sherman County",
    "Silver Lake",
    "Smith Center",
    "Smith County",
    "Solomon",
    "South Hutchinson",
    "Spring Hill",
    "Stafford County",
    "Stanton County",
    "Sterling",
    "Stevens County",
    "Stockton",
    "Sublette",
    "Sumner County",
    "Syracuse",
    "Thomas County",
    "Tonganoxie",
    "Topeka",
    "Towanda",
    "Trego County",
    "Tribune",
    "Troy",
    "Ulysses",
    "Valley Center",
    "Valley Falls",
    "Victoria",
    "WaKeeney",
    "Wabaunsee County",
    "Wallace County",
    "Wamego",
    "Washington",
    "Washington County",
    "Wathena",
    "Wellington",
    "Wellsville",
    "Westmoreland",
    "Westwood",
    "Wichita",
    "Wichita County",
    "Wilson County",
    "Winfield",
    "Woodson County",
    "Wyandotte County",
    "Yates Center"
  ],
  "Kentucky": [
    "Adair County",
    "Albany",
    "Alexandria",
    "Allen County",
    "Anchorage",
    "Anderson County",
    "Annville",
    "Ashland",
    "Auburn",
    "Audubon Park",
    "Augusta",
    "Ballard County",
    "Barbourmeade",
    "Barbourville",
    "Bardstown",
    "Bardwell",
    "Barren County",
    "Bath County",
    "Beattyville",
    "Beaver Dam",
    "Bedford",
    "Beechwood Village",
    "Bell County",
    "Bellevue",
    "Benton",
    "Berea",
    "Bloomfield",
    "Boone County",
    "Booneville",
    "Bourbon County",
    "Bowling Green",
    "Boyd County",
    "Boyle County",
    "Bracken County",
    "Brandenburg",
    "Breathitt County",
    "Breckinridge Center",
    "Breckinridge County",
    "Brodhead",
    "Brooks",
    "Brooksville",
    "Brownsville",
    "Buckner",
    "Buechel",
    "Bullitt County",
    "Burkesville",
    "Burlington",
    "Butler County",
    "Cadiz",
    "Caldwell County",
    "Calhoun",
    "Calloway County",
    "Calvert City",
    "Camargo",
    "Campbell County",
    "Campbellsville",
    "Campton",
    "Carlisle",
    "Carlisle County",
    "Carroll County",
    "Carrollton",
    "Carter County",
    "Casey County",
    "Catlettsburg",
    "Cave City",
    "Central City",
    "Christian County",
    "Clark County",
    "Claryville",
    "Clay",
    "Clay City",
    "Clay County",
    "Clinton",
    "Clinton County",
    "Cloverport",
    "Coal Run Village",
    "Cold Spring",
    "Coldstream",
    "Columbia",
    "Corbin",
    "Covington",
    "Crescent Springs",
    "Crestview Hills",
    "Crestwood",
    "Crittenden",
    "Crittenden County",
    "Cumberland",
    "Cumberland County",
    "Cynthiana",
    "Danville",
    "Daviess County",
    "Dawson Springs",
    "Dayton",
    "Dixon",
    "Doe Valley",
    "Douglass Hills",
    "Dry Ridge",
    "Earlington",
    "Eddyville",
    "Edgewood",
    "Edmonson County",
    "Edmonton",
    "Elizabethtown",
    "Elk Creek",
    "Elkfork",
    "Elkton",
    "Elliott County",
    "Elsmere",
    "Eminence",
    "Erlanger",
    "Estill County",
    "Fairdale",
    "Falmouth",
    "Farley",
    "Fayette County",
    "Fern Creek",
    "Flatwoods",
    "Fleming County",
    "Flemingsburg",
    "Florence",
    "Floyd County",
    "Fort Campbell North",
    "Fort Knox",
    "Fort Mitchell",
    "Fort Thomas",
    "Fort Wright",
    "Francisville",
    "Frankfort",
    "Franklin",
    "Franklin County",
    "Frenchburg",
    "Fulton",
    "Fulton County",
    "Gallatin County",
    "Garrard County",
    "Georgetown",
    "Glasgow",
    "Grant County",
    "Graves County",
    "Graymoor-Devondale",
    "Grayson",
    "Grayson County",
    "Green County",
    "Greensburg",
    "Greenup",
    "Greenup County",
    "Greenville",
    "Guthrie",
    "Hancock County",
    "Hardin County",
    "Hardinsburg",
    "Harlan",
    "Harlan County",
    "Harrison County",
    "Harrodsburg",
    "Hart County",
    "Hartford",
    "Hawesville",
    "Hazard",
    "Hebron",
    "Hebron Estates",
    "Henderson",
    "Henderson County",
    "Hendron",
    "Henry County",
    "Heritage Creek",
    "Hickman",
    "Hickman County",
    "Highland Heights",
    "Highview",
    "Hillview",
    "Hindman",
    "Hodgenville",
    "Hopkins County",
    "Hopkinsville",
    "Horse Cave",
    "Hurstbourne",
    "Hurstbourne Acres",
    "Hyden",
    "Independence",
    "Indian Hills",
    "Indian Hills Cherokee Section",
    "Inez",
    "Ironville",
    "Irvine",
    "Irvington",
    "Jackson",
    "Jackson County",
    "Jamestown",
    "Jefferson County",
    "Jeffersontown",
    "Jeffersonville",
    "Jenkins",
    "Jessamine County",
    "Johnson County",
    "Junction City",
    "Kenton County",
    "Knott County",
    "Knottsville",
    "Knox County",
    "La Center",
    "La Grange",
    "Lakeside Park",
    "Lancaster",
    "Larue County",
    "Laurel County",
    "Lawrence County",
    "Lawrenceburg",
    "Lebanon",
    "Lebanon Junction",
    "Ledbetter",
    "Lee County",
    "Leitchfield",
    "Leslie County",
    "Letcher County",
    "Lewis County",
    "Lewisport",
    "Lexington",
    "Lexington-Fayette",
    "Liberty",
    "Lincoln County",
    "Livermore",
    "Livingston County",
    "Logan County",
    "London",
    "Louisa",
    "Louisville",
    "Ludlow",
    "Lyndon",
    "Lyon County",
    "Madison County",
    "Madisonville",
    "Magoffin County",
    "Manchester",
    "Marion",
    "Marion County",
    "Marshall County",
    "Martin County",
    "Mason County",
    "Masonville",
    "Massac",
    "Mayfield",
    "Maysville",
    "McCracken County",
    "McCreary County",
    "McKee",
    "McLean County",
    "Meade County",
    "Meads",
    "Menifee County",
    "Mercer County",
    "Metcalfe County",
    "Middlesboro",
    "Middletown",
    "Midway",
    "Monroe County",
    "Montgomery County",
    "Monticello",
    "Morehead",
    "Morgan County",
    "Morganfield",
    "Morgantown",
    "Mount Olivet",
    "Mount Sterling",
    "Mount Vernon",
    "Mount Washington",
    "Muhlenberg County",
    "Munfordville",
    "Murray",
    "Nelson County",
    "New Castle",
    "Newburg",
    "Newport",
    "Nicholas County",
    "Nicholasville",
    "North Corbin",
    "Northfield",
    "Nortonville",
    "Oak Grove",
    "Oakbrook",
    "Ohio County",
    "Okolona",
    "Oldham County",
    "Olive Hill",
    "Orchard Grass Hills",
    "Owen County",
    "Owensboro",
    "Owenton",
    "Owingsville",
    "Owsley County",
    "Paducah",
    "Paintsville",
    "Paris",
    "Park Hills",
    "Pendleton County",
    "Perry County",
    "Pewee Valley",
    "Pike County",
    "Pikeville",
    "Pine Knot",
    "Pineville",
    "Pioneer Village",
    "Plano",
    "Pleasure Ridge Park",
    "Powell County",
    "Prestonsburg",
    "Princeton",
    "Prospect",
    "Providence",
    "Pulaski County",
    "Raceland",
    "Radcliff",
    "Reidland",
    "Richmond",
    "Robertson County",
    "Rockcastle County",
    "Rowan County",
    "Russell",
    "Russell County",
    "Russell Springs",
    "Russellville",
    "Ryland Heights",
    "Saint Dennis",
    "Saint Matthews",
    "Saint Regis Park",
    "Salyersville",
    "Sandy Hook",
    "Scott County",
    "Scottsville",
    "Sebree",
    "Shelby County",
    "Shelbyville",
    "Shepherdsville",
    "Shively",
    "Silver Grove",
    "Simpson County",
    "Simpsonville",
    "Smithland",
    "Somerset",
    "South Shore",
    "Southgate",
    "Spencer County",
    "Springfield",
    "Stanford",
    "Stanton",
    "Stearns",
    "Sturgis",
    "Taylor County",
    "Taylor Mill",
    "Taylorsville",
    "Todd County",
    "Tompkinsville",
    "Trigg County",
    "Trimble County",
    "Union",
    "Union County",
    "Valley Station",
    "Van Lear",
    "Vanceburg",
    "Verona",
    "Versailles",
    "Villa Hills",
    "Vine Grove",
    "Walton",
    "Warren County",
    "Warsaw",
    "Washington County",
    "Watterson Park",
    "Wayne County",
    "Webster County",
    "West Buechel",
    "West Liberty",
    "Westwood",
    "Whitesburg",
    "Whitley City",
    "Whitley County",
    "Wickliffe",
    "Wilder",
    "Williamsburg",
    "Williamstown",
    "Wilmore",
    "Winchester",
    "Windy Hills",
    "Wolfe County",
    "Woodford County",
    "Worthington",
    "Worthington Hills",
    "Wurtland"
  ],
  "Louisiana": [
    "Abbeville",
    "Abita Springs",
    "Acadia Parish",
    "Addis",
    "Albany",
    "Alexandria",
    "Allen Parish",
    "Ama",
    "Amelia",
    "Amite",
    "Arabi",
    "Arcadia",
    "Arnaudville",
    "Ascension Parish",
    "Assumption Parish",
    "Avondale",
    "Avoyelles Parish",
    "Baker",
    "Baldwin",
    "Ball",
    "Banks Springs",
    "Barataria",
    "Basile",
    "Bastrop",
    "Baton Rouge",
    "Bawcomville",
    "Bayou Cane",
    "Bayou Gauche",
    "Bayou Vista",
    "Beauregard Parish",
    "Belle Chasse",
    "Belle Rose",
    "Benton",
    "Bernice",
    "Berwick",
    "Bienville Parish",
    "Blanchard",
    "Bogalusa",
    "Bossier City",
    "Bossier Parish",
    "Bourg",
    "Boutte",
    "Breaux Bridge",
    "Bridge City",
    "Broussard",
    "Brownsfield",
    "Brownsville",
    "Brusly",
    "Bunkie",
    "Caddo Parish",
    "Cade",
    "Calcasieu Parish",
    "Caldwell Parish",
    "Cameron",
    "Cameron Parish",
    "Campti",
    "Carencro",
    "Carlyss",
    "Carville",
    "Catahoula",
    "Catahoula Parish",
    "Cecilia",
    "Central",
    "Chackbay",
    "Chalmette",
    "Charenton",
    "Chauvin",
    "Church Point",
    "Claiborne",
    "Claiborne Parish",
    "Clarks",
    "Clinton",
    "Colfax",
    "Columbia",
    "Concordia Parish",
    "Convent",
    "Cottonport",
    "Coushatta",
    "Covington",
    "Crowley",
    "Cullen",
    "Cut Off",
    "De Soto Parish",
    "DeQuincy",
    "DeRidder",
    "Delcambre",
    "Delhi",
    "Denham Springs",
    "Des Allemands",
    "Destrehan",
    "Deville",
    "Donaldsonville",
    "Dulac",
    "Duson",
    "East Baton Rouge Parish",
    "East Carroll Parish",
    "East Feliciana Parish",
    "Eastwood",
    "Eden Isle",
    "Edgard",
    "Elmwood",
    "Elton",
    "Erath",
    "Erwinville",
    "Estelle",
    "Eunice",
    "Evangeline Parish",
    "Farmerville",
    "Ferriday",
    "Fort Polk North",
    "Fort Polk South",
    "Franklin",
    "Franklin Parish",
    "Franklinton",
    "French Settlement",
    "Galliano",
    "Gardere",
    "Garyville",
    "Glenmora",
    "Golden Meadow",
    "Gonzales",
    "Grambling",
    "Gramercy",
    "Grand Isle",
    "Grand Point",
    "Grant Parish",
    "Gray",
    "Greensburg",
    "Greenwood",
    "Gretna",
    "Gueydan",
    "Hackberry",
    "Hahnville",
    "Hammond",
    "Harahan",
    "Harrisonburg",
    "Harvey",
    "Haughton",
    "Haynesville",
    "Henderson",
    "Homer",
    "Houma",
    "Iberia Parish",
    "Iberville Parish",
    "Independence",
    "Inniswold",
    "Iota",
    "Iowa",
    "Jackson",
    "Jackson Parish",
    "Jean Lafitte",
    "Jeanerette",
    "Jefferson",
    "Jefferson Davis Parish",
    "Jefferson Parish",
    "Jena",
    "Jennings",
    "Jonesboro",
    "Jonesville",
    "Kaplan",
    "Kenner",
    "Kentwood",
    "Killian",
    "Kinder",
    "Krotz Springs",
    "La Salle Parish",
    "Labadieville",
    "Lacombe",
    "Lafayette",
    "Lafayette Parish",
    "Lafourche Parish",
    "Lake Arthur",
    "Lake Charles",
    "Lake Providence",
    "Lakeshore",
    "Laplace",
    "Larose",
    "Lawtell",
    "Lecompte",
    "Leesville",
    "Leonville",
    "Lincoln Parish",
    "Livingston",
    "Livingston Parish",
    "Livonia",
    "Lockport",
    "Lockport Heights",
    "Logansport",
    "Luling",
    "Lutcher",
    "Madison Parish",
    "Mamou",
    "Mandeville",
    "Mansfield",
    "Mansura",
    "Many",
    "Maringouin",
    "Marksville",
    "Marrero",
    "Mathews",
    "Maurice",
    "Melville",
    "Meraux",
    "Merrydale",
    "Merryville",
    "Metairie",
    "Metairie Terrace",
    "Midway",
    "Milton",
    "Minden",
    "Minorca",
    "Monroe",
    "Montegut",
    "Monticello",
    "Montz",
    "Morehouse Parish",
    "Morgan City",
    "Moss Bluff",
    "Napoleonville",
    "Natalbany",
    "Natchitoches",
    "Natchitoches Parish",
    "New Iberia",
    "New Llano",
    "New Orleans",
    "New Roads",
    "New Sarpy",
    "Newellton",
    "Norco",
    "North Vacherie",
    "Oak Grove",
    "Oak Hills Place",
    "Oakdale",
    "Oberlin",
    "Old Jefferson",
    "Olla",
    "Opelousas",
    "Orleans Parish",
    "Ossun",
    "Ouachita Parish",
    "Paradis",
    "Patterson",
    "Paulina",
    "Pearl River",
    "Pierre Part",
    "Pine Prairie",
    "Pineville",
    "Plaquemine",
    "Plaquemines Parish",
    "Pointe Coupee Parish",
    "Ponchatoula",
    "Port Allen",
    "Port Barre",
    "Port Sulphur",
    "Poydras",
    "Prairieville",
    "Presquille",
    "Prien",
    "Raceland",
    "Rapides Parish",
    "Rayne",
    "Rayville",
    "Red Chute",
    "Red River Parish",
    "Reserve",
    "Richland Parish",
    "Richwood",
    "Ringgold",
    "River Ridge",
    "Roseland",
    "Rosepine",
    "Ruston",
    "Sabine Parish",
    "Saint Bernard Parish",
    "Saint Charles Parish",
    "Saint Francisville",
    "Saint Gabriel",
    "Saint Helena Parish",
    "Saint James Parish",
    "Saint John the Baptist Parish",
    "Saint Joseph",
    "Saint Landry Parish",
    "Saint Martin Parish",
    "Saint Martinville",
    "Saint Mary Parish",
    "Saint Rose",
    "Saint Tammany Parish",
    "Schriever",
    "Scott",
    "Shenandoah",
    "Shreveport",
    "Sibley",
    "Simmesport",
    "Slidell",
    "Sorrento",
    "South Vacherie",
    "Springhill",
    "Sterlington",
    "Stonewall",
    "Sulphur",
    "Sunset",
    "Supreme",
    "Swartz",
    "Tallulah",
    "Tangipahoa Parish",
    "Tensas Parish",
    "Terrebonne Parish",
    "Terrytown",
    "Thibodaux",
    "Timberlane",
    "Union Parish",
    "Urania",
    "Vermilion Parish",
    "Vernon Parish",
    "Vidalia",
    "Vienna Bend",
    "Village Saint George",
    "Ville Platte",
    "Vinton",
    "Violet",
    "Vivian",
    "Waggaman",
    "Walker",
    "Washington Parish",
    "Watson",
    "Webster Parish",
    "Welsh",
    "West Baton Rouge Parish",
    "West Carroll Parish",
    "West Feliciana Parish",
    "West Ferriday",
    "West Monroe",
    "Westlake",
    "Westminster",
    "Westwego",
    "White Castle",
    "Winn Parish",
    "Winnfield",
    "Winnsboro",
    "Woodmere",
    "Woodworth",
    "Youngsville",
    "Zachary",
    "Zwolle"
  ],
  "Maine": [
    "Acton",
    "Addison",
    "Albion",
    "Alfred",
    "Androscoggin County",
    "Appleton",
    "Aroostook County",
    "Arundel",
    "Auburn",
    "Augusta",
    "Bangor",
    "Bar Harbor",
    "Bath",
    "Belfast",
    "Belgrade",
    "Benton",
    "Berwick",
    "Bethel",
    "Biddeford",
    "Boothbay",
    "Boothbay Harbor",
    "Bradford",
    "Bradley",
    "Brewer",
    "Bridgton",
    "Bristol",
    "Brooks",
    "Brownfield",
    "Brownville",
    "Brunswick",
    "Buckfield",
    "Bucksport",
    "Burnham",
    "Buxton",
    "Calais",
    "Camden",
    "Canaan",
    "Canton",
    "Cape Neddick",
    "Caribou",
    "Carmel",
    "Castine",
    "Charleston",
    "Chelsea",
    "Cherryfield",
    "Chesterville",
    "China",
    "Chisholm",
    "Clinton",
    "Corinna",
    "Cornish",
    "Cornville",
    "Cumberland Center",
    "Cumberland County",
    "Cushing",
    "Damariscotta",
    "Dayton",
    "Dedham",
    "Deer Isle",
    "Denmark",
    "Dexter",
    "Dixfield",
    "Dixmont",
    "Dover-Foxcroft",
    "East Machias",
    "East Millinocket",
    "Easton",
    "Eastport",
    "Eddington",
    "Edgecomb",
    "Eliot",
    "Ellsworth",
    "Enfield",
    "Etna",
    "Fairfield",
    "Falmouth",
    "Falmouth Foreside",
    "Farmingdale",
    "Farmington",
    "Fayette",
    "Fort Fairfield",
    "Fort Kent",
    "Frankfort",
    "Franklin",
    "Franklin County",
    "Freeport",
    "Frenchville",
    "Friendship",
    "Fryeburg",
    "Gardiner",
    "Garland",
    "Gorham",
    "Gouldsboro",
    "Greenbush",
    "Greene",
    "Greenville",
    "Hallowell",
    "Hampden",
    "Hancock",
    "Hancock County",
    "Harpswell Center",
    "Harrison",
    "Hartford",
    "Hebron",
    "Hermon",
    "Hiram",
    "Hodgdon",
    "Holden",
    "Hollis Center",
    "Hope",
    "Houlton",
    "Howland",
    "Hudson",
    "Jay",
    "Jefferson",
    "Jonesport",
    "Kenduskeag",
    "Kennebec County",
    "Kennebunk",
    "Kennebunkport",
    "Kingfield",
    "Kittery",
    "Kittery Point",
    "Knox County",
    "Lake Arrowhead",
    "Lebanon",
    "Leeds",
    "Levant",
    "Lewiston",
    "Limerick",
    "Limestone",
    "Limington",
    "Lincoln",
    "Lincoln County",
    "Lincolnville",
    "Lisbon",
    "Lisbon Falls",
    "Livermore",
    "Livermore Falls",
    "Lovell",
    "Machias",
    "Machiasport",
    "Madawaska",
    "Madison",
    "Manchester",
    "Mechanic Falls",
    "Medway",
    "Mexico",
    "Milbridge",
    "Milford",
    "Millinocket",
    "Milo",
    "Minot",
    "Monmouth",
    "Mount Vernon",
    "New Gloucester",
    "New Sharon",
    "Newfield",
    "Newport",
    "Nobleboro",
    "Norridgewock",
    "North Bath",
    "North Berwick",
    "North Windham",
    "Northport",
    "Norway",
    "Oakland",
    "Ogunquit",
    "Old Orchard Beach",
    "Old Town",
    "Orland",
    "Orono",
    "Orrington",
    "Owls Head",
    "Oxford",
    "Oxford County",
    "Palermo",
    "Palmyra",
    "Paris",
    "Parsonsfield",
    "Patten",
    "Penobscot",
    "Penobscot County",
    "Peru",
    "Phillips",
    "Phippsburg",
    "Piscataquis County",
    "Pittsfield",
    "Pittston",
    "Plymouth",
    "Poland",
    "Porter",
    "Portland",
    "Presque Isle",
    "Randolph",
    "Raymond",
    "Readfield",
    "Richmond",
    "Rockland",
    "Rockport",
    "Rome",
    "Rumford",
    "Sabattus",
    "Saco",
    "Sagadahoc County",
    "Saint Albans",
    "Saint George",
    "Sanford",
    "Sangerville",
    "Scarborough",
    "Searsmont",
    "Sedgwick",
    "Shapleigh",
    "Sidney",
    "Skowhegan",
    "Somerset County",
    "South Berwick",
    "South Eliot",
    "South Paris",
    "South Portland",
    "South Portland Gardens",
    "South Sanford",
    "South Thomaston",
    "South Windham",
    "Springvale",
    "Steep Falls",
    "Stetson",
    "Steuben",
    "Stockton Springs",
    "Stonington",
    "Strong",
    "Sullivan",
    "Surry",
    "Swanville",
    "Thomaston",
    "Topsham",
    "Tremont",
    "Trenton",
    "Troy",
    "Turner",
    "Union",
    "Van Buren",
    "Vassalboro",
    "Veazie",
    "Vinalhaven",
    "Waldo County",
    "Waldoboro",
    "Warren",
    "Washington",
    "Washington County",
    "Waterboro",
    "Waterville",
    "Wayne",
    "Wells Beach Station",
    "West Kennebunk",
    "West Paris",
    "West Scarborough",
    "Westbrook",
    "Whitefield",
    "Wilton",
    "Windsor",
    "Winslow",
    "Winterport",
    "Winthrop",
    "Wiscasset",
    "Woodstock",
    "Woolwich",
    "Yarmouth",
    "York Beach",
    "York County",
    "York Harbor"
  ],
  "Maryland": [
    "Aberdeen",
    "Aberdeen Proving Ground",
    "Accokeek",
    "Adamstown",
    "Adelphi",
    "Algonquin",
    "Allegany County",
    "Andrews AFB",
    "Annapolis",
    "Anne Arundel County",
    "Arbutus",
    "Arden on the Severn",
    "Arlington",
    "Arnold",
    "Ashton-Sandy Spring",
    "Aspen Hill",
    "Baden",
    "Ballenger Creek",
    "Baltimore",
    "Baltimore County",
    "Baltimore Highlands",
    "Bartonsville",
    "Bel Air",
    "Bel Air North",
    "Bel Air South",
    "Beltsville",
    "Bennsville",
    "Berlin",
    "Berwyn Heights",
    "Bethesda",
    "Bladensburg",
    "Boonsboro",
    "Bowie",
    "Bowleys Quarters",
    "Bowling Green",
    "Braddock Heights",
    "Brandywine",
    "Brentwood",
    "Brock Hall",
    "Brooklyn Park",
    "Brookmont",
    "Brunswick",
    "Bryans Road",
    "Buckeystown",
    "Burtonsville",
    "Butcher's Hill",
    "Cabin John",
    "California",
    "Calvert County",
    "Calverton",
    "Cambridge",
    "Camp Springs",
    "Cape Saint Claire",
    "Capitol Heights",
    "Carney",
    "Caroline County",
    "Carroll County",
    "Catonsville",
    "Cavetown",
    "Cecil County",
    "Centreville",
    "Charles County",
    "Charles Village",
    "Charlestown",
    "Charlotte Hall",
    "Chesapeake Beach",
    "Chesapeake Ranch Estates",
    "Chester",
    "Chestertown",
    "Cheverly",
    "Chevy Chase",
    "Chevy Chase Village",
    "Chillum",
    "City of Baltimore",
    "Clarksburg",
    "Clinton",
    "Clover Hill",
    "Cloverly",
    "Cobb Island",
    "Cockeysville",
    "Colesville",
    "College Park",
    "Colmar Manor",
    "Columbia",
    "Coral Hills",
    "Cottage City",
    "Cresaptown",
    "Crisfield",
    "Crofton",
    "Croom",
    "Crownsville",
    "Cumberland",
    "Damascus",
    "Darnestown",
    "Davidsonville",
    "Deale",
    "Denton",
    "Derwood",
    "District Heights",
    "Dorchester County",
    "Drum Point",
    "Dundalk",
    "Dunkirk",
    "Dunkirk Town Center",
    "East Riverdale",
    "Easton",
    "Edgemere",
    "Edgewater",
    "Edgewood",
    "Edmonston",
    "Eldersburg",
    "Elkridge",
    "Elkton",
    "Ellicott City",
    "Emmitsburg",
    "Essex",
    "Fairland",
    "Fairmount Heights",
    "Fairwood",
    "Fallston",
    "Federalsburg",
    "Ferndale",
    "Forest Glen",
    "Forest Heights",
    "Forestville",
    "Fort George G Mead Junction",
    "Fort Meade",
    "Fort Washington",
    "Fountainhead-Orchard Hills",
    "Four Corners",
    "Frederick",
    "Frederick County",
    "Friendly",
    "Friendship Village",
    "Frostburg",
    "Fruitland",
    "Fulton",
    "Gaithersburg",
    "Gambrills",
    "Garrett County",
    "Garrett Park",
    "Garrison",
    "Germantown",
    "Glassmanor",
    "Glen Burnie",
    "Glenarden",
    "Glenmont",
    "Glenn Dale",
    "Goddard",
    "Golden Beach",
    "Grasonville",
    "Greater Upper Marlboro",
    "Green Haven",
    "Green Valley",
    "Greenbelt",
    "Greensboro",
    "Hagerstown",
    "Halfway",
    "Hampstead",
    "Hampton",
    "Hancock",
    "Hanover",
    "Harford County",
    "Havre de Grace",
    "Hebron",
    "Herald Harbor",
    "Highfield-Cascade",
    "Highland",
    "Hillandale",
    "Hillcrest Heights",
    "Hillsmere Shores",
    "Howard County",
    "Hughesville",
    "Hunt Valley",
    "Huntingtown",
    "Huntingtown Town Center",
    "Hurlock",
    "Hyattsville",
    "Ilchester",
    "Indian Head",
    "Irvington",
    "Jarrettsville",
    "Jefferson",
    "Jessup",
    "Joppatowne",
    "Keedysville",
    "Kemp Mill",
    "Kensington",
    "Kent County",
    "Kettering",
    "Kingstown",
    "Kingsville",
    "La Plata",
    "La Vale",
    "Lake Arbor",
    "Lake Shore",
    "Landover",
    "Landover Hills",
    "Langley Park",
    "Lanham",
    "Lanham-Seabrook",
    "Lansdowne",
    "Largo",
    "Laurel",
    "Layhill",
    "Leisure World",
    "Leonardtown",
    "Lexington Park",
    "Linganore",
    "Linthicum",
    "Lochearn",
    "Lonaconing",
    "Londontowne",
    "Long Beach",
    "Lusby",
    "Lutherville",
    "Lutherville-Timonium",
    "Manchester",
    "Marlboro Meadows",
    "Marlboro Village",
    "Marlow Heights",
    "Marlton",
    "Maryland City",
    "Maugansville",
    "Mayo",
    "Mays Chapel",
    "Mechanicsville",
    "Mellwood",
    "Middle River",
    "Middletown",
    "Milford Mill",
    "Mitchellville",
    "Montgomery County",
    "Montgomery Village",
    "Morningside",
    "Mount Airy",
    "Mount Rainier",
    "Mountain Lake Park",
    "Myersville",
    "National Harbor",
    "Naval Academy",
    "New Carrollton",
    "New Windsor",
    "North Beach",
    "North Bel Air",
    "North Bethesda",
    "North East",
    "North Kensington",
    "North Laurel",
    "North Potomac",
    "Oakland",
    "Ocean City",
    "Ocean Pines",
    "Odenton",
    "Olney",
    "Overlea",
    "Owings",
    "Owings Mills",
    "Oxon Hill",
    "Oxon Hill-Glassmanor",
    "Paramount-Long Meadow",
    "Parkville",
    "Parole",
    "Pasadena",
    "Peppermill Village",
    "Perry Hall",
    "Perryman",
    "Perryville",
    "Pikesville",
    "Pittsville",
    "Pleasant Hills",
    "Pocomoke City",
    "Point of Rocks",
    "Poolesville",
    "Potomac",
    "Potomac Heights",
    "Potomac Park",
    "Prince Frederick",
    "Prince George's County",
    "Princess Anne",
    "Pumphrey",
    "Queen Anne",
    "Queen Anne's County",
    "Queenland",
    "Randallstown",
    "Redland",
    "Reisterstown",
    "Ridgely",
    "Rising Sun",
    "Riva",
    "Riverdale Park",
    "Riverside",
    "Riviera Beach",
    "Robinwood",
    "Rock Hall",
    "Rockville",
    "Rosaryville",
    "Rosedale",
    "Rossmoor",
    "Rossville",
    "Saint Charles",
    "Saint James",
    "Saint Mary's County",
    "Saint Michaels",
    "Salisbury",
    "Savage",
    "Scaggsville",
    "Seabrook",
    "Seat Pleasant",
    "Selby-on-the-Bay",
    "Severn",
    "Severna Park",
    "Shady Side",
    "Silver Hill",
    "Silver Spring",
    "Smithsburg",
    "Snow Hill",
    "Solomons",
    "Somerset",
    "Somerset County",
    "South Bel Air",
    "South Gate",
    "South Kensington",
    "South Laurel",
    "Spencerville",
    "Spring Ridge",
    "Springdale",
    "Stevensville",
    "Suitland",
    "Suitland-Silver Hill",
    "Summerfield",
    "Sykesville",
    "Takoma Park",
    "Talbot County",
    "Taneytown",
    "Temple Hills",
    "Thurmont",
    "Timonium",
    "Towson",
    "Trappe",
    "Travilah",
    "University Park",
    "Upper Marlboro",
    "Urbana",
    "Waldorf",
    "Walker Mill",
    "Walkersville",
    "Washington County",
    "West Elkridge",
    "West Laurel",
    "West Ocean City",
    "Westernport",
    "Westminster",
    "Westphalia",
    "Wheaton",
    "White Marsh",
    "White Oak",
    "Wicomico County",
    "Williamsport",
    "Wilson-Conococheague",
    "Woodlawn",
    "Woodmore",
    "Woodsboro",
    "Worcester County"
  ],
  "Massachusetts": [
    "Abington",
    "Acton",
    "Acushnet",
    "Acushnet Center",
    "Adams",
    "Agawam",
    "Amesbury",
    "Amherst",
    "Amherst Center",
    "Andover",
    "Arlington",
    "Ashburnham",
    "Ashby",
    "Ashfield",
    "Ashland",
    "Assonet",
    "Athol",
    "Attleboro",
    "Auburn",
    "Avon",
    "Ayer",
    "Baldwinville",
    "Barnstable",
    "Barnstable County",
    "Barre",
    "Becket",
    "Bedford",
    "Belchertown",
    "Bellingham",
    "Belmont",
    "Berkley",
    "Berkshire County",
    "Berlin",
    "Bernardston",
    "Beverly",
    "Beverly Cove",
    "Billerica",
    "Blackstone",
    "Bliss Corner",
    "Bolton",
    "Bondsville",
    "Boston",
    "Bourne",
    "Boxborough",
    "Boxford",
    "Boylston",
    "Braintree",
    "Brewster",
    "Bridgewater",
    "Brimfield",
    "Bristol County",
    "Brockton",
    "Brookline",
    "Buckland",
    "Burlington",
    "Buzzards Bay",
    "Cambridge",
    "Canton",
    "Carlisle",
    "Carver",
    "Centerville",
    "Charlemont",
    "Charlton",
    "Chatham",
    "Chelmsford",
    "Chelsea",
    "Chesterfield",
    "Chicopee",
    "Clinton",
    "Cochituate",
    "Cohasset",
    "Colrain",
    "Concord",
    "Conway",
    "Cordaville",
    "Cotuit",
    "Dalton",
    "Danvers",
    "Dartmouth",
    "Dedham",
    "Dennis",
    "Dennis Port",
    "Devens",
    "Dighton",
    "Douglas",
    "Dover",
    "Dracut",
    "Dudley",
    "Dukes County",
    "Dunstable",
    "Duxbury",
    "East Bridgewater",
    "East Brookfield",
    "East Dennis",
    "East Douglas",
    "East Falmouth",
    "East Harwich",
    "East Longmeadow",
    "East Pepperell",
    "East Sandwich",
    "Eastham",
    "Easthampton",
    "Easton",
    "Edgartown",
    "Erving",
    "Essex",
    "Essex County",
    "Everett",
    "Fairhaven",
    "Fall River",
    "Falmouth",
    "Fiskdale",
    "Fitchburg",
    "Forestdale",
    "Foxborough",
    "Framingham",
    "Framingham Center",
    "Franklin",
    "Franklin County",
    "Freetown",
    "Gardner",
    "Gill",
    "Gloucester",
    "Grafton",
    "Granby",
    "Granville",
    "Great Barrington",
    "Green Harbor-Cedar Crest",
    "Greenfield",
    "Groton",
    "Groveland",
    "Hadley",
    "Halifax",
    "Hamilton Worcester",
    "Hampden",
    "Hampden County",
    "Hampshire County",
    "Hanover",
    "Hanson",
    "Hardwick",
    "Harvard",
    "Harwich",
    "Harwich Center",
    "Harwich Port",
    "Hatfield",
    "Haverhill",
    "Head of Westport",
    "Hingham",
    "Hinsdale",
    "Holbrook",
    "Holden",
    "Holland",
    "Holliston",
    "Holyoke",
    "Hopedale",
    "Hopkinton",
    "Housatonic",
    "Hubbardston",
    "Hudson",
    "Hull",
    "Hyannis",
    "Ipswich",
    "Jamaica Plain",
    "Kingston",
    "Lancaster",
    "Lanesborough",
    "Lawrence",
    "Lee",
    "Leicester",
    "Lenox",
    "Leominster",
    "Leverett",
    "Lexington",
    "Lincoln",
    "Littleton Common",
    "Longmeadow",
    "Lowell",
    "Ludlow",
    "Lunenburg",
    "Lynn",
    "Lynnfield",
    "Malden",
    "Manchester-by-the-Sea",
    "Mansfield",
    "Mansfield Center",
    "Marblehead",
    "Marion",
    "Marion Center",
    "Marlborough",
    "Marshfield",
    "Marshfield Hills",
    "Marstons Mills",
    "Mashpee",
    "Mattapoisett",
    "Mattapoisett Center",
    "Maynard",
    "Medfield",
    "Medford",
    "Medway",
    "Melrose",
    "Mendon",
    "Merrimac",
    "Methuen",
    "Middleborough",
    "Middleborough Center",
    "Middlesex County",
    "Middleton",
    "Milford",
    "Millbury",
    "Millers Falls",
    "Millis",
    "Millis-Clicquot",
    "Millville",
    "Milton",
    "Monson",
    "Monson Center",
    "Montague",
    "Monument Beach",
    "Nahant",
    "Nantucket",
    "Nantucket County",
    "Natick",
    "Needham",
    "New Bedford",
    "New Marlborough",
    "Newburyport",
    "Newton",
    "Norfolk",
    "Norfolk County",
    "North Adams",
    "North Amherst",
    "North Andover",
    "North Attleborough Center",
    "North Brookfield",
    "North Chicopee",
    "North Eastham",
    "North Falmouth",
    "North Lakeville",
    "North Pembroke",
    "North Plymouth",
    "North Reading",
    "North Scituate",
    "North Seekonk",
    "North Westport",
    "Northampton",
    "Northborough",
    "Northbridge",
    "Northfield",
    "Northwest Harwich",
    "Norton",
    "Norton Center",
    "Norwell",
    "Norwood",
    "Oak Bluffs",
    "Oakham",
    "Ocean Bluff-Brant Rock",
    "Ocean Grove",
    "Onset",
    "Orange",
    "Orleans",
    "Osterville",
    "Otis",
    "Oxford",
    "Palmer",
    "Paxton",
    "Peabody",
    "Pelham",
    "Pepperell",
    "Phillipston",
    "Pinehurst",
    "Pittsfield",
    "Plainville",
    "Plymouth",
    "Plymouth County",
    "Plympton",
    "Pocasset",
    "Princeton",
    "Provincetown",
    "Quincy",
    "Randolph",
    "Raynham",
    "Raynham Center",
    "Reading",
    "Rehoboth",
    "Revere",
    "Richmond",
    "Rochester",
    "Rockland",
    "Rockport",
    "Rowley",
    "Royalston",
    "Rutland",
    "Sagamore",
    "Salem",
    "Salisbury",
    "Sandwich",
    "Saugus",
    "Scituate",
    "Seekonk",
    "Sharon",
    "Sheffield",
    "Shelburne",
    "Shelburne Falls",
    "Sherborn",
    "Shirley",
    "Shrewsbury",
    "Shutesbury",
    "Smith Mills",
    "Somerset",
    "Somerville",
    "South Amherst",
    "South Ashburnham",
    "South Boston",
    "South Deerfield",
    "South Dennis",
    "South Duxbury",
    "South Hadley",
    "South Lancaster",
    "South Peabody",
    "South Yarmouth",
    "Southampton",
    "Southborough",
    "Southbridge",
    "Southwick",
    "Spencer",
    "Springfield",
    "Sterling",
    "Stockbridge",
    "Stoneham",
    "Stoughton",
    "Stow",
    "Sturbridge",
    "Sudbury",
    "Suffolk County",
    "Sunderland",
    "Sutton",
    "Swampscott",
    "Swansea",
    "Taunton",
    "Teaticket",
    "Templeton",
    "Tewksbury",
    "Three Rivers",
    "Topsfield",
    "Townsend",
    "Truro",
    "Turners Falls",
    "Tyngsboro",
    "Upton",
    "Uxbridge",
    "Vineyard Haven",
    "Wakefield",
    "Wales",
    "Walpole",
    "Waltham",
    "Ware",
    "Wareham Center",
    "Warren",
    "Watertown",
    "Wayland",
    "Webster",
    "Wellesley",
    "Wellfleet",
    "Wendell",
    "Wenham",
    "West Barnstable",
    "West Boylston",
    "West Bridgewater",
    "West Brookfield",
    "West Chatham",
    "West Concord",
    "West Dennis",
    "West Falmouth",
    "West Newbury",
    "West Springfield",
    "West Stockbridge",
    "West Tisbury",
    "West Wareham",
    "West Yarmouth",
    "Westborough",
    "Westfield",
    "Westford",
    "Westhampton",
    "Westminster",
    "Weston",
    "Westwood",
    "Weweantic",
    "Weymouth",
    "Whately",
    "White Island Shores",
    "Whitinsville",
    "Whitman",
    "Wilbraham",
    "Williamsburg",
    "Williamstown",
    "Wilmington",
    "Winchendon",
    "Winchester",
    "Winthrop",
    "Woburn",
    "Worcester",
    "Worcester County",
    "Wrentham",
    "Yarmouth",
    "Yarmouth Port"
  ],
  "Michigan": [
    "Adrian",
    "Albion",
    "Alcona County",
    "Alger County",
    "Algonac",
    "Allegan",
    "Allegan County",
    "Allen Park",
    "Allendale",
    "Alma",
    "Almont",
    "Alpena",
    "Alpena County",
    "Ann Arbor",
    "Antrim County",
    "Arenac County",
    "Argentine",
    "Armada",
    "Athens",
    "Atlanta",
    "Au Sable",
    "Auburn",
    "Auburn Hills",
    "Bad Axe",
    "Baldwin",
    "Bangor",
    "Baraga",
    "Baraga County",
    "Barnes Lake-Millers Lake",
    "Barry County",
    "Bath",
    "Battle Creek",
    "Bay City",
    "Bay County",
    "Bay Harbor",
    "Beaverton",
    "Beecher",
    "Beechwood",
    "Belding",
    "Bellaire",
    "Belleville",
    "Bellevue",
    "Benton Harbor",
    "Benton Heights",
    "Benzie County",
    "Berkley",
    "Berrien County",
    "Berrien Springs",
    "Bessemer",
    "Beulah",
    "Beverly Hills",
    "Big Rapids",
    "Bingham Farms",
    "Birch Run",
    "Birmingham",
    "Blissfield",
    "Bloomfield Hills",
    "Boyne City",
    "Branch County",
    "Breckenridge",
    "Bridgeport",
    "Bridgman",
    "Brighton",
    "Bronson",
    "Brooklyn",
    "Brown City",
    "Brownlee Park",
    "Buchanan",
    "Buena Vista",
    "Burt",
    "Burton",
    "Byron Center",
    "Cadillac",
    "Caledonia",
    "Calhoun County",
    "Canadian Lakes",
    "Canton",
    "Capac",
    "Carleton",
    "Caro",
    "Carrollton",
    "Carson City",
    "Cass City",
    "Cass County",
    "Cassopolis",
    "Cedar Springs",
    "Center Line",
    "Centreville",
    "Charlevoix",
    "Charlevoix County",
    "Charlotte",
    "Cheboygan",
    "Cheboygan County",
    "Chelsea",
    "Chesaning",
    "Chippewa County",
    "Clare",
    "Clare County",
    "Clarkston",
    "Clawson",
    "Clinton",
    "Clinton County",
    "Clinton Township",
    "Clio",
    "Coldwater",
    "Coleman",
    "Coloma",
    "Colon",
    "Comstock Northwest",
    "Comstock Park",
    "Concord",
    "Constantine",
    "Coopersville",
    "Corunna",
    "Crawford County",
    "Croswell",
    "Crystal Falls",
    "Cutlerville",
    "Davison",
    "DeWitt",
    "Dearborn",
    "Dearborn Heights",
    "Decatur",
    "Delta County",
    "Detroit",
    "Detroit Beach",
    "Dexter",
    "Dickinson County",
    "Dimondale",
    "Dollar Bay",
    "Douglas",
    "Dowagiac",
    "Dundee",
    "Durand",
    "Eagle River",
    "East Grand Rapids",
    "East Jordan",
    "East Lansing",
    "East Tawas",
    "Eastpointe",
    "Eastwood",
    "Eaton County",
    "Eaton Rapids",
    "Ecorse",
    "Edgemont Park",
    "Edmore",
    "Edwardsburg",
    "Elk Rapids",
    "Emmet County",
    "Escanaba",
    "Essexville",
    "Evart",
    "Fair Plain",
    "Farmington",
    "Farmington Hills",
    "Fennville",
    "Fenton",
    "Ferndale",
    "Ferrysburg",
    "Flat Rock",
    "Flint",
    "Flushing",
    "Forest Hills",
    "Fowler",
    "Fowlerville",
    "Frankenmuth",
    "Frankfort",
    "Franklin",
    "Fraser",
    "Freeland",
    "Fremont",
    "Fruitport",
    "Galesburg",
    "Garden City",
    "Gaylord",
    "Genesee County",
    "Gibraltar",
    "Gladstone",
    "Gladwin",
    "Gladwin County",
    "Gogebic County",
    "Goodrich",
    "Grand Blanc",
    "Grand Haven",
    "Grand Ledge",
    "Grand Rapids",
    "Grand Traverse County",
    "Grandville",
    "Grass Lake",
    "Gratiot County",
    "Grayling",
    "Greenville",
    "Greilickville",
    "Grosse Ile",
    "Grosse Pointe",
    "Grosse Pointe Farms",
    "Grosse Pointe Park",
    "Grosse Pointe Shores",
    "Grosse Pointe Woods",
    "Gwinn",
    "Hamtramck",
    "Hancock",
    "Harbor Beach",
    "Harbor Springs",
    "Harper Woods",
    "Harrison",
    "Harrisville",
    "Hart",
    "Hartford",
    "Harvey",
    "Haslett",
    "Hastings",
    "Hazel Park",
    "Hemlock",
    "Highland Park",
    "Hillsdale",
    "Hillsdale County",
    "Holland",
    "Holly",
    "Holt",
    "Homer",
    "Houghton",
    "Houghton County",
    "Houghton Lake",
    "Howard City",
    "Howell",
    "Hubbard Lake",
    "Hudson",
    "Hudsonville",
    "Huntington Woods",
    "Huron County",
    "Imlay City",
    "Indian River",
    "Ingham County",
    "Inkster",
    "Ionia",
    "Ionia County",
    "Iosco County",
    "Iron County",
    "Iron Mountain",
    "Iron River",
    "Ironwood",
    "Isabella County",
    "Ishpeming",
    "Ithaca",
    "Jackson",
    "Jackson County",
    "Jenison",
    "Jonesville",
    "K. I. Sawyer Air Force Base",
    "Kalamazoo",
    "Kalamazoo County",
    "Kalkaska",
    "Kalkaska County",
    "Keego Harbor",
    "Kent City",
    "Kent County",
    "Kentwood",
    "Keweenaw County",
    "Kilmanagh",
    "Kingsford",
    "Kingsley",
    "L'Anse",
    "Laingsburg",
    "Lake City",
    "Lake County",
    "Lake Fenton",
    "Lake Isabella",
    "Lake Michigan Beach",
    "Lake Odessa",
    "Lake Orion",
    "Lakeview",
    "Lakewood Club",
    "Lambertville",
    "Lansing",
    "Lapeer",
    "Lapeer County",
    "Lathrup Village",
    "Laurium",
    "Lawton",
    "Leelanau County",
    "Leland",
    "Lenawee County",
    "Leslie",
    "Level Park-Oak Park",
    "Lewiston",
    "Lexington",
    "Lincoln Park",
    "Linden",
    "Litchfield",
    "Livingston County",
    "Livonia",
    "Lowell",
    "Luce County",
    "Ludington",
    "Luna Pier",
    "Mackinac County",
    "Macomb County",
    "Madison Heights",
    "Mancelona",
    "Manchester",
    "Manistee",
    "Manistee County",
    "Manistique",
    "Manitou Beach-Devils Lake",
    "Manton",
    "Marcellus",
    "Marine City",
    "Marlette",
    "Marquette",
    "Marquette County",
    "Marshall",
    "Marysville",
    "Mason",
    "Mason County",
    "Mattawan",
    "Mecosta County",
    "Melvindale",
    "Memphis",
    "Menominee",
    "Menominee County",
    "Michigan Center",
    "Middleville",
    "Midland",
    "Midland County",
    "Milan",
    "Milford",
    "Millington",
    "Mio",
    "Missaukee County",
    "Monroe",
    "Monroe County",
    "Montague",
    "Montcalm County",
    "Montmorency County",
    "Montrose",
    "Morenci",
    "Mount Clemens",
    "Mount Morris",
    "Mount Pleasant",
    "Munising",
    "Muskegon",
    "Muskegon County",
    "Muskegon Heights",
    "Napoleon",
    "Nashville",
    "Negaunee",
    "New Baltimore",
    "New Buffalo",
    "New Haven",
    "Newaygo",
    "Newaygo County",
    "Newberry",
    "Niles",
    "North Branch",
    "North Muskegon",
    "Northview",
    "Northville",
    "Norton Shores",
    "Norway",
    "Novi",
    "Oak Park",
    "Oakland County",
    "Oceana County",
    "Ogemaw County",
    "Okemos",
    "Olivet",
    "Ontonagon",
    "Ontonagon County",
    "Orchard Lake",
    "Ortonville",
    "Osceola County",
    "Oscoda County",
    "Otsego",
    "Otsego County",
    "Ottawa County",
    "Ovid",
    "Owosso",
    "Oxford",
    "Parchment",
    "Paw Paw",
    "Paw Paw Lake",
    "Pearl Beach",
    "Perry",
    "Petersburg",
    "Petoskey",
    "Pigeon",
    "Pinckney",
    "Pinconning",
    "Plainwell",
    "Pleasant Ridge",
    "Plymouth",
    "Pontiac",
    "Port Huron",
    "Portage",
    "Portland",
    "Potterville",
    "Presque Isle County",
    "Prudenville",
    "Quincy",
    "Quinnesec",
    "Rapid City",
    "Ravenna",
    "Reading",
    "Redford",
    "Reed City",
    "Reese",
    "Richmond",
    "River Rouge",
    "Riverview",
    "Rochester",
    "Rochester Hills",
    "Rockford",
    "Rockwood",
    "Rogers City",
    "Romeo",
    "Romulus",
    "Roosevelt Park",
    "Roscommon",
    "Roscommon County",
    "Roseville",
    "Royal Oak",
    "Saginaw",
    "Saginaw County",
    "Saginaw Township North",
    "Saint Charles",
    "Saint Clair",
    "Saint Clair County",
    "Saint Clair Shores",
    "Saint Helen",
    "Saint Ignace",
    "Saint Johns",
    "Saint Joseph",
    "Saint Joseph County",
    "Saint Louis",
    "Saline",
    "Sand Lake",
    "Sandusky",
    "Sanilac County",
    "Saranac",
    "Sault Ste. Marie",
    "Schoolcraft",
    "Schoolcraft County",
    "Scottville",
    "Sebewaing",
    "Shelby",
    "Shepherd",
    "Shiawassee County",
    "Shields",
    "Shorewood-Tower Hills-Harbert",
    "Skidway Lake",
    "South Gull Lake",
    "South Haven",
    "South Lyon",
    "South Monroe",
    "South Rockwood",
    "Southfield",
    "Southgate",
    "Sparta",
    "Spring Arbor",
    "Spring Lake",
    "Springfield",
    "Stambaugh, Iron River",
    "Standish",
    "Stanton",
    "Sterling Heights",
    "Stevensville",
    "Stockbridge",
    "Stony Point",
    "Sturgis",
    "Swartz Creek",
    "Sylvan Lake",
    "Tawas City",
    "Taylor",
    "Tecumseh",
    "Temperance",
    "Three Oaks",
    "Three Rivers",
    "Traverse City",
    "Trenton",
    "Trowbridge Park",
    "Troy",
    "Tuscola County",
    "Twin Lake",
    "Union City",
    "Utica",
    "Van Buren County",
    "Vandercook Lake",
    "Vassar",
    "Vicksburg",
    "Wacousta",
    "Wakefield",
    "Walker",
    "Walled Lake",
    "Warren",
    "Washtenaw County",
    "Waterford",
    "Watervliet",
    "Waverly",
    "Wayland",
    "Wayne",
    "Wayne County",
    "Webberville",
    "West Bloomfield Township",
    "West Branch",
    "West Ishpeming",
    "West Monroe",
    "Westland",
    "Westwood",
    "Wexford County",
    "White Cloud",
    "White Pigeon",
    "Whitehall",
    "Whitmore Lake",
    "Williamston",
    "Wixom",
    "Wolf Lake",
    "Wolverine Lake",
    "Woodhaven",
    "Woodland Beach",
    "Wyandotte",
    "Wyoming",
    "Yale",
    "Ypsilanti",
    "Zeeland",
    "Zilwaukee"
  ],
  "Minnesota": [
    "Ada",
    "Adrian",
    "Afton",
    "Aitkin",
    "Aitkin County",
    "Albany",
    "Albert Lea",
    "Albertville",
    "Alexandria",
    "Andover",
    "Annandale",
    "Anoka",
    "Anoka County",
    "Apple Valley",
    "Appleton",
    "Arden Hills",
    "Arlington",
    "Arnold",
    "Atwater",
    "Aurora",
    "Austin",
    "Avon",
    "Babbitt",
    "Bagley",
    "Barnesville",
    "Baudette",
    "Baxter",
    "Bayport",
    "Becker",
    "Becker County",
    "Belle Plaine",
    "Beltrami County",
    "Bemidji",
    "Benson",
    "Benton County",
    "Big Lake",
    "Big Stone County",
    "Birchwood",
    "Blaine",
    "Blooming Prairie",
    "Bloomington",
    "Blue Earth",
    "Blue Earth County",
    "Braham",
    "Brainerd",
    "Branch",
    "Breckenridge",
    "Breezy Point",
    "Brooklyn Center",
    "Brooklyn Park",
    "Brown County",
    "Buffalo",
    "Burnsville",
    "Byron",
    "Caledonia",
    "Cambridge",
    "Canby",
    "Cannon Falls",
    "Carlton",
    "Carlton County",
    "Carver",
    "Carver County",
    "Cass County",
    "Center City",
    "Centerville",
    "Champlin",
    "Chanhassen",
    "Chaska",
    "Chatfield",
    "Chippewa County",
    "Chisago City",
    "Chisago County",
    "Chisholm",
    "Circle Pines",
    "Clara City",
    "Clay County",
    "Clearwater",
    "Clearwater County",
    "Cloquet",
    "Cohasset",
    "Cokato",
    "Cold Spring",
    "Coleraine",
    "Collegeville",
    "Cologne",
    "Columbia Heights",
    "Columbus",
    "Cook County",
    "Coon Rapids",
    "Corcoran",
    "Cottage Grove",
    "Cottonwood",
    "Cottonwood County",
    "Crookston",
    "Crosby",
    "Cross Lake",
    "Crow Wing County",
    "Crystal",
    "Dakota County",
    "Dassel",
    "Dawson",
    "Dayton",
    "Deephaven",
    "Delano",
    "Dellwood",
    "Detroit Lakes",
    "Dilworth",
    "Dodge Center",
    "Dodge County",
    "Douglas County",
    "Duluth",
    "Dundas",
    "Eagan",
    "Eagle Lake",
    "East Bethel",
    "East Grand Forks",
    "East Gull Lake",
    "Eden Prairie",
    "Eden Valley",
    "Edgerton",
    "Edina",
    "Elbow Lake",
    "Elgin",
    "Elk River",
    "Elko New Market",
    "Ely",
    "Esko",
    "Eveleth",
    "Excelsior",
    "Eyota",
    "Fairfax",
    "Fairmont",
    "Falcon Heights",
    "Faribault",
    "Faribault County",
    "Farmington",
    "Fergus Falls",
    "Fillmore County",
    "Foley",
    "Forest Lake",
    "Fosston",
    "Frazee",
    "Freeborn County",
    "Fridley",
    "Fulda",
    "Gaylord",
    "Gilbert",
    "Glencoe",
    "Glenwood",
    "Glyndon",
    "Golden Valley",
    "Goodhue",
    "Goodhue County",
    "Goodview",
    "Grand Marais",
    "Grand Meadow",
    "Grand Rapids",
    "Granite Falls",
    "Grant",
    "Grant County",
    "Greenfield",
    "Hallock",
    "Ham Lake",
    "Hanover",
    "Harris",
    "Hastings",
    "Hawley",
    "Hayfield",
    "Hector",
    "Hennepin County",
    "Hermantown",
    "Hibbing",
    "Hinckley",
    "Hopkins",
    "Houston County",
    "Howard Lake",
    "Hoyt Lakes",
    "Hubbard County",
    "Hugo",
    "Hutchinson",
    "Independence",
    "International Falls",
    "Inver Grove Heights",
    "Isanti",
    "Isanti County",
    "Itasca County",
    "Ivanhoe",
    "Jackson",
    "Jackson County",
    "Janesville",
    "Jordan",
    "Kanabec County",
    "Kandiyohi County",
    "Kasson",
    "Keewatin",
    "Kenyon",
    "Kittson County",
    "Koochiching County",
    "La Crescent",
    "Lac qui Parle County",
    "Lake City",
    "Lake County",
    "Lake Crystal",
    "Lake Elmo",
    "Lake Saint Croix Beach",
    "Lake Shore",
    "Lake of the Woods County",
    "Lakefield",
    "Lakeland",
    "Lakeville",
    "Lauderdale",
    "Le Center",
    "Le Sueur",
    "Le Sueur County",
    "Lester Prairie",
    "Lewiston",
    "Lexington",
    "Lincoln County",
    "Lindstrom",
    "Lino Lakes",
    "Litchfield",
    "Little Canada",
    "Little Falls",
    "Little Rock",
    "Long Lake",
    "Long Prairie",
    "Lonsdale",
    "Luverne",
    "Lyon County",
    "Madelia",
    "Madison",
    "Madison Lake",
    "Mahnomen",
    "Mahnomen County",
    "Mahtomedi",
    "Mankato",
    "Mantorville",
    "Maple Grove",
    "Maple Lake",
    "Maple Plain",
    "Mapleton",
    "Maplewood",
    "Marshall",
    "Marshall County",
    "Martin County",
    "Mayer",
    "McLeod County",
    "Medford",
    "Medina",
    "Meeker County",
    "Melrose",
    "Menahga",
    "Mendota Heights",
    "Milaca",
    "Mille Lacs County",
    "Minneapolis",
    "Minneota",
    "Minnetonka",
    "Minnetonka Mills",
    "Minnetrista",
    "Montevideo",
    "Montgomery",
    "Monticello",
    "Montrose",
    "Moorhead",
    "Moose Lake",
    "Mora",
    "Morris",
    "Morrison County",
    "Mound",
    "Mounds View",
    "Mountain Iron",
    "Mountain Lake",
    "Mower County",
    "Murray County",
    "New Brighton",
    "New Hope",
    "New London",
    "New Prague",
    "New Richland",
    "New Ulm",
    "New York Mills",
    "Newport",
    "Nicollet",
    "Nicollet County",
    "Nisswa",
    "Nobles County",
    "Norman County",
    "North Branch",
    "North Mankato",
    "North Oaks",
    "North Saint Paul",
    "Northfield",
    "Norwood (historical)",
    "Norwood Young America",
    "Nowthen",
    "Oak Grove",
    "Oak Park Heights",
    "Oakdale",
    "Oakport",
    "Olivia",
    "Olmsted County",
    "Orono",
    "Oronoco",
    "Ortonville",
    "Osakis",
    "Osseo",
    "Otsego",
    "Otter Tail County",
    "Owatonna",
    "Park Rapids",
    "Parkers Prairie",
    "Parkville",
    "Paynesville",
    "Pelican Rapids",
    "Pennington County",
    "Pequot Lakes",
    "Perham",
    "Pierz",
    "Pine City",
    "Pine County",
    "Pine Island",
    "Pipestone",
    "Pipestone County",
    "Plainview",
    "Plymouth",
    "Polk County",
    "Pope County",
    "Preston",
    "Princeton",
    "Prior Lake",
    "Proctor",
    "Ramsey",
    "Ramsey County",
    "Red Lake",
    "Red Lake County",
    "Red Lake Falls",
    "Red Wing",
    "Redby",
    "Redwood County",
    "Redwood Falls",
    "Renville",
    "Renville County",
    "Rice",
    "Rice County",
    "Richfield",
    "Richmond",
    "Robbinsdale",
    "Rochester",
    "Rock County",
    "Rock Creek",
    "Rockford",
    "Rockville",
    "Rogers",
    "Roseau",
    "Roseau County",
    "Rosemount",
    "Roseville",
    "Royalton",
    "Rush City",
    "Rushford",
    "Saint Anthony",
    "Saint Augusta",
    "Saint Bonifacius",
    "Saint Charles",
    "Saint Cloud",
    "Saint Francis",
    "Saint James",
    "Saint Joseph",
    "Saint Louis County",
    "Saint Louis Park",
    "Saint Michael",
    "Saint Paul",
    "Saint Paul Park",
    "Saint Peter",
    "Sandstone",
    "Sartell",
    "Sauk Centre",
    "Sauk Rapids",
    "Savage",
    "Scandia",
    "Scott County",
    "Shafer",
    "Shakopee",
    "Sherburn",
    "Sherburne County",
    "Shoreview",
    "Shorewood",
    "Sibley County",
    "Silver Bay",
    "Slayton",
    "Sleepy Eye",
    "South Saint Paul",
    "Spicer",
    "Spring Grove",
    "Spring Lake Park",
    "Spring Park",
    "Spring Valley",
    "Springfield",
    "Stacy",
    "Staples",
    "Starbuck",
    "Stearns County",
    "Steele County",
    "Stevens County",
    "Stewartville",
    "Stillwater",
    "Swift County",
    "Taylors Falls",
    "Thief River Falls",
    "Todd County",
    "Tonka Bay",
    "Tracy",
    "Traverse County",
    "Truman",
    "Two Harbors",
    "Tyler",
    "Vadnais Heights",
    "Victoria",
    "Vineland",
    "Virginia",
    "Wabasha",
    "Wabasha County",
    "Waconia",
    "Wadena",
    "Wadena County",
    "Waite Park",
    "Walker",
    "Wanamingo",
    "Warren",
    "Warroad",
    "Waseca",
    "Waseca County",
    "Washington County",
    "Watertown",
    "Waterville",
    "Watonwan County",
    "Waverly",
    "Wayzata",
    "Wells",
    "West Coon Rapids",
    "West Saint Paul",
    "Wheaton",
    "White Bear Lake",
    "Wilkin County",
    "Willmar",
    "Windom",
    "Winnebago",
    "Winona",
    "Winona County",
    "Winsted",
    "Winthrop",
    "Woodbury",
    "Worthington",
    "Wright County",
    "Wyoming",
    "Yellow Medicine County",
    "Young America (historical)",
    "Zimmerman",
    "Zumbrota"
  ],
  "Mississippi": [
    "Aberdeen",
    "Ackerman",
    "Adams County",
    "Alcorn County",
    "Amite County",
    "Amory",
    "Arnold Line",
    "Ashland",
    "Attala County",
    "Baldwyn",
    "Batesville",
    "Bay Saint Louis",
    "Bay Springs",
    "Beechwood",
    "Belmont",
    "Belzoni",
    "Benton County",
    "Biloxi",
    "Bolivar County",
    "Booneville",
    "Brandon",
    "Brookhaven",
    "Brooksville",
    "Bruce",
    "Bude",
    "Byhalia",
    "Byram",
    "Caledonia",
    "Calhoun City",
    "Calhoun County",
    "Canton",
    "Carriere",
    "Carroll County",
    "Carrollton",
    "Carthage",
    "Centreville",
    "Charleston",
    "Chickasaw County",
    "Choctaw County",
    "Claiborne County",
    "Clarke County",
    "Clarksdale",
    "Clay County",
    "Cleary",
    "Cleveland",
    "Clinton",
    "Coahoma County",
    "Coldwater",
    "Collins",
    "Collinsville",
    "Columbia",
    "Columbus",
    "Columbus Air Force Base",
    "Como",
    "Conehatta",
    "Copiah County",
    "Corinth",
    "Covington County",
    "Crystal Springs",
    "D'Iberville",
    "De Kalb",
    "De Lisle",
    "De Soto County",
    "Decatur",
    "Derma",
    "Diamondhead",
    "Drew",
    "Duck Hill",
    "Durant",
    "Edwards",
    "Ellisville",
    "Escatawpa",
    "Eupora",
    "Farmington",
    "Fayette",
    "Flora",
    "Florence",
    "Flowood",
    "Forest",
    "Forrest County",
    "Franklin County",
    "Friars Point",
    "Fulton",
    "Gautier",
    "George County",
    "Glendale",
    "Goodman",
    "Greene County",
    "Greenville",
    "Greenwood",
    "Grenada",
    "Grenada County",
    "Gulf Hills",
    "Gulf Park Estates",
    "Gulfport",
    "Guntown",
    "Hancock County",
    "Harrison County",
    "Hattiesburg",
    "Hazlehurst",
    "Helena",
    "Hernando",
    "Hickory Hills",
    "Hide-A-Way Lake",
    "Hillsboro",
    "Hinds County",
    "Hollandale",
    "Holly Springs",
    "Holmes County",
    "Horn Lake",
    "Houston",
    "Humphreys County",
    "Hurley",
    "Indianola",
    "Issaquena County",
    "Itawamba County",
    "Itta Bena",
    "Iuka",
    "Jackson",
    "Jackson County",
    "Jasper County",
    "Jefferson County",
    "Jefferson Davis County",
    "Jones County",
    "Jonestown",
    "Kearney Park",
    "Kemper County",
    "Kiln",
    "Kosciusko",
    "Lafayette County",
    "Lamar County",
    "Lambert",
    "Latimer",
    "Lauderdale County",
    "Laurel",
    "Lawrence County",
    "Leake County",
    "Leakesville",
    "Lee County",
    "Leflore County",
    "Leland",
    "Lexington",
    "Liberty",
    "Lincoln County",
    "Long Beach",
    "Louisville",
    "Lowndes County",
    "Lucedale",
    "Lumberton",
    "Lyman",
    "Lynchburg",
    "Macon",
    "Madison",
    "Madison County",
    "Magee",
    "Magnolia",
    "Mantachie",
    "Marion",
    "Marion County",
    "Marks",
    "Marshall County",
    "Mayersville",
    "McComb",
    "Meadville",
    "Mendenhall",
    "Meridian",
    "Meridian Station",
    "Metcalfe",
    "Mikoma",
    "Monroe County",
    "Montgomery County",
    "Monticello",
    "Moorhead",
    "Morgantown",
    "Morton",
    "Moss Point",
    "Mound Bayou",
    "Natchez",
    "Nellieburg",
    "Neshoba County",
    "Nettleton",
    "New Albany",
    "New Augusta",
    "New Hope",
    "Newton",
    "Newton County",
    "Nicholson",
    "North Tunica",
    "Noxubee County",
    "Ocean Springs",
    "Okolona",
    "Oktibbeha County",
    "Olive Branch",
    "Oxford",
    "Panola County",
    "Pascagoula",
    "Pass Christian",
    "Pearl",
    "Pearl River",
    "Pearl River County",
    "Pearlington",
    "Pelahatchie",
    "Perry County",
    "Petal",
    "Philadelphia",
    "Picayune",
    "Pickens",
    "Pike County",
    "Pittsboro",
    "Plantersville",
    "Pontotoc",
    "Pontotoc County",
    "Poplarville",
    "Port Gibson",
    "Prentiss",
    "Prentiss County",
    "Purvis",
    "Quitman",
    "Quitman County",
    "Raleigh",
    "Rankin County",
    "Rawls Springs",
    "Raymond",
    "Richland",
    "Richton",
    "Ridgeland",
    "Ripley",
    "Rolling Fork",
    "Rosedale",
    "Ruleville",
    "Saint Martin",
    "Saltillo",
    "Sardis",
    "Saucier",
    "Scott County",
    "Senatobia",
    "Shannon",
    "Sharkey County",
    "Sharon",
    "Shaw",
    "Shelby",
    "Simpson County",
    "Smith County",
    "Southaven",
    "Starkville",
    "Stone County",
    "Stonewall",
    "Summit",
    "Sumrall",
    "Sunflower",
    "Sunflower County",
    "Tallahatchie County",
    "Tate County",
    "Taylorsville",
    "Tchula",
    "Terry",
    "Tippah County",
    "Tishomingo County",
    "Tunica",
    "Tunica County",
    "Tunica Resorts",
    "Tupelo",
    "Tutwiler",
    "Tylertown",
    "Union",
    "Union County",
    "University",
    "Vancleave",
    "Vardaman",
    "Verona",
    "Vicksburg",
    "Wade",
    "Walls",
    "Walnut Grove",
    "Walthall",
    "Walthall County",
    "Warren County",
    "Washington County",
    "Water Valley",
    "Waveland",
    "Wayne County",
    "Waynesboro",
    "Webster County",
    "Wesson",
    "West Gulfport",
    "West Hattiesburg",
    "West Point",
    "Wiggins",
    "Wilkinson County",
    "Winona",
    "Winston County",
    "Woodville",
    "Yalobusha County",
    "Yazoo City",
    "Yazoo County"
  ],
  "Missouri": [
    "Adair County",
    "Adrian",
    "Advance",
    "Affton",
    "Albany",
    "Alton",
    "Anderson",
    "Andrew County",
    "Appleton City",
    "Archie",
    "Arnold",
    "Ash Grove",
    "Ashland",
    "Atchison County",
    "Audrain County",
    "Aurora",
    "Ava",
    "Ballwin",
    "Barnhart",
    "Barry County",
    "Barton County",
    "Bates County",
    "Battlefield",
    "Bel-Nor",
    "Bel-Ridge",
    "Belle",
    "Bellefontaine Neighbors",
    "Belton",
    "Benton",
    "Benton County",
    "Berkeley",
    "Bernie",
    "Bethany",
    "Billings",
    "Bismarck",
    "Black Jack",
    "Bloomfield",
    "Blue Springs",
    "Bolivar",
    "Bollinger County",
    "Bonne Terre",
    "Boone County",
    "Boonville",
    "Bourbon",
    "Bowling Green",
    "Branson",
    "Breckenridge Hills",
    "Brentwood",
    "Bridgeton",
    "Brookfield",
    "Buchanan County",
    "Buckner",
    "Buffalo",
    "Butler",
    "Butler County",
    "Byrnes Mill",
    "Cabool",
    "Caldwell County",
    "California",
    "Callaway County",
    "Calverton Park",
    "Camden County",
    "Camdenton",
    "Cameron",
    "Campbell",
    "Canton",
    "Cape Girardeau",
    "Cape Girardeau County",
    "Carl Junction",
    "Carroll County",
    "Carrollton",
    "Carter County",
    "Carterville",
    "Carthage",
    "Caruthersville",
    "Cass County",
    "Cassville",
    "Castle Point",
    "Cedar County",
    "Cedar Hill",
    "Centerville",
    "Centralia",
    "Chaffee",
    "Chariton County",
    "Charlack",
    "Charleston",
    "Chesterfield",
    "Chillicothe",
    "Christian County",
    "City of Saint Louis",
    "Clark County",
    "Clarkson Valley",
    "Clarkton",
    "Clay County",
    "Claycomo",
    "Clayton",
    "Clever",
    "Clinton",
    "Clinton County",
    "Cole Camp",
    "Cole County",
    "Columbia",
    "Concord",
    "Concordia",
    "Cool Valley",
    "Cooper County",
    "Cottleville",
    "Country Club Hills",
    "Country Club Village",
    "Crane",
    "Crawford County",
    "Crestwood",
    "Creve Coeur",
    "Crocker",
    "Crystal City",
    "Cuba",
    "Dade County",
    "Dallas County",
    "Dardenne Prairie",
    "Daviess County",
    "De Soto",
    "DeKalb County",
    "Dellwood",
    "Dent County",
    "Des Peres",
    "Desloge",
    "Dexter",
    "Dixon",
    "Doniphan",
    "Douglas County",
    "Duenweg",
    "Dunklin County",
    "Duquesne",
    "East Independence",
    "East Prairie",
    "Edina",
    "El Dorado Springs",
    "Eldon",
    "Ellisville",
    "Elsberry",
    "Elvins",
    "Eminence",
    "Esther",
    "Eureka",
    "Excelsior Springs",
    "Fair Grove",
    "Farmington",
    "Fayette",
    "Fenton",
    "Ferguson",
    "Festus",
    "Flat River",
    "Florissant",
    "Forsyth",
    "Fort Leonard Wood",
    "Four Seasons",
    "Franklin County",
    "Fredericktown",
    "Frontenac",
    "Fulton",
    "Gainesville",
    "Galena",
    "Gallatin",
    "Garden City",
    "Gasconade County",
    "Gentry County",
    "Gerald",
    "Gideon",
    "Gladstone",
    "Glasgow",
    "Glasgow Village",
    "Glendale",
    "Goodman",
    "Gower",
    "Grain Valley",
    "Granby",
    "Grandview",
    "Grant City",
    "Gray Summit",
    "Green Park",
    "Greene County",
    "Greenfield",
    "Greenville",
    "Greenwood",
    "Grundy County",
    "Hallsville",
    "Hamilton",
    "Hanley Hills",
    "Hannibal",
    "Harrison County",
    "Harrisonville",
    "Hartville",
    "Hayti",
    "Hazelwood",
    "Henry County",
    "Herculaneum",
    "Hermann",
    "Hermitage",
    "Hickory County",
    "Higginsville",
    "High Ridge",
    "Hillsboro",
    "Hillsdale",
    "Holden",
    "Hollister",
    "Holt County",
    "Holts Summit",
    "Houston",
    "Howard County",
    "Howell County",
    "Humansville",
    "Huntsville",
    "Imperial",
    "Independence",
    "Iron County",
    "Ironton",
    "Jackson",
    "Jackson County",
    "Jasper County",
    "Jefferson City",
    "Jefferson County",
    "Jennings",
    "Johnson County",
    "Joplin",
    "Kahoka",
    "Kansas City",
    "Kearney",
    "Kennett",
    "Keytesville",
    "Kimberling City",
    "King City",
    "Kingston",
    "Kirksville",
    "Kirkwood",
    "Kissee Mills",
    "Knob Noster",
    "Knox County",
    "La Monte",
    "La Plata",
    "LaBarque Creek",
    "Laclede County",
    "Ladue",
    "Lafayette County",
    "Lake Lotawana",
    "Lake Ozark",
    "Lake Saint Louis",
    "Lake Winnebago",
    "Lakeshire",
    "Lamar",
    "Lancaster",
    "Lathrop",
    "Lawrence County",
    "Lawson",
    "Leadwood",
    "Lebanon",
    "Lee's Summit",
    "Lemay",
    "Lewis County",
    "Lexington",
    "Liberty",
    "Licking",
    "Lilbourn",
    "Lincoln",
    "Lincoln County",
    "Linn",
    "Linn County",
    "Linneus",
    "Livingston County",
    "Lone Jack",
    "Louisiana",
    "Macon",
    "Macon County",
    "Madison County",
    "Malden",
    "Manchester",
    "Mansfield",
    "Maplewood",
    "Marble Hill",
    "Marceline",
    "Maries County",
    "Marion County",
    "Marionville",
    "Marlborough",
    "Marshall",
    "Marshfield",
    "Marthasville",
    "Maryland Heights",
    "Maryville",
    "Maysville",
    "McDonald County",
    "Mehlville",
    "Memphis",
    "Mercer County",
    "Merriam Woods",
    "Mexico",
    "Milan",
    "Miller County",
    "Mississippi County",
    "Moberly",
    "Moline Acres",
    "Monett",
    "Moniteau County",
    "Monroe City",
    "Monroe County",
    "Montgomery City",
    "Montgomery County",
    "Monticello",
    "Morgan County",
    "Moscow Mills",
    "Mound City",
    "Mount Vernon",
    "Mountain Grove",
    "Mountain View",
    "Murphy",
    "Neosho",
    "Nevada",
    "New Franklin",
    "New Haven",
    "New London",
    "New Madrid",
    "New Madrid County",
    "Newton County",
    "Nixa",
    "Nodaway County",
    "Noel",
    "Normandy",
    "North Kansas City",
    "Northwoods",
    "O'Fallon",
    "Oak Grove",
    "Oakland",
    "Oakville",
    "Odessa",
    "Old Jamestown",
    "Olivette",
    "Oran",
    "Oregon",
    "Oregon County",
    "Oronogo",
    "Osage Beach",
    "Osage County",
    "Osceola",
    "Overland",
    "Owensville",
    "Ozark",
    "Ozark County",
    "Pacific",
    "Pagedale",
    "Palmyra",
    "Paris",
    "Park Hills",
    "Parkville",
    "Peculiar",
    "Pemiscot County",
    "Perry County",
    "Perryville",
    "Pettis County",
    "Pevely",
    "Phelps County",
    "Piedmont",
    "Pierce City",
    "Pike County",
    "Pine Lawn",
    "Pineville",
    "Platte City",
    "Platte County",
    "Plattsburg",
    "Pleasant Hill",
    "Pleasant Valley",
    "Polk County",
    "Poplar Bluff",
    "Portageville",
    "Potosi",
    "Princeton",
    "Pulaski County",
    "Purdy",
    "Putnam County",
    "Ralls County",
    "Randolph County",
    "Ray County",
    "Raymore",
    "Raytown",
    "Republic",
    "Reynolds County",
    "Rich Hill",
    "Richland",
    "Richmond",
    "Richmond Heights",
    "Ripley County",
    "Riverside",
    "Riverview",
    "Rock Hill",
    "Rock Port",
    "Rogersville",
    "Rolla",
    "Saint Ann",
    "Saint Charles",
    "Saint Charles County",
    "Saint Clair",
    "Saint Clair County",
    "Saint Francois County",
    "Saint George",
    "Saint James",
    "Saint John",
    "Saint Johns",
    "Saint Joseph",
    "Saint Louis County",
    "Saint Martins",
    "Saint Paul",
    "Saint Peters",
    "Saint Robert",
    "Sainte Genevieve",
    "Sainte Genevieve County",
    "Salem",
    "Saline County",
    "Salisbury",
    "Sappington",
    "Sarcoxie",
    "Savannah",
    "Schuyler County",
    "Scotland County",
    "Scott City",
    "Scott County",
    "Sedalia",
    "Senath",
    "Seneca",
    "Seymour",
    "Shannon County",
    "Shelbina",
    "Shelby County",
    "Shelbyville",
    "Shell Knob",
    "Shrewsbury",
    "Sikeston",
    "Slater",
    "Smithville",
    "Spanish Lake",
    "Sparta",
    "Springfield",
    "St. Louis",
    "Stanberry",
    "Steele",
    "Steelville",
    "Stockton",
    "Stoddard County",
    "Stone County",
    "Stover",
    "Strafford",
    "Sugar Creek",
    "Sullivan",
    "Sullivan County",
    "Sunset Hills",
    "Sweet Springs",
    "Taney County",
    "Taos",
    "Tarkio",
    "Terre Haute",
    "Terre du Lac",
    "Texas County",
    "Thayer",
    "Tipton",
    "Town and Country",
    "Trenton",
    "Troy",
    "Tuscumbia",
    "Union",
    "Unionville",
    "University City",
    "Valley Park",
    "Van Buren",
    "Vandalia",
    "Velda Village",
    "Velda Village Hills",
    "Vernon County",
    "Versailles",
    "Vienna",
    "Villa Ridge",
    "Vinita Park",
    "Wardsville",
    "Warren County",
    "Warrensburg",
    "Warrenton",
    "Warsaw",
    "Warson Woods",
    "Washington",
    "Washington County",
    "Wayne County",
    "Waynesville",
    "Weatherby Lake",
    "Webb City",
    "Webster County",
    "Webster Groves",
    "Weldon Spring",
    "Wellston",
    "Wellsville",
    "Wentzville",
    "West Plains",
    "Weston",
    "Whiteman Air Force Base",
    "Wildwood",
    "Willard",
    "Willow Springs",
    "Winchester",
    "Windsor",
    "Winfield",
    "Winona",
    "Woodson Terrace",
    "Worth County",
    "Wright City",
    "Wright County"
  ],
  "Montana": [
    "Absarokee",
    "Anaconda",
    "Baker",
    "Beaverhead County",
    "Belgrade",
    "Big Horn County",
    "Big Sky",
    "Big Timber",
    "Bigfork",
    "Billings",
    "Blaine County",
    "Bonner-West Riverside",
    "Boulder",
    "Bozeman",
    "Broadus",
    "Broadwater County",
    "Browning",
    "Butte",
    "Butte-Silver Bow (Balance)",
    "Carbon County",
    "Carter County",
    "Cascade County",
    "Chester",
    "Chinook",
    "Choteau",
    "Chouteau County",
    "Circle",
    "Clancy",
    "Clinton",
    "Colstrip",
    "Columbia Falls",
    "Columbus",
    "Conrad",
    "Crow Agency",
    "Custer County",
    "Cut Bank",
    "Daniels County",
    "Dawson County",
    "Deer Lodge",
    "Deer Lodge County",
    "Dillon",
    "East Helena",
    "East Missoula",
    "Ekalaka",
    "Eureka",
    "Evergreen",
    "Fallon County",
    "Fergus County",
    "Flathead County",
    "Forsyth",
    "Fort Belknap Agency",
    "Fort Benton",
    "Four Corners",
    "Frenchtown",
    "Gallatin County",
    "Garfield County",
    "Glacier County",
    "Glasgow",
    "Glendive",
    "Golden Valley County",
    "Granite County",
    "Great Falls",
    "Hamilton",
    "Hardin",
    "Harlowton",
    "Havre",
    "Helena",
    "Helena Valley Northeast",
    "Helena Valley Northwest",
    "Helena Valley Southeast",
    "Helena Valley West Central",
    "Helena West Side",
    "Hill County",
    "Hysham",
    "Jefferson County",
    "Jordan",
    "Judith Basin County",
    "Kalispell",
    "Lake County",
    "Lakeside",
    "Lame Deer",
    "Laurel",
    "Lewis and Clark County",
    "Lewistown",
    "Libby",
    "Liberty County",
    "Lincoln",
    "Lincoln County",
    "Livingston",
    "Lockwood",
    "Lolo",
    "Madison County",
    "Malmstrom Air Force Base",
    "Malta",
    "Manhattan",
    "McCone County",
    "Meagher County",
    "Miles City",
    "Mineral County",
    "Missoula",
    "Missoula County",
    "Montana City",
    "Musselshell County",
    "North Browning",
    "Orchard Homes",
    "Pablo",
    "Park County",
    "Petroleum County",
    "Philipsburg",
    "Phillips County",
    "Plains",
    "Plentywood",
    "Polson",
    "Pondera County",
    "Powder River County",
    "Powell County",
    "Prairie County",
    "Ravalli County",
    "Red Lodge",
    "Richland County",
    "Ronan",
    "Roosevelt County",
    "Rosebud County",
    "Roundup",
    "Ryegate",
    "Sanders County",
    "Scobey",
    "Seeley Lake",
    "Shelby",
    "Sheridan County",
    "Sidney",
    "Silver Bow County",
    "Somers",
    "South Browning",
    "Stanford",
    "Stevensville",
    "Stillwater County",
    "Sun Prairie",
    "Superior",
    "Sweet Grass County",
    "Terry",
    "Teton County",
    "Thompson Falls",
    "Three Forks",
    "Toole County",
    "Townsend",
    "Treasure County",
    "Valley County",
    "Virginia City",
    "Warm Springs",
    "West Glendive",
    "West Yellowstone",
    "Wheatland County",
    "White Sulphur Springs",
    "Whitefish",
    "Whitehall",
    "Wibaux",
    "Wibaux County",
    "Winnett",
    "Wolf Point",
    "Yellowstone County"
  ],
  "Nebraska": [
    "Adams County",
    "Ainsworth",
    "Albion",
    "Alliance",
    "Alma",
    "Antelope County",
    "Arapahoe",
    "Arlington",
    "Arthur",
    "Arthur County",
    "Ashland",
    "Atkinson",
    "Auburn",
    "Aurora",
    "Banner County",
    "Bartlett",
    "Bassett",
    "Battle Creek",
    "Bayard",
    "Beatrice",
    "Beaver City",
    "Bellevue",
    "Benkelman",
    "Bennington",
    "Blaine County",
    "Blair",
    "Boone County",
    "Box Butte County",
    "Boyd County",
    "Brewster",
    "Bridgeport",
    "Broken Bow",
    "Brown County",
    "Buffalo County",
    "Burt County",
    "Burwell",
    "Butler County",
    "Butte",
    "Cambridge",
    "Cass County",
    "Cedar County",
    "Center",
    "Central City",
    "Chadron",
    "Chalco",
    "Chappell",
    "Chase County",
    "Cherry County",
    "Cheyenne County",
    "Clay Center",
    "Clay County",
    "Colfax County",
    "Columbus",
    "Cozad",
    "Creighton",
    "Crete",
    "Cuming County",
    "Custer County",
    "Dakota City",
    "Dakota County",
    "David City",
    "Dawes County",
    "Dawson County",
    "Deuel County",
    "Dixon County",
    "Dodge County",
    "Douglas County",
    "Dundy County",
    "Eagle",
    "Elkhorn",
    "Elwood",
    "Fairbury",
    "Falls City",
    "Fillmore County",
    "Franklin",
    "Franklin County",
    "Fremont",
    "Friend",
    "Frontier County",
    "Fullerton",
    "Furnas County",
    "Gage County",
    "Garden County",
    "Garfield County",
    "Geneva",
    "Gering",
    "Gibbon",
    "Gordon",
    "Gosper County",
    "Gothenburg",
    "Grand Island",
    "Grant",
    "Grant County",
    "Greeley",
    "Greeley County",
    "Gretna",
    "Hall County",
    "Hamilton County",
    "Harlan County",
    "Harrisburg",
    "Harrison",
    "Hartington",
    "Hastings",
    "Hayes Center",
    "Hayes County",
    "Hebron",
    "Hickman",
    "Hitchcock County",
    "Holdrege",
    "Holt County",
    "Hooker County",
    "Howard County",
    "Hyannis",
    "Imperial",
    "Jefferson County",
    "Johnson County",
    "Kearney",
    "Kearney County",
    "Keith County",
    "Keya Paha County",
    "Kimball",
    "Kimball County",
    "Knox County",
    "La Vista",
    "Lancaster County",
    "Lexington",
    "Lincoln",
    "Lincoln County",
    "Logan County",
    "Louisville",
    "Loup City",
    "Loup County",
    "Macy",
    "Madison",
    "Madison County",
    "McCook",
    "McPherson County",
    "Merrick County",
    "Milford",
    "Minden",
    "Mitchell",
    "Morrill County",
    "Mullen",
    "Nance County",
    "Nebraska City",
    "Neligh",
    "Nelson",
    "Nemaha County",
    "Norfolk",
    "North Bend",
    "North Platte",
    "Nuckolls County",
    "O'Neill",
    "Oakland",
    "Offutt Air Force Base",
    "Ogallala",
    "Omaha",
    "Ord",
    "Osceola",
    "Oshkosh",
    "Otoe County",
    "Papillion",
    "Pawnee City",
    "Pawnee County",
    "Pender",
    "Perkins County",
    "Phelps County",
    "Pierce",
    "Pierce County",
    "Plainview",
    "Platte County",
    "Plattsmouth",
    "Polk County",
    "Ponca",
    "Ralston",
    "Ravenna",
    "Red Cloud",
    "Red Willow County",
    "Richardson County",
    "Rock County",
    "Rushville",
    "Saint Paul",
    "Saline County",
    "Sarpy County",
    "Saunders County",
    "Schuyler",
    "Scotts Bluff County",
    "Scottsbluff",
    "Seward",
    "Seward County",
    "Shelton",
    "Sheridan County",
    "Sherman County",
    "Sidney",
    "Sioux County",
    "South Sioux City",
    "Springfield",
    "Springview",
    "Stanton",
    "Stanton County",
    "Stapleton",
    "Stockville",
    "Stromsburg",
    "Superior",
    "Sutherland",
    "Sutton",
    "Syracuse",
    "Taylor",
    "Tecumseh",
    "Tekamah",
    "Terrytown",
    "Thayer County",
    "Thedford",
    "Thomas County",
    "Thurston County",
    "Trenton",
    "Tryon",
    "Valentine",
    "Valley",
    "Valley County",
    "Wahoo",
    "Wakefield",
    "Washington County",
    "Waterloo",
    "Waverly",
    "Wayne",
    "Wayne County",
    "Webster County",
    "Weeping Water",
    "West Point",
    "Wheeler County",
    "Wilber",
    "Wisner",
    "Wood River",
    "Wymore",
    "York",
    "York County",
    "Yutan"
  ],
  "Nevada": [
    "Alamo",
    "Battle Mountain",
    "Beatty",
    "Boulder City",
    "Bunkerville",
    "Caliente",
    "Carlin",
    "Carson City",
    "Churchill County",
    "Clark County",
    "Cold Springs",
    "Dayton",
    "Douglas County",
    "East Valley",
    "Elko",
    "Elko County",
    "Ely",
    "Enterprise",
    "Esmeralda County",
    "Eureka",
    "Eureka County",
    "Fallon",
    "Fernley",
    "Gardnerville",
    "Gardnerville Ranchos",
    "Golden Valley",
    "Goldfield",
    "Hawthorne",
    "Henderson",
    "Humboldt County",
    "Incline Village",
    "Indian Hills",
    "Jackpot",
    "Johnson Lane",
    "Kingsbury",
    "Lander County",
    "Las Vegas",
    "Laughlin",
    "Lemmon Valley",
    "Lincoln County",
    "Lovelock",
    "Lyon County",
    "McGill",
    "Mesquite",
    "Minden",
    "Mineral County",
    "Moapa Town",
    "Moapa Valley",
    "Mogul",
    "Nellis Air Force Base",
    "North Las Vegas",
    "Nye County",
    "Pahrump",
    "Paradise",
    "Pershing County",
    "Pioche",
    "Reno",
    "Sandy Valley",
    "Silver Springs",
    "Smith",
    "Smith Valley",
    "Spanish Springs",
    "Sparks",
    "Spring Creek",
    "Spring Valley",
    "Stagecoach",
    "Storey County",
    "Summerlin South",
    "Sun Valley",
    "Sunrise Manor",
    "Tonopah",
    "Topaz Ranch Estates",
    "Verdi",
    "Virginia City",
    "Washoe County",
    "Wells",
    "West Wendover",
    "White Pine County",
    "Whitney",
    "Winchester",
    "Winnemucca",
    "Yerington"
  ],
  "New Hampshire": [
    "Alexandria",
    "Alstead",
    "Andover",
    "Antrim",
    "Ashland",
    "Atkinson",
    "Auburn",
    "Barnstead",
    "Barrington",
    "Bedford",
    "Belknap County",
    "Belmont",
    "Berlin",
    "Boscawen",
    "Bow Bog",
    "Brentwood",
    "Bridgewater",
    "Bristol",
    "Brookline",
    "Candia",
    "Canterbury",
    "Carroll County",
    "Center Harbor",
    "Charlestown",
    "Cheshire County",
    "Chester",
    "Chesterfield",
    "Chichester",
    "Claremont",
    "Colebrook",
    "Concord",
    "Contoocook",
    "Conway",
    "Coos County",
    "Danbury",
    "Danville",
    "Deerfield",
    "Deering",
    "Derry",
    "Derry Village",
    "Dover",
    "Dublin",
    "Durham",
    "East Concord",
    "East Kingston",
    "East Merrimack",
    "Effingham",
    "Enfield",
    "Epping",
    "Epsom",
    "Exeter",
    "Farmington",
    "Fitzwilliam",
    "Francestown",
    "Franklin",
    "Freedom",
    "Fremont",
    "Gilford",
    "Gilmanton",
    "Goffstown",
    "Gorham",
    "Grafton",
    "Grafton County",
    "Grantham",
    "Greenfield",
    "Greenland",
    "Greenville",
    "Groveton",
    "Hampstead",
    "Hampton",
    "Hampton Beach",
    "Hampton Falls",
    "Hanover",
    "Harrisville",
    "Haverhill",
    "Henniker",
    "Hill",
    "Hillsborough",
    "Hillsborough County",
    "Hinsdale",
    "Holderness",
    "Hollis",
    "Hooksett",
    "Hopkinton",
    "Hudson",
    "Jaffrey",
    "Jefferson",
    "Keene",
    "Kensington",
    "Kingston",
    "Laconia",
    "Lancaster",
    "Lebanon",
    "Lee",
    "Lempster",
    "Litchfield",
    "Littleton",
    "Londonderry",
    "Lyme",
    "Lyndeborough",
    "Madbury",
    "Madison",
    "Manchester",
    "Marlborough",
    "Mason",
    "Meredith",
    "Merrimack",
    "Merrimack County",
    "Milan",
    "Milford",
    "Mont Vernon",
    "Moultonborough",
    "Nashua",
    "New Boston",
    "New Castle",
    "New Durham",
    "New Ipswich",
    "New London",
    "Newbury",
    "Newmarket",
    "Newport",
    "Newton",
    "North Conway",
    "North Hampton",
    "Northfield",
    "Northumberland",
    "Northwood",
    "Nottingham",
    "Orford",
    "Ossipee",
    "Pelham",
    "Pembroke",
    "Peterborough",
    "Pinardville",
    "Pittsfield",
    "Plaistow",
    "Plymouth",
    "Portsmouth",
    "Raymond",
    "Richmond",
    "Rindge",
    "Rochester",
    "Rockingham County",
    "Rollinsford",
    "Rumney",
    "Rye",
    "Salem",
    "Salisbury",
    "Sanbornton",
    "Sanbornville",
    "Sandown",
    "Sandwich",
    "Seabrook",
    "Somersworth",
    "South Hooksett",
    "Springfield",
    "Strafford",
    "Strafford County",
    "Stratford",
    "Stratham Station",
    "Sullivan County",
    "Sunapee",
    "Suncook",
    "Sutton",
    "Swanzey",
    "Tamworth",
    "Temple",
    "Thornton",
    "Tilton",
    "Tilton-Northfield",
    "Troy",
    "Tuftonboro",
    "Unity",
    "Wakefield",
    "Weare",
    "Webster",
    "West Swanzey",
    "Westmoreland",
    "Whitefield",
    "Wilmot",
    "Wilton",
    "Winchester",
    "Windham",
    "Wolfeboro",
    "Woodstock",
    "Woodsville"
  ],
  "New Jersey": [
    "Absecon",
    "Allendale",
    "Allentown",
    "Alloway",
    "Alpha",
    "Alpine",
    "Annandale",
    "Asbury Park",
    "Ashland",
    "Atco",
    "Atlantic City",
    "Atlantic County",
    "Atlantic Highlands",
    "Audubon",
    "Audubon Park",
    "Avalon",
    "Avenel",
    "Avon-by-the-Sea",
    "Barnegat",
    "Barrington",
    "Bayonne",
    "Bayville",
    "Beach Haven",
    "Beach Haven West",
    "Beachwood",
    "Beattystown",
    "Beckett",
    "Bedminster",
    "Belford",
    "Belleville",
    "Bellmawr",
    "Belmar",
    "Belvidere",
    "Bergen County",
    "Bergenfield",
    "Berkeley Heights",
    "Berlin",
    "Bernardsville",
    "Beverly",
    "Blackwood",
    "Bloomfield",
    "Bloomingdale",
    "Bogota",
    "Boonton",
    "Bordentown",
    "Bound Brook",
    "Bradley Beach",
    "Bradley Gardens",
    "Brass Castle",
    "Bridgeton",
    "Bridgewater",
    "Brielle",
    "Brigantine",
    "Brookdale",
    "Brooklawn",
    "Browns Mills",
    "Brownville",
    "Budd Lake",
    "Buena",
    "Burlington",
    "Burlington County",
    "Butler",
    "Caldwell",
    "Califon",
    "Camden",
    "Camden County",
    "Cape May",
    "Cape May County",
    "Cape May Court House",
    "Carlstadt",
    "Carneys Point",
    "Carteret",
    "Cedar Glen Lakes",
    "Cedar Glen West",
    "Cedar Grove",
    "Chatham",
    "Cherry Hill",
    "Cherry Hill Mall",
    "Chesilhurst",
    "Chester",
    "Cinnaminson",
    "Clark",
    "Clayton",
    "Clearbrook Park",
    "Clementon",
    "Cliffside Park",
    "Cliffwood Beach",
    "Clifton",
    "Clinton",
    "Closter",
    "Collings Lakes",
    "Collingswood",
    "Colonia",
    "Concordia",
    "Country Lake Estates",
    "Cranbury",
    "Crandon Lakes",
    "Cranford",
    "Cresskill",
    "Crestwood Village",
    "Cumberland County",
    "Dayton",
    "Delanco",
    "Demarest",
    "Dover",
    "Dover Beaches North",
    "Dover Beaches South",
    "Dumont",
    "Dunellen",
    "East Brunswick",
    "East Franklin",
    "East Freehold",
    "East Hanover",
    "East Newark",
    "East Orange",
    "East Rutherford",
    "Eatontown",
    "Echelon",
    "Edgewater",
    "Edgewater Park",
    "Edison",
    "Egg Harbor City",
    "Elizabeth",
    "Ellisburg",
    "Elmer",
    "Elmwood Park",
    "Elwood",
    "Emerson",
    "Englewood",
    "Englewood Cliffs",
    "Englishtown",
    "Erma",
    "Essex County",
    "Essex Fells",
    "Estell Manor",
    "Ewing",
    "Fair Haven",
    "Fair Lawn",
    "Fairfield",
    "Fairton",
    "Fairview",
    "Fanwood",
    "Farmingdale",
    "Finderne",
    "Flemington",
    "Florence",
    "Florham Park",
    "Folsom",
    "Fords",
    "Forked River",
    "Fort Dix",
    "Fort Lee",
    "Franklin",
    "Franklin Center",
    "Franklin Lakes",
    "Franklin Park",
    "Freehold",
    "Frenchtown",
    "Garfield",
    "Garwood",
    "Gibbsboro",
    "Gibbstown",
    "Gladstone",
    "Glassboro",
    "Glen Gardner",
    "Glen Ridge",
    "Glen Rock",
    "Glendora",
    "Gloucester City",
    "Gloucester County",
    "Golden Triangle",
    "Green Knoll",
    "Greentree",
    "Groveville",
    "Guttenberg",
    "Hackensack",
    "Hackettstown",
    "Haddon Heights",
    "Haddonfield",
    "Haledon",
    "Hamburg",
    "Hamilton Square",
    "Hammonton",
    "Hampton",
    "Hanover",
    "Hardwick",
    "Harrington Park",
    "Harrison",
    "Hasbrouck Heights",
    "Haworth",
    "Hawthorne",
    "Hazlet Township",
    "Heathcote",
    "Helmetta",
    "High Bridge",
    "Highland Lake",
    "Highland Park",
    "Highlands",
    "Hightstown",
    "Hillsdale",
    "Hillside",
    "Ho-Ho-Kus",
    "Hoboken",
    "Holiday City South",
    "Holiday City-Berkeley",
    "Holiday Heights",
    "Hopatcong",
    "Hopatcong Hills",
    "Hopewell",
    "Hudson County",
    "Hunterdon County",
    "Irvington",
    "Iselin",
    "Island Heights",
    "Jackson",
    "Jamesburg",
    "Jersey City",
    "Keansburg",
    "Kearny",
    "Kendall Park",
    "Kenilworth",
    "Kenvil",
    "Keyport",
    "Kingston",
    "Kingston Estates",
    "Kinnelon",
    "Lake Como",
    "Lake Mohawk",
    "Lake Telemark",
    "Lakehurst",
    "Lakewood",
    "Lambertville",
    "Landing",
    "Laurel Lake",
    "Laurel Springs",
    "Laurence Harbor",
    "Lavallette",
    "Lawnside",
    "Lawrenceville",
    "Lebanon",
    "Leisure Knoll",
    "Leisure Village",
    "Leisure Village East",
    "Leisure Village West-Pine Lake Park",
    "Leisuretowne",
    "Leonardo",
    "Leonia",
    "Lincoln Park",
    "Lincroft",
    "Linden",
    "Lindenwold",
    "Linwood",
    "Little Falls",
    "Little Ferry",
    "Little Silver",
    "Livingston",
    "Lodi",
    "Long Branch",
    "Long Valley",
    "Lyndhurst",
    "Madison",
    "Madison Park",
    "Magnolia",
    "Mahwah",
    "Manahawkin",
    "Manasquan",
    "Mantua Township",
    "Manville",
    "Maple Shade",
    "Maplewood",
    "Margate City",
    "Marlboro",
    "Marlton",
    "Martinsville",
    "Matawan",
    "Mays Landing",
    "Maywood",
    "McGuire AFB",
    "Medford Lakes",
    "Mendham",
    "Mercer County",
    "Mercerville",
    "Mercerville-Hamilton Square",
    "Merchantville",
    "Metuchen",
    "Middlebush",
    "Middlesex",
    "Middlesex County",
    "Midland Park",
    "Milford",
    "Milltown",
    "Millville",
    "Monmouth Beach",
    "Monmouth County",
    "Monmouth Junction",
    "Montclair",
    "Montvale",
    "Moonachie",
    "Moorestown-Lenola",
    "Morganville",
    "Morris County",
    "Morris Plains",
    "Morristown",
    "Mount Arlington",
    "Mount Ephraim",
    "Mount Holly",
    "Mount Laurel",
    "Mountain Lakes",
    "Mountainside",
    "Mullica Hill",
    "Mystic Island",
    "National Park",
    "Navesink",
    "Neptune City",
    "Netcong",
    "New Brunswick",
    "New Egypt",
    "New Milford",
    "New Providence",
    "Newark",
    "Newfield",
    "Newton",
    "North Arlington",
    "North Beach Haven",
    "North Bergen",
    "North Caldwell",
    "North Cape May",
    "North Haledon",
    "North Middletown",
    "North Plainfield",
    "North Wildwood",
    "Northfield",
    "Northvale",
    "Norwood",
    "Nutley",
    "Oak Valley",
    "Oakhurst",
    "Oakland",
    "Oaklyn",
    "Ocean Acres",
    "Ocean City",
    "Ocean County",
    "Ocean Gate",
    "Ocean Grove",
    "Oceanport",
    "Ogdensburg",
    "Old Bridge",
    "Old Tappan",
    "Olivet",
    "Oradell",
    "Orange",
    "Oxford",
    "Palisades Park",
    "Palmyra",
    "Paramus",
    "Park Ridge",
    "Parsippany",
    "Passaic",
    "Passaic County",
    "Paterson",
    "Paulsboro",
    "Peapack",
    "Pemberton",
    "Pemberton Heights",
    "Pennington",
    "Penns Grove",
    "Pennsauken",
    "Pennsville",
    "Perth Amboy",
    "Phillipsburg",
    "Pine Beach",
    "Pine Hill",
    "Pine Lake Park",
    "Pine Ridge at Crestwood",
    "Piscataway",
    "Pitman",
    "Plainfield",
    "Plainsboro Center",
    "Pleasantville",
    "Point Pleasant",
    "Point Pleasant Beach",
    "Pomona",
    "Pompton Lakes",
    "Port Monmouth",
    "Port Norris",
    "Port Reading",
    "Port Republic",
    "Presidential Lakes Estates",
    "Princeton",
    "Princeton Junction",
    "Princeton Meadows",
    "Prospect Park",
    "Rahway",
    "Ramblewood",
    "Ramsey",
    "Ramtown",
    "Randolph",
    "Raritan",
    "Red Bank",
    "Richwood",
    "Ridgefield",
    "Ridgefield Park",
    "Ridgewood",
    "Ringwood",
    "Rio Grande",
    "River Edge",
    "River Vale",
    "Riverdale",
    "Riverton",
    "Robbinsville",
    "Robertsville",
    "Rochelle Park",
    "Rockaway",
    "Roebling",
    "Roseland",
    "Roselle",
    "Roselle Park",
    "Rosenhayn",
    "Rossmoor",
    "Rumson",
    "Runnemede",
    "Rutherford",
    "Saddle Brook",
    "Saddle River",
    "Salem",
    "Salem County",
    "Sayreville",
    "Sayreville Junction",
    "Scotch Plains",
    "Sea Bright",
    "Sea Girt",
    "Sea Isle City",
    "Seabrook Farms",
    "Seaside Heights",
    "Seaside Park",
    "Secaucus",
    "Sewaren",
    "Shark River Hills",
    "Ship Bottom",
    "Short Hills",
    "Shrewsbury",
    "Sicklerville",
    "Silver Ridge",
    "Singac",
    "Sixmile Run",
    "Smithville",
    "Society Hill",
    "Somerdale",
    "Somers Point",
    "Somerset",
    "Somerset County",
    "Somerville",
    "South Amboy",
    "South Belmar",
    "South Bound Brook",
    "South Old Bridge",
    "South Orange",
    "South Plainfield",
    "South River",
    "South Toms River",
    "South Vineland",
    "Sparta",
    "Spotswood",
    "Spring Lake",
    "Spring Lake Heights",
    "Springdale",
    "Springfield",
    "Stanhope",
    "Stratford",
    "Strathmore",
    "Succasunna",
    "Summit",
    "Surf City",
    "Sussex",
    "Sussex County",
    "Swedesboro",
    "Teaneck",
    "Ten Mile Run",
    "Tenafly",
    "Tinton Falls",
    "Toms River",
    "Totowa",
    "Trenton",
    "Tuckerton",
    "Turnersville",
    "Twin Rivers",
    "Union",
    "Union Beach",
    "Union City",
    "Union County",
    "Upper Montclair",
    "Upper Pohatcong",
    "Upper Saddle River",
    "Ventnor City",
    "Vernon Center",
    "Vernon Valley",
    "Verona",
    "Victory Gardens",
    "Victory Lakes",
    "Villas",
    "Vincentown",
    "Vineland",
    "Vista Center",
    "Voorhees",
    "Waldwick",
    "Wallington",
    "Wanamassa",
    "Wanaque",
    "Waretown",
    "Warren County",
    "Warren Township",
    "Washington",
    "Watchung",
    "Wayne",
    "Weehawken",
    "Wenonah",
    "West Belmar",
    "West Cape May",
    "West Freehold",
    "West Long Branch",
    "West Milford",
    "West New York",
    "West Orange",
    "Westfield",
    "Weston",
    "Westville",
    "Westwood",
    "Wharton",
    "White Horse",
    "White Meadow Lake",
    "Whitehouse Station",
    "Whitesboro",
    "Whitesboro-Burleigh",
    "Whittingham",
    "Wildwood",
    "Wildwood Crest",
    "Williamstown",
    "Willingboro",
    "Winfield",
    "Wood-Lynne",
    "Wood-Ridge",
    "Woodbine",
    "Woodbridge",
    "Woodbury",
    "Woodbury Heights",
    "Woodcliff Lake",
    "Woodland Park",
    "Woodstown",
    "Wyckoff",
    "Yardville",
    "Yorketown"
  ],
  "New Mexico": [
    "Agua Fria",
    "Alamo",
    "Alamogordo",
    "Albuquerque",
    "Angel Fire",
    "Anthony",
    "Arenas Valley",
    "Arroyo Seco",
    "Artesia",
    "Atoka",
    "Aztec",
    "Bayard",
    "Belen",
    "Berino",
    "Bernalillo",
    "Bernalillo County",
    "Black Rock",
    "Bloomfield",
    "Boles Acres",
    "Bosque Farms",
    "Cannon Air Force Base",
    "Capitan",
    "Carlsbad",
    "Carnuel",
    "Carrizozo",
    "Catron County",
    "Chama",
    "Chaparral",
    "Chaves County",
    "Chimayo",
    "Church Rock",
    "Cibola County",
    "Clayton",
    "Clovis",
    "Colfax County",
    "Columbus",
    "Corrales",
    "Crownpoint",
    "Curry County",
    "De Baca County",
    "Deming",
    "Dexter",
    "Doña Ana",
    "Doña Ana County",
    "Dulce",
    "Eddy County",
    "Edgewood",
    "El Cerro",
    "El Cerro Mission",
    "El Rancho",
    "El Valle de Arroyo Seco",
    "Eldorado at Santa Fe",
    "Elephant Butte",
    "Enchanted Hills",
    "Española",
    "Estancia",
    "Eunice",
    "Farmington",
    "Flora Vista",
    "Fort Sumner",
    "Gallup",
    "Grant County",
    "Grants",
    "Guadalupe County",
    "Hagerman",
    "Harding County",
    "Hatch",
    "Hidalgo County",
    "Hobbs",
    "Holloman Air Force Base",
    "Hurley",
    "Jal",
    "Jarales",
    "Jemez Pueblo",
    "Keeler Farm",
    "Kirtland",
    "La Cienega",
    "La Huerta",
    "La Luz",
    "La Mesilla",
    "La Puebla",
    "La Union",
    "Laguna",
    "Las Cruces",
    "Las Maravillas",
    "Las Vegas",
    "Lea County",
    "Lee Acres",
    "Lincoln County",
    "Lordsburg",
    "Los Alamos",
    "Los Alamos County",
    "Los Chavez",
    "Los Lunas",
    "Los Ranchos de Albuquerque",
    "Loving",
    "Lovington",
    "Luna County",
    "McIntosh",
    "McKinley County",
    "Meadow Lake",
    "Mescalero",
    "Mesilla",
    "Mesquite",
    "Milan",
    "Monterey Park",
    "Mora",
    "Mora County",
    "Moriarty",
    "Mosquero",
    "Nambe",
    "Navajo",
    "North Valley",
    "Ohkay Owingeh",
    "Otero County",
    "Paradise Hills",
    "Pecos",
    "Peralta",
    "Placitas",
    "Pojoaque",
    "Ponderosa Pine",
    "Portales",
    "Quay County",
    "Questa",
    "Radium Springs",
    "Ranchos de Taos",
    "Raton",
    "Reserve",
    "Rio Arriba County",
    "Rio Communities",
    "Rio Rancho",
    "Roosevelt County",
    "Roswell",
    "Ruidoso",
    "Ruidoso Downs",
    "San Felipe Pueblo",
    "San Juan County",
    "San Miguel",
    "San Miguel County",
    "San Ysidro",
    "Sandia Heights",
    "Sandia Knolls",
    "Sandoval County",
    "Santa Clara",
    "Santa Clara Pueblo",
    "Santa Fe",
    "Santa Fe County",
    "Santa Rosa",
    "Santa Teresa",
    "Santo Domingo Pueblo",
    "Shiprock",
    "Sierra County",
    "Silver City",
    "Skyline-Ganipa",
    "Socorro",
    "Socorro County",
    "South Valley",
    "Spencerville",
    "Sunland Park",
    "Taos",
    "Taos County",
    "Taos Pueblo",
    "Texico",
    "Thoreau",
    "Tierra Amarilla",
    "Tome",
    "Torrance County",
    "Truth or Consequences",
    "Tucumcari",
    "Tularosa",
    "Twin Lakes",
    "Union County",
    "University Park",
    "Upper Fruitland",
    "Vado",
    "Valencia",
    "Valencia County",
    "Waterflow",
    "West Hammond",
    "White Rock",
    "White Sands",
    "Zuni Pueblo"
  ],
  "New York": [
    "Adams",
    "Adams Center",
    "Addison",
    "Airmont",
    "Akron",
    "Alabama",
    "Albany",
    "Albany County",
    "Albertson",
    "Albion",
    "Alden",
    "Alexandria Bay",
    "Alfred",
    "Allegany",
    "Allegany County",
    "Altamont",
    "Amagansett",
    "Amherst",
    "Amityville",
    "Amsterdam",
    "Andover",
    "Angola",
    "Angola on the Lake",
    "Apalachin",
    "Aquebogue",
    "Arcade",
    "Ardsley",
    "Arlington",
    "Armonk",
    "Arrochar",
    "Arverne",
    "Astoria",
    "Athens",
    "Atlantic Beach",
    "Attica",
    "Auburn",
    "Augusta",
    "Averill Park",
    "Avon",
    "Babylon",
    "Bainbridge",
    "Baiting Hollow",
    "Baldwin",
    "Baldwin Harbor",
    "Baldwinsville",
    "Ballston Spa",
    "Balmville",
    "Bardonia",
    "Barnum Island",
    "Batavia",
    "Bath",
    "Bath Beach",
    "Baxter Estates",
    "Bay Park",
    "Bay Shore",
    "Bay Wood",
    "Baychester",
    "Bayport",
    "Bayside",
    "Bayville",
    "Beacon",
    "Beaver Dam Lake",
    "Beaverdam Lake-Salisbury Mills",
    "Bedford",
    "Bedford Hills",
    "Bellaire",
    "Belle Harbor",
    "Bellerose",
    "Bellerose Terrace",
    "Bellmore",
    "Bellport",
    "Belmont",
    "Bensonhurst",
    "Bergen",
    "Bergen Beach",
    "Bethpage",
    "Big Flats",
    "Billington Heights",
    "Binghamton",
    "Black River",
    "Blasdell",
    "Blauvelt",
    "Bloomfield",
    "Blue Point",
    "Bohemia",
    "Bolivar",
    "Boonville",
    "Borough Park",
    "Boston",
    "Brentwood",
    "Brewerton",
    "Brewster",
    "Brewster Hill",
    "Briarcliff Manor",
    "Briarwood",
    "Bridgehampton",
    "Bridgeport",
    "Brighton",
    "Brighton Beach",
    "Brightwaters",
    "Brinckerhoff",
    "Broad Channel",
    "Broadalbin",
    "Brockport",
    "Brocton",
    "Bronx",
    "Bronxville",
    "Brookhaven",
    "Brooklyn",
    "Brooklyn Heights",
    "Brookville",
    "Broome County",
    "Brownsville",
    "Brownville",
    "Buchanan",
    "Buffalo",
    "Bushwick",
    "Cairo",
    "Calcium",
    "Caledonia",
    "Calverton",
    "Cambria Heights",
    "Cambridge",
    "Camden",
    "Camillus",
    "Canajoharie",
    "Canandaigua",
    "Canarsie",
    "Canastota",
    "Canisteo",
    "Canton",
    "Carle Place",
    "Carmel",
    "Carmel Hamlet",
    "Carthage",
    "Castleton-on-Hudson",
    "Catskill",
    "Cattaraugus County",
    "Cayuga County",
    "Cayuga Heights",
    "Cazenovia",
    "Cedarhurst",
    "Celoron",
    "Center Moriches",
    "Centereach",
    "Centerport",
    "Central Islip",
    "Central Square",
    "Central Valley",
    "Chadwicks",
    "Champlain",
    "Chappaqua",
    "Charleston",
    "Chatham",
    "Chautauqua County",
    "Cheektowaga",
    "Chelsea",
    "Chemung County",
    "Chenango Bridge",
    "Chenango County",
    "Chester",
    "Chestnut Ridge",
    "Chittenango",
    "Churchville",
    "City Island",
    "Clarence",
    "Clarence Center",
    "Clark Mills",
    "Clarkson",
    "Clayton",
    "Clifton",
    "Clifton Springs",
    "Clinton",
    "Clinton County",
    "Clintondale",
    "Clyde",
    "Clymer",
    "Cobleskill",
    "Cohoes",
    "Cold Spring",
    "Cold Spring Harbor",
    "College Point",
    "Colonie",
    "Columbia County",
    "Commack",
    "Concord",
    "Coney Island",
    "Congers",
    "Constantia",
    "Cooperstown",
    "Copiague",
    "Coram",
    "Corinth",
    "Corning",
    "Cornwall",
    "Corona",
    "Cortland",
    "Cortland County",
    "Cortland West",
    "Country Knolls",
    "Coxsackie",
    "Crompond",
    "Croton-on-Hudson",
    "Crown Heights",
    "Crugers",
    "Cuba",
    "Cumberland Head",
    "Cutchogue",
    "Cypress Hills",
    "Dannemora",
    "Dansville",
    "Deer Park",
    "Delaware County",
    "Delevan",
    "Delhi",
    "Delmar",
    "Depew",
    "Deposit",
    "Dexter",
    "Dix Hills",
    "Dobbs Ferry",
    "Dolgeville",
    "Dongan Hills",
    "Douglaston",
    "Dover Plains",
    "Dryden",
    "Dundee",
    "Dunkirk",
    "Durham",
    "Dutchess County",
    "Dyker Heights",
    "East Atlantic Beach",
    "East Aurora",
    "East Elmhurst",
    "East Farmingdale",
    "East Flatbush",
    "East Garden City",
    "East Glenville",
    "East Greenbush",
    "East Hampton",
    "East Hampton North",
    "East Harlem",
    "East Hills",
    "East Islip",
    "East Ithaca",
    "East Massapequa",
    "East Meadow",
    "East Moriches",
    "East New York",
    "East Northport",
    "East Norwich",
    "East Patchogue",
    "East Quogue",
    "East Rochester",
    "East Rockaway",
    "East Setauket",
    "East Shoreham",
    "East Syracuse",
    "East Tremont",
    "East Village",
    "East Williston",
    "Eastchester",
    "Eastport",
    "Eatons Neck",
    "Eden",
    "Edgemere",
    "Edinburg",
    "Eggertsville",
    "Elbridge",
    "Elizabethtown",
    "Ellenville",
    "Elma Center",
    "Elmhurst",
    "Elmira",
    "Elmira Heights",
    "Elmont",
    "Elmsford",
    "Eltingville",
    "Elwood",
    "Emerson Hill",
    "Endicott",
    "Endwell",
    "Erie County",
    "Essex County",
    "Fairmount",
    "Fairport",
    "Fairview",
    "Falconer",
    "Fallsburg",
    "Far Rockaway",
    "Farmingdale",
    "Farmingville",
    "Fayetteville",
    "Financial District",
    "Firthcliffe",
    "Fishkill",
    "Flanders",
    "Flatbush",
    "Flatlands",
    "Floral Park",
    "Florida",
    "Flower Hill",
    "Fonda",
    "Fordham",
    "Forest Hills",
    "Fort Covington Hamlet",
    "Fort Drum",
    "Fort Edward",
    "Fort Hamilton",
    "Fort Montgomery",
    "Fort Plain",
    "Fort Salonga",
    "Fort Wadsworth",
    "Frankfort",
    "Franklin County",
    "Franklin Square",
    "Franklinville",
    "Fredonia",
    "Freeport",
    "Fresh Meadows",
    "Frewsburg",
    "Friendship",
    "Fulton",
    "Fulton County",
    "Galeville",
    "Gang Mills",
    "Garden City",
    "Garden City Park",
    "Garden City South",
    "Gardnertown",
    "Gasport",
    "Gates-North Gates",
    "Genesee County",
    "Geneseo",
    "Geneva",
    "Glasco",
    "Glen Cove",
    "Glen Head",
    "Glen Oaks",
    "Glendale",
    "Glens Falls",
    "Glens Falls North",
    "Glenwood Landing",
    "Gloversville",
    "Goldens Bridge",
    "Gordon Heights",
    "Goshen",
    "Gouverneur",
    "Gowanda",
    "Gramercy Park",
    "Grand Island",
    "Grandyle Village",
    "Graniteville",
    "Grant City",
    "Granville",
    "Gravesend",
    "Great Kills",
    "Great Neck",
    "Great Neck Estates",
    "Great Neck Gardens",
    "Great Neck Plaza",
    "Great River",
    "Greece",
    "Green Island",
    "Greenburgh",
    "Greene",
    "Greene County",
    "Greenlawn",
    "Greenpoint",
    "Greenport",
    "Greenport West",
    "Greenvale",
    "Greenville",
    "Greenwich",
    "Greenwood Lake",
    "Groton",
    "Grymes Hill",
    "Hadley",
    "Hagaman",
    "Halesite",
    "Hamburg",
    "Hamilton",
    "Hamilton County",
    "Hamlin",
    "Hampton Bays",
    "Hampton Manor",
    "Hannawa Falls",
    "Harbor Isle",
    "Harlem",
    "Harriman",
    "Harris Hill",
    "Harrison",
    "Hartford",
    "Hartsdale",
    "Hastings-on-Hudson",
    "Hauppauge",
    "Haverstraw",
    "Haviland",
    "Hawthorne",
    "Head of the Harbor",
    "Hell's Kitchen",
    "Hempstead",
    "Heritage Hills",
    "Herkimer",
    "Herkimer County",
    "Herricks",
    "Hewlett",
    "Hewlett Harbor",
    "Hicksville",
    "Highland",
    "Highland Falls",
    "Highland Mills",
    "Hillcrest",
    "Hillside",
    "Hillside Lake",
    "Hilton",
    "Holbrook",
    "Holcomb",
    "Holland",
    "Holley",
    "Hollis",
    "Holtsville",
    "Homer",
    "Honeoye Falls",
    "Hoosick Falls",
    "Hornell",
    "Horseheads",
    "Horseheads North",
    "Houghton",
    "Howard Beach",
    "Hudson",
    "Hudson Falls",
    "Huguenot",
    "Huntington",
    "Huntington Bay",
    "Huntington Station",
    "Hunts Point",
    "Hurley",
    "Hyde Park",
    "Ilion",
    "Inwood",
    "Irondequoit",
    "Irvington",
    "Island Park",
    "Islandia",
    "Islip",
    "Islip Terrace",
    "Ithaca",
    "Jackson Heights",
    "Jamaica",
    "Jamesport",
    "Jamestown",
    "Jamestown West",
    "Jefferson County",
    "Jefferson Heights",
    "Jefferson Valley-Yorktown",
    "Jericho",
    "Johnson City",
    "Johnstown",
    "Jordan",
    "Kaser",
    "Katonah",
    "Keeseville",
    "Kenmore",
    "Kensington",
    "Kerhonkson",
    "Keuka Park",
    "Kew Gardens",
    "Kew Gardens Hills",
    "Kiantone",
    "Kinderhook",
    "Kings Bridge",
    "Kings County",
    "Kings Park",
    "Kings Point",
    "Kingston",
    "Kiryas Joel",
    "Lackawanna",
    "Lake Carmel",
    "Lake Erie Beach",
    "Lake Grove",
    "Lake Katrine",
    "Lake Luzerne",
    "Lake Mohegan",
    "Lake Placid",
    "Lake Pleasant",
    "Lake Ronkonkoma",
    "Lake Success",
    "Lakeland",
    "Lakeview",
    "Lakewood",
    "Lancaster",
    "Lansing",
    "Larchmont",
    "Latham",
    "Lattingtown",
    "Laurel",
    "Laurel Hollow",
    "Laurelton",
    "Lawrence",
    "Le Roy",
    "Levittown",
    "Lewis County",
    "Lewiston",
    "Liberty",
    "Lido Beach",
    "Lima",
    "Lincoln Park",
    "Lincolndale",
    "Lindenhurst",
    "Lindley",
    "Little Falls",
    "Little Neck",
    "Little Valley",
    "Liverpool",
    "Livingston County",
    "Livingston Manor",
    "Livonia",
    "Lloyd Harbor",
    "Lockport",
    "Locust Valley",
    "Long Beach",
    "Long Island City",
    "Lorenz Park",
    "Lowville",
    "Lynbrook",
    "Lyncourt",
    "Lyons",
    "Macedon",
    "Madison County",
    "Mahopac",
    "Malone",
    "Malverne",
    "Mamaroneck",
    "Manchester",
    "Manhasset",
    "Manhasset Hills",
    "Manhattan",
    "Manlius",
    "Manorhaven",
    "Manorville",
    "Marbletown",
    "Marcellus",
    "Mariners Harbor",
    "Marion",
    "Marlboro",
    "Maspeth",
    "Massapequa",
    "Massapequa Park",
    "Massena",
    "Mastic",
    "Mastic Beach",
    "Mattituck",
    "Mattydale",
    "Maybrook",
    "Mayville",
    "McGraw",
    "McKownville",
    "Mechanicstown",
    "Mechanicville",
    "Medford",
    "Medina",
    "Melrose",
    "Melrose Park",
    "Melville",
    "Menands",
    "Merrick",
    "Merritt Park",
    "Mexico",
    "Middle Island",
    "Middle Village",
    "Middleburgh",
    "Middleport",
    "Middletown",
    "Midland Beach",
    "Mill Neck",
    "Millbrook",
    "Miller Place",
    "Milton",
    "Mineola",
    "Minetto",
    "Mineville",
    "Minoa",
    "Mohawk",
    "Monroe",
    "Monroe County",
    "Monsey",
    "Montauk",
    "Montebello",
    "Montgomery",
    "Montgomery County",
    "Monticello",
    "Montour Falls",
    "Montrose",
    "Moravia",
    "Moriches",
    "Morningside Heights",
    "Morris Heights",
    "Morris Park",
    "Morrisania",
    "Morrisonville",
    "Morrisville",
    "Mott Haven",
    "Mount Ivy",
    "Mount Kisco",
    "Mount Morris",
    "Mount Sinai",
    "Mount Vernon",
    "Mountain Lodge Park",
    "Munsey Park",
    "Munsons Corners",
    "Muttontown",
    "Myers Corner",
    "Nanuet",
    "Napanoch",
    "Naples",
    "Nassau",
    "Nassau County",
    "Nedrow",
    "Neponsit",
    "Nesconset",
    "New Brighton",
    "New Cassel",
    "New City",
    "New Dorp",
    "New Dorp Beach",
    "New Hartford",
    "New Hempstead",
    "New Hyde Park",
    "New Paltz",
    "New Rochelle",
    "New Springville",
    "New Square",
    "New Windsor",
    "New York City",
    "New York County",
    "New York Mills",
    "Newark",
    "Newburgh",
    "Newfane",
    "Niagara County",
    "Niagara Falls",
    "Niskayuna",
    "Nissequogue",
    "Niverville",
    "Norfolk",
    "North Amityville",
    "North Babylon",
    "North Ballston Spa",
    "North Bay Shore",
    "North Bellmore",
    "North Bellport",
    "North Boston",
    "North Castle",
    "North Collins",
    "North Elba",
    "North Gates",
    "North Great River",
    "North Hills",
    "North Lindenhurst",
    "North Massapequa",
    "North Merrick",
    "North New Hyde Park",
    "North Patchogue",
    "North Sea",
    "North Syracuse",
    "North Tonawanda",
    "North Valley Stream",
    "North Wantagh",
    "Northeast Ithaca",
    "Northport",
    "Northumberland",
    "Northville",
    "Northwest Harbor",
    "Northwest Ithaca",
    "Norwich",
    "Norwood",
    "Noyack",
    "Nunda",
    "Nyack",
    "Oakdale",
    "Oakfield",
    "Oakwood",
    "Oceanside",
    "Ogdensburg",
    "Olcott",
    "Old Bethpage",
    "Old Brookville",
    "Old Westbury",
    "Olean",
    "Oneida",
    "Oneida County",
    "Oneonta",
    "Onondaga County",
    "Ontario",
    "Ontario County",
    "Orange County",
    "Orange Lake",
    "Orangeburg",
    "Orchard Park",
    "Oriskany",
    "Orleans County",
    "Ossining",
    "Oswego",
    "Oswego County",
    "Otisville",
    "Otsego County",
    "Owego",
    "Oxford",
    "Oyster Bay",
    "Oyster Bay Cove",
    "Ozone Park",
    "Painted Post",
    "Palenville",
    "Palmyra",
    "Park Slope",
    "Parkchester",
    "Patchogue",
    "Pawling",
    "Peach Lake",
    "Pearl River",
    "Peekskill",
    "Pelham",
    "Pelham Manor",
    "Penn Yan",
    "Perry",
    "Perth",
    "Peru",
    "Phelps",
    "Philadelphia",
    "Philmont",
    "Phoenix",
    "Piermont",
    "Pine Bush",
    "Pine Plains",
    "Pittsford",
    "Plainedge",
    "Plainview",
    "Plandome",
    "Plandome Heights",
    "Plattekill",
    "Plattsburgh",
    "Plattsburgh West",
    "Pleasant Valley",
    "Pleasantville",
    "Poestenkill",
    "Point Lookout",
    "Pomona",
    "Port Byron",
    "Port Chester",
    "Port Dickinson",
    "Port Ewen",
    "Port Henry",
    "Port Jefferson",
    "Port Jefferson Station",
    "Port Jervis",
    "Port Morris",
    "Port Richmond",
    "Port Washington",
    "Port Washington North",
    "Portland",
    "Potsdam",
    "Poughkeepsie",
    "Pound Ridge",
    "Pulaski",
    "Purchase",
    "Putnam County",
    "Putnam Lake",
    "Queens",
    "Queens County",
    "Queens Village",
    "Queensbury",
    "Randolph",
    "Ransomville",
    "Rapids",
    "Ravena",
    "Red Hook",
    "Red Oaks Mill",
    "Rego Park",
    "Remsenburg-Speonk",
    "Rensselaer",
    "Rensselaer County",
    "Rhinebeck",
    "Richfield Springs",
    "Richland",
    "Richmond County",
    "Richmond Hill",
    "Ridge",
    "Ridgewood",
    "Riverdale",
    "Riverhead",
    "Riverside",
    "Rochester",
    "Rock Hill",
    "Rockaway Point",
    "Rockland County",
    "Rockville Centre",
    "Rocky Point",
    "Roessleville",
    "Rome",
    "Ronkonkoma",
    "Roosevelt",
    "Rosebank",
    "Rosedale",
    "Rosendale Village",
    "Roslyn",
    "Roslyn Estates",
    "Roslyn Harbor",
    "Roslyn Heights",
    "Rossville",
    "Rotterdam",
    "Rouses Point",
    "Rye",
    "Rye Brook",
    "Sackets Harbor",
    "Sag Harbor",
    "Saint Bonaventure",
    "Saint James",
    "Saint Johnsville",
    "Salamanca",
    "Salisbury",
    "Sanborn",
    "Sands Point",
    "Saranac Lake",
    "Saratoga County",
    "Saratoga Springs",
    "Saugerties",
    "Saugerties South",
    "Sayville",
    "Scarsdale",
    "Schenectady",
    "Schenectady County",
    "Schoharie",
    "Schoharie County",
    "Schuyler County",
    "Schuylerville",
    "Scotchtown",
    "Scotia",
    "Scottsville",
    "Sea Cliff",
    "Seaford",
    "Searingtown",
    "Seaside",
    "Selden",
    "Seneca County",
    "Seneca Falls",
    "Seneca Knolls",
    "Setauket-East Setauket",
    "Sheepshead Bay",
    "Shelter Island",
    "Shelter Island Heights",
    "Shenorock",
    "Sherburne",
    "Sherrill",
    "Shinnecock Hills",
    "Shirley",
    "Shokan",
    "Shortsville",
    "Shrub Oak",
    "Sidney",
    "Silver Creek",
    "Skaneateles",
    "Sleepy Hollow",
    "Sloan",
    "Sloatsburg",
    "Smithtown",
    "Sodus",
    "Solvay",
    "Sound Beach",
    "South Beach",
    "South Blooming Grove",
    "South Corning",
    "South Fallsburg",
    "South Farmingdale",
    "South Floral Park",
    "South Glens Falls",
    "South Hempstead",
    "South Hill",
    "South Huntington",
    "South Lockport",
    "South Nyack",
    "South Valley Stream",
    "Southampton",
    "Southold",
    "Southport",
    "Spackenkill",
    "Sparkill",
    "Spencerport",
    "Spring Valley",
    "Springfield",
    "Springfield Gardens",
    "Springs",
    "Springville",
    "Spuyten Duyvil",
    "St. Lawrence County",
    "Stamford",
    "Stapleton",
    "Staten Island",
    "Steinway",
    "Steuben County",
    "Stewart Manor",
    "Stillwater",
    "Stone Ridge",
    "Stony Brook",
    "Stony Point",
    "Stottville",
    "Suffern",
    "Suffolk County",
    "Sullivan County",
    "Sunnyside",
    "Sunset Park",
    "Sylvan Beach",
    "Syosset",
    "Syracuse",
    "Tappan",
    "Tarrytown",
    "Terrace Heights",
    "Terryville",
    "The Bronx",
    "Thiells",
    "Thomaston",
    "Thornwood",
    "Throgs Neck",
    "Ticonderoga",
    "Tillson",
    "Tioga County",
    "Tivoli",
    "Tompkins County",
    "Tompkinsville",
    "Tonawanda",
    "Town Line",
    "Tremont",
    "Tribes Hill",
    "Troy",
    "Trumansburg",
    "Tuckahoe",
    "Tupper Lake",
    "Ulster County",
    "Unadilla",
    "Union Springs",
    "Uniondale",
    "Unionport",
    "University Gardens",
    "University Heights",
    "Upper Brookville",
    "Upper Nyack",
    "Utica",
    "Vails Gate",
    "Valatie",
    "Valhalla",
    "Valley Cottage",
    "Valley Stream",
    "Van Nest",
    "Vernon",
    "Verplanck",
    "Victor",
    "Village Green",
    "Village of the Branch",
    "Viola",
    "Volney",
    "Voorheesville",
    "Wading River",
    "Wakefield",
    "Walden",
    "Wallkill",
    "Walton",
    "Walton Park",
    "Wampsville",
    "Wanakah",
    "Wantagh",
    "Wappingers Falls",
    "Warren County",
    "Warrensburg",
    "Warsaw",
    "Warwick",
    "Washington County",
    "Washington Heights",
    "Washington Mills",
    "Washingtonville",
    "Watchtower",
    "Water Mill",
    "Waterford",
    "Waterloo",
    "Watertown",
    "Waterville",
    "Watervliet",
    "Watkins Glen",
    "Waverly",
    "Wawarsing",
    "Wayland",
    "Wayne County",
    "Webster",
    "Weedsport",
    "Wellsville",
    "Wesley Hills",
    "West Albany",
    "West Babylon",
    "West Bay Shore",
    "West Carthage",
    "West Elmira",
    "West End",
    "West Glens Falls",
    "West Haverstraw",
    "West Hempstead",
    "West Hills",
    "West Hurley",
    "West Islip",
    "West Nyack",
    "West Point",
    "West Sand Lake",
    "West Sayville",
    "West Seneca",
    "Westbury",
    "Westchester County",
    "Westerleigh",
    "Westfield",
    "Westhampton",
    "Westhampton Beach",
    "Westmere",
    "Weston Mills",
    "Westvale",
    "Wheatley Heights",
    "White Plains",
    "Whitehall",
    "Whitesboro",
    "Whitestone",
    "Williamsburg",
    "Williamson",
    "Williamsville",
    "Williston Park",
    "Willowbrook",
    "Wilson",
    "Wolcott",
    "Woodbury",
    "Woodhaven",
    "Woodlawn",
    "Woodmere",
    "Woodrow",
    "Woodside",
    "Woodstock",
    "Worcester",
    "Wurtsboro",
    "Wyandanch",
    "Wykagyl",
    "Wynantskill",
    "Wyoming County",
    "Yaphank",
    "Yates County",
    "Yonkers",
    "Yorkshire",
    "Yorktown Heights",
    "Yorkville",
    "Youngstown",
    "Zena"
  ],
  "North Carolina": [
    "Aberdeen",
    "Advance",
    "Ahoskie",
    "Alamance County",
    "Albemarle",
    "Alexander County",
    "Alleghany County",
    "Andrews",
    "Angier",
    "Anson County",
    "Apex",
    "Archdale",
    "Archer Lodge",
    "Ashe County",
    "Asheboro",
    "Asheville",
    "Atlantic Beach",
    "Avery County",
    "Avery Creek",
    "Ayden",
    "Badin",
    "Bakersville",
    "Balfour",
    "Banner Elk",
    "Barker Heights",
    "Bayboro",
    "Bayshore",
    "Beaufort",
    "Beaufort County",
    "Belhaven",
    "Belmont",
    "Belville",
    "Benson",
    "Bent Creek",
    "Bermuda Run",
    "Bertie County",
    "Bessemer City",
    "Bethel",
    "Bethlehem",
    "Beulaville",
    "Biltmore Forest",
    "Biscoe",
    "Black Mountain",
    "Bladen County",
    "Bladenboro",
    "Blowing Rock",
    "Boiling Spring Lakes",
    "Boiling Springs",
    "Bolivia",
    "Boone",
    "Boonville",
    "Brevard",
    "Brices Creek",
    "Broad Creek",
    "Broadway",
    "Brogden",
    "Brunswick",
    "Brunswick County",
    "Bryson City",
    "Buies Creek",
    "Buncombe County",
    "Burgaw",
    "Burke County",
    "Burlington",
    "Burnsville",
    "Butner",
    "Buxton",
    "Cabarrus County",
    "Cajahs Mountain",
    "Calabash",
    "Caldwell County",
    "Camden",
    "Camden County",
    "Canton",
    "Cape Carteret",
    "Carolina Beach",
    "Carolina Shores",
    "Carrboro",
    "Carteret County",
    "Carthage",
    "Cary",
    "Castle Hayne",
    "Caswell County",
    "Catawba County",
    "Cedar Point",
    "Chadbourn",
    "Chapel Hill",
    "Charlotte",
    "Chatham County",
    "Cherokee",
    "Cherokee County",
    "Cherryville",
    "China Grove",
    "Chowan County",
    "Claremont",
    "Clay County",
    "Clayton",
    "Clemmons",
    "Cleveland County",
    "Clinton",
    "Clyde",
    "Coats",
    "Columbia",
    "Columbus",
    "Columbus County",
    "Concord",
    "Connelly Springs",
    "Conover",
    "Cordova",
    "Cornelius",
    "Cove Creek",
    "Cramerton",
    "Craven County",
    "Creedmoor",
    "Cricket",
    "Cullowhee",
    "Cumberland County",
    "Currituck",
    "Currituck County",
    "Dallas",
    "Dana",
    "Danbury",
    "Dare County",
    "Davidson",
    "Davidson County",
    "Davie County",
    "Denton",
    "Denver",
    "Dobson",
    "Drexel",
    "Dunn",
    "Duplin County",
    "Durham",
    "Durham County",
    "East Flat Rock",
    "East Rockingham",
    "East Spencer",
    "Eastover",
    "Eden",
    "Edenton",
    "Edgecombe County",
    "Edneyville",
    "Elizabeth City",
    "Elizabethtown",
    "Elkin",
    "Ellerbe",
    "Elm City",
    "Elon",
    "Elroy",
    "Emerald Isle",
    "Enfield",
    "Enochville",
    "Erwin",
    "Etowah",
    "Fairfield Harbour",
    "Fairmont",
    "Fairplains",
    "Fairview",
    "Farmville",
    "Fayetteville",
    "Fearrington Village",
    "Flat Rock",
    "Fletcher",
    "Forest City",
    "Forest Oaks",
    "Forsyth County",
    "Fort Bragg",
    "Foscoe",
    "Four Oaks",
    "Franklin",
    "Franklin County",
    "Franklinton",
    "Franklinville",
    "Fremont",
    "Fruitland",
    "Fuquay-Varina",
    "Gamewell",
    "Garner",
    "Gaston",
    "Gaston County",
    "Gastonia",
    "Gates County",
    "Gatesville",
    "Gibsonville",
    "Glen Alpine",
    "Glen Raven",
    "Goldsboro",
    "Gorman",
    "Graham",
    "Graham County",
    "Granite Falls",
    "Granite Quarry",
    "Granville County",
    "Green Level",
    "Greene County",
    "Greensboro",
    "Greenville",
    "Grifton",
    "Guilford County",
    "Half Moon",
    "Halifax",
    "Halifax County",
    "Hamlet",
    "Hampstead",
    "Harkers Island",
    "Harnett County",
    "Harrisburg",
    "Havelock",
    "Haw River",
    "Hayesville",
    "Hays",
    "Haywood County",
    "Hazelwood",
    "Hemby Bridge",
    "Henderson",
    "Henderson County",
    "Hendersonville",
    "Hertford",
    "Hertford County",
    "Hickory",
    "High Point",
    "Hildebran",
    "Hillsborough",
    "Hoke County",
    "Holly Ridge",
    "Holly Springs",
    "Hoopers Creek",
    "Hope Mills",
    "Horse Shoe",
    "Hudson",
    "Huntersville",
    "Hyde County",
    "Icard",
    "Indian Trail",
    "Iredell County",
    "Jackson",
    "Jackson County",
    "Jacksonville",
    "James City",
    "Jamestown",
    "Jefferson",
    "Johnston County",
    "Jones County",
    "Jonesville",
    "Kannapolis",
    "Kenansville",
    "Kenly",
    "Kernersville",
    "Kill Devil Hills",
    "King",
    "Kings Grant",
    "Kings Mountain",
    "Kinston",
    "Kitty Hawk",
    "Knightdale",
    "Kure Beach",
    "La Grange",
    "Lake Junaluska",
    "Lake Lure",
    "Lake Norman of Catawba",
    "Lake Park",
    "Lake Waccamaw",
    "Landis",
    "Laurel Hill",
    "Laurel Park",
    "Laurinburg",
    "Lee County",
    "Leland",
    "Lenoir",
    "Lenoir County",
    "Lewisville",
    "Lexington",
    "Liberty",
    "Lillington",
    "Lincoln County",
    "Lincolnton",
    "Locust",
    "Long Beach",
    "Longview",
    "Louisburg",
    "Lowell",
    "Lowesville",
    "Lucama",
    "Lumberton",
    "Macon County",
    "Madison",
    "Madison County",
    "Maggie Valley",
    "Maiden",
    "Manteo",
    "Mar-Mac",
    "Marion",
    "Mars Hill",
    "Marshall",
    "Marshville",
    "Martin County",
    "Marvin",
    "Masonboro",
    "Matthews",
    "Maury",
    "Maxton",
    "Mayodan",
    "Maysville",
    "McDowell County",
    "McLeansville",
    "Mebane",
    "Mecklenburg County",
    "Midland",
    "Midway",
    "Millers Creek",
    "Mills River",
    "Mineral Springs",
    "Mint Hill",
    "Mitchell County",
    "Mocksville",
    "Monroe",
    "Montgomery County",
    "Moore County",
    "Mooresville",
    "Moravian Falls",
    "Morehead City",
    "Morganton",
    "Morrisville",
    "Mount Airy",
    "Mount Gilead",
    "Mount Holly",
    "Mount Olive",
    "Mount Pleasant",
    "Mountain Home",
    "Mountain View",
    "Moyock",
    "Mulberry",
    "Murfreesboro",
    "Murphy",
    "Murraysville",
    "Myrtle Grove",
    "Nags Head",
    "Nash County",
    "Nashville",
    "Navassa",
    "Neuse Forest",
    "New Bern",
    "New Hanover County",
    "Newland",
    "Newport",
    "Newton",
    "Norlina",
    "North Wilkesboro",
    "Northampton County",
    "Northchase",
    "Northlakes",
    "Norwood",
    "Oak Island",
    "Oak Ridge",
    "Oakboro",
    "Ogden",
    "Onslow County",
    "Orange County",
    "Oxford",
    "Pamlico County",
    "Pasquotank County",
    "Pembroke",
    "Pender County",
    "Perquimans County",
    "Person County",
    "Pilot Mountain",
    "Pine Knoll Shores",
    "Pine Level",
    "Pinebluff",
    "Pinehurst",
    "Pinetops",
    "Pineville",
    "Piney Green",
    "Pitt County",
    "Pittsboro",
    "Plain View",
    "Pleasant Garden",
    "Pleasant Hill",
    "Plymouth",
    "Polk County",
    "Polkton",
    "Princeton",
    "Princeville",
    "Pumpkin Center",
    "Raeford",
    "Raleigh",
    "Ramseur",
    "Randleman",
    "Randolph County",
    "Ranlo",
    "Red Oak",
    "Red Springs",
    "Reidsville",
    "Rhodhiss",
    "Richlands",
    "Richmond County",
    "River Bend",
    "River Road",
    "Roanoke Rapids",
    "Robbins",
    "Robbinsville",
    "Robersonville",
    "Robeson County",
    "Rockfish",
    "Rockingham",
    "Rockingham County",
    "Rockwell",
    "Rocky Mount",
    "Rocky Point",
    "Rolesville",
    "Rose Hill",
    "Roseboro",
    "Rowan County",
    "Rowland",
    "Roxboro",
    "Royal Pines",
    "Rural Hall",
    "Rutherford College",
    "Rutherford County",
    "Rutherfordton",
    "Saint James",
    "Saint Pauls",
    "Saint Stephens",
    "Salem",
    "Salisbury",
    "Sampson County",
    "Sanford",
    "Sawmills",
    "Saxapahaw",
    "Scotland County",
    "Scotland Neck",
    "Scotts Mill",
    "Sea Breeze",
    "Seagate",
    "Selma",
    "Seven Lakes",
    "Shallotte",
    "Sharpsburg",
    "Shelby",
    "Sherrills Ford",
    "Siler City",
    "Silver Lake",
    "Skippers Corner",
    "Smithfield",
    "Sneads Ferry",
    "Snow Hill",
    "South Gastonia",
    "South Henderson",
    "South Rosemary",
    "Southern Pines",
    "Southern Shores",
    "Southmont",
    "Southport",
    "Sparta",
    "Spencer",
    "Spindale",
    "Spring Hope",
    "Spring Lake",
    "Spruce Pine",
    "Stallings",
    "Stanfield",
    "Stanley",
    "Stanly County",
    "Statesville",
    "Stedman",
    "Stokes County",
    "Stokesdale",
    "Stoneville",
    "Stony Point",
    "Summerfield",
    "Sunset Beach",
    "Surf City",
    "Surry County",
    "Swain County",
    "Swannanoa",
    "Swanquarter",
    "Swansboro",
    "Swepsonville",
    "Sylva",
    "Tabor City",
    "Tarboro",
    "Taylorsville",
    "Thomasville",
    "Thurmond",
    "Toast",
    "Tobaccoville",
    "Transylvania County",
    "Trent Woods",
    "Trenton",
    "Trinity",
    "Troutman",
    "Troy",
    "Tryon",
    "Tyro",
    "Tyrrell County",
    "Union County",
    "Unionville",
    "Valdese",
    "Valley Hill",
    "Vance County",
    "Vander",
    "Wadesboro",
    "Wake County",
    "Wake Forest",
    "Walkertown",
    "Wallace",
    "Wallburg",
    "Walnut Cove",
    "Wanchese",
    "Warren County",
    "Warrenton",
    "Warsaw",
    "Washington",
    "Washington County",
    "Watauga County",
    "Waxhaw",
    "Wayne County",
    "Waynesville",
    "Weaverville",
    "Weddington",
    "Welcome",
    "Weldon",
    "Wendell",
    "Wentworth",
    "Wesley Chapel",
    "West Canton",
    "West Jefferson",
    "West Marion",
    "West Raleigh",
    "Westport",
    "Whispering Pines",
    "White Plains",
    "Whiteville",
    "Wilkes County",
    "Wilkesboro",
    "Williamston",
    "Wilmington",
    "Wilson",
    "Wilson County",
    "Wilsons Mills",
    "Windsor",
    "Wingate",
    "Winston-Salem",
    "Winterville",
    "Winton",
    "Woodfin",
    "Wrightsboro",
    "Wrightsville Beach",
    "Yadkin County",
    "Yadkinville",
    "Yancey County",
    "Yanceyville",
    "Youngsville",
    "Zebulon"
  ],
  "North Dakota": [
    "Adams County",
    "Amidon",
    "Ashley",
    "Barnes County",
    "Beach",
    "Belcourt",
    "Belfield",
    "Benson County",
    "Beulah",
    "Billings County",
    "Bismarck",
    "Bottineau",
    "Bottineau County",
    "Bowbells",
    "Bowman",
    "Bowman County",
    "Burke County",
    "Burleigh County",
    "Burlington",
    "Cando",
    "Carrington",
    "Carson",
    "Cass County",
    "Casselton",
    "Cavalier",
    "Cavalier County",
    "Center",
    "Cooperstown",
    "Crosby",
    "Devils Lake",
    "Dickey County",
    "Dickinson",
    "Divide County",
    "Dunn County",
    "Eddy County",
    "Ellendale",
    "Emmons County",
    "Fargo",
    "Fessenden",
    "Finley",
    "Forman",
    "Fort Totten",
    "Fort Yates",
    "Foster County",
    "Garrison",
    "Golden Valley County",
    "Grafton",
    "Grand Forks",
    "Grand Forks Air Force Base",
    "Grand Forks County",
    "Grant County",
    "Griggs County",
    "Harvey",
    "Hazen",
    "Hettinger",
    "Hettinger County",
    "Hillsboro",
    "Horace",
    "Jamestown",
    "Kenmare",
    "Kidder County",
    "Killdeer",
    "LaMoure County",
    "Lakota",
    "Langdon",
    "Larimore",
    "Lincoln",
    "Linton",
    "Lisbon",
    "Logan County",
    "Mandan",
    "Manning",
    "Mayville",
    "McClusky",
    "McHenry County",
    "McIntosh County",
    "McKenzie County",
    "McLean County",
    "Medora",
    "Mercer County",
    "Minnewaukan",
    "Minot",
    "Minot Air Force Base",
    "Mohall",
    "Morton County",
    "Mott",
    "Mountrail County",
    "Napoleon",
    "Nelson County",
    "New Rockford",
    "New Town",
    "Oakes",
    "Oliver County",
    "Park River",
    "Parshall",
    "Pembina County",
    "Pierce County",
    "Ramsey County",
    "Ransom County",
    "Renville County",
    "Richland County",
    "Rolette County",
    "Rolla",
    "Rugby",
    "Sargent County",
    "Sheldon",
    "Shell Valley",
    "Sheridan County",
    "Sioux County",
    "Slope County",
    "Stanley",
    "Stanton",
    "Stark County",
    "Steele",
    "Steele County",
    "Stutsman County",
    "Surrey",
    "Thompson",
    "Tioga",
    "Towner",
    "Towner County",
    "Traill County",
    "Valley City",
    "Velva",
    "Wahpeton",
    "Walsh County",
    "Ward County",
    "Washburn",
    "Watford City",
    "Wells County",
    "West Fargo",
    "Williams County",
    "Williston"
  ],
  "Ohio": [
    "Ada",
    "Adams County",
    "Akron",
    "Allen County",
    "Alliance",
    "Amberley",
    "Amelia",
    "Amherst",
    "Andover",
    "Anna",
    "Ansonia",
    "Antwerp",
    "Apple Creek",
    "Apple Valley",
    "Arcanum",
    "Archbold",
    "Arlington",
    "Ashland",
    "Ashland County",
    "Ashley",
    "Ashtabula",
    "Ashtabula County",
    "Ashville",
    "Athens",
    "Athens County",
    "Auglaize County",
    "Aurora",
    "Austintown",
    "Avon",
    "Avon Center",
    "Avon Lake",
    "Bainbridge",
    "Ballville",
    "Baltimore",
    "Barberton",
    "Barnesville",
    "Batavia",
    "Bay Village",
    "Beach City",
    "Beachwood",
    "Beavercreek",
    "Beckett Ridge",
    "Bedford",
    "Bedford Heights",
    "Beechwood Trails",
    "Bellaire",
    "Bellbrook",
    "Bellefontaine",
    "Bellevue",
    "Bellville",
    "Belmont County",
    "Belpre",
    "Berea",
    "Bethel",
    "Bethesda",
    "Beverly",
    "Bexley",
    "Blacklick Estates",
    "Blanchester",
    "Blue Ash",
    "Bluffton",
    "Boardman",
    "Bolindale",
    "Boston Heights",
    "Botkins",
    "Bowling Green",
    "Bradford",
    "Bradner",
    "Bratenahl",
    "Brecksville",
    "Bremen",
    "Brewster",
    "Bridgeport",
    "Bridgetown",
    "Brilliant",
    "Brimfield",
    "Broadview Heights",
    "Brook Park",
    "Brookfield Center",
    "Brooklyn",
    "Brooklyn Heights",
    "Brookville",
    "Brown County",
    "Brunswick",
    "Bryan",
    "Buckeye Lake",
    "Bucyrus",
    "Burlington",
    "Burton",
    "Butler County",
    "Byesville",
    "Cadiz",
    "Calcutta",
    "Caldwell",
    "Cambridge",
    "Camden",
    "Campbell",
    "Canal Fulton",
    "Canal Winchester",
    "Canfield",
    "Canton",
    "Cardington",
    "Carey",
    "Carlisle",
    "Carroll County",
    "Carrollton",
    "Cedarville",
    "Celina",
    "Centerburg",
    "Centerville",
    "Chagrin Falls",
    "Champaign County",
    "Champion Heights",
    "Chardon",
    "Chauncey",
    "Cherry Grove",
    "Chesterland",
    "Cheviot",
    "Chillicothe",
    "Choctaw Lake",
    "Churchill",
    "Cincinnati",
    "Circleville",
    "Clark County",
    "Clark-Fulton",
    "Clayton",
    "Clermont County",
    "Cleveland",
    "Cleveland Heights",
    "Cleves",
    "Clinton",
    "Clinton County",
    "Clyde",
    "Coal Grove",
    "Coldwater",
    "Collinwood",
    "Columbiana",
    "Columbiana County",
    "Columbus",
    "Columbus Grove",
    "Commercial Point",
    "Conneaut",
    "Continental",
    "Convoy",
    "Copley",
    "Cortland",
    "Coshocton",
    "Coshocton County",
    "Covedale",
    "Covington",
    "Craig Beach",
    "Crawford County",
    "Crestline",
    "Creston",
    "Cridersville",
    "Crooksville",
    "Crystal Lakes",
    "Curtice",
    "Cuyahoga County",
    "Cuyahoga Falls",
    "Dalton",
    "Danville",
    "Darke County",
    "Day Heights",
    "Dayton",
    "De Graff",
    "Deer Park",
    "Defiance",
    "Defiance County",
    "Delaware",
    "Delaware County",
    "Delhi Hills",
    "Delphos",
    "Delta",
    "Dennison",
    "Dent",
    "Deshler",
    "Detroit-Shoreway",
    "Devola",
    "Dillonvale",
    "Dover",
    "Doylestown",
    "Dresden",
    "Drexel",
    "Dry Ridge",
    "Dry Run",
    "Dublin",
    "Dunlap",
    "East Canton",
    "East Cleveland",
    "East Liverpool",
    "East Palestine",
    "Eastlake",
    "Eaton",
    "Eaton Estates",
    "Edgerton",
    "Edgewood",
    "Elida",
    "Elmore",
    "Elmwood Place",
    "Elyria",
    "Englewood",
    "Enon",
    "Erie County",
    "Etna",
    "Euclid",
    "Evendale",
    "Fairborn",
    "Fairfax",
    "Fairfield",
    "Fairfield Beach",
    "Fairfield County",
    "Fairlawn",
    "Fairport Harbor",
    "Fairview Park",
    "Farmersville",
    "Fayette",
    "Fayette County",
    "Findlay",
    "Finneytown",
    "Five Points",
    "Forest",
    "Forest Park",
    "Forestville",
    "Fort Loramie",
    "Fort McKinley",
    "Fort Recovery",
    "Fort Shawnee",
    "Fostoria",
    "Frankfort",
    "Franklin",
    "Franklin County",
    "Franklin Furnace",
    "Frazeysburg",
    "Fredericktown",
    "Fremont",
    "Fruit Hill",
    "Fulton County",
    "Gahanna",
    "Galion",
    "Gallia County",
    "Gallipolis",
    "Gambier",
    "Garfield Heights",
    "Garrettsville",
    "Gates Mills",
    "Geauga County",
    "Geneva",
    "Geneva-on-the-Lake",
    "Genoa",
    "Georgetown",
    "Germantown",
    "Gibsonburg",
    "Girard",
    "Glandorf",
    "Glendale",
    "Glenmoor",
    "Glenville",
    "Glouster",
    "Gnadenhutten",
    "Golf Manor",
    "Goshen",
    "Grafton",
    "Grandview",
    "Grandview Heights",
    "Granville",
    "Granville South",
    "Green",
    "Green Meadows",
    "Green Springs",
    "Greene County",
    "Greenfield",
    "Greenhills",
    "Greensburg",
    "Greentown",
    "Greenville",
    "Greenwich",
    "Groesbeck",
    "Grove City",
    "Groveport",
    "Guernsey County",
    "Hamilton",
    "Hamilton County",
    "Hancock County",
    "Hanover",
    "Harbor Hills",
    "Hardin County",
    "Harrison",
    "Harrison County",
    "Hartville",
    "Haskins",
    "Heath",
    "Hebron",
    "Henry County",
    "Hicksville",
    "Highland County",
    "Highland Heights",
    "Highpoint",
    "Hilliard",
    "Hillsboro",
    "Hiram",
    "Hocking County",
    "Holgate",
    "Holiday Valley",
    "Holland",
    "Holmes County",
    "Hough",
    "Howland Center",
    "Hubbard",
    "Huber Heights",
    "Huber Ridge",
    "Hudson",
    "Hunter",
    "Huron",
    "Huron County",
    "Independence",
    "Ironton",
    "Jackson",
    "Jackson Center",
    "Jackson County",
    "Jamestown",
    "Jefferson",
    "Jefferson County",
    "Jeffersonville",
    "Johnstown",
    "Kalida",
    "Kent",
    "Kenton",
    "Kenwood",
    "Kettering",
    "Kings Mills",
    "Kingston",
    "Kirtland",
    "Knox County",
    "La Croft",
    "Lagrange",
    "Lake County",
    "Lake Darby",
    "Lake Lakengren",
    "Lake Mohawk",
    "Lakemore",
    "Lakeview",
    "Lakewood",
    "Lancaster",
    "Landen",
    "Lawrence County",
    "Leavittsburg",
    "Lebanon",
    "Leesburg",
    "Leetonia",
    "Leipsic",
    "Lewis Center",
    "Lewisburg",
    "Lexington",
    "Liberty Center",
    "Licking County",
    "Lima",
    "Lincoln Heights",
    "Lincoln Village",
    "Lisbon",
    "Lithopolis",
    "Lockland",
    "Lodi",
    "Logan",
    "Logan County",
    "Logan Elm Village",
    "London",
    "Lorain",
    "Lorain County",
    "Lordstown",
    "Loudonville",
    "Louisville",
    "Loveland",
    "Loveland Park",
    "Lowellville",
    "Lucas County",
    "Lucasville",
    "Luckey",
    "Lynchburg",
    "Lyndhurst",
    "Macedonia",
    "Mack",
    "Madeira",
    "Madison",
    "Madison County",
    "Mahoning County",
    "Malvern",
    "Manchester",
    "Mansfield",
    "Mantua",
    "Maple Heights",
    "Mariemont",
    "Marietta",
    "Marion",
    "Marion County",
    "Martins Ferry",
    "Marysville",
    "Mason",
    "Massillon",
    "Masury",
    "Maumee",
    "Mayfield",
    "Mayfield Heights",
    "McArthur",
    "McComb",
    "McConnelsville",
    "McDonald",
    "McKinley Heights",
    "Mechanicsburg",
    "Medina",
    "Medina County",
    "Meigs County",
    "Mentor",
    "Mentor-on-the-Lake",
    "Mercer County",
    "Miami County",
    "Miami Heights",
    "Miamisburg",
    "Miamitown",
    "Middleburg Heights",
    "Middlefield",
    "Middleport",
    "Middletown",
    "Milan",
    "Milford",
    "Millbury",
    "Millersburg",
    "Millersport",
    "Mineral Ridge",
    "Minerva",
    "Minerva Park",
    "Mingo Junction",
    "Minster",
    "Mogadore",
    "Monfort Heights",
    "Monroe",
    "Monroe County",
    "Monroeville",
    "Montgomery",
    "Montgomery County",
    "Montpelier",
    "Montrose-Ghent",
    "Moraine",
    "Moreland Hills",
    "Morgan County",
    "Morgandale",
    "Morrow",
    "Morrow County",
    "Mount Carmel",
    "Mount Gilead",
    "Mount Healthy",
    "Mount Healthy Heights",
    "Mount Orab",
    "Mount Repose",
    "Mount Sterling",
    "Mount Vernon",
    "Mulberry",
    "Munroe Falls",
    "Muskingum County",
    "Napoleon",
    "Navarre",
    "Nelsonville",
    "New Albany",
    "New Boston",
    "New Bremen",
    "New Burlington",
    "New California",
    "New Carlisle",
    "New Concord",
    "New Franklin",
    "New Lebanon",
    "New Lexington",
    "New London",
    "New Matamoras",
    "New Miami",
    "New Middletown",
    "New Paris",
    "New Philadelphia",
    "New Richmond",
    "New Vienna",
    "New Waterford",
    "Newark",
    "Newburgh Heights",
    "Newcomerstown",
    "Newport",
    "Newton Falls",
    "Newtown",
    "Niles",
    "Noble County",
    "North Baltimore",
    "North Canton",
    "North College Hill",
    "North Fork Village",
    "North Kingsville",
    "North Lewisburg",
    "North Madison",
    "North Olmsted",
    "North Randall",
    "North Ridgeville",
    "North Royalton",
    "North Zanesville",
    "Northbrook",
    "Northfield",
    "Northgate",
    "Northridge",
    "Northwood",
    "Norton",
    "Norwalk",
    "Norwood",
    "Oak Harbor",
    "Oak Hill",
    "Oakwood",
    "Oberlin",
    "Obetz",
    "Olmsted Falls",
    "Ontario",
    "Orange",
    "Oregon",
    "Orrville",
    "Orwell",
    "Ottawa",
    "Ottawa County",
    "Ottawa Hills",
    "Oxford",
    "Painesville",
    "Pandora",
    "Park Layne",
    "Parma",
    "Parma Heights",
    "Pataskala",
    "Paulding",
    "Paulding County",
    "Payne",
    "Peebles",
    "Pemberville",
    "Pepper Pike",
    "Perry",
    "Perry County",
    "Perry Heights",
    "Perrysburg",
    "Pickaway County",
    "Pickerington",
    "Pike County",
    "Piketon",
    "Pioneer",
    "Piqua",
    "Plain City",
    "Pleasant Grove",
    "Pleasant Hill",
    "Pleasant Run",
    "Pleasant Run Farm",
    "Plymouth",
    "Poland",
    "Pomeroy",
    "Port Clinton",
    "Portage County",
    "Portage Lakes",
    "Portsmouth",
    "Powell",
    "Powhatan Point",
    "Preble County",
    "Prospect",
    "Putnam County",
    "Ravenna",
    "Reading",
    "Reminderville",
    "Reno",
    "Reynoldsburg",
    "Richfield",
    "Richland County",
    "Richmond Heights",
    "Richville",
    "Richwood",
    "Ripley",
    "Rittman",
    "Riverside",
    "Roaming Shores",
    "Rockford",
    "Rocky River",
    "Rosemount",
    "Roseville",
    "Ross",
    "Ross County",
    "Rossford",
    "Rossmoyne",
    "Russells Point",
    "Sabina",
    "Saint Bernard",
    "Saint Clairsville",
    "Saint Henry",
    "Saint Marys",
    "Saint Paris",
    "Salem",
    "Salem Heights",
    "Salineville",
    "Sandusky",
    "Sandusky County",
    "Sawyerwood",
    "Scioto County",
    "Sciotodale",
    "Sebring",
    "Seneca County",
    "Seven Hills",
    "Seville",
    "Shadyside",
    "Shaker Heights",
    "Sharonville",
    "Shawnee Hills",
    "Sheffield",
    "Sheffield Lake",
    "Shelby",
    "Shelby County",
    "Sherwood",
    "Shiloh",
    "Shreve",
    "Sidney",
    "Silver Lake",
    "Silverton",
    "Sixteen Mile Stand",
    "Skyline Acres",
    "Smithville",
    "Solon",
    "Somerset",
    "South Amherst",
    "South Bloomfield",
    "South Canal",
    "South Charleston",
    "South Euclid",
    "South Lebanon",
    "South Point",
    "South Russell",
    "South Zanesville",
    "Spencerville",
    "Springboro",
    "Springdale",
    "Springfield",
    "Stark County",
    "Steubenville",
    "Stony Prairie",
    "Stow",
    "Strasburg",
    "Streetsboro",
    "Strongsville",
    "Struthers",
    "Stryker",
    "Sugarcreek",
    "Sugarcreek Police Dept",
    "Summerside",
    "Summit County",
    "Sunbury",
    "Swanton",
    "Sylvania",
    "Tallmadge",
    "Terrace Park",
    "The Plains",
    "The Village of Indian Hill",
    "Thornport",
    "Tiffin",
    "Tiltonsville",
    "Tipp City",
    "Toledo",
    "Toronto",
    "Trenton",
    "Trotwood",
    "Troy",
    "Trumbull County",
    "Turpin Hills",
    "Tuscarawas",
    "Tuscarawas County",
    "Twinsburg",
    "Uhrichsville",
    "Union",
    "Union City",
    "Union County",
    "Uniontown",
    "University Heights",
    "Upper Arlington",
    "Upper Sandusky",
    "Urbana",
    "Urbancrest",
    "Utica",
    "Valley View",
    "Van Wert",
    "Van Wert County",
    "Vandalia",
    "Vermilion",
    "Vermilion-on-the-Lake",
    "Versailles",
    "Vinton County",
    "Wadsworth",
    "Wakeman",
    "Walbridge",
    "Walton Hills",
    "Wapakoneta",
    "Warren",
    "Warren County",
    "Warrensville Heights",
    "Washington County",
    "Washington Court House",
    "Waterville",
    "Wauseon",
    "Waverly",
    "Wayne County",
    "Waynesville",
    "Wellington",
    "Wellston",
    "Wellsville",
    "West Alexandria",
    "West Carrollton City",
    "West Hill",
    "West Jefferson",
    "West Lafayette",
    "West Liberty",
    "West Milton",
    "West Portsmouth",
    "West Salem",
    "West Union",
    "West Unity",
    "Westerville",
    "Westfield Center",
    "Westlake",
    "Weston",
    "Wetherington",
    "Wheelersburg",
    "White Oak",
    "Whitehall",
    "Whitehouse",
    "Wickliffe",
    "Wilberforce",
    "Willard",
    "Williams County",
    "Williamsburg",
    "Williamsport",
    "Willoughby",
    "Willoughby Hills",
    "Willowick",
    "Wilmington",
    "Winchester",
    "Windham",
    "Wintersville",
    "Withamsville",
    "Wolfhurst",
    "Wood County",
    "Woodlawn",
    "Woodsfield",
    "Woodville",
    "Wooster",
    "Worthington",
    "Wright-Patterson AFB",
    "Wyandot County",
    "Wyoming",
    "Xenia",
    "Yellow Springs",
    "Yorkville",
    "Youngstown",
    "Zanesville"
  ],
  "Oklahoma": [
    "Ada",
    "Adair County",
    "Afton",
    "Alfalfa County",
    "Altus",
    "Alva",
    "Anadarko",
    "Antlers",
    "Apache",
    "Arapaho",
    "Ardmore",
    "Arkoma",
    "Arnett",
    "Atoka",
    "Atoka County",
    "Barnsdall",
    "Bartlesville",
    "Beaver",
    "Beaver County",
    "Beckham County",
    "Beggs",
    "Bethany",
    "Bethel Acres",
    "Bixby",
    "Blackwell",
    "Blaine County",
    "Blanchard",
    "Boise City",
    "Boley",
    "Bray",
    "Bristow",
    "Broken Arrow",
    "Broken Bow",
    "Bryan County",
    "Buffalo",
    "Burns Flat",
    "Bushyhead",
    "Byng",
    "Cache",
    "Caddo",
    "Caddo County",
    "Calera",
    "Canadian County",
    "Carnegie",
    "Carter County",
    "Catoosa",
    "Central High",
    "Chandler",
    "Checotah",
    "Chelsea",
    "Cherokee",
    "Cherokee County",
    "Cheyenne",
    "Chickasha",
    "Choctaw",
    "Choctaw County",
    "Chouteau",
    "Cimarron County",
    "Claremore",
    "Cleora",
    "Cleveland",
    "Cleveland County",
    "Clinton",
    "Coal County",
    "Coalgate",
    "Colbert",
    "Collinsville",
    "Comanche",
    "Comanche County",
    "Commerce",
    "Copeland",
    "Cordell",
    "Cotton County",
    "Coweta",
    "Craig County",
    "Creek County",
    "Crescent",
    "Cushing",
    "Custer County",
    "Cyril",
    "Davis",
    "Del City",
    "Delaware County",
    "Dewey",
    "Dewey County",
    "Dickson",
    "Drumright",
    "Duncan",
    "Durant",
    "Edmond",
    "El Reno",
    "Elgin",
    "Elk City",
    "Ellis County",
    "Enid",
    "Erick",
    "Eufaula",
    "Fairfax",
    "Fairland",
    "Fairview",
    "Fletcher",
    "Forest Park",
    "Fort Gibson",
    "Frederick",
    "Garfield County",
    "Garvin County",
    "Geary",
    "Geronimo",
    "Glenpool",
    "Goldsby",
    "Goodwell",
    "Grady County",
    "Granite",
    "Grant County",
    "Greer County",
    "Grove",
    "Guthrie",
    "Guymon",
    "Hall Park",
    "Harmon County",
    "Harper County",
    "Harrah",
    "Hartshorne",
    "Haskell",
    "Haskell County",
    "Healdton",
    "Heavener",
    "Helena",
    "Hennessey",
    "Henryetta",
    "Hinton",
    "Hobart",
    "Holdenville",
    "Hollis",
    "Hominy",
    "Hooker",
    "Hughes County",
    "Hugo",
    "Idabel",
    "Inola",
    "Jackson County",
    "Jay",
    "Jefferson County",
    "Jenks",
    "Johnston County",
    "Jones",
    "Justice",
    "Kay County",
    "Kellyville",
    "Kenwood",
    "Kiefer",
    "Kingfisher",
    "Kingfisher County",
    "Kingston",
    "Kiowa County",
    "Konawa",
    "Krebs",
    "Langston",
    "Latimer County",
    "Laverne",
    "Lawton",
    "Le Flore County",
    "Lexington",
    "Lincoln County",
    "Lindsay",
    "Locust Grove",
    "Logan County",
    "Lone Grove",
    "Longtown",
    "Love County",
    "Luther",
    "Madill",
    "Major County",
    "Mangum",
    "Mannford",
    "Marietta",
    "Marlow",
    "Marshall County",
    "Maud",
    "Mayes County",
    "Maysville",
    "McAlester",
    "McClain County",
    "McCord",
    "McCurtain County",
    "McIntosh County",
    "McLoud",
    "Medford",
    "Meeker",
    "Meridian",
    "Miami",
    "Midwest City",
    "Minco",
    "Moore",
    "Mooreland",
    "Morris",
    "Mounds",
    "Muldrow",
    "Murray County",
    "Muskogee",
    "Muskogee County",
    "Mustang",
    "Newcastle",
    "Newkirk",
    "Nichols Hills",
    "Nicoma Park",
    "Ninnekah",
    "Noble",
    "Noble County",
    "Norman",
    "Nowata",
    "Nowata County",
    "Oakhurst",
    "Oakland",
    "Oilton",
    "Okarche",
    "Okeene",
    "Okemah",
    "Okfuskee County",
    "Oklahoma City",
    "Oklahoma County",
    "Okmulgee",
    "Okmulgee County",
    "Oologah",
    "Osage County",
    "Ottawa County",
    "Owasso",
    "Panama",
    "Park Hill",
    "Pauls Valley",
    "Pawhuska",
    "Pawnee",
    "Pawnee County",
    "Payne County",
    "Perkins",
    "Perry",
    "Piedmont",
    "Pink",
    "Pittsburg County",
    "Pocola",
    "Ponca City",
    "Pontotoc County",
    "Poteau",
    "Pottawatomie County",
    "Prague",
    "Pryor",
    "Pryor Creek",
    "Purcell",
    "Pushmataha County",
    "Quinton",
    "Ringling",
    "Roger Mills County",
    "Rogers County",
    "Roland",
    "Rush Springs",
    "Salina",
    "Sallisaw",
    "Sand Springs",
    "Sapulpa",
    "Sayre",
    "Seminole",
    "Seminole County",
    "Sequoyah County",
    "Shattuck",
    "Shawnee",
    "Skiatook",
    "Slaughterville",
    "Snyder",
    "Spencer",
    "Sperry",
    "Spiro",
    "Stephens County",
    "Stigler",
    "Stillwater",
    "Stilwell",
    "Stratford",
    "Stroud",
    "Sulphur",
    "Tahlequah",
    "Talihina",
    "Taloga",
    "Tecumseh",
    "Texanna",
    "Texas County",
    "The Village",
    "Thomas",
    "Tillman County",
    "Tishomingo",
    "Tonkawa",
    "Tulsa",
    "Tulsa County",
    "Turley",
    "Tuttle",
    "Union City",
    "Verdigris",
    "Vian",
    "Vinita",
    "Wagoner",
    "Wagoner County",
    "Walters",
    "Warner",
    "Warr Acres",
    "Washington County",
    "Washita County",
    "Watonga",
    "Waukomis",
    "Waurika",
    "Weatherford",
    "Westville",
    "Wetumka",
    "Wewoka",
    "Wilburton",
    "Wilson",
    "Wister",
    "Woods County",
    "Woodward",
    "Woodward County",
    "Wynnewood",
    "Yale",
    "Yukon"
  ],
  "Oregon": [
    "Albany",
    "Aloha",
    "Altamont",
    "Amity",
    "Ashland",
    "Astoria",
    "Athena",
    "Aumsville",
    "Baker City",
    "Baker County",
    "Bandon",
    "Banks",
    "Barview",
    "Bay City",
    "Beavercreek",
    "Beaverton",
    "Bend",
    "Benton County",
    "Bethany",
    "Boardman",
    "Brookings",
    "Brownsville",
    "Bunker Hill",
    "Burns",
    "Canby",
    "Cannon Beach",
    "Canyon City",
    "Canyonville",
    "Carlton",
    "Cascade Locks",
    "Cave Junction",
    "Cedar Hills",
    "Cedar Mill",
    "Central Point",
    "Chenoweth",
    "Clackamas",
    "Clackamas County",
    "Clatskanie",
    "Clatsop County",
    "Coburg",
    "Columbia City",
    "Columbia County",
    "Condon",
    "Coos Bay",
    "Coos County",
    "Coquille",
    "Cornelius",
    "Corvallis",
    "Cottage Grove",
    "Creswell",
    "Crook County",
    "Culp Creek",
    "Culver",
    "Curry County",
    "Dallas",
    "Damascus",
    "Dayton",
    "Depoe Bay",
    "Deschutes County",
    "Deschutes River Woods",
    "Donald",
    "Douglas County",
    "Drain",
    "Dundee",
    "Dunes City",
    "Durham",
    "Eagle Point",
    "Elgin",
    "Enterprise",
    "Estacada",
    "Eugene",
    "Fairview",
    "Florence",
    "Forest Grove",
    "Fossil",
    "Four Corners",
    "Fruitdale",
    "Garden Home-Whitford",
    "Gearhart",
    "Gervais",
    "Gilliam County",
    "Gladstone",
    "Glide",
    "Gold Beach",
    "Gold Hill",
    "Grand Ronde",
    "Grant County",
    "Grants Pass",
    "Green",
    "Gresham",
    "Happy Valley",
    "Harbor",
    "Harney County",
    "Harrisburg",
    "Hayesville",
    "Heppner",
    "Hermiston",
    "Hillsboro",
    "Hines",
    "Hood River",
    "Hood River County",
    "Hubbard",
    "Independence",
    "Irrigon",
    "Island City",
    "Jackson County",
    "Jacksonville",
    "Jefferson",
    "Jefferson County",
    "Jennings Lodge",
    "John Day",
    "Joseph",
    "Josephine County",
    "Junction City",
    "Keizer",
    "Kenton",
    "King City",
    "Klamath County",
    "Klamath Falls",
    "La Grande",
    "La Pine",
    "Lafayette",
    "Lake County",
    "Lake Oswego",
    "Lakeside",
    "Lakeview",
    "Lane County",
    "Lebanon",
    "Lents",
    "Lincoln Beach",
    "Lincoln City",
    "Lincoln County",
    "Linn County",
    "Lowell",
    "Lyons",
    "Madras",
    "Malheur County",
    "Marion County",
    "McMinnville",
    "Medford",
    "Merlin",
    "Metzger",
    "Mill City",
    "Millersburg",
    "Milton-Freewater",
    "Milwaukie",
    "Mission",
    "Molalla",
    "Monmouth",
    "Moro",
    "Morrow County",
    "Mount Angel",
    "Mount Hood Village",
    "Mulino",
    "Multnomah County",
    "Myrtle Creek",
    "Myrtle Point",
    "New Hope",
    "Newberg",
    "Newport",
    "North Bend",
    "North Plains",
    "North Portland",
    "Nyssa",
    "Oak Grove",
    "Oak Hills",
    "Oakridge",
    "Oatfield",
    "Odell",
    "Ontario",
    "Oregon City",
    "Pacific City",
    "Pendleton",
    "Philomath",
    "Phoenix",
    "Pilot Rock",
    "Polk County",
    "Port Orford",
    "Portland",
    "Prineville",
    "Rainier",
    "Raleigh Hills",
    "Redmond",
    "Redwood",
    "Reedsport",
    "Riddle",
    "Rockaway Beach",
    "Rockcreek",
    "Rogue River",
    "Rose Lodge",
    "Roseburg",
    "Roseburg North",
    "Saint Helens",
    "Salem",
    "Sandy",
    "Scappoose",
    "Seaside",
    "Shady Cove",
    "Sheridan",
    "Sherman County",
    "Sherwood",
    "Siletz",
    "Silverton",
    "Sisters",
    "South Lebanon",
    "Springfield",
    "Stafford",
    "Stanfield",
    "Stayton",
    "Sublimity",
    "Sunriver",
    "Sutherlin",
    "Sweet Home",
    "Talent",
    "Tangent",
    "Terrebonne",
    "The Dalles",
    "Three Rivers",
    "Tigard",
    "Tillamook",
    "Tillamook County",
    "Toledo",
    "Tri-City",
    "Troutdale",
    "Tualatin",
    "Turner",
    "Umatilla",
    "Umatilla County",
    "Union",
    "Union County",
    "Vale",
    "Veneta",
    "Vernonia",
    "Waldport",
    "Wallowa County",
    "Warm Springs",
    "Warren",
    "Warrenton",
    "Wasco County",
    "Washington County",
    "West Haven",
    "West Haven-Sylvan",
    "West Linn",
    "West Slope",
    "Wheeler County",
    "White City",
    "Willamina",
    "Williams",
    "Wilsonville",
    "Winston",
    "Wood Village",
    "Woodburn",
    "Yamhill",
    "Yamhill County",
    "Yoncalla"
  ],
  "Pennsylvania": [
    "Abbottstown",
    "Adams County",
    "Adamstown",
    "Akron",
    "Albion",
    "Alburtis",
    "Aldan",
    "Aliquippa",
    "Allegheny County",
    "Alleghenyville",
    "Allentown",
    "Allison Park",
    "Almedia",
    "Altoona",
    "Ambler",
    "Ambridge",
    "Amity Gardens",
    "Ancient Oaks",
    "Annville",
    "Apollo",
    "Archbald",
    "Ardmore",
    "Arlington Heights",
    "Armstrong County",
    "Arnold",
    "Ashland",
    "Ashley",
    "Aspinwall",
    "Atglen",
    "Athens",
    "Audubon",
    "Avalon",
    "Avis",
    "Avoca",
    "Avon",
    "Avondale",
    "Avonia",
    "Back Mountain",
    "Baden",
    "Baidland",
    "Bainbridge",
    "Bakerstown",
    "Bala-Cynwyd",
    "Baldwin",
    "Bally",
    "Bangor",
    "Barnesboro",
    "Bath",
    "Bear Rocks",
    "Beaver",
    "Beaver County",
    "Beaver Falls",
    "Beaverdale",
    "Bedford",
    "Bedford County",
    "Bedminster",
    "Beech Mountain Lakes",
    "Belfast",
    "Bell Acres",
    "Belle Vernon",
    "Bellefonte",
    "Belleville",
    "Bellevue",
    "Bellwood",
    "Belmont",
    "Ben Avon",
    "Bentleyville",
    "Berks County",
    "Berlin",
    "Berwick",
    "Berwyn",
    "Bessemer",
    "Bethel Park",
    "Bethlehem",
    "Big Bass Lake",
    "Big Beaver",
    "Biglerville",
    "Birchwood Lakes",
    "Birdsboro",
    "Black Lick",
    "Blair County",
    "Blairsville",
    "Blakely",
    "Blandon",
    "Blawnox",
    "Bloomfield",
    "Bloomsburg",
    "Blossburg",
    "Blue Ball",
    "Blue Bell",
    "Boalsburg",
    "Boiling Springs",
    "Bonneauville",
    "Boothwyn",
    "Boswell",
    "Bowmansville",
    "Boyertown",
    "Brackenridge",
    "Braddock",
    "Braddock Hills",
    "Bradford",
    "Bradford County",
    "Bradford Woods",
    "Breinigsville",
    "Brentwood",
    "Bressler",
    "Brickerville",
    "Bridgeport",
    "Bridgeville",
    "Bristol",
    "Brittany Farms-Highlands",
    "Brockway",
    "Brodheadsville",
    "Brookhaven",
    "Brookville",
    "Broomall",
    "Brownstown",
    "Brownsville",
    "Browntown",
    "Bryn Athyn",
    "Bryn Mawr",
    "Bucks County",
    "Burgettstown",
    "Burnham",
    "Butler",
    "Butler County",
    "California",
    "Caln",
    "Calumet",
    "Cambria County",
    "Cambridge Springs",
    "Cameron County",
    "Camp Hill",
    "Campbelltown",
    "Canonsburg",
    "Canton",
    "Carbon County",
    "Carbondale",
    "Carlisle",
    "Carnegie",
    "Carnot-Moon",
    "Carroll Valley",
    "Castanea",
    "Castle Shannon",
    "Catasauqua",
    "Catawissa",
    "Cecil-Bishop",
    "Cementon",
    "Center City",
    "Centerville",
    "Central City",
    "Centre County",
    "Centre Hall",
    "Cetronia",
    "Chalfont",
    "Chambersburg",
    "Charleroi",
    "Cherryville",
    "Chester",
    "Chester County",
    "Chester Heights",
    "Chester Springs",
    "Chesterbrook",
    "Cheswick",
    "Chevy Chase Heights",
    "Chicora",
    "Chinchilla",
    "Christiana",
    "Church Hill",
    "Churchill",
    "Churchville",
    "Clairton",
    "Clarion",
    "Clarion County",
    "Clarks Green",
    "Clarks Summit",
    "Clay",
    "Claysburg",
    "Clearfield",
    "Clearfield County",
    "Cleona",
    "Clifton Heights",
    "Clinton County",
    "Clymer",
    "Coaldale",
    "Coatesville",
    "Cochranton",
    "Collegeville",
    "Collingdale",
    "Collinsburg",
    "Colonial Park",
    "Colony Park",
    "Columbia",
    "Columbia County",
    "Colwyn",
    "Conashaugh Lakes",
    "Conemaugh",
    "Conestoga",
    "Conneaut Lakeshore",
    "Connellsville",
    "Conshohocken",
    "Conway",
    "Conyngham",
    "Coopersburg",
    "Coplay",
    "Coraopolis",
    "Cornwall",
    "Cornwells Heights",
    "Corry",
    "Coudersport",
    "Crafton",
    "Cranberry Township",
    "Crawford County",
    "Cresson",
    "Cressona",
    "Croydon",
    "Cumberland County",
    "Curtisville",
    "Curwensville",
    "Dale",
    "Dallas",
    "Dallastown",
    "Dalton",
    "Danville",
    "Darby",
    "Dauphin County",
    "Davidsville",
    "Delaware County",
    "Delmont",
    "Denver",
    "Derry",
    "Devon",
    "Dewart",
    "Dickson City",
    "Dillsburg",
    "Donora",
    "Dormont",
    "Dorneyville",
    "Dover",
    "Downingtown",
    "Doylestown",
    "Dravosburg",
    "Dresher",
    "Drexel Hill",
    "DuBois",
    "Dublin",
    "Duboistown",
    "Dunbar",
    "Duncannon",
    "Duncansville",
    "Dunmore",
    "Dunnstown",
    "Dupont",
    "Duquesne",
    "Duryea",
    "Eagleview",
    "Eagleville",
    "Earlston",
    "East Bangor",
    "East Berlin",
    "East Berwick",
    "East Conemaugh",
    "East Earl",
    "East Greenville",
    "East Lansdowne",
    "East McKeesport",
    "East Petersburg",
    "East Pittsburgh",
    "East Stroudsburg",
    "East Uniontown",
    "East Washington",
    "East York",
    "Eastlawn Gardens",
    "Easton",
    "Ebensburg",
    "Economy",
    "Eddington",
    "Eddystone",
    "Edgewood",
    "Edgeworth",
    "Edinboro",
    "Edwardsville",
    "Effort",
    "Egypt",
    "Elim",
    "Elizabeth",
    "Elizabethtown",
    "Elizabethville",
    "Elk County",
    "Elkland",
    "Ellport",
    "Ellwood City",
    "Elverson",
    "Elysburg",
    "Emerald Lakes",
    "Emigsville",
    "Emmaus",
    "Emporium",
    "Emsworth",
    "Enhaut",
    "Enlow",
    "Enola",
    "Ephrata",
    "Erie",
    "Erie County",
    "Espy",
    "Etna",
    "Evans City",
    "Evansburg",
    "Everett",
    "Exeter",
    "Exton",
    "Factoryville",
    "Fairchance",
    "Fairdale",
    "Fairhope",
    "Fairless Hills",
    "Fairview",
    "Fairview-Ferndale",
    "Falls Creek",
    "Farrell",
    "Faxon",
    "Fayette County",
    "Fayetteville",
    "Feasterville",
    "Fellsburg",
    "Ferndale",
    "Fernway",
    "Fivepointville",
    "Fleetwood",
    "Flemington",
    "Flourtown",
    "Flying Hills",
    "Folcroft",
    "Folsom",
    "Ford City",
    "Forest City",
    "Forest County",
    "Forest Hills",
    "Fort Washington",
    "Forty Fort",
    "Foster Brook",
    "Fountain Hill",
    "Fox Chapel",
    "Fox Chase",
    "Fox Run",
    "Frackville",
    "Franklin",
    "Franklin County",
    "Franklin Park",
    "Fredericksburg",
    "Freedom",
    "Freeland",
    "Freemansburg",
    "Freeport",
    "Friedens",
    "Fullerton",
    "Fulton County",
    "Galeton",
    "Gallitzin",
    "Gap",
    "Garden View",
    "Gastonville",
    "Geistown",
    "Georgetown",
    "Gettysburg",
    "Gibsonia",
    "Gilbertsville",
    "Girard",
    "Girardville",
    "Glassport",
    "Glen Lyon",
    "Glen Rock",
    "Glenolden",
    "Glenshaw",
    "Glenside",
    "Gold Key Lake",
    "Grantley",
    "Green Tree",
    "Greencastle",
    "Greene County",
    "Greenfields",
    "Greenock",
    "Greensburg",
    "Greenville",
    "Greenwood",
    "Grill",
    "Grove City",
    "Guilford",
    "Guilford Siding",
    "Halfway House",
    "Hallam",
    "Hallstead",
    "Hamburg",
    "Hanover",
    "Harleigh",
    "Harleysville",
    "Harrisburg",
    "Harveys Lake",
    "Hasson Heights",
    "Hastings",
    "Hatboro",
    "Hatfield",
    "Hawley",
    "Hazleton",
    "Hebron",
    "Heidelberg",
    "Hellertown",
    "Hemlock Farms",
    "Hermitage",
    "Hershey",
    "Highland Park",
    "Highspire",
    "Hilldale",
    "Hiller",
    "Hokendauqua",
    "Hollidaysburg",
    "Homeacre-Lyndora",
    "Homer City",
    "Homestead",
    "Hometown",
    "Honesdale",
    "Honey Brook",
    "Hopwood",
    "Horsham",
    "Houserville",
    "Houston",
    "Hudson",
    "Hughestown",
    "Hughesville",
    "Hummels Wharf",
    "Hummelstown",
    "Huntingdon",
    "Huntingdon County",
    "Hyde",
    "Hyde Park",
    "Imperial",
    "Indian Mountain Lake",
    "Indiana",
    "Indiana County",
    "Industry",
    "Ingram",
    "Inkerman",
    "Intercourse",
    "Irwin",
    "Ivyland",
    "Jacksonwald",
    "Jacobus",
    "Jeannette",
    "Jefferson County",
    "Jefferson Hills",
    "Jenkintown",
    "Jermyn",
    "Jerome",
    "Jersey Shore",
    "Jessup",
    "Jim Thorpe",
    "Johnsonburg",
    "Johnstown",
    "Jonestown",
    "Juniata County",
    "Kane",
    "Kenhorst",
    "Kenilworth",
    "Kenmar",
    "Kennett Square",
    "King of Prussia",
    "Kingston",
    "Kittanning",
    "Knox",
    "Kulpmont",
    "Kulpsville",
    "Kutztown",
    "Lackawanna County",
    "Lafayette Hill",
    "Laflin",
    "Lake City",
    "Lake Heritage",
    "Lake Latonka",
    "Lake Meade",
    "Lake Wynonah",
    "Lakemont",
    "Lampeter",
    "Lancaster",
    "Lancaster County",
    "Landisville",
    "Langhorne",
    "Langhorne Manor",
    "Lansdale",
    "Lansdowne",
    "Lansford",
    "Laporte",
    "Larksville",
    "Latrobe",
    "Laureldale",
    "Laurys Station",
    "Lawnton",
    "Lawrence County",
    "Lawrence Park",
    "Lawson Heights",
    "Lebanon",
    "Lebanon County",
    "Lebanon South",
    "Leechburg",
    "Leesport",
    "Leetsdale",
    "Lehigh County",
    "Lehighton",
    "Leith-Hatfield",
    "Lemont",
    "Lemoyne",
    "Lenape Heights",
    "Leola",
    "Level Green",
    "Levittown",
    "Lewisburg",
    "Lewistown",
    "Liberty",
    "Light Street",
    "Ligonier",
    "Lima",
    "Limerick",
    "Lincoln",
    "Lincoln Park",
    "Linglestown",
    "Linntown",
    "Linwood",
    "Lionville",
    "Lititz",
    "Littlestown",
    "Lock Haven",
    "Loganville",
    "Lorane",
    "Loretto",
    "Lower Allen",
    "Lower Burrell",
    "Loyalhanna",
    "Luzerne",
    "Luzerne County",
    "Lycoming County",
    "Lykens",
    "Lynnwood-Pricedale",
    "Macungie",
    "Mahanoy City",
    "Malvern",
    "Manchester",
    "Manheim",
    "Manor",
    "Mansfield",
    "Maple Glen",
    "Marcus Hook",
    "Marianne",
    "Marienville",
    "Marietta",
    "Mars",
    "Marshallton",
    "Martinsburg",
    "Marysville",
    "Masontown",
    "Matamoras",
    "Mayfield",
    "Maytown",
    "McAdoo",
    "McConnellsburg",
    "McConnellstown",
    "McDonald",
    "McGovern",
    "McKean County",
    "McKees Rocks",
    "McKeesport",
    "McMurray",
    "McSherrystown",
    "Meadowood",
    "Meadville",
    "Mechanicsburg",
    "Mechanicsville",
    "Media",
    "Mercer",
    "Mercer County",
    "Mercersburg",
    "Meridian",
    "Meyersdale",
    "Middleburg",
    "Middletown",
    "Midland",
    "Midway",
    "Mifflin County",
    "Mifflinburg",
    "Mifflintown",
    "Mifflinville",
    "Milesburg",
    "Milford",
    "Mill Hall",
    "Millbourne",
    "Millersburg",
    "Millersville",
    "Millvale",
    "Milroy",
    "Milton",
    "Minersville",
    "Mohnton",
    "Monaca",
    "Monessen",
    "Monongahela",
    "Monroe County",
    "Monroeville",
    "Mont Alto",
    "Montgomery",
    "Montgomery County",
    "Montgomeryville",
    "Montour County",
    "Montoursville",
    "Montrose",
    "Moosic",
    "Morningside",
    "Morrisville",
    "Morton",
    "Moscow",
    "Mount Carmel",
    "Mount Cobb",
    "Mount Holly Springs",
    "Mount Joy",
    "Mount Lebanon",
    "Mount Oliver",
    "Mount Penn",
    "Mount Pleasant",
    "Mount Pocono",
    "Mount Union",
    "Mount Wolf",
    "Mountain Top",
    "Mountainhome",
    "Mountville",
    "Muhlenberg Park",
    "Muncy",
    "Mundys Corner",
    "Munhall",
    "Murrysville",
    "Muse",
    "Myerstown",
    "Nanticoke",
    "Nanty Glo",
    "Narberth",
    "Nazareth",
    "Nescopeck",
    "Nesquehoning",
    "New Beaver",
    "New Berlinville",
    "New Bloomfield",
    "New Brighton",
    "New Britain",
    "New Castle",
    "New Castle Northwest",
    "New Columbia",
    "New Cumberland",
    "New Eagle",
    "New Freedom",
    "New Holland",
    "New Hope",
    "New Kensington",
    "New Oxford",
    "New Philadelphia",
    "New Stanton",
    "New Wilmington",
    "Newmanstown",
    "Newport",
    "Newtown",
    "Newtown Grant",
    "Newville",
    "Nixon",
    "Norristown",
    "North Apollo",
    "North Belle Vernon",
    "North Braddock",
    "North Catasauqua",
    "North Charleroi",
    "North East",
    "North Versailles",
    "North Wales",
    "North Warren",
    "North York",
    "Northampton",
    "Northampton County",
    "Northern Cambria",
    "Northumberland",
    "Northumberland County",
    "Northwest Harborcreek",
    "Norwood",
    "Oak Hills",
    "Oakdale",
    "Oakland",
    "Oakmont",
    "Oakwood",
    "Ohioville",
    "Oil City",
    "Old Forge",
    "Old Orchard",
    "Oley",
    "Oliver",
    "Olyphant",
    "Orchard Hills",
    "Oreland",
    "Orwigsburg",
    "Osceola Mills",
    "Oxford",
    "Palmdale",
    "Palmer Heights",
    "Palmerton",
    "Palmyra",
    "Palo Alto",
    "Paoli",
    "Paradise",
    "Park Forest Village",
    "Parkesburg",
    "Parkside",
    "Parkville",
    "Patton",
    "Paxtang",
    "Paxtonia",
    "Pen Argyl",
    "Penbrook",
    "Penn Estates",
    "Penn Hills",
    "Penn Wynne",
    "Penndel",
    "Pennsburg",
    "Pennside",
    "Pennsport",
    "Pennville",
    "Penryn",
    "Perkasie",
    "Perry County",
    "Perryopolis",
    "Philadelphia",
    "Philadelphia County",
    "Philipsburg",
    "Phoenixville",
    "Pike County",
    "Pine Grove",
    "Pine Grove Mills",
    "Pine Ridge",
    "Pitcairn",
    "Pittsburgh",
    "Pittston",
    "Plains",
    "Pleasant Gap",
    "Pleasant Hill",
    "Pleasant Hills",
    "Plum",
    "Plumsteadville",
    "Plymouth",
    "Plymouth Meeting",
    "Pocono Pines",
    "Pocono Ranch Lands",
    "Point Marion",
    "Port Allegany",
    "Port Carbon",
    "Port Vue",
    "Portage",
    "Potter County",
    "Pottsgrove",
    "Pottstown",
    "Pottsville",
    "Progress",
    "Prospect",
    "Prospect Park",
    "Punxsutawney",
    "Pymatuning Central",
    "Quakertown",
    "Quarryville",
    "Radnor",
    "Rankin",
    "Raubsville",
    "Reading",
    "Reamstown",
    "Red Hill",
    "Red Lion",
    "Reiffton",
    "Reinholds",
    "Rennerdale",
    "Renovo",
    "Republic",
    "Reynolds Heights",
    "Reynoldsville",
    "Rheems",
    "Richboro",
    "Richland",
    "Richlandtown",
    "Ridgway",
    "Ridley Park",
    "River View Park",
    "Riverside",
    "Roaring Spring",
    "Robesonia",
    "Rochester",
    "Rockledge",
    "Roseto",
    "Rothsville",
    "Royalton",
    "Royersford",
    "Russell",
    "Russellton",
    "Rutherford",
    "Saint Clair",
    "Saint Lawrence",
    "Saint Marys",
    "Salix",
    "Salunga",
    "Sanatoga",
    "Sand Hill",
    "Sandy",
    "Saw Creek",
    "Saxonburg",
    "Saylorsburg",
    "Sayre",
    "Schlusser",
    "Schnecksville",
    "Schoeneck",
    "Schuylkill County",
    "Schuylkill Haven",
    "Schwenksville",
    "Scotland",
    "Scottdale",
    "Scranton",
    "Selinsgrove",
    "Sellersville",
    "Seneca",
    "Seven Fields",
    "Sewickley",
    "Shamokin",
    "Shamokin Dam",
    "Shanor-Northvue",
    "Sharon",
    "Sharon Hill",
    "Sharpsburg",
    "Sharpsville",
    "Shavertown",
    "Sheffield",
    "Shenandoah",
    "Shenandoah Heights",
    "Shillington",
    "Shiloh",
    "Shinglehouse",
    "Shippensburg",
    "Shiremanstown",
    "Shoemakersville",
    "Shrewsbury",
    "Sierra View",
    "Simpson",
    "Sinking Spring",
    "Skippack",
    "Skyline View",
    "Slatington",
    "Slippery Rock",
    "Smethport",
    "Snyder County",
    "Somerset",
    "Somerset County",
    "Souderton",
    "South Coatesville",
    "South Connellsville",
    "South Greensburg",
    "South Park Township",
    "South Pottstown",
    "South Temple",
    "South Uniontown",
    "South Waverly",
    "South Williamsport",
    "Southmont",
    "Southwest Greensburg",
    "Spangler",
    "Speers",
    "Spinnerstown",
    "Spring City",
    "Spring Grove",
    "Spring House",
    "Spring Mount",
    "Spring Ridge",
    "Springdale",
    "Springfield",
    "Spry",
    "State College",
    "State Line",
    "Steelton",
    "Stewartstown",
    "Stiles",
    "Stoneboro",
    "Stony Creek Mills",
    "Stonybrook",
    "Stormstown",
    "Stowe",
    "Strasburg",
    "Stroudsburg",
    "Sturgeon",
    "Sugarcreek",
    "Sullivan County",
    "Summit Hill",
    "Sun Valley",
    "Sunbury",
    "Sunrise Lake",
    "Susquehanna",
    "Susquehanna County",
    "Susquehanna Trails",
    "Swarthmore",
    "Swartzville",
    "Swissvale",
    "Swoyersville",
    "Sykesville",
    "Tacony",
    "Tamaqua",
    "Tannersville",
    "Tarentum",
    "Tatamy",
    "Taylor",
    "Telford",
    "Temple",
    "Terre Hill",
    "The Hideout",
    "Thompsonville",
    "Thorndale",
    "Throop",
    "Tinicum",
    "Tioga County",
    "Tionesta",
    "Tipton",
    "Titusville",
    "Toftrees",
    "Topton",
    "Toughkenamon",
    "Towamensing Trails",
    "Towanda",
    "Tower City",
    "Trafford",
    "Trainer",
    "Trappe",
    "Treasure Lake",
    "Tremont",
    "Trevorton",
    "Trevose",
    "Trexlertown",
    "Trooper",
    "Troy",
    "Trucksville",
    "Tullytown",
    "Tunkhannock",
    "Turtle Creek",
    "Tyrone",
    "Union City",
    "Union County",
    "Uniontown",
    "Upland",
    "Upper Saint Clair",
    "Valley Green",
    "Valley View",
    "Vandergrift",
    "Venango County",
    "Verona",
    "Versailles",
    "Village Green-Green Ridge",
    "Village Shires",
    "Vinco",
    "Wallenpaupack Lake Estates",
    "Walnutport",
    "Warminster Heights",
    "Warren",
    "Warren County",
    "Washington",
    "Washington County",
    "Waterford",
    "Watsontown",
    "Waymart",
    "Wayne",
    "Wayne County",
    "Wayne Heights",
    "Waynesboro",
    "Waynesburg",
    "Weatherly",
    "Weigelstown",
    "Weissport East",
    "Wellsboro",
    "Wernersville",
    "Wescosville",
    "Wesleyville",
    "West Chester",
    "West Conshohocken",
    "West Easton",
    "West Fairview",
    "West Grove",
    "West Hamburg",
    "West Hazleton",
    "West Hills",
    "West Homestead",
    "West Kittanning",
    "West Lawn",
    "West Leechburg",
    "West Mayfield",
    "West Mifflin",
    "West Newton",
    "West Norriton",
    "West Pittston",
    "West Reading",
    "West View",
    "West Wyoming",
    "West Wyomissing",
    "West York",
    "Westfield",
    "Westmont",
    "Westmoreland County",
    "Wharton",
    "Whitaker",
    "White Haven",
    "White Oak",
    "Whitehall",
    "Whitehall Township",
    "Whitfield",
    "Whitman",
    "Wickerham Manor-Fisher",
    "Wilkes-Barre",
    "Wilkinsburg",
    "Williamsburg",
    "Williamsport",
    "Williamstown",
    "Willow Grove",
    "Willow Street",
    "Wilmerding",
    "Wilson",
    "Wind Gap",
    "Windber",
    "Windsor",
    "Wolfdale",
    "Womelsdorf",
    "Woodbourne",
    "Woodland Heights",
    "Woodlyn",
    "Woodside",
    "Wormleysburg",
    "Woxall",
    "Wrightsville",
    "Wyncote",
    "Wyndmoor",
    "Wyoming",
    "Wyoming County",
    "Wyomissing",
    "Wyomissing Hills",
    "Yardley",
    "Yeadon",
    "Yeagertown",
    "Yoe",
    "York",
    "York County",
    "Yorklyn",
    "Youngsville",
    "Youngwood",
    "Zelienople",
    "Zion"
  ],
  "Puerto Rico": [
    "Adjuntas",
    "Aguada",
    "Aguadilla",
    "Aguas Buenas",
    "Aguas Claras",
    "Aguilita",
    "Aibonito",
    "Animas",
    "Antón Ruiz",
    "Arecibo",
    "Arroyo",
    "Añasco",
    "Bairoa",
    "Bajadero",
    "Bajandas",
    "Barahona",
    "Barceloneta",
    "Barranquitas",
    "Bartolo",
    "Bayamon",
    "Benitez",
    "Betances",
    "Boqueron",
    "Boquerón, Cabo Rojo",
    "Brenas",
    "Buena Vista",
    "Bufalo",
    "Caban",
    "Cabo Rojo",
    "Cabo Rojo Municipio",
    "Cacao",
    "Caguas",
    "Campanilla",
    "Campo Rico",
    "Camuy",
    "Candelaria",
    "Candelaria Arenas",
    "Candelero Arriba",
    "Canóvanas",
    "Capitanejo",
    "Carolina",
    "Carrizales",
    "Cataño",
    "Cayey",
    "Cayuco",
    "Ceiba",
    "Ceiba Municipio",
    "Celada",
    "Central Aguirre",
    "Ciales",
    "Ciales Municipio",
    "Cidra",
    "Coamo",
    "Coco",
    "Comerío",
    "Comerío Municipio",
    "Comunas",
    "Coquí",
    "Corazón",
    "Corcovado",
    "Corozal",
    "Corozal Municipio",
    "Coto Laurel",
    "Coto Norte",
    "Culebra",
    "Culebra barrio-pueblo",
    "Daguao",
    "Dorado",
    "Dorado Municipio",
    "El Mangó",
    "El Negro",
    "El Ojo",
    "Emajagua",
    "Esperanza",
    "Espino",
    "Estancias de Florida",
    "Fajardo",
    "Fajardo Municipio",
    "Florida",
    "Franquez",
    "Fuig",
    "G. L. Garcia",
    "Galateo",
    "Garrochales",
    "Guayabal",
    "Guayama",
    "Guayama Municipio",
    "Guayanilla",
    "Guaynabo",
    "Gurabo",
    "Gurabo Municipio",
    "Guánica",
    "Guánica Municipio",
    "H. Rivera Colon",
    "Hatillo",
    "Hatillo Municipio",
    "Hato Arriba",
    "Hato Candal",
    "Hormigueros",
    "Hormigueros Municipio",
    "Humacao",
    "Imbery",
    "Indios",
    "Ingenio",
    "Isabel Segunda",
    "Isabela",
    "Jagual",
    "Jauca",
    "Jayuya",
    "Jobos",
    "Juana Díaz",
    "Juana Díaz Municipio",
    "Juncal",
    "Juncos",
    "La Alianza",
    "La Dolores",
    "La Fermina",
    "La Luisa",
    "La Parguera",
    "La Playa",
    "La Plena",
    "Lajas",
    "Lajas Municipio",
    "Lamboglia",
    "Lares",
    "Las Marias",
    "Las Marías Municipio",
    "Las Ochenta",
    "Las Ollas",
    "Las Piedras",
    "Las Piedras Municipio",
    "Levittown",
    "Liborio Negron Torres",
    "Lluveras",
    "Lomas",
    "Los Llanos",
    "Loíza",
    "Luis Lloréns Torres",
    "Luis M. Cintron",
    "Luquillo",
    "Luquillo Municipio",
    "Luyando",
    "Magas Arriba",
    "Manatí",
    "Maria Antonia",
    "Mariano Colón",
    "Maricao",
    "Maricao Municipio",
    "Martorell",
    "Marueño",
    "Maunabo",
    "Maunabo Municipio",
    "Mayagüez",
    "Miranda",
    "Moca",
    "Moca Municipio",
    "Monserrate",
    "Monte Grande",
    "Mora",
    "Morovis",
    "Mucarabones",
    "Naguabo",
    "Naguabo Municipio",
    "Naranjito",
    "Naranjito Municipio",
    "Olimpo",
    "Orocovis",
    "Orocovis Municipio",
    "Pajonal",
    "Palmarejo",
    "Palmas",
    "Palmer",
    "Palo Seco",
    "Palomas",
    "Parcelas La Milagrosa",
    "Parcelas Nuevas",
    "Parcelas Peñuelas",
    "Pastos",
    "Patillas",
    "Patillas Municipio",
    "Peña Pobre",
    "Peñuelas",
    "Piedra Gorda",
    "Playa Fortuna",
    "Playita",
    "Playita Cortada",
    "Pole Ojea",
    "Ponce",
    "Potala Pastillo",
    "Pueblito del Rio",
    "Puerto Real",
    "Punta Santiago",
    "Pájaros",
    "Quebrada",
    "Quebradillas",
    "Quebradillas Municipio",
    "Rafael Capo",
    "Rafael Gonzalez",
    "Rafael Hernandez",
    "Ramos",
    "Rincón",
    "Rincón Municipio",
    "Rio Lajas",
    "Rosa Sanchez",
    "Río Blanco",
    "Río Cañas Abajo",
    "Río Grande",
    "Río Grande Municipio",
    "Río Piedras",
    "Sabana",
    "Sabana Eneas",
    "Sabana Grande",
    "Sabana Grande Municipio",
    "Sabana Hoyos",
    "Sabana Seca",
    "Salinas",
    "San Antonio",
    "San Germán Municipio",
    "San Isidro",
    "San José",
    "San Juan",
    "San Lorenzo",
    "San Sebastián",
    "Santa Barbara",
    "Santa Clara",
    "Santa Isabel",
    "Santa Isabel Municipio",
    "Santo Domingo",
    "Santurce",
    "Stella",
    "Sumidero",
    "Suárez",
    "Tallaboa",
    "Tallaboa Alta",
    "Tiburones",
    "Tierras Nuevas Poniente",
    "Toa Alta",
    "Toa Alta Municipio",
    "Toa Baja",
    "Trujillo Alto",
    "Trujillo Alto Municipio",
    "Utuado",
    "Utuado barrio-pueblo",
    "Vega Alta",
    "Vega Alta Municipio",
    "Vega Baja",
    "Vieques",
    "Vieques Municipality",
    "Villalba",
    "Villalba Municipio",
    "Vázquez",
    "Yabucoa",
    "Yabucoa Municipio",
    "Yauco",
    "Yaurel"
  ],
  "Rhode Island": [
    "Ashaway",
    "Barrington",
    "Bradford",
    "Bristol",
    "Bristol County",
    "Central Falls",
    "Charlestown",
    "Chepachet",
    "Coventry",
    "Cranston",
    "Cumberland",
    "Cumberland Hill",
    "East Greenwich",
    "East Providence",
    "Exeter",
    "Foster",
    "Greenville",
    "Harrisville",
    "Hope Valley",
    "Hopkinton",
    "Jamestown",
    "Johnston",
    "Kent County",
    "Kingston",
    "Lincoln",
    "Melville",
    "Middletown",
    "Narragansett",
    "Narragansett Pier",
    "New Shoreham",
    "Newport",
    "Newport County",
    "Newport East",
    "North Kingstown",
    "North Providence",
    "North Scituate",
    "North Smithfield",
    "Pascoag",
    "Pawtucket",
    "Portsmouth",
    "Providence",
    "Providence County",
    "Smithfield",
    "South Kingstown",
    "Tiverton",
    "Valley Falls",
    "Wakefield-Peacedale",
    "Warren",
    "Warwick",
    "Washington County",
    "West Greenwich",
    "West Warwick",
    "Westerly",
    "Woonsocket"
  ],
  "South Carolina": [
    "Abbeville",
    "Abbeville County",
    "Aiken",
    "Aiken County",
    "Allendale",
    "Allendale County",
    "Anderson",
    "Anderson County",
    "Andrews",
    "Arcadia",
    "Arial",
    "Awendaw",
    "Bamberg",
    "Bamberg County",
    "Barnwell",
    "Barnwell County",
    "Batesburg",
    "Batesburg-Leesville",
    "Beaufort",
    "Beaufort County",
    "Belton",
    "Belvedere",
    "Bennettsville",
    "Berea",
    "Berkeley County",
    "Bishopville",
    "Blacksburg",
    "Blackville",
    "Bluffton",
    "Blythewood",
    "Boiling Springs",
    "Bonneau Beach",
    "Brookdale",
    "Buffalo",
    "Burnettown",
    "Burton",
    "Calhoun County",
    "Calhoun Falls",
    "Camden",
    "Cane Savannah",
    "Catawba",
    "Cayce",
    "Centerville",
    "Central",
    "Chapin",
    "Charleston",
    "Charleston County",
    "Cheraw",
    "Cherokee County",
    "Cherryvale",
    "Chester",
    "Chester County",
    "Chesterfield",
    "Chesterfield County",
    "City View",
    "Clarendon County",
    "Clearwater",
    "Clemson",
    "Clinton",
    "Clover",
    "Colleton County",
    "Columbia",
    "Conway",
    "Cowpens",
    "Dalzell",
    "Darlington",
    "Darlington County",
    "Denmark",
    "Dentsville",
    "Dillon",
    "Dillon County",
    "Dorchester County",
    "Due West",
    "Duncan",
    "Dunean",
    "Easley",
    "East Gaffney",
    "East Sumter",
    "Edgefield",
    "Edgefield County",
    "Edisto",
    "Elgin",
    "Estill",
    "Eureka Mill",
    "Fairfax",
    "Fairfield County",
    "Fairforest",
    "Five Forks",
    "Florence",
    "Florence County",
    "Folly Beach",
    "Forest Acres",
    "Forestbrook",
    "Fort Mill",
    "Fountain Inn",
    "Gadsden",
    "Gaffney",
    "Gantt",
    "Garden City",
    "Gaston",
    "Georgetown",
    "Georgetown County",
    "Gloverville",
    "Golden Grove",
    "Goose Creek",
    "Graniteville",
    "Great Falls",
    "Greenville",
    "Greenville County",
    "Greenwood",
    "Greenwood County",
    "Greer",
    "Hampton",
    "Hampton County",
    "Hanahan",
    "Hardeeville",
    "Hartsville",
    "Hilton Head",
    "Hilton Head Island",
    "Holly Hill",
    "Hollywood",
    "Homeland Park",
    "Honea Path",
    "Hopkins",
    "Horry County",
    "India Hook",
    "Inman",
    "Inman Mills",
    "Irmo",
    "Irwin",
    "Isle of Palms",
    "Iva",
    "Jackson",
    "James Island",
    "Jasper County",
    "Joanna",
    "Johnsonville",
    "Johnston",
    "Judson",
    "Kershaw",
    "Kershaw County",
    "Kiawah Island",
    "Kingstree",
    "Ladson",
    "Lake City",
    "Lake Murray of Richland",
    "Lake Secession",
    "Lake Wylie",
    "Lakewood",
    "Lancaster",
    "Lancaster County",
    "Lancaster Mill",
    "Landrum",
    "Langley",
    "Latta",
    "Laurel Bay",
    "Laurens",
    "Laurens County",
    "Lee County",
    "Leesville",
    "Lesslie",
    "Lexington",
    "Lexington County",
    "Liberty",
    "Lincolnville",
    "Little River",
    "Loris",
    "Lugoff",
    "Lyman",
    "Manning",
    "Marion",
    "Marion County",
    "Marlboro County",
    "Mauldin",
    "Mayo",
    "McColl",
    "McCormick",
    "McCormick County",
    "Meggett",
    "Monarch Mill",
    "Moncks Corner",
    "Mount Pleasant",
    "Mullins",
    "Murphys Estates",
    "Murrells Inlet",
    "Myrtle Beach",
    "New Ellenton",
    "Newberry",
    "Newberry County",
    "Newport",
    "Ninety Six",
    "North Augusta",
    "North Charleston",
    "North Hartsville",
    "North Myrtle Beach",
    "Northlake",
    "Oak Grove",
    "Oakland",
    "Oconee County",
    "Orangeburg",
    "Orangeburg County",
    "Pacolet",
    "Pageland",
    "Pamplico",
    "Parker",
    "Pendleton",
    "Pickens",
    "Pickens County",
    "Piedmont",
    "Pineridge",
    "Port Royal",
    "Powdersville",
    "Privateer",
    "Prosperity",
    "Ravenel",
    "Red Bank",
    "Red Hill",
    "Richland County",
    "Ridgeland",
    "Ridgeville",
    "Rock Hill",
    "Roebuck",
    "Saint Andrews",
    "Saint George",
    "Saint Matthews",
    "Saint Stephen",
    "Saluda",
    "Saluda County",
    "Sangaree",
    "Sans Souci",
    "Saxon",
    "Seabrook Island",
    "Seneca",
    "Seven Oaks",
    "Shell Point",
    "Simpsonville",
    "Slater-Marietta",
    "Socastee",
    "South Congaree",
    "South Sumter",
    "Southern Shops",
    "Spartanburg",
    "Spartanburg County",
    "Springdale",
    "Stateburg",
    "Sullivans Island",
    "Summerville",
    "Sumter",
    "Sumter County",
    "Surfside Beach",
    "Taylors",
    "Tega Cay",
    "Tigerville",
    "Timmonsville",
    "Travelers Rest",
    "Union",
    "Union County",
    "Utica",
    "Valley Falls",
    "Varnville",
    "Wade Hampton",
    "Walhalla",
    "Walterboro",
    "Ware Shoals",
    "Warrenville",
    "Watts Mills",
    "Wedgefield",
    "Wedgewood",
    "Welcome",
    "Wellford",
    "West Columbia",
    "Westminster",
    "Whitmire",
    "Wilkinson Heights",
    "Williamsburg County",
    "Williamston",
    "Williston",
    "Winnsboro",
    "Winnsboro Mills",
    "Woodfield",
    "Woodruff",
    "York",
    "York County"
  ],
  "South Dakota": [
    "Aberdeen",
    "Alexandria",
    "Armour",
    "Aurora County",
    "Baltic",
    "Beadle County",
    "Belle Fourche",
    "Bennett County",
    "Beresford",
    "Bison",
    "Blackhawk",
    "Bon Homme County",
    "Box Elder",
    "Brandon",
    "Britton",
    "Brookings",
    "Brookings County",
    "Brown County",
    "Brule County",
    "Buffalo",
    "Buffalo County",
    "Burke",
    "Butte County",
    "Campbell County",
    "Canton",
    "Chamberlain",
    "Charles Mix County",
    "Clark",
    "Clark County",
    "Clay County",
    "Clear Lake",
    "Codington County",
    "Colonial Pine Hills",
    "Corson County",
    "Crooks",
    "Custer",
    "Custer County",
    "Dakota Dunes",
    "Davison County",
    "Day County",
    "De Smet",
    "Deadwood",
    "Dell Rapids",
    "Deuel County",
    "Dewey County",
    "Douglas County",
    "Dupree",
    "Eagle Butte",
    "Edmunds County",
    "Elk Point",
    "Fall River County",
    "Faulk County",
    "Faulkton",
    "Flandreau",
    "Fort Pierre",
    "Fort Thompson",
    "Freeman",
    "Garretson",
    "Gettysburg",
    "Grant County",
    "Gregory",
    "Gregory County",
    "Groton",
    "Haakon County",
    "Hamlin County",
    "Hand County",
    "Hanson County",
    "Harding County",
    "Harrisburg",
    "Hartford",
    "Hayti",
    "Highmore",
    "Hot Springs",
    "Howard",
    "Hughes County",
    "Huron",
    "Hutchinson County",
    "Hyde County",
    "Ipswich",
    "Jackson County",
    "Jerauld County",
    "Jones County",
    "Kadoka",
    "Kennebec",
    "Kingsbury County",
    "Lake Andes",
    "Lake County",
    "Lawrence County",
    "Lead",
    "Lemmon",
    "Lennox",
    "Leola",
    "Lincoln County",
    "Lyman County",
    "Madison",
    "Marshall County",
    "Martin",
    "McCook County",
    "McIntosh",
    "McPherson County",
    "Meade County",
    "Mellette County",
    "Milbank",
    "Miller",
    "Miner County",
    "Minnehaha County",
    "Mission",
    "Mitchell",
    "Mobridge",
    "Moody County",
    "Mound City",
    "Murdo",
    "North Eagle Butte",
    "North Sioux City",
    "North Spearfish",
    "Oglala",
    "Oglala Lakota County",
    "Olivet",
    "Onida",
    "Parker",
    "Parkston",
    "Pennington County",
    "Perkins County",
    "Philip",
    "Pierre",
    "Pine Ridge",
    "Plankinton",
    "Platte",
    "Porcupine",
    "Potter County",
    "Rapid City",
    "Rapid Valley",
    "Redfield",
    "Roberts County",
    "Rosebud",
    "Salem",
    "Sanborn County",
    "Selby",
    "Sioux Falls",
    "Sisseton",
    "Spearfish",
    "Spink County",
    "Springfield",
    "Stanley County",
    "Sturgis",
    "Sully County",
    "Summerset",
    "Tea",
    "Timber Lake",
    "Todd County",
    "Tripp County",
    "Turner County",
    "Tyndall",
    "Union County",
    "Vermillion",
    "Volga",
    "Wagner",
    "Walworth County",
    "Watertown",
    "Webster",
    "Wessington Springs",
    "White River",
    "Winner",
    "Woonsocket",
    "Yankton",
    "Yankton County",
    "Ziebach County"
  ],
  "Tennessee": [
    "Adamsville",
    "Alamo",
    "Alcoa",
    "Algood",
    "Altamont",
    "Anderson County",
    "Apison",
    "Ardmore",
    "Arlington",
    "Ashland City",
    "Athens",
    "Atoka",
    "Banner Hill",
    "Bartlett",
    "Baxter",
    "Bean Station",
    "Bedford County",
    "Belle Meade",
    "Bells",
    "Benton",
    "Benton County",
    "Blaine",
    "Bledsoe County",
    "Bloomingdale",
    "Blount County",
    "Blountville",
    "Bluff City",
    "Bolivar",
    "Bon Aqua Junction",
    "Bradford",
    "Bradley County",
    "Brentwood",
    "Brentwood Estates",
    "Brighton",
    "Bristol",
    "Brownsville",
    "Bruceton",
    "Burns",
    "Byrdstown",
    "Camden",
    "Campbell County",
    "Cannon County",
    "Carroll County",
    "Carter County",
    "Carthage",
    "Caryville",
    "Celina",
    "Centerville",
    "Central",
    "Chapel Hill",
    "Charlotte",
    "Chattanooga",
    "Cheatham County",
    "Chester County",
    "Christiana",
    "Church Hill",
    "Claiborne County",
    "Clarksville",
    "Clay County",
    "Cleveland",
    "Clifton",
    "Clinton",
    "Coalfield",
    "Cocke County",
    "Coffee County",
    "Collegedale",
    "Collierville",
    "Colonial Heights",
    "Columbia",
    "Condon",
    "Cookeville",
    "Coopertown",
    "Cornersville",
    "Covington",
    "Cowan",
    "Crockett County",
    "Cross Plains",
    "Crossville",
    "Crump",
    "Cumberland County",
    "Dandridge",
    "Davidson County",
    "Dayton",
    "DeKalb County",
    "Decatur",
    "Decatur County",
    "Decaturville",
    "Decherd",
    "Dickson",
    "Dickson County",
    "Dodson Branch",
    "Dover",
    "Dresden",
    "Dunlap",
    "Dyer",
    "Dyer County",
    "Dyersburg",
    "Eagleton Village",
    "East Brainerd",
    "East Chattanooga",
    "East Cleveland",
    "East Ridge",
    "Elizabethton",
    "Englewood",
    "Erin",
    "Erwin",
    "Estill Springs",
    "Etowah",
    "Fairfield Glade",
    "Fairmount",
    "Fairview",
    "Fall Branch",
    "Falling Water",
    "Farragut",
    "Fayette County",
    "Fayetteville",
    "Fentress County",
    "Fincastle",
    "Forest Hills",
    "Franklin",
    "Franklin County",
    "Gainesboro",
    "Gallatin",
    "Gatlinburg",
    "Germantown",
    "Gibson County",
    "Giles County",
    "Gleason",
    "Goodlettsville",
    "Gordonsville",
    "Grainger County",
    "Gray",
    "Graysville",
    "Green Hill",
    "Greenback",
    "Greenbrier",
    "Greene County",
    "Greeneville",
    "Greenfield",
    "Grimsley",
    "Gruetli-Laager",
    "Grundy County",
    "Halls",
    "Hamblen County",
    "Hamilton County",
    "Hancock County",
    "Hardeman County",
    "Hardin County",
    "Harriman",
    "Harrison",
    "Harrogate",
    "Hartsville",
    "Hawkins County",
    "Haywood County",
    "Henderson",
    "Henderson County",
    "Hendersonville",
    "Henry County",
    "Hickman County",
    "Hickory Withe",
    "Hohenwald",
    "Hopewell",
    "Houston County",
    "Humboldt",
    "Humphreys County",
    "Hunter",
    "Huntingdon",
    "Huntsville",
    "Jacksboro",
    "Jackson",
    "Jackson County",
    "Jamestown",
    "Jasper",
    "Jefferson City",
    "Jefferson County",
    "Jellico",
    "Johnson City",
    "Johnson County",
    "Johnsonville",
    "Jonesborough",
    "Kenton",
    "Kimball",
    "Kingsport",
    "Kingston",
    "Kingston Springs",
    "Knox County",
    "Knoxville",
    "La Vergne",
    "LaFollette",
    "Lafayette",
    "Lake County",
    "Lake Tansi",
    "Lakeland",
    "Lakesite",
    "Lakewood",
    "Lauderdale County",
    "Lawrence County",
    "Lawrenceburg",
    "Lebanon",
    "Lenoir City",
    "Lewis County",
    "Lewisburg",
    "Lexington",
    "Lincoln County",
    "Linden",
    "Livingston",
    "Lone Oak",
    "Lookout Mountain",
    "Loretto",
    "Loudon",
    "Loudon County",
    "Louisville",
    "Luttrell",
    "Lynchburg",
    "Macon County",
    "Madison County",
    "Madisonville",
    "Manchester",
    "Marion County",
    "Marshall County",
    "Martin",
    "Maryville",
    "Mascot",
    "Mason",
    "Maury County",
    "Maynardville",
    "McEwen",
    "McKenzie",
    "McMinn County",
    "McMinnville",
    "McNairy County",
    "Medina",
    "Meigs County",
    "Memphis",
    "Middle Valley",
    "Midtown",
    "Midway",
    "Milan",
    "Millersville",
    "Millington",
    "Monroe County",
    "Monteagle",
    "Monterey",
    "Montgomery County",
    "Moore County",
    "Morgan County",
    "Morristown",
    "Mosheim",
    "Mount Carmel",
    "Mount Juliet",
    "Mount Pleasant",
    "Mountain City",
    "Mowbray Mountain",
    "Munford",
    "Murfreesboro",
    "Nashville",
    "New Hope",
    "New Johnsonville",
    "New Market",
    "New South Memphis",
    "New Tazewell",
    "New Union",
    "Newbern",
    "Newport",
    "Nolensville",
    "Norris",
    "Oak Grove",
    "Oak Hill",
    "Oak Ridge",
    "Oakland",
    "Obion",
    "Obion County",
    "Oliver Springs",
    "Olivet",
    "Oneida",
    "Overton County",
    "Paris",
    "Park City",
    "Parsons",
    "Pegram",
    "Perry County",
    "Pickett County",
    "Pigeon Forge",
    "Pikeville",
    "Pine Crest",
    "Piperton",
    "Plainview",
    "Pleasant View",
    "Polk County",
    "Portland",
    "Powells Crossroads",
    "Pulaski",
    "Putnam County",
    "Red Bank",
    "Red Boiling Springs",
    "Rhea County",
    "Ridgely",
    "Ridgetop",
    "Ripley",
    "Roan Mountain",
    "Roane County",
    "Robertson County",
    "Rockwood",
    "Rocky Top",
    "Rogersville",
    "Rural Hill",
    "Rutherford",
    "Rutherford County",
    "Rutledge",
    "Sale Creek",
    "Savannah",
    "Scott County",
    "Selmer",
    "Sequatchie County",
    "Sevier County",
    "Sevierville",
    "Sewanee",
    "Seymour",
    "Shackle Island",
    "Shelby County",
    "Shelbyville",
    "Signal Mountain",
    "Smith County",
    "Smithville",
    "Smyrna",
    "Sneedville",
    "Soddy-Daisy",
    "Somerville",
    "South Carthage",
    "South Cleveland",
    "South Fulton",
    "South Pittsburg",
    "Sparta",
    "Spencer",
    "Spring City",
    "Spring Hill",
    "Springfield",
    "Spurgeon",
    "Stewart County",
    "Sullivan County",
    "Sumner County",
    "Surgoinsville",
    "Sweetwater",
    "Tazewell",
    "Tellico Village",
    "Tennessee Ridge",
    "Thompson's Station",
    "Three Way",
    "Tipton County",
    "Tiptonville",
    "Tracy City",
    "Trenton",
    "Trousdale County",
    "Troy",
    "Tullahoma",
    "Tusculum",
    "Unicoi",
    "Unicoi County",
    "Union City",
    "Union County",
    "Unionville",
    "Van Buren County",
    "Vonore",
    "Walden",
    "Walnut Hill",
    "Warren County",
    "Wartburg",
    "Washington County",
    "Watertown",
    "Waverly",
    "Wayne County",
    "Waynesboro",
    "Weakley County",
    "Westmoreland",
    "White Bluff",
    "White County",
    "White House",
    "White Pine",
    "Whiteville",
    "Whitwell",
    "Wildwood",
    "Wildwood Lake",
    "Williamson County",
    "Wilson County",
    "Winchester",
    "Woodbury"
  ],
  "Texas": [
    "Abernathy",
    "Abilene",
    "Abram",
    "Addison",
    "Agua Dulce",
    "Alamo",
    "Alamo Heights",
    "Albany",
    "Aldine",
    "Aledo",
    "Alice",
    "Alief",
    "Allen",
    "Alpine",
    "Alto",
    "Alton",
    "Alton North (historical)",
    "Alvarado",
    "Alvin",
    "Alvord",
    "Amarillo",
    "Ames",
    "Anahuac",
    "Anderson",
    "Anderson County",
    "Anderson Mill",
    "Andrews",
    "Andrews County",
    "Angelina County",
    "Angleton",
    "Anna",
    "Annetta",
    "Anson",
    "Anthony",
    "Anton",
    "Aransas County",
    "Aransas Pass",
    "Archer City",
    "Archer County",
    "Arcola",
    "Argyle",
    "Arlington",
    "Armstrong County",
    "Asherton",
    "Aspermont",
    "Atascocita",
    "Atascosa County",
    "Athens",
    "Atlanta",
    "Aubrey",
    "Aurora",
    "Austin",
    "Austin County",
    "Azle",
    "Bacliff",
    "Bailey County",
    "Baird",
    "Balch Springs",
    "Balcones Heights",
    "Ballinger",
    "Bandera",
    "Bandera County",
    "Bangs",
    "Barrett",
    "Bartlett",
    "Barton Creek",
    "Bartonville",
    "Bastrop",
    "Bastrop County",
    "Batesville",
    "Bay City",
    "Baylor County",
    "Bayou Vista",
    "Baytown",
    "Beach City",
    "Beaumont",
    "Bedford",
    "Bee Cave",
    "Bee County",
    "Beeville",
    "Bell County",
    "Bellaire",
    "Bellmead",
    "Bells",
    "Bellville",
    "Belton",
    "Benavides",
    "Benbrook",
    "Benjamin",
    "Berryville",
    "Bertram",
    "Beverly",
    "Beverly Hills",
    "Bevil Oaks",
    "Bexar County",
    "Big Lake",
    "Big Sandy",
    "Big Spring",
    "Bishop",
    "Blanco",
    "Blanco County",
    "Bloomington",
    "Blossom",
    "Blue Mound",
    "Boerne",
    "Bogata",
    "Boling",
    "Bolivar Peninsula",
    "Bonham",
    "Booker",
    "Borden County",
    "Borger",
    "Bosque County",
    "Bovina",
    "Bowie",
    "Bowie County",
    "Boyd",
    "Brackettville",
    "Brady",
    "Brazoria",
    "Brazoria County",
    "Brazos County",
    "Breckenridge",
    "Brenham",
    "Brewster County",
    "Briar",
    "Briarcliff",
    "Bridge City",
    "Bridgeport",
    "Briscoe County",
    "Brooks County",
    "Brookshire",
    "Brookside Village",
    "Brown County",
    "Brownfield",
    "Brownsboro",
    "Brownsville",
    "Brownwood",
    "Bruceville-Eddy",
    "Brushy Creek",
    "Bryan",
    "Buchanan Dam",
    "Buda",
    "Buffalo",
    "Bullard",
    "Bulverde",
    "Buna",
    "Bunker Hill Village",
    "Burkburnett",
    "Burleson",
    "Burleson County",
    "Burnet",
    "Burnet County",
    "Bushland",
    "Cactus",
    "Caddo Mills",
    "Caldwell",
    "Caldwell County",
    "Calhoun County",
    "Callahan County",
    "Calvert",
    "Cameron",
    "Cameron County",
    "Cameron Park",
    "Cameron Park Colonia",
    "Camp County",
    "Camp Swift",
    "Canadian",
    "Canton",
    "Canutillo",
    "Canyon",
    "Canyon Lake",
    "Carrizo Springs",
    "Carrollton",
    "Carson County",
    "Carthage",
    "Cass County",
    "Castle Hills",
    "Castro County",
    "Castroville",
    "Cedar Hill",
    "Cedar Park",
    "Celina",
    "Center",
    "Centerville",
    "Central Gardens",
    "Chambers County",
    "Chandler",
    "Channelview",
    "Channing",
    "Charlotte",
    "Cherokee County",
    "Chico",
    "Childress",
    "Childress County",
    "China",
    "China Grove",
    "Cibolo",
    "Cienegas Terrace",
    "Cinco Ranch",
    "Circle D-KC Estates",
    "Cisco",
    "Citrus City",
    "Clarendon",
    "Clarksville",
    "Claude",
    "Clay County",
    "Clear Lake Shores",
    "Cleburne",
    "Cleveland",
    "Clifton",
    "Clint",
    "Cloverleaf",
    "Clute",
    "Clyde",
    "Cochran County",
    "Cockrell Hill",
    "Coke County",
    "Coldspring",
    "Coleman",
    "Coleman County",
    "College Station",
    "Colleyville",
    "Collin County",
    "Collingsworth County",
    "Collinsville",
    "Colorado City",
    "Colorado County",
    "Columbus",
    "Comal County",
    "Comanche",
    "Comanche County",
    "Combes",
    "Combine",
    "Comfort",
    "Commerce",
    "Concho County",
    "Conroe",
    "Converse",
    "Cooke County",
    "Cooper",
    "Coppell",
    "Copper Canyon",
    "Copperas Cove",
    "Corinth",
    "Corpus Christi",
    "Corrigan",
    "Corsicana",
    "Coryell County",
    "Cottle County",
    "Cottonwood Shores",
    "Cotulla",
    "Crandall",
    "Crane",
    "Crane County",
    "Crockett",
    "Crockett County",
    "Crosby",
    "Crosby County",
    "Crosbyton",
    "Cross Mountain",
    "Crowell",
    "Crowley",
    "Crystal City",
    "Cuero",
    "Culberson County",
    "Cut and Shoot",
    "Cypress",
    "César Chávez",
    "Daingerfield",
    "Dalhart",
    "Dallam County",
    "Dallas",
    "Dallas County",
    "Dalworthington Gardens",
    "Danbury",
    "Dawson County",
    "Dayton",
    "De Kalb",
    "De Leon",
    "DeCordova",
    "DeSoto",
    "DeWitt County",
    "Deaf Smith County",
    "Decatur",
    "Deer Park",
    "Del Rio",
    "Delta County",
    "Denison",
    "Denton",
    "Denton County",
    "Denver City",
    "Devine",
    "Deweyville",
    "Diboll",
    "Dickens",
    "Dickens County",
    "Dickinson",
    "Dilley",
    "Dimmit County",
    "Dimmitt",
    "Doffing",
    "Donley County",
    "Donna",
    "Doolittle",
    "Double Oak",
    "Dripping Springs",
    "Dublin",
    "Dumas",
    "Duncanville",
    "Duval County",
    "Eagle Lake",
    "Eagle Mountain",
    "Eagle Pass",
    "Early",
    "Earth",
    "East Bernard",
    "Eastland",
    "Eastland County",
    "Ector County",
    "Edcouch",
    "Eden",
    "Edgecliff Village",
    "Edgewood",
    "Edinburg",
    "Edna",
    "Edwards County",
    "Eidson Road",
    "El Campo",
    "El Cenizo",
    "El Lago",
    "El Paso",
    "El Paso County",
    "Eldorado",
    "Electra",
    "Elgin",
    "Elkhart",
    "Ellis County",
    "Elm Creek",
    "Elmendorf",
    "Elsa",
    "Emory",
    "Encantada-Ranchito-El Calaboz",
    "Ennis",
    "Erath County",
    "Escobares",
    "Euless",
    "Evadale",
    "Everman",
    "Fabens",
    "Fair Oaks Ranch",
    "Fairchilds",
    "Fairfield",
    "Fairview",
    "Falcon Lake Estates",
    "Falfurrias",
    "Falls County",
    "Fannett",
    "Fannin County",
    "Farmers Branch",
    "Farmersville",
    "Farwell",
    "Fate",
    "Fayette County",
    "Ferris",
    "Fifth Street",
    "Fisher County",
    "Flatonia",
    "Florence",
    "Floresville",
    "Flower Mound",
    "Floyd County",
    "Floydada",
    "Foard County",
    "Forest Hill",
    "Forney",
    "Fort Bend County",
    "Fort Bliss",
    "Fort Clark Springs",
    "Fort Davis",
    "Fort Hancock",
    "Fort Hood",
    "Fort Stockton",
    "Fort Worth",
    "Four Corners",
    "Franklin",
    "Franklin County",
    "Frankston",
    "Fredericksburg",
    "Freeport",
    "Freer",
    "Freestone County",
    "Fresno",
    "Friendswood",
    "Frio County",
    "Friona",
    "Frisco",
    "Fritch",
    "Fulshear",
    "Fulton",
    "Gail",
    "Gaines County",
    "Gainesville",
    "Galena Park",
    "Galveston",
    "Galveston County",
    "Ganado",
    "Garden City",
    "Garden Ridge",
    "Gardendale",
    "Garfield",
    "Garland",
    "Garza County",
    "Gatesville",
    "George West",
    "Georgetown",
    "Geronimo",
    "Gholson",
    "Giddings",
    "Gillespie County",
    "Gilmer",
    "Gladewater",
    "Glasscock County",
    "Glen Rose",
    "Glenn Heights",
    "Godley",
    "Goldthwaite",
    "Goliad",
    "Goliad County",
    "Gonzales",
    "Gonzales County",
    "Gorman",
    "Graham",
    "Granbury",
    "Grand Prairie",
    "Grand Saline",
    "Grandview",
    "Granger",
    "Granite Shoals",
    "Grape Creek",
    "Grapeland",
    "Grapevine",
    "Gray County",
    "Grayson County",
    "Greatwood",
    "Green Valley Farms",
    "Greenville",
    "Gregg County",
    "Gregory",
    "Grimes County",
    "Groesbeck",
    "Groves",
    "Groveton",
    "Gruver",
    "Guadalupe County",
    "Gun Barrel City",
    "Gunter",
    "Guthrie",
    "Hackberry",
    "Hale Center",
    "Hale County",
    "Hall County",
    "Hallettsville",
    "Hallsville",
    "Haltom City",
    "Hamilton",
    "Hamilton County",
    "Hamlin",
    "Hansford County",
    "Hardeman County",
    "Hardin County",
    "Harker Heights",
    "Harlingen",
    "Harper",
    "Harris County",
    "Harrison County",
    "Hart",
    "Hartley County",
    "Haskell",
    "Haskell County",
    "Haslet",
    "Hawkins",
    "Hays County",
    "Hearne",
    "Heath",
    "Hebbronville",
    "Hedwig Village",
    "Heidelberg",
    "Helotes",
    "Hemphill",
    "Hemphill County",
    "Hempstead",
    "Henderson",
    "Henderson County",
    "Henrietta",
    "Hereford",
    "Hewitt",
    "Hickory Creek",
    "Hico",
    "Hidalgo",
    "Hidalgo County",
    "Hideaway",
    "Highland Park",
    "Highland Village",
    "Highlands",
    "Hill Country Village",
    "Hill County",
    "Hillsboro",
    "Hilltop Lakes",
    "Hitchcock",
    "Hockley County",
    "Holiday Lakes",
    "Holland",
    "Holliday",
    "Holly Lake Ranch",
    "Hollywood Park",
    "Homestead Meadows North",
    "Homestead Meadows South",
    "Hondo",
    "Honey Grove",
    "Hood County",
    "Hooks",
    "Hopkins County",
    "Horizon City",
    "Hornsby Bend",
    "Horseshoe Bay",
    "Houston",
    "Houston County",
    "Howard County",
    "Howe",
    "Hubbard",
    "Hudson",
    "Hudson Bend",
    "Hudson Oaks",
    "Hudspeth County",
    "Hughes Springs",
    "Humble",
    "Hunt County",
    "Hunters Creek Village",
    "Huntington",
    "Huntsville",
    "Hurst",
    "Hutchins",
    "Hutchinson County",
    "Hutto",
    "Idalou",
    "Indian Hills",
    "Inez",
    "Ingleside",
    "Ingram",
    "Iowa Colony",
    "Iowa Park",
    "Iraan",
    "Irion County",
    "Irving",
    "Italy",
    "Itasca",
    "Jacinto City",
    "Jack County",
    "Jacksboro",
    "Jackson County",
    "Jacksonville",
    "Jamaica Beach",
    "Jarrell",
    "Jasper",
    "Jasper County",
    "Jayton",
    "Jeff Davis County",
    "Jefferson",
    "Jefferson County",
    "Jersey Village",
    "Jewett",
    "Jim Hogg County",
    "Jim Wells County",
    "Johnson City",
    "Johnson County",
    "Jollyville",
    "Jones County",
    "Jones Creek",
    "Jonestown",
    "Josephine",
    "Joshua",
    "Jourdanton",
    "Junction",
    "Justin",
    "Karnes City",
    "Karnes County",
    "Katy",
    "Kaufman",
    "Kaufman County",
    "Keene",
    "Keller",
    "Kemah",
    "Kemp",
    "Kempner",
    "Kendall County",
    "Kenedy",
    "Kenedy County",
    "Kennedale",
    "Kent County",
    "Kerens",
    "Kermit",
    "Kerr County",
    "Kerrville",
    "Kilgore",
    "Killeen",
    "Kimble County",
    "King County",
    "Kingsland",
    "Kingsville",
    "Kingwood Area",
    "Kinney County",
    "Kirby",
    "Kirbyville",
    "Kleberg County",
    "Knox City",
    "Knox County",
    "Kountze",
    "Krugerville",
    "Krum",
    "Kyle",
    "La Blanca",
    "La Coste",
    "La Feria",
    "La Grange",
    "La Grulla",
    "La Homa",
    "La Joya",
    "La Marque",
    "La Paloma",
    "La Porte",
    "La Pryor",
    "La Salle County",
    "La Vernia",
    "La Villa",
    "Lackland Air Force Base",
    "Lacy-Lakeview",
    "Lago Vista",
    "Laguna Heights",
    "Laguna Park",
    "Laguna Vista",
    "Lake Brownwood",
    "Lake Dallas",
    "Lake Dunlap",
    "Lake Jackson",
    "Lake Kiowa",
    "Lake Worth",
    "Lakehills",
    "Lakeside",
    "Lakeway",
    "Lamar County",
    "Lamb County",
    "Lamesa",
    "Lampasas",
    "Lampasas County",
    "Lancaster",
    "Lantana",
    "Laredo",
    "Las Lomas",
    "Las Palmas II",
    "Las Quintas Fronterizas",
    "Las Quintas Fronterizas Colonia",
    "Lasara",
    "Laughlin Air Force Base",
    "Laureles",
    "Lavaca County",
    "Lavon",
    "League City",
    "Leakey",
    "Leander",
    "Lee County",
    "Leon County",
    "Leon Valley",
    "Leonard",
    "Levelland",
    "Lewisville",
    "Lexington",
    "Liberty",
    "Liberty City",
    "Liberty County",
    "Liberty Hill",
    "Limestone County",
    "Lindale",
    "Linden",
    "Lindsay",
    "Lipscomb County",
    "Little Elm",
    "Little River-Academy",
    "Littlefield",
    "Live Oak",
    "Live Oak County",
    "Livingston",
    "Llano",
    "Llano County",
    "Llano Grande",
    "Lockhart",
    "Lockney",
    "Lone Star",
    "Longview",
    "Lopezville",
    "Lorena",
    "Lorenzo",
    "Los Fresnos",
    "Los Indios",
    "Lost Creek",
    "Loving County",
    "Lowry Crossing",
    "Lubbock",
    "Lubbock County",
    "Lucas",
    "Lufkin",
    "Luling",
    "Lumberton",
    "Lyford",
    "Lynn County",
    "Lytle",
    "Mabank",
    "Madison County",
    "Madisonville",
    "Magnolia",
    "Malakoff",
    "Manchaca",
    "Manor",
    "Mansfield",
    "Manvel",
    "Marble Falls",
    "Marfa",
    "Marion",
    "Marion County",
    "Markham",
    "Marlin",
    "Marshall",
    "Mart",
    "Martin County",
    "Martindale",
    "Mason",
    "Mason County",
    "Matador",
    "Matagorda County",
    "Mathis",
    "Maud",
    "Mauriceville",
    "Maverick County",
    "McAllen",
    "McCamey",
    "McCulloch County",
    "McGregor",
    "McKinney",
    "McLendon-Chisholm",
    "McLennan County",
    "McMullen County",
    "McQueeney",
    "Meadowlakes",
    "Meadows Place",
    "Medina",
    "Medina County",
    "Melissa",
    "Memphis",
    "Menard",
    "Menard County",
    "Mentone",
    "Mercedes",
    "Meridian",
    "Merkel",
    "Mertzon",
    "Mesquite",
    "Mexia",
    "Miami",
    "Midland",
    "Midland County",
    "Midlothian",
    "Midway North",
    "Midway South",
    "Mila Doce",
    "Milam",
    "Milam County",
    "Mills County",
    "Mineola",
    "Mineral Wells",
    "Mission",
    "Mission Bend",
    "Missouri City",
    "Mitchell County",
    "Monahans",
    "Mont Belvieu",
    "Montague",
    "Montague County",
    "Monte Alto",
    "Montgomery County",
    "Moody",
    "Moore County",
    "Morgans Point Resort",
    "Morris County",
    "Morton",
    "Motley County",
    "Mount Pleasant",
    "Mount Vernon",
    "Muenster",
    "Muleshoe",
    "Munday",
    "Muniz",
    "Murillo Colonia",
    "Murphy",
    "Nacogdoches",
    "Nacogdoches County",
    "Naples",
    "Nash",
    "Nassau Bay",
    "Natalia",
    "Navarro County",
    "Navasota",
    "Nederland",
    "Needville",
    "Nevada",
    "New Boston",
    "New Braunfels",
    "New Summerfield",
    "New Territory",
    "New Waverly",
    "Newark",
    "Newton",
    "Newton County",
    "Nixon",
    "Nocona",
    "Nolan County",
    "Nolanville",
    "North Alamo",
    "North Richland Hills",
    "Northcliff",
    "Northcrest",
    "Northlake",
    "Nueces County",
    "Nurillo",
    "Oak Cliff Place",
    "Oak Leaf",
    "Oak Point",
    "Oak Ridge North",
    "Oak Trail Shores",
    "Ochiltree County",
    "Odem",
    "Odessa",
    "Old River-Winfree",
    "Oldham County",
    "Olivarez",
    "Olmito",
    "Olmos Park",
    "Olney",
    "Olton",
    "Onalaska",
    "Onion Creek",
    "Orange",
    "Orange County",
    "Orange Grove",
    "Ore City",
    "Overton",
    "Ovilla",
    "Oyster Creek",
    "Ozona",
    "Paducah",
    "Paint Rock",
    "Palacios",
    "Palestine",
    "Palm Valley",
    "Palmer",
    "Palmhurst",
    "Palmview",
    "Palmview South",
    "Palo Pinto",
    "Palo Pinto County",
    "Paloma Creek",
    "Paloma Creek South",
    "Pampa",
    "Panhandle",
    "Panola County",
    "Panorama Village",
    "Pantego",
    "Paris",
    "Parker",
    "Parker County",
    "Parmer County",
    "Pasadena",
    "Patton Village",
    "Pearland",
    "Pearsall",
    "Pecan Acres",
    "Pecan Grove",
    "Pecan Plantation",
    "Pecos",
    "Pecos County",
    "Pelican Bay",
    "Penitas",
    "Perezville",
    "Perryton",
    "Petersburg",
    "Pflugerville",
    "Pharr",
    "Pilot Point",
    "Pine Island",
    "Pinehurst",
    "Pinewood Estates",
    "Piney Point Village",
    "Pittsburg",
    "Plains",
    "Plainview",
    "Plano",
    "Pleak",
    "Pleasanton",
    "Plum Grove",
    "Polk County",
    "Ponder",
    "Port Aransas",
    "Port Arthur",
    "Port Isabel",
    "Port Lavaca",
    "Port Neches",
    "Port O'Connor",
    "Porter Heights",
    "Portland",
    "Post",
    "Poteet",
    "Poth",
    "Potosi",
    "Potter County",
    "Pottsboro",
    "Powderly",
    "Prairie View",
    "Premont",
    "Presidio",
    "Presidio County",
    "Preston",
    "Primera",
    "Princeton",
    "Progreso",
    "Prosper",
    "Providence",
    "Quail Creek",
    "Quanah",
    "Queen City",
    "Quinlan",
    "Quitman",
    "Rains County",
    "Ralls",
    "Rancho Alegre",
    "Rancho Viejo",
    "Randall County",
    "Ranger",
    "Rankin",
    "Ransom Canyon",
    "Raymondville",
    "Reagan County",
    "Real County",
    "Red Lick",
    "Red Oak",
    "Red River County",
    "Redland",
    "Redwater",
    "Redwood",
    "Reeves County",
    "Refugio",
    "Refugio County",
    "Rendon",
    "Reno",
    "Rhome",
    "Ricardo",
    "Richardson",
    "Richland Hills",
    "Richmond",
    "Richwood",
    "Riesel",
    "Rio Bravo",
    "Rio Grande City",
    "Rio Hondo",
    "River Oaks",
    "Roanoke",
    "Robert Lee",
    "Roberts County",
    "Robertson County",
    "Robinson",
    "Robstown",
    "Roby",
    "Rockdale",
    "Rockport",
    "Rocksprings",
    "Rockwall",
    "Rockwall County",
    "Rogers",
    "Rollingwood",
    "Roma",
    "Roma-Los Saenz",
    "Roman Forest",
    "Roscoe",
    "Rosebud",
    "Rosenberg",
    "Rosharon",
    "Rosita North",
    "Rosita South",
    "Rotan",
    "Round Rock",
    "Rowlett",
    "Royse City",
    "Runaway Bay",
    "Runge",
    "Runnels County",
    "Rusk",
    "Rusk County",
    "Sabinal",
    "Sabine County",
    "Sachse",
    "Saginaw",
    "Saint Hedwig",
    "Saint Jo",
    "Saint Paul",
    "Salado",
    "Sam Rayburn",
    "San Angelo",
    "San Antonio",
    "San Augustine",
    "San Augustine County",
    "San Benito",
    "San Carlos",
    "San Diego",
    "San Elizario",
    "San Jacinto County",
    "San Juan",
    "San Leon",
    "San Marcos",
    "San Patricio County",
    "San Saba",
    "San Saba County",
    "Sanderson",
    "Sanger",
    "Sansom Park",
    "Santa Anna",
    "Santa Fe",
    "Santa Rosa",
    "Sarita",
    "Savannah",
    "Scenic Oaks",
    "Schertz",
    "Schleicher County",
    "Schulenburg",
    "Scissors",
    "Scurry County",
    "Seabrook",
    "Seadrift",
    "Seagoville",
    "Seagraves",
    "Sealy",
    "Sebastian",
    "Seguin",
    "Selma",
    "Seminole",
    "Serenada",
    "Seth Ward",
    "Seven Points",
    "Seymour",
    "Shackelford County",
    "Shady Hollow",
    "Shady Shores",
    "Shallowater",
    "Shamrock",
    "Shavano Park",
    "Shelby County",
    "Sheldon",
    "Shenandoah",
    "Shepherd",
    "Sherman",
    "Sherman County",
    "Sherwood Shores",
    "Shiner",
    "Shoreacres",
    "Sienna Plantation",
    "Sierra Blanca",
    "Siesta Acres",
    "Siesta Shores",
    "Silsbee",
    "Silverton",
    "Sinton",
    "Slaton",
    "Smith County",
    "Smithville",
    "Snyder",
    "Socorro",
    "Socorro Mission Number 1 Colonia",
    "Somerset",
    "Somervell County",
    "Somerville",
    "Sonora",
    "Sour Lake",
    "South Alamo",
    "South Houston",
    "South Padre Island",
    "South Point",
    "Southlake",
    "Southmayd",
    "Southside Place",
    "Sparks",
    "Spearman",
    "Splendora",
    "Spring",
    "Spring Valley",
    "Springtown",
    "Spur",
    "Stafford",
    "Stamford",
    "Stanton",
    "Starr County",
    "Stephens County",
    "Stephenville",
    "Sterling City",
    "Sterling County",
    "Stinnett",
    "Stockdale",
    "Stonewall County",
    "Stowell",
    "Stratford",
    "Sugar Land",
    "Sullivan City",
    "Sulphur Springs",
    "Sundown",
    "Sunnyvale",
    "Sunray",
    "Sutton County",
    "Sweeny",
    "Sweetwater",
    "Swisher County",
    "Taft",
    "Taft Southwest (historical)",
    "Tahoka",
    "Talty",
    "Tarrant County",
    "Tatum",
    "Taylor",
    "Taylor County",
    "Taylor Lake Village",
    "Teague",
    "Temple",
    "Tenaha",
    "Terrell",
    "Terrell County",
    "Terrell Hills",
    "Terry County",
    "Texarkana",
    "Texas City",
    "The Colony",
    "The Hills",
    "The Woodlands",
    "Thorndale",
    "Three Rivers",
    "Throckmorton",
    "Throckmorton County",
    "Tiki Island",
    "Tilden",
    "Timberwood Park",
    "Timpson",
    "Titus County",
    "Tom Bean",
    "Tom Green County",
    "Tomball",
    "Tool",
    "Tornillo",
    "Travis County",
    "Travis Ranch",
    "Trinity",
    "Trinity County",
    "Trophy Club",
    "Troup",
    "Troy",
    "Tulia",
    "Tye",
    "Tyler",
    "Tyler County",
    "Uhland",
    "Universal City",
    "University Park",
    "Upshur County",
    "Upton County",
    "Uvalde",
    "Uvalde County",
    "Uvalde Estates",
    "Val Verde County",
    "Val Verde Park",
    "Valley Mills",
    "Van",
    "Van Alstyne",
    "Van Horn",
    "Van Vleck",
    "Van Zandt County",
    "Vega",
    "Venus",
    "Vernon",
    "Victoria",
    "Victoria County",
    "Vidor",
    "Vinton",
    "Von Ormy",
    "Waco",
    "Wake Village",
    "Walker County",
    "Waller",
    "Waller County",
    "Wallis",
    "Ward County",
    "Washington County",
    "Waskom",
    "Watauga",
    "Waxahachie",
    "Weatherford",
    "Webb County",
    "Webster",
    "Weimar",
    "Wellington",
    "Wells Branch",
    "Weslaco",
    "West",
    "West Columbia",
    "West Lake Hills",
    "West Livingston",
    "West Odessa",
    "West Orange",
    "West Sharyland",
    "West Tawakoni",
    "West University Place",
    "Western Lake",
    "Westlake",
    "Weston Lakes",
    "Westway",
    "Westworth",
    "Wharton",
    "Wharton County",
    "Wheeler",
    "Wheeler County",
    "White Oak",
    "White Settlement",
    "Whitehouse",
    "Whitesboro",
    "Whitewright",
    "Whitney",
    "Wichita County",
    "Wichita Falls",
    "Wilbarger County",
    "Wild Peach Village",
    "Wildwood",
    "Willacy County",
    "Williamson County",
    "Willis",
    "Willow Park",
    "Wills Point",
    "Wilmer",
    "Wilson County",
    "Wimberley",
    "Windcrest",
    "Windemere",
    "Wink",
    "Winkler County",
    "Winnie",
    "Winnsboro",
    "Winters",
    "Wise County",
    "Wolfe City",
    "Wolfforth",
    "Wood County",
    "Woodbranch",
    "Woodcreek",
    "Woodsboro",
    "Woodville",
    "Woodway",
    "Wortham",
    "Wyldwood",
    "Wylie",
    "Yoakum",
    "Yoakum County",
    "Yorktown",
    "Young County",
    "Zapata",
    "Zapata County",
    "Zavala County"
  ],
  "Utah": [
    "Alpine",
    "American Fork",
    "Aurora",
    "Ballard",
    "Beaver",
    "Beaver County",
    "Benjamin",
    "Benson",
    "Blanding",
    "Bluffdale",
    "Bountiful",
    "Box Elder County",
    "Brigham City",
    "Cache County",
    "Canyon Rim",
    "Carbon County",
    "Carbonville",
    "Castle Dale",
    "Cedar City",
    "Cedar Hills",
    "Centerfield",
    "Centerville",
    "Clearfield",
    "Clinton",
    "Coalville",
    "Cottonwood Heights",
    "Daggett County",
    "Daniel",
    "Davis County",
    "Delta",
    "Draper",
    "Duchesne",
    "Duchesne County",
    "Eagle Mountain",
    "East Carbon City",
    "East Millcreek",
    "Elk Ridge",
    "Elwood",
    "Emery County",
    "Enoch",
    "Enterprise",
    "Ephraim",
    "Erda",
    "Fairview",
    "Farmington",
    "Farr West",
    "Ferron",
    "Fillmore",
    "Fountain Green",
    "Francis",
    "Fruit Heights",
    "Garfield County",
    "Garland",
    "Genola",
    "Grand County",
    "Granite",
    "Grantsville",
    "Gunnison",
    "Harrisville",
    "Heber City",
    "Helper",
    "Herriman",
    "Highland",
    "Hildale",
    "Hill Air Force Base",
    "Holladay",
    "Honeyville",
    "Hooper",
    "Huntington",
    "Hurricane",
    "Hyde Park",
    "Hyrum",
    "Iron County",
    "Ivins",
    "Juab County",
    "Junction",
    "Kamas",
    "Kanab",
    "Kane County",
    "Kaysville",
    "Kearns",
    "LaVerkin",
    "Layton",
    "Lehi",
    "Lewiston",
    "Liberty",
    "Lindon",
    "Little Cottonwood Creek Valley",
    "Loa",
    "Logan",
    "Maeser",
    "Magna",
    "Manila",
    "Manti",
    "Mapleton",
    "Marriott-Slaterville",
    "Mendon",
    "Midvale",
    "Midway",
    "Milford",
    "Millard County",
    "Millcreek",
    "Millville",
    "Moab",
    "Mona",
    "Monroe",
    "Monticello",
    "Morgan",
    "Morgan County",
    "Moroni",
    "Mount Olympus",
    "Mount Pleasant",
    "Mountain Green",
    "Murray",
    "Naples",
    "Nephi",
    "Nibley",
    "North Logan",
    "North Ogden",
    "North Salt Lake",
    "Oakley",
    "Ogden",
    "Oquirrh",
    "Orangeville",
    "Orem",
    "Panguitch",
    "Park City",
    "Parowan",
    "Payson",
    "Perry",
    "Piute County",
    "Plain City",
    "Pleasant Grove",
    "Pleasant View",
    "Price",
    "Providence",
    "Provo",
    "Randolph",
    "Rich County",
    "Richfield",
    "Richmond",
    "River Heights",
    "Riverdale",
    "Riverton",
    "Roosevelt",
    "Roy",
    "Saint George",
    "Salem",
    "Salina",
    "Salt Lake City",
    "Salt Lake County",
    "San Juan County",
    "Sandy",
    "Sandy Hills",
    "Sanpete County",
    "Santa Clara",
    "Santaquin",
    "Saratoga Springs",
    "Sevier County",
    "Silver Summit",
    "Smithfield",
    "Snyderville",
    "South Jordan",
    "South Jordan Heights",
    "South Ogden",
    "South Salt Lake",
    "South Weber",
    "South Willard",
    "Spanish Fork",
    "Spring City",
    "Spring Glen",
    "Springville",
    "Stansbury park",
    "Summit County",
    "Summit Park",
    "Sunset",
    "Syracuse",
    "Taylorsville",
    "Tooele",
    "Tooele County",
    "Toquerville",
    "Tremonton",
    "Uintah",
    "Uintah County",
    "Utah County",
    "Vernal",
    "Vineyard",
    "Wasatch County",
    "Washington",
    "Washington County",
    "Washington Terrace",
    "Wayne County",
    "Weber County",
    "Wellington",
    "Wellsville",
    "Wendover",
    "West Bountiful",
    "West Haven",
    "West Jordan",
    "West Mountain",
    "West Point",
    "West Valley City",
    "White City",
    "Willard",
    "Wolf Creek",
    "Woodland Hills",
    "Woods Cross"
  ],
  "Vermont": [
    "Addison",
    "Addison County",
    "Arlington",
    "Barre",
    "Bellows Falls",
    "Bennington",
    "Bennington County",
    "Brandon",
    "Brattleboro",
    "Bridport",
    "Bristol",
    "Burlington",
    "Caledonia County",
    "Castleton",
    "Charlotte",
    "Chelsea",
    "Chester",
    "Chittenden",
    "Chittenden County",
    "Clarendon",
    "Colchester",
    "Danby",
    "Dover",
    "Enosburg Falls",
    "Essex County",
    "Essex Junction",
    "Fair Haven",
    "Ferrisburgh",
    "Franklin County",
    "Grand Isle County",
    "Guildhall",
    "Hardwick",
    "Hartford",
    "Hinesburg",
    "Hyde Park",
    "Jamaica",
    "Jericho",
    "Johnson",
    "Lamoille County",
    "Leicester",
    "Lincoln",
    "Londonderry",
    "Lunenburg",
    "Lyndon",
    "Lyndonville",
    "Manchester Center",
    "Mendon",
    "Middlebury (village)",
    "Milton",
    "Montgomery",
    "Montpelier",
    "Moretown",
    "Morristown",
    "Morrisville",
    "Mount Holly",
    "Newfane",
    "Newport",
    "North Bennington",
    "North Hero",
    "Northfield",
    "Orange County",
    "Orleans County",
    "Pawlet",
    "Poultney",
    "Pownal",
    "Randolph",
    "Richford",
    "Rockingham",
    "Rutland",
    "Rutland County",
    "Saint Albans",
    "Saint Johnsbury",
    "Salisbury",
    "South Barre",
    "South Burlington",
    "Springfield",
    "St Johnsbury",
    "Starksboro",
    "Stowe",
    "Swanton",
    "Townshend",
    "Vergennes",
    "Washington",
    "Washington County",
    "Waterbury",
    "West Brattleboro",
    "West Rutland",
    "White River Junction",
    "Wilder",
    "Williamstown",
    "Williston",
    "Windham County",
    "Windsor",
    "Windsor County",
    "Winooski",
    "Woodstock"
  ],
  "Virginia": [
    "Abingdon",
    "Accomac",
    "Accomack County",
    "Adwolf",
    "Albemarle County",
    "Alexandria",
    "Alleghany County",
    "Altavista",
    "Amelia County",
    "Amelia Court House",
    "Amherst",
    "Amherst County",
    "Annandale",
    "Appalachia",
    "Apple Mountain Lake",
    "Appomattox",
    "Appomattox County",
    "Aquia Harbour",
    "Arlington",
    "Arlington County",
    "Ashburn",
    "Ashland",
    "Atkins",
    "Augusta County",
    "Baileys Crossroads",
    "Bassett",
    "Basye",
    "Bath County",
    "Bealeton",
    "Bedford",
    "Bedford County",
    "Belle Haven",
    "Bellwood",
    "Belmont",
    "Belmont Estates",
    "Bensley",
    "Berryville",
    "Big Stone Gap",
    "Blacksburg",
    "Blackstone",
    "Bland",
    "Bland County",
    "Blue Ridge",
    "Bluefield",
    "Bon Air",
    "Boswell's Corner",
    "Botetourt County",
    "Bowling Green",
    "Boydton",
    "Bracey",
    "Brambleton",
    "Brandermill",
    "Bridgewater",
    "Brightwood",
    "Bristol",
    "Broadlands",
    "Broadway",
    "Brookneal",
    "Brunswick County",
    "Buchanan",
    "Buchanan County",
    "Buckhall",
    "Buckingham",
    "Buckingham County",
    "Buena Vista",
    "Bull Run",
    "Bull Run Mountain Estates",
    "Burke",
    "Campbell County",
    "Cana",
    "Cape Charles",
    "Captains Cove",
    "Caroline County",
    "Carroll County",
    "Carrollton",
    "Castlewood",
    "Cave Spring",
    "Cedar Bluff",
    "Central Garage",
    "Centreville",
    "Chamberlayne",
    "Chantilly",
    "Charles City",
    "Charles City County",
    "Charlotte County",
    "Charlotte Court House",
    "Charlottesville",
    "Chase City",
    "Chatham",
    "Chatmoss",
    "Cherry Hill",
    "Chesapeake",
    "Chester",
    "Chesterfield County",
    "Chesterfield Court House",
    "Chilhowie",
    "Chincoteague",
    "Christiansburg",
    "City of Alexandria",
    "City of Bedford",
    "City of Bristol",
    "City of Buena Vista",
    "City of Charlottesville",
    "City of Chesapeake",
    "City of Colonial Heights",
    "City of Covington",
    "City of Danville",
    "City of Emporia",
    "City of Fairfax",
    "City of Falls Church",
    "City of Franklin",
    "City of Fredericksburg",
    "City of Galax",
    "City of Hampton",
    "City of Harrisonburg",
    "City of Hopewell",
    "City of Lexington",
    "City of Lynchburg",
    "City of Manassas",
    "City of Manassas Park",
    "City of Martinsville",
    "City of Newport News",
    "City of Norfolk",
    "City of Norton",
    "City of Petersburg",
    "City of Poquoson",
    "City of Portsmouth",
    "City of Radford",
    "City of Richmond",
    "City of Roanoke",
    "City of Salem",
    "City of Staunton",
    "City of Suffolk",
    "City of Virginia Beach",
    "City of Waynesboro",
    "City of Williamsburg",
    "City of Winchester",
    "Clarke County",
    "Clarksville",
    "Claypool Hill",
    "Clifton Forge",
    "Clintwood",
    "Cloverdale",
    "Coeburn",
    "Collinsville",
    "Colonial Beach",
    "Colonial Heights",
    "Concord",
    "Countryside",
    "Courtland",
    "Covington",
    "Craig County",
    "Crewe",
    "Crimora",
    "Crozet",
    "Culpeper",
    "Culpeper County",
    "Cumberland",
    "Cumberland County",
    "Dahlgren",
    "Dale City",
    "Daleville",
    "Danville",
    "Dayton",
    "Deltaville",
    "Dickenson County",
    "Dinwiddie County",
    "Dooms",
    "Dranesville",
    "Dryden",
    "Dublin",
    "Dulles Town Center",
    "Dumbarton",
    "Dumfries",
    "Dunn Loring",
    "East Hampton",
    "East Highland Park",
    "East Lexington",
    "Eastville",
    "Edinburg",
    "Elkton",
    "Emory",
    "Emporia",
    "Enon",
    "Essex County",
    "Ettrick",
    "Exmore",
    "Fairfax",
    "Fairfax County",
    "Fairfax Station",
    "Fairlawn",
    "Falls Church",
    "Falmouth",
    "Farmville",
    "Fauquier County",
    "Ferrum",
    "Fincastle",
    "Fishersville",
    "Floris",
    "Floyd",
    "Floyd County",
    "Fluvanna County",
    "Forest",
    "Fort Belvoir",
    "Fort Hunt",
    "Fort Lee",
    "Franconia",
    "Franklin",
    "Franklin County",
    "Frederick County",
    "Fredericksburg",
    "Front Royal",
    "Gainesville",
    "Galax",
    "Gate City",
    "Giles County",
    "Glade Spring",
    "Glasgow",
    "Glen Allen",
    "Gloucester County",
    "Gloucester Courthouse",
    "Gloucester Point",
    "Goochland",
    "Goochland County",
    "Gordonsville",
    "Grayson County",
    "Great Falls",
    "Greenbriar",
    "Greene County",
    "Greensville County",
    "Gretna",
    "Grottoes",
    "Groveton",
    "Grundy",
    "Halifax",
    "Halifax County",
    "Hampden Sydney",
    "Hampton",
    "Hanover",
    "Hanover County",
    "Harrisonburg",
    "Hayfield",
    "Haymarket",
    "Heathsville",
    "Henrico County",
    "Henry County",
    "Henry Fork",
    "Herndon",
    "Highland County",
    "Highland Springs",
    "Hillsville",
    "Hollins",
    "Hollymead",
    "Honaker",
    "Hopewell",
    "Horse Pasture",
    "Huntington",
    "Hurt",
    "Hybla Valley",
    "Idylwood",
    "Independence",
    "Independent Hill",
    "Isle of Wight County",
    "James City County",
    "Jefferson",
    "Jolivue",
    "Jonesville",
    "Kenbridge",
    "Kilmarnock",
    "King George",
    "King George County",
    "King William",
    "King William County",
    "King and Queen County",
    "King and Queen Court House",
    "Kings Park",
    "Kings Park West",
    "Lake Barcroft",
    "Lake Monticello",
    "Lake Ridge",
    "Lakeside",
    "Lancaster County",
    "Laurel",
    "Laurel Hill",
    "Lawrenceville",
    "Laymantown",
    "Lebanon",
    "Lee County",
    "Leesburg",
    "Lexington",
    "Lincolnia",
    "Linton Hall",
    "Loch Lomond",
    "Lorton",
    "Loudoun County",
    "Loudoun Valley Estates",
    "Louisa",
    "Louisa County",
    "Lovettsville",
    "Lovingston",
    "Lowes Island",
    "Lunenburg",
    "Lunenburg County",
    "Luray",
    "Lynchburg",
    "Lyndhurst",
    "Madison",
    "Madison County",
    "Madison Heights",
    "Manassas",
    "Manassas Park",
    "Mantua",
    "Marion",
    "Marshall",
    "Martinsville",
    "Massanetta Springs",
    "Massanutten",
    "Mathews",
    "Mathews County",
    "Matoaca",
    "McLean",
    "Meadowbrook",
    "Mechanicsville",
    "Mecklenburg County",
    "Merrifield",
    "Merrimac",
    "Middlesex County",
    "Middletown",
    "Montclair",
    "Monterey",
    "Montgomery County",
    "Montrose",
    "Montross",
    "Motley",
    "Mount Hermon",
    "Mount Jackson",
    "Mount Vernon",
    "Mountain Road",
    "Narrows",
    "Nellysford",
    "Nelson County",
    "New Baltimore",
    "New Castle",
    "New Kent",
    "New Kent County",
    "New Market",
    "Newington",
    "Newport News",
    "Nokesville",
    "Norfolk",
    "North Shore",
    "North Springfield",
    "Northampton County",
    "Northumberland County",
    "Norton",
    "Nottoway County",
    "Oak Grove",
    "Oak Hill",
    "Oakton",
    "Occoquan",
    "Onancock",
    "Orange",
    "Orange County",
    "Page County",
    "Palmyra",
    "Pannill Fork",
    "Pantops",
    "Passapatanzy",
    "Patrick County",
    "Patrick Springs",
    "Pearisburg",
    "Pembroke",
    "Pennington Gap",
    "Petersburg",
    "Pimmit Hills",
    "Pittsylvania County",
    "Plum Creek",
    "Poquoson",
    "Portsmouth",
    "Portsmouth Heights",
    "Potomac Mills",
    "Powhatan County",
    "Prices Fork",
    "Prince Edward County",
    "Prince George",
    "Prince George County",
    "Prince William County",
    "Pulaski",
    "Pulaski County",
    "Purcellville",
    "Quantico Station",
    "Radford",
    "Rappahannock County",
    "Raven",
    "Ravensworth",
    "Reston",
    "Richlands",
    "Richmond",
    "Richmond County",
    "Roanoke",
    "Roanoke County",
    "Rockbridge County",
    "Rockingham County",
    "Rocky Mount",
    "Rose Hill",
    "Rosslyn",
    "Ruckersville",
    "Rural Retreat",
    "Rushmere",
    "Russell County",
    "Rustburg",
    "Salem",
    "Saltville",
    "Saluda",
    "Sandston",
    "Scott County",
    "Seven Corners",
    "Shawnee Land",
    "Shawsville",
    "Shenandoah",
    "Shenandoah County",
    "Shenandoah Farms",
    "Short Pump",
    "Smithfield",
    "Smyth County",
    "South Boston",
    "South Hill",
    "South Riding",
    "South Suffolk",
    "Southampton County",
    "Southern Gateway",
    "Spotsylvania County",
    "Spotsylvania Courthouse",
    "Springfield",
    "Springville",
    "Stafford",
    "Stafford County",
    "Stanardsville",
    "Stanley",
    "Stanleytown",
    "Staunton",
    "Stephens City",
    "Sterling",
    "Strasburg",
    "Stuart",
    "Stuarts Draft",
    "Sudley",
    "Suffolk",
    "Sugarland Run",
    "Surry",
    "Surry County",
    "Sussex",
    "Sussex County",
    "Tappahannock",
    "Tazewell",
    "Tazewell County",
    "Timberlake",
    "Timberville",
    "Triangle",
    "Tuckahoe",
    "Twin Lakes",
    "Tysons Corner",
    "Union Hall",
    "University Center",
    "Verona",
    "Victoria",
    "Vienna",
    "Vinton",
    "Virginia Beach",
    "Warm Springs",
    "Warren County",
    "Warrenton",
    "Warsaw",
    "Washington",
    "Washington County",
    "Wattsville",
    "Waverly",
    "Waynesboro",
    "Weber City",
    "West Falls Church",
    "West Gate",
    "West Lynchburg",
    "West Point",
    "West Springfield",
    "Westmoreland County",
    "Weyers Cave",
    "Williamsburg",
    "Winchester",
    "Windsor",
    "Wise",
    "Wise County",
    "Wolf Trap",
    "Woodbridge",
    "Woodburn",
    "Woodlake",
    "Woodlawn",
    "Woodstock",
    "Wyndham",
    "Wythe County",
    "Wytheville",
    "York County",
    "Yorkshire",
    "Yorktown"
  ],
  "Washington": [
    "Aberdeen",
    "Adams County",
    "Ahtanum",
    "Airway Heights",
    "Alderton",
    "Alderwood Manor",
    "Algona",
    "Allyn",
    "Amboy",
    "Ames Lake",
    "Anacortes",
    "Arlington",
    "Arlington Heights",
    "Artondale",
    "Asotin",
    "Asotin County",
    "Auburn",
    "Ault Field",
    "Bainbridge Island",
    "Bangor Trident Base",
    "Barberton",
    "Basin City",
    "Battle Ground",
    "Belfair",
    "Bellevue",
    "Bellingham",
    "Benton City",
    "Benton County",
    "Bethel",
    "Big Lake",
    "Birch Bay",
    "Black Diamond",
    "Blaine",
    "Bonney Lake",
    "Bothell",
    "Bothell East",
    "Bothell West",
    "Boulevard Park",
    "Bremerton",
    "Brewster",
    "Bridgeport",
    "Brier",
    "Browns Point",
    "Brush Prairie",
    "Bryant",
    "Bryn Mawr-Skyway",
    "Buckley",
    "Bunk Foss",
    "Burbank",
    "Burien",
    "Burley",
    "Burlington",
    "Camano",
    "Camas",
    "Canterwood",
    "Carnation",
    "Carson",
    "Cascade Valley",
    "Cashmere",
    "Castle Rock",
    "Cathcart",
    "Cathlamet",
    "Central Park",
    "Centralia",
    "Chehalis",
    "Chelan",
    "Chelan County",
    "Cheney",
    "Chewelah",
    "Chico",
    "City of Sammamish",
    "Clallam County",
    "Clark County",
    "Clarkston",
    "Clarkston Heights-Vineland",
    "Cle Elum",
    "Clear Lake",
    "Clearview",
    "Clyde Hill",
    "Colfax",
    "College Place",
    "Columbia County",
    "Colville",
    "Connell",
    "Cosmopolis",
    "Cottage Lake",
    "Coulee Dam",
    "Country Homes",
    "Coupeville",
    "Covington",
    "Cowlitz County",
    "Crocker",
    "Dallesport",
    "Darrington",
    "Davenport",
    "Dayton",
    "Deer Park",
    "Des Moines",
    "Desert Aire",
    "Dishman",
    "Dollar Corner",
    "Douglas County",
    "DuPont",
    "Duvall",
    "East Hill-Meridian",
    "East Port Orchard",
    "East Renton Highlands",
    "East Wenatchee",
    "East Wenatchee Bench",
    "Eastgate",
    "Eastmont",
    "Eatonville",
    "Edgewood",
    "Edmonds",
    "Electric City",
    "Elk Plain",
    "Ellensburg",
    "Elma",
    "Enetai",
    "Entiat",
    "Enumclaw",
    "Ephrata",
    "Erlands Point-Kitsap Lake",
    "Esperance",
    "Everett",
    "Everson",
    "Fairchild Air Force Base",
    "Fairwood",
    "Fall City",
    "Federal Way",
    "Felida",
    "Fern Prairie",
    "Ferndale",
    "Ferry County",
    "Fife",
    "Fife Heights",
    "Finley",
    "Fircrest",
    "Five Corners",
    "Fobes Hill",
    "Fords Prairie",
    "Forks",
    "Fox Island",
    "Franklin County",
    "Frederickson",
    "Freeland",
    "Friday Harbor",
    "Garfield County",
    "Garrett",
    "Geneva",
    "Gig Harbor",
    "Gleed",
    "Gold Bar",
    "Goldendale",
    "Graham",
    "Grand Coulee",
    "Grand Mound",
    "Grandview",
    "Granger",
    "Granite Falls",
    "Grant County",
    "Grays Harbor County",
    "Hansville",
    "Hazel Dell",
    "Highland",
    "Hobart",
    "Hockinson",
    "Home",
    "Hoquiam",
    "Indianola",
    "Inglewood-Finn Hill",
    "Island County",
    "Issaquah",
    "Jefferson County",
    "Joint Base Lewis McChord",
    "Kalama",
    "Kelso",
    "Kenmore",
    "Kennewick",
    "Kent",
    "Kettle Falls",
    "Key Center",
    "King County",
    "Kingsgate",
    "Kingston",
    "Kirkland",
    "Kitsap County",
    "Kittitas",
    "Kittitas County",
    "Klahanie",
    "Klickitat County",
    "La Center",
    "Lacey",
    "Lake Forest Park",
    "Lake Marcel-Stillwater",
    "Lake Morton-Berrydale",
    "Lake Shore",
    "Lake Stevens",
    "Lake Stickney",
    "Lakeland North",
    "Lakeland South",
    "Lakewood",
    "Langley",
    "Larch Way",
    "Lea Hill",
    "Leavenworth",
    "Lewis County",
    "Lewisville",
    "Liberty Lake",
    "Lincoln County",
    "Lochsloy",
    "Lofall",
    "Long Beach",
    "Longbranch",
    "Longview",
    "Longview Heights",
    "Lynden",
    "Lynnwood",
    "Mabton",
    "Machias",
    "Maltby",
    "Manchester",
    "Manson",
    "Maple Heights-Lake Desire",
    "Maple Valley",
    "Maplewood",
    "Marietta",
    "Marietta-Alderwood",
    "Martha Lake",
    "Marysville",
    "Mason County",
    "Mattawa",
    "McChord Air Force Base",
    "McCleary",
    "McMillin",
    "Mead",
    "Meadow Glade",
    "Meadowdale",
    "Medical Lake",
    "Medina",
    "Mercer Island",
    "Midland",
    "Mill Creek",
    "Mill Creek East",
    "Mill Plain",
    "Millwood",
    "Milton",
    "Minnehaha",
    "Mirrormont",
    "Monroe",
    "Monroe North",
    "Montesano",
    "Morton",
    "Moses Lake",
    "Moses Lake North",
    "Mount Vernon",
    "Mount Vista",
    "Mountlake Terrace",
    "Mukilteo",
    "Napavine",
    "Navy Yard City",
    "Newcastle",
    "Newport",
    "Nooksack",
    "Normandy Park",
    "North Bend",
    "North Creek",
    "North Fort Lewis",
    "North Puyallup",
    "North Yelm",
    "Oak Harbor",
    "Ocean Park",
    "Ocean Shores",
    "Okanogan",
    "Okanogan County",
    "Olympia",
    "Omak",
    "Opportunity",
    "Orchards",
    "Oroville",
    "Orting",
    "Othello",
    "Otis Orchards-East Farms",
    "Pacific",
    "Pacific County",
    "Palouse",
    "Parkland",
    "Parkwood",
    "Pasco",
    "Peaceful Valley",
    "Pend Oreille County",
    "Picnic Point",
    "Picnic Point-North Lynnwood",
    "Pierce County",
    "Point Roberts",
    "Pomeroy",
    "Port Angeles",
    "Port Angeles East",
    "Port Hadlock-Irondale",
    "Port Ludlow",
    "Port Orchard",
    "Port Townsend",
    "Poulsbo",
    "Prairie Heights",
    "Prairie Ridge",
    "Prosser",
    "Pullman",
    "Purdy",
    "Puyallup",
    "Quincy",
    "Rainier",
    "Ravensdale",
    "Raymond",
    "Redmond",
    "Renton",
    "Republic",
    "Richland",
    "Ridgefield",
    "Ritzville",
    "Riverbend",
    "Riverton",
    "Rochester",
    "Rocky Point",
    "Rosedale",
    "Royal City",
    "Salmon Creek",
    "Sammamish",
    "San Juan County",
    "SeaTac",
    "Seabeck",
    "Seattle",
    "Sedro-Woolley",
    "Selah",
    "Sequim",
    "Shelton",
    "Shoreline",
    "Silver Firs",
    "Silverdale",
    "Sisco Heights",
    "Skagit County",
    "Skamania County",
    "Smokey Point",
    "Snohomish",
    "Snohomish County",
    "Snoqualmie",
    "Soap Lake",
    "South Bend",
    "South Hill",
    "South Wenatchee",
    "Southworth",
    "Spanaway",
    "Spokane",
    "Spokane County",
    "Spokane Valley",
    "Stanwood",
    "Steilacoom",
    "Stevens County",
    "Stevenson",
    "Sudden Valley",
    "Sultan",
    "Sumas",
    "Summit",
    "Summit View",
    "Sumner",
    "Sunnyside",
    "Sunnyslope",
    "Suquamish",
    "Tacoma",
    "Tanglewilde",
    "Tanglewilde-Thompson Place",
    "Tanner",
    "Tenino",
    "Terrace Heights",
    "Three Lakes",
    "Thurston County",
    "Tieton",
    "Tonasket",
    "Toppenish",
    "Town and Country",
    "Tracyton",
    "Trentwood",
    "Tukwila",
    "Tulalip",
    "Tulalip Bay",
    "Tumwater",
    "Union Gap",
    "Union Hill-Novelty Hill",
    "University Place",
    "Vancouver",
    "Vashon",
    "Venersborg",
    "Veradale",
    "Wahkiakum County",
    "Waitsburg",
    "Walla Walla",
    "Walla Walla County",
    "Walla Walla East",
    "Waller",
    "Walnut Grove",
    "Wapato",
    "Warden",
    "Warm Beach",
    "Washougal",
    "Waterville",
    "Wauna",
    "Wenatchee",
    "West Clarkston-Highland",
    "West Lake Sammamish",
    "West Lake Stevens",
    "West Longview",
    "West Pasco",
    "West Richland",
    "West Side Highway",
    "West Valley",
    "West Wenatchee",
    "Westport",
    "Whatcom County",
    "White Center",
    "White Salmon",
    "Whitman County",
    "Wilderness Rim",
    "Winlock",
    "Wollochet",
    "Woodinville",
    "Woodland",
    "Woods Creek",
    "Woodway",
    "Yacolt",
    "Yakima",
    "Yakima County",
    "Yarrow Point",
    "Yelm",
    "Zillah"
  ],
  "West Virginia": [
    "Alderson",
    "Alum Creek",
    "Ansted",
    "Barbour County",
    "Barboursville",
    "Barrackville",
    "Beaver",
    "Beckley",
    "Belington",
    "Belle",
    "Benwood",
    "Berkeley County",
    "Berkeley Springs",
    "Bethany",
    "Bethlehem",
    "Blennerhassett",
    "Bluefield",
    "Bluewell",
    "Boaz",
    "Bolivar",
    "Boone County",
    "Bradley",
    "Braxton County",
    "Bridgeport",
    "Brooke County",
    "Brookhaven",
    "Brush Fork",
    "Buckhannon",
    "Buffalo",
    "Cabell County",
    "Calhoun County",
    "Ceredo",
    "Chapmanville",
    "Charles Town",
    "Charleston",
    "Cheat Lake",
    "Chesapeake",
    "Chester",
    "Clarksburg",
    "Clay",
    "Clay County",
    "Clendenin",
    "Coal City",
    "Coal Fork",
    "Crab Orchard",
    "Craigsville",
    "Cross Lanes",
    "Culloden",
    "Daniels",
    "Despard",
    "Doddridge County",
    "Dunbar",
    "Eleanor",
    "Elizabeth",
    "Elkins",
    "Elkview",
    "Fairlea",
    "Fairmont",
    "Fayette County",
    "Fayetteville",
    "Follansbee",
    "Fort Ashby",
    "Franklin",
    "Gilbert Creek",
    "Gilmer County",
    "Glendale",
    "Glenville",
    "Grafton",
    "Grant County",
    "Grantsville",
    "Granville",
    "Greenbrier County",
    "Hamlin",
    "Hampshire County",
    "Hancock County",
    "Hardy County",
    "Harrison County",
    "Harrisville",
    "Hinton",
    "Hooverson Heights",
    "Huntington",
    "Hurricane",
    "Inwood",
    "Jackson County",
    "Jefferson County",
    "Kanawha County",
    "Kenova",
    "Keyser",
    "Kingwood",
    "Lavalette",
    "Lesage",
    "Lewis County",
    "Lewisburg",
    "Lincoln County",
    "Logan",
    "Logan County",
    "Lubeck",
    "Mabscott",
    "MacArthur",
    "Madison",
    "Mallory",
    "Mannington",
    "Marion County",
    "Marlinton",
    "Marmet",
    "Marshall County",
    "Martinsburg",
    "Mason County",
    "McDowell County",
    "McMechen",
    "Mercer County",
    "Middlebourne",
    "Milton",
    "Mineral County",
    "Mineral Wells",
    "Mingo County",
    "Monongah",
    "Monongalia County",
    "Monroe County",
    "Montgomery",
    "Moorefield",
    "Morgan County",
    "Morgantown",
    "Moundsville",
    "Mount Gay-Shamrock",
    "Mount Hope",
    "Mullens",
    "New Cumberland",
    "New Haven",
    "New Martinsville",
    "Newell",
    "Nicholas County",
    "Nitro",
    "Nutter Fort",
    "Oak Hill",
    "Oceana",
    "Ohio County",
    "Paden City",
    "Parkersburg",
    "Parsons",
    "Pea Ridge",
    "Pendleton County",
    "Pennsboro",
    "Petersburg",
    "Philippi",
    "Pinch",
    "Pineville",
    "Pleasant Valley",
    "Pleasants County",
    "Pocahontas County",
    "Point Pleasant",
    "Preston County",
    "Princeton",
    "Prosperity",
    "Putnam County",
    "Rainelle",
    "Raleigh County",
    "Rand",
    "Randolph County",
    "Ranson",
    "Ravenswood",
    "Richwood",
    "Ripley",
    "Ritchie County",
    "Roane County",
    "Romney",
    "Ronceverte",
    "Saint Albans",
    "Saint Marys",
    "Salem",
    "Shady Spring",
    "Shannondale",
    "Shepherdstown",
    "Shinnston",
    "Sissonville",
    "Sistersville",
    "Sophia",
    "South Charleston",
    "Spencer",
    "Stanaford",
    "Star City",
    "Stonewood",
    "Summers County",
    "Summersville",
    "Sutton",
    "Taylor County",
    "Teays Valley",
    "Terra Alta",
    "Tornado",
    "Tucker County",
    "Tyler County",
    "Union",
    "Upshur County",
    "Vienna",
    "Washington",
    "Wayne",
    "Wayne County",
    "Webster County",
    "Webster Springs",
    "Weirton",
    "Weirton Heights",
    "Welch",
    "Wellsburg",
    "West Liberty",
    "West Union",
    "Weston",
    "Westover",
    "Wetzel County",
    "Wheeling",
    "White Sulphur Springs",
    "Wiley Ford",
    "Williamson",
    "Williamstown",
    "Winfield",
    "Wirt County",
    "Wood County",
    "Wyoming County"
  ],
  "Wisconsin": [
    "Abbotsford",
    "Adams",
    "Adams County",
    "Addison",
    "Albany",
    "Algoma",
    "Allouez",
    "Alma",
    "Alto",
    "Altoona",
    "Amery",
    "Amherst",
    "Antigo",
    "Appleton",
    "Arcadia",
    "Ashford",
    "Ashland",
    "Ashland County",
    "Ashwaubenon",
    "Athens",
    "Augusta",
    "Aztalan",
    "Baldwin",
    "Balsam Lake",
    "Bangor",
    "Baraboo",
    "Barneveld",
    "Barron",
    "Barron County",
    "Barton",
    "Bayfield County",
    "Bayside",
    "Beaver Dam",
    "Belgium",
    "Belle Plaine",
    "Belleville",
    "Bellevue",
    "Beloit",
    "Berlin",
    "Bevent",
    "Big Bend",
    "Black Creek",
    "Black Earth",
    "Black River Falls",
    "Blair",
    "Bloomer",
    "Bohners Lake",
    "Bonduel",
    "Boscobel",
    "Boyceville",
    "Brice Prairie",
    "Brillion",
    "Bristol",
    "Brodhead",
    "Brookfield",
    "Brooklyn",
    "Brothertown",
    "Brown County",
    "Brown Deer",
    "Browns Lake",
    "Brussels",
    "Buffalo County",
    "Burlington",
    "Burnett County",
    "Butler",
    "Cadott",
    "Caledonia",
    "Calumet County",
    "Cambridge",
    "Cameron",
    "Camp Lake",
    "Campbellsport",
    "Cashton",
    "Cato",
    "Cedar Grove",
    "Cedarburg",
    "Chetek",
    "Chilton",
    "Chippewa County",
    "Chippewa Falls",
    "Clark County",
    "Clear Lake",
    "Cleveland",
    "Clinton",
    "Clintonville",
    "Colby",
    "Colfax",
    "Columbia County",
    "Columbus",
    "Combined Locks",
    "Como",
    "Concord",
    "Cooperstown",
    "Cornell",
    "Cottage Grove",
    "Crandon",
    "Crawford County",
    "Cross Plains",
    "Cuba City",
    "Cudahy",
    "Cumberland",
    "Dakota",
    "Dane",
    "Dane County",
    "Darien",
    "Darlington",
    "De Forest",
    "De Pere",
    "Decatur",
    "Deerfield",
    "Delafield",
    "Delavan",
    "Delavan Lake",
    "Denmark",
    "Dickeyville",
    "Dodge County",
    "Dodgeville",
    "Door County",
    "Douglas County",
    "Dousman",
    "Dunn County",
    "Durand",
    "Eagle",
    "Eagle Lake",
    "Eagle River",
    "East Troy",
    "Easton",
    "Eau Claire",
    "Eau Claire County",
    "Edgar",
    "Edgerton",
    "Eldorado",
    "Elkhorn",
    "Ellsworth",
    "Elm Grove",
    "Elroy",
    "Evansville",
    "Evergreen",
    "Fall Creek",
    "Fall River",
    "Fennimore",
    "Fitchburg",
    "Florence",
    "Florence County",
    "Fond du Lac",
    "Fond du Lac County",
    "Fontana",
    "Forest County",
    "Fort Atkinson",
    "Fox Lake",
    "Fox Point",
    "Franklin",
    "Franksville",
    "Frederic",
    "Fredonia",
    "French Island",
    "Friendship",
    "Galesville",
    "Genoa City",
    "Germantown",
    "Gillett",
    "Glendale",
    "Glenmore",
    "Glenwood City",
    "Grafton",
    "Grant County",
    "Grantsburg",
    "Green Bay",
    "Green County",
    "Green Lake",
    "Green Lake County",
    "Greendale",
    "Greenfield",
    "Greenwood",
    "Hales Corners",
    "Hammond",
    "Harrison",
    "Hartford",
    "Hartland",
    "Hayward",
    "Hazel Green",
    "Hilbert",
    "Hillsboro",
    "Hobart",
    "Holmen",
    "Horicon",
    "Hortonville",
    "Howard",
    "Howards Grove",
    "Hudson",
    "Hurley",
    "Hustisford",
    "Independence",
    "Iola",
    "Iowa County",
    "Iron County",
    "Ixonia",
    "Jackson",
    "Jackson County",
    "Janesville",
    "Jefferson",
    "Jefferson County",
    "Johnson Creek",
    "Juneau",
    "Juneau County",
    "Kaukauna",
    "Kenosha",
    "Kenosha County",
    "Keshena",
    "Kewaskum",
    "Kewaunee",
    "Kewaunee County",
    "Kiel",
    "Kimberly",
    "King",
    "Kohler",
    "Kronenwetter",
    "La Crosse",
    "La Crosse County",
    "Lac du Flambeau",
    "Ladysmith",
    "Lafayette County",
    "Lake Delton",
    "Lake Geneva",
    "Lake Hallie",
    "Lake Koshkonong",
    "Lake Mills",
    "Lake Nebagamon",
    "Lake Ripley",
    "Lake Wazeecha",
    "Lake Wisconsin",
    "Lake Wissota",
    "Lamartine",
    "Lancaster",
    "Langlade County",
    "Lannon",
    "Legend Lake",
    "Lincoln County",
    "Little Chute",
    "Little Round Lake",
    "Lodi",
    "Lomira",
    "Loyal",
    "Luck",
    "Luxemburg",
    "Madison",
    "Maine",
    "Manawa",
    "Manchester",
    "Manitowoc",
    "Manitowoc County",
    "Maple Bluff",
    "Marathon",
    "Marathon County",
    "Marinette",
    "Marinette County",
    "Marion",
    "Markesan",
    "Marquette County",
    "Marshall",
    "Marshfield",
    "Mauston",
    "Mayville",
    "Mazomanie",
    "McFarland",
    "Medford",
    "Menasha",
    "Menominee County",
    "Menomonee Falls",
    "Menomonie",
    "Mequon",
    "Merrill",
    "Merton",
    "Middleton",
    "Milford",
    "Milton",
    "Milwaukee",
    "Milwaukee County",
    "Mineral Point",
    "Mishicot",
    "Mondovi",
    "Monona",
    "Monroe",
    "Monroe County",
    "Montello",
    "Monticello",
    "Mosinee",
    "Mount Horeb",
    "Mount Morris",
    "Mount Pleasant",
    "Mukwonago",
    "Muscoda",
    "Muskego",
    "Nashotah",
    "Nashville",
    "Neenah",
    "Neillsville",
    "Nekoosa",
    "New Berlin",
    "New Glarus",
    "New Holstein",
    "New Lisbon",
    "New London",
    "New Richmond",
    "Newburg",
    "Niagara",
    "North Fond du Lac",
    "North Hudson",
    "North La Crosse",
    "North Prairie",
    "Oak Creek",
    "Oakfield",
    "Oconomowoc",
    "Oconto",
    "Oconto County",
    "Oconto Falls",
    "Okauchee Lake",
    "Omro",
    "Onalaska",
    "Oneida",
    "Oneida County",
    "Oostburg",
    "Oregon",
    "Orfordville",
    "Osceola",
    "Oshkosh",
    "Osseo",
    "Outagamie County",
    "Ozaukee County",
    "Paddock Lake",
    "Palmyra",
    "Pardeeville",
    "Park Falls",
    "Pell Lake",
    "Pepin County",
    "Peshtigo",
    "Pewaukee",
    "Phillips",
    "Pierce County",
    "Pittsfield",
    "Platteville",
    "Pleasant Prairie",
    "Plover",
    "Plymouth",
    "Polk County",
    "Port Edwards",
    "Port Washington",
    "Portage",
    "Portage County",
    "Portland",
    "Potter Lake",
    "Powers Lake",
    "Poynette",
    "Prairie du Chien",
    "Prairie du Sac",
    "Prescott",
    "Price County",
    "Princeton",
    "Pulaski",
    "Racine",
    "Racine County",
    "Randolph",
    "Random Lake",
    "Redgranite",
    "Reedsburg",
    "Reedsville",
    "Rhinelander",
    "Rib Mountain",
    "Rice Lake",
    "Richfield",
    "Richland Center",
    "Richland County",
    "Richmond",
    "Rio",
    "Ripon",
    "River Falls",
    "River Hills",
    "Roberts",
    "Rochester",
    "Rock County",
    "Rome",
    "Rosendale",
    "Rothschild",
    "Roxbury",
    "Rusk County",
    "Rutland",
    "Saint Croix County",
    "Saint Croix Falls",
    "Saint Francis",
    "Saint Peter",
    "Salem",
    "Sauk City",
    "Sauk County",
    "Saukville",
    "Sawyer County",
    "Schofield",
    "Seymour",
    "Sharon",
    "Shawano",
    "Shawano County",
    "Sheboygan",
    "Sheboygan County",
    "Sheboygan Falls",
    "Shell Lake",
    "Sherwood",
    "Shorewood",
    "Shorewood Hills",
    "Shullsburg",
    "Silver Lake",
    "Siren",
    "Slinger",
    "Somers",
    "Somerset",
    "South Milwaukee",
    "Sparta",
    "Spencer",
    "Spooner",
    "Spring Green",
    "Spring Valley",
    "Stanley",
    "Stevens Point",
    "Stoughton",
    "Stratford",
    "Strum",
    "Sturgeon Bay",
    "Sturtevant",
    "Suamico",
    "Sun Prairie",
    "Superior",
    "Sussex",
    "Tainter Lake",
    "Taylor County",
    "Theresa",
    "Thiensville",
    "Thorp",
    "Tichigan",
    "Tomah",
    "Tomahawk",
    "Trempealeau",
    "Trempealeau County",
    "Turtle Lake",
    "Twin Lakes",
    "Two Rivers",
    "Union Grove",
    "Vernon County",
    "Verona",
    "Vilas County",
    "Viroqua",
    "Wales",
    "Walworth",
    "Walworth County",
    "Washburn",
    "Washburn County",
    "Washington County",
    "Waterford",
    "Waterloo",
    "Watertown",
    "Waukesha",
    "Waukesha County",
    "Waunakee",
    "Waupaca",
    "Waupaca County",
    "Waupun",
    "Wausau",
    "Waushara County",
    "Wautoma",
    "Wauwatosa",
    "West Allis",
    "West Baraboo",
    "West Bend",
    "West Milwaukee",
    "West Salem",
    "Westby",
    "Westfield",
    "Weston",
    "Weyauwega",
    "Whitefish Bay",
    "Whitehall",
    "Whitewater",
    "Whiting",
    "Williams Bay",
    "Wind Lake",
    "Wind Point",
    "Windsor",
    "Winnebago County",
    "Winneconne",
    "Wisconsin Dells",
    "Wisconsin Rapids",
    "Wittenberg",
    "Wood County",
    "Woodville",
    "Wrightstown"
  ],
  "Wyoming": [
    "Afton",
    "Albany County",
    "Antelope Valley-Crestview",
    "Arapahoe",
    "Bar Nunn",
    "Basin",
    "Big Horn County",
    "Buffalo",
    "Campbell County",
    "Carbon County",
    "Casper",
    "Cheyenne",
    "Cody",
    "Converse County",
    "Crook County",
    "Douglas",
    "Ethete",
    "Evanston",
    "Evansville",
    "Fort Washakie",
    "Fox Farm-College",
    "Fremont County",
    "Gillette",
    "Glenrock",
    "Goshen County",
    "Green River",
    "Greybull",
    "Guernsey",
    "Hoback",
    "Hot Springs County",
    "Jackson",
    "Johnson County",
    "Kemmerer",
    "Lander",
    "Laramie",
    "Laramie County",
    "Lincoln County",
    "Lovell",
    "Lusk",
    "Lyman",
    "Marbleton",
    "Mills",
    "Moorcroft",
    "Moose Wilson Road",
    "Mountain View",
    "Natrona County",
    "Newcastle",
    "Niobrara County",
    "North Rock Springs",
    "Park County",
    "Pine Bluffs",
    "Pinedale",
    "Platte County",
    "Powell",
    "Rafter J Ranch",
    "Ranchettes",
    "Rawlins",
    "Riverton",
    "Rock Springs",
    "Saratoga",
    "Sheridan",
    "Sheridan County",
    "Sleepy Hollow",
    "South Greeley",
    "South Park",
    "Star Valley Ranch",
    "Sublette County",
    "Sundance",
    "Sweetwater County",
    "Teton County",
    "Thermopolis",
    "Torrington",
    "Uinta County",
    "Upton",
    "Washakie County",
    "Weston County",
    "Wheatland",
    "Wilson",
    "Worland",
    "Wright"
  ]
}
