const Style = (theme) => ({
  tabs__bar: {
    backgroundColor: "transparent",
    // boxShadow: 'none',
    padding: "0",
    zIndex: "1",
    position: "relative",
    borderRadius: "0",
    minHeight: "30px !important",
    width: "max-content",
    "& .MuiTabScrollButton-root": {
      color: "rgba(109, 125, 139, 1)",
      borderBottom: "3px solid #CCD2E3",
      opacity: "1 !important",
    },
    "& .MuiTabScrollButton-root.Mui-disabled": {
      color: "rgba(109, 125, 139, .3)",
    },
    "& .MuiTabs-scroller": {
      "& .MuiTabs-flexContainer": {
        justifyContent: "flex-start",
        "& .MuiTab-root": {
          minHeight: "30px",
          width: "max-content",
          maxWidth: "max-content",
          minWidth: "max-content",
          textTransform: "capitalize",
          color: "rgba(109, 125, 139, 1)",
          opacity: "1",
          borderBottom: "0",
          fontSize: "13px",
          padding: ".8rem 1.4rem",
          margin: "0",
        },
        "& .Mui-selected": {
          color: theme.palette.primary.main,
          zIndex: 1,
          "& svg": {
            "& path": {
              stroke: theme.palette.primary.main,
            },
          },
        },
      },
    },
  },
});
export default Style;
