import pdfImg from "../../assets/images/pdfImg.png";
import Img from "../../assets/images/image-icon.png";
import docImg from "../../assets/images/docImg.png";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);

export function getMyValue(value) {
  if (value === null || value === undefined || value === "null") {
    return;
  }
  return value;
}

export function getRandomBackgroundColor(index) {
  let bgColor;
  switch (index) {
    case 0:
      bgColor = "#0C80F3";
      break;
    case 1:
      bgColor = "rgb(255, 99, 132)";
      break;
    case 2:
      bgColor = "rgb(255, 205, 86)";
      break;
    case 3:
      bgColor = "rgb(75, 192, 192)";
      break;
    case 4:
      bgColor = "#0C80F3";
      break;
    case 5:
      bgColor = "rgb(255, 99, 132)";
      break;
    case 6:
      bgColor = "rgb(255, 205, 86)";
      break;
    case 7:
      bgColor = "rgb(75, 192, 192)";
      break;
    case 8:
      bgColor = "#0C80F3";
      break;
    case 9:
      bgColor = "rgb(255, 99, 132)";
      break;
    case 10:
      bgColor = "rgb(255, 205, 86)";
      break;
    case 11:
      bgColor = "rgb(75, 192, 192)";
      break;
    default:
      bgColor = "rgb(153, 102, 255)";
      break;
  }

  return bgColor;
}

export function removeLastExtension(fileName) {
  const lastDotIndex = fileName?.lastIndexOf(".");

  if (lastDotIndex !== -1) {
    const nameWithoutExtension = fileName?.substring(0, lastDotIndex);
    const extension = fileName?.substring(lastDotIndex);

    return { nameWithoutExtension, extension };
  } else {
    // No extension found
    return fileName;
  }
}

export const bodyPainWord = (bodyPartName) => {
  const formattedBodyPartName = bodyPartName.replace("-", " ");
  return formattedBodyPartName;
};

export const exportPdfPainData = (allData) => {
  const painTableData = [];
  let srNo = 1;

  // Loop over allData
  allData?.forEach((data, index) => {
    const createdTime = data?.created_at
      ? dayjs(data?.created_at).format("hh:mm A")
      : "-";
    // Overall Body Part
    // if (data?.global_pain_level !== null) {
    //   return;
    // const row = [
    //   srNo++,
    //   data?.date,
    //   createdTime,
    //   "Overall",
    //   data?.global_pain_level,
    //   "-",
    // ];
    // painTableData.push(row);
    // } else {
    //   const row = [srNo++, data?.date, createdTime, "", "-", "-"];
    //   painTableData?.push(row);
    // }

    const parsedData = JSON.parse(data?.body_parts_data);

    // Loop over body_parts_data
    parsedData?.forEach((bodyPart, bodyPartIndex) => {
      const row = [
        srNo++,
        data?.date,
        createdTime,
        `${bodyPart?.side}-${bodyPart?.name}`,
        bodyPart?.pain_scale,
        bodyPart?.description ? bodyPart?.description : "-",
      ];
      painTableData.push(row);
    });
  });

  return painTableData;
};

export const checkFileExtension = (fileName) => {
  const fileExtension = fileName?.split(".")?.pop();

  const imageExtensions = ["jpg", "jpeg", "png"];

  const isImage = imageExtensions?.includes(fileExtension?.toLowerCase());
  return isImage;
};

export const onlyImageName = (imageName) => {
  const parts = imageName?.split(".");

  const nameWithoutExtension = parts?.slice(0, -1)?.join(".");

  return nameWithoutExtension;
};

export const getCaseValueText = (caseValue) => {
  const option = caseTypeOptions.find((option) => option.value === caseValue);
  return option ? option.title : caseValue;
};

export const isPDF = (fileName) => {
  return fileName.toLowerCase().endsWith(".pdf");
};

// export const exportCsvData = (data) => {
//   // Create CSV data
//   const csvData = [];
//   csvData.push([
//     "Sr No.",
//     "Date",
//     "Time",
//     "Body Part",
//     "Pain Intensity",
//     "Comments",
//   ]);

//   data.forEach((item, index) => {
//     const dateObject = dayjs(item?.created_at);
//     const formattedTime = dateObject.format("hh:mm A");

//     const actualDate = item?.date || "-";

//     const bodyPartName = "Overall";

//     const bodyParts =
//       item?.body_parts_data?.length === 0
//         ? ""
//         : item?.body_parts_data
//             ?.map((part) => {
//               return `${part?.side}-${part?.name}`;
//             })
//             .join(", ");

//     const painIntensity =
//       item?.global_pain_level !== null ? item?.global_pain_level : "N/A";

//     const comments =
//       item?.body_parts_data
//         .map((part) => {
//           return part?.description;
//         })
//         .join(", ") || "-";

//     csvData.push([
//       index + 1,
//       actualDate,
//       formattedTime,
//       bodyParts,
//       painIntensity,
//       comments,
//     ]);
//   });

//   // return csvData;
// };

export const exportCsvData = (allData) => {
  const csvData = [];
  let srNo = 1;

  // Adding CSV headers
  csvData?.push([
    "Sr No.",
    "Date",
    "Time",
    "Body Part",
    "Duration",
    "Pain Intensity",
    "Sleep Loss",
    "Comments",
  ]);

  // Loop over allData
  allData?.forEach((data, index) => {
    const createdTime = data?.created_at
      ? dayjs(data?.created_at).format("hh:mm A")
      : "-";
    // Overall Body Part
    // if (data?.global_pain_level !== null) {
    //   const row = [
    //     srNo++,
    //     data?.date,
    //     createdTime,
    //     "Overall",
    //     data?.global_pain_level,
    //     "-",
    //   ];
    //   csvData.push(row);
    // } else {
    //   const row = [srNo++, data?.date, createdTime, "", "-", "-"];
    //   csvData?.push(row);
    // }

    // Loop over body_parts_data
    data?.body_parts_data?.forEach((bodyPart, bodyPartIndex) => {
      const sleepLoss = bodyPart?.loss_of_sleep === true ? "Yes" : "No";
      const painDurationValue = data?.duration
        ? painDurationValues[data?.duration]
        : "-";

      const row = [
        srNo++,
        data?.date,
        createdTime,
        `${bodyPart?.side}-${bodyPart?.name}`,
        painDurationValue,
        bodyPart?.pain_scale,
        sleepLoss,
        bodyPart?.description,
      ];
      csvData.push(row);
    });
  });

  return csvData;
};

export const validateEmail = (inputEmail) => {
  const pattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return pattern.test(inputEmail);
};

export const clearContact = (phoneNumber) => {
  return phoneNumber.replace(/[+() ]/g, "");
};

export const formatPhoneNumber = (input) => {
  const numericInput = input.replace(/^1/, "").replace(/\D/g, ""); // Remove non-numeric characters from input

  if (numericInput.length === 0) {
    return "";
  }

  let formattedNumber = "+1 ";
  if (numericInput.length >= 1) {
    formattedNumber += `(${numericInput.slice(0, 3)}`;
  }
  if (numericInput.length >= 4) {
    formattedNumber += `) ${numericInput.slice(3, 6)}`;
  }
  if (numericInput.length >= 7) {
    formattedNumber += `-${numericInput.slice(6, 10)}`;
  }

  return formattedNumber;
};

export const isSupportedType = (filename) => {
  const extension = filename?.split(".")?.pop()?.toLowerCase();
  return ["jpg", "jpeg", "png", "gif", "mp4", "webm", "ogg"]?.includes(
    extension
  );
};

export const timeAgo = (createdAt) => {
  const formattedTime = dayjs(createdAt).fromNow();

  return formattedTime;
};

export const caseLogsOptions = [
  {
    title: "All",
    value: "all",
    borderColor: "#1669BB",
    bgColor: "#E3F1FF",
    hoverBgColor: "#1669BB",
  },
  {
    title: "Appointments",
    value: "appointments",
    borderColor: "#3DC583",
    bgColor: "#F3FFF9",
    hoverBgColor: "#2CA068",
  },
  {
    title: "Expenses",
    value: "expenses",
    bgColor: "#FFEDED",
    borderColor: "#FF2D2D",
    hoverBgColor: "#CC3D3D",
  },
  {
    title: "Notes",
    value: "notes",
    bgColor: "#FFF8EC",
    borderColor: "#F1B62E",
    hoverBgColor: "#D29836",
  },
  {
    title: "Other",
    value: "other",
    borderColor: "#1669BB",
    bgColor: "#E3F1FF",
    hoverBgColor: "#1669BB",
  },
];

export const expenseTypeListItems = [
  {
    key: "transportExpenses",
    label: "Transport Expenses",
  },
  {
    key: "medicalExpenses",
    label: "Medical Expenses",
  },
  {
    key: "salaryLoss",
    label: "SalaryLoss",
  },
  {
    key: "others",
    label: "Others",
  },
];

export const caseTypeOptions = [
  { title: "Personal Injury", value: "personal injury" },
  { title: "Auto Accident", value: "Auto accident" },
  { title: "Slip & Fall", value: "slip-fall" },
  { title: "Premises Liability", value: "premises liability" },
  { title: "Dog Bite", value: "dog bite" },
  { title: "Pedestrian Accident", value: "pedestrian accident" },
  { title: "Other", value: "other" },
];

export const caseTypeName = {
  "personal injury": "Personal Injury",
  "Auto accident": "Auto Accident",
  "slip-fall": "Slip & Fall",
  "premises liability": "Premises Liability",
  "dog bite": "Dog Bite",
  "pedestrian accident": "Pedestrian Accident",
  other: "Other",
};

export const caseTypeValues = {
  "Personal Injury": "personal injury",
  "Auto Accident": "Auto accident",
  "Slip & Fall": "slip-fall",
  "Premises Liability": "premises liability",
  "Dog Bite": "dog bite",
  "Pedestrian Accident": "pedestrian accident",
  Other: "other",
};

export const findOptionByValue = (value) =>
  caseTypeOptions.find((option) => option.value === value) || null;

export const getNotificationText = (data, classes) => {
  let displayText = "";

  if (data?.log_type === "appointment_update") {
    const { appointment_status, appointment_title } = data;
    displayText = (
      <span className={classes.lightText}>
        {appointment_status} {appointment_title.toLowerCase()}
      </span>
    );
  } else if (data?.log_type === "logged_in") {
    displayText = <span className={classes.lightText}>logged in</span>;
  } else if (data?.log_type === "document_uploaded") {
    displayText = (
      <span className={classes.lightText}>
        {data?.multiple_documents
          ? `uploaded documents`
          : `uploaded a document`}
      </span>
    );
  } else if (data?.log_type === "pain_logged") {
    displayText = <span className={classes.lightText}>logged pain</span>;
  } else if (data?.log_type === "case_created") {
    const { client } = data;
    displayText = (
      <span className={classes.lightText}>
        {" "}
        created a case for {client.name}
      </span>
    );
  } else if (data?.log_type === "case_status_update") {
    const { client, case_status } = data;
    displayText = (
      <span className={classes.lightText}>
        {" "}
        updated case status to {case_status} for {client.name}
      </span>
    );
  } else if (data?.log_type === "expense_added") {
    displayText = (
      <span className={classes.lightText}> added a new expense</span>
    );
  }
  return displayText;
};

export const getAlertNotificationText = (data) => {
  let displayText;

  if (data?.log_type === "no_pain_logged") {
    displayText = (
      <span>has not logged pain for the last {data?.number_of_days} days</span>
    );
  } else if (data?.log_type === "no_activity") {
    displayText = (
      <span>
        has not performed any activity for the last {data?.number_of_days} days
      </span>
    );
  } else if (data?.log_type === "missed_appointment_status") {
    displayText = (
      <span>
        has not updated the status of last {data?.number_of_days} appointments
      </span>
    );
  } else {
    displayText = "Unknown Log Type";
  }

  return displayText;
};

export function formatSizeUnits(bytes) {
  if (bytes >= 1073741824) {
    bytes = (bytes / 1073741824).toFixed(2) + " GB";
  } else if (bytes >= 1048576) {
    bytes = (bytes / 1048576).toFixed(2) + " MB";
  } else if (bytes >= 1024) {
    bytes = (bytes / 1024).toFixed(2) + " KB";
  } else if (bytes > 1) {
    bytes = bytes + " bytes";
  } else if (bytes === 1) {
    bytes = bytes + " byte";
  } else {
    bytes = "0 byte";
  }
  return bytes;
}

export const fileTypes = (type) => {
  if (type === "application/pdf") {
    return pdfImg;
  } else if (
    type === "image/png" ||
    type === "image/jpg" ||
    type === "image/jpeg"
  ) {
    return Img;
  } else {
    return docImg;
  }
};

export function caseStatusType(type) {
  if (type.toLowerCase() === "open" || type.toLowerCase() === "sent") {
    return "primary";
  } else if (type.toLowerCase() === "closed") {
    return "error";
  } else if (type.toLowerCase() === "on hold") {
    return "warning";
  } else {
    return "success";
  }
}

export function formatCaseTypeText(text) {
  // Replace underscores and hyphens with space and capitalize the first letter of each word
  const formattedText = text
    .replace(/[_-]/g, " & ")
    .replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase();
    });
  return formattedText;
}

export function notificationStatusColor(status) {
  if (status.toLowerCase() === "sent") {
    return "success";
  } else if (status.toLowerCase() === "scheduled") {
    return "primary";
  } else if (status.toLowerCase() === "on hold") {
    return "warning";
  } else {
    return "error";
  }
}

// export function referralStatusColor(status) {
//   let color;
//   if (status.toLowerCase() === "sent") {
//     color = "#2196F3";
//   } else if (status.toLowerCase() === "completed") {
//     color = "#2E7D32";
//   } else {
//     color = "#FAD44D";
//   }
//   return color;
// }

export const caseValueColor = (value) => {
  let color;
  switch (value) {
    case "high":
      color = "#D32F2F";
      break;
    case "low":
      color = "#649F67";
      break;
    case "moderate":
      color = "#F29244";
      break;
    default:
      break;
  }

  return color;
};

export const caseValueBgColor = (value) => {
  let color;
  switch (value) {
    case "high":
      color = "rgba(211, 47, 47, 0.15)";
      break;
    case "low":
      color = "rgba(100, 159, 103, 0.15)";
      break;
    case "moderate":
      color = "rgba(242, 146, 68, 0.15)";
      break;
    default:
      break;
  }

  return color;
};

export const caseStatusColor = (value) => {
  let color;
  switch (value) {
    case "closed":
      color = "#D32F2F";
      break;
    case "settled":
      color = "#649F67";
      break;
    case "on hold":
      color = "#F29244";
      break;
    case "open":
      color = "#0C80F3";
      break;
    default:
      break;
  }

  return color;
};

export const caseStatusBgColor = (value) => {
  let color;
  switch (value) {
    case "closed":
      color = "rgba(211, 47, 47, 0.15)";
      break;
    case "settled":
      color = "rgba(100, 159, 103, 0.15)";
      break;
    case "on hold":
      color = "rgba(242, 146, 68, 0.15)";
      break;
    case "open":
      color = "rgba(12, 128, 243, 0.15)";
      break;
    default:
      break;
  }

  return color;
};

export const caseLogsCategory = {
  pain_logged: "General",
  document_uploaded: "General",
  appointment_update: "Appointment",
  case_status_update: "System",
  case_created: "System",
  custom_note: "Note",
  expense_added: "Expense",
};

export function stepperIcon(type) {
  if (type === "appointment_update") {
    return "#3DC583";
  } else if (type === "custom_note") {
    return "#F3AF3D";
  } else if (type === "expense_added") {
    return "#F54949";
  } else {
    return "#0C80F3";
  }
}

const log_type_title_config = {
  pain_logged: "Pain Logged",
  case_created: "Case Created",
  appointment_update: "",
  case_status_update: "Case Status changed to ",
  document_uploaded: "Uploaded a Document",
  chat_activity: "Client replied in chat",
};

export const expenseTypeValuesStatement = {
  other: "Other Expense",
  transport_expense: "Transport Expense",
  medical_expense: "Medical Expense",
  salary_loss: "Salary Loss",
};

export const painDurationValues = {
  all_day: "All Day",
  hours_1_3: "1-3 Hours",
  hours_3_10: "3-10 Hours",
  hours_10_20: "10-20 Hours",
};

export const renderStepContent = (step) => {
  switch (step.log_type) {
    case "custom_note":
      return step.log_text;

    case "chat_activity":
    case "pain_logged":
    case "case_created":
    case "document_uploaded":
      return log_type_title_config[step.log_type];
    case "case_status_update":
      return `${log_type_title_config[step.log_type]} ${step.case_status}`;
    case "appointment_update":
      return `${step.appointment_status} ${step.appointment_title}`;
    case "expense_added":
      return `Added a ${expenseTypeValuesStatement[step?.expense_type]} of $${
        step?.expense_amount
      }`;
    default:
      return step.log_type;
  }
};

export const painLevelBackgroundColor = (painLevel) => {
  let bgColor;
  switch (painLevel) {
    case 0:
      bgColor = "#43A762";
      break;
    case 1:
      bgColor = "#9ECA46";
      break;
    case 2:
      bgColor = "#E6E62F";
      break;
    case 3:
      bgColor = "#E6E630";
      break;
    case 4:
      bgColor = "#E6E62F";
      break;
    case 5:
      bgColor = "#E8C929";
      break;
    case 6:
      bgColor = "#E9AB22";
      break;
    case 7:
      bgColor = "#E8921B";
      break;
    case 8:
      bgColor = "#E67612";
      break;
    case 9:
      bgColor = "#E85014";
      break;
    case 10:
      bgColor = "#E91416";
      break;
    default:
      return "#000";
  }

  return bgColor;
};

export const lastMonthCreatedCases = (cases, month) => {
  const expectedMonth = dayjs().subtract(month, "month");

  const casesCreatedLastMonth = cases?.filter((caseItem) =>
    dayjs(caseItem.created_at).isAfter(expectedMonth)
  );

  return casesCreatedLastMonth;
};

export const dateFormat = (date) => {
  const formattedDate = dayjs(date).format("MM/DD/YYYY");
  return formattedDate;
};

export const dateFormatHyphen = (date) => {
  const formattedDate = dayjs(date).format("MM-DD-YYYY");
  return formattedDate;
};

export const dateTimeFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("hh:mm A, D MMMM");
  return formattedDateTime;
};

export const timeDateFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("D MMMM, hh:mm A");
  return formattedDateTime;
};
export const shortDateFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("D MMM, hh:mm A");
  return formattedDateTime;
};
export const onlyDateFormat = (dateTimeString) => {
  const formattedDateTime = dayjs(dateTimeString).format("MMM D, YYYY");
  return formattedDateTime;
};

export const extractDate = (date) => {
  const stringDate = dayjs(date).format("DD");
  return stringDate;
};

export const extractMonth = (date) => {
  const month = dayjs(date).format("MMM");
  return month;
};

export const parseAndMergeBodyParts = (bodyPartsArrays) => {
  let mergedBodyParts = [];

  bodyPartsArrays?.forEach((bodyPart) => {
    mergedBodyParts = [...mergedBodyParts, ...bodyPart];
  });

  return mergedBodyParts;
};

export function displayExpenseAmounts(expenseDetails) {
  let transportExpenseAmount = 0;
  let medicalExpenseAmount = 0;
  let salaryLossAmount = 0;
  let otherAmount = 0;

  expenseDetails?.forEach((expense) => {
    switch (expense.expense_type) {
      case "transport_expense":
        transportExpenseAmount += expense.total_amount;
        break;
      case "medical_expense":
        medicalExpenseAmount += expense.total_amount;
        break;
      case "salary_loss":
        salaryLossAmount += expense.total_amount;
        break;
      default:
        otherAmount += expense.total_amount;
        break;
    }
  });

  return {
    transportExpenseAmount,
    medicalExpenseAmount,
    salaryLossAmount,
    otherAmount,
  };
}

export function showExpenseTypeName(expenseType) {
  switch (expenseType) {
    case "transport_expense":
      return "Transport";
    case "medical_expense":
      return "Medical";
    case "salary_loss":
      return "Salary Loss";
    case "other":
      return "Other";
    default:
      break;
  }
}

export const expenseTypeValues = {
  transport_expense: "transportExpenses",
  medical_expense: "medicalExpenses",
  salary_loss: "salaryLoss",
  other: "others",
};
