export const USER_LOGIN_REQUEST = "USER_LOGIN_REQUEST";
export const USER_LOGIN_FAIL = "USER_LOGIN_FAIL";
export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const USER_LOGOUT = "USER_LOGOUT";

export const USER_REGISTER_REQUEST = "USER_REGISTER_REQUEST";
export const USER_REGISTER_FAIL = "USER_REGISTER_FAIL";
export const USER_REGISTER_SUCCESS = "USER_REGISTER_SUCCESS";

export const USER_FORGOT_REQUEST = "USER_FORGOT_REQUEST";
export const USER_FORGOT_FAIL = "USER_FORGOT_FAIL";
export const USER_FORGOT_SUCCESS = "USER_FORGOT_SUCCESS";

export const FORGOT_EMAIL_VERIFY_REQUEST = "FORGOT_EMAIL_VERIFY_REQUEST";
export const FORGOT_EMAIL_VERIFY_FAIL = "FORGOT_EMAIL_VERIFY_FAIL";
export const FORGOT_EMAIL_VERIFY_SUCCESS = "FORGOT_EMAIL_VERIFY_SUCCESS";

export const OTP_VERIFY_REQUEST = "OTP_VERIFY_REQUEST";
export const OTP_VERIFY_SUCCESS = "OTP_VERIFY_SUCCESS";
export const OTP_VERIFY_FAIL = "OTP_VERIFY_FAIL";

export const AGENT_TOKEN = "AGENT_TOKEN";

export const UPDATE_FORGOTTEN_PASSWORD_REQUEST =
  "UPDATE_FORGOTTEN_PASSWORD_REQUEST";
export const UPDATE_FORGOTTEN_PASSWORD_SUCCESS =
  "UPDATE_FORGOTTEN_PASSWORD_SUCCESS";
export const UPDATE_FORGOTTEN_PASSWORD_FAIL = "UPDATE_FORGOTTEN_PASSWORD_FAIL";

export const ADD_PASSWORD_REQUEST = "ADD_PASSWORD_REQUEST";
export const ADD_PASSWORD_SUCCESS = "ADD_PASSWORD_SUCCESS";
export const ADD_PASSWORD_FAIL = "ADD_PASSWORD_FAIL";

export const RESEND_CLIENT_CREDENTIALS_REQUEST =
  "RESEND_CLIENT_CREDENTIALS_REQUEST";
export const RESEND_CLIENT_CREDENTIALS_SUCCESS =
  "RESEND_CLIENT_CREDENTIALS_SUCCESS";
export const RESEND_CLIENT_CREDENTIALS_FAIL = "RESEND_CLIENT_CREDENTIALS_FAIL";
export const RESET_RESEND_CLIENT_CREDENTIALS =
  "RESET_RESEND_CLIENT_CREDENTIALS";
