import React, { useEffect } from "react";
import BgBox from "../../../common/BgBox";
import HeaderText from "../../../common/HeaderText";
import CardUI from "../../../common/Card";
import { Box, Button, Grid, Typography, Link, Avatar } from "@mui/material";
import { Link as LinkTo, useLocation, useNavigate } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { useSelector } from "react-redux";
import MccLogo from "../../../components/MccLogo";

const useStyles = makeStyles((theme) => {
  return {
    email_Btn: {
      border: "1px solid #C6C6C6",
      textTransform: "none",
      color: "#000!important",
      fontWeight: "400",
      borderRadius: "8px",
      padding: "4px 16px",
      cursor: "pointer",
    },
    p8: {
      padding: "8px",
      borderRadius: "4px",
    },
  };
});

function stringAvatar(name) {
  const newName = name?.replace(/[^a-zA-Z0-9]/g, " ")?.replace(/\s\s+/g, " ");
  return {
    children: `${newName?.split(" ")[0][0]}${
      newName.split(" ")[1] ? newName.split(" ")[1][0] : ""
    }`,
  };
}

const JoinWorkspace = () => {
  const navigate = useNavigate();
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  // const {
  //   state: { agent_data },
  // } = useLocation();
  const { state } = useLocation();

  const agentData = state && state?.agentData;

  // console.log("LOCATION WORKSP", state);

  useEffect(() => {
    if (state === null) {
      navigate(-1);
    }
  }, [state, navigate]);

  // useEffect(() => {
  //   console.log(agent_data);
  // }, [agent_data]);

  const classes = useStyles();

  const joinWorkspaceHandler = (e) => {
    e.preventDefault();

    navigate("/create-workspace", {
      state: { JoinWorkspace: true, agentData },
    });
  };

  if (state === null) {
    return;
  }

  return (
    <BgBox>
      <CardUI paddingTop="86px">
        {/* MCC LOGO */}
        <MccLogo />

        <Grid container component="main">
          <Box
            component="form"
            onSubmit={joinWorkspaceHandler}
            xs={12}
            display="flex"
            gap="52px"
            flexDirection="column"
            width="100%"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              gap="24px"
            >
              <HeaderText
                variant="h3"
                fontSize={34}
                textContent="Choose Workspace"
                lineHeight="24px"
              />

              <Box
                display="flex"
                alignItems="center"
                gap={1}
                className={classes.email_Btn}
              >
                <Typography variant="body2" color="inherit">
                  {`Verified as ${agentData?.agent?.email}`}
                </Typography>
                <Link
                  ml={1}
                  component={LinkTo}
                  to="/"
                  textAlign="center"
                  color="inherit"
                >
                  Change
                </Link>
              </Box>
            </Box>

            <Box display="flex" flexDirection="column" gap="16px">
              {state?.agent_data?.existing_agencies.length ? (
                <Typography
                  variant="body1"
                  className="d-flex"
                  alignItems="center"
                  justifyContent="center"
                  fontSize={16}
                  color="#566875"
                >
                  This email is already linked with the firm "
                  {state?.agent_data.existing_agencies[0].name}"
                </Typography>
              ) : (
                <Button
                  fullWidth
                  type="submit"
                  variant="contained"
                  sx={{ padding: "8px 22px", fontSize: 15 }}
                >
                  Create new workspace
                </Button>
              )}

              {/* <Typography
                variant="body1"
                className="d-flex"
                alignItems="center"
                justifyContent="center"
                fontSize={16}
                color="#566875"
              >
                or join a workspace linked to this email
              </Typography>

              <Box
                display="flex"
                alignItems="center"
                gap="12px"
                className={`${classes.email_Btn} ${classes.p8}`}
              >
                <Avatar
                  sx={{ bgcolor: "#B0D6FE" }}
                  {...stringAvatar("Acme & Adams")}
                  variant="rounded"
                />
                <Box display="flex" flexDirection="column">
                  <Typography variant="h6" fontSize="16px">
                    Acme & Acme
                  </Typography>
                  <Typography fontSize="12px" color="secondary">
                    0 Members
                  </Typography>
                </Box>
              </Box> */}
            </Box>

            <Link component={LinkTo} to="/" textAlign="center">
              Go to Home Page
            </Link>

            {/* <Button
        className="w-100"
        fontSize="15px"
        color="primary"
        
        title="Sign In"
        variant="contained"
        width="100%"
        height="48px"
        // disabled={!fieldVals?.email || !fieldVals?.password} // Disabling it off on purpose
        // onClick={() => onSignIn()}
        // loading={signInLoading}
        loadingColor="primary"
      /> */}
          </Box>
        </Grid>
      </CardUI>
    </BgBox>
  );
};

export default JoinWorkspace;
