import React from "react";

const InfoIconBlue = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M10.0859 6.41634H11.9193V8.24967H10.0859V6.41634ZM10.0859 10.083H11.9193V15.583H10.0859V10.083ZM11.0026 1.83301C5.9426 1.83301 1.83594 5.93967 1.83594 10.9997C1.83594 16.0597 5.9426 20.1663 11.0026 20.1663C16.0626 20.1663 20.1693 16.0597 20.1693 10.9997C20.1693 5.93967 16.0626 1.83301 11.0026 1.83301ZM11.0026 18.333C6.9601 18.333 3.66927 15.0422 3.66927 10.9997C3.66927 6.95717 6.9601 3.66634 11.0026 3.66634C15.0451 3.66634 18.3359 6.95717 18.3359 10.9997C18.3359 15.0422 15.0451 18.333 11.0026 18.333Z"
        fill="#0288D1"
      />
    </svg>
  );
};

export default InfoIconBlue;
