import React, { useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  IconButton,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import DragIcon from "../../assets/images/drag-icon.png";
import InfoIconBlue from "../../icons/InfoIconBlue";
import CloseIconBlue from "../../icons/CloseIconBlue";

const IntakeQuestionField = ({
  questionText,
  changeTextHandler,
  showError,
  saveQuestionHandler,
  isRequired,
  setIsRequired,
  showHelperText,
  changeHelperTextHandler,
  helperTextValues,
  setHelperTextValues,
  label,
  showDragIcon = true,
  showRequired = true,
  fromNewCaseModal,
}) => {
  const [ShowInfoText, setShowInfoText] = useState(true);

  return (
    <Stack
      bgcolor="rgba(33, 150, 243, 0.10)"
      borderRadius="4px"
      border="1px solid #0C80F3"
      padding="16px"
      borderLeft="4px solid #0C80F3"
      gap="24px"
    >
      <Box display="flex" gap="24px">
        {showDragIcon && (
          <Box>
            <img src={DragIcon} alt="drag icon" />
          </Box>
        )}
        <Stack width="100%" gap="6px">
          <Box display="flex" gap="24px" alignItems="center">
            <Box flex="1">
              <TextField
                variant="standard"
                label={label}
                value={questionText}
                onChange={changeTextHandler}
                fullWidth
                error={showError}
                multiline
              />
              {/* )} */}
            </Box>
            <IconButton
              sx={{
                background: "#3DC583",
                borderRadius: "4px",
                color: "#fff",
                padding: "8px 10px",
                boxShadow:
                  "0px 3px 1px -2px rgba(0, 0, 0, 0.20), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12)",
                "&:hover": {
                  background: "#3DC583",
                },
              }}
              onClick={saveQuestionHandler}
            >
              <CheckIcon />
            </IconButton>
          </Box>
          <Box>
            {showRequired && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isRequired}
                    onChange={(e) => setIsRequired(e.target.checked)}
                  />
                }
                label="Required"
              />
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={showHelperText}
                  onChange={changeHelperTextHandler}
                />
              }
              label="Answer Guidelines"
            />
          </Box>
          {showHelperText && (
            <Box bgcolor="#fff">
              <TextField
                id="helper-text"
                sx={{ paddingLeft: "16px", paddingTop: "16px" }}
                onChange={(e) => setHelperTextValues(e.target.value)}
                value={helperTextValues}
                placeholder="Type your answer guidelines here"
                multiline
                rows={4}
                variant="standard"
                fullWidth
              />
            </Box>
          )}
        </Stack>
      </Box>
      {fromNewCaseModal && ShowInfoText && (
        <Box
          bgcolor="#fff"
          display="flex"
          alignItems="center"
          padding="6px 16px"
          borderRadius="4px"
          gap="12px"
        >
          <InfoIconBlue />
          <Typography flex="1" variant="body1" color="#014361">
            This question will only be added to this case’s intake form.
          </Typography>
          <IconButton
            onClick={() => {
              setShowInfoText(false);
            }}
          >
            <CloseIconBlue />
          </IconButton>
        </Box>
      )}
    </Stack>
  );
};

export default IntakeQuestionField;
