import React from "react";

const CollapseDownIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="11"
      viewBox="0 0 12 11"
      fill="none"
    >
      <path
        d="M9.63012 4.10254L6.64178 7.09087C6.28887 7.44379 5.71137 7.44379 5.35845 7.09087L2.37012 4.10254"
        stroke="#0C80F3"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CollapseDownIcon;
