const Style = (theme) => ({
  fields__space: {
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse !important",
    },
    "& .control-width": {
      width: "48.7%",
      [theme.breakpoints.down("md")]: {
        width: "100%",
        margin: "0",
      },
      "& .react-tel-input": {
        // marginTop: ' !important',
        marginBottom: "3px !important",
      },
    },
  },
  form__radio__group: {
    width: "100%",
    "& label": {
      paddingBottom: ".5rem",
      width: "100%",
      display: "flex",
      marginLeft: "0",
      justifyContent: "space-between",
    },
  },
  banner__withName: {
    borderRadius: "10px",
    border: "1px solid #242121",
    "& .main__container": {
      borderRadius: "10px",
    },
    "& .overlay": {
      display: "none",
      position: "absolute",
      top: "0",
      left: "0",
      height: "100%",
      width: "100%",
      backgroundColor: "rgba(0,0,0,.6)",
      borderRadius: "8px",
    },
    "&:hover": {
      "& .overlay": {
        display: "flex",
      },
    },
    "& svg": {
      "& path": {
        stroke: "#fff",
      },
    },
  },
  general__settings__main: {
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column-reverse !important",
    },
  },
  grid_column: {
    [theme.breakpoints.down("lg")]: {
      maxWidth: "100% !important",
    },
  },
});
export default Style;
