import React, { useEffect, useState } from "react";
import CommonDrawer from "../../common/CommonDrawer";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllCasesFunc,
  getCaseDetailByCaseId,
  resetUpdateCase,
  updateCaseFunc,
} from "../../actions/Cases";
import CircularProgressLoading from "../../common/CircularProgress";
import AlertMessage from "../../common/AlertMessage";
import {
  getAgentsListFunc,
  resetUpdatedTeamMember,
  updateAgentTeamMembersFunc,
} from "../../actions/Agency";
import { CastForEducationTwoTone } from "@mui/icons-material";
import dayjs from "dayjs";
import { segmentError, segmentTrack } from "../../utils/helpers/tracking_utils";
import { caseTypeOptions, findOptionByValue } from "../../utils/helpers";
import { appInsights } from "../../utils/helpers/AppInsights";

const EditCaseDrawer = ({
  anchor,
  openEditCase,
  setOpenEditCase,
  onClose,
  selectedRowCaseId,
  fromCaseOverview,
  page,
  pageSize,
  query,
}) => {
  const [caseTitle, setCaseTitle] = useState("");
  const [opposingParty, setOpposingParty] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [caseType, setCaseType] = useState(null);
  const [dateOfIncident, setDateOfIncident] = useState(null);
  const [caseOpenDate, setCaseOpenDate] = useState(null);
  const [statueOfLimitation, setStatueOfLimitation] = useState(null);
  const [caseFillingDate, setCaseFillingDate] = useState(null);
  const [locationOfIncident, setLocationOfIncident] = useState("");
  const [jurisdiction, setJurisdiction] = useState("");
  const [caseDescription, setCaseDescription] = useState("");
  const [witnessName, setWitnessName] = useState("");
  const [witnessNumber, setWitnessNumber] = useState("");
  const [discoveryInformation, setDiscoveryInformation] = useState("");
  const [insuranceDetails, setInsuranceDetails] = useState("");
  const [courtOrders, setCourtOrders] = useState("");
  const [expenses, setExpenses] = useState("");
  const [caseNote, setCaseNote] = useState("");
  const [assignedTeamMembers, setAssignedTeamMembers] = useState([]);

  const dispatch = useDispatch();
  const caseDetailByCaseId = useSelector((state) => state.caseDetailByCaseId);
  const { loading, error, caseDetail } = caseDetailByCaseId;

  const getAgentsList = useSelector((state) => state.getAgentsList);
  const { loading: loadingAgent, error: errorAgent, allAgents } = getAgentsList;

  const updateCase = useSelector((state) => state.updateCase);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    updatedCase,
  } = updateCase;

  const updateAgentTeamMembers = useSelector(
    (state) => state.updateAgentTeamMembers
  );
  const {
    loading: loadingUpdateMembers,
    error: errorUpdateMembers,
    updatedAgentTeamMembers,
  } = updateAgentTeamMembers;

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentSelf,
    error: errorAgentSelf,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  useEffect(() => {
    if (selectedRowCaseId && fromCaseOverview) {
      if (!allAgents) {
        dispatch(getAgentsListFunc());
      }
    } else if (selectedRowCaseId) {
      dispatch(getCaseDetailByCaseId(selectedRowCaseId));
      if (!allAgents) {
        dispatch(getAgentsListFunc());
      }
    }
  }, [selectedRowCaseId]);

  const allAgentsOptions =
    allAgents?.agents_list.length > 0
      ? allAgents?.agents_list?.filter(
          (agent) => agent?.agent_type !== "mcc_support"
        )
      : [];

  const assignedTeamMembersId = assignedTeamMembers.map((member) => member.id);

  useEffect(() => {
    if (selectedRowCaseId) {
      setCaseTitle(caseDetail?.case?.title ? caseDetail?.case?.title : "");
      setOpposingParty(
        caseDetail?.case?.opposing_party ? caseDetail?.case?.opposing_party : ""
      );
      setCaseNumber(
        caseDetail?.case?.case_number ? caseDetail?.case?.case_number : ""
      );
      setCaseType(
        caseDetail?.case?.case_type ? caseDetail?.case?.case_type : null
      );
      setDateOfIncident(
        caseDetail?.case?.incident_date
          ? dayjs(caseDetail?.case?.incident_date)
          : null
      );
      setCaseOpenDate(
        caseDetail?.case?.case_opening_date
          ? dayjs(caseDetail?.case?.case_opening_date)
          : null
      );
      setStatueOfLimitation(
        caseDetail?.case?.statute_of_limitation
          ? dayjs(caseDetail?.case?.statute_of_limitation)
          : null
      );
      setCaseFillingDate(
        caseDetail?.case?.case_registration_date
          ? dayjs(caseDetail?.case?.case_registration_date)
          : null
      );
      setAssignedTeamMembers(
        caseDetail?.case?.agents ? caseDetail?.case?.agents : []
      );
      setLocationOfIncident(
        caseDetail?.case?.incident_location
          ? caseDetail?.case?.incident_location
          : ""
      );
      setJurisdiction(
        caseDetail?.case?.jurisdiction ? caseDetail?.case?.jurisdiction : ""
      );
      setCaseDescription(
        caseDetail?.case?.description ? caseDetail?.case?.description : ""
      );
      // setWitnessName(caseDetail?.case?.witnes ? caseDetail?.case?.witnes : "");
      // setWitnessNumber();
      setDiscoveryInformation(
        caseDetail?.case?.discovery_information
          ? caseDetail?.case?.discovery_information
          : ""
      );
      setInsuranceDetails(
        caseDetail?.case?.insurance_information
          ? caseDetail?.case?.insurance_information
          : ""
      );
      setCourtOrders(
        caseDetail?.case?.court_orders ? caseDetail?.case?.court_orders : ""
      );
      setExpenses(caseDetail?.case?.expenses ? caseDetail?.case?.expenses : "");
      setCaseNote(caseDetail?.case?.notes ? caseDetail?.case?.notes : "");
    }
  }, [caseDetail]);

  const editCaseHandler = async (e) => {
    e.preventDefault();

    const assignTeamMemberData = {
      case_id: selectedRowCaseId,
      members: assignedTeamMembersId,
    };

    dispatch(updateAgentTeamMembersFunc(assignTeamMemberData));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "case_updated",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  useEffect(() => {
    if (updatedAgentTeamMembers?.ok) {
      const caseData = {
        title: caseTitle,
        opposing_party: opposingParty,
        case_type: caseType,
        case_number: caseNumber,
        description: caseDescription,
        incident_date: dateOfIncident,
        case_opening_date: caseOpenDate,
        case_registration_date: caseFillingDate,
        statute_of_limitation: statueOfLimitation,
        incident_location: locationOfIncident,
        jurisdiction: jurisdiction,
        witnesses: JSON.stringify([]),
        discovery_information: discoveryInformation,
        insurance_information: insuranceDetails,
        court_orders: courtOrders,
        expenses: expenses,
        notes: caseNote,
      };

      const config = {
        case_id: selectedRowCaseId,
        case_obj: caseData,
      };
      dispatch(updateCaseFunc(config));

      dispatch(resetUpdatedTeamMember());
    } else if (errorUpdateMembers) {
      appInsights.trackTrace({
        message: `Error while updating agent team members: ${
          errorUpdateMembers || "Could not update agent team members!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_UPDATING_AGENT",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorUpdateMembers || "Could not update agent team member!"}`,
      });
    }
  }, [updatedAgentTeamMembers, errorUpdateMembers]);

  useEffect(() => {
    if (updatedCase?.ok && fromCaseOverview) {
      dispatch(getCaseDetailByCaseId(selectedRowCaseId));
      setOpenEditCase(false);
    } else if (errorUpdate) {
      appInsights.trackTrace({
        message: `Error while editing case: ${
          errorUpdateMembers || "Could not edit the case!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_EDIT_CASE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorUpdateMembers || "Could not edit the case!"}`,
      });
    }
  }, [updatedCase, errorUpdate]);

  useEffect(() => {
    if (updatedCase?.ok) {
      dispatch(getAllCasesFunc(page, pageSize, query));
      setOpenEditCase(false);

      dispatch(resetUpdateCase());
    } else if (errorUpdate) {
      appInsights.trackTrace({
        message: `Error while editing case: ${
          errorUpdateMembers || "Could not edit the case!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_EDIT_CASE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorUpdateMembers || "Could not edit the case!"}`,
      });
    }
  }, [updatedCase, errorUpdate]);

  const isDisabled = !caseTitle || !assignedTeamMembers.length;

  return (
    <CommonDrawer
      anchor={anchor}
      open={openEditCase}
      setOpen={setOpenEditCase}
      onClose={onClose}
    >
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}
      {!loadingAgent && errorAgent && (
        <AlertMessage
          message={errorAgent}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}
      {!loadingUpdate && errorUpdate && (
        <AlertMessage
          message={errorUpdate}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {loading || loadingAgent ? (
        <CircularProgressLoading height="100vh" />
      ) : (
        <Stack gap="24px">
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            pb="32px"
          >
            <Typography variant="h5">Edit Case</Typography>

            <IconButton
              size="small"
              onClick={() => {
                setOpenEditCase(false);
              }}
            >
              <CancelOutlinedIcon />
            </IconButton>
          </Box>
          <Typography
            variant="boxHeading"
            color="secondary"
            textTransform="uppercase"
          >
            Basic Case details
          </Typography>
          <Stack direction="row" justifyContent="space-between" gap="24px">
            <TextField
              // error={isTouched && !!error}
              // helperText={error?.message}
              id="case-title"
              margin="dense"
              label="Case Title"
              variant="outlined"
              name="caseTitle"
              fullWidth
              value={caseTitle}
              onChange={(e) => setCaseTitle(e.target.value)}
              required
            />
          </Stack>

          <TextField
            margin="dense"
            label="Opposing Party"
            variant="outlined"
            name="opposingParty"
            fullWidth
            // error={isTouched && !!error}
            // helperText={error?.message}
            value={opposingParty}
            onChange={(e) => setOpposingParty(e.target.value)}
            required
          />

          <Stack
            direction="row"
            width="100%"
            justifyContent="space-between"
            gap="24px"
          >
            <TextField
              margin="dense"
              label="Case Number"
              variant="outlined"
              name="caseNumber"
              fullWidth
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={caseNumber}
              onChange={(e) => setCaseNumber(e.target.value)}
            />

            {/* CASE TYPE */}
            {/* <FormControl
              fullWidth
              required
              // error={isTouched && !formValues.caseType}
            >
              <InputLabel id="caseType">Type of case</InputLabel>
              <Select
                labelId="caseType"
                id="caseType"
                label="Type of case"
                name="caseType"
                value={caseType}
                onChange={(e) => setCaseType(e.target.value)}
              >
                <MenuItem value="personal-injury">Personal Injury</MenuItem>
                <MenuItem value="Auto-accident">Auto Accident</MenuItem>
                <MenuItem value="slip-fall">Slip & Fall</MenuItem>
                <MenuItem value="premises-liability">
                  Premises Liability
                </MenuItem>
                <MenuItem value="dog-bite">Dog Bite</MenuItem>
                <MenuItem value="pedestrian-accident">
                  Pedestrian Accident
                </MenuItem>
                <MenuItem value="other">Other</MenuItem>
              </Select>
            </FormControl> */}
            <Autocomplete
              disablePortal
              id="case-type"
              value={findOptionByValue(caseType)}
              onChange={(event, newValue) => {
                setCaseType(newValue?.value || null);
              }}
              options={caseTypeOptions}
              getOptionLabel={(option) => option?.title}
              fullWidth
              renderInput={(params) => (
                <TextField {...params} label="Type of case" />
              )}
            />
          </Stack>

          <Stack direction="row" justifyContent="space-between" gap="24px">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Date of Incident"
                value={dateOfIncident}
                onChange={(newValue) => setDateOfIncident(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
                sx={{ marginTop: 1 }}
                maxDate={dayjs()}
                textField={(params) => <TextField {...params} readOnly />}
                // renderInput={(params) => <TextField {...params} readOnly />}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Case open Date"
                value={caseOpenDate}
                onChange={(newValue) => setCaseOpenDate(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
                sx={{ marginTop: 1 }}
              />
            </LocalizationProvider>
          </Stack>

          <Stack direction="row" justifyContent="space-between" gap="24px">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Statute of Limitation"
                value={statueOfLimitation}
                onChange={(newValue) => setStatueOfLimitation(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
                sx={{ marginTop: 1 }}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Case Filing Date"
                value={caseFillingDate}
                onChange={(newValue) => setCaseFillingDate(newValue)}
                slotProps={{ textField: { fullWidth: true } }}
                sx={{ marginTop: 1 }}
              />
            </LocalizationProvider>
          </Stack>

          <Stack direction="row" justifyContent="space-between" gap="24px">
            <TextField
              margin="dense"
              label="Location Of incident"
              variant="outlined"
              name="locationOfIncident"
              fullWidth
              width="100%"
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={locationOfIncident}
              onChange={(e) => setLocationOfIncident(e.target.value)}
            />
            <TextField
              margin="dense"
              label="Jurisdiction"
              variant="outlined"
              name="jurisdiction"
              fullWidth
              width="100%"
              // error={isTouched && !!error}
              // helperText={error?.message}
              value={jurisdiction}
              onChange={(e) => setJurisdiction(e.target.value)}
            />
          </Stack>

          <Typography
            component="label"
            variant="boxHeading"
            color="disabled.boxHeading"
            textTransform="uppercase"
          >
            Assign to
          </Typography>

          <Autocomplete
            multiple
            id="assignTeamMembers"
            options={allAgentsOptions}
            getOptionLabel={(option) => (option.name ? option.name : [])}
            filterSelectedOptions
            value={assignedTeamMembers}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            defaultValue={assignedTeamMembers}
            onChange={(e, value) => {
              setAssignedTeamMembers(value && value);
            }}
            renderTags={(value, props) =>
              value.map((option, index) => (
                <Chip
                  label={option.name}
                  {...props({ index })}
                  style={{ color: "#2196F3", background: "#35A0F426" }}
                  sx={{
                    "& .MuiChip-deleteIcon": {
                      color: "#2196F3",
                    },
                  }}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Add Team Members"
              />
            )}
          />

          <Typography
            component="label"
            variant="boxHeading"
            color="disabled.boxHeading"
            textTransform="uppercase"
          >
            Case Description
          </Typography>

          <TextField
            margin="dense"
            label="Add Case Description"
            variant="outlined"
            name="caseDescription"
            multiline
            rows={4}
            // error={isTouched && !!error}
            // helperText={error?.message}
            value={caseDescription}
            onChange={(e) => setCaseDescription(e.target.value)}
          />

          {/* <Typography
   component="label"
   variant="boxHeading"
   color="disabled.boxHeading"
   textTransform="uppercase"
 >
   Witness information
 </Typography>

 <TextField
   margin="dense"
   label="Name"
   variant="outlined"
   name="witnessName"
   fullWidth
   width="100%"
   // error={isTouched && !!error}
   // helperText={error?.message}
   value={witnessName}
   onChange={(e) => setWitnessName(e.target.value)}
 />
 <TextField
   margin="dense"
   label="Contact Information"
   variant="outlined"
   name="witnessNumber"
   fullWidth
   width="100%"
   // error={isTouched && !!error}
   // helperText={error?.message}
   value={witnessNumber}
   onChange={(e) => setWitnessNumber(e.target.value)}
 />

 <Button variant="text" sx={{ alignSelf: "flex-start" }}>
   Add More Witness
 </Button> */}

          <Typography
            component="label"
            variant="boxHeading"
            color="disabled.boxHeading"
            textTransform="uppercase"
          >
            ADDITIONAL INFORMATION
          </Typography>

          <TextField
            margin="dense"
            label="Discovery Information"
            variant="outlined"
            name="discoveryInformation"
            multiline
            rows={4}
            // error={isTouched && !!error}
            // helperText={error?.message}
            value={discoveryInformation}
            onChange={(e) => setDiscoveryInformation(e.target.value)}
          />

          <TextField
            margin="dense"
            label="Insurance Details"
            variant="outlined"
            name="insuranceDetails"
            multiline
            rows={4}
            // error={isTouched && !!error}
            // helperText={error?.message}
            value={insuranceDetails}
            onChange={(e) => setInsuranceDetails(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Court Orders"
            variant="outlined"
            name="courtOrders"
            multiline
            rows={4}
            // error={isTouched && !!error}
            // helperText={error?.message}
            value={courtOrders}
            onChange={(e) => setCourtOrders(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Expenses"
            variant="outlined"
            name="expenses"
            multiline
            rows={4}
            // error={isTouched && !!error}
            // helperText={error?.message}
            value={expenses}
            onChange={(e) => setExpenses(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Notes/Comments"
            variant="outlined"
            name="caseNote"
            multiline
            rows={4}
            // error={isTouched && !!error}
            // helperText={error?.message}
            value={caseNote}
            onChange={(e) => setCaseNote(e.target.value)}
          />

          <LoadingButton
            id="update-case-submit"
            color="primary"
            variant="contained"
            sx={{ alignSelf: "flex-end" }}
            loading={loadingUpdateMembers || loadingUpdate}
            disabled={isDisabled || loading}
            onClick={editCaseHandler}
          >
            Save changes
          </LoadingButton>
        </Stack>
      )}
    </CommonDrawer>
  );
};

export default EditCaseDrawer;
