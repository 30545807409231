import {
  Alert,
  Autocomplete,
  Avatar,
  AvatarGroup,
  Box,
  Button,
  Chip,
  IconButton,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CardUI from "../../../common/Card";
import HeaderText from "../../../common/HeaderText";
import companyLogo from "../../../assets/images/company-logo.jpg";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  addQuestionToCaseFunc,
  getCaseDetailByCaseId,
  resetAddQuestionToCase,
  resetUpdateCase,
  updateCaseFunc,
} from "../../../actions/Cases";
import dayjs from "dayjs";
import AvatarGroups from "../../../components/AvatarGroups";
import {
  caseTypeName,
  caseTypeOptions,
  dateFormat,
  findOptionByValue,
} from "../../../utils/helpers";
import ReminderIcon from "../../../icons/ReminderIcon";
import AddIcon from "@mui/icons-material/Add";
import {
  intakeQuestionsAnswersFunc,
  intakeReminderFunc,
  resetIntakeQuestionsAnswers,
  resetIntakeReminder,
} from "../../../actions/ClientIntakeActions";
import CircularProgressLoading from "../../../common/CircularProgress";
import SkeletonGrid from "../../../components/SkeletonGrid";
import CaseMoreInfoSkeleton from "../../../components/CaseMoreInfoSkeleton";
import AlertDialog from "../../../components/AlertDialog";
import IntakeQuestionField from "../../../components/IntakeQuestionField";
import DeleteIcon from "../../../icons/DeleteIcon";
import IntakeQuestionWaitingIcon from "../../../icons/IntakeQuestionWaitingIcon";
import EditIcon from "../../../icons/EditIcon";
import EditCaseField from "../../../components/EditCaseField";
import { segmentError } from "../../../utils/helpers/tracking_utils";
import { appInsights } from "../../../utils/helpers/AppInsights";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
  getAgentsListFunc,
  resetUpdatedTeamMember,
  updateAgentTeamMembersFunc,
} from "../../../actions/Agency";

const MoreInfoTab = ({ caseDetail, loading }) => {
  // const { id } = useParams();
  const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [openMoreQuestionModal, setOpenMoreQuestionModal] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [questionText, setQuestionText] = useState("");
  const [helperTextValues, setHelperTextValues] = useState("");
  const [isTouched, setIsTouched] = useState(false);
  const [isRequired, setIsRequired] = useState(false);
  const [showHelperText, setShowHelperText] = useState(false);
  const [questionsArr, setQuestionsArr] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [modalStates, setModalStates] = useState({
    description: false,
    incident_location: false,
    opposing_party: false,
    jurisdiction: false,
    case_type: false,
    incident_date: false,
    case_opening_date: false,
    case_registration_date: false,
    statute_of_limitation: false,
    discovery_information: false,
    insurance_information: false,
    court_orders: false,
    expenses: false,
    members: false,
  });

  // EDIT CASE FIELD STATES
  const [caseTitle, setCaseTitle] = useState("");
  const [locationOfIncident, setLocationOfIncident] = useState("");
  const [opposingParty, setOpposingParty] = useState("");
  const [jurisdiction, setJurisdiction] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [caseType, setCaseType] = useState(null);
  const [dateOfIncident, setDateOfIncident] = useState(null);
  const [caseOpenDate, setCaseOpenDate] = useState(null);
  const [statueOfLimitation, setStatueOfLimitation] = useState(null);
  const [caseFillingDate, setCaseFillingDate] = useState(null);
  const [witnessName, setWitnessName] = useState("");
  const [witnessNumber, setWitnessNumber] = useState("");
  const [discoveryInformation, setDiscoveryInformation] = useState("");
  const [insuranceDetails, setInsuranceDetails] = useState("");
  const [courtOrders, setCourtOrders] = useState("");
  const [expenses, setExpenses] = useState("");
  const [caseNote, setCaseNote] = useState("");
  const [assignedTeamMembers, setAssignedTeamMembers] = useState([]);
  const [caseDescriptionValue, setCaseDescriptionValue] = useState("");
  const [caseFieldIsTouched, setCaseFieldIsTouched] = useState(false);
  const [caseAttributeField, setCaseAttributeField] = useState(null);

  const caseId = caseDetail?.case?.id;
  const clientId = caseDetail?.case?.client?.id;
  const dispatch = useDispatch();

  const getAgentsList = useSelector((state) => state.getAgentsList);
  const { loading: loadingAgent, error: errorAgent, allAgents } = getAgentsList;

  const intakeQuestionsAnswers = useSelector(
    (state) => state.intakeQuestionsAnswers
  );
  const {
    loading: loadingIntakeQuestionsAnswers,
    error: errorIntakeQuestionsAnswers,
    allQuestionsAnswers,
  } = intakeQuestionsAnswers;

  const addQuestionToCase = useSelector((state) => state.addQuestionToCase);
  const {
    loading: loadingAddQuestionToCase,
    error: errorAddQuestionToCase,
    addedQuestion,
  } = addQuestionToCase;

  const intakeReminder = useSelector((state) => state.intakeReminder);
  const {
    loading: loadingintakeReminder,
    error: errorintakeReminder,
    intakeClientReminder,
  } = intakeReminder;

  const agentProfile = useSelector((state) => state.agentProfile);
  const {
    loading: loadingAgentSelf,
    error: errorAgentSelf,
    agentUser,
  } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const updateCase = useSelector((state) => state.updateCase);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    updatedCase,
  } = updateCase;

  const updateAgentTeamMembers = useSelector(
    (state) => state.updateAgentTeamMembers
  );
  const {
    loading: loadingUpdateMembers,
    error: errorUpdateMembers,
    updatedAgentTeamMembers,
  } = updateAgentTeamMembers;

  const allQuestions =
    caseDetail?.case?.intake_questions &&
    JSON.parse(caseDetail?.case?.intake_questions);

  const allAgentsOptions =
    allAgents?.agents_list.length > 0
      ? allAgents?.agents_list?.filter(
          (agent) => agent?.agent_type !== "mcc_support"
        )
      : [];

  const assignedTeamMembersId = assignedTeamMembers?.map((member) => member.id);

  useEffect(() => {
    if (clientId && caseId) {
      dispatch(intakeQuestionsAnswersFunc(clientId, caseId));
      setCaseDescriptionValue(caseDetail?.case?.description || "");
      setJurisdiction(caseDetail?.case?.jurisdiction || "");
      setExpenses(caseDetail?.case?.expenses || "");
      setDiscoveryInformation(caseDetail?.case?.discovery_information || "");
      setOpposingParty(caseDetail?.case?.opposing_party || "");
      setLocationOfIncident(caseDetail?.case?.incident_location || "");
      setAssignedTeamMembers(
        caseDetail?.case?.agents ? caseDetail?.case?.agents : []
      );
      setInsuranceDetails(caseDetail?.case?.insuranceDetails || "");
      setCourtOrders(caseDetail?.case?.court_orders || "");
      setDateOfIncident(dayjs(caseDetail?.case?.incident_date) || null);
      setCaseOpenDate(dayjs(caseDetail?.case?.case_opening_date) || null);
      setStatueOfLimitation(
        dayjs(caseDetail?.case?.statute_of_limitation) || null
      );
      setCaseFillingDate(
        dayjs(caseDetail?.case?.case_registration_date) || null
      );
      setCaseType(caseDetail?.case?.case_type || null);
    }
  }, [clientId, caseId]);

  useEffect(() => {
    if (allQuestionsAnswers?.ok) {
      setQuestionsAnswers(allQuestionsAnswers?.intake_answers);
      resetIntakeQuestionsAnswers();
    }
  }, [allQuestionsAnswers]);

  const intakeReminderHandler = () => {
    const intakeObj = {
      client_id: clientId,
      case_id: caseId,
    };
    dispatch(intakeReminderFunc(intakeObj));
    setOpenSnackbar(true);
  };

  useEffect(() => {
    if (intakeClientReminder?.ok) {
      resetIntakeReminder();
    }
  }, [intakeClientReminder]);

  const openEditFieldModalHandler = (id) => {
    if (!allAgents) {
      dispatch(getAgentsListFunc());
    }
    setCaseDescriptionValue(caseDetail?.case?.description);
    setLocationOfIncident(caseDetail?.case?.incident_location);
    setAssignedTeamMembers(caseDetail?.case?.agents);
    setDateOfIncident(dayjs(caseDetail?.case?.incident_date));
    setCaseOpenDate(dayjs(caseDetail?.case?.case_opening_date));
    setCaseFillingDate(dayjs(caseDetail?.case?.case_registration_date));
    setStatueOfLimitation(dayjs(caseDetail?.case?.statute_of_limitation));
    setCaseType(caseDetail?.case?.case_type);
    setJurisdiction(caseDetail?.case?.jurisdiction);
    setExpenses(caseDetail?.case?.expenses);
    setDiscoveryInformation(caseDetail?.case?.discovery_information);
    setInsuranceDetails(caseDetail?.case?.insuranceDetails);
    setCourtOrders(caseDetail?.case?.court_orders);
    setOpposingParty(caseDetail?.case?.opposing_party);
    if (modalStates[id]) {
      setModalStates((prevStates) => ({
        ...prevStates,
        [id]: false,
      }));
    } else {
      setModalStates((prevStates) => {
        const updatedStates = { ...prevStates };
        Object.keys(updatedStates).forEach((key) => {
          updatedStates[key] = key === id; // Set the state to true only for the clicked id
        });
        return updatedStates;
      });
    }
  };

  const closeEditModalHandler = (id) => {
    setModalStates((prevStates) => ({
      ...prevStates,
      [id]: false,
    }));
    setCaseFieldIsTouched(false);
    setCaseDescriptionValue(caseDetail?.case?.description);
    setLocationOfIncident(caseDetail?.case?.incident_location);
    setAssignedTeamMembers(caseDetail?.case?.agents);
    setStatueOfLimitation(dayjs(caseDetail?.case?.statute_of_limitation));
    setDateOfIncident(dayjs(caseDetail?.case?.incident_date));
    setCaseOpenDate(dayjs(caseDetail?.case?.case_opening_date));
    setCaseFillingDate(dayjs(caseDetail?.case?.case_registration_date));
    setCaseType(caseDetail?.case?.case_type);
    setJurisdiction(caseDetail?.case?.jurisdiction);
    setExpenses(caseDetail?.case?.expenses);
    setDiscoveryInformation(caseDetail?.case?.discovery_information);
    setInsuranceDetails(caseDetail?.case?.insuranceDetails);
    setCourtOrders(caseDetail?.case?.court_orders);
    setOpposingParty(caseDetail?.case?.opposing_party);
  };

  const saveFieldHandler = (value, case_attribute) => {
    setCaseFieldIsTouched(true);
    setCaseAttributeField(case_attribute);

    if (!value || value.length === 0) {
      return;
    }

    // const caseData = {
    //   title: caseTitle,
    //   opposing_party: opposingParty,
    //   case_type: caseType,
    //   case_number: caseNumber,
    //   description: caseDescription,
    //   incident_date: dateOfIncident,
    //   case_opening_date: caseOpenDate,
    //   case_registration_date: caseFillingDate,
    //   statute_of_limitation: statueOfLimitation,
    //   incident_location: locationOfIncident,
    //   jurisdiction: jurisdiction,
    //   witnesses: JSON.stringify([]),
    //   discovery_information: discoveryInformation,
    //   insurance_information: insuranceDetails,
    //   court_orders: courtOrders,
    //   expenses: expenses,
    //   notes: caseNote,
    // };

    if (case_attribute === "members") {
      const assignTeamMemberData = {
        case_id: caseId,
        members: assignedTeamMembersId,
      };
      dispatch(updateAgentTeamMembersFunc(assignTeamMemberData));
    } else {
      const updatedCaseData = {
        [case_attribute]: value,
      };

      const caseConfig = {
        case_id: caseDetail?.case?.id,
        case_obj: updatedCaseData,
      };

      dispatch(updateCaseFunc(caseConfig));
    }
  };

  useEffect(() => {
    if (updatedAgentTeamMembers?.ok) {
      dispatch(resetUpdatedTeamMember());
      dispatch(getCaseDetailByCaseId(caseDetail?.case?.id));
      closeEditModalHandler(caseAttributeField);
    } else if (errorUpdateMembers) {
      appInsights.trackTrace({
        message: `Error while updating agent team members: ${
          errorUpdateMembers || "Could not update agent team members!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_UPDATING_AGENT",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorUpdateMembers || "Could not update agent team member!"}`,
      });
    }
  }, [updatedAgentTeamMembers, errorUpdateMembers]);

  useEffect(() => {
    if (updatedCase?.ok) {
      dispatch(resetUpdateCase());
      dispatch(getCaseDetailByCaseId(caseDetail?.case?.id));
      closeEditModalHandler(caseAttributeField);
    } else if (errorUpdate) {
      appInsights.trackTrace({
        message: `Error while editing case: ${
          errorUpdate || "Could not edit the case!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_EDIT_CASE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorUpdate || "Could not edit the case!"}`,
      });
    }
  }, [updatedCase, errorUpdate]);

  // ================ MORE INFO QUESTION REQUEST =============================
  const openMoreQuestionModalHandler = () => {
    setOpenMoreQuestionModal(true);
    setModalContentData({ title: "Request More Information" });
  };

  const saveQuestionHandler = () => {
    setIsTouched(true);

    if (!questionText) return;
    const questionObj = {
      question_text: questionText,
      question_type: "text",
      is_required: isRequired,
      helper_text: helperTextValues,
    };

    // Add new question
    setQuestionsArr((prev) => [questionObj, ...prev]);

    setQuestionText("");
    setIsRequired(false);
    setIsTouched(false);
    setHelperTextValues("");
    setShowHelperText(false);
  };

  const changeHelperTextHandler = (e) => {
    if (!e.target.checked) {
      setHelperTextValues("");
      setShowHelperText(e.target.checked);
    } else {
      setShowHelperText(e.target.checked);
    }
  };

  const deleteQuestionHandler = (i) => {
    setQuestionText("");
    setHelperTextValues("");
    setIsRequired(false);
    setShowHelperText(false);
    setQuestionsArr((prevState) => prevState.filter((_, index) => index !== i));
  };

  const changeTextHandler = (e) => {
    setQuestionText(e.target.value);
  };

  const addQuestionToCaseHandler = () => {
    const newQuestionData = {
      questions: questionsArr,
      case_id: caseId,
    };

    dispatch(addQuestionToCaseFunc(newQuestionData));
  };

  useEffect(() => {
    if (addedQuestion?.ok) {
      setQuestionsArr([]);
      setOpenMoreQuestionModal(false);
      setIsTouched(false);
      setShowHelperText(false);
      dispatch(getCaseDetailByCaseId(caseId));
      dispatch(resetAddQuestionToCase());
    }
  }, [addedQuestion]);

  const showError = isTouched && !questionText;

  return (
    <>
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={() => setOpenSnackbar(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Alert
            onClose={() => setOpenSnackbar(false)}
            severity="success"
            color="success"
            sx={{ width: "100%" }}
          >
            Reminder sent
          </Alert>
        </Snackbar>
      )}

      {/* =============== OPEN MORE QUESTION BOX  =====================  */}
      {openMoreQuestionModal && (
        <AlertDialog
          openModal={openMoreQuestionModal}
          closeModal={() => {
            setQuestionsArr([]);
            setIsTouched(false);
            setShowHelperText(false);
            setOpenMoreQuestionModal(false);
          }}
          data={modalContentData}
          submitText="Send request"
          maxWidth="703px"
          cancelColor=""
          isLoading={loadingAddQuestionToCase}
          disabled={!questionsArr?.length}
          padding="16px 32px 32px 32px"
          formSubmitFunction={addQuestionToCaseHandler}
        >
          <Stack gap="16px">
            <Stack gap="4px">
              <Typography
                variant="subtitle2"
                color="secondary"
                fontWeight={400}
              >
                Request From
              </Typography>
              <Box display="flex" alignItems="center" gap="8px">
                <Avatar
                  src={
                    caseDetail?.case?.client?.profile_picture_filename &&
                    caseDetail?.case?.client?.profile_picture_url
                  }
                  alt="name"
                />
                <Stack>
                  <Typography
                    fontSize="16px"
                    variant="body1"
                    color="#000"
                    fontWeight="400"
                  >
                    {caseDetail?.case?.client?.name
                      ? caseDetail?.case?.client?.name
                      : "-"}
                  </Typography>

                  <Typography
                    variant="body2"
                    color="rgba(0, 0, 0, 0.38)"
                    fontWeight="400"
                  >
                    {caseDetail?.case?.client?.email}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
            <Typography variant="subtitle2" color="secondary" fontWeight={400}>
              Questions
            </Typography>
            <IntakeQuestionField
              questionText={questionText}
              changeTextHandler={changeTextHandler}
              showError={showError}
              saveQuestionHandler={saveQuestionHandler}
              isRequired={isRequired}
              setIsRequired={setIsRequired}
              showHelperText={showHelperText}
              changeHelperTextHandler={changeHelperTextHandler}
              helperTextValues={helperTextValues}
              setHelperTextValues={setHelperTextValues}
              label="Type your question here"
              showDragIcon={false}
              showRequired={false}
            />
            <Stack gap="8px" maxHeight="200px" overflow="auto">
              {questionsArr?.map((question, index) => (
                <Box
                  display="flex"
                  alignItems="center"
                  borderRadius="4px"
                  border="1px solid #EBEBEB"
                  borderLeft="4px solid #0C80F3"
                  padding="2px 16px"
                  gap="16px"
                  key={index}
                >
                  <Stack flex="1">
                    <Typography
                      variant="body1"
                      color="rgba(0, 0, 0, 0.60)"
                      sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "440px",
                        overflow: "hidden",
                      }}
                    >
                      {question?.question_text}
                    </Typography>
                    {/* {question?.helper_text && (
                    <Typography
                      variant="body2"
                      fontSize="12px"
                      sx={{
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "440px",
                        overflow: "hidden",
                      }}
                    >
                      {question?.helper_text}
                    </Typography>
                  )} */}
                  </Stack>
                  {question?.is_required && (
                    <Typography width="134px" variant="caption">
                      Required
                    </Typography>
                  )}
                  <Box display="flex" alignItems="center" gap="16px">
                    <IconButton
                      onClick={() => {
                        deleteQuestionHandler(index);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Stack>
          </Stack>
        </AlertDialog>
      )}

      <Box
        display="flex"
        width="100%"
        gap="18px"
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <CardUI
          maxWidth="100%"
          dashboard="true"
          padding="24px"
          overflow="inherit"
        >
          {loading ? (
            <CaseMoreInfoSkeleton leftCard={true} />
          ) : (
            <Stack gap="32px">
              <Stack gap="16px" alignItems="flex-start">
                <HeaderText
                  variant="h3"
                  textContent="Case Information"
                  fontSize="18px"
                />
                {/* CASE DESCRIPTION */}
                <EditCaseField
                  id="description"
                  label="Case Description"
                  value={caseDescriptionValue}
                  displayValue={caseDescriptionValue}
                  closeEditModal={closeEditModalHandler}
                  modalStates={modalStates}
                  saveFieldHandler={saveFieldHandler}
                  openEditFieldModalHandler={openEditFieldModalHandler}
                  loadingUpdatedCase={loadingUpdate}
                  padding="4px"
                >
                  <TextField
                    label="Case Description"
                    size="small"
                    value={caseDescriptionValue}
                    variant="outlined"
                    onChange={(e) => setCaseDescriptionValue(e.target.value)}
                    multiline
                    rows={4}
                    fullWidth
                    error={!caseDescriptionValue && caseFieldIsTouched}
                  />
                </EditCaseField>
              </Stack>

              <Stack gap="16px">
                {/* ASSIGNED TO */}
                <EditCaseField
                  id="members"
                  label="Assigned to"
                  value={assignedTeamMembers}
                  displayValue={caseDetail?.case?.agents}
                  closeEditModal={closeEditModalHandler}
                  modalStates={modalStates}
                  saveFieldHandler={saveFieldHandler}
                  openEditFieldModalHandler={openEditFieldModalHandler}
                  loadingUpdatedCase={loadingUpdateMembers}
                  padding="4px"
                  assignedTo={true}
                >
                  <Autocomplete
                    multiple
                    id="assignTeamMembers"
                    options={allAgentsOptions}
                    getOptionLabel={(option) =>
                      option.name ? option.name : []
                    }
                    filterSelectedOptions
                    value={assignedTeamMembers}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                    defaultValue={assignedTeamMembers}
                    onChange={(e, value) => {
                      setAssignedTeamMembers(value && value);
                    }}
                    renderTags={(value, props) =>
                      value.map((option, index) => (
                        <Chip
                          label={option.name}
                          {...props({ index })}
                          style={{ color: "#2196F3", background: "#35A0F426" }}
                          sx={{
                            "& .MuiChip-deleteIcon": {
                              color: "#2196F3",
                            },
                          }}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Add Team Members"
                        error={
                          assignedTeamMembers?.length === 0 &&
                          caseFieldIsTouched
                        }
                      />
                    )}
                  />
                </EditCaseField>
                {/* <Stack>
                  <Typography
                    color="secondary"
                    pb="4px"
                    fontSize="14px"
                    variant="body1"
                  >
                    Assigned to
                  </Typography>

                  <AvatarGroups
                    assigned_to_details={caseDetail?.case?.agents}
                  />
                </Stack> */}

                <Stack gap="4px" width="100%">
                  {/* CASE TYPE AND OPPOSING PARTY */}
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="4px"
                  >
                    {/* CASE TYPE */}

                    <EditCaseField
                      id="case_type"
                      label="Case Type"
                      value={caseType}
                      displayValue={caseTypeName[caseType]}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                      padding="4px"
                    >
                      <Autocomplete
                        disablePortal
                        id="case_type"
                        value={findOptionByValue(caseType)}
                        onChange={(event, newValue) => {
                          setCaseType(newValue?.value || null);
                        }}
                        options={caseTypeOptions}
                        getOptionLabel={(option) => option?.title}
                        fullWidth
                        renderInput={(params) => (
                          <TextField {...params} label="Case Type" />
                        )}
                      />
                    </EditCaseField>

                    {/* OPPOSING PARTY */}
                    <EditCaseField
                      id="opposing_party"
                      label="Opposing Party"
                      value={opposingParty}
                      displayValue={opposingParty}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                    >
                      <TextField
                        label="Opposing Party"
                        size="small"
                        value={opposingParty}
                        variant="outlined"
                        onChange={(e) => setOpposingParty(e.target.value)}
                        fullWidth
                        error={!opposingParty && caseFieldIsTouched}
                      />
                    </EditCaseField>
                  </Box>

                  {/* DATE OF INCIDENT AND CASE OPEN DATE   */}
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* DATE OF INCIDENT */}
                    <EditCaseField
                      id="incident_date"
                      label="Date of incident"
                      value={dateOfIncident}
                      displayValue={dateFormat(dateOfIncident)}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Date of Incident"
                          value={dateOfIncident}
                          onChange={(newValue) => setDateOfIncident(newValue)}
                          slotProps={{ textField: { fullWidth: true } }}
                          sx={{ marginTop: 1 }}
                          maxDate={dayjs()}
                          textField={(params) => (
                            <TextField {...params} readOnly />
                          )}
                          // renderInput={(params) => <TextField {...params} readOnly />}
                        />
                      </LocalizationProvider>
                    </EditCaseField>

                    {/* CASE OPEN DATE */}
                    <EditCaseField
                      id="case_opening_date"
                      label="Case Open Date"
                      value={caseOpenDate}
                      displayValue={dateFormat(caseOpenDate)}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Case Open Date"
                          value={caseOpenDate}
                          onChange={(newValue) => setCaseOpenDate(newValue)}
                          slotProps={{ textField: { fullWidth: true } }}
                          sx={{ marginTop: 1 }}
                          maxDate={dayjs()}
                          textField={(params) => (
                            <TextField {...params} readOnly />
                          )}
                          // renderInput={(params) => <TextField {...params} readOnly />}
                        />
                      </LocalizationProvider>
                    </EditCaseField>
                  </Box>

                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    {/* CASE FILLING DATE */}
                    <EditCaseField
                      id="case_registration_date"
                      label="Case Filing Date"
                      value={caseFillingDate}
                      displayValue={dateFormat(caseFillingDate)}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Case Filing Date"
                          value={caseFillingDate}
                          onChange={(newValue) => setCaseFillingDate(newValue)}
                          slotProps={{ textField: { fullWidth: true } }}
                          sx={{ marginTop: 1 }}
                          maxDate={dayjs()}
                          textField={(params) => (
                            <TextField {...params} readOnly />
                          )}
                          // renderInput={(params) => <TextField {...params} readOnly />}
                        />
                      </LocalizationProvider>
                    </EditCaseField>

                    {/* STATUTE OF LIMITATION */}
                    <EditCaseField
                      id="statute_of_limitation"
                      label="Statute of Limitation"
                      value={statueOfLimitation}
                      displayValue={dateFormat(statueOfLimitation)}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                    >
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          label="Statute of Limitation"
                          value={statueOfLimitation}
                          onChange={(newValue) =>
                            setStatueOfLimitation(newValue)
                          }
                          slotProps={{ textField: { fullWidth: true } }}
                          sx={{ marginTop: 1 }}
                          maxDate={dayjs()}
                          textField={(params) => (
                            <TextField {...params} readOnly />
                          )}
                          // renderInput={(params) => <TextField {...params} readOnly />}
                        />
                      </LocalizationProvider>
                    </EditCaseField>
                    {/* <Stack width="50%">
                      <Typography
                        fontSize="14px"
                        variant="body1"
                        color="secondary"
                        pb="4px"
                      >
                        Statute of Limitation
                      </Typography>
                      <HeaderText
                        textContent={
                          caseDetail?.case?.statute_of_limitation
                            ? dateFormat(
                                caseDetail?.case?.statute_of_limitation
                              )
                            : "-"
                        }
                        fontSize="16px"
                        variant="body1"
                        color="#000"
                        fontWeight="400"
                      />
                    </Stack> */}
                  </Box>
                  <Box
                    width="100%"
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    gap="4px"
                  >
                    {" "}
                    {/* LOCATION OF INCIDENT */}
                    <EditCaseField
                      id="incident_location"
                      label="Location of Incident"
                      value={locationOfIncident}
                      displayValue={locationOfIncident}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                    >
                      <TextField
                        label="Location of Incident"
                        size="small"
                        value={locationOfIncident}
                        variant="outlined"
                        onChange={(e) => setLocationOfIncident(e.target.value)}
                        fullWidth
                        error={!locationOfIncident && caseFieldIsTouched}
                      />
                    </EditCaseField>
                    <EditCaseField
                      id="jurisdiction"
                      label="Jurisdiction"
                      value={jurisdiction}
                      displayValue={jurisdiction}
                      closeEditModal={closeEditModalHandler}
                      modalStates={modalStates}
                      saveFieldHandler={saveFieldHandler}
                      openEditFieldModalHandler={openEditFieldModalHandler}
                      loadingUpdatedCase={loadingUpdate}
                    >
                      <TextField
                        label="Jurisdiction"
                        size="small"
                        value={jurisdiction}
                        variant="outlined"
                        onChange={(e) => setJurisdiction(e.target.value)}
                        fullWidth
                        error={!jurisdiction && caseFieldIsTouched}
                      />
                    </EditCaseField>
                  </Box>
                </Stack>
              </Stack>

              {/* Discovery Information */}
              <EditCaseField
                id="discovery_information"
                label="Discovery Information"
                value={discoveryInformation}
                displayValue={discoveryInformation}
                closeEditModal={closeEditModalHandler}
                modalStates={modalStates}
                saveFieldHandler={saveFieldHandler}
                openEditFieldModalHandler={openEditFieldModalHandler}
                loadingUpdatedCase={loadingUpdate}
              >
                <TextField
                  label="Discovery Information"
                  size="small"
                  value={discoveryInformation}
                  variant="outlined"
                  onChange={(e) => setDiscoveryInformation(e.target.value)}
                  fullWidth
                  rows={4}
                  multiline
                  error={!discoveryInformation && caseFieldIsTouched}
                />
              </EditCaseField>

              {/* Settlement Information */}
              {/* <EditCaseField
                id="discovery_information"
                label="Discovery Information"
                value={discoveryInformation}
                displayValue={discoveryInformation}
                closeEditModal={closeEditModalHandler}
                modalStates={modalStates}
                saveFieldHandler={saveFieldHandler}
                openEditFieldModalHandler={openEditFieldModalHandler}
                loadingUpdatedCase={loadingUpdate}
              >
                <TextField
                  label="Discovery Information"
                  size="small"
                  value={discoveryInformation}
                  variant="outlined"
                  onChange={(e) => setDiscoveryInformation(e.target.value)}
                  fullWidth
                  rows={4}
                  multiline
                  error={!discoveryInformation && caseFieldIsTouched}
                />
              </EditCaseField> */}
              {/* <Stack gap="4px" alignItems="flex-start">
                <Typography fontSize="14px" variant="body1" color="secondary">
                  Settlement Information
                </Typography>
                <HeaderText
                  textContent="-"
                  fontSize="16px"
                  variant="body1"
                  color="#000"
                  fontWeight="400"
                />
              </Stack> */}

              {/* Insurance Details */}
              <EditCaseField
                id="insurance_information"
                label="Insurance Details"
                value={insuranceDetails}
                displayValue={insuranceDetails}
                closeEditModal={closeEditModalHandler}
                modalStates={modalStates}
                saveFieldHandler={saveFieldHandler}
                openEditFieldModalHandler={openEditFieldModalHandler}
                loadingUpdatedCase={loadingUpdate}
              >
                <TextField
                  label="Insurance Details"
                  size="small"
                  value={insuranceDetails}
                  variant="outlined"
                  onChange={(e) => setInsuranceDetails(e.target.value)}
                  fullWidth
                  rows={4}
                  multiline
                  error={!insuranceDetails && caseFieldIsTouched}
                />
              </EditCaseField>

              {/* Court Orders */}
              <EditCaseField
                id="court_orders"
                label="Court Orders"
                value={courtOrders}
                displayValue={courtOrders}
                closeEditModal={closeEditModalHandler}
                modalStates={modalStates}
                saveFieldHandler={saveFieldHandler}
                openEditFieldModalHandler={openEditFieldModalHandler}
                loadingUpdatedCase={loadingUpdate}
              >
                <TextField
                  label="Court Orders"
                  size="small"
                  value={courtOrders}
                  variant="outlined"
                  onChange={(e) => setCourtOrders(e.target.value)}
                  fullWidth
                  rows={4}
                  multiline
                  error={!courtOrders && caseFieldIsTouched}
                />
              </EditCaseField>

              {/* EXPENSES */}
              <EditCaseField
                id="expenses"
                label="Expenses"
                value={expenses}
                displayValue={expenses}
                closeEditModal={closeEditModalHandler}
                modalStates={modalStates}
                saveFieldHandler={saveFieldHandler}
                openEditFieldModalHandler={openEditFieldModalHandler}
                loadingUpdatedCase={loadingUpdate}
              >
                <TextField
                  label="Expenses"
                  size="small"
                  value={expenses}
                  variant="outlined"
                  onChange={(e) => setExpenses(e.target.value)}
                  fullWidth
                  rows={4}
                  multiline
                  error={!expenses && caseFieldIsTouched}
                />
              </EditCaseField>
              {/* <Stack gap="4px" alignItems="flex-start">
                <Typography fontSize="14px" variant="body1" color="secondary">
                  Expenses
                </Typography>
                <Typography
                  fontSize="16px"
                  variant="body1"
                  color="#000"
                  fontWeight="400"
                >
                  {caseDetail?.case?.expenses
                    ? caseDetail?.case?.expenses
                    : "-"}
                </Typography>
              </Stack> */}
            </Stack>
          )}
        </CardUI>

        {/* INTAKE QUESTIONS CARD */}
        <Stack gap={3} maxWidth="536px" width="100%">
          {/* CLIENT INTAKE QUESTIONS AND ANSWERS */}
          {allQuestions?.length ? (
            <CardUI maxWidth="536px" dashboard="true" padding="24px">
              {loadingIntakeQuestionsAnswers ? (
                <CaseMoreInfoSkeleton />
              ) : (
                <Stack gap="16px" alignItems="flex-start">
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="16px"
                    justifyContent="space-between"
                    width="100%"
                  >
                    <HeaderText
                      variant="h3"
                      textContent="Intake Information"
                      fontSize="18px"
                    />
                    <Box display="flex" alignItems="center" gap="8px">
                      <Button
                        variant="text"
                        startIcon={<AddIcon />}
                        sx={{
                          textTransform: "capitalize",
                        }}
                        onClick={openMoreQuestionModalHandler}
                      >
                        Ask more{" "}
                      </Button>
                      <Tooltip
                        title={"Send Reminder to fill missing details"}
                        placement="top"
                        arrow
                        PopperProps={{
                          sx: {
                            marginBottom: "-7px !important",
                            [`& .${tooltipClasses.tooltip}`]: {
                              maxWidth: 120,
                              padding: "8px",
                            },
                          },
                        }}
                      >
                        <IconButton
                          onClick={intakeReminderHandler}
                          sx={{
                            backgroundColor: "#FF852D1A",
                            borderRadius: "4px",
                            "&:hover": {
                              backgroundColor: "#FF852D1A",
                            },
                          }}
                        >
                          <ReminderIcon />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  {allQuestions?.map((questionAnswer) => {
                    const correspondingAnswer = questionsAnswers?.find(
                      (answer) =>
                        answer?.intake_question_id === questionAnswer?.id
                    );

                    return (
                      <Stack key={questionAnswer?.id}>
                        <Typography
                          variant="subtitle2"
                          color="secondary"
                          fontWeight="400"
                        >
                          {questionAnswer?.question_text}
                        </Typography>
                        {correspondingAnswer?.answer_text ? (
                          <Typography variant="body1">
                            {correspondingAnswer.answer_text}
                          </Typography>
                        ) : (
                          <Box display="flex" alignItems="center" gap="8px">
                            <IntakeQuestionWaitingIcon />
                            <Typography variant="caption" color="#ED6C02">
                              Waiting for client to update information.
                            </Typography>
                          </Box>
                        )}
                      </Stack>
                    );
                  })}
                </Stack>
              )}
            </CardUI>
          ) : (
            <></>
          )}

          {/* CLIENT INFO CARD */}
          <CardUI maxWidth="536px" dashboard="true" padding="24px">
            {loading ? (
              <CaseMoreInfoSkeleton />
            ) : (
              <Stack gap="16px">
                <Stack gap="16px" alignItems="flex-start">
                  <HeaderText
                    variant="h3"
                    textContent="Client Info"
                    fontSize="18px"
                  />
                  <Stack>
                    <Box display="flex" alignItems="center" gap="8px">
                      <Avatar
                        src={
                          caseDetail?.case?.client?.profile_picture_filename &&
                          caseDetail?.case?.client?.profile_picture_url
                        }
                        alt="name"
                      />
                      <Stack>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.name
                            ? caseDetail?.case?.client?.name
                            : "-"}
                        </Typography>

                        <Typography
                          variant="body2"
                          color="rgba(0, 0, 0, 0.38)"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.email}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>

                  <Stack gap="16px" width="100%">
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          Contact Number
                        </Typography>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.contact_number
                            ? caseDetail?.case?.client?.contact_number
                            : "-"}
                        </Typography>
                      </Stack>
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          Date of birth
                        </Typography>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.dob
                            ? dateFormat(caseDetail?.case?.client?.dob)
                            : "-"}
                        </Typography>
                      </Stack>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          Address
                        </Typography>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.address
                            ? caseDetail?.case?.client?.address
                            : "-"}
                        </Typography>
                      </Stack>
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          Country
                        </Typography>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.country
                            ? caseDetail?.case?.client?.country
                            : "-"}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          State
                        </Typography>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.region
                            ? caseDetail?.case?.client?.region
                            : "-"}
                        </Typography>
                      </Stack>
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          City
                        </Typography>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.city
                            ? caseDetail?.case?.client?.city
                            : "-"}
                        </Typography>
                      </Stack>
                    </Box>
                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          Zip code
                        </Typography>

                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.zipcode
                            ? caseDetail?.case?.client?.zipcode
                            : "-"}
                        </Typography>
                      </Stack>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          Job Title
                        </Typography>

                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.job_title
                            ? caseDetail?.case?.client?.job_title
                            : "-"}
                        </Typography>
                      </Stack>
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          Company Name
                        </Typography>
                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.company_name
                            ? caseDetail?.case?.client?.company_name
                            : "-"}
                        </Typography>
                      </Stack>
                    </Box>

                    <Box
                      width="100%"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack width="50%">
                        <Typography
                          fontSize="14px"
                          variant="body1"
                          color="secondary"
                          pb="4px"
                        >
                          Annual Income
                        </Typography>

                        <Typography
                          fontSize="16px"
                          variant="body1"
                          color="#000"
                          fontWeight="400"
                        >
                          {caseDetail?.case?.client?.annual_income
                            ? caseDetail?.case?.client?.annual_income
                            : "-"}
                        </Typography>
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            )}
          </CardUI>

          {/* <CardUI maxWidth="536px" dashboard="true">
          <Stack gap="16px" alignItems="flex-start">
            <HeaderText
              variant="h3"
              textContent="Witness Information"
              fontSize="18px"
            />
            {caseDetail?.case?.witnesses &&
              JSON.parse(caseDetail?.case?.witnesses).map((witness, i) => (
                <Box
                  key={i}
                  width="100%"
                  display="flex"
                  alignItems="flex-start"
                  justifyContent="space-between"
                >
                  <Stack width="50%">
                    <Typography
                      fontSize="14px"
                      variant="body1"
                      color="secondary"
                      pb="4px"
                    >
                      Name
                    </Typography>

                    <Typography
                      fontSize="16px"
                      variant="body1"
                      color="#000"
                      fontWeight="400"
                    >
                      {witness?.name ? witness?.name : "-"}
                    </Typography>
                  </Stack>
                  <Stack width="50%">
                    <Typography
                      fontSize="14px"
                      variant="body1"
                      color="secondary"
                      pb="4px"
                    >
                      Contact information
                    </Typography>
                    <Typography
                      fontSize="16px"
                      variant="body1"
                      color="#000"
                      fontWeight="400"
                    >
                      Ph:{" "}
                      {witness?.contact_information
                        ? witness?.contact_information
                        : "-"}
                    </Typography>
                  </Stack>
                </Box>
              ))}
          </Stack>
        </CardUI> */}
        </Stack>
      </Box>
    </>
  );
};

export default MoreInfoTab;
