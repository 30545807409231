import React from "react";

const UploadBannerIcon = ({ color = "#566875" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path d="M7.49967 14.167V9.16699L5.83301 10.8337" fill="white" />
      <path
        d="M7.49967 14.167V9.16699L5.83301 10.8337"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.5 9.16699L9.16667 10.8337"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3337 8.33366V12.5003C18.3337 16.667 16.667 18.3337 12.5003 18.3337H7.50033C3.33366 18.3337 1.66699 16.667 1.66699 12.5003V7.50033C1.66699 3.33366 3.33366 1.66699 7.50033 1.66699H11.667"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3337 8.33366H15.0003C12.5003 8.33366 11.667 7.50033 11.667 5.00033V1.66699L18.3337 8.33366Z"
        stroke={color}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UploadBannerIcon;
