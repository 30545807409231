import { Checkbox, Stack, Switch, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { set } from "immutable";
import React from "react";

const useStyles = makeStyles(() => {
  return {
    checkbox__radio: {
      width: "75px",
      textAlign: "center",
    },
  };
});

const CheckBoxrow = ({ label, permissions, setPermissions }) => {
  const classes = useStyles();

  const changePermisssionHandler = (key, isChecked) => {
    setPermissions({ ...permissions, [key]: isChecked });
  };

  return (
    <Stack
      justifyContent="space-between"
      direction="row"
      pb={1}
      alignItems="center"
    >
      <Typography
        variant="h4"
        color="disabled.customs"
        sx={{ fontWeight: 400 }}
      >
        {label}
      </Typography>
      <Stack direction="row" gap="24px">
        <Stack alignItems="center" className={classes.checkbox__radio}>
          <Checkbox
            checked={permissions.create}
            onChange={(e) =>
              changePermisssionHandler("create", e.currentTarget.checked)
            }
          />
        </Stack>
        <Stack alignItems="center" className={classes.checkbox__radio}>
          <Checkbox
            checked={permissions.read}
            onChange={(e) =>
              changePermisssionHandler("read", e.currentTarget.checked)
            }
          />
        </Stack>
        <Stack alignItems="center" className={classes.checkbox__radio}>
          <Checkbox
            checked={permissions.edit}
            onChange={(e) =>
              changePermisssionHandler("edit", e.currentTarget.checked)
            }
          />
        </Stack>
        <Stack alignItems="center" className={classes.checkbox__radio}>
          <Checkbox
            checked={permissions.delete}
            onChange={(e) =>
              changePermisssionHandler("delete", e.currentTarget.checked)
            }
          />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default CheckBoxrow;
