import React, { useEffect, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import {
  Box,
  Breadcrumbs,
  Grid,
  Link,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import DocumentGridViewCard from "../DocumentGridViewCard";
import DocumentListViewCard from "../DocumentListViewCard";
import FsLightbox from "fslightbox-react";
import EmptyBox from "../../assets/images/empty-box.png";
import { useDispatch, useSelector } from "react-redux";
import {
  innerFolderViewFunc,
  resetInnerFolderView,
} from "../../actions/DocumentsActions";
import CircularProgressLoading from "../../common/CircularProgress";
import { checkFileExtension } from "../../utils/helpers";
import DocumentMenuItems from "../DocumentMenuItems";

const InnerFolderView = ({
  selectedDoc,
  documentView,
  setInnerFolderView,
  fsLightBoxViewHandler,
  handleDocumentClick,
  setMenuFileIndex,
  setMenuFileDownloadURLs,
  setSelectedDocument,
  lightboxSources,
  toggler,
  openDocumentMenu,
  handleDocumentClose,
  anchorDocumentEl,
  addMoreDocumentsHandler,
  moveFileDialogHandler,
  moveToNewFolderFunction,
  renameDocumentHandler,
  handleDownloadClick,
  documentDeleteHandler,
  innerSelectedFile,
  setInnerSelectedFile,
  handleBoxClick,
  changeCheckBoxHandler,
  changeInnerCheckBoxHandler,
  innerSelectedCheckedDoc,
  innerFolderContents,
  setInnerFolderContents,
  loading,
  fromClientPage = false,
  documentRequestTitle,
  selectedCheckedDoc,
  downloadImgAsPdfHandler,
}) => {
  const isImage =
    innerSelectedFile?.type === "file" &&
    checkFileExtension(innerSelectedFile?.file_name);

  const goBackToRootHandler = () => {
    if (innerSelectedCheckedDoc?.length) {
      return null;
    } else {
      setInnerFolderContents([]);
      setInnerFolderView(null);
      setSelectedDocument(null);
      setInnerSelectedFile(null);
    }
  };

  return (
    <>
      <Stack gap="24px" height="100%">
        <Box display="flex" alignItems="center" gap="16px">
          <Box
            width="20px"
            height="20px"
            boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
            borderRadius="50%"
            bgcolor="#fff"
            display="flex"
            alignItems="center"
            justifyContent="center"
            color="secondary"
            sx={{ cursor: "pointer" }}
            onClick={goBackToRootHandler}
          >
            <ChevronLeftIcon />
          </Box>

          <Breadcrumbs aria-label="documents">
            <Link
              underline="none"
              sx={{ cursor: "pointer" }}
              color="inherit"
              onClick={goBackToRootHandler}
            >
              Documents
            </Link>
            <Typography color="text.primary">{selectedDoc?.name}</Typography>
          </Breadcrumbs>
        </Box>

        {loading ? (
          <CircularProgressLoading />
        ) : !loading && innerFolderContents?.length === 0 ? (
          <Stack
            gap="16px"
            alignItems="center"
            justifyContent="center"
            height="100%"
          >
            <Box width="128px" height="128px">
              <img src={EmptyBox} alt="empty box" width="100%" />
            </Box>
            <Typography variant="body2">
              This folder is empty, use{" "}
              <Box
                component="span"
                color="#0C80F3"
                sx={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={addMoreDocumentsHandler}
              >
                add document
              </Box>{" "}
              button to add files.
            </Typography>
          </Stack>
        ) : (
          <>
            {" "}
            {/* <Stack gap="8px"> */}
            {documentView === "grid" ? (
              <Grid container spacing={3}>
                {innerFolderContents?.map((doc, i) => (
                  <React.Fragment key={i}>
                    <DocumentGridViewCard
                      index={i}
                      fromClientPage={fromClientPage}
                      isFolder={false}
                      fileName={doc?.file_name}
                      blob_name={doc?.blob_name}
                      fileUrl={doc?.file_url}
                      fileTitle={doc?.file_name}
                      numOfFiles={1}
                      fileSize={doc?.file_size}
                      documentType={
                        doc?.file_type === "client_level" ||
                        doc?.folder_type === "client_level"
                          ? "Personal"
                          : fromClientPage
                          ? documentRequestTitle(doc)
                          : documentRequestTitle
                      }
                      handleBoxClick={handleBoxClick}
                      doc={doc}
                      fsLightBoxViewHandler={fsLightBoxViewHandler}
                      handleDocumentClick={handleDocumentClick}
                      setMenuFileIndex={setMenuFileIndex}
                      setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                      singleInnerFolderFile={doc}
                      selectedSingleDoc={selectedDoc}
                      setSelectedDocument={setSelectedDocument}
                      fromInnerFolderView={true}
                      setInnerSelectedFile={setInnerSelectedFile}
                      changeCheckBoxHandler={changeCheckBoxHandler}
                      changeInnerCheckBoxHandler={changeInnerCheckBoxHandler}
                      innerSelectedCheckedDoc={innerSelectedCheckedDoc}
                      selectedCheckedDoc={selectedCheckedDoc}
                      innerModifiedCheckFile={{
                        ...doc,
                        folder_id: selectedDoc?.id,
                        index_id: `${selectedDoc?.id}-${i}`,
                      }}
                    />
                  </React.Fragment>
                ))}
              </Grid>
            ) : (
              <Stack gap="8px">
                {innerFolderContents?.map((doc, i) => (
                  <React.Fragment key={i}>
                    <DocumentListViewCard
                      index={i}
                      isFolder={false}
                      fileName={doc?.file_name}
                      fileUrl={doc?.file_url}
                      fileTitle={doc?.file_name}
                      numOfFiles={1}
                      fileSize={doc?.file_size}
                      documentType={
                        selectedDoc?.document_type === "client_level"
                          ? "Personal"
                          : fromClientPage
                          ? documentRequestTitle(doc)
                          : documentRequestTitle
                      }
                      doc={doc}
                      fsLightBoxViewHandler={fsLightBoxViewHandler}
                      handleDocumentClick={handleDocumentClick}
                      setMenuFileIndex={setMenuFileIndex}
                      setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                      singleInnerFolderFile={doc}
                      selectedSingleDoc={selectedDoc}
                      setSelectedDocument={setSelectedDocument}
                      fromInnerFolderView={true}
                      setInnerSelectedFile={setInnerSelectedFile}
                      handleBoxClick={handleBoxClick}
                      changeCheckBoxHandler={changeCheckBoxHandler}
                      changeInnerCheckBoxHandler={changeInnerCheckBoxHandler}
                      selectedCheckedDoc={selectedCheckedDoc}
                      innerSelectedCheckedDoc={innerSelectedCheckedDoc}
                      innerModifiedCheckFile={{
                        ...doc,
                        folder_id: selectedDoc?.id,
                        index_id: `${selectedDoc?.id}-${i}`,
                      }}
                    />
                  </React.Fragment>
                ))}
              </Stack>
            )}
          </>
        )}

        <Stack>
          <FsLightbox toggler={toggler} sources={lightboxSources} />
          <DocumentMenuItems
            fromInnerFolderView={true}
            anchorDocumentEl={anchorDocumentEl}
            openDocumentMenu={openDocumentMenu}
            handleDocumentClose={handleDocumentClose}
            addMoreDocumentsHandler={addMoreDocumentsHandler}
            innerSelectedDocument={innerSelectedFile}
            moveFileDialogHandler={moveFileDialogHandler}
            moveToNewFolderFunction={moveToNewFolderFunction}
            renameDocumentHandler={renameDocumentHandler}
            handleDownloadClick={handleDownloadClick}
            documentDeleteHandler={documentDeleteHandler}
            downloadImgAsPdfHandler={downloadImgAsPdfHandler}
          />
          {/* <Menu
            id="document-menu"
            anchorEl={anchorDocumentEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={openDocumentMenu}
            onClose={handleDocumentClose}
            MenuListProps={{
              "aria-labelledby": "document-button",
            }}
          >
            <MenuItem key="move" onClick={moveFileDialogHandler}>
              Move
            </MenuItem>
            <MenuItem
              onClick={() => renameDocumentHandler("fromInnerFolderFile")}
            >
              Rename
            </MenuItem>
            {isImage ? (
              [
                <MenuItem key="download-image" onClick={handleDownloadClick}>
                  Download Image
                </MenuItem>,
                <MenuItem key="download-pdf">Download Pdf</MenuItem>,
              ]
            ) : (
              <MenuItem onClick={handleDownloadClick}>Download</MenuItem>
            )}

            <MenuItem onClick={documentDeleteHandler} sx={{ color: "#FF2D2D" }}>
              Delete
            </MenuItem>
          </Menu> */}
        </Stack>
      </Stack>
    </>
  );
};

export default InnerFolderView;
