import React, { useEffect, useState } from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import Style from "../style";
import { makeStyles } from "@mui/styles";
import AlertNotificationCard from "../../AlertNotificationCard";
import EmptyBox from "../../../assets/images/empty-box.png";
import { useDispatch, useSelector } from "react-redux";
import { alertNotificationsFunc } from "../../../actions/AppConfig";
import { page_size } from "../../../assets/constants/appConstants";
import CircularProgressLoading from "../../../common/CircularProgress";
import InfiniteScroll from "react-infinite-scroll-component";
import SkeletonGrid from "../../SkeletonGrid";

const AlertNotifications = ({ tabValue, backgroundColor }) => {
  // const alertNotificationData = notificationData?.filter(
  //   (data) => data?.log_category === "alert"
  // );

  const [alertNotificationData, setAlertNotificationData] = useState([]);
  const [hasNext, setHasNext] = useState(false);
  const [page, setPage] = useState(1);

  const dispatch = useDispatch();
  const getAlertNotifications = useSelector(
    (state) => state.getAlertNotifications
  );
  const { loading, error, alertNotifications } = getAlertNotifications;

  const fetchMoreData = () => {
    setPage((prevState) => prevState + 1);
  };

  useEffect(() => {
    dispatch(alertNotificationsFunc(page, page_size));
  }, [tabValue]);

  useEffect(() => {
    if (!loading && alertNotifications?.ok) {
      setAlertNotificationData((prevState) => [
        ...prevState,
        ...alertNotifications?.alerts,
      ]);

      setHasNext(alertNotifications?.has_next);
    }
  }, [alertNotifications]);

  useEffect(() => {
    if (hasNext) {
      dispatch(alertNotificationsFunc(page, page_size));
    }
  }, [page]);

  return (
    <>
      {loading && alertNotificationData?.length === 0 && (
        <SkeletonGrid itemCount={10} height={100} spacing={2} pt={2} pl={2} />
      )}

      {!loading && alertNotificationData?.length === 0 ? (
        <Stack gap="16px" alignItems="center" justifyContent="center">
          <Box width="128px" height="128px">
            <img src={EmptyBox} alt="empty box" width="100%" />
          </Box>
          <Typography variant="body1">No alert notifications yet</Typography>
        </Stack>
      ) : (
        <div style={{ padding: "16px" }}>
          <InfiniteScroll
            dataLength={alertNotificationData.length || []}
            next={fetchMoreData}
            hasMore={hasNext}
            loader={<CircularProgressLoading size={20} />}
            // scrollableTarget="scrollableDiv"
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "16px",
            }}
            height={885}
          >
            {alertNotificationData?.map((data, index) => (
              <AlertNotificationCard
                key={index}
                notificationData={data}
                backgroundColor={backgroundColor}
              />
            ))}
          </InfiniteScroll>
        </div>
      )}

      {/* <Stack p="24px" gap="16px">
        { 
          alertNotificationData?.map((data, index) => (
            <AlertNotificationCard
              key={index}
              data={data}
              backgroundColor={backgroundColor}
            />
          ))
        ) : (
          <Stack gap="16px" alignItems="center" justifyContent="center">
            <Box width="128px" height="128px">
              <img src={EmptyBox} alt="empty box" width="100%" />
            </Box>
            <Typography variant="body1">No alert notifications yet</Typography>
          </Stack>
        )}
      </Stack> */}
    </>
  );
};

export default AlertNotifications;
