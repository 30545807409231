import React, { useEffect, useState } from "react";

import clsx from "clsx";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import { Box, Stack, Tab, Tabs, Typography } from "@mui/material";

import Style from "./style";
import Team from "./Components/Team";
import General from "./Components/General";
import Cases from "./Components/Cases";
import {
  BillingIcon,
  CasesTabIcon,
  GeneralTabIcon,
  TeamTabIcon,
} from "../../assets/constants";
import { useDispatch, useSelector } from "react-redux";
import {
  resetUpdateAgencyFunc,
  getAgencyDetailFunc,
} from "../../actions/Agency";
import { segmentTrack } from "../../utils/helpers/tracking_utils";
import Billing from "./Components/Billing";
import { useNavigate, useSearchParams } from "react-router-dom";
import { resetAllCases } from "../../actions/Cases";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ pt: 3, height: "100%" }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Styles = makeStyles((theme) => Style(theme));
export default function Settings() {
  const [activeTab, setActiveTab] = useState(0);
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || "general";

  const navigate = useNavigate();

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "settings",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  useEffect(() => {
    if (selectedTab === "general") {
      setActiveTab(0);
    } else if (selectedTab === "case") {
      setActiveTab(1);
    } else if (selectedTab === "team") {
      setActiveTab(2);
    } else {
      setActiveTab(3);
    }
  }, [selectedTab]);

  const handleChange = (event, newValue) => {
    if (newValue === 0) {
      searchParams.set("tab", "general");
    } else if (newValue === 1) {
      searchParams.set("tab", "case");
    } else if (newValue === 2) {
      searchParams.set("tab", "team");
    } else {
      searchParams.set("tab", "billing");
    }

    navigate({ search: `?${searchParams.toString()}` });
    setActiveTab(newValue);
    dispatch(resetUpdateAgencyFunc());
    dispatch(resetAllCases());
  };

  const allTabs = [
    {
      icon: <GeneralTabIcon />,
      title: "General",
      component: <General />,
    },
    {
      icon: <CasesTabIcon />,
      title: "Case",
      component: <Cases />,
    },
    {
      icon: <TeamTabIcon />,
      title: "Team",
      component: <Team />,
    },
    {
      icon: <BillingIcon />,
      title: "Billing",
      component: <Billing />,
    },
  ];
  const classes = Styles();
  return (
    <div>
      <Typography variant="h5">Lawfirm Settings</Typography>
      <Stack pt={2}>
        <Tabs
          className={clsx(classes.tabs__bar)}
          value={activeTab}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {allTabs?.map(
            (tab, key) =>
              tab.title && (
                <Tab
                  iconPosition="start"
                  icon={tab.icon}
                  label={tab.title}
                  key={tab.title}
                  {...a11yProps(key++)}
                />
              )
          )}
        </Tabs>
        <TabPanel
          value={activeTab}
          index={activeTab}
          className={clsx(classes.tab__panel)}
        >
          {allTabs[activeTab].component}
        </TabPanel>
      </Stack>
    </div>
  );
}
