import {
  GET_NOTIFICATIONS_LIST_FAIL,
  GET_NOTIFICATIONS_LIST_REQUEST,
  GET_NOTIFICATIONS_LIST_SUCCESS,
  GET_REFERRALS_BYAGENCY_FAIL,
  GET_REFERRALS_BYAGENCY_REQUEST,
  GET_REFERRALS_BYAGENCY_SUCCESS,
  POST_NOTIFICATION_FAIL,
  POST_NOTIFICATION_REQUEST,
  POST_NOTIFICATION_SUCCESS,
} from "../../assets/constants/referralsConstants";

// GET REFERRALS LIST REDUCER
export const getReferralsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_REFERRALS_BYAGENCY_REQUEST:
      return { loading: true };
    case GET_REFERRALS_BYAGENCY_SUCCESS:
      return { loading: false, referralsList: action.payload };
    case GET_REFERRALS_BYAGENCY_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET NOTIFICATIONS LIST REDUCER
export const getNotificationsListReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS_LIST_REQUEST:
      return { loading: true };
    case GET_NOTIFICATIONS_LIST_SUCCESS:
      return { loading: false, notifications: action.payload };
    case GET_NOTIFICATIONS_LIST_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// POST NOTIFICATION REDUCER
export const postNotificationReducer = (state = {}, action) => {
  switch (action.type) {
    case POST_NOTIFICATION_REQUEST:
      return { loading: true };
    case POST_NOTIFICATION_SUCCESS:
      return { loading: false, newNotification: action.payload };
    case POST_NOTIFICATION_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};
