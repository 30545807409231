import React from "react";

const ChatIcon = ({ color = "#566875" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M7.08317 15.8337H6.6665C3.33317 15.8337 1.6665 15.0003 1.6665 10.8337V6.66699C1.6665 3.33366 3.33317 1.66699 6.6665 1.66699H13.3332C16.6665 1.66699 18.3332 3.33366 18.3332 6.66699V10.8337C18.3332 14.167 16.6665 15.8337 13.3332 15.8337H12.9165C12.6582 15.8337 12.4082 15.9587 12.2498 16.167L10.9998 17.8337C10.4498 18.567 9.54984 18.567 8.99984 17.8337L7.74984 16.167C7.6165 15.9837 7.30817 15.8337 7.08317 15.8337Z"
        fill="white"
        stroke={color}
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3305 9.16667H13.338"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.99607 9.16667H10.0036"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66209 9.16667H6.66957"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
