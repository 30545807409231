import {
  Checkbox,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  Popover,
} from "@mui/material";
import React from "react";
import BodyPartFiltericon from "../../icons/BodyPartFiltericon";
import { expenseTypeListItems } from "../../utils/helpers";

const ExpenseTypeFilter = ({
  selectExpenseTypeHandler,
  expenseTypeAnchorEl,
  checkedItems,
  changeAllExpensesHandler,
  changeExpensesHandler,
  isAllChecked,
  isAllUnchecked,
}) => {
  const isIndeterminate =
    (!isAllChecked &&
      (checkedItems.transportExpenses ||
        checkedItems.medicalExpenses ||
        checkedItems.salaryLoss ||
        checkedItems.others)) ||
    (!isAllUnchecked &&
      (!checkedItems.transportExpenses ||
        !checkedItems.medicalExpenses ||
        !checkedItems.salaryLoss ||
        !checkedItems.others)) ||
    (!isAllChecked &&
      !isAllUnchecked &&
      Object.values(checkedItems).some((item) => item));

  return (
    <>
      <IconButton
        onClick={selectExpenseTypeHandler}
        sx={{ backgroundColor: "#F4F6F8 !important", borderRadius: "4px" }}
      >
        <BodyPartFiltericon />
      </IconButton>
      {/* POPOVER BODY PARTS FILTERS */}
      <Popover
        open={Boolean(expenseTypeAnchorEl)}
        anchorEl={expenseTypeAnchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <List style={{ maxHeight: 200, overflowY: "auto" }}>
          <ListItem onClick={(e) => e.stopPropagation()}>
            <FormControlLabel
              label="All"
              control={
                <Checkbox
                  checked={isAllChecked}
                  indeterminate={isIndeterminate}
                  onChange={changeAllExpensesHandler}
                />
              }
            />
          </ListItem>
          {expenseTypeListItems?.map((item) => (
            <ListItem key={item.key} onClick={(e) => e.stopPropagation()}>
              <FormControlLabel
                label={item.label}
                control={
                  <Checkbox
                    checked={checkedItems[item.key]}
                    onChange={changeExpensesHandler(item.key)}
                  />
                }
              />
            </ListItem>
          ))}
        </List>
      </Popover>
    </>
  );
};

export default ExpenseTypeFilter;
