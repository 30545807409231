import {
  ADD_INTAKE_ANSWER_FAIL,
  ADD_INTAKE_ANSWER_REQUEST,
  ADD_INTAKE_ANSWER_SUCCESS,
  CREATE_PRESET_FAIL,
  CREATE_PRESET_REQUEST,
  CREATE_PRESET_SUCCESS,
  DELETE_PRESET_FAIL,
  DELETE_PRESET_REQUEST,
  DELETE_PRESET_SUCCESS,
  GET_CLIENT_INTAKE_LISTS_FAIL,
  GET_CLIENT_INTAKE_LISTS_REQUEST,
  GET_CLIENT_INTAKE_LISTS_SUCCESS,
  GET_PRESET_FAIL,
  GET_PRESET_REQUEST,
  GET_PRESET_SUCCESS,
  GET_QUESTIONS_BY_CTYPE_FAIL,
  GET_QUESTIONS_BY_CTYPE_REQUEST,
  GET_QUESTIONS_BY_CTYPE_SUCCESS,
  INTAKE_QUESTIONS_ANSWERS_FAIL,
  INTAKE_QUESTIONS_ANSWERS_REQUEST,
  INTAKE_QUESTIONS_ANSWERS_SUCCESS,
  INTAKE_REMINDER_FAIL,
  INTAKE_REMINDER_REQUEST,
  INTAKE_REMINDER_SUCCESS,
  RESET_ADD_INTAKE_ANSWER,
  RESET_CREATE_PRESET,
  RESET_DELETE_PRESET,
  RESET_GET_QUESTIONS_BY_CTYPE,
  RESET_INTAKE_QUESTIONS_ANSWERS,
  RESET_INTAKE_REMINDER,
  RESET_PRESET_DETAIL,
  RESET_UPDATE_PRESET,
  UPDATE_PRESET_FAIL,
  UPDATE_PRESET_REQUEST,
  UPDATE_PRESET_SUCCESS,
} from "../../assets/constants/clientIntakeConstants";

// GET CLIENT INTAKE LISTS
export const getClientIntakeListsReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_CLIENT_INTAKE_LISTS_REQUEST:
      return { loading: true };
    case GET_CLIENT_INTAKE_LISTS_SUCCESS:
      return { loading: false, clientIntakeLists: action.payload };
    case GET_CLIENT_INTAKE_LISTS_FAIL:
      return { loading: false, error: action.payload };
    default:
      return state;
  }
};

// GET PRESET DETAIL
export const getPresetDetailReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_PRESET_REQUEST:
      return { loading: true };
    case GET_PRESET_SUCCESS:
      return { loading: false, presetDetail: action.payload };
    case GET_PRESET_FAIL:
      return { loading: false, error: action.payload };
    case RESET_PRESET_DETAIL:
      return {};
    default:
      return state;
  }
};

// CREATE PRESET DETAIL
export const createPresetReducer = (state = {}, action) => {
  switch (action.type) {
    case CREATE_PRESET_REQUEST:
      return { loading: true };
    case CREATE_PRESET_SUCCESS:
      return { loading: false, newPreset: action.payload };
    case CREATE_PRESET_FAIL:
      return { loading: false, error: action.payload };
    case RESET_CREATE_PRESET:
      return {};
    default:
      return state;
  }
};

// UPDATE PRESET DETAIL
export const updatePresetReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PRESET_REQUEST:
      return { loading: true };
    case UPDATE_PRESET_SUCCESS:
      return { loading: false, updatedPreset: action.payload };
    case UPDATE_PRESET_FAIL:
      return { loading: false, error: action.payload };
    case RESET_UPDATE_PRESET:
      return { loading: false, error: null, updatedPreset: {} };
    default:
      return state;
  }
};

// DELETE PRESET DETAIL
export const deletePresetReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_PRESET_REQUEST:
      return { loading: true };
    case DELETE_PRESET_SUCCESS:
      return { loading: false, deletedPreset: action.payload };
    case DELETE_PRESET_FAIL:
      return { loading: false, error: action.payload };
    case RESET_DELETE_PRESET:
      return {};
    default:
      return state;
  }
};

// INTAKE QUESTIONS ANSWERS
export const intakeQuestionsAnswersReducer = (state = {}, action) => {
  switch (action.type) {
    case INTAKE_QUESTIONS_ANSWERS_REQUEST:
      return { loading: true };
    case INTAKE_QUESTIONS_ANSWERS_SUCCESS:
      return { loading: false, allQuestionsAnswers: action.payload };
    case INTAKE_QUESTIONS_ANSWERS_FAIL:
      return { loading: false, error: action.payload };
    case RESET_INTAKE_QUESTIONS_ANSWERS:
      return {};
    default:
      return state;
  }
};

// INTAKE REMINDER ANSWERS
export const intakeReminderReducer = (state = {}, action) => {
  switch (action.type) {
    case INTAKE_REMINDER_REQUEST:
      return { loading: true };
    case INTAKE_REMINDER_SUCCESS:
      return { loading: false, intakeClientReminder: action.payload };
    case INTAKE_REMINDER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_INTAKE_REMINDER:
      return {};
    default:
      return state;
  }
};

// GET QUESTIONS BY CASE TYPE ANSWERS
export const getQuestionsbyCaseTypeReducer = (state = {}, action) => {
  switch (action.type) {
    case GET_QUESTIONS_BY_CTYPE_REQUEST:
      return { loading: true };
    case GET_QUESTIONS_BY_CTYPE_SUCCESS:
      return { loading: false, allQuestions: action.payload };
    case GET_QUESTIONS_BY_CTYPE_FAIL:
      return { loading: false, error: action.payload };
    case RESET_GET_QUESTIONS_BY_CTYPE:
      return {};
    default:
      return state;
  }
};

// ADD ANSWERS TO INTAKE QUESTIONS REDUCERS
export const addIntakeAnswersReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_INTAKE_ANSWER_REQUEST:
      return { loading: true };
    case ADD_INTAKE_ANSWER_SUCCESS:
      return { loading: false, intakeAnswers: action.payload };
    case ADD_INTAKE_ANSWER_FAIL:
      return { loading: false, error: action.payload };
    case RESET_ADD_INTAKE_ANSWER:
      return {};
    default:
      return state;
  }
};
