import React from "react";

const ExportIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
    >
      <path
        d="M9.59011 5.19141C11.6901 5.37224 12.5476 6.45141 12.5476 8.81391V8.88974C12.5476 11.4972 11.5034 12.5414 8.89594 12.5414H5.09844C2.49094 12.5414 1.44678 11.4972 1.44678 8.88974V8.81391C1.44678 6.46891 2.29261 5.38974 4.35761 5.19724"
        stroke="#696969"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 8.74966V2.11133"
        stroke="#696969"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.95423 3.41217L7.00007 1.45801L5.0459 3.41217"
        stroke="#696969"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ExportIcon;
