import React, { useEffect, useMemo, useState, useRef } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  MenuItem,
  Stack,
  TextField,
  Tooltip,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import Button from "../../../common/Button";
import { DataGrid, gridClasses } from "@mui/x-data-grid";
import CardUI from "../../../common/Card";
import EmptyBox from "../../../assets/images/empty-box.png";
import AddIcon from "@mui/icons-material/Add";
import {
  caseStatusType,
  notificationStatusColor,
  shortDateFormat,
  timeDateFormat,
} from "../../../utils/helpers";
import { InfoCircle, SecurityIcon } from "../../../assets/constants";
import DialogOverlay from "../../../components/DialogOverlay";
import PushNotificationForm from "../../../components/PushNotificationForm";
import { useDispatch, useSelector } from "react-redux";
import { getNotificationsListFunc } from "../../../actions/Referrals";
import { getAgencyDetailFunc } from "../../../actions/Agency";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";
import AlertMessage from "../../../common/AlertMessage";
import CircularProgressLoading from "../../../common/CircularProgress";
import SkeletonGrid from "../../../components/SkeletonGrid";
import CaseTableSkeleton from "../../../components/CaseTableSkeleton";

const PushNotifications = () => {
  const [notificationsList, setNotificationsList] = useState([]);
  const [openDialog, setopenDialog] = useState(false);
  const [permissionMessage, setPermissionMessage] = useState(null);

  const dispatch = useDispatch();
  const getNotificationsList = useSelector(
    (state) => state.getNotificationsList
  );
  const { loading, error, notifications } = getNotificationsList;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "push_notifications",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  const agentUserRef = useRef();

  useEffect(() => {
    agentUserRef.current = agentUser;
  }, [agentUser]);

  const [openAlert, setOpenAlert] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenAlert(false);
    setPermissionMessage(null);
  };

  useEffect(() => {
    // setNotificationsList(notificationsData);
    dispatch(getNotificationsListFunc());
  }, []);

  useEffect(() => {
    if (notifications?.notifications) {
      setNotificationsList(notifications?.notifications);
    }
  }, [notifications]);

  // const delieveryRate = notification?.total_devices_delivered/notification?.total_devices_delivered

  // console.log("NOTIFICATION", notificationsList);

  const [query, setQuery] = useState("");
  const regex = new RegExp(query, "i");

  const rows = useMemo(() => {
    if (!notificationsList.length) return [];

    return notificationsList
      .filter(
        ({ title, body }) =>
          title.search(regex) !== -1 || body.search(regex) !== -1
      )
      .map((notification) => ({
        id: notification?.id,
        message: {
          messageTitle: notification?.title ? notification?.title : "",
          messageDescription: notification?.body ? notification?.body : "",
        },
        status: {
          type: notification?.status ? notification?.status : "",
          createdAt: notification?.notification_time
            ? shortDateFormat(notification?.notification_time)
            : "",
        },
        deliveryRate:
          notification?.total_devices_delivered ||
          notification?.total_devices_targeted
            ? notification?.total_devices_delivered >
              notification?.total_devices_targeted
              ? 100
              : Math.floor(
                  notification?.total_devices_delivered /
                    notification?.total_devices_targeted
                ) * 100
            : 0,
        openRate:
          notification?.total_devices_opened ||
          notification?.total_devices_targeted
            ? notification?.total_devices_opened >
              notification?.total_devices_targeted
              ? 100
              : Math.floor(
                  notification?.total_devices_opened /
                    notification?.total_devices_targeted
                ) * 100
            : 0,
      }));
  }, [notificationsList, query]); // clientsList, query

  const columns = useMemo(
    () => [
      {
        field: "message",
        headerName: "Message",
        width: 528,
        renderCell: ({ value }) => (
          <Stack>
            <Typography variant="body1">{value.messageTitle}</Typography>
            <Typography variant="body2" color="rgba(0, 0, 0, 0.38)">
              {value.messageDescription}
            </Typography>
          </Stack>
        ),
        sortable: false,
      },
      {
        field: "status",
        headerName: "Status",
        renderCell: ({ value }) => (
          <Chip
            size="small"
            label={`${value.type} ${value.createdAt}`}
            sx={{ textTransform: "capitalize" }}
            color={notificationStatusColor(value.type)}
            variant="outlined"
          />
        ),
        width: 210,
        sortable: false,
      },
      {
        field: "deliveryRate",
        renderHeader: () => (
          <Box display="flex" gap="4px" alignItems="flex-start">
            <Typography
              variant="h2"
              fontSize="14px"
              fontWeight="500"
              lineHeight="28px"
            >
              Delivery Rate
            </Typography>
            <Tooltip
              title="Percentage of the selected audience that successfully received the push notification"
              placement="top"
              arrow
              PopperProps={{
                sx: {
                  marginBottom: "-7px !important",
                },
              }}
            >
              <InfoCircle />
            </Tooltip>
          </Box>
        ),

        renderCell: ({ value }) => (
          <Box display="flex" gap="8px" alignItems="center">
            <CircularProgress
              sx={{ width: "16px !important", height: "16px !important" }}
              variant="determinate"
              value={Number(value)}
            />
            {value}%
          </Box>
        ),
        sortable: false,
        width: 93,
        flex: 1,
      },
      {
        field: "openRate",
        renderHeader: () => (
          <Box display="flex" gap="4px" alignItems="flex-start">
            <Typography
              variant="h2"
              fontSize="14px"
              fontWeight="500"
              lineHeight="28px"
            >
              Open Rate
            </Typography>
            <Tooltip
              title="Percentage of the selected audience that opened the push notification"
              placement="top"
              arrow
              PopperProps={{
                sx: {
                  marginBottom: "-7px !important",
                },
              }}
            >
              <InfoCircle />
            </Tooltip>
          </Box>
        ),
        renderCell: ({ value }) => (
          <Box display="flex" gap="8px" alignItems="center">
            <CircularProgress
              sx={{ width: "16px !important", height: "16px !important" }}
              variant="determinate"
              value={Number(value)}
            />
            {value}%
          </Box>
        ),
        sortable: false,
        width: 93,
        flex: 1,
      },
      // {
      //   field: "actions",
      //   type: "actions",
      //   flex: 1,
      //   width: 80,
      //   getActions: (params) => [
      //     <GridActionsCellItem label="Edit" showInMenu />,
      //     <GridActionsCellItem
      //       label="Delete"
      //       onClick={() => console.log(params.row)}
      //       showInMenu
      //       sx={{ color: "red" }}
      //     />,
      //   ],
      // },
    ],
    []
  );

  const openNotificationFormHandler = () => {
    const access =
      agentUserRef.current?.agent?.permissions?.access_push_notifications
        ?.create;
    if (!access) {
      setPermissionMessage("You do not have permission to send a new message!");
      setOpenAlert(true);
      return;
    } else {
      setopenDialog((prevState) => !prevState);

      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "new_pn_clicked",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  };

  return (
    <Stack height="742px">
      {openDialog && (
        <DialogOverlay open={openDialog} height="100%">
          <PushNotificationForm handleClose={openNotificationFormHandler} />
        </DialogOverlay>
      )}
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}
      {!loading && permissionMessage && (
        <AlertMessage
          message={permissionMessage}
          severity="error"
          open={openAlert}
          handleClose={handleClose}
        />
      )}

      <CardUI maxWidth="100%" dashboard="true" responsive="true" height="100%" padding="32px">
        {loading ? (
          <CaseTableSkeleton
            headers={["Message", "Status", "Delivery Rate", "Open Rate"]}
            numRows={4}
          />
        ) : notificationsList?.length > 0 ? (
          <Stack gap="24px" height="100%">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
            >
              <Box display="flex" gap="16px">
                <FormControl size="small" variant="outlined">
                  <InputLabel htmlFor="search-messages">Search</InputLabel>
                  <OutlinedInput
                    id="search-messages"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    endAdornment={
                      <InputAdornment position="end">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    }
                    label="Search"
                    size="small"
                    sx={{
                      backgroundColor: "#FFF",
                      minWidth: "414px",
                      width: "100%",
                    }}
                  />
                </FormControl>
              </Box>

              {notificationsList.length > 0 && (
                <Button
                  startIcon={<AddIcon />}
                  title="New Message"
                  color="primary"
                  fontSize="15px"
                  variant="contained"
                  onClick={openNotificationFormHandler}
                />
              )}
            </Box>

            <div
              style={{
                height: "613px",
                width: "100%",
                lineHeight: "normal !important",
                // maxWidth: "1200px",
                // overflowX: "scroll",
              }}
            >
              <DataGrid
                rows={rows}
                columns={columns}
                getRowHeight={() => "auto"}
                pageSize={10}
                rowsPerPageOptions={[10]}
                // initialState={{
                //   pagination: {
                //     paginationModel: { page: 0, pageSize: 5 },
                //   },
                // }}
                // rowsPerPageOptions={[5, 10]}
                sx={{
                  cursor: "pointer !important",
                  "&": { border: "none" },
                  [`& .${gridClasses.cell}`]: {
                    py: "16px",
                  },
                }}
                disableRowSelectionOnClick
              />
            </div>
          </Stack>
        ) : (
          <Stack
            height="100%"
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={2}
          >
            <img src={EmptyBox} alt="add notifications" />
            <Typography
              variant="body2"
              textAlign="center"
              color="rgba(0, 0, 0, 0.38)"
            >
              Start Sending push notifications to your clients.
            </Typography>
            <Button
              startIcon={<AddIcon />}
              title="New Message"
              color="primary"
              fontSize="15px"
              variant="contained"
              onClick={openNotificationFormHandler}
            />
          </Stack>
        )}
      </CardUI>
    </Stack>
  );
};

export default PushNotifications;
