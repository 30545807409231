import React from "react";
import { Box, Skeleton, Stack, Typography } from "@mui/material";
import IncreaseImg from "../../assets/images/increase-img.png";

const DashboardCard = ({
  headerText,
  number,
  imgName,
  alt,
  increaseNum,
  borderRadius,
  loading,
}) => {
  return (
    <Box
      boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
      padding="32px"
      height="175px"
      borderRadius="8px"
      position="relative"
      overflow="hidden"
      width="100%"
      bgcolor="#fff"
    >
      <Stack alignItems="flex-start" justifyContent="space-between" gap={2}>
        <Typography variant="body1" fontSize="14px" fontWeight="500">
          {headerText}
        </Typography>
        {loading ? (
          <Skeleton variant="rounded" width={87} height={36} />
        ) : (
          <Typography variant="h3" fontSize="50px" fontWeight="700">
            {number}
          </Typography>
        )}
        {/* <Box display="flex" gap="4px" alignItems="center">
          <img src={IncreaseImg} alt="increase bar" />
          <Typography variant="body1" fontSize="14px" color="secondary">
            <Box component="span" fontWeight="500" color="rgba(0, 0, 0, 0.87)">
              +{increaseNum}%{" "}
            </Box>{" "}
            than last month
          </Typography>
        </Box> */}
      </Stack>
      <Box
        position="absolute"
        bottom="-11px"
        right="-9px"
        sx={
          borderRadius && {
            width: "138px",
            height: "138px",
            borderRadius: "90px",
            background: "rgba(86, 104, 117, 0.06)",
            padding: "25px",
            bottom: "-27px",
            right: "-18px",
          }
        }
      >
        <img src={imgName} alt={alt} width="100%" height="100%" />
      </Box>
    </Box>
  );
};

export default DashboardCard;
