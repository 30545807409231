import axios from "axios";
import {
  Avatar,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  Tab,
  Tabs,
  TextField,
  Typography,
  Tooltip,
  Menu,
  Chip,
  Snackbar,
  Alert,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { ArrowLeftIcon } from "../../../assets/constants";
import ChatIcon from "../../../icons/ChatIcon";
import FolderGreyIcon from "../../../icons/FolderGreyIcon";
import MoreInfoIcon from "../../../icons/MoreInfoIcon";
import { PrinterIcon } from "../../../assets/constants";
import {
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import CardUI from "../../../common/Card";
import HeaderText from "../../../common/HeaderText";
import companyLogo from "../../../assets/images/company-logo.jpg";
import ProfilePicture from "../../../common/ProfilePicture";
import { EditIcon } from "../../../assets/constants";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import LinearProgressbar from "../../../common/LinearProgressbar";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { makeStyles } from "@mui/styles";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import OverviewTab from "../OverviewTab";
import MoreInfoTab from "../MoreInfoTab";
import ChatTab from "../ChatTab";
import DocumentTab from "../DocumentTab";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCaseFunc,
  getCaseClientDocsFunc,
  getCaseDetailByCaseId,
  getClientPainlogsFunc,
  getPieChartStatsFunc,
  resetCaseClientDocs,
  resetCaseDetailByCaseId,
  resetClientPainlogs,
  resetPieChartStats,
  resetUpdateCase,
  updateCaseFunc,
} from "../../../actions/Cases";
import AlertMessage from "../../../common/AlertMessage";
import EditCaseDrawer from "../EditCaseDrawer";
import AlertDialog from "../../../components/AlertDialog";
import { getAgencyDetailFunc } from "../../../actions/Agency";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";
import {
  caseStatusBgColor,
  caseStatusColor,
  caseValueBgColor,
  caseValueColor,
  formatCaseTypeText,
} from "../../../utils/helpers";
import SkeletonGrid from "../../../components/SkeletonGrid";
import CaseHeaderSkeleton from "../../../components/CaseHeaderSkeleton";
import RectangleSkeletons from "../../../components/RectangleSkeletons";
import CaseSummaryBoxContent from "../../../components/CaseSummaryBoxContent";
import {
  intakeQuestionsAnswersFunc,
  resetIntakeQuestionsAnswers,
} from "../../../actions/ClientIntakeActions";
import {
  getAllBodyPartsFunc,
  resetAllBodyParts,
} from "../../../actions/AppConfig";

import Cookies from "js-cookie";
import RequestDocIcon from "../../../icons/RequestDocIcon";
import dayjs from "dayjs";
import {
  getBillingSubscriptionFunc,
  removeCaseFromBillingSubscriptionFunc,
  resetRemoveCaseToSubscription,
} from "../../../actions/BillingSubscription";

const useStyles = makeStyles((theme) => {
  return {
    sticky_div: {
      position: "sticky",
      top: "82px",
      zIndex: "99",
    },
  };
});

const CaseOverview = () => {
  const classes = useStyles();

  const token = Cookies.get("token");

  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarData, setSnackbarData] = useState({});

  const [isSticky, setIsSticky] = useState(false);

  const navigate = useNavigate();
  const { id } = useParams();

  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("tab") || "overview";

  // const [questionsAnswers, setQuestionsAnswers] = useState([]);
  const [tabValue, setTabValue] = useState("overview");
  const [caseValue, setCaseValue] = useState("high");
  const [caseStatus, setCaseStatus] = useState("open");
  const [changedCaseStatus, setChangedCaseStatus] = useState(false);
  const [changedCaseValue, setChangedCaseValue] = useState(false);
  const [fromCaseOverview, setFromCaseOverview] = useState(false);
  const [modalContentData, setModalContentData] = useState({});
  const [openDeleteCaseModal, setOpenDeleteCaseModal] = useState(false);
  const [openCaseSummaryDialogBox, setOpenCaseSummaryDialogBox] =
    useState(false);
  const [documentValue, setDocumentValue] = useState([]);
  const [bodyPartsValue, setBodyPartsValue] = useState([]);
  const [fromPdfDate, setFromPdfDate] = useState(null);
  const [toPdfDate, setToPdfDate] = useState(dayjs());
  const [dateFieldIsTouched, setDateFieldIsTouched] = useState(false);
  const [fromPdfButton, setFromPdfButton] = useState(false);
  const [allBodyPartsData, setAllBodyPartsData] = useState([]);
  const [checkedPainLogs, setCheckedPainLogs] = useState(true);
  const [checkedEventLogs, setCheckedEventLogs] = useState(true);
  const [pieChartStatsState, setPieChartStatsState] = useState({});
  const [documentsResponse, setDocumentsResponse] = useState(null);
  const [loadingDocumentURLs, setLoadingDocumentURLs] = useState(false);
  const [billingActive, setBillingActive] = useState(false);
  const [caseAddedInPlan, setCaseAddedInPlan] = useState(false);
  const [showBillingPlan, setShowBillingPlan] = useState(false);
  const [openCancelBillingBox, setOpenCancelBillingBox] = useState(false);

  // const queryParams = new URLSearchParams(location.search);
  // const selectedTab = queryParams.get("tab") || "overview";

  // const selectedRowId =
  //   location &&
  //   location.state &&
  //   location.state.rowData &&
  //   location.state.rowData.id;

  const tabsData = [
    {
      label: "Overview",
      value: "overview",
      icon: <VisibilityOutlinedIcon />,
    },
    {
      label: "Chat",
      value: "chat",
      icon: <ChatIcon />,
    },
    {
      label: "Documents",
      value: "documents",
      icon: <FolderGreyIcon />,
    },
    {
      label: "More Info",
      value: "more_info",
      icon: <MoreInfoIcon />,
    },
  ];

  const pieRef = useRef();

  const [anchorMenuEl, setAnchorMenuEl] = useState(null);

  const openCaseOverviewMenu = Boolean(anchorMenuEl);
  const [loadingPdfDoc, setLoadingPdfDoc] = useState(false);

  const dispatch = useDispatch();
  const caseDetailByCaseId = useSelector((state) => state.caseDetailByCaseId);
  const { loading, error, caseDetail } = caseDetailByCaseId;

  const getCaseSummaryLink = useSelector((state) => state.getCaseSummaryLink);
  const {
    loading: loadingCaseSummaryLink,
    error: errorCaseSummaryLink,
    caseSummaryPdfLink,
  } = getCaseSummaryLink;

  const getPieChartStats = useSelector((state) => state.getPieChartStats);
  const {
    loading: loadingPieChartStats,
    error: errorPieChartStats,
    pieChartStats,
  } = getPieChartStats;

  const getCaseClientDocs = useSelector((state) => state.getCaseClientDocs);
  const {
    loading: loadingCaseClientDocs,
    error: errorCaseClientDocs,
    caseClientDocs,
  } = getCaseClientDocs;

  const getClientPainlogs = useSelector((state) => state.getClientPainlogs);
  const {
    loading: loadingPain,
    error: errorPain,
    painlogs,
  } = getClientPainlogs;

  const intakeQuestionsAnswers = useSelector(
    (state) => state.intakeQuestionsAnswers
  );
  const {
    loading: loadingIntakeQuestionsAnswers,
    error: errorIntakeQuestionsAnswers,
    allQuestionsAnswers,
  } = intakeQuestionsAnswers;

  const allFolderAndFiles = caseClientDocs?.contents?.filter(
    (item) =>
      item?.type === "file" ||
      (item?.type === "folder" && item?.number_of_children > 0)
  );

  const updateCase = useSelector((state) => state.updateCase);
  const {
    loading: loadingUpdateCase,
    error: errorUpdateCase,
    updatedCase,
  } = updateCase;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAllBodyParts = useSelector((state) => state.getAllBodyParts);
  const {
    loading: loadingBodyParts,
    error: errorBodyParts,
    allBodyParts,
  } = getAllBodyParts;

  const removeCaseFromSubscription = useSelector(
    (state) => state.removeCaseFromSubscription
  );
  const {
    loading: loadingCaseFromSubsc,
    error: errorCaseFromSubsc,
    caseRemovedFromSubscription,
  } = removeCaseFromSubscription;

  const getBillingSubscription = useSelector(
    (state) => state.getBillingSubscription
  );
  const {
    loading: loadingBilling,
    error: errorBilling,
    billingSubscription,
  } = getBillingSubscription;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "case_overview",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  }, [agentUser, agencyDetail]);

  useEffect(() => {
    dispatch(getCaseDetailByCaseId(id));
    dispatch(getBillingSubscriptionFunc());
  }, [id]);

  useEffect(() => {
    if (id && caseDetail?.case?.client?.id) {
      setCaseAddedInPlan(caseDetail?.case?.added_to_subscription);
      dispatch(getCaseClientDocsFunc(id, caseDetail?.case?.client?.id));
      dispatch(intakeQuestionsAnswersFunc(caseDetail?.case?.client?.id, id));
      dispatch(getAllBodyPartsFunc(caseDetail?.case?.client?.id, id));
      dispatch(getAgencyDetailFunc());
      caseDetail?.case?.incident_date
        ? setFromPdfDate(dayjs(caseDetail?.case?.incident_date))
        : setFromPdfDate(dayjs(caseDetail?.case?.created_at));
      setBillingActive(caseDetail?.case?.added_to_subscription || false);
    }
  }, [id, caseDetail?.case?.client?.id]);

  useEffect(() => {
    if (caseDetail?.case?.title)
      document.title = `MCC | ${caseDetail?.case?.title}`;
  }, [caseDetail]);

  const [open, setOpen] = useState(true);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const handleChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    navigate({ search: `?${searchParams.toString()}` });
    setTabValue(newValue);
  };

  useEffect(() => {
    if (selectedTab === "overview") {
      setTabValue("overview");
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "overview_tab",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } else if (selectedTab === "chat") {
      setTabValue("chat");
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "chat_tab",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } else if (selectedTab === "documents") {
      setTabValue("documents");
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "documents_tab",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    } else {
      setTabValue("more_info");
      if (agentUser?.agent && agencyDetail?.agency) {
        segmentTrack({
          event: "info_tab",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
        });
      }
    }
  }, [selectedTab]);

  const caseValueChangeHandler = (e) => {
    setCaseValue(e.target.value);
    setChangedCaseValue(true);
    setChangedCaseStatus(false);
    setOpen(true);

    const caseValue = {
      case_value: e.target.value,
    };

    const config = {
      case_id: id,
      case_obj: caseValue,
    };

    dispatch(updateCaseFunc(config));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "case_value_updated",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const caseStatusChangeHandler = (e) => {
    setCaseStatus(e.target.value);
    setChangedCaseStatus(true);
    setChangedCaseValue(false);

    const caseData = {
      status: e.target.value,
    };

    const config = {
      case_id: id,
      case_obj: caseData,
    };

    dispatch(updateCaseFunc(config));

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "case_status_updated",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  useEffect(() => {
    if (updatedCase?.ok && changedCaseStatus) {
      setOpen(true);
      dispatch(getCaseDetailByCaseId(id));
    }
  }, [updatedCase]);

  useEffect(() => {
    if (caseDetail?.case?.status) {
      setCaseStatus(caseDetail?.case?.status?.toLowerCase());
    }
  }, [caseDetail?.case?.status]);

  useEffect(() => {
    if (caseDetail?.case?.case_value) {
      setCaseValue(caseDetail?.case?.case_value);
    }
  }, [caseDetail?.case?.case_value]);

  const openEditDrawerHandler = () => {
    setFromCaseOverview(true);
    setOpenEditDrawer(true);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "edit_case_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const navigateToClientHandler = () => {
    if (caseDetail?.case?.client?.is_deleted) {
      return;
    } else {
      navigate(`/client/${caseDetail?.case?.client?.id}`);
    }
  };

  // ==============================================================================
  // OPEN CONFIRM BILLING CANCEL MODAL
  const modalCloseHandler = () => {
    setOpenCancelBillingBox(false);
    setBillingActive(true);
  };

  const cancelBillingSubscriptionHandler = () => {
    const caseObjId = {
      case_ID: caseDetail?.case?.id,
    };
    dispatch(removeCaseFromBillingSubscriptionFunc(caseObjId));
  };

  useEffect(() => {
    if (caseRemovedFromSubscription?.ok) {
      setCaseAddedInPlan(false);
      setOpenCancelBillingBox(false);
      dispatch(resetRemoveCaseToSubscription());
    }
  }, [caseRemovedFromSubscription]);

  const modalopenBillingCancelHandler = () => {
    setOpenCancelBillingBox(true);
  };

  const openBillingCancelModal = () => {
    setModalContentData({
      title: "Are you sure?",
      subtitle:
        "If you remove this case from your plan, access will be restricted. Are you sure you want to proceed?",
    });
    modalopenBillingCancelHandler();
  };

  // =====================================================================================

  const openMenuHandler = (event) => {
    setAnchorMenuEl(event.currentTarget);
  };

  const closeMenuHandler = () => {
    setAnchorMenuEl(null);
  };

  // OPEN DELETE CASE PROFILE MODAL
  const modalDeleteCaseOpenHandler = () => {
    setOpenDeleteCaseModal(true);
  };

  const modalDeleteCaseCloseHandler = () => {
    setOpenDeleteCaseModal(false);
    setOpenCaseSummaryDialogBox(false);
    // setFromPdfDate(null);
    // setToPdfDate(null);
    // setDocumentValue([]);
    setBodyPartsValue(allBodyPartsData);
    setCheckedEventLogs(true);
    setCheckedPainLogs(true);
    setDateFieldIsTouched(false);
  };

  // OPEN DELETE CASE CONFIRM MODAL
  const openDeleteCaseModalHandler = () => {
    setModalContentData({
      title: "Are you sure?",
      subtitle:
        "This will permanently delete the case and all the data associated with it.",
    });
    modalDeleteCaseOpenHandler();
    setAnchorMenuEl(null);
  };

  // DELETING CASE HANDLER
  const deleteCaseHandler = () => {
    // const clientDeleteData = { client_id: clientData?.id };
    dispatch(deleteCaseFunc(id));
    // dispatch(deleteClientFunc(clientDeleteData));
    navigate("/cases");
    modalDeleteCaseCloseHandler();
  };

  const openCaseSummaryDialogHandler = () => {
    setOpenCaseSummaryDialogBox(true);
    setModalContentData({
      title: "Client Summary Report",
      lightText:
        "Configure the report and export it according to your requirements",
    });
  };

  useEffect(() => {
    if (allBodyParts?.ok) {
      var pain_entries = allBodyParts?.pain_entries;
      var body_parts = [];

      for (const pain_entry of pain_entries) {
        if (pain_entry.body_parts_data) {
          var body_parts_data = JSON.parse(pain_entry?.body_parts_data);
          if (body_parts_data && body_parts_data?.length) {
            body_parts.push(
              ...body_parts_data.map(
                (body_part) => `${body_part.side} ${body_part.name}`
              )
            );
          }
        }
      }

      body_parts = [...new Set(body_parts)].map(
        (bp) => bp.charAt(0).toUpperCase() + bp.slice(1)
      );
      setAllBodyPartsData(body_parts);
      setBodyPartsValue(body_parts);
    }
  }, [allBodyParts]);

  const generatePdf = async () => {
    setDateFieldIsTouched(true);
    setFromPdfButton(true);

    if (!fromPdfDate || !toPdfDate || !bodyPartsValue?.length) {
      return;
    }

    const startDate = fromPdfDate?.format("YYYY-MM-DD");
    const endDate = toPdfDate?.format("YYYY-MM-DD");
    const extractedDocumentValues = documentValue?.map((doc) => ({
      document_type: doc.type,
      id: doc.id,
    }));

    dispatch(
      getClientPainlogsFunc(
        id,
        caseDetail?.case?.client?.id,
        startDate,
        endDate,
        "all"
      )
    );

    dispatch(
      getPieChartStatsFunc(caseDetail?.case?.client?.id, id, startDate, endDate)
    );

    const config = {
      headers: {
        "Content-type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    };

    setLoadingDocumentURLs(true);

    const documents_response = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/storage/get-all-document-urls`,
      {
        client_id: caseDetail?.case?.client?.id,
        documents: extractedDocumentValues,
      },
      config
    );

    setDocumentsResponse(documents_response?.data);
    setLoadingDocumentURLs(false);

    setDateFieldIsTouched(false);
  };

  useEffect(() => {
    if (
      fromPdfButton &&
      painlogs?.ok &&
      pieChartStats?.ok &&
      documentsResponse?.ok
    ) {
      const formState = {
        startDate: fromPdfDate?.format("YYYY-MM-DD"),
        endDate: toPdfDate?.format("YYYY-MM-DD"),
        bodyParts: bodyPartsValue,
        showPainLogsTable: checkedPainLogs,
      };

      const localStorageState = {
        caseDetail: caseDetail,
        formState: formState,
        intakeQuestionsAnswers: allQuestionsAnswers?.intake_answers,
        painLogs: painlogs?.pain_entries,
        pieChartStatsState: pieChartStats?.report_stats,
        document_urls: documentsResponse?.document_urls,
      };
      localStorage.setItem(
        "newTabPageState",
        JSON.stringify(localStorageState)
      );

      const url = `/client-summary-report/${caseDetail?.case?.id}`;
      const newTab = window.open(url, "_blank");

      newTab.onload = () => {
        newTab.localStorage.removeItem("newTabPageState");
      };

      // Reset states
      setFromPdfButton(false);
      setOpenCaseSummaryDialogBox(false);
      setFromPdfDate(null);
      setToPdfDate(null);
      setDocumentValue([]);
      setBodyPartsValue(allBodyPartsData);
      setCheckedEventLogs(true);
      setCheckedPainLogs(true);
      setDateFieldIsTouched(false);
    }
  }, [painlogs, pieChartStats, documentsResponse]);

  const billingActiveHandler = (e) => {
    setBillingActive(e.target.checked);
    if (e.target.checked) {
      setShowBillingPlan(true);
    } else {
      openBillingCancelModal();
      setBillingActive(false);
    }
  };

  return (
    <>
      {!loading && error && (
        <AlertMessage
          message={error}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )}

      {/* CASE SUMMARY DIALOG BOX */}
      {openCaseSummaryDialogBox && (
        <AlertDialog
          data={modalContentData}
          openModal={openCaseSummaryDialogBox}
          closeModal={modalDeleteCaseCloseHandler}
          moveFileDialog={true}
          titlePaddingTop="32px"
          contentPaddingTop="0px !important"
          formSubmitFunction={generatePdf}
          submitText="Generate"
          maxWidth="md"
          padding="32px"
          caseSummary={true}
          isLoading={
            loadingCaseSummaryLink ||
            loadingPieChartStats ||
            loadingDocumentURLs
          }
        >
          <CaseSummaryBoxContent
            allFolderAndFiles={allFolderAndFiles}
            documentValue={documentValue}
            setDocumentValue={setDocumentValue}
            bodyPartsValue={bodyPartsValue}
            setBodyPartsValue={setBodyPartsValue}
            checkedPainLogs={checkedPainLogs}
            setCheckedPainLogs={setCheckedPainLogs}
            checkedEventLogs={checkedEventLogs}
            setCheckedEventLogs={setCheckedEventLogs}
            fromPdfDate={fromPdfDate}
            setFromPdfDate={setFromPdfDate}
            toPdfDate={toPdfDate}
            setToPdfDate={setToPdfDate}
            dateFieldIsTouched={dateFieldIsTouched}
            allBodyPartsData={allBodyPartsData}
            loadingCaseClientDocs={loadingCaseClientDocs}
            loadingBodyParts={loadingBodyParts}
            caseDetail={caseDetail}
          />
        </AlertDialog>
      )}
      {/* OPEN SNACKBAR DATA  */}
      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={closeSnackbarHandler}
          anchorOrigin={{
            vertical: snackbarData.vertical,
            horizontal: snackbarData.horizontal,
          }}
        >
          <Alert
            onClose={closeSnackbarHandler}
            severity={snackbarData.severity}
            color={snackbarData.color}
            sx={{ width: "100%" }}
          >
            {snackbarData.textContent}
          </Alert>
        </Snackbar>
      )}

      {/* {!loading && errorUpdate && (
        <AlertMessage
          message={errorUpdate}
          severity="error"
          open={open}
          handleClose={handleClose}
        />
      )} */}

      {!loading && changedCaseStatus && (
        <AlertMessage
          message="Case status updated"
          severity="success"
          open={open}
          handleClose={handleClose}
        />
      )}

      {!loading && changedCaseValue && (
        <AlertMessage
          message="Case value updated"
          severity="success"
          open={open}
          handleClose={handleClose}
        />
      )}

      {/* ALERT DELETE CASE MODAL */}
      {openDeleteCaseModal && (
        <AlertDialog
          submitID="confirm-delete-case"
          openModal={openDeleteCaseModal}
          closeModal={modalDeleteCaseCloseHandler}
          data={modalContentData}
          formSubmitFunction={deleteCaseHandler}
          color="error"
        />
      )}

      {/* EDIT CASE DRAWER */}
      {openEditDrawer && (
        <EditCaseDrawer
          anchor={"right"}
          openEditCase={openEditDrawer}
          setOpenEditCase={setOpenEditDrawer}
          selectedRowCaseId={id}
          fromCaseOverview={fromCaseOverview}
          // open={open}
          // setOpen={setOpen}
          onClose={() => setOpenEditDrawer(false)}
        />
      )}
      {/* {Object.keys(pieChartStatsState).length !== 0 && (
        <Stack gap="18px" pb="40px">
          <div style={{ width: "250px" }} ref={pieRef}>
            <CustomPieChart pieChartStatsState={pieChartStatsState} />
          </div>
          <Stack>
            <Box p="6px 16px" display="flex" alignItems="center">
              <Box width="12px" height="12px" bgcolor="#F45454"></Box>
              <Typography
                variant="body1"
                mx="8px"
                fontWeight="600"
                width="176px"
              >
                Days with Worst Pain
              </Typography>
              <Typography variant="body1">
                {pieChartStatsState?.worst_pain_entries || 0} days (
                {worstPainPercentage.toFixed(2)}% of the recorded period){" "}
              </Typography>
            </Box>
            <Box p="6px 16px" display="flex" alignItems="center">
              <Box width="12px" height="12px" bgcolor="#AAE77A"></Box>
              <Typography
                variant="body1"
                mx="8px"
                fontWeight="600"
                width="176px"
              >
                Days with Mild Pain{" "}
              </Typography>
              <Typography variant="body1">
                {pieChartStatsState?.mild_pain_entries || 0} days (
                {mildPainPercentage.toFixed(2)}% of the recorded period){" "}
              </Typography>
            </Box>
            <Box p="6px 16px" display="flex" alignItems="center">
              <Box width="12px" height="12px" bgcolor="#FFDD66"></Box>
              <Typography
                variant="body1"
                mx="8px"
                fontWeight="600"
                width="176px"
              >
                Days with Severe{" "}
              </Typography>
              <Typography variant="body1">
                {pieChartStatsState?.severe_pain_entries || 0} days (
                {severePainPercentage.toFixed(2)}% of the recorded period){" "}
              </Typography>
            </Box>
            <Box p="6px 16px" display="flex" alignItems="center">
              <Box width="12px" height="12px" bgcolor="#0C80F3 "></Box>
              <Typography
                variant="body1"
                mx="8px"
                fontWeight="600"
                width="176px"
              >
                Days with No Pain{" "}
              </Typography>
              <Typography variant="body1">
                {pieChartStatsState?.no_pain_entries || 0} days (
                {noPainPercentage.toFixed(2)}% of the recorded period){" "}
              </Typography>
            </Box>
            <Box p="6px 16px" display="flex" alignItems="center">
              <Typography
                variant="body1"
                mx="8px"
                fontWeight="600"
                width="176px"
              >
                Average Pain Intensity
              </Typography>
              <Typography variant="body1">3.7 out of 5</Typography>
            </Box>
          </Stack>
        </Stack>
      )} */}

      <Stack alignItems="flex-start" gap="18px">
        <Button
          variant="text"
          disableRipple
          disableFocusRipple
          startIcon={
            <Paper
              sx={{
                background: "#fff",
                boxShadow: "0px 2px 16px 0px rgba(0, 0, 0, 0.08)",
                borderRadius: "50%",
                width: "16px",
                height: "16px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <ArrowLeftIcon width="100%" height="100%" />
            </Paper>
          }
          color="secondary"
          sx={{
            textTransform: "capitalize",
            "&.MuiButtonBase-root:hover": {
              bgcolor: "transparent",
            },
          }}
          onClick={() => {
            dispatch(resetCaseClientDocs());
            dispatch(resetAllBodyParts());
            navigate("/cases");
          }}
        >
          Go back
        </Button>

        {/* CASE OVERVIEW HEADER */}
        <Box
          boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
          bgcolor="#fff"
          borderRadius="8px"
          overflow="hidden"
          gap="24px"
          width="100%"
          position="sticky"
          top="82px"
          zIndex="99"
          // className={`${
          //   isSticky ? { position: "sticky", top: "82px", zIndex: "1" } : ""
          // }`}
        >
          {/* SUB HEADER SECTION */}
          <Stack gap="12px" paddingBottom="12px">
            <Stack
              sx={{
                padding: "24px 32px 12px",
                background: loading
                  ? "#fff"
                  : "radial-gradient(85.47% 105.71% at 15.61% 87.28%, rgba(128, 12, 243, 0.09) 0%, rgba(12, 128, 243, 0.09) 100%)",
              }}
            >
              {loading ? (
                <CaseHeaderSkeleton />
              ) : (
                <Box
                  display="flex"
                  alignItems="flex-end"
                  justifyContent="space-between"
                >
                  <Stack alignItems="flex-start" gap="8px">
                    <Box display="flex" alignItems="center" gap="1rem">
                      <HeaderText
                        textContent={caseDetail?.case?.title}
                        variant="h1"
                        fontSize="28px"
                      />
                      {caseDetail?.case?.case_origin === "clio" && ( // Assuming you have a similar property to check
                        <Chip
                          label="Synced with Clio"
                          size="small"
                          sx={{
                            fontSize: "0.75rem",
                            height: "auto",
                            padding: "2px 4px",
                            backgroundColor: "#e0e0e0",
                            color: "#000000",
                            borderRadius: "16px",
                          }}
                        />
                      )}
                    </Box>
                    <Box display="flex" alignItems="center" gap="8px">
                      {caseDetail?.case?.case_type && (
                        <Typography variant="body1">
                          {formatCaseTypeText(caseDetail?.case?.case_type)}
                        </Typography>
                      )}

                      {caseDetail?.case?.case_number && (
                        <Typography
                          variant="body1"
                          color="secondary"
                        >{`# ${caseDetail?.case?.case_number}`}</Typography>
                      )}

                      <Box
                        bgcolor="rgba(0, 0, 0, 0.08)"
                        borderRadius="100px"
                        padding="0 8px"
                      >
                        <FormControlLabel
                          sx={{ marginLeft: "0", marginRight: "0" }}
                          value="start"
                          control={
                            <Switch
                              size="small"
                              color="primary"
                              checked={billingActive}
                              onChange={billingActiveHandler}
                            />
                          }
                          label={
                            <span
                              style={{
                                fontSize: "13px",
                                color: "#0C80F3",
                                marginRight: "4px",
                                fontWeight: "500",
                              }}
                            >
                              {billingActive
                                ? "Billing Active"
                                : "Billing Inactive"}
                            </span>
                          }
                          labelPlacement="start"
                        />
                      </Box>
                    </Box>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap="8px">
                    <ProfilePicture
                      src={caseDetail?.case?.client?.profile_picture_url}
                      width="24px"
                      height="24px"
                      alt="client name"
                    />
                    {caseDetail?.case?.client?.name && (
                      <Typography
                        color="primary"
                        sx={{
                          textDecoration: caseDetail?.case?.client?.is_deleted
                            ? "none"
                            : "underline",
                          cursor: caseDetail?.case?.client?.is_deleted
                            ? "auto"
                            : "pointer",
                        }}
                        onClick={navigateToClientHandler}
                      >
                        {caseDetail?.case?.client?.name}
                      </Typography>
                    )}
                    {caseDetail?.case?.opposing_party && (
                      <>
                        <Typography color="secondary">vs</Typography>
                        <Typography color="#000">
                          {caseDetail?.case?.opposing_party}
                        </Typography>
                      </>
                    )}
                    <Divider
                      orientation="vertical"
                      variant="middle"
                      flexItem
                      sx={{
                        paddingLeft: "8px",
                        paddingRight: "8px",
                        height: "17px",
                      }}
                    />
                    <Tooltip title="Generate Report">
                      <IconButton
                        // sx={{ padding: "0", paddingLeft: "8px" }}
                        onClick={openCaseSummaryDialogHandler}
                      >
                        <RequestDocIcon />
                      </IconButton>
                    </Tooltip>
                    <IconButton
                      // sx={{ padding: "0", paddingLeft: "8px" }}
                      onClick={openEditDrawerHandler}
                      id="edit-case"
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton
                      sx={{ padding: "0" }}
                      onClick={openMenuHandler}
                      id="case-overview-menu"
                      aria-controls={
                        openCaseOverviewMenu ? "case-overview-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openCaseOverviewMenu ? "true" : undefined}
                    >
                      <MoreVertIcon />
                    </IconButton>
                    <Menu
                      id="case-overview-menu"
                      anchorEl={anchorMenuEl}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      open={openCaseOverviewMenu}
                      onClose={closeMenuHandler}
                      MenuListProps={{
                        "aria-labelledby": "case-overview-button",
                      }}
                    >
                      <MenuItem
                        id="delete-case"
                        onClick={openDeleteCaseModalHandler}
                        sx={{ color: "#FF2D2D" }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Stack>
                </Box>
              )}
            </Stack>

            <Box
              display="flex"
              width="100%"
              alignItems="center"
              justifyContent="space-between"
              gap="24px"
              padding="0 32px"
              alignSelf="flex-end"
            >
              {/* CASE HEALTH */}
              {/* <Box display="flex" alignItems="center" gap="8px">
                  <HeaderText
                    fontSize="16px"
                    textContent="Case Health :"
                    variant="h6"
                    fontWeight="400"
                  />
                  <Box
                    height="20px"
                    width="350px"
                    borderRadius="24px"
                    boxShadow="0px 0px 4px 0px rgba(0, 0, 0, 0.25) inset"
                    padding="4px"
                    position="relative"
                  >
                    <LinearProgressbar />
                    <Typography
                      position="absolute"
                      right="7px"
                      top="2px"
                      fontSize="12px"
                      color="#fff"
                    >
                      100%
                    </Typography>
                  </Box>
                </Box> */}

              {loading ? (
                <RectangleSkeletons totalRows={1} height={27} />
              ) : (
                <Box display="flex" justifyContent="space-between" gap="24px">
                  <Box display="flex" alignItems="center" gap="11px">
                    <Typography fontSize="16px" variant="h6" fontWeight="400">
                      Priority :
                    </Typography>

                    <FormControl>
                      {/* <InputLabel id="demo-simple-select-label">High</InputLabel> */}
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={caseValue}
                        IconComponent={ExpandCircleDownIcon}
                        size="small"
                        // MenuProps={{
                        //   disableScrollLock: true, // Disable scroll lock to prevent text truncation
                        //   variant: "menu", // Set variant to menu
                        // }}
                        sx={{
                          fontSize: "13px",
                          fontWeight: 500,
                          padding: "0 !Important",
                          color: `${caseValueColor(caseValue)} !important`,
                          borderRadius: "100px !important",
                          background: `${caseValueBgColor(
                            caseValue
                          )} !important`,
                          "&:before": {
                            borderColor: "white !important",
                          },
                          "&:after": {
                            borderColor: "white !important",
                          },
                          boxShadow: "none !important",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "0  !important",
                          },
                          ".MuiSelect-outlined": {
                            padding: "4px 0",
                            paddingRight: "0 !important",
                            paddingLeft: "10px",
                            width: "91px",
                          },

                          ".MuiSvgIcon-root ": {
                            width: "20px",
                            fill: `${caseValueColor(caseValue)} !important`,
                          },
                        }}
                        onChange={caseValueChangeHandler}
                      >
                        <MenuItem value="high">High</MenuItem>
                        <MenuItem value="moderate">Moderate</MenuItem>
                        <MenuItem value="low">Low</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>

                  <Box display="flex" alignItems="center" gap="11px">
                    <Typography fontSize="16px" variant="h6" fontWeight="400">
                      Case Status :
                    </Typography>
                    <FormControl>
                      {/* <InputLabel id="demo-simple-select-label">High</InputLabel> */}
                      <Select
                        labelId="case-status"
                        id="case-status"
                        value={caseStatus}
                        IconComponent={ExpandCircleDownIcon}
                        size="small"
                        sx={{
                          fontSize: "13px",
                          fontWeight: 600,
                          marginRight: "10px !important",
                          color: `${caseStatusColor(caseStatus)} !important`,
                          borderRadius: "100px !important",
                          background: `${caseStatusBgColor(
                            caseStatus
                          )} !important`,
                          "&:before": {
                            borderColor: "white !important",
                          },
                          "&:after": {
                            borderColor: "white !important",
                          },
                          boxShadow: "none !important",
                          ".MuiOutlinedInput-notchedOutline": {
                            border: "0  !important",
                          },
                          ".MuiSelect-outlined": {
                            padding: "4px 0",
                            paddingRight: "0 !important",
                            paddingLeft: "10px",
                            width: "91px",
                          },
                          ".MuiSvgIcon-root ": {
                            width: "20px",
                            fill: `${caseStatusColor(caseStatus)} !important`,
                          },
                        }}
                        inputProps={
                          {
                            // shrink: true,
                            //   classes: {
                            //     icon: { fill: "red !important" },
                            //   },
                          }
                        }
                        onChange={caseStatusChangeHandler}
                      >
                        <MenuItem value="open">Open</MenuItem>
                        <MenuItem value="closed">Closed</MenuItem>
                        <MenuItem value="settled">Settled</MenuItem>
                        <MenuItem value="on hold">On Hold</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              )}
            </Box>
          </Stack>

          <Box padding="0 32px">
            <Tabs
              // sx={{
              //   color: "#fff",
              //   // "& button:active": { background: "orange" },
              // }}
              value={tabValue}
              onChange={handleChange}
              sx={{ minHeight: "0 !important" }}
              indicatorColor="primary"
              // textColor="secondary"
              // aria-label="secondary tabs example"
            >
              {tabsData.map((tabData, i) => (
                <Tab
                  icon={React.cloneElement(tabData.icon, {
                    color:
                      selectedTab === tabData.value ? "#0C80F3" : "#566875",
                  })}
                  iconPosition="start"
                  sx={{
                    minHeight: "0 !important",
                    "& svg": {
                      marginRight: "8px !important",
                    },
                  }}
                  disableRipple
                  key={i}
                  value={tabData.value}
                  label={tabData.label}
                  to={`/case/${id}?tab=${tabData.value}`}
                  // to={`/case/${id}?tab=${tabData.value}`}
                />
              ))}
            </Tabs>
          </Box>
        </Box>

        {openCancelBillingBox && (
          <AlertDialog
            openModal={openCancelBillingBox}
            closeModal={modalCloseHandler}
            isLoading={loadingCaseFromSubsc}
            data={modalContentData}
            formSubmitFunction={cancelBillingSubscriptionHandler}
            color="error"
          />
        )}

        {/* TABS CONTENT  */}
        {/* TAB 1 OVERVIEW SECTION  */}
        {selectedTab === "overview" && (
          <OverviewTab
            caseDetail={caseDetail}
            allBodyPartsData={allBodyPartsData}
            billingActive={billingActive}
            setBillingActive={setBillingActive}
            setShowBillingPlan={setShowBillingPlan}
            showBillingPlan={showBillingPlan}
            caseAddedInPlan={caseAddedInPlan}
            setCaseAddedInPlan={setCaseAddedInPlan}
            billingSubscription={billingSubscription}
            loadingBilling={loadingBilling}
            // datesInRange={datesInRange}
            // setDatesInRange={setDatesInRange}
          />
        )}

        {/* TAB 2 CHAT SECTION CONTENT */}
        {selectedTab === "chat" && (
          <ChatTab caseDetail={caseDetail} loadingCaseDetail={loading} />
        )}

        {/* TAB 3 DCOUMENTS SECTION  CONTENT */}
        {selectedTab === "documents" && (
          <DocumentTab
            caseDetail={caseDetail}
            // onCancelRequest={() => setIsCancelledRequest(true)}
          />
        )}

        {/* TAB 2 MORE INFO SECTION CONTENT */}
        {selectedTab === "more_info" && (
          <MoreInfoTab
            loading={loading}
            error={error}
            caseDetail={caseDetail}
          />
        )}
      </Stack>
    </>
  );
};

export default CaseOverview;
