import React from "react";
import {
  Box,
  Checkbox,
  Chip,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { checkFileExtension, isPDF } from "../../utils/helpers";
import { makeStyles } from "@mui/styles";
import DocumentFolderIcon from "../../icons/DocumentFolderIcon";
import DocImg from "../../assets/images/doc-img.png";
import pdfViewImg from "../../assets/images/pdfViewImg.png";
import { Document, Page } from "react-pdf";
import PdfUi from "../PdfUi";

const useStyles = makeStyles(() => {
  return {
    doc_container: {
      display: "flex",
      alignItems: "center",
      gap: "24px",
      flexWrap: "wrap",
    },

    doc_card: {
      //   width: "246.25px",
      position: "relative",
      borderRadius: "8px",
      overflow: "hidden",
      border: "1px solid #EBEBEB",
      background: "#fff",
      width: "auto",
      height: "100%",
      cursor: "pointer",
      "&:hover": {
        background: "#EBEBEB",
      },
    },
    icon: {
      width: "100%",
      height: "100%",
      padding: "4px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    image_container: {
      height: "113px",
      width: "100%",
      padding: "1rem",
      background: "#fff",
      "& .rpv-core__inner-pages": {
        overflow: "hidden !important",
      },
    },
    doc_title: {
      fontSize: "16px !important",
      fontWeight: "400 !important",
      width: "67%",
      //   overflow: "hidden",
      //   textOverflow: "ellipsis",
      //   whiteSpace: "nowrap",
      //   marginRight: 8,
    },
  };
});

const DocumentGridViewCard = ({
  doc,
  singleInnerFolderFile,
  handleTitleClick,
  handleBoxClick,
  changeCheckBoxHandler,
  selectedCheckedDoc,
  openDocumentMenu,
  handleDocumentClick,
  setMenuFileIndex,
  setMenuFileDownloadURLs,
  setSelectedDocument,
  index,
  isFolder,
  blob_name,
  fileUrl,
  fileTitle,
  numOfFiles,
  fileSize,
  documentType,
  fsLightBoxViewHandler,
  fromInnerFolderView,
  setInnerSelectedFile,
  selectedSingleDoc,
  changeInnerCheckBoxHandler,
  innerSelectedCheckedDoc,
  innerModifiedCheckFile,
  fromClientPage,
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={12}
      sm={fromClientPage ? 12 : 6}
      md={fromClientPage ? 6 : 4}
      lg={fromClientPage ? 6 : 3}
    >
      <Box
        className={classes.doc_card}
        bgcolor={
          selectedCheckedDoc?.some((item) => item?.id === doc?.id)
            ? "#D9ECFF !important"
            : ""
        }
        onClick={() => {
          if (isFolder) {
            handleTitleClick(doc);
            setSelectedDocument(doc);
          } else {
            fsLightBoxViewHandler(doc);
            // setSelectedDocument(InnerFolderView);
          }
        }}
      >
        {isFolder ? (
          <Box className={classes.image_container}>
            <DocumentFolderIcon />
          </Box>
        ) : checkFileExtension(blob_name) ? (
          <Box bgcolor="#FCFCFC" className={classes.image_container}>
            <img
              src={fileUrl}
              alt={fileTitle}
              style={{ height: "100%", objectFit: "contain" }}
            />
          </Box>
        ) : isPDF(blob_name) ? (
          <Box className={classes.image_container}>
            <PdfUi pdfUrl={fileUrl} />
          </Box>
        ) : (
          <Box bgcolor="#FCFCFC" className={classes.image_container}>
            <img src={pdfViewImg} alt={fileTitle} />
          </Box>
        )}
        <Stack padding="1rem" gap="8px">
          <Box
            display="flex"
            gap="16px"
            alignItems="center"
            justifyContent="space-between"
          >
            <Tooltip
              title={fileTitle}
              // arrow
              placement="top-start"
              PopperProps={{
                sx: {
                  marginBottom: "-14px !important",
                },
              }}
            >
              <Typography
                className={classes.doc_title}
                variant="subtitle1"
                noWrap
              >
                {fileTitle}
              </Typography>
            </Tooltip>
            {isFolder ? (
              <Typography
                alignSelf="flex-end"
                variant="caption"
                // fontSize="12px"
                fontWeight="400"
              >
                {numOfFiles === 1
                  ? `${numOfFiles} file`
                  : `${numOfFiles} files`}
              </Typography>
            ) : (
              <Typography
                alignSelf="flex-end"
                variant="caption"
                // fontSize="12px"
                fontWeight="400"
                width="50%"
                textAlign="right"
              >
                {fileSize}
              </Typography>
            )}
          </Box>

          <Box
            display="flex"
            alignItems="center"
            gap="8px"
            justifyContent="space-between"
          >
            <Chip
              label={documentType}
              size="small"
              sx={{
                marginLeft: 0,
                padding: "4px",
                color: `${documentType === "Personal" ? "#2196F3" : "#3DC583"}`,
                background: `${
                  documentType === "Personal"
                    ? "rgba(33, 150, 243, 0.15)"
                    : "rgba(61, 197, 131, 0.15)"
                }`,
                // "& .MuiChip-deleteIcon": {
                //   color: `${
                //     doc?.document_type === "client_level"
                //       ? "#2196F3"
                //       : "#3DC583"
                //   }`,
                // },
              }}
            />
            {selectedCheckedDoc?.length === 0 && (
              <IconButton
                sx={{ padding: "0" }}
                id="document-btn"
                aria-controls={openDocumentMenu ? "document-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={openDocumentMenu ? "true" : undefined}
                onClick={(event) => {
                  handleDocumentClick(event);
                  setMenuFileIndex(index);
                  setMenuFileDownloadURLs([doc]);
                  fromInnerFolderView
                    ? innerSelectedCheckedDoc?.length
                      ? setInnerSelectedFile(null)
                      : setInnerSelectedFile({
                          ...singleInnerFolderFile,
                          // folder_id: selectedSingleDoc?.id,
                        })
                    : selectedCheckedDoc?.length
                    ? setSelectedDocument(null)
                    : setSelectedDocument(doc);
                }}
              >
                <MoreVertIcon />
              </IconButton>
            )}
          </Box>
          <Box
            onClick={handleBoxClick}
            sx={{
              position: "absolute",
              top: "5px",
              right: "5px",
            }}
          >
            <Checkbox
              sx={{ background: "#fff" }}
              onChange={() => {
                if (fromInnerFolderView) {
                  changeInnerCheckBoxHandler({
                    ...singleInnerFolderFile,
                    index_id: `${selectedSingleDoc?.id}-${index}`,
                    index: index,
                  });
                } else {
                  changeCheckBoxHandler({
                    ...doc,
                    unique_id: `${doc?.type}-${doc?.id}`,
                  });
                }
              }}
              checked={
                fromInnerFolderView
                  ? innerSelectedCheckedDoc.some(
                      (selectedItem) =>
                        selectedItem?.index_id ===
                        innerModifiedCheckFile?.index_id
                    )
                  : selectedCheckedDoc?.some(
                      (selectedItem) =>
                        selectedItem?.unique_id === `${doc?.type}-${doc?.id}`
                    )
              }
              inputProps={{ "aria-label": "controlled" }}
            />
          </Box>
        </Stack>
      </Box>
    </Grid>
  );
};

export default DocumentGridViewCard;
