import React, { useEffect } from "react";
import ComingSoonPage from "../../../common/ComingSoonPage";
import { useDispatch, useSelector } from "react-redux";
import { getAgencyDetailFunc } from "../../../actions/Agency";
import { segmentTrack } from "../../../utils/helpers/tracking_utils";

const EmailMarketing = () => {
  const dispatch = useDispatch();
  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  useEffect(() => {
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({ event: "email_marketing", agent: agentUser?.agent, agency: agencyDetail?.agency })
    }

  }, [agentUser, agencyDetail])

  useEffect(() => {
    dispatch(getAgencyDetailFunc());
  }, []);

  return <ComingSoonPage />;
};

export default EmailMarketing;
