import React from "react";
import { Avatar, Box, Stack, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Style from "../NotificationDrawer/style";
import SmsIcon from "../../icons/SmsIcon";
import CallIcon from "../../icons/CallIcon";
import { getAlertNotificationText } from "../../utils/helpers";

const Styles = makeStyles((theme) => Style(theme));

const AlertNotificationCard = ({ notificationData, backgroundColor }) => {
  const classes = Styles();

  const displayText = getAlertNotificationText(notificationData);

  return (
    <Box className={classes.all_container} bgcolor={backgroundColor}>
      <Avatar src={notificationData?.client?.profile_picture_url} alt="name" />
      <Stack gap="8px">
        <Stack gap="4px">
          <Typography variant="body2" fontSize="16px">
            {notificationData?.activity_performed_by}{" "}
            <span className={classes.lightText}>{displayText}</span>{" "}
          </Typography>

          <Typography
            variant="caption"
            fontSize="12px"
            className={classes.lightText}
          >
            {notificationData?.date}
          </Typography>
        </Stack>

        <Stack borderRadius="4px" bgcolor="#fff" p={2} gap={1}>
          <Typography variant="caption" color="rgba(0, 0, 0, 0.38)">
            Contact {notificationData?.client?.name}
          </Typography>
          <Box display="flex" alignItems="center" gap="16px">
            {notificationData?.client?.email && (
              <Box
                href={`mailto:${notificationData?.client?.email}`}
                display="flex"
                gap="4px"
                alignItems="center"
                component="a"
              >
                <SmsIcon />
                <Typography component="span" fontSize="12px" color="secondary">
                  {notificationData?.client?.email}
                </Typography>
              </Box>
            )}

            {notificationData?.client?.contact_number && (
              <Box
                component="a"
                href={`tel:${notificationData?.client?.contact_number}`}
                display="flex"
                gap="4px"
                alignItems="center"
              >
                <CallIcon />
                <Typography component="span" fontSize="12px" color="secondary">
                  {notificationData?.client?.contact_number}
                </Typography>
              </Box>
            )}
          </Box>
        </Stack>
      </Stack>
    </Box>
  );
};

export default AlertNotificationCard;
