import clioLogo from "../../assets/images/clio.png";
import mycaseLogo from "../../assets/images/my-case.png";
import practicePantherLogo from "../../assets/images/practice-panther.png";
import caseOneLogo from "../../assets/images/case-one.png";
import needlesLogo from "../../assets/images/needles.png";
import fileVineLogo from "../../assets/images/file-vine.png";

import Cookies from "js-cookie";

const agencyId = Cookies.get("agencyId");
const agentId = Cookies.get("agentId");

export const integrationsItems = [
  {
    title: "Clio",
    website: "https://www.clio.com/",
    description:
      "Seamless client intake, calendaring, billing, and document automation, all on one platform.",
    subheading: "www.clio.com",
    coming_soon: false,
    logo: clioLogo,
    authLink: `https://app.clio.com/oauth/authorize?response_type=code&client_id=bRt1cMNgJrtvXoDq9fxcHHzust9GL4ZLfBx5H44T&redirect_uri=https%3A%2F%2Fapi.mycasecare.com%2Fintegrations/clio/auth&state=${agentId}-${agencyId}`,
  },
  {
    title: "FileVine",
    website: "https://www.filevine.com/",
    description: "Your Complete Legal Tech Stack, Supercharged by AI.",
    subheading: "www.filevine.com",
    coming_soon: true,
    logo: fileVineLogo,
    authLink: "",
  },
  {
    title: "Mycase",
    website: "https://www.mycase.com",
    description: "MyCase is a web-based legal practice management software for the modern law firm.",
    subheading: "www.mycase.com",
    coming_soon: true,
    logo: mycaseLogo,
    authLink: "",
  },
  {
    title: "PracticePanther",
    website: "https://www.practicepanther.com",
    description: "Simple and secure law practice management software for lawyers and firms.",
    subheading: "www.practicepanther.com",
    coming_soon: true,
    logo: practicePantherLogo,
    authLink: "",
  },
  {
    title: "Case.One",
    website: "https://www.case.one",
    description: "Case.one is a company that focuses on legal process automation in the legal tech industry.",
    subheading: "www.case.one",
    coming_soon: true,
    logo: caseOneLogo,
    authLink: "",
  },
  {
    title: "Needles",
    website: "https://assemblysoftware.com/needles",
    description: "Needles is a powerful legal software that eliminates the hassle of manually managing all of your firm's projects.",
    subheading: "www.needles.com",
    coming_soon: true,
    logo: needlesLogo,
    authLink: "",
  },
];
