import {
  Avatar,
  Box,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import DashboardIcon from "../../icons/DashboardIcon";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { makeStyles, styled } from "@mui/styles";
import Style from "./style";
import MccVerticalLogo from "../../assets/images/mcc-vertical-logo.png";
import Cookies from "js-cookie";
import CasesIcon from "../../icons/CasesIcon";
import ClientsIcon from "../../icons/ClientsIcon";
import MarketingIcon from "../../icons/MarketingIcon";
import ReferralsIcon from "../../icons/ReferralsIcon";
import { useDispatch } from "react-redux";
import {
  resetAllBodyParts,
  resetUserFeedNotifications,
} from "../../actions/AppConfig";
import IntegrationIcon from "../../icons/IntegrationIcon";
import SettingIcon from "../../icons/SettingIcon";
import {
  resetCaseDetailByCaseId,
  resetClientPainlogs,
} from "../../actions/Cases";

// const allTabs = [
//   {
//     title: "Account",
//     component: <Profile />,
//     to: "/profile",
//   },
// ];

// const styles = makeStyles((theme) => {
//   return {
//     activeColor: {
//       // selected and (selected + hover) states
//       "&& .Mui-selected, && .Mui-selected:hover": {
//         backgroundColor: "red",
//         "&, & .MuiListItemIcon-root": {
//           color: "pink",
//         },
//       },
//     },
//   };
// });

const dashboardLists = [
  {
    title: "Dashboard",
    icon: <DashboardIcon />,
    to: "/",
  },
  { title: "Cases", icon: <CasesIcon />, to: "/cases", alternateName: "/case" },
  {
    title: "Clients",
    icon: <ClientsIcon />,
    to: "/clients",
    alternateName: "/client",
  },
  {
    title: "Marketing",
    icon: <MarketingIcon />,
    to: "/marketing?tab=branding",
    alternateName: "/marketing",
  },
  { title: "Referrals", icon: <ReferralsIcon />, to: "/referrals" },
  { title: "Integrations", icon: <IntegrationIcon />, to: "/integrations" },
  {
    title: "Settings",
    icon: <SettingIcon />,
    to: "/settings?tab=general",
    alternateName: "/settings",
    className: "settingBottom",
  },
];

const Styles = makeStyles((theme) => Style(theme));

const Sidedrawer = ({ drawerWidth }) => {
  const location = useLocation();
  const classes = Styles();
  const pathName = location?.pathname;
  const [selectedList, setSelectedList] = useState(
    parseInt(localStorage.getItem("selectedIndex")) || 0
  );
  const dispatch = useDispatch();

  // useEffect to handle refreshing the page
  useEffect(() => {
    const storedIndex = parseInt(Cookies.get("selectedIndex"));
    if (storedIndex !== selectedList) {
      setSelectedList(storedIndex || 0);
    }
  }, []);

  useEffect(() => {
    let pageTitle = "MCC";
    if (pathName === "/") {
      pageTitle = "MCC | Dashboard";
    } else if (pathName === "/cases") {
      pageTitle = "MCC | Cases";
    } else if (pathName === "/clients") {
      pageTitle = "MCC | Clients";
    } else if (pathName === "/marketing") {
      pageTitle = "MCC | Marketing";
    } else if (pathName === "/referrals") {
      pageTitle = "MCC | Referrals";
    } else if (pathName === "integrations") {
      pageTitle = "MCC | Integrations";
    } else if (pathName === "/settings") {
      pageTitle = "MCC | Settings";
    } else if (pathName === "/profile") {
      pageTitle = "MCC | Profile";
    }

    document.title = pageTitle;
  }, [pathName]);

  const selectListHandler = (index) => {
    dispatch(resetUserFeedNotifications());
    dispatch(resetClientPainlogs());
    dispatch(resetAllBodyParts());
    dispatch(resetCaseDetailByCaseId());
    setSelectedList(index);
    Cookies.set("selectedIndex", index);
  };

  return (
    <Box>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            padding: "1rem",
          },
        }}
        variant="permanent"
        anchor="left"
      >
        <Box padding="12px 8px">
          <img src={MccVerticalLogo} width="100%" height="100%" alt="logo" />
        </Box>
        <Divider />

        <List
          sx={{
            height: "100%",
            paddingTop: "24px",
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          {dashboardLists.map((text, index) => (
            <React.Fragment key={index}>
              <ListItem
                disablePadding
                onClick={() => selectListHandler(index)}
                sx={{
                  borderRadius: "8px !important",
                  color: "#566875 !important",
                  "&:hover": {
                    background: "rgba(12, 128, 243, 0.10)",
                    color: "#0C80F3 !important",
                    "& svg path": {
                      stroke: "#0C80F3",
                    },
                  },
                }}
                className={`${text.className ? classes.settingBottom : ""} ${
                  pathName === text.to ||
                  pathName.startsWith(text.alternateName)
                    ? //  ||selectedList === index
                      classes.activeTab
                    : ""
                }`}
                // className={`${text.className ? classes.settingBottom : ""} ${
                //   pathName === text.to ? classes.activeTab : ""
                // }`}
              >
                <ListItemButton
                  disableRipple
                  component={NavLink}
                  // selected={selectedList === index}
                  selected={
                    pathName === text.to ||
                    pathName.startsWith(text.alternateName)
                  }
                  to={text.to}
                  sx={{
                    "&:hover": {
                      backgroundColor: "transparent !important",
                    },
                  }}
                >
                  <ListItemIcon className={classes.icon}>
                    {/* {React.cloneElement(text.icon, {
        color: selectedList === index ? "#fff" : "#566875",
      })} */}
                    {React.cloneElement(text.icon, {
                      color:
                        pathName === text.to ||
                        pathName.startsWith(text.alternateName)
                          ? "#fff"
                          : "#566875",
                    })}
                  </ListItemIcon>
                  <ListItemText
                    primary={text.title}
                    // className={`${
                    //   selectedList === index ? classes.activeColor : ""
                    // }`}
                    className={`${
                      pathName === text.to ||
                      pathName.startsWith(text.alternateName)
                        ? classes.activeColor
                        : ""
                    }`}
                  />
                </ListItemButton>
              </ListItem>
            </React.Fragment>
          ))}
        </List>
      </Drawer>
    </Box>
  );
};

export default Sidedrawer;
