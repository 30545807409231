import React from "react";

const ListViewIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M4 15H20V13H4V15ZM4 19H20V17H4V19ZM4 11H20V9H4V11ZM4 5V7H20V5H4Z"
        fill="black"
        fillOpacity="0.6"
      />
    </svg>
  );
};

export default ListViewIcon;
