import React, { useState, useEffect, useMemo, useRef } from "react";
import axios from "axios";
import JSZip from "jszip";

import {
  Avatar,
  Box,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  Menu,
  Chip,
  FormControl,
  InputLabel,
  Select,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  List,
  ListItemButton,
  Breadcrumbs,
  Link,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";

import Cookies from "js-cookie";

import Button from "../../../common/Button";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import pdfImg from "../../../assets/images/pdfImg.png";
import pngImg from "../../../assets/images/image-icon.png";
import docImg from "../../../assets/images/docImg.png";
import { makeStyles } from "@mui/styles";
import { DocCircle, FolderIcon, InfoCircle } from "../../../assets/constants";
import { saveAs } from "file-saver";
import { Foldericon } from "../../../assets/images/folder-icon.svg";

import DocumentDrawer from "./DocumentDrawer";
import { useDispatch, useSelector } from "react-redux";
import {
  getCaseClientDocsFunc,
  getCaseDetailByCaseId,
  resetCaseClientDocs,
  resetCaseDetailByCaseId,
} from "../../../actions/Cases";
import AlertDialog from "../../../components/AlertDialog";
import { resetDeleteDocument } from "../../../actions/AppConfig";
import { getAgencyDetailFunc } from "../../../actions/Agency";
import AlertMessage from "../../../common/AlertMessage";
import {
  segmentError,
  segmentTrack,
} from "../../../utils/helpers/tracking_utils";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import AddFolderIcon from "../../../icons/AddFolderIcon";
import GridViewIcon from "../../../icons/GridViewIcon";
import ListViewIcon from "../../../icons/ListViewIcon";
import GridViewLayout from "../../../components/GridViewLayout";
import ListViewLayout from "../../../components/ListViewLayout";
import {
  isSupportedType,
  onlyImageName,
  removeLastExtension,
  timeDateFormat,
} from "../../../utils/helpers";
import MoveFileLayout from "../../../components/MoveFileLayout";
import DeleteIcon from "../../../icons/DeleteIcon";
import InnerFolderView from "../../../components/InnerFolderView";
import {
  cancelDocRequestFunc,
  createEmptyFolderFunc,
  deleteFileFunc,
  deleteFileInsideFolderFunc,
  deleteFolderFunc,
  deleteMultipleDocumentsFunc,
  deleteMultipleFilesInFolderFunc,
  innerFolderViewFunc,
  moveFileToFolderFunc,
  moveFileToRootFunc,
  moveMultipleFilesToFolderFunc,
  renameFileFunc,
  renameFolderFunc,
  resetCancelDocRequest,
  resetCreateEmptyFolder,
  resetDeleteFile,
  resetDeleteFileInsideFolder,
  resetDeleteFolder,
  resetDeleteMultipleDocuments,
  resetDeleteMultipleFilesInFolder,
  resetInnerFolderView,
  resetMoveMultipleFilesToFolder,
  resetMoveToFolder,
  resetMoveToRoot,
  resetRenameFile,
  resetRenameFolder,
  resetSendDocReminder,
  sendDocReminderFunc,
} from "../../../actions/DocumentsActions";
import { useParams } from "react-router-dom";
import { appInsights } from "../../../utils/helpers/AppInsights";

const useStyles = makeStyles(() => {
  return {
    doc_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "12px !important",
      borderRadius: "8px !important",
      padding: "12px !important",
      border: "1px solid #EBEBEB !important",
      // padding: "12px",
    },
    checked_files_container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
      gap: "16px",
      borderRadius: "8px",
      background: " #D9ECFF",
      padding: "8px 16px",
    },
  };
});

const DocumentTab = ({ caseDetail, onCancelRequest }) => {
  const classes = useStyles();
  const token = Cookies.get("token");
  const agentID = Cookies.get("agentId");
  const imageAsPdfRef = useRef();

  const [documents, setDocuments] = useState([]);
  const [docsLoading, setDocsLoading] = useState(false);

  const [innerFolderContents, setInnerFolderContents] = useState([]);
  const [documentTypeTitle, setDocumentTypeTitle] = useState(null);
  const [modalContentData, setModalContentData] = useState({});
  const [snackbarData, setSnackbarData] = useState({});
  const [openModal, setOpenModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [documentView, setDocumentView] = useState("grid");
  const [selectedMoveFolderList, setSelectedMoveFolderList] = useState(null);
  const [breadcrumbs, setBreadcrumbs] = useState(["Home"]);
  const [onlyFolders, setOnlyFolders] = useState([]);
  const [fieldIsTouched, setFieldIsTouched] = useState(false);

  const [toggler, setToggler] = useState(false);
  const [lightboxSources, setLightboxSources] = useState([]);
  const [anchorDocumentEl, setAnchorDocumentEl] = useState(null);
  const [anchorRequestDocEl, setAnchorRequestDocEl] = useState(null);
  const [menuFileIndex, setMenuFileIndex] = useState(null);
  const [menuFileDownloadURLs, setMenuFileDownloadURLs] = useState([]);

  const [selectedCheckedDoc, setSelectedCheckedDoc] = useState([]);
  const [innerSelectedCheckedDoc, setInnerSelectedCheckedDoc] = useState([]);
  const [documentType, setDocumentType] = useState("personal");
  const [newFileName, setNewFileName] = useState("");
  const [fileExtension, setFileExtension] = useState("");
  const [newFolderName, setNewFolderName] = useState("");
  const [moveFolderName, setMoveFolderName] = useState("");

  const [openRenameModal, setOpenRenameModal] = useState(false);
  const [openCreateFolderModal, setOpenCreateFolderModal] = useState(false);
  const [moveFolderOpenModal, setMoveFolderOpenModal] = useState(false);
  const [moveFileDialog, setMoveFileDialog] = useState(false);
  const [showNewFolderBox, setShowNewFolderBox] = useState(false);
  const [innerFolderView, setInnerFolderView] = useState(null);
  const [innerSelectedFile, setInnerSelectedFile] = useState(null);
  const [fileToMove, setFileToMove] = useState(null);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const openDocumentMenu = Boolean(anchorDocumentEl);
  const openRequestDocMenu = Boolean(anchorRequestDocEl);

  const dispatch = useDispatch();
  const getCaseClientDocs = useSelector((state) => state.getCaseClientDocs);
  const { loading, error, caseClientDocs } = getCaseClientDocs;

  const agentProfile = useSelector((state) => state.agentProfile);
  const { loading: loadingAgent, error: errorAgent, agentUser } = agentProfile;

  const innerFolderViewContent = useSelector((state) => state.innerFolderView);
  const {
    loading: loadingFolderViewContent,
    errorFolderViewContent,
    folderContents,
  } = innerFolderViewContent;

  const getAgencyDetail = useSelector((state) => state.getAgencyDetail);
  const {
    loading: loadingAgency,
    error: errorAgency,
    agencyDetail,
  } = getAgencyDetail;

  const createdEmptyFolder = useSelector((state) => state.createdEmptyFolder);
  const {
    loading: loadingCreateEmptyFolder,
    error: errorCreateEmptyFolder,
    emptyFolder,
  } = createdEmptyFolder;

  const renameFolder = useSelector((state) => state.renameFolder);
  const {
    loading: loadingRenameFolder,
    error: errorRenameFolder,
    renameFolderName,
  } = renameFolder;

  const renameFile = useSelector((state) => state.renameFile);
  const {
    loading: loadingRenameFile,
    error: errorRenameFile,
    renameFileName,
  } = renameFile;

  const moveFileToFolder = useSelector((state) => state.moveFileToFolder);
  const {
    loading: loadingMoveFileToFolder,
    error: errorMoveFileToFolder,
    moveFileToFolders,
  } = moveFileToFolder;

  const deleteFile = useSelector((state) => state.deleteFile);
  const {
    loading: loadingDeleteFile,
    error: errorDeleteFile,
    deletedFile,
  } = deleteFile;

  const deleteFolder = useSelector((state) => state.deleteFolder);
  const {
    loading: loadingDeleteFolder,
    error: errorDeleteFolder,
    deletedFolder,
  } = deleteFolder;

  const sendDocReminder = useSelector((state) => state.sendDocReminder);
  const {
    loading: loadingSendDocReminder,
    error: errorSendDocReminder,
    sentDocReminder,
  } = sendDocReminder;

  const cancelDocument = useSelector((state) => state.cancelDocument);
  const {
    loading: loadingcancelDocument,
    error: errorcancelDocument,
    cancelledDocument,
  } = cancelDocument;

  const { id } = useParams();

  const [relatedTo, setRelatedTo] = useState("");
  const [documentDrawerState, setDocumentDrawerState] = useState({
    right: false,
  });
  const [drawerOpen, setDrawerOpen] = useState(false);

  const closeSnackbarHandler = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };

  useEffect(() => {
    let onlyFoldersList;
    if (selectedDocument && !selectedCheckedDoc?.length) {
      onlyFoldersList = documents?.filter(
        (doc) => doc?.type === "folder" && selectedDocument?.id !== doc?.id
      );
    } else {
      onlyFoldersList = documents?.filter((doc) => doc?.type === "folder");
    }

    setOnlyFolders(onlyFoldersList);
  }, [selectedDocument, selectedCheckedDoc, documents]);

  const [open, setOpen] = useState(false);
  const [openCreatedFolderMessage, setOpenCreatedFolderMessage] =
    useState(false);
  const [fileMovingMessage, setFileMovingMessage] = useState(false);
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
    setOpenCreatedFolderMessage(false);
    setFileMovingMessage(false);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDocumentDrawerState({ ...documentDrawerState, [anchor]: open });
  };

  const handleTitleClick = (file) => {
    if (!selectedCheckedDoc?.length) {
      setInnerFolderView(file);
      dispatch(innerFolderViewFunc(clientId, file?.id));
    }
  };

  useEffect(() => {
    if (folderContents?.ok) {
      setInnerFolderContents(folderContents?.contents);
      dispatch(resetInnerFolderView());
    } else if (errorFolderViewContent) {
      appInsights.trackTrace({
        message: `Error while getting folder contents: ${
          error || "Failed to load the folder contents!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_GETTING_FOLDER_CONTENTS",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Failed to load the folder contents on backend!"} `,
      });
    }
  }, [folderContents, errorFolderViewContent]);

  const fsLightBoxViewHandler = (file) => {
    const lightboxFiles = [];
    const unsupportedFiles = [];

    if (isSupportedType(file?.blob_name)) {
      lightboxFiles.push(file?.file_url);
    } else {
      unsupportedFiles.push(file?.file_url);
    }

    // Open the unsupported files in new tabs
    unsupportedFiles.forEach((url) => window.open(url, "_blank"));

    // Open the supported files in FsLightbox
    if (lightboxFiles?.length > 0) {
      setLightboxSources(lightboxFiles);
      setToggler(!toggler);
    }

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "document_viewed",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const caseId = caseDetail?.case?.id;
  const clientId = caseDetail?.case?.client_id;

  const handleDocumentClose = () => {
    setAnchorDocumentEl(null);
    setAnchorRequestDocEl(null);
  };

  // const image = new Image();
  // image.src = imageFile?.file_url;

  // const onlyImageName = imageFile?.file_name?

  const downlaodFolderPdf = async (selectedFile) => {
    const folderId = selectedFile?.id;

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // responseType: "blob",
      };

      setSnackbarData({
        textContent: `Downloading ${selectedFile?.name}`,
        horizontal: "right",
        vertical: "bottom",
        severity: "success",
        color: "info",
      });

      setAnchorDocumentEl(null);
      setOpenSnackbar(true);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/storage/get-folder-as-pdf?client_id=${clientId}&folder_id=${folderId}`,
        config
      );

      if (!response?.data?.ok) {
        appInsights.trackTrace({
          message: `Error while getting the pdf link ${
            response?.data?.message || "Could not fetch the pdf link!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_GETTING_PDF_LINK",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${
            response?.data?.message || "Could not fetch the pdf link!"
          } `,
        });
        throw new Error("Could not fetch the pdf link");
      }

      const pdfDownloadLink = response?.data?.pdfURL;
      // fetch(pdfDownloadLink)
      //   .then((response) => response.blob())
      //   .then((blob) => {
      //     const url = URL.createObjectURL(blob);

      const anchor = document.createElement("a");
      anchor.href = pdfDownloadLink;
      anchor.download = `${selectedFile?.name}.pdf`; // Set the desired filename for downloaded file
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      appInsights.trackTrace({
        message: `Error while getting the pdf link ${
          error || "Could not fetch the pdf link!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_GETTING_PDF_LINK",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Could not fetch the pdf link!"} `,
      });
      console.error("Error creating or downloading the PDF:", error);
    }
  };

  const downloadImagePdfHandler = async (fileDetail) => {
    const imageName = onlyImageName(fileDetail?.file_name);

    try {
      const config = {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        // responseType: "blob",
      };

      setSnackbarData({
        textContent: `Downloading ${imageName}.pdf`,
        horizontal: "right",
        vertical: "bottom",
        severity: "success",
        color: "info",
      });
      setOpenSnackbar(true);

      setAnchorDocumentEl(null);

      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/storage/get-file-as-pdf?client_id=${clientId}&file_id=${fileDetail?.id}`,
        config
      );

      if (!response?.data?.ok) {
        appInsights.trackTrace({
          message: `Error while getting the image as pdf link ${
            response?.data?.message || "Could not fetch the image as pdf link!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_GETTING_IMG_PDF_LINK",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${
            response?.data?.message || "Could not fetch the image as pdf link!"
          } `,
        });
        throw new Error("Could not fetch the pdf link");
      }

      const pdfDownloadLink = response?.data?.pdfURL;
      fetch(pdfDownloadLink)
        .then((response) => response.blob())
        .then((blob) => {
          const url = URL.createObjectURL(blob);

          const anchor = document.createElement("a");
          anchor.href = url;
          anchor.download = `${imageName}.pdf`;
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);

          URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error downloading PDF:", error);
        });
    } catch (error) {
      appInsights.trackTrace({
        message: `Error while getting the image as pdf link ${
          error || "Could not fetch the image as pdf link!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_GETTING_IMG_PDF_LINK",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Could not fetch the image as pdf link!"} `,
      });
      console.error("Error creating or downloading the PDF:", error);
    }
  };

  const downloadImgAsPdfHandler = () => {
    if (
      selectedDocument &&
      selectedDocument?.type === "folder" &&
      innerSelectedFile
    ) {
      downloadImagePdfHandler(innerSelectedFile);
    } else if (selectedDocument?.type === "folder") {
      downlaodFolderPdf(selectedDocument);
    } else {
      downloadImagePdfHandler(selectedDocument);
    }
  };

  const handleDownloadClick = async () => {
    setAnchorDocumentEl(null);

    setSnackbarData({
      textContent: `Downloading ${
        innerSelectedFile ? innerSelectedFile?.name : selectedDocument?.name
      }`,
      horizontal: "right",
      vertical: "bottom",
      severity: "success",
      color: "info",
    });
    setOpenSnackbar(true);

    if (
      selectedDocument &&
      selectedDocument?.type === "folder" &&
      innerSelectedFile
    ) {
      const fileName = innerSelectedFile || selectedDocument;

      if (fileName) {
        const response = await fetch(fileName?.download_url);
        const blob = await response.blob();
        saveAs(blob, fileName?.name);
      }
    } else if (selectedDocument?.type === "file") {
      // const selectedFile = menuFileDownloadURLs?.find(
      //   (file) => file?.name === selectedDocument?.name
      // );

      if (selectedDocument) {
        const response = await fetch(selectedDocument?.download_url);
        const blob = await response.blob();
        saveAs(blob, selectedDocument?.name);
      }
    } else {
      try {
        const folderId = selectedDocument?.id;
        // const folderData = await axios.get()

        const config = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        };
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/storage/get-folder-contents?client_id=${clientId}&folder_id=${folderId}`,
          config
        );

        if (!response?.data?.ok) {
          appInsights.trackTrace({
            message: `Error while downloading folder:  ${
              response?.data?.message || "Could not download the folder!"
            }`,
          });
          segmentError({
            event: "ERR_BACKEND_DOWNLOAD_FOLDER",
            agent: agentUser?.agent,
            agency: agencyDetail?.agency,
            error: `${
              response?.data?.message || "Could not download the folder!"
            } `,
          });
          throw new Error(
            response?.data?.message || "Error while downloading folder"
          );
        }

        const zip = new JSZip();

        // Fetch all files and add them to the zip
        const fetchPromises = response?.data?.contents?.map(async (file) => {
          const response = await fetch(file?.download_url);
          const blob = await response.blob();
          zip.file(file?.file_name, blob);
        });

        // Wait for all files to be added to the zip
        await Promise.all(fetchPromises);

        // Generate the zip blob and initiate the download
        const zipBlob = await zip.generateAsync({ type: "blob" });
        saveAs(zipBlob, `${selectedDocument?.name}.zip`);
      } catch (error) {
        appInsights.trackTrace({
          message: `Error while downloading folder:  ${
            error || "Could not download the folder!"
          }`,
        });
        segmentError({
          event: "ERR_BACKEND_DOWNLOAD_FOLDER",
          agent: agentUser?.agent,
          agency: agencyDetail?.agency,
          error: `${error || "Could not download the folder!"} `,
        });
        const folderError =
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message;
        console.log(folderError);
      }
    }

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "document_downloaded",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  const handleDocumentClick = (event) => {
    event.stopPropagation();
    selectedCheckedDoc?.length || innerSelectedCheckedDoc?.length
      ? setAnchorDocumentEl(null)
      : setAnchorDocumentEl(event.currentTarget);
  };

  const handleRequestDocClick = (e) => {
    setAnchorRequestDocEl(e.currentTarget);
  };

  useEffect(() => {
    if (caseId && clientId) {
      dispatch(getCaseClientDocsFunc(caseId, clientId));
      dispatch(getAgencyDetailFunc());
    }
  }, [caseDetail]);
  useEffect(() => {
    if (caseClientDocs?.ok) {
      let folders = [];
      let files = [];

      caseClientDocs?.contents.forEach((doc) => {
        if (doc.type === "folder") {
          folders.push(doc);
        } else {
          files.push(doc);
        }
      });

      // Sort folders and files alphabetically
      folders.sort((a, b) => a.name.localeCompare(b.name));
      files.sort((a, b) => a.name.localeCompare(b.name));

      // Concatenate folders and files
      const sortedDocuments = folders.concat(files);

      setDocuments(sortedDocuments);
    } else if (error) {
      appInsights.trackTrace({
        message: `Error while getting documents in case: ${
          error || "Getting documents failed in case!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_GETTING_CASE_DOCUMENTS",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${error || "Error getting case documents on backend!"} `,
      });
    }
  }, [caseClientDocs, error]);

  useEffect(() => {
    if (innerSelectedFile) {
      const { extension, nameWithoutExtension } = removeLastExtension(
        innerSelectedFile?.name
      );

      setFileExtension(extension);
      setNewFileName(nameWithoutExtension);
    } else if (selectedDocument?.type === "folder") {
      setNewFolderName(selectedDocument?.name);
    } else {
      const { extension, nameWithoutExtension } = removeLastExtension(
        selectedDocument?.name
      );

      setFileExtension(extension);
      setNewFileName(nameWithoutExtension);
      // setNewFileName(selectedDocument?.name);
    }
  }, [selectedDocument, innerSelectedFile]);

  const modalCloseHandler = () => {
    setOpenModal(false);
    setOpenRenameModal(false);
    setOpenCreateFolderModal(false);
    setMoveFolderOpenModal(false);
    setMoveFileDialog(false);
    setFieldIsTouched(false);
    setShowNewFolderBox(false);
  };

  // DOCUMENTS LAYOUT VIEW HANDLER
  const documentViewHandler = (event, nextView) => {
    setDocumentView(nextView);
    // setSelectedCheckedDoc([]);
  };

  // OPEN CREATE FOLDER MODAL
  const createNewFolderHandler = (e) => {
    setModalContentData({
      title: "Create New Folder",
    });
    setOpenCreateFolderModal(true);
    setNewFolderName("");
  };

  // ****************** MOVING FILE TO FOLDER OR ROOT ****************

  // MOVE FOLDER MODAL
  const moveToNewFolderHandler = (selectedFile) => {
    setModalContentData({
      title: "Move to New Folder",
    });
    setFileToMove(selectedFile);
    setMoveFolderOpenModal(true);
    setAnchorDocumentEl(null);
  };

  // MOVE FILE DIALOG MODAL
  const moveFileDialogHandler = () => {
    setModalContentData({
      title: "Move",
      lightText: innerSelectedFile
        ? `${innerSelectedFile?.name}`
        : selectedCheckedDoc?.length === 1
        ? `${selectedCheckedDoc[0]?.name}`
        : selectedCheckedDoc?.length >= 2
        ? `${selectedCheckedDoc?.length} files`
        : `${selectedDocument?.name}`,
    });
    setNewFolderName("");
    setFileToMove(null);
    setMoveFileDialog(true);
    setAnchorDocumentEl(null);
  };

  // MOVE FILE DIALOG MODAL
  const moveFilesInFolderDialogHandler = () => {
    setModalContentData({
      title: "Move",
      lightText:
        innerSelectedCheckedDoc?.length === 1
          ? `${innerSelectedCheckedDoc[0]?.file_name}`
          : `${innerSelectedCheckedDoc?.length} files`,
    });

    setMoveFileDialog(true);
    setAnchorDocumentEl(null);
  };

  const modalOpenHandler = () => {
    setOpenModal(true);
  };

  // MOVE FILE TO FOLDER / ROOT FUNCTION HANDLER
  const moveFileToFolderFunctionHandler = () => {
    let fileObj;

    if (
      !selectedCheckedDoc?.length &&
      !innerSelectedFile &&
      !innerSelectedCheckedDoc?.length &&
      selectedMoveFolderList
    ) {
      // MOVE SINGLE FILE FROM ROOT TO FOLDER
      fileObj = {
        client_id: clientId,
        destination_folder_id: selectedMoveFolderList?.id,
        file_ids: [selectedDocument?.id],
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (innerSelectedFile && selectedMoveFolderList) {
      // MOVE SINGLE FILE FROM INSIDE FOLDER TO ANOTHER FOLDER
      fileObj = {
        client_id: clientId,
        destination_folder_id: selectedMoveFolderList?.id,
        file_ids: [innerSelectedFile?.id],
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (
      innerSelectedFile &&
      !selectedCheckedDoc?.length &&
      !selectedMoveFolderList
    ) {
      // MOVE SINGLE FILE FROM INSIDE FOLDER TO ROOT
      fileObj = {
        client_id: clientId,
        destination_folder_id: caseDetail?.case?.client?.root_folder_id,
        file_ids: [innerSelectedFile?.id],
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (selectedCheckedDoc?.length && selectedMoveFolderList) {
      // MOVE MULTIPLE FILES FROM ROOT TO FOLDER
      const fileIds = selectedCheckedDoc?.map((doc) => doc?.id);
      fileObj = {
        client_id: clientId,
        destination_folder_id: selectedMoveFolderList?.id,
        file_ids: fileIds,
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (innerSelectedCheckedDoc?.length && selectedMoveFolderList) {
      // MOVE MULTIPLE FILES FROM INSIDE FOLDER TO FOLDER
      const fileIds = innerSelectedCheckedDoc?.map((doc) => doc?.id);

      fileObj = {
        client_id: clientId,
        destination_folder_id: selectedMoveFolderList?.id,
        file_ids: fileIds,
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else if (innerSelectedCheckedDoc?.length && !selectedMoveFolderList) {
      // MOVE MULTIPLE FILES FROM INSIDE FOLDER TO ROOT
      const fileIds = innerSelectedCheckedDoc?.map((doc) => doc?.id);

      fileObj = {
        client_id: clientId,
        destination_folder_id: caseDetail?.case?.client?.root_folder_id,
        file_ids: fileIds,
      };
      dispatch(moveFileToFolderFunc(fileObj));
    } else {
      setMoveFileDialog(false);
      setSelectedCheckedDoc([]);
    }
  };

  useEffect(() => {
    if (moveFileToFolders?.ok) {
      (innerSelectedFile || innerSelectedCheckedDoc?.length) &&
        dispatch(innerFolderViewFunc(clientId, selectedDocument?.id));

      dispatch(getCaseClientDocsFunc(caseId, clientId));
      setMoveFileDialog(false);
      emptyBreadcrumbHandler();
      dispatch(resetMoveToFolder());
      setInnerSelectedCheckedDoc([]);
      setSelectedCheckedDoc([]);
      setInnerSelectedFile(null);
    } else {
      setFileMovingMessage(true);
      dispatch(resetMoveToFolder());
      setMoveFileDialog(false);
      emptyBreadcrumbHandler();
      setInnerSelectedCheckedDoc([]);
      setInnerSelectedFile(null);
    }
  }, [moveFileToFolders]);

  // *********** DELETING DOCUMENTS *******************
  // OPEN DOCUMENT DELETE CONFIRM MODAL
  const documentDeleteHandler = (e) => {
    setModalContentData({
      title: "Are you sure?",
      subtitle: innerSelectedFile
        ? "This will permanently delete this document."
        : selectedDocument?.type === "folder"
        ? "This will permanently delete this folder and all the documents in it."
        : "This will permanently delete this document.",
    });
    modalOpenHandler();
    setAnchorDocumentEl(null);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "delete_document_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // OPEN DOCUMENT DELETE CONFIRM MODAL
  const multipleDocumentsDeleteHandler = (e) => {
    setModalContentData({
      title: "Are you sure?",
      subtitle:
        selectedCheckedDoc?.length <= 1
          ? selectedCheckedDoc[0]?.type === "folder"
            ? `This will permanently delete "${selectedCheckedDoc[0]?.name}" folder and all the files inside it.`
            : `This will permanently delete "${selectedCheckedDoc[0]?.name}" file.`
          : "This will permanently delete the selected files and folders ( including files inside it )",
    });
    modalOpenHandler();
    setAnchorDocumentEl(null);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "delete_document_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // OPEN DOCUMENT DELETE DIALOG WHEN INNNER FILES SELECTED
  const multipleFilesDeleteHandler = (e) => {
    setModalContentData({
      title: "Are you sure?",
      subtitle:
        innerSelectedCheckedDoc?.length <= 1
          ? `This will permanently delete "${innerSelectedCheckedDoc[0]?.name}" file.`
          : `This will permanently delete ${innerSelectedCheckedDoc?.length} files.`,
    });
    modalOpenHandler();
    setAnchorDocumentEl(null);

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "delete_document_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  // DELETING PARTICULAR DOCUMENT HANDLER
  const deletingDocHandler = () => {
    let fileObj;
    let folderObj;
    let filesToDelete;
    let foldersToDelete;
    if (innerSelectedFile && !innerSelectedCheckedDoc?.length) {
      // DELETE SINGLE FILE INSIDE FOLDER
      fileObj = {
        client_id: clientId,
        file_ids: [innerSelectedFile?.id],
      };

      dispatch(deleteFileFunc(fileObj));
    } else if (innerSelectedCheckedDoc?.length) {
      // DELETE MULTIPLE FILES INSIDE FOLDER
      const fileIds = innerSelectedCheckedDoc?.map((file) => file?.id);
      fileObj = {
        client_id: clientId,
        file_ids: fileIds,
      };

      dispatch(deleteFileFunc(fileObj));
    } else if (selectedCheckedDoc?.length) {
      // DELETE MULTIPLE FILES OR FOLDERS IN ROOT
      filesToDelete = selectedCheckedDoc
        ?.filter((item) => item?.type === "file")
        ?.map((file) => file?.id);

      foldersToDelete = selectedCheckedDoc
        ?.filter((item) => item?.type === "folder")
        ?.map((file) => file?.id);

      fileObj = {
        client_id: clientId,
        file_ids: filesToDelete,
      };

      folderObj = {
        folder_ids: foldersToDelete,
      };

      filesToDelete?.length && dispatch(deleteFileFunc(fileObj));
      foldersToDelete?.length && dispatch(deleteFolderFunc(folderObj));
    } else {
      // DELETE SINGLE DOCUMENT IN ROOT

      fileObj = {
        client_id: clientId,
        file_ids: [selectedDocument?.id],
      };

      folderObj = { folder_ids: [selectedDocument?.id] };

      selectedDocument?.type === "file"
        ? dispatch(deleteFileFunc(fileObj))
        : dispatch(deleteFolderFunc(folderObj));
    }

    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "document_deleted",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
  };

  useEffect(() => {
    if (deletedFile?.ok) {
      modalCloseHandler();
      dispatch(getCaseClientDocsFunc(caseId, clientId));
      (innerSelectedCheckedDoc?.length || innerSelectedFile) &&
        dispatch(innerFolderViewFunc(clientId, selectedDocument?.id));

      innerSelectedCheckedDoc?.length
        ? setInnerSelectedCheckedDoc([])
        : setSelectedCheckedDoc([]);
      dispatch(resetDeleteFile());
    } else if (errorDeleteFile) {
      appInsights.trackTrace({
        message: `Error while deleting file: ${
          errorDeleteFile || "Could not delete the file!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_DELETE_FILE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorDeleteFile || "Could not delete the file!"}`,
      });
    }
  }, [deletedFile, errorDeleteFile]);

  useEffect(() => {
    if (deletedFolder?.ok) {
      modalCloseHandler();
      dispatch(getCaseClientDocsFunc(caseId, clientId));

      setSelectedCheckedDoc([]);
      dispatch(resetDeleteFolder());
    } else if (errorDeleteFolder) {
      appInsights.trackTrace({
        message: `Error while deleting folder: ${
          errorDeleteFolder || "Could not delete the folder!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_DELETE_FOLDER",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorDeleteFolder || "Could not delete the folder!"}`,
      });
    }
  }, [deletedFolder, errorDeleteFolder]);

  // ************* RENAMING DOCUMENTS OR FOLDER ******************

  // RENAME DOCUMENT/FILENAME FUNCTION
  const renameFileFunctionHandler = () => {
    setFieldIsTouched(true);
    const fileObj = {
      client_id: clientId,
      file_id: innerSelectedFile?.id,
      new_file_name: newFileName + fileExtension,
    };

    if (!newFileName) return;
    dispatch(renameFileFunc(fileObj));
  };

  useEffect(() => {
    if (renameFileName?.ok) {
      innerSelectedFile
        ? dispatch(innerFolderViewFunc(clientId, selectedDocument?.id))
        : dispatch(getCaseClientDocsFunc(caseId, clientId));
      setOpenRenameModal(false);
      // setInnerFolderView(null);
      setNewFileName("");
      setFileExtension("");

      dispatch(resetRenameFile());
    } else if (errorRenameFile) {
      appInsights.trackTrace({
        message: `Error while renaming file: ${
          errorDeleteFolder || "Could not rename the file!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_RENAME_FILE",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorDeleteFolder || "Could not rename the file!"}`,
      });
    }
  }, [renameFileName, errorRenameFile]);

  // RENAME FOLDER FUNCTION
  const renameFolderAndFileFunctionHandler = () => {
    setFieldIsTouched(true);

    let fileObj;
    if (selectedDocument?.type === "folder") {
      fileObj = {
        folder_id: selectedDocument?.id,
        new_name: newFolderName,
      };

      if (!newFolderName) return;

      dispatch(renameFolderFunc(fileObj));
    } else {
      const fileObj = {
        client_id: clientId,
        file_id: selectedDocument?.id,
        new_file_name: newFileName + fileExtension,
      };

      if (!newFileName) return;
      dispatch(renameFileFunc(fileObj));
    }
  };

  useEffect(() => {
    if (renameFolderName?.ok) {
      dispatch(getCaseClientDocsFunc(caseId, clientId));
      setOpenRenameModal(false);
      setNewFolderName("");
      // setOpenCreatedFolderMessage(true);

      dispatch(resetRenameFolder());
    } else if (errorRenameFolder) {
      appInsights.trackTrace({
        message: `Error while renaming folder: ${
          errorDeleteFolder || "Could not rename the folder!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_RENAME_FOLDER",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorDeleteFolder || "Could not rename the folder!"}`,
      });
    }
  }, [renameFolderName, errorRenameFolder]);

  // RENAME DOCUMENT FUNCTION
  const renameDocumentHandler = () => {
    setModalContentData({
      title: innerSelectedFile
        ? "Document Rename"
        : selectedDocument?.type === "folder"
        ? "Folder Rename"
        : "Document Rename",
    });
    // setFromInnerFolderFile(text ? true : false);
    setOpenRenameModal(true);
    setAnchorDocumentEl(null);
  };

  // CHANGE FILE NAME HANDLER
  const changeFileNameHandler = (e) => {
    setNewFileName(e.target.value);
  };

  // CHANGE FOLDER NAME
  const changeFolderNameHandler = (e) => {
    setNewFolderName(e.target.value);
  };

  // CREATING NEW FOLDER FUNCTION
  const creatingNewFolderHandler = () => {
    setFieldIsTouched(true);
    const doc_type = "case_level";

    const documentObj = {
      client_id: clientId,
      case_id: caseId,
      parent_id: caseDetail?.case?.client?.root_folder_id,
      folder_name:
        fileToMove && selectedDocument ? moveFolderName : newFolderName,
      folder_type: doc_type,
    };

    if (!newFolderName) return;

    dispatch(createEmptyFolderFunc(documentObj));
  };

  useEffect(() => {
    if (emptyFolder?.ok) {
      setFieldIsTouched(false);
      const createdFolderId = emptyFolder?.new_folder?.id;

      if (createdFolderId && fileToMove) {
        const fileObj = {
          client_id: clientId,
          destination_folder_id: createdFolderId,
          file_ids: [selectedDocument?.id],
        };
        dispatch(moveFileToFolderFunc(fileObj));
        setFileToMove(null);
        setMoveFolderOpenModal(false);
      }

      setMoveFolderName("");
      setShowNewFolderBox(false);
      setNewFolderName("");
      dispatch(getCaseClientDocsFunc(caseId, clientId));
      setOpenCreateFolderModal(false);
      setOpenCreatedFolderMessage(true);

      dispatch(resetCreateEmptyFolder());
    } else if (errorCreateEmptyFolder) {
      appInsights.trackTrace({
        message: `Error while creating an empty folder: ${
          errorDeleteFolder || "Could not create an empty folder!"
        }`,
      });
      segmentError({
        event: "ERR_BACKEND_CREATING_EMPTY_FOLDER",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
        error: `${errorDeleteFolder || "Could not create an empty folder!"}`,
      });
    }
  }, [emptyFolder, errorCreateEmptyFolder]);

  const showError = fieldIsTouched && newFolderName?.trim() === "";
  const moveToNewFileError = fieldIsTouched && moveFolderName?.trim() === "";
  const renameFileError = fieldIsTouched && newFileName?.trim() === "";

  // CREATING MOVE FOLDER FUNCTION
  const creatingMoveFolderFunction = () => {
    setFieldIsTouched(true);
    if (!moveFolderName) return;

    creatingNewFolderHandler();
  };

  // SEND REQUEST DOC REMINDER
  const sendRequestDocReminderHandler = () => {
    setAnchorRequestDocEl(null);
    const reminderObj = {
      client_id: clientId,
      document_request_id: selectedDocument?.id,
    };
    dispatch(sendDocReminderFunc(reminderObj));
    setOpenSnackbar(true);
    setSnackbarData({
      textContent: `Reminder Sent`,
      horizontal: "center",
      vertical: "top",
      severity: "success",
      color: "success",
    });
  };

  useEffect(() => {
    if (sentDocReminder?.ok) {
      resetSendDocReminder();
    }
  }, [sentDocReminder]);

  // CANCEL DOC REQUEST HANDLER
  const cancelDocRequestHandler = () => {
    const docReqObj = {
      document_request_id: selectedDocument?.id,
    };

    setAnchorRequestDocEl(null);
    dispatch(cancelDocRequestFunc(docReqObj, caseId));
    setOpenSnackbar(true);
    setSnackbarData({
      textContent: `Request Cancelled`,
      horizontal: "center",
      vertical: "top",
      severity: "success",
      color: "success",
    });
  };

  // SELECTED MOVE FOLDER LIST HANDLER
  const selectedMoveFolderListHandler = (doc) => {
    setBreadcrumbs((prevBreadcrumbs) => {
      const isAlreadySelected = prevBreadcrumbs.includes(doc?.name);

      if (isAlreadySelected) {
        setSelectedMoveFolderList(null);
        return prevBreadcrumbs.filter((breadcrumb) => breadcrumb !== doc?.name);
      } else {
        setSelectedMoveFolderList(doc);
        return ["Home", doc?.name];
      }
    });
  };

  // OPEN ADD DOCUMENTS DRAWER FROM ADD MORE DOCUMENTS
  const addMoreDocumentsHandler = () => {
    if (!innerFolderView) {
      setSelectedDocument(null);
    }
    toggleDrawer("right", true);
    // setInnerFolderView(false);
    setDrawerOpen(true);
    if (agentUser?.agent && agencyDetail?.agency) {
      segmentTrack({
        event: "new_document_clicked",
        agent: agentUser?.agent,
        agency: agencyDetail?.agency,
      });
    }
    setAnchorDocumentEl(null);
  };

  // BREADCRUMB LIST HANDLER
  const emptyBreadcrumbHandler = () => {
    setSelectedMoveFolderList(null);
    setBreadcrumbs(["Home"]);
  };

  const handleBoxClick = (e) => {
    e.stopPropagation();
  };

  const changeCheckBoxHandler = (doc) => {
    const isItemSelected = selectedCheckedDoc?.some(
      (selectedItem) => selectedItem?.unique_id === `${doc?.type}-${doc?.id}`
    );

    if (isItemSelected) {
      const newSelectedCheckboxes = selectedCheckedDoc?.filter(
        (selectedItem) => selectedItem?.unique_id !== `${doc?.type}-${doc?.id}`
      );
      setSelectedCheckedDoc(newSelectedCheckboxes);
    } else {
      setSelectedCheckedDoc([
        ...selectedCheckedDoc,
        { ...doc, unique_id: `${doc?.type}-${doc?.id}` },
      ]);
    }
  };

  const changeInnerCheckBoxHandler = (innerDoc) => {
    const isItemSelected = innerSelectedCheckedDoc?.some(
      (selectedItem) => selectedItem?.index_id === innerDoc?.index_id
    );

    if (isItemSelected) {
      const newSelectedCheckBoxes = innerSelectedCheckedDoc?.filter(
        (selectedItem) => selectedItem?.index_id !== innerDoc?.index_id
      );
      setInnerSelectedCheckedDoc(newSelectedCheckBoxes);
    } else {
      setInnerSelectedCheckedDoc([...innerSelectedCheckedDoc, innerDoc]);
    }
  };

  const parentCheckedState =
    selectedCheckedDoc?.length === 0
      ? "none"
      : selectedCheckedDoc?.length === documents?.length
      ? "all"
      : "some";

  const innerParentCheckedState =
    innerSelectedCheckedDoc?.length === 0
      ? "none"
      : innerSelectedCheckedDoc?.length === selectedDocument?.files?.length
      ? "all"
      : "some";

  // PARENT CHECK BOX HANDLER
  const changeParentCheckedBoxHandler = () => {
    if (innerSelectedCheckedDoc?.length) {
      const allSelected = innerFolderContents?.every((doc, i) =>
        innerSelectedCheckedDoc?.some((innerItem) => innerItem?.id === doc?.id)
      );
      const noneSelected = innerSelectedCheckedDoc?.length === 0;

      if (allSelected || noneSelected) {
        // Unselect all if all are selected or none are selected
        setInnerSelectedCheckedDoc([]);
      } else {
        // Select all if some are selected
        setInnerSelectedCheckedDoc(
          innerFolderContents?.map((item, i) => ({
            ...item,
            index_id: `${selectedDocument?.id}-${i}`,
          }))
        );
      }
    } else {
      const allSelected = documents?.every((item, index) =>
        selectedCheckedDoc?.some(
          (selectedItem) =>
            `${item?.type}-${item?.id}` === selectedItem?.unique_id
        )
      );
      const noneSelected = selectedCheckedDoc?.length === 0;

      if (allSelected || noneSelected) {
        // Unselect all if all are selected or none are selected
        setSelectedCheckedDoc([]);
      } else {
        // Select all if some are selected
        setSelectedCheckedDoc(
          documents?.map((item) => ({
            ...item,
            unique_id: `${item?.type}-${item?.id}`,
          }))
        );
      }
    }
  };

  const isFolderIncluded = selectedCheckedDoc?.some(
    (item) => item?.type === "folder"
  );

  return (
    <>
      {drawerOpen && (
        <DocumentDrawer
          anchor={"right"}
          open={drawerOpen}
          setOpen={setDrawerOpen}
          onClose={toggleDrawer}
          case_obj={caseDetail?.case}
          selectedDocument={selectedDocument}
          agent={
            caseDetail?.case?.agents.filter(
              (case_agent) => case_agent.id === agentID
            )[0]
          }
          client={caseDetail?.case?.client}
          // getCaseDocuments={getCaseDocuments}
        />
      )}

      {/* DOCUMENT DELETE MESSAGE */}
      {open && (
        <AlertMessage
          message="Document deleted!"
          severity="success"
          open={open}
          handleClose={handleClose}
        />
      )}

      {/* CREATED NEW FOLDER MESSAGE */}
      {openCreatedFolderMessage && (
        <AlertMessage
          message="Folder created succesfully!"
          severity="success"
          open={openCreatedFolderMessage}
          handleClose={handleClose}
        />
      )}

      {/* ERROR WHILE MOVING FILE MESSAGE */}
      {!loadingMoveFileToFolder && errorMoveFileToFolder && (
        <AlertMessage
          message={errorMoveFileToFolder}
          severity="error"
          open={fileMovingMessage}
          handleClose={handleClose}
        />
      )}

      {/* DELETE ALERT MODAL */}
      {openModal && (
        <AlertDialog
          openModal={openModal}
          closeModal={modalCloseHandler}
          isLoading={loadingDeleteFolder || loadingDeleteFile}
          data={modalContentData}
          formSubmitFunction={deletingDocHandler}
          color="error"
        />
      )}

      {/* RENAME FILE NAME ALERT */}
      {openRenameModal && (
        <AlertDialog
          openModal={openRenameModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          submitText="Save"
          isLoading={
            selectedDocument?.type === "folder" && !innerSelectedFile
              ? loadingRenameFolder
              : loadingRenameFile
          }
          formSubmitFunction={
            innerSelectedFile
              ? renameFileFunctionHandler
              : renameFolderAndFileFunctionHandler
          }
        >
          {innerSelectedFile ? (
            <TextField
              size="medium"
              value={newFileName}
              onChange={changeFileNameHandler}
              label="Document name"
              error={renameFileError}
              fullWidth
            />
          ) : selectedDocument?.type === "folder" ? (
            <TextField
              size="medium"
              value={newFolderName}
              onChange={changeFolderNameHandler}
              label="Folder name"
              error={showError}
              fullWidth
            />
          ) : (
            <TextField
              size="medium"
              value={newFileName}
              onChange={changeFileNameHandler}
              label="Document name"
              error={renameFileError}
              fullWidth
            />
          )}
        </AlertDialog>
      )}

      {/* CREATE NEW FOLDER MODAL ALERT */}
      {openCreateFolderModal && (
        <AlertDialog
          openModal={openCreateFolderModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          submitText="Save"
          isLoading={loadingCreateEmptyFolder}
          formSubmitFunction={creatingNewFolderHandler}
        >
          <Stack gap="16px">
            <TextField
              size="medium"
              value={newFolderName}
              onChange={changeFolderNameHandler}
              label="Folder name"
              fullWidth
              error={showError}
            />
          </Stack>
        </AlertDialog>
      )}

      {/* CREATE AND MOVE NEW FOLDER MODAL ALERT */}
      {moveFolderOpenModal && (
        <AlertDialog
          openModal={moveFolderOpenModal}
          closeModal={modalCloseHandler}
          data={modalContentData}
          submitText="Create and Move"
          isLoading={loadingCreateEmptyFolder || loadingMoveFileToFolder}
          formSubmitFunction={creatingMoveFolderFunction}
        >
          <Stack gap="16px">
            <TextField
              size="medium"
              value={moveFolderName}
              onChange={(e) => setMoveFolderName(e.target.value)}
              label="Folder name"
              fullWidth
              error={moveToNewFileError}
            />
          </Stack>
        </AlertDialog>
      )}

      {/* MOVE FILE DIALOG  */}
      {moveFileDialog && (
        <AlertDialog
          openModal={moveFileDialog}
          closeModal={modalCloseHandler}
          data={modalContentData}
          submitText="Move Here"
          maxWidth="727px"
          isLoading={loadingMoveFileToFolder}
          padding="16px 32px 32px 32px"
          moveFileDialog={true}
          titlePaddingTop="32px"
          contentPaddingTop="0px !important"
          formSubmitFunction={moveFileToFolderFunctionHandler}
        >
          <MoveFileLayout
            breadcrumbs={breadcrumbs}
            emptyBreadcrumbHandler={emptyBreadcrumbHandler}
            showError={showError}
            setShowNewFolderBox={setShowNewFolderBox}
            showNewFolderBox={showNewFolderBox}
            documentRequestTitle={caseDetail?.case?.title}
            onlyFoldersList={onlyFolders}
            selectedMoveFolderList={selectedMoveFolderList}
            selectedMoveFolderListHandler={selectedMoveFolderListHandler}
            createFolderHandler={creatingNewFolderHandler}
            newFolderName={newFolderName}
            setNewFolderName={setNewFolderName}
            loadingCreateEmptyFolder={loadingCreateEmptyFolder}
          />
        </AlertDialog>
      )}

      {openSnackbar && (
        <Snackbar
          open={openSnackbar}
          autoHideDuration={3000}
          onClose={closeSnackbarHandler}
          anchorOrigin={{
            vertical: snackbarData.vertical,
            horizontal: snackbarData.horizontal,
          }}
        >
          <Alert
            onClose={closeSnackbarHandler}
            severity={snackbarData.severity}
            color={snackbarData.color}
            sx={{ width: "100%" }}
          >
            {snackbarData.textContent}
          </Alert>
        </Snackbar>
      )}

      <Stack
        boxShadow="0px 2px 16px 0px rgba(0, 0, 0, 0.08)"
        borderRadius="8px"
        padding="24px"
        // maxWidth="1106px"
        width="100%"
        gap="24px"
        height="645px"
        overflow="auto"
      >
        {/* FILTER SEARCH box */}
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box
            display="flex"
            gap="16px"
            alignItems="center"
            justifyContent="center"
          >
            {/* <Typography variant="h5">Documents</Typography> */}
            {/* <Box minWidth="326px">
              <TextField
                size="small"
                placeholder="Coming soon"
                label="Coming soon"
                fullWidth
                disabled={true}
              />
            </Box> */}
            {/* <Box>
              <FormControl sx={{ minWidth: "168px" }} size="small">
                <InputLabel id="demo-simple-select-label">
                  Related to
                </InputLabel>
                <Select
                  labelId="all-activity"
                  label="Related to"
                  id="all-activity"
                  size="small"
                  value={relatedTo}
                  IconComponent={ArrowDropDownIcon}
                  //   size="small"
                  //   sx={{
                  //     marginRight: "10px !important",
                  //     color: "#000 !important",
                  //     borderRadius: "4px !important",
                  //     background: "#F4F6F8",
                  //     "&:before": {
                  //       borderColor: "white !important",
                  //     },
                  //     "&:after": {
                  //       borderColor: "white !important",
                  //     },
                  //     boxShadow: "none !important",
                  //     ".MuiOutlinedInput-notchedOutline": {
                  //       border: "0  !important",
                  //     },

                  //     ".MuiSvgIcon-root ": {
                  //       fill: "#000 !important",
                  //     },
                  //   }}
                  onChange={(e) => setRelatedTo(e.target.value)}
                >
                  <MenuItem value="all-cases">All cases</MenuItem>
                </Select>
              </FormControl>
            </Box> */}
            <ToggleButtonGroup
              sx={{ height: "40px" }}
              exclusive
              value={documentView}
              onChange={documentViewHandler}
              aria-label="view"
            >
              <ToggleButton value="grid" aria-label="grid">
                <GridViewIcon />
              </ToggleButton>
              <ToggleButton value="list" aria-label="list">
                <ListViewIcon />
              </ToggleButton>
            </ToggleButtonGroup>
            {!innerFolderView && (
              <Box
                borderRadius="4px"
                component="button"
                border="1px solid rgba(0, 0, 0, 0.12)"
                padding="8px"
                height="40px"
                bgcolor="#fff"
                sx={{ cursor: "pointer" }}
                onClick={createNewFolderHandler}
              >
                <AddFolderIcon />
              </Box>
            )}
          </Box>

          <Button
            startIcon={<AddRoundedIcon />}
            title="Add documents"
            color="primary"
            variant="contained"
            disabled={
              selectedCheckedDoc?.length || innerSelectedCheckedDoc?.length
                ? true
                : false
            }
            onClick={() => addMoreDocumentsHandler()}
          />
        </Box>

        {/* MOVE FILES AND DELETED FILES WHEN SELECTED CHECKBOX */}
        {selectedCheckedDoc?.length ? (
          <Box className={classes.checked_files_container}>
            <Checkbox
              onChange={changeParentCheckedBoxHandler}
              checked={parentCheckedState === "all"}
              indeterminate={parentCheckedState === "some"}
              inputProps={{ "aria-label": "move files" }}
            />
            <Box flex="1">
              {selectedCheckedDoc?.length > 1 ? (
                <Typography
                  variant="subtitle1"
                  fontSize="16px"
                  fontWeight="400"
                >
                  {selectedCheckedDoc?.length} Items Selected
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  fontSize="16px"
                  fontWeight="400"
                >
                  "{selectedCheckedDoc[0]?.name}" Selected
                </Typography>
              )}
            </Box>
            {!isFolderIncluded &&
              (selectedCheckedDoc?.length > 1 ? (
                <Button
                  variant="text"
                  onClick={moveFileDialogHandler}
                  title="Move files"
                  size="small"
                />
              ) : (
                <Button
                  variant="text"
                  onClick={moveFileDialogHandler}
                  title="Move file"
                  size="small"
                />
              ))}
            <IconButton onClick={multipleDocumentsDeleteHandler}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <></>
        )}

        {/* MOVE INNER CHECKED FILES AND DELETED FILES WHEN SELECTED INNER CHECKBOX */}
        {innerSelectedCheckedDoc?.length ? (
          <Box className={classes.checked_files_container}>
            <Checkbox
              onChange={changeParentCheckedBoxHandler}
              checked={innerParentCheckedState === "all"}
              indeterminate={innerParentCheckedState === "some"}
              inputProps={{ "aria-label": "move files" }}
            />
            <Box flex="1">
              {innerSelectedCheckedDoc?.length > 1 ? (
                <Typography
                  variant="subtitle1"
                  fontSize="16px"
                  fontWeight="400"
                >
                  {innerSelectedCheckedDoc?.length} Items Selected
                </Typography>
              ) : (
                <Typography
                  variant="subtitle1"
                  fontSize="16px"
                  fontWeight="400"
                >
                  "{innerSelectedCheckedDoc[0]?.file_name}" Selected
                </Typography>
              )}
            </Box>

            <Button
              onClick={moveFilesInFolderDialogHandler}
              variant="text"
              title={
                innerSelectedCheckedDoc?.length <= 1
                  ? "Move file"
                  : "Move files"
              }
              size="small"
            />

            <IconButton onClick={multipleFilesDeleteHandler}>
              <DeleteIcon />
            </IconButton>
          </Box>
        ) : (
          <></>
        )}

        {innerFolderView ? (
          <InnerFolderView
            documentView={documentView}
            selectedDoc={selectedDocument}
            innerFolderView={innerFolderView}
            loading={loadingFolderViewContent}
            error={errorFolderViewContent}
            setInnerFolderView={setInnerFolderView}
            innerFolderContents={innerFolderContents}
            setInnerFolderContents={setInnerFolderContents}
            documentRequestTitle={caseDetail?.case?.title}
            fsLightBoxViewHandler={fsLightBoxViewHandler}
            handleDocumentClick={handleDocumentClick}
            setMenuFileIndex={setMenuFileIndex}
            setMenuFileDownloadURLs={setMenuFileDownloadURLs}
            setSelectedDocument={setSelectedDocument}
            lightboxSources={lightboxSources}
            toggler={toggler}
            openDocumentMenu={openDocumentMenu}
            handleDocumentClose={handleDocumentClose}
            anchorDocumentEl={anchorDocumentEl}
            addMoreDocumentsHandler={addMoreDocumentsHandler}
            moveFileDialogHandler={moveFileDialogHandler}
            moveToNewFolderFunction={moveToNewFolderHandler}
            renameDocumentHandler={renameDocumentHandler}
            handleDownloadClick={handleDownloadClick}
            documentDeleteHandler={documentDeleteHandler}
            setInnerSelectedFile={setInnerSelectedFile}
            innerSelectedFile={innerSelectedFile}
            handleBoxClick={handleBoxClick}
            changeCheckBoxHandler={changeCheckBoxHandler}
            changeInnerCheckBoxHandler={changeInnerCheckBoxHandler}
            innerSelectedCheckedDoc={innerSelectedCheckedDoc}
            selectedCheckedDoc={selectedCheckedDoc}
            downloadImgAsPdfHandler={downloadImgAsPdfHandler}
          />
        ) : (
          <>
            {" "}
            {/* GRID VIEW LAYOUT */}
            {documentView === "grid" && (
              <GridViewLayout
                documentsRequest={caseDetail?.case?.document_requests}
                documentRequestTitle={caseDetail?.case?.title}
                loading={loading}
                docsLoading={docsLoading}
                documents={documents}
                toggleDrawer={toggleDrawer}
                setDrawerOpen={setDrawerOpen}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                handleTitleClick={handleTitleClick}
                openDocumentMenu={openDocumentMenu}
                openRequestDocMenu={openRequestDocMenu}
                handleDocumentClick={handleDocumentClick}
                handleRequestDocClick={handleRequestDocClick}
                handleDownloadClick={handleDownloadClick}
                setMenuFileIndex={setMenuFileIndex}
                setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                toggler={toggler}
                lightboxSources={lightboxSources}
                anchorDocumentEl={anchorDocumentEl}
                anchorRequestDocEl={anchorRequestDocEl}
                handleDocumentClose={handleDocumentClose}
                documentDeleteHandler={documentDeleteHandler}
                renameDocumentHandler={renameDocumentHandler}
                moveToNewFolderFunction={moveToNewFolderHandler}
                moveFileDialogHandler={moveFileDialogHandler}
                selectedCheckedDoc={selectedCheckedDoc}
                addMoreDocumentsHandler={addMoreDocumentsHandler}
                fsLightBoxViewHandler={fsLightBoxViewHandler}
                changeCheckBoxHandler={changeCheckBoxHandler}
                handleBoxClick={handleBoxClick}
                sendRequestDocReminderHandler={sendRequestDocReminderHandler}
                cancelDocRequestHandler={cancelDocRequestHandler}
                downloadImgAsPdfHandler={downloadImgAsPdfHandler}
                imageAsPdfRef={imageAsPdfRef}
              />
            )}
            {/* LIST VIEW LAYOUT */}
            {documentView === "list" && (
              <ListViewLayout
                documentsRequest={caseDetail?.case?.document_requests}
                documentRequestTitle={caseDetail?.case?.title}
                loading={loading}
                docsLoading={docsLoading}
                documents={documents}
                toggleDrawer={toggleDrawer}
                setDrawerOpen={setDrawerOpen}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
                handleTitleClick={handleTitleClick}
                openDocumentMenu={openDocumentMenu}
                openRequestDocMenu={openRequestDocMenu}
                handleDocumentClick={handleDocumentClick}
                handleRequestDocClick={handleRequestDocClick}
                handleDownloadClick={handleDownloadClick}
                setMenuFileIndex={setMenuFileIndex}
                setMenuFileDownloadURLs={setMenuFileDownloadURLs}
                toggler={toggler}
                lightboxSources={lightboxSources}
                anchorDocumentEl={anchorDocumentEl}
                anchorRequestDocEl={anchorRequestDocEl}
                handleDocumentClose={handleDocumentClose}
                documentDeleteHandler={documentDeleteHandler}
                renameDocumentHandler={renameDocumentHandler}
                moveToNewFolderFunction={moveToNewFolderHandler}
                moveFileDialogHandler={moveFileDialogHandler}
                selectedCheckedDoc={selectedCheckedDoc}
                addMoreDocumentsHandler={addMoreDocumentsHandler}
                fsLightBoxViewHandler={fsLightBoxViewHandler}
                changeCheckBoxHandler={changeCheckBoxHandler}
                handleBoxClick={handleBoxClick}
                sendRequestDocReminderHandler={sendRequestDocReminderHandler}
                cancelDocRequestHandler={cancelDocRequestHandler}
                downloadImgAsPdfHandler={downloadImgAsPdfHandler}
                imageAsPdfRef={imageAsPdfRef}
              />
            )}{" "}
          </>
        )}

        {/* DOCUMENTS SECTION */}
        {/* {!loading &&
        !docsLoading &&
        !documents.length &&
        !caseDetail?.case?.document_requests?.length ? (
          <Stack alignItems="center" justifyContent="center" height="100%">
            <Avatar
              src={EmptyBox}
              alt="coming soon"
              sx={{
                width: "128px",
                height: "128px",
                paddingBottom: "24px",
              }}
            />
            <Typography variant="body1" color="secondary" pb={2}>
              All documents will show up here. you can also send document
              request to clients
            </Typography>
            <Button
              startIcon={<AddRoundedIcon />}
              title="Request New"
              color="primary"
              variant="contained"
              onClick={() => {
                toggleDrawer("right", true);
                setDrawerOpen(true);
              }}
            />
          </Stack>
        ) : docsLoading ? (
          <CircularProgressLoading />
        ) : (
          <>
            <Stack gap={1}>
              {documents.map((doc, i) => {
                return (
                  <Stack
                    key={i}
                    component="a"
                    p={1.5}
                    onClick={() => handleTitleClick(doc.files)}
                    direction="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                    sx={{ background: "#F4F6F8", borderRadius: "8px" }}
                  >
                    <Stack direction="row" alignItems="center">
                      <Box
                        width="48px"
                        height="48px"
                        bgcolor="#fff"
                        borderRadius="8px"
                      >
                        {doc.files.length > 1 ? (
                          <FolderIcon className={classes.icon} />
                        ) : (
                          <img
                            src={`${doc.icon}`}
                            alt={doc.title}
                            className={classes.icon}
                            width="100%"
                            height="100%"
                          />
                        )}
                      </Box>

                      <Stack pl={1.5}>
                        <Typography
                          variant="boxHeading"
                          color="disabled.customs"
                          sx={{
                            cursor: "pointer",
                            textDecoration: "none",
                            "&:hover": {
                              textDecoration: "underline",
                            },
                          }}
                        >
                          {doc.title}
                        </Typography>

                        <Typography
                          pb={1}
                          variant="tableSemiHeading"
                          color="disabled.boxHeading"
                        >
                          {doc.files.length}{" "}
                          {doc.files.length > 1 ? "files" : "file"}
                        </Typography>
                      </Stack>
                    </Stack>
                    <Box display="flex" alignItems="center">
                      <Chip
                        label={
                          doc?.document_type === "client_level"
                            ? "Personal"
                            : caseDetail?.case?.title
                        }
                        size="small"
                        sx={{
                          marginLeft: 0,
                          padding: "4px",
                          color: `${
                            doc?.document_type === "client_level"
                              ? "#2196F3"
                              : "#3DC583"
                          }`,
                          background: `${
                            doc?.document_type === "client_level"
                              ? "rgba(33, 150, 243, 0.15)"
                              : "rgba(61, 197, 131, 0.15)"
                          }`,
                          // "& .MuiChip-deleteIcon": {
                          //   color: `${
                          //     doc?.document_type === "client_level"
                          //       ? "#2196F3"
                          //       : "#3DC583"
                          //   }`,
                          // },
                        }}
                      />
                      <IconButton
                        id="document-btn"
                        aria-controls={
                          openDocumentMenu ? "document-menu" : undefined
                        }
                        aria-haspopup="true"
                        aria-expanded={openDocumentMenu ? "true" : undefined}
                        onClick={(event) => {
                          handleDocumentClick(event);
                          setMenuFileIndex(i);
                          setMenuFileDownloadURLs(doc.files);
                          setSelectedDocument(doc);
                        }}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Box>
                  </Stack>
                );
              })}
            </Stack>

            <Stack gap="8px">
              <Stack>
                <FsLightbox toggler={toggler} sources={lightboxSources} />
                <Menu
                  id="document-menu"
                  anchorEl={anchorDocumentEl}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={openDocumentMenu}
                  onClose={handleDocumentClose}
                  MenuListProps={{
                    "aria-labelledby": "document-button",
                  }}
                >
                  <MenuItem onClick={handleDownloadClick}>Download</MenuItem>
                  <MenuItem
                    onClick={documentDeleteHandler}
                    sx={{ color: "#FF2D2D" }}
                  >
                    Delete
                  </MenuItem>
                </Menu>
              </Stack>
            </Stack>
          </>
        )} */}
      </Stack>
    </>
  );
};

export default DocumentTab;
