import React from "react";
import CommonDrawer from "../../common/CommonDrawer";
import { makeStyles } from "@mui/styles";
import {
  Checkbox,
  Divider,
  Drawer,
  FormControl,
  FormControlLabel,
  FormGroup,
  Stack,
  Switch,
  Typography,
  IconButton,
  Box,
  Autocomplete,
  Chip,
  TextField,
  Tabs,
  Tab,
} from "@mui/material";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";

import Textfield from "../../common/Textfield";
import Button from "../../common/Button";
import Dropdown from "../../common/Dropdown";
import Style from "./style";
import RequestFromClient from "./RequestFromClient";
import UploadDocManually from "./UploadDocManually";
import RequestDocIcon from "../../icons/RequestDocIcon";
import UploadDocIcon from "../../icons/UploadDocIcon";

const tabsData = [
  {
    label: "Request From Client",
    value: "request-from-client",
    icon: <RequestDocIcon />,
  },
  {
    label: "Upload Manually",
    value: "upload-manually",
    icon: <UploadDocIcon />,
  },
];

const OverviewDrawer = ({
  anchor,
  open,
  setOpen,
  onClose,
  agent,
  client,
  getClientsData,
  selectedDocument,
}) => {
  const [tabValue, setTabValue] = React.useState("request-from-client");
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <CommonDrawer
      anchor={anchor}
      open={open}
      onClose={() => setOpen(false)}
      setOpen={setOpen}
      height="100%"
    >
      <Stack gap="24px" height="100%">
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Typography variant="h5">New Document</Typography>

          <IconButton size="small" onClick={() => setOpen(false)}>
            <CancelOutlinedIcon />
          </IconButton>
        </Box>

        <Box sx={{ width: "100%" }}>
          <Tabs
            // sx={{
            //   color: "#fff",
            //   // "& button:active": { background: "orange" },
            // }}
            value={tabValue}
            onChange={handleChange}
            // textColor="secondary"
            // aria-label="secondary tabs example"
          >
            {tabsData.map((tabData, i) => (
              <Tab
                icon={React.cloneElement(tabData.icon, {
                  color: tabValue === tabData.value ? "#0C80F3" : "#566875",
                })}
                iconPosition="start"
                sx={{
                  minHeight: "0 !important",
                  "& svg": {
                    marginRight: "8px !important",
                  },
                }}
                disableRipple
                key={i}
                value={tabData.value}
                label={tabData.label}
              />
            ))}
          </Tabs>
          <Divider />
        </Box>
        {/* TAB 1 REQUEST FROM CLIENT */}
        {tabValue === "request-from-client" && (
          <RequestFromClient
            agent={agent}
            client={client}
            onClose={onClose}
            setOpen={setOpen}
            getClientsData={getClientsData}
            selectedDocument={selectedDocument}
          />
        )}

        {/* TAB 2 TEAM CONTENT */}
        {tabValue === "upload-manually" && (
          <UploadDocManually
            agent={agent}
            client={client}
            getClientsData={getClientsData}
            onClose={onClose}
            setOpen={setOpen}
            selectedDocument={selectedDocument}
          />
        )}
      </Stack>
    </CommonDrawer>
  );
};

export default OverviewDrawer;
